import React from "react";
import Buttons from "../../../../../../base/basicComponents/button";
import EllipsisToolTip from "../../../../../../base/basicComponents/ellipsisToolTip";
import HistoricalVersionComponent from "../../../../../../base/basicComponents/historicalVersionComponent";
import {
  FileStatusDiv,
  ProposedChangeTagDiv,
} from "../../../../../../base/basicComponents/status";
import UploadFile from "../../../../../../base/basicComponents/uploadFile";
import BaseTbody from "../../../../../commonComponents/fileTbodys/baseFileTbody";
import {
  fileTags,
  flowBusinessIdMap,
} from "../../../../../publicDictionaryValues";
import "../index.scss";

export default class UpdateSupportingFileTbody extends BaseTbody {
  /**
   * function about show link if column type is link
   * @param {*} rowColumn
   * @param {*} column
   * @returns
   */
  returnFileName = (rowColumn, column, row) => {
    const fileId = row.data.find((item) => item.name === "fileId").value;
    const fileKey = row.data
      .find((item) => item.name === "fileKey")
      .value.trim();

    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
        />
        {this.props.isShowHistoryVersion && (
          <HistoricalVersionComponent
            fileId={fileId}
            fileKey={fileKey}
            flowBusinessId={flowBusinessIdMap.supportingFile}
          />
        )}
        {rowColumn.tagValue !== null && (
          <FileStatusDiv status={rowColumn.tagValue} />
        )}
      </div>
    );
  };

  returnTag = (rowColumn) => {
    return <ProposedChangeTagDiv status={rowColumn.value} />;
  };

  returnButton = (row, column, rowIndex, isSupportingFile) => {
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;

    return (
      <div className="buttonTd-content">
        {tagValue !== fileTags.new && (
          <>
            <Buttons
              color={column.approveColor}
              size="small"
              onClick={(e) => column.onApproveClick(e)}
              disabled={row.isApproveDisabled}
              rowindex={rowIndex}
            >
              {column.approveText}
            </Buttons>
            <UploadFile
              fileInputRef={row.replaceRef}
              isMultiple={false}
              handleFileSelect={this.props.handleFileSelect}
              rowindex={rowIndex}
              isSupportingFile={isSupportingFile}
            />
          </>
        )}
        <Buttons
          color={column.returnColor}
          size="small"
          onClick={column.onReturnClick(rowIndex)}
          disabled={row.isReturnDisabled || tagValue === fileTags.deleted}
          rowindex={rowIndex}
        >
          {column.returnText}
        </Buttons>
      </div>
    );
  };

  render() {
    const {
      data,
      columns,
      procedureNoteMaxLength,
      fileSectionDisabled,
      optionList,
      isShowReturnReason,
      currentRole,
    } = this.props;

    const typeToClassName = {
      link: "linkTd",
      input: "inputTd",
      default: "textTd",
      tag: "tagTd",
      select: "selectTd",
      button: "buttonTd",
      fileName: "fileNameTd",
    };

    return (
      <>
        <tbody className="update-tbody">
          {data.map((row, rowIndex) => {
            return (
              <tr
                key={row.data.find((item) => item.name === "lineId").value}
                className={`table-tr ${this.returnTrErrorClass(row)}`}
                id={`table-tr-${rowIndex}`}
              >
                {columns.map((column, columnIndex) => {
                  if (!column.isShow) {
                    return;
                  }
                  const rowColumn = row.data.find(
                    (x) => x.name === column.dataName
                  );
                  if (column.type === "checkbox") {
                    return this.returnCheckBox(column, row);
                  } else {
                    return (
                      <td
                        key={column.dataName}
                        style={{
                          display: this.columnShowStyle(column),
                        }}
                        className={`${
                          typeToClassName[column.type] ||
                          typeToClassName.default
                        } ${column.dataName + "Td"}`}
                      >
                        {column.type === "link" &&
                          this.returnLink(rowColumn, column, rowIndex, row)}
                        {column.type === "fileName" &&
                          this.returnFileName(rowColumn, column, row)}
                        {column.type === "input" &&
                          this.returnInput(
                            row,
                            rowColumn,
                            column,
                            rowIndex,
                            isShowReturnReason,
                            procedureNoteMaxLength,
                            fileSectionDisabled
                          )}
                        {column.type === "select" &&
                          this.returnSelect(
                            currentRole,
                            rowColumn,
                            column,
                            row,
                            rowIndex,
                            optionList
                          )}
                        {column.type === "button" &&
                          this.returnButton(row, column, rowIndex, true)}
                        {column.type === "tag" && this.returnTag(rowColumn)}
                        {this.judgeIsShowText(column) && rowColumn.value}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}
