import RoutingComponent from "../../common/RoutingComponent";
import MenuModel from "../../../utils/model/MenuModel";
import appService from "../../../service/app";
import AppSetting from "../../../config/AppSetting";


export default class MenuComponent extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = { menuData:[], menuElement: [] };
  }

  componentDidMount() {
    this._showLoading();
    this.loadMenu();
  }

  loadMenu() {
    const menus= AppSetting.localStorageValue.menus.get();
    if(menus!== undefined && menus.length > 0)
    {
      this.setState({ menuData: menus });
      this._hideLoading();
      return;
    }

    appService.getMenu(
      this.appSetting.localStorageValue.user.get(),
      this._onSuccess,
      this._onError
    );
  }

  _onSuccess = (response) => {
    let menuList = [];
    response.forEach((element) => {
      if (element.active) {
        menuList.push(new MenuModel({
          ...element,
          iconKey: element.key,
        }));
      }
    });

    menuList = menuList.sort((a, b) => (a.order > b.order && 1) || -1);
    this._hideLoading();
    AppSetting.localStorageValue.menus.set(menuList)

    this.setState({ menuData: menuList });
  };

  _onError = (response) => {
    console.log(response);
  };
}
