import React, { createRef } from "react";
import PMPExcludedProcess from "..";
import NormalSpin from "../../../../base/basicComponents/spin";
import ApplyPMPExcludedComplete from "./applyPMPExcludedComplete";
import ApprovePMPExcludedComplete from "./approvePMPExcludedComplete";
import ApprovePMPExcludedPending from "./approvePMPExcludedPending";

const applyPMPExcludedRef = createRef();
export default class ApplyPMPExcluded extends PMPExcludedProcess {
  render() {
    const {
      procedureStepStatus,
      procedureStatus,
      receiveDate,
      requestByName,
      rejectReason,
      rejectStatus,
      loading,
    } = this.state.pageState;

    const isApplyComplete =
      this.isRevisePage && procedureStepStatus === "Complete";
    const isApprovePending =
      !this.isRevisePage && procedureStepStatus === "Pending";
    const isApproveComplete =
      !this.isRevisePage && procedureStepStatus === "Complete";
    const {
      taskInfoData,
      procedureInfoData,
      ppoList,
      fileTableData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;
    const serverPathValue =
      this.state.serverPathSectionState !== undefined
        ? this.state.serverPathSectionState.serverPathValue
        : "";
    const savedInRadioValue =
      this.state.savedInSectionState !== undefined
        ? this.state.savedInSectionState.savedInRadioValue
        : null;

    const replaceRegex = /(\n\r|\r\n|\r|\n)/g;
    let newRejectReason = "";
    if (rejectReason !== undefined && rejectReason !== null) {
      newRejectReason = rejectReason.replace(replaceRegex, "<br/>");
    } else {
      newRejectReason = rejectReason;
    }

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        children={
          <>
            {isApplyComplete && (
              <ApplyPMPExcludedComplete
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  procedureStatus,
                  receiveDate,
                  requestByName,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                shareTaskData={shareTaskData}
                supportingTableData={this.state.supportingTableData}
                ref={applyPMPExcludedRef}
                applyPMPExcludedRef={applyPMPExcludedRef}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
              />
            )}
            {isApprovePending && (
              <ApprovePMPExcludedPending
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  procedureStatus,
                  receiveDate,
                  requestByName,
                  newRejectReason,
                  rejectStatus,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                shareTaskData={shareTaskData}
                supportingTableData={this.state.supportingTableData}
                ref={applyPMPExcludedRef}
                applyPMPExcludedRef={applyPMPExcludedRef}
                handleReturnSubmitData={this.handleReturnSubmitData}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                closePage={this.closePage}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
              />
            )}
            {isApproveComplete && (
              <ApprovePMPExcludedComplete
                params={{
                  ProcedureTaskId: this.taskID,
                  ProcedureID: this.procedureID,
                  ProcedureStepID: this.procedureStepID,
                }}
                pageProps={{
                  procedureStatus,
                  receiveDate,
                  requestByName,
                  newRejectReason,
                }}
                sectionProps={{
                  taskInfoData,
                  procedureInfoData,
                  ppoList,
                  fileTableData,
                  jbProcedureSavedIn,
                }}
                serverPathValue={serverPathValue}
                savedInRadioValue={savedInRadioValue}
                shareTaskData={shareTaskData}
                supportingTableData={this.state.supportingTableData}
                ref={applyPMPExcludedRef}
                applyPMPExcludedRef={applyPMPExcludedRef}
                actionID={this.actionID}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                setProcedureFileData={this.setProcedureFileData}
                setSupportingFileData={this.setSupportingFileData}
                setTaskInfoData={this.setTaskInfoData}
                setProcedureInfoData={this.setProcedureInfoData}
                setShareTaskData={this.setShareTaskData}
              />
            )}
          </>
        }
      />
    );
  }
}
