class MyRequestTableFilterMap {
  constructor() {
    this.map = new Map();
    /* this.map.set(dataIndex, dbName); */
    this.map.set("taskGroupName", "TaskGroupID");
    this.map.set("categoryName", "CategoryName");
    this.map.set("taskLabelName", "TaskLabelName");
    this.map.set("highlyRegulated", "HighlyRegulated");
    this.map.set("triggerProcessName", "TriggerProcess");
    this.map.set("currentProcessor", "CurrentProcessor");
    this.map.set("processInitiatorName", "ProcessInitiator");
  }

  set(key, value) {
    this.map.set(key, value);
  }

  get(key) {
    return this.map.get(key);
  }
}

const myRequestListTableFilterMap = new MyRequestTableFilterMap();

export default myRequestListTableFilterMap;
