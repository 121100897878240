import React from "react";
import BaseComponent from "../../common/BaseComponent";
import { Spin } from "antd";
import "./style/index.scss";

export default class NormalSpin extends BaseComponent {
  render() {
    const { size, delay, spinning, tip, children, indicator } = this.props;
    return (
      <div className="spinContainer">
        <Spin
          size={size}
          delay={delay}
          spinning={spinning}
          tip={tip}
          indicator={indicator}
        >
          {children}
        </Spin>
      </div>
    );
  }
}
