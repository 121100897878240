import React, { createRef } from "react";
import RoutingComponent from "../../../../base/common/RoutingComponent";
import styles from "../index.module.scss";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import ApproveProcedureInformation from "../../../sections/procedureInfoSections/approveProcedureInfoSection";
import TaskInformation from "../../../sections/taskInfoSections";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const procedureInfoRef = createRef();
export default class ApprovePPOCompleted extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = {
      procedureInfoState: {},
      modalState: {
        isReturnModalOpen: false,
        isRejectModalOpen: false,
        isModalOpen: false,
        modalOkText: "",
        modalCancelText: "",
        modalChildContent: "",
        handleModalConfirm: () => {},
        handleModalCancel: () => {},
      },
    };
    this.procedureTaskId = this.props.procedureTaskId;
    this.procedureID = this.props.procedureID;
    this.procedureStepID = this.props.procedureStepID;
  }

  render() {
    const {
      isApprovedCompletedPage,
      isSubmittedCompletedPage,
      isReturnedCompletedPage,
      isRejectedCompletedPage,
      procedureInformationData,
      supportingTableData,
      jbProcedureSavedIn,
      taskInformationData,
    } = this.props;
    const { procedureTableData, clientServerPath, savedInRadioValue } =
      this.props.procedureFileData;
    const { receiveDate, requestByName } =
      procedureInformationData.pageHeadState;
    const { rejectReason } = procedureInformationData;
    const { modalState } = this.state;
    const procedureData = {
      ...this.props.procedureInformationData.procedureInfoState,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
    };
    const params = {
      TaskId: this.procedureTaskId,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    return (
      <div className={styles.body}>
        <div className={styles.header}>
          <PageHeader
            title={
              isApprovedCompletedPage || isReturnedCompletedPage || isRejectedCompletedPage
                ? "Approve Primary Procedure Owner"
                : "Change Primary Procedure Owner"
            }
            receiveDate={receiveDate}
            requestBy={requestByName}
            status="Complete"
          />
        </div>
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInformationData} />}
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setTaskInfoData,
              this.setError,
              [StepBusinessType.taskInfo]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlyProcedureFileSection
              procedureFileData={procedureTableData}
              procedureFileRef={procedureFileRef}
              clientServerPath={clientServerPath}
              savedInRadioValue={savedInRadioValue}
              jbProcedureSavedIn={jbProcedureSavedIn}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setProcedureFileData,
              this.setError,
              [StepBusinessType.procedureFile]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlySupportingFileSection
              supportingFileRef={supportingFileRef}
              supportingFileData={supportingTableData}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setSupportingFileData,
              this.setError,
              [StepBusinessType.supportingFile]
            );
          }}
        />
        <ProcedureBlock
          className={styles.ProcedureInfoSection}
          child={
            <ApproveProcedureInformation
              ref={procedureInfoRef}
              procedureData={procedureData}
              submitData={procedureData}
              noteDisabled={true}
              isPPODisabled={true}
              title="Procedure Information"
              className={styles.approveProcedureInformation}
              closePage={this.props.closePage}
              isShowActionBtns={false}
              isSubmittedCompletedPage={isSubmittedCompletedPage}
              isReturnedCompletedPage={isReturnedCompletedPage}
              modalState={modalState}
            />
          }
          isShowArrow={true}
          isOpen={true}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly shareTaskData={this.props.shareTask} />
          }
          isShowArrow={true}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        {!!rejectReason && (
          <div className={styles.rejectReasonBlock}>
            <span className={styles.rejectReasonTip}>Reject Reason</span>
            <p className={styles.rejectReasonContent}>{rejectReason}</p>
          </div>
        )}
      </div>
    );
  }
}
