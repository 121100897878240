import { createRef } from "react";
import {
  deepClone,
  fileTags,
  getTimeZoneString,
  proposedChangeTags,
} from "../../../../../publicDictionaryValues";
import ReviseProcedureFileManagement from "../../../../procedureFileSections/fields/procedureFileManagementFields/reviseProcedureFileManagement";
import ReviseUpdateSupportingFileTable from "../../supportingFileTable/reviseUpdateSupportingFileTable";
import reasonEditDefaultIcon from "../../../../../../assets/image/reasonEditIcon.png";
import styles from "../../../index.module.scss";

const procedureReplaceFileRef = createRef();
export default class ReviseUpdateSupportingFileManagement extends ReviseProcedureFileManagement {
  componentDidMount() {
    if (
      this.props.fileTableRef !== undefined &&
      this.props.fileTableRef.current !== null
    ) {
      this.props.fileTableRef.current.updateDataList(this.props.data);
      this.setInitSaveSubmitData(this.props.data);
    }
  }

  handleReturnShareBtn = () => {
    return null;
  };

  /**
   * function about click cancel button in each row
   * @param {obj} e
   */
  cancelRowFile = (e) => {
    const dataList = this.getDataList();
    const index = e.target.attributes.rowindex.nodeValue;
    let newList = deepClone(dataList);
    newList[index].data.find((item) => item.name === "fileName").tagValue =
      fileTags.cancelled;
    newList[index].data.find((item) => item.name === "isReviewed").value = true;
    if (newList[index].isError) {
      newList[index].isError = false;
    }
    this.updateDataList(newList);
  };

  /**
   * function about add data in table when upload files
   * @param {array} dataList
   * @param {string} fileId
   * @param {string} fileName
   */
  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    const newFileItme = {
      data: [
        { name: "lineId", value: lineId },
        { name: "fileId", value: fileId },
        { name: "supportingFileID", value: 0 },
        {
          name: "supportingFileStepID",
          value: null,
        },
        { name: "fileKey", value: fileKey },
        {
          name: "fileName",
          value: fileName,
          tagValue: fileTags.new,
        },
        {
          name: "uploadedDate",
          value: this._formateDate(getTimeZoneString(this._getDate())),
        },
        { name: "uploadByName", value: this.user.identityUserInfo.englishName },
        {
          name: "uploadById",
          value: this.user.identityUserInfo.internalUserID,
        },
        { name: "proposedChange", value: proposedChangeTags.new },
        { name: "status", value: null },
        {
          name: "returnReason",
          value: null,
          isDisabled: true,
          isShowReasonLog: false,
        },
        {
          name: "attachment",
          value: null,
          isDisabled: false,
          link: null,
          fileId: null,
        },
        { name: "newVersion", value: null, link: null, fileId: null },
        { name: "note", value: null },
        {
          name: "previousStatus",
          value: null,
        },
        {
          name: "previousFileTag",
          value: null,
        },
        {
          name: "isReviewed",
          value: false,
        },
      ],
      checked: false,
      isApproveDisabled: false,
      isReturnDisabled: false,
      isReturned: false,
      isError: false,
      replaceRef: procedureReplaceFileRef,
      attachmentEditIcon: reasonEditDefaultIcon,
      isDelete: false,
      isUpdate: false,
    };
    dataList.unshift(newFileItme);
  };

  /**
   * return table columns
   * @param {*} obj
   * @returns
   */
  handleReturnColumns = (obj) => {
    const { isDisableAll, reasonRef } = obj;
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: isDisableAll,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Proposed Change",
        dataName: "proposedChange",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Return Reason",
        dataName: "returnReason",
        type: "input",
        ref: reasonRef,
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
        isDisabled: isDisableAll,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
      },
      {
        name: "New Version",
        dataName: "newVersion",
        type: "link",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Replace",
        returnText: "Delete",
        cancelText: "Cancel",
        approveColor: "blue",
        returnColor: "greyRed",
        cancelColor: "blue",
        onApproveClick: this.replaceRowFile,
        onReturnClick: () => this.deleteRowFile,
        onCancelClick: () => this.cancelRowFile,
        isShow: !isDisableAll && !this.props.isCopyPage,
      },
    ];
  };

  handleReturnFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      className,
      currentRole,
      downloadFile,
      isDisableAll,
      initProcedureTableData,
      setIsTableChange,
      cancelIsTableChange,
      fileTableStatus,
      optionList,
      isTableBorderError,
      isFileTableShow,
    } = obj;
    return (
      <div className={`${isTableBorderError ? styles.supporting_border_error : ""}`}>
        <ReviseUpdateSupportingFileTable
          ref={fileTableRef}
          columns={columns}
          procedureNoteMaxLength={1000}
          procedureNoteSize="small"
          inputOnChange={this.inputOnChange}
          fileTableRef={fileTableRef}
          className={className}
          optionList={optionList}
          currentRole={currentRole}
          downloadFile={downloadFile}
          isComplete={isDisableAll}
          initProcedureTableData={initProcedureTableData}
          setIsTableChange={setIsTableChange}
          cancelIsTableChange={cancelIsTableChange}
          fileTableStatus={fileTableStatus}
          handleFileSelect={
            this.handleReviseProcedureFileManagementRowReplaceFileSelect
          }
          data={this.props.data}
          isFileTableShow={isFileTableShow}
        />
      </div>
    );
  };
}
