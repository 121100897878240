import React, { Component } from "react";
import editIcon from "../../../../../../assets/image/reasonEditIcon.png";
import editHoverIcon from "../../../../../../assets/image/reasonEditHoverIcon.png";
import NormalToolTip from "../../../../../../base/basicComponents/toolTip";
import "../index.scss";
import applyPMPExcludedService from "../../../../../../service/applyPmpExcluded";

export default class PMPExcludedEditIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editIcon: editIcon,
      isModalOpen: false,
      isEditTipOpen: false,
    };
  }

  /**
   * function about change icon to hover icon when hovering edit icon
   */
  handleEditIconHover = () => {
    this.setState({ editIcon: editHoverIcon });
  };

  /**
   * function about change icon to default icon when leaving edit icon
   */
  handleEditIconDefault = () => {
    this.setState({ editIcon: editIcon });
  };

  /**
   * success callback about check whether current pmp exlcuded is pending approve
   * @param {object} response
   */
  checkApplyPmpExcludedEditSuccess = (response) => {
    if (!response.result) {
      this.setState({ isEditTipOpen: true });
    } else {
      this.setState({ isEditTipOpen: false });
      this.props.handleModalOpen();
    }
  };

  /**
   * error callback about check whether current pmp exlcuded is pending approve
   * @param {object} response
   */
  checkApplyPmpExcludedEditError = (error) => {
    console.log(error);
  };

  /**
   * function about click edit icon
   */
  handleEditClick = () => {
    const { procedureTaskId, procedureId, procedureAccountId, pmpExcluded } = this.props;
    const param = {
      procedureTaskId: procedureTaskId,
      procedureId: procedureId,
      procedureAccountId: procedureAccountId,
      IsExcluded: pmpExcluded,
    };
    applyPMPExcludedService.checkApplyPmpExcludedEdit(
      param,
      this.checkApplyPmpExcludedEditSuccess,
      this.checkApplyPmpExcludedEditError
    );
  };

  render() {
    const { editIcon, isEditTipOpen } = this.state;
    return (
      <NormalToolTip
        element={
          <div className={this.props?.disabled ? "setCursor" : ""}>
            <div
              tabIndex="-1"
              onMouseEnter={this.handleEditIconHover}
              onMouseLeave={this.handleEditIconDefault}
              onBlur={() => this.setState({ isEditTipOpen: false })}
              onClick={this.handleEditClick}
              className={`editIcon ${this.props?.disabled && 'pmpExcludedDisable'}`}
            >
              <img src={editIcon} alt="edit icon" />
            </div>
          </div>
        }
        title="Apply for PMP Excluded Process is pending approval."
        open={isEditTipOpen}
        placement="right"
      />
    );
  }
}
