import React, { Component } from "react";
import delegateImg from "../../../../../../assets/image/delegate.png";
import "../index.scss";

export default class DelegationIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editIcon: delegateImg,
      isModalOpen: false,
      isEditTipOpen: false,
    };
  }

  /**
   * function about change icon to hover icon when hovering edit icon
   */
  handleEditIconHover = () => {
    this.setState({ editIcon: delegateImg });
  };

  /**
   * function about change icon to default icon when leaving edit icon
   */
  handleEditIconDefault = () => {
    this.setState({ editIcon: delegateImg });
  };

  /**
   * function about click edit icon
   */
  handleEditClick = () => {
    this.props.handleModalOpen();
  };

  render() {
    const { editIcon } = this.state;
    const { disabled } = this.props;
    return (
      <div
        onMouseEnter={!disabled && this.handleEditIconHover}
        onMouseLeave={!disabled && this.handleEditIconDefault}
        onBlur={() => this.setState({ isEditTipOpen: false })}
        onClick={this.handleEditClick}
        className={`editIcon ${disabled && "editDisabled"}`}
      >
        <img src={editIcon} alt="edit icon" />
      </div>
    );
  }
}
