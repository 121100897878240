import { React, createRef } from "react";
import ReadOnlyText from "../../../../base/basicComponents/readOnly-text";
import Buttons from "../../../../base/basicComponents/button/index";
import { NormalTextArea } from "../../../../base/basicComponents/input";
import BaseComponent from "../../../../base/common/BaseComponent";
import requiredWarning from "../../../../assets/image/required-warning.png";
import styles from "./index.module.scss";
import { FileUploadDragger } from "../../../../base/basicComponents/uploadFile";
import appService from "../../../../service/app";
import LostPermissionModal from "../../lostPermissionModal";

const attachmentRef = createRef();
const attachmentInputRef = createRef();
class TlReviewProcedureSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      reviewResultText: "",
      submitDisabled: false,
      reviewResultStatus: "normal",
      fileList: [],
      noPermission: false,
    };
  }

  showInputError = () => {
    this.setState({
      reviewResultStatus: "error",
    });
  };

  setDefault = () => {
    this.setState({
      reviewResultStatus: "normal",
      reviewResultText: "",
    });
    attachmentRef.current.clearFileList();
  };

  handleReasonTextChange = (value) => {
    this.setState({
      reviewResultStatus: "normal",
      reviewResultText: value,
    });
  };

  handleFileListChange = (list) => {
    this.setState({ fileList: list });
  };

  _checkStepPermissionSuccess = (result, reviewResultText, fileList) => {
    this.props.hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.props.onSubmit(reviewResultText, fileList);
  };

  checkStepPermission = (reviewResultText, fileList) => {
    this.props.showLoading();
    if (this.props.params) {
      const param = {
        taskID: this.props.params.ProcedureTaskId,
        procedureID: this.props.params.ProcedureID,
        procedureStepID: this.props.params.ProcedureStepID,
        parentPage: null,
      };
      appService.checkStepPermission(
        param,
        (response) =>
          this._checkStepPermissionSuccess(
            response,
            reviewResultText,
            fileList
          ),
        (response) => console.log(response.message)
      );
    } else {
      this.props.onSubmit(reviewResultText, fileList);
      this.props.hideLoading();
    }
  };

  render() {
    const { onCancel, showLoading, hideLoading, isInModal, reviewedDate } =
      this.props;

    const { reviewResultText, submitDisabled, reviewResultStatus, fileList } =
      this.state;

    return (
      <div className={styles.tlReviewProcedureSectionDiv}>
        <div className={styles.tlReviewProcedureSectionTop}>
          <span className={`${styles.tlReviewProcedureSectionTitle} fs-18`}>
            Review Information
          </span>
        </div>
        <div className={styles.tlReviewProcedureSectionContent}>
          <div className={styles.fieldArea}>
            <div className={styles.tlReviewField}>
              <span className={styles.filedTilte}>Reviewed Date</span>
              <ReadOnlyText
                value={isInModal ? this.getCurrentDate() : reviewedDate}
                marginLeft="-1rem"
                width={isInModal ? "25rem" : "30rem"}
              />
            </div>
            <div className={styles.tlReviewField}>
              <span className={styles.filedTilte}>
                Review Result
                <img
                  src={requiredWarning}
                  alt="required"
                  className={styles.requiredWarning}
                />
              </span>
              <NormalTextArea
                width={isInModal ? "25" : "30"}
                value={reviewResultText}
                onChange={(e) => this.handleReasonTextChange(e.target.value)}
                status={reviewResultStatus}
                className="normalTextArea"
              />
            </div>
          </div>
          <div className={styles.attachmentArea}>
            <span className={styles.filedTilte}>Attachment</span>
            <FileUploadDragger
              ref={attachmentRef}
              className={styles.fileDragger}
              fileInputRef={attachmentInputRef}
              isShowBox={true}
              isMultiple={true}
              handleFileListChange={this.handleFileListChange}
              isShowDeleteBtn={true}
              openLoading={showLoading}
              hideLoading={hideLoading}
              fileIds={[]}
              fileNames={[]}
              isDownload={false}
              fileNameType="text"
            />
          </div>
        </div>
        <div className={styles.buttonDiv}>
          <Buttons color="grey" size="middle" onClick={onCancel}>
            Cancel
          </Buttons>
          <Buttons
            color="blue"
            disabled={submitDisabled}
            size="middle"
            onClick={() => this.checkStepPermission(reviewResultText, fileList)}
          >
            Submit
          </Buttons>
        </div>
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}

export default TlReviewProcedureSection;
