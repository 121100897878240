import React, { useEffect, useRef } from "react";
import { useMount, useSetState } from "ahooks";
import TopFilterBase from "../../../../commonComponents/listBase/TopFilterBase";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import Buttons from "../../../../../base/basicComponents/button";
import changeHistoryService from "../../../../../service/changeHistory";
import styles from "../../../toDoList/toDoListSections/topFilter/index.module.scss";
import "../../../../../assets/css/style.css";

export default function TopFilter(props) {
  const { sortList, handleChange, handleDeSelect, handleFilterOption } =
    TopFilterBase();

  const isFirstRender = useRef(true);

  /* state about selects' data */
  const [filterOptionList, setFilterOptionList] = useSetState({
    processCategory: [],
    processStatus: [],
  });

  /* state about select's selected data */
  const [selectOptions, setSelectOptions] = useSetState({
    processCategory: [],
    processStatus: [],
  });

  const handleOptionList = (list, isSort) => {
    let tempOptionList = [];
    list.map((item, index) => {
      let optionItem = { name: item.value, value: item.key, index: index };
      tempOptionList.push(optionItem);
    });

    return tempOptionList;
  };

  const onSuccess = (response, type) => {
    let option;
    switch (type) {
      case "processCategory":
        option = handleOptionList(response.data);
        setFilterOptionList({ processCategory: option });
        break;
      case "processStatus":
        option = handleOptionList(response.data);
        setFilterOptionList({
          processStatus: option,
        });
        break;
      default:
        break;
    }
  };

  /* callback about when call api error */
  const onError = (error) => {
    console.log(error);
  };

  const handleFilterClick = () => {
    props.handleFilterClick(filterOptionList, selectOptions);
  };

  const handleClearClick = () => {
    const defaultOption = {
      processStatus: [],
      processCategory: [],
    };
    setSelectOptions(defaultOption);
    filterOptionList.processCategory.sort((s, t) => sortList(s, t));
    filterOptionList.processStatus.sort((s, t) => sortListByIndex(s, t));
    props.handleFilterClick(filterOptionList, defaultOption);
  };

  const handleSelectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    const allOptionsValue = optionList.map(item => item.value);
    const isProcessStatus = filterName === "processStatus";

    setSelectOptions({
      [filterName]: allOptionsValue,
    });
    setFilterOptionList({
      [filterName]: [
        ...handleChange(optionList, allOptionsValue, isProcessStatus),
      ],
    });
  }

  const handleDeselectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    const isProcessStatus = filterName === "processStatus";

    setSelectOptions({
      [filterName]: [],
    });
    setFilterOptionList({
      [filterName]: isProcessStatus
        ? [...optionList.sort((s, t) => sortListByIndex(s, t))]
        : [...optionList.sort((s, t) => sortList(s, t))]
    });
  }

  const getProcessStatus = () => {
    changeHistoryService.getProcessStatusList(
      onSuccess,
      onError,
      "processStatus"
    );
  };

  const getProcessCategory = () => {
    const para = {
      ProcedureTaskId: props.procedureTaskId,
      HistoryType: props.tab,
    };
    changeHistoryService.getCategoryList(
      para,
      onSuccess,
      onError,
      "processCategory"
    );
  };

  /* function about sort filter list by alphabetical order */
  const sortListByIndex = (s, t) => {
    const a = s.index;
    const b = t.index;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  /* function about when page init get filter selects option list */
  useMount(() => {
    getProcessStatus();
    getProcessCategory();
    const filterOptionPara = {
      processStatus: [],
      processCategory: [],
    };
    const selectOptionsPara = {
      processStatus: [],
      processCategory: [],
    };
    props.handleFilterClick(filterOptionPara, selectOptionsPara);
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getProcessStatus();
    getProcessCategory();

    setSelectOptions({
      processStatus: [],
      processCategory: [],
    });
    const filterOptionPara = {
      processStatus: [],
      processCategory: [],
    };
    const selectOptionsPara = {
      processStatus: [],
      processCategory: [],
    };
    props.handleFilterClick(filterOptionPara, selectOptionsPara);
  }, [props.tab]);

  return (
    <div className={styles.topFilterContainer}>
      <div className={styles.topFilterSelects}>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Category"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.processCategory}
            optionList={filterOptionList.processCategory}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.processCategory;
              setSelectOptions({
                processCategory: e,
              });
              setFilterOptionList({
                processCategory: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.processCategory];
              setSelectOptions({
                processCategory: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.processCategory.length > 0}
            handleSelectAll={() => handleSelectAll('processCategory')}
            handleDeselectAll={() => handleDeselectAll('processCategory')}
          />
        </div>
        {
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Process Status"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.processStatus}
              optionList={filterOptionList.processStatus}
              className="topFilterSelectInput"
              mode="multiple"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                const optionList = filterOptionList.processStatus;
                setSelectOptions({
                  processStatus: e,
                });
                setFilterOptionList({
                  processStatus: [
                    ...handleChange(optionList, e, true),
                    ...optionList.sort((s, t) => sortListByIndex(s, t)),
                  ],
                });
              }}
              handleDeSelect={(e) => {
                const selectList = [...selectOptions.processStatus];
                setSelectOptions({
                  processStatus: handleDeSelect(e, selectList),
                });
              }}
              handleFilterOption={(input, option) =>
                handleFilterOption(input, option)
              }
              isNeedSelectAll={filterOptionList.processStatus.length > 0}
              handleSelectAll={() => handleSelectAll('processStatus')}
              handleDeselectAll={() => handleDeselectAll('processStatus')}
            />
          </div>
        }
      </div>
      <div className={styles.topFilterBtns}>
        <Buttons color="blue" size="middle" onClick={handleFilterClick}>
          Filter
        </Buttons>
        <Buttons color="grey" size="middle" onClick={handleClearClick}>
          Clear
        </Buttons>
      </div>
    </div>
  );
}
