import React from "react";
import { Modal } from "antd";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../../base/basicComponents/select";
import { NormalTextArea } from "../../../../../../base/basicComponents/input";
import {
  pmpExcludedReasonSelectOptions,
  pmpExcludedReasonSelects,
} from "../../../../../publicDictionaryValues";
import "../index.scss";
import procedureListService from "../../../../../../service/procedureList";
import BaseComponent from "../../../../../../base/common/BaseComponent";
export default class PMPExcludedModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      initExcludedSelectValue: this.props.pmpExcluded,
      excludedSelectValue: this.props.pmpExcluded,
      reasonSelectValue: null,
      reasonText: null,
      reasonSelectStatus: "",
      isShowEditModal: true,
      isShowMsgContent: false,
      msgContent: "",
      okText: "Submit for Approval",
      cancelText: "Cancel",
      actionType: "edit",
    };
    this.excludedSelectOptions = [
      { value: "No", name: "No" },
      { value: "Yes", name: "Yes" },
    ];
  }

  /**
   * reset state
   */
  handleSetInitState = () => {
    this.setState({
      initExcludedSelectValue: this.props.pmpExcluded,
      excludedSelectValue: this.props.pmpExcluded,
      reasonSelectValue: null,
      reasonText: null,
      reasonSelectStatus: "",
      isShowEditModal: true,
      isShowMsgContent: false,
      msgContent: "",
      okText: "Submit for Approval",
      cancelText: "Cancel",
      actionType: "edit",
    });
    this.prevState = {};
  };

  /**
   * when close modal reset state
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isModalOpen !== this.props.isModalOpen &&
      this.props.isModalOpen === false
    ) {
      this.handleSetInitState();
    }
    if (prevProps.pmpExcluded !== this.props.pmpExcluded) {
      this.setState({
        initExcludedSelectValue: this.props.pmpExcluded,
        excludedSelectValue: this.props.pmpExcluded,
      });
    }
  }

  /**
   * function about change pmp excluded select
   * @param {string} value select value
   */
  handleExcludedChange = (value) => {
    this.setState({ excludedSelectValue: value });
    if (!value) {
      this.setState({
        reasonSelectValue: null,
        reasonText: null,
        reasonSelectStatus: "",
        reasonInputStatus: "",
      });
    }
  };

  /**
   * function about change reason select
   * @param {boolean} value - select value
   */
  handleReasonSelectChange = (value) => {
    this.setState({ reasonSelectValue: value, reasonSelectStatus: "" });
  };

  /**
   * function about change reason textarea value
   * @param {string} value - write value
   */
  handleReasonTextChange = (value) => {
    this.setState({ reasonText: value, reasonInputStatus: "" });
  };

  /**
   * function about manatory check when clicking submit in edit modal
   */
  handleEditSubmitMandatoryCheck = () => {
    if (this.state.reasonSelectValue === null) {
      this.setState({ reasonSelectStatus: "error" });
    }
    if (
      (this.state.reasonText === null || this.state.reasonText === "") &&
      (this.state.reasonSelectValue === pmpExcludedReasonSelects.otherReason ||
        (this.state.initExcludedSelectValue === "Yes" &&
          this.state.excludedSelectValue === "No"))
    ) {
      this.setState({ reasonInputStatus: "error" });
    }
  };

  /**
   * success callback when submit pmp excluded change
   */
  handleSubmitPMPExcludedSuccuess = () => {
    this.props.handleEditDisabled();
    this._alertSuccess("Submit Successful");
    if (this.state.initExcludedSelectValue === "Yes") {
      const list = [...this.props.dataList];
      list.find(
        (item) => item.procedureTaskId === this.props.procedureTaskId
      ).pmpExcluded = false;
      this.props.setProcedureIdToList(list);
    }
  };

  /**
   * error callback when submit pmp excluded change
   */
  handleSubmitPMPExcludedError = (error) => {
    console.log(error);
    this._alertError("Submit Error!");
  };

  /**
   * function about click confirm button in submit confirm modal
   */
  handleSubmitModalConfirm = () => {
    const { excludedSelectValue, reasonSelectValue, reasonText } = this.state;
    const param = {
      procedureTaskId: this.props.procedureTaskId,
      procedureId: this.props.procedureId || "",
      isExcluded: excludedSelectValue === "Yes" ? true : false,
      excludedReasonId: reasonSelectValue,
      reason: reasonText,
    };
    procedureListService.applyPMPExcluded(
      param,
      this.handleSubmitPMPExcludedSuccuess,
      this.handleSubmitPMPExcludedError
    );
    this.props.handleCloseModal();
  };

  /**
   * function about click cancel button in submit confirm modal
   */
  handleSubmitModalCancel = () => {
    this.setState({ ...this.prevState });
  };

  /**
   * function about create submit modal
   */
  handleSetSubmitModal = () => {
    this.prevState = { ...this.state };
    this.setState({
      msgContent: <span className="fs-16">Are you sure to submit?</span>,
      okText: "Confirm",
      actionType: "submit",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about click confirm button in cancel modal
   */
  handleCancelModalConfirm = () => {
    this.props.handleCloseModal();
    this.setState({
      msgContent: "",
      okText: "Submit for Approval",
      actionType: "edit",
      isShowEditModal: true,
      isShowMsgContent: false,
    });
  };

  /**
   * function about click cancel button in cancel modal
   */
  handleCancelModalCancel = () => {
    this.setState({
      ...this.prevState,
    });
  };

  /**
   * function about create cancel modal
   */
  handleSetCancelModal = () => {
    this.setState({
      msgContent: (
        <span className="fs-16">Are you sure to quit without saving?</span>
      ),
      okText: "Confirm",
      actionType: "cancel",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about click confirm button in edit modal
   */
  handleEditSubmitConfirm = () => {
    if (this.state.excludedSelectValue === "No") {
      if (this.state.reasonText === null || this.state.reasonText === "") {
        this.handleEditSubmitMandatoryCheck();
      } else {
        this.handleSetSubmitModal();
      }
    } else {
      if (
        this.state.reasonSelectValue === null ||
        (this.state.reasonSelectValue ===
          "39EF216D-A1C2-4CDE-9C84-A23C40441BA1" &&
          (this.state.reasonText === null || this.state.reasonText === ""))
      ) {
        this.handleEditSubmitMandatoryCheck();
      } else {
        this.handleSetSubmitModal();
      }
    }
  };

  /**
   * function about click cancel button in edit modal
   */
  handleEditModalCancel = () => {
    this.prevState = { ...this.state };
    this.setState({
      msgContent: (
        <span className="fs-16">Are you sure to quit without saving?</span>
      ),
      okText: "Confirm",
      actionType: "cancel",
      isShowEditModal: false,
      isShowMsgContent: true,
    });
  };

  /**
   * function about return submit method for modal component
   * @returns
   */
  handleSubmit = () => {
    switch (this.state.actionType) {
      case "edit":
        return this.handleEditSubmitConfirm();
      case "submit":
        return this.handleSubmitModalConfirm();
      case "cancel":
        return this.handleCancelModalConfirm();
      default:
        return;
    }
  };

  /**
   * function about return cancel method for modal component
   * @returns
   */
  handleCancel = () => {
    switch (this.state.actionType) {
      case "edit":
        return this.handleEditModalCancel();
      case "submit":
        return this.handleSubmitModalCancel();
      case "cancel":
        return this.handleCancelModalCancel();
      default:
        return this.handleCancelClick();
    }
  };

  render() {
    const { isModalOpen } = this.props;
    const {
      initExcludedSelectValue,
      excludedSelectValue,
      reasonSelectValue,
      reasonText,
      reasonSelectStatus,
      reasonInputStatus,
      isShowEditModal,
      okText,
      cancelText,
      isShowMsgContent,
      msgContent,
      actionType,
    } = this.state;
    const isShowReasonInput =
      initExcludedSelectValue === "Yes" ||
      (excludedSelectValue === "Yes" &&
        reasonSelectValue === pmpExcludedReasonSelects.otherReason);

    const isShowReasonLabel =
      initExcludedSelectValue === "Yes" || excludedSelectValue === "Yes";
    return (
      <Modal
        open={isModalOpen}
        okText={okText}
        cancelText={cancelText}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          disabled:
            actionType === "edit" &&
            excludedSelectValue === initExcludedSelectValue,
        }}
        wrapClassName="pmpExcludedModal"
        zIndex={1201}
      >
        {isShowEditModal && (
          <div className="excludedContainer">
            <div className="excludedPart">
              <InputLabel text="PMP Excluded" className="excludedLabel" />
              <NormalSelect
                optionList={this.excludedSelectOptions}
                handleChange={this.handleExcludedChange}
                value={excludedSelectValue}
              />
            </div>
            <div className="excludedReasonPart">
              {isShowReasonLabel && (
                <InputLabel
                  text="Reason"
                  className="excludedLabel"
                  required={true}
                />
              )}
              <div className="excludedReasonComponents">
                {initExcludedSelectValue === "No" &&
                  excludedSelectValue === "Yes" && (
                    <div className="excludedReasonSelect">
                      <NormalSelect
                        optionList={pmpExcludedReasonSelectOptions}
                        handleChange={this.handleReasonSelectChange}
                        value={reasonSelectValue}
                        status={reasonSelectStatus}
                      />
                    </div>
                  )}
                {isShowReasonInput && (
                  <div className="excludedReasonInput">
                    <InputLabel text="" className="excludedLabel" />
                    <NormalTextArea
                      width="22.2"
                      value={reasonText}
                      onChange={(e) =>
                        this.handleReasonTextChange(e.target.value)
                      }
                      status={reasonInputStatus}
                      className="excludedReasonText"
                      disabled={
                        initExcludedSelectValue === "Yes" &&
                        excludedSelectValue === "Yes"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {isShowMsgContent && msgContent}
      </Modal>
    );
  }
}
