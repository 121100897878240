import BaseService from "../base";

class ChangeHistoryService extends BaseService {
  getCurrentProcedureHistory = (para, success, error) => {
    this.sendRequest("getCurrentProcedureHistory", para, success, error);
  };

  getAllProcedureHistory = (para, success, error) => {
    this.sendRequest("getAllProcedureHistory", para, success, error);
  };

  getTaskHistory = (para, success, error) => {
    this.sendRequest("getTaskHistory", para, success, error);
  };

  getCategoryList = (para, success, error, returnPara) => {
    this.sendRequest("getCategoryList", para, success, error, returnPara);
  };

  getProcessStatusList = (success, error, returnPara) => {
    this.sendRequest("getProcessStatusList", {}, success, error, returnPara);
  };

  getInitiatorList = (para, success, error, returnPara) => {
    this.sendRequest("getInitiatorList", para, success, error, returnPara);
  };

  getCurrentProcessorFilter = (para, success, error, returnPara) => {
    this.sendRequest(
      "getCurrentProcessorFilter",
      para,
      success,
      error,
      returnPara
    );
  };

  getTriggerProcessFilter = (para, success, error, returnPara) => {
    this.sendRequest(
      "getTriggerProcessFilter",
      para,
      success,
      error,
      returnPara
    );
  };

  getProcessInitiatorFilter = (para, success, error, returnPara) => {
    this.sendRequest(
      "getProcessInitiatorFilter",
      para,
      success,
      error,
      returnPara
    );
  };
}

const changeHistoryService = new ChangeHistoryService();

export default changeHistoryService;
