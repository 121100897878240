import React, { useEffect, useRef } from "react";
import { useMount, useSetState } from "ahooks";
import TopFilterBase from "../../../../commonComponents/listBase/TopFilterBase";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import Buttons from "../../../../../base/basicComponents/button";
import toDoListService from "../../../../../service/toDoListService/index";
import styles from "./index.module.scss";
import "../../../../../assets/css/style.css";

export default function TopFilter(props) {
  const {
    handleOptionList,
    sortList,
    handleChange,
    handleDeSelect,
    handleFilterOption,
  } = TopFilterBase();

  const isFirstRender = useRef(true);

  /* state about selects' data */
  const [filterOptionList, setFilterOptionList] = useSetState({
    accountAbbr: [],
    actionRequired: [],
    actionTaken: [],
    processCategory: [],
  });

  /* state about select's selected data */
  const [selectOptions, setSelectOptions] = useSetState({
    accountAbbr: [],
    actionRequired: [],
    actionTaken: [],
    processCategory: [],
  });

  const onSuccess = (response, type) => {
    let option;
    switch (type) {
      case "accountAbbr":
        option = handleOptionList(response, true);
        const filterOptionPara = {
          accountAbbr: option,
        };
        const selectOptionsPara = {
          accountAbbr: [],
        };
        props.handleFilterClick(filterOptionPara, selectOptionsPara);
        setFilterOptionList({ accountAbbr: option });
        break;
      case "actionRequired":
        option = handleOptionList(response, false);
        setFilterOptionList({
          actionRequired: option,
        });
        break;
      case "actionTaken":
        option = handleOptionList(response, false);
        setFilterOptionList({ actionTaken: option });
        break;
      case "processCategory":
        option = handleOptionList(response, false);
        setFilterOptionList({ processCategory: option });
        break;
      default:
        break;
    }
  };

  /* callback about when call api error */
  const onError = (error) => {
    console.log(error);
  };

  const handleFilterClick = () => {
    props.handleFilterClick(filterOptionList, selectOptions);
  };

  const handleClearClick = () => {
    props.setLoading(true);
    const defaultOption = {
      accountAbbr: [],
      actionRequired: [],
      actionTaken: [],
      processCategory: [],
    };
    setSelectOptions(defaultOption);
    getAccountAbbr();
    getActionRequired();
    getActionTaken();
    getProcessCategory();
  };

  const handleSelectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    const allOptionsValue = optionList.map(item => item.value);

    setSelectOptions({
      [filterName]: allOptionsValue,
    });
    setFilterOptionList({
      [filterName]: [
        ...handleChange(optionList, allOptionsValue),
      ],
    });
  }

  const handleDeselectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    
    setSelectOptions({
      [filterName]: [],
    });
    setFilterOptionList({
      [filterName]: [
        ...optionList.sort((s, t) => sortList(s, t)),
      ],
    });
  }

  const getAccountAbbr = () => {
    if (props.isOpex) {
      toDoListService.getAllAccountAbbreviations(
        2,
        onSuccess,
        onError,
        "accountAbbr"
      );
    } else {
      toDoListService.getAccountAbbrList(onSuccess, onError, "accountAbbr");
    }
  };

  const getActionRequired = () => {
    toDoListService.getActionRequiredDDL(onSuccess, onError, "actionRequired");
  };

  const getActionTaken = () => {
    toDoListService.getActionTakenDDL(onSuccess, onError, "actionTaken");
  };

  const getProcessCategory = () => {
    toDoListService.getProcessCategoryDDL(
      onSuccess,
      onError,
      "processCategory"
    );
  };

  /* function about when page init get filter selects option list */
  useMount(() => {
    getAccountAbbr();
    getActionRequired();
    getActionTaken();
    getProcessCategory();
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getAccountAbbr();
    getActionRequired();
    getActionTaken();
    getProcessCategory();

    setSelectOptions({
      accountAbbr: [],
      actionRequired: [],
      actionTaken: [],
      processCategory: [],
    });
    const filterOptionPara = {
      accountAbbr: filterOptionList.accountAbbr,
    };
    const selectOptionsPara = {
      accountAbbr: [],
    };
    props.handleFilterClick(filterOptionPara, selectOptionsPara);
  }, [props.tab]);

  return (
    <div className={styles.topFilterContainer}>
      <div className={styles.topFilterSelects}>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Account Code"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.accountAbbr}
            optionList={filterOptionList.accountAbbr}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.accountAbbr;
              setSelectOptions({
                accountAbbr: e,
              });
              setFilterOptionList({
                accountAbbr: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.accountAbbr];
              setSelectOptions({
                accountAbbr: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.accountAbbr.length > 0}
            handleSelectAll={() => handleSelectAll('accountAbbr')}
            handleDeselectAll={() => handleDeselectAll('accountAbbr')}
          />
        </div>
        {props.tab === 0 && (
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Action Required"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.actionRequired}
              optionList={filterOptionList.actionRequired}
              className="topFilterSelectInput"
              mode="multiple"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                const optionList = filterOptionList.actionRequired;
                setSelectOptions({
                  actionRequired: e,
                });
                setFilterOptionList({
                  actionRequired: [
                    ...handleChange(optionList, e),
                    ...optionList.sort((s, t) => sortList(s, t)),
                  ],
                });
              }}
              handleFilterOption={(input, option) =>
                handleFilterOption(input, option)
              }
              isNeedSelectAll={filterOptionList.actionRequired.length > 0}
              handleSelectAll={() => handleSelectAll('actionRequired')}
              handleDeselectAll={() => handleDeselectAll('actionRequired')}
            />
          </div>
        )}
        {props.tab === 1 && (
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Action Taken"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.actionTaken}
              optionList={filterOptionList.actionTaken}
              className="topFilterSelectInput"
              mode="multiple"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                const optionList = filterOptionList.actionTaken;
                setSelectOptions({
                  actionTaken: e,
                });
                setFilterOptionList({
                  actionTaken: [
                    ...handleChange(optionList, e),
                    ...optionList.sort((s, t) => sortList(s, t)),
                  ],
                });
              }}
              handleDeSelect={(e) => {
                const selectList = [...selectOptions.actionTaken];
                setSelectOptions({
                  actionTaken: handleDeSelect(e, selectList),
                });
              }}
              handleFilterOption={(input, option) =>
                handleFilterOption(input, option)
              }
              isNeedSelectAll={filterOptionList.actionTaken.length > 0}
              handleSelectAll={() => handleSelectAll('actionTaken')}
              handleDeselectAll={() => handleDeselectAll('actionTaken')}
            />
          </div>
        )}
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Process Category"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.processCategory}
            optionList={filterOptionList.processCategory}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.processCategory;
              setSelectOptions({
                processCategory: e,
              });
              setFilterOptionList({
                processCategory: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.processCategory];
              setSelectOptions({
                processCategory: handleDeSelect(e, selectList),
              });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.processCategory.length > 0}
            handleSelectAll={() => handleSelectAll('processCategory')}
            handleDeselectAll={() => handleDeselectAll('processCategory')}
          />
        </div>
      </div>
      <div className={styles.topFilterBtns}>
        <Buttons color="blue" size="middle" onClick={handleFilterClick}>
          Filter
        </Buttons>
        <Buttons color="grey" size="middle" onClick={handleClearClick}>
          Clear
        </Buttons>
      </div>
    </div>
  );
}
