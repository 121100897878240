import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import ApproveProcedureFileManagement from "../fields/procedureFileManagementFields/approveProcedureFileManagement";
import styles from "../index.module.scss";
import ApproveSavedIn from "../fields/savedInFields/approvedSavedIn";
import ApproveClientServerPath from "../fields/serverPathFields/approveSeverPath";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../publicDictionaryValues";

export default class ApproveProcedureFileSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      procedureFileTableState: {
        isTableBorderError: this.props.isTableBorderError,
        isChange: false,
      },
    };
  }

  handleReturnSectionTop = (isDisableAll) => {
    const {
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      isSavedInApproveBtnDisabled,
      isSavedInReturnBtnDisabled,
      initSavedInStatus,
      savedInReturnReasonLog,
    } = this.props.savedInProps;

    const {
      clientServerPathStatusName,
      clientServerPathStatusID,
      serverPathReturnReasonInputValue,
      isServerPathApproveBtnDisabled,
      isServerPathReturnBtnDisabled,
      clientServerPathReturnReason,
      serverPathReturnReasonLog,
      serverPathValue,
    } = this.props.serverPathProps;

    const { procedureSavedInRef, procedureServerPathRef } = this.props.refs;

    const { currentRole } = this.props.sectionProps;
    const isShow =
      savedInRadioValue !== savedInRadioValues.systemOnly &&
      savedInRadioValue !== null;

    return (
      <div className={styles.fileSectionTop}>
        <ApproveSavedIn
          ref={procedureSavedInRef}
          savedInReturnReasonInputValue={savedInReturnReasonInputValue}
          procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
          isSavedInReturnBtnDisabled={isSavedInReturnBtnDisabled}
          isSavedInApproveBtnDisabled={isSavedInApproveBtnDisabled}
          isSavedInReturnReasonDisabled={
            savedInReturnReasonInputValue === null ||
            procedureSaveInCategoryStatusID === dataStatus.approval
          }
          savedInRadioValue={savedInRadioValue}
          initSavedInStatus={initSavedInStatus}
          savedInReturnReasonLog={savedInReturnReasonLog}
          isDisableAll={isDisableAll}
        />
        {/* {savedInRadioValue !== savedInRadioValues.systemOnly && ( */}
        <ApproveClientServerPath
          isShow={isShow}
          clientServerPathReturnReason={clientServerPathReturnReason}
          serverPathStatusID={clientServerPathStatusID}
          isServerPathReturnBtnDisabled={isServerPathReturnBtnDisabled}
          isServerPathApproveBtnDisabled={isServerPathApproveBtnDisabled}
          isServerPathReturnReasonDisabled={
            serverPathReturnReasonInputValue === null ||
            clientServerPathStatusID === dataStatus.approval
          }
          initServerPathStatus={
            this.props.initServerPathSectionState.clientServerPathStatusID
          }
          serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
          isServerPathReturned={clientServerPathStatusName === "Return"}
          currentRole={currentRole}
          ref={procedureServerPathRef}
          serverPathReturnReasonLog={serverPathReturnReasonLog}
          serverPathValue={serverPathValue}
          isDisableAll={isDisableAll}
        />
        {/* )} */}
      </div>
    );
  };

  render() {
    const { savedInRadioValue } = this.props.savedInProps;
    const { currentRole, isDisableAll } = this.props.sectionProps;
    const {
      procedureRef,
      procedureFileTableRef,
      reasonRef,
      noteRef,
      procedureFileRef,
      procedureReplaceFileRef,
    } = this.props.refs;
    const isShow =
      savedInRadioValue !== savedInRadioValues.serverOnly &&
      savedInRadioValue !== null;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Procedure Files</div>

        {this.handleReturnSectionTop(isDisableAll)}

        <div
          className={`${styles.fileSectionTable} ${
            this.state.procedureFileTableState.isTableBorderError
              ? styles.border_error
              : ""
          }`}
          hidden={savedInRadioValue === savedInRadioValues.serverOnly}
        >
          {/* {savedInRadioValue !== savedInRadioValues.serverOnly && ( */}
          <ApproveProcedureFileManagement
            isShow={isShow}
            data={this.props.data}
            ref={procedureRef}
            procedureRef={procedureRef}
            fileTableRef={procedureFileTableRef}
            handleApprovedClick={(e) =>
              this.handleProcedureFileTableApprovedClick(
                e.target.attributes.rowindex.value
              )
            }
            handleReturnClick={(e) =>
              this.handleProcedureFileTableReturnClick(
                e.target.attributes.rowindex.value
              )
            }
            className="approveProcedureTable"
            pageType="approvePage"
            reasonRef={reasonRef}
            noteRef={noteRef}
            fileInputRef={procedureFileRef}
            tableReplaceFileInputRef={procedureReplaceFileRef}
            currentRole={currentRole}
            handleFileSelect={(e) => this.handleReplaceFile(e, false)}
            handleReplaceFileSelect={this.handleRowReplaceFileSelect}
            isShowExpendIcon={false}
            initProcedureTableData={[...this.props.initProcedureTableData]}
            isDisableAll={isDisableAll}
            isTableChange={this.state.procedureFileTableState.isChange}
            isTableBorderError={
              this.state.procedureFileTableState.isTableBorderError
            }
            isCopyPage={this.props.isCopyPage}
            isRejectCompletePage={this.props.isRejectCompletePage}
          />
          {/* )} */}
        </div>
      </div>
    );
  }
}
