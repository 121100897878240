import React, { createRef } from "react";
import ProcedureFileSection from "../procedureFileSection";
import ReadOnlyActiveProcedureFileManagement from "../fields/procedureFileManagementFields/readOnlyActiveProcedureFileManagement";

const procedureFileTableRef = createRef();
const procedureRef = createRef();
export default class ReadOnlyProcedureFileSection extends ProcedureFileSection {
  componentDidMount() {
    if (procedureFileTableRef.current !== null) {
      procedureFileTableRef.current.updateDataList(
        this.props.procedureFileData
      );
    }
    if (procedureRef.current !== null) {
      procedureRef.current.updateJbProcedureSaveIn(
        this.props.jbProcedureSavedIn
      );
    }
  }

  render() {
    const {
      procedureFileRef,
      clientServerPath,
      savedInRadioValue,
    } = this.props;
    return (
      <ReadOnlyActiveProcedureFileManagement
        ref={procedureRef}
        fileInputRef={procedureFileRef}
        fileTableRef={procedureFileTableRef}
        title={"Procedure Files"}
        clientPathShow={true}
        isScrollData={true}
        className="procedureFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        clientPath={clientServerPath}
        savedInRadioValue={savedInRadioValue}
        procedureSaveInValue={savedInRadioValue}
        hasSubmit={false}
      />
    );
  }
}
