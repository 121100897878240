/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import MenuComponent from "../../../control/routing/MenuComponent";
import { Layout, Menu, Skeleton } from "antd";
import styles from "./style/index.module.scss";
import ProcedureListIcon from "../../../../assets/image/procedureListIcon.png";
import TodoListIcon from "../../../../assets/image/todoListIcon.png";
import MyRequestIcon from "../../../../assets/image/myRequestIcon.png";
import ReportCenterIcon from "../../../../assets/image/reportCenterIcon (2).png";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class LeftMenu extends MenuComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: this.props.getActiveKey(),
    };
  }

  _getIcon = (iconKey) => {
    switch (iconKey) {
      case "ProcedureList":
        return <img alt="" src={ProcedureListIcon} className={styles.menuIcon}/>;
      case "ToDoList":
        return <img alt="" src={TodoListIcon} className={styles.menuIcon}/>;
      case "MyRequest":
        return <img alt="" src={MyRequestIcon} className={styles.menuIcon}/>;
      case "ReportCenter":
        return <img alt="" src={ReportCenterIcon} className={styles.menuIcon}/>;
      default:
        return "";
    }
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  selectedMenu = (url) => {
    this.route(url);
    this.setState({ ...this.state, activeKey: url });
  };

  _recurringMenu(data) {
    let elements = [];
    if (data == null || data.length === 0) {
      return elements;
    }

    data.forEach((item) => {
      if (!item.routing) {
        elements.push(
          <Menu.Item
            key={item.url}
            onClick={() => {
              this.selectedMenu(item.url);
            }}
            icon={this._getIcon(item.iconKey)}
          >
            {item.name}
          </Menu.Item>
        );
        return;
      }

      elements.push(
        <SubMenu
          key={item.name}
          title={item.name}
          icon={this._getIcon(item.iconKey)}
        >
          {this._recurringMenu(item.children)}
        </SubMenu>
      );
    });

    return <React.Fragment>{elements}</React.Fragment>;
  }

  componentDidUpdate() {
    const activeKey = this.props.getActiveKey();

    if(activeKey !== this.state.activeKey && activeKey !== '/') {
      this.setState({ ...this.state, activeKey: activeKey});
    }else if(this.state.activeKey === '/') {
      this.setState({ ...this.state, activeKey: '/ProcedureList'});
    }
  }

  render() {
    const { collapsed } = this.state;
    return (
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        theme="light"
        width="170"
        collapsedWidth="64"
        className="leftMenu"
      >
        <Menu
          theme="light"
          selectedKeys={[this.state.activeKey]}
          mode="inline"
        >
          {this.state.menuData !== undefined &&
          this.state.menuData.length > 0 ? (
            this._recurringMenu(this.state.menuData)
          ) : (
            <Skeleton paragraph={{ rows: 4 }} />
          )}
        </Menu>
      </Sider>
    );
  }
}
