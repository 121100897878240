import React from "react";
import { Input as AntdInput } from "antd";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, {
  NormalTextArea,
  ExpendTextArea,
} from "../../../../base/basicComponents/input";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import NormalModal from "../../../../base/basicComponents/modal";
import styles from "./index.module.scss";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import returnReasonLogIcon from "../../../../assets/image/returnReasonLogIcon.png";
import ReturnReasonLogModal from "../../../../base/basicComponents/returnReasonLogModal";
import RequestReasonLogModal from "../../../../base/basicComponents/requestReasonLogModal";
import RejectModal from "../../../../base/basicComponents/rejectModal";
import ReturnToReviseModal from "../../../../base/basicComponents/returnToReviseModal";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import { actions } from "../../../publicDictionaryValues";

export default class ApproveProcedureInformation extends BaseProcedureInformation {
  constructor(props) {
    super(props);
    this.state = {
      isReasonLogModalOpen: false,
      isRequestReasonLogModalOpen: false,
    };
  }

  handleReturnReasonLogModal = () => {
    this.setState({
      isReasonLogModalOpen: !this.state.isReasonLogModalOpen,
    });
  };

  handleRequestReasonLogModal = () => {
    this.setState({
      isRequestReasonLogModalOpen: !this.state.isRequestReasonLogModalOpen,
    });
  };

  changePrimaryProcedureOwnerTriggerStepAction = (param) => {
    this.props._showLoading();
    ApproveChangePPOService.ChangePrimaryProcedureOwnerTriggerStepAction(
      param,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  handleRejectConfirm = (value) => {
    this.props._showLoading();
    const { procedureID, procedureStepID, workflowProcessInstanceId } =
      this.props.procedureData;
    const param = {
      procedureId: procedureID,
      actionID: actions.reject,
      procedureStepID,
      workflowProcessInstanceId,
      RejectReason: value,
    };

    this.changePrimaryProcedureOwnerTriggerStepAction(param);

    this.props.closeRejectModal();
  };

  handleApproveProcedureInfoRejectCancelClick = () => {
    this.props.closeRejectModal();
  };

  handleReturnConfirm = (value) => {
    this.props._showLoading();
    const { procedureID, procedureStepID, workflowProcessInstanceId } =
      this.props.procedureData;
    const param = {
      procedureId: procedureID,
      actionID: actions.return,
      procedureStepID,
      workflowProcessInstanceId,
      returnReason: value,
    };

    this.changePrimaryProcedureOwnerTriggerStepAction(param);

    this.props.closeReturnModal();
  };

  handleReturnCancelClick = () => {
    this.props.closeReturnModal();
  };

  render() {
    const {
      noteDisabled,
      title,
      procedureData,
      isShowActionBtns,
      isSubmittedCompletedPage,
      isReturnedCompletedPage,
      modalState,
    } = this.props;

    const {
      isReturnModalOpen,
      isRejectModalOpen,
      isModalOpen,
      modalOkText,
      modalCancelText,
      modalChildContent,
      handleModalConfirm,
      handleModalCancel,
    } = modalState;

    const {
      procedureStatus,
      isPMPExcluded,
      primaryProcedureOwner,
      procedureOwner,
      procedureNote,
      returnReasonLog,
      requestReasonLog,
    } = procedureData;

    const labelInputComponent = (text, value, disabled) => {
      return (
        <div className={styles.informationItem}>
          <InputLabel text={text} className={styles.informationLabel} />
          <Input value={value} disabled={disabled} />
        </div>
      );
    };

    const labelTagComponent = (tag) => {
      if (tag === "Old") {
        return <div className={styles.oldTag}>{tag}</div>;
      }
      return <div className={styles.newTag}>{tag}</div>;
    };

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        {returnReasonLog.length > 0 && isReturnedCompletedPage && (
          <div className={styles.reviseInfo}>
            <div className={styles.reviseInfoTop}>
              <span className={styles.returnReasonTitle}>Return Reason</span>
              <NormalToolTip
                element={
                  <div
                    className={styles.returnReasonLogIcon}
                    onClick={() =>
                      this.setState({ isReasonLogModalOpen: true })
                    }
                  >
                    <img src={returnReasonLogIcon} alt="PPO" />
                  </div>
                }
                title="View previous Return Reason"
                placement="right"
                color="#3E6CB5"
              />
            </div>
            <div className="returnReasonWrapper">
              <AntdInput.TextArea
                readOnly={true}
                value={returnReasonLog[0]?.returnReasonColumn}
                style={{
                  height: 120,
                  resize: "none",
                }}
                maxLength={1000}
                showCount={true}
              />
            </div>
          </div>
        )}
        <div className={styles.informationContent}>
          {labelInputComponent(
            "Procedure Status",
            this.getProcedureStatus(procedureStatus),
            true
          )}
          {labelInputComponent("PMP Excluded", isPMPExcluded, true)}
          {isShowActionBtns || !isSubmittedCompletedPage ? (
            <>
              <div className={styles.informationItem}>
                <InputLabel
                  text="Primary Procedure Owner"
                  className={styles.informationLabel}
                />
                <Input
                  value={primaryProcedureOwner?.oldPrimaryProcedureOwnerName}
                  disabled={true}
                />
                {labelTagComponent("Old")}
                {returnReasonLog.length > 0 && !isReturnedCompletedPage && (
                  <NormalToolTip
                    element={
                      <div
                        className={styles.returnReasonLogIcon}
                        onClick={() =>
                          this.setState({ isReasonLogModalOpen: true })
                        }
                      >
                        <img src={returnReasonLogIcon} alt="PPO" />
                      </div>
                    }
                    title="View previous Return Reason"
                    placement="right"
                    color="#3E6CB5"
                  />
                )}
              </div>
              <div className={styles.informationItem}>
                <InputLabel className={styles.informationLabel} />
                <Input
                  value={primaryProcedureOwner?.primaryProcedureOwnerName}
                  disabled={true}
                />
                {labelTagComponent("New")}
              </div>
            </>
          ) : (
            <>
              <div className={styles.informationItem}>
                <InputLabel
                  text="Primary Procedure Owner"
                  className={styles.informationLabel}
                />
                <Input
                  value={primaryProcedureOwner?.primaryProcedureOwnerName}
                  disabled={true}
                />
              </div>
            </>
          )}
          <div className={styles.informationReasonItem}>
            <InputLabel 
              text="Reason" 
              className={styles.informationLabel} 
            />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={primaryProcedureOwner?.reason}
              disabled={true}
              maxLength={1000}
            />
            <NormalToolTip
              element={
                <div
                  className={styles.returnReasonLogIcon}
                  onClick={() =>
                    this.setState({ isRequestReasonLogModalOpen: true })
                  }
                >
                  <img src={returnReasonLogIcon} alt="Reason" />
                </div>
              }
              title="View previous Reason"
              placement="right"
              color="#3E6CB5"
            />
          </div>
          {labelInputComponent(
            "Procedure Owner",
            procedureOwner?.procedureOwnerName,
            true
          )}
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={procedureNote}
              disabled={noteDisabled}
            />
          </div>
        </div>
        <ReturnReasonLogModal
          isReasonLogModalOpen={this.state.isReasonLogModalOpen}
          handleReturnReasonLog={this.handleReturnReasonLogModal}
          returnReasonLogData={returnReasonLog}
        />
        <RequestReasonLogModal
          isReasonLogModalOpen={this.state.isRequestReasonLogModalOpen}
          handleRequestReasonLog={this.handleRequestReasonLogModal}
          requestReasonLogData={requestReasonLog}
        />
        <RejectModal
          isRejectModalOpen={isRejectModalOpen}
          handleRejectConfirm={this.handleRejectConfirm}
          handleRejectCancel={this.handleApproveProcedureInfoRejectCancelClick}
        />
        <ReturnToReviseModal
          isReturnModalOpen={isReturnModalOpen}
          handleReturnConfirm={this.handleReturnConfirm}
          handleReturnCancel={this.handleReturnCancelClick}
        />
        <NormalModal
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={handleModalCancel}
          closable={false}
        />
      </div>
    );
  }
}
