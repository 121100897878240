import React from "react";
import { Radio } from "antd";
import { savedInRadioValues } from "../../../../publicDictionaryValues";
import ApprovedSavedIn from "../../../procedureFileSections/fields/savedInFields/approvedSavedIn";
import styles from "../../../procedureFileSections/fields/savedInFields/index.module.scss";
import applyJbSavedInStyles from "../../index.module.scss";

export default class ApplyJbSavedIn extends ApprovedSavedIn {
  render() {
    const { savedInRadioValue } = this.state.savedInSectionState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={applyJbSavedInStyles.procedureSavedIn}
          style={{ borderColor: "#b3b3b3" }}
        >
          <div>
            <div className={styles.savedInTop}>
              <div className={styles.procedureSaveInLabel}>
                Procedure Saved In:
              </div>
              <div className={styles.procedureSaveInChoice}>
                <Radio.Group value={savedInRadioValue} disabled={true}>
                  <Radio value={savedInRadioValues.systemOnly}>
                    PMP System Only
                  </Radio>
                  <Radio value={savedInRadioValues.systemAndClientServer}>
                    PMP System & Client Server
                  </Radio>
                  <Radio value={savedInRadioValues.serverOnly}>
                    Client Server Only
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
