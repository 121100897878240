import { BrowserRouter } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import RoutingComponent from "../common/RoutingComponent";

export default class LoaderPage extends RoutingComponent {
  constructor(props) {
    super(props);
    this.login = props.login;
    this.main = props.main;
    this.loginProps = props.loginProps;
    this.mainProps = props.mainProps;
  }

  render() {
    let isAuthenticated =
      this.appSetting.localStorageValue.isAuthenticated.get();
    if (isAuthenticated === "true") {
      return this.authenticatedRoute;
    } 

    return this.unauthenticatedRoute;
  }

  get authenticatedRoute() {
    return <this.main></this.main>;
  }

  get unauthenticatedRoute() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <this.login
              redirectUrl={
                window.location.href.toLowerCase().indexOf("/login") > -1
                  ? this.loginProps
                  : window.location.href
              }
            />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}
