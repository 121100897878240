import { message } from "antd";

import moment from "moment";
import "moment-timezone";
import AppSetting from "../../config/AppSetting";
import ProcedureDetailService from "../../service/procedureFile/ProcedureDetailService";

export const signOffSelectValues = {
  No: "13A681C0-B44D-4138-9CBD-7B6D298A896C",
  Yes: "5232DDE3-8D5F-4FE7-9B52-B94A75A61544",
  NoNeed: "600B26EF-B219-4C2E-9E6E-A78D7ED8F70E",
};

export const savedInRadioValues = {
  systemOnly: "626F6ABF-C335-4814-B745-B2C151CA475C",
  systemAndClientServer: "DA88B581-521E-4B48-92D7-73C6EBB2CE49",
  serverOnly: "E7EA172A-D3F9-4362-9613-3B9B2DF30849",
};

export const fileTags = {
  replaced: "35BCA356-DE7A-4C86-95FF-2DBCDE0A14CF",
  deleted: "AD0377D7-1499-4489-8BF7-080F668BC3B6",
  new: "E2172EF2-6770-4767-A9E5-539C571E8E81",
  cancelled: "E8DACBBF-889C-4BF9-A457-7E4FCAC07BBE",
};

export const dataStatus = {
  return: "63216EA6-C2F2-4B36-BFE4-60E0342C5638",
  approval: "6B970A66-F5C3-4DB6-8CAB-470E016250EC",
  reject: "42C33BD0-E338-426E-B796-0709D13BB4DD",
};

export const actions = {
  approve: "29E2C350-E1B0-440F-BF72-664821F4D53F",
  return: "5A1AC5D6-FF85-489D-9CD1-25D1635C23CC",
  save: "610E9444-6F30-417E-993D-958EA2004756",
  revise: "95E03178-6073-4E46-9C5A-6F99BDC5F4D5",
  reject: "C24A978B-818B-4C2D-868E-7B71693F531B",
  signOff: "2A6BDD08-18A5-44AB-AE4E-CC0B245D65FC",
  submit: "94CB705D-30DC-43C7-A04A-5DCF232E55A1",
};

export const pageStatus = {
  reject: "101C25B3-AF9E-482E-B6F4-32A2B2797FA8",
  draft: "306FC640-15DE-40C4-A8B2-ABBF7518B411",
  active: "6B216AC9-6051-47A9-AEFB-62AF5C02B931",
  pendingApproval: "97AD0F48-3ABA-454E-9990-6C002B191022",
  archived: "E690D285-A182-476B-8163-FC3971F0E029",
};

export const userRoles = {
  Manager: "21F95C20-5D90-4C75-94C5-B4D50E0C064C",
  Opex: "5D2E3BEE-756D-4BA0-B414-305C9D950FD9",
  Specialist: "7D59AC9C-2905-48E4-AFFE-C6BF97AC6BB1",
  TeamLeader: "A57C74EC-D425-43D6-B6AF-9E810AA873F2",
  Assistant: "D861A28F-435A-467C-AD67-283F89D0DAA9",
  AccountMember: "C70DC6D8-1B5B-486D-9E5F-A6CF44D134FE",
  OpExAndTOO: "36D1447F-DBA0-4EE7-8D8D-C91D98CAFC5E",
};

export const shareTaskMassage = {
  0: "",
  1: "Only Main Task can manage Sharing Relationship.",
  2: "Please assign a Primary Procedure Owner first.",
  3: "You cannot submit Sharing Relationship process while there is another approval in progress.",
  4: "Sharing Relationship Process is pending approval.",
  5: "This task is in another sharing relationship.",
  6: "This task has another Sharing Relationship pending approval.",
  7: "This task has another approval in progress.",
  8: "Main Task's Procedure Saved In is Client Server Only. Please change this Task's Procedure Saved In value to Client Server Only to add it as a Shared Task.",
  9: "You cannot submit change po process while there is another approval in progress.",
  10: "You cannot submit change po process while no permission.",
  11: "This section is pending approval.",
  12: "You cannot submit process while there is po process in progress.",
  13: "You cannot submit process while there is ppo process in progress.",
  14: "Old Main Task's Procedure Saved In is Client Server Only. Please change this Task's Procedure Saved In value to Client Server Only to set it as New Main Task.",
  15: "Please assign a Procedure Owner first.",
  16: "You cannot submit process while there is PMP excluded process in progress.",
  17: "Delegate To Is Process Initiator",
  18: "Delegate To Is The Upstream Approvers",
  19: "Process Step Has Actioned",
  20: "Someone Already Have This To Do",
  21: "You cannot submit Create New Supporting File process while there is a Sharing Relationship approval in progress. Please save all as draft and try again later.",
  22: "You cannot submit Update Supporting File process while there is a Sharing Relationship approval in progress.",
  23: "You cannot submit process while there is TL Review process in progress.",
  24: "There is an Apply for JB Status process pending approval.",
  25: "Update Procedure Process is pending approval.",
  29: "This task is inactive, please delete it in this request.",
  30: "Only SDU Team Member can update procedure.",
  31: "This task is a Highly Regulated task, only Primary Procedure Owner and above can update procedure.",
  32: "This task is a Highly Regulated task, only Procedure Owner and above can update procedure.",
  33: "Only SDU Team Member can create procedure.",
  34: "You cannot submit Create New Procedure process while there is a Sharing Relationship approval in progress. Please save all as draft and try again later.",
  35: "You cannot submit Update Procedure File process while there is a Sharing Relationship approval in progress.",
  36: "The old PPO has lost account membership, you cannot return this process. Please reject this process and reassign a new PPO on Procedure Detail Page.",
  37: "The new PPO has lost account membership, please return or reject this process.",
};

/* function about load procedure file table and supporting file table data */
export const setTableData = (
  dataList,
  files,
  _generateGUID,
  handleDataNull,
  _formateDate
) => {
  files.forEach((procedureFile) => {
    const lineId = _generateGUID();
    const uploadedDate = handleDataNull(procedureFile, "createdDate");
    dataList.push({
      data: [
        { name: "lineId", value: lineId },
        {
          name: "fileId",
          value: handleDataNull(procedureFile, "fileID"),
        },
        {
          name: "fileKey",
          value: handleDataNull(procedureFile, "fileKey"),
        },
        {
          name: "fileName",
          value: handleDataNull(procedureFile, "fileName"),
        },
        {
          name: "uploadedDate",
          value:
            uploadedDate !== null ? _formateDate(uploadedDate) : uploadedDate,
        },
        {
          name: "uploadByName",
          value: handleDataNull(procedureFile, "uploadByName"),
        },
        {
          name: "uploadBy",
          value: handleDataNull(procedureFile, "uploadBy"),
        },
        { name: "note", value: handleDataNull(procedureFile, "note") },
      ],
      checked: false,
    });
  });
  return dataList;
};

export const pmpExcludedReasonSelects = {
  premierService: "3778C212-0E52-40E2-A7BC-DB4CA53D8E3C",
  simpleProcess: "C7E384B0-86E4-4E7F-9660-533DE1F20A73",
  otherReason: "39EF216D-A1C2-4CDE-9C84-A23C40441BA1",
};

export const pmpExcludedReasonSelectOptions = [
  {
    value: pmpExcludedReasonSelects.premierService,
    name: "Premier Service",
  },
  { value: pmpExcludedReasonSelects.simpleProcess, name: "Simple Process" },
  { value: pmpExcludedReasonSelects.otherReason, name: "Other Reason" },
];

export const regularExpressionOptions = {
  // The string is not empty. False is returned if the string contains only Spaces or carriage returns.
  nonempty: /^\s*$/,
};

export const openSections = {
  default: "e4574523-fae0-4d42-a4b9-d8a37c31e76e",
  procedureFile: "454ffcac-6648-47ed-9098-f75b3920ea23",
  supportingFile: "7909cf22-9d78-400b-84ec-ac344148338a",
  procedureInfo: "1b11ad1d-811f-4c3f-b769-9935fdc39e09",
  shareTask: "18529ee0-72bd-4716-bdcc-6ac60e0d240a",
};

export const revisionFromList = {
  RSP: "483CFA2D-547D-4388-AF6B-CF365DBD4089",
  Client: "E6785D2A-0B06-4243-BAC9-A1E223FA7F03",
};

export const revisionCategories = {
  clientRequested: "7BE9C74F-398F-4C25-A5C3-616E29DBA69F",
  rspSuggested: "2BB90DA5-B18D-4588-8D27-AC989E9B9015",
  procedureRefinement: "30C08712-F673-41A8-BBD7-B8602043124C",
  systemTransition: "1E891D07-0E10-4F19-9D60-D9765A54FD52",
  storageChange: "96ED4858-3FFA-40ED-AD84-A7FE335C92B5",
};

export const flowCategoryId = {
  copyProcedure: "BB044627-9093-4875-A7E4-43F6215B05FB",
  copySupportingFile: "C441C543-F3D1-4A86-B682-E54D63BCF14C",
  copyJBStatus: "54436820-C7DA-4857-BCDF-19765FFC330E",
};

export const processDetailRouteMap = {
  "0083214D-83BD-4C82-A7C9-238D45AE87AE": "/CreateProcdureFileProcess/",
  "07008BB0-E127-4945-952C-4C0BC5BB09CF": "/AssignPrimaryProcedureOwner/",
  "13472863-E1A4-4F1F-A6A6-D5F50BED1AFB": "/TLReviewProcedure/",
  "2C22662F-EEC2-4B08-A6BC-C24A5876B840": "/ChangeProcedureOwner/",
  "42A9A592-EFD0-47D5-A6DF-E71F3E0D28AE": "/ApprovePrimaryProcedureOwner/",
  // "4445F6F6-0AF9-421C-9A5F-EB3C28FC151C": "", // Archive Procedure
  "637CB4D9-1346-45D2-B6B2-5C7F910F69DA": "/ApplyPMPExcluded/",
  "6C11B813-107D-4BA1-AC50-45699AC0113B": "/ManageShareTasks/", // Share Task
  "6C283DCB-9EFF-456B-A196-72AEE51A294C": "/ChangeMainTask/",
  "6F16E086-DE54-4F94-8FC8-1D4B2DAF2444": "/ManageShareTasks/",
  "924C404D-82A7-4908-902E-9095AA18EA4A": "/SignOffConfirmation/",
  "B75448B6-5F79-492A-BABC-AFE6843F4ED2": "/CancelPMPExcluded/",
  "C818176E-2AC4-4CB3-A19E-443697917931": "/UpdateSupportingFileProcess/",
  "CE391CD1-EC84-4682-85E1-FF76CF08CF89": "/CreateSupportingFileProcess/",
  "D239BBB6-8EAC-42C4-B659-330022DC323C": "/UpdateProcdureFileProcess/",
  "E1AE03F5-8829-4C9C-A93A-7B5F3D94600C": "/ReassignPrimaryProcedureOwner/",
  "ED4EE056-4EE5-41CE-A381-0C144FD4EBC4": "/ChangeProcedureOwner/",
  "F997CA0F-84CF-458C-843F-3C307DF33DC1": "/ChangeMainTask/",
  "F9D57BA2-2DAD-4C22-82E8-049841A3F803": "/ApplyforJBStatus/",
  "BB044627-9093-4875-A7E4-43F6215B05FB": "/CreateProcdureFileProcess/",
  "C441C543-F3D1-4A86-B682-E54D63BCF14C": "/CreateSupportingFileProcess/",
  "54436820-C7DA-4857-BCDF-19765FFC330E": "/ApplyforJBStatus/",
  "0B53CE39-FE80-4F71-A584-30DFE5AF3D0F": "/CreateSupportingFileProcess/",
  "23897079-626D-48B2-B450-2BCB89DD53F6": "/ManageShareTasks/",
  "290099CA-B992-4B19-98F0-EFFE87A9E4F1": "/AssignPrimaryProcedureOwner/",
  "43A69022-5895-4FB9-91E2-4F889664127A": "/ApplyPMPExcluded/",
  "44F0659E-9F14-4633-8A2D-D0F3DB588818": "/CreateProcdureFileProcess/",
  "770DED60-4A55-4C0B-B2C2-C861979B2FDA": "/ApplyforJBStatus/",
  "A0CF184F-5EAE-41AC-9AC5-6348D2AB72BF": "/ChangeProcedureOwner/",
  "B0A608DA-E339-4BBB-8E2C-FAFF4D101843": "/UpdateProcdureFileProcess/",
  "D3AD67E8-C93A-44DF-B270-D671E6681553": "/ManageShareTasks/",
};

export const RoutingMap = {
  updatesupportingfileprocess: {
    routingID: "0EEAAAFF-C6BE-480B-8232-653F41B12329",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  sharedocument: {
    routingID: "0F31AB71-74D1-4ECD-A23D-E762C8922E80",
    routingParams: [],
  },
  proceduredetail: {
    routingID: "15CB6B74-4BC1-4758-8904-AF7924F2AD1D",
    routingParams: ["ProcedureId", "ProcedureTaskId"],
  },
  tlreviewprocedure: {
    routingID: "506CC1D8-E35E-48BB-BD53-0665B4A1EE44",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  reassignprimaryprocedureowner: {
    routingID: "7DB4CD9C-6B25-40D5-844E-DAB2B937DAFC",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  approveprimaryprocedureowner: {
    routingID: "85A558F0-79AE-4FFC-8454-BF48CC8B346F",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  changemaintask: {
    routingID: "8B46882E-ABF3-42DE-B953-4020DBF544E6",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  managesharetasks: {
    routingID: "A30122CD-9AD2-495D-9F41-D30CCCC0FE37",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  cancelpmpexcluded: {
    routingID: "A7556D43-6575-4033-87CD-ADB518647F6A",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  createproceduredetail: {
    routingID: "B365FFF1-19E0-4D3A-9B00-98C905A9048D",
    routingParams: ["ProcedureTaskId"],
  },
  assignprimaryprocedureowner: {
    routingID: "B3A064E6-1837-47F8-B8D2-F93EDF2A6BF1",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  applyforjbstatus: {
    routingID: "B4009A92-244B-41E9-84B9-108418A82F65",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  createprocdurefileprocess: {
    routingID: "C1A768AD-E716-41A1-8B9F-B3D7803374BC",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  signoffconfirmation: {
    routingID: "C69C02B6-B1B1-4354-81D3-A472BD130508",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  createsupportingfileprocess: {
    routingID: "DC491AEB-A404-4600-8CD9-9D2029727B29",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  processdetail: {
    routingID: "E086112A-8D4D-4E28-97E8-24CEBD6EAEAE",
    routingParams: ["ProcessID"],
  },
  changehistory: {
    routingID: "F02A0C1D-CDC3-4669-BAC2-CAE1924F14D4",
    routingParams: ["ProcedureTaskId", "ProcedureId"],
  },
  applypmpexcluded: {
    routingID: "F0FE4095-B810-4600-8E83-1B1DAFB63401",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  updateprocdurefileprocess: {
    routingID: "F9E4172B-9166-48E3-8261-FDDDFD75A8AD",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
  changeprocedureowner: {
    routingID: "FE233F06-ADAB-41D6-B336-E2F0C6E108E3",
    routingParams: [
      "ProcedureTaskId",
      "ProcedureID",
      "ProcedureStepID",
      "ParentPage",
    ],
  },
};

export const flowBusinessIdMap = {
  jBStatus: "3BA38E39-1DF8-42E4-9BF5-75DA16BE0D62",
  shareTask: "0D3D0ED4-19A5-4972-82A3-A4F2A2D8A01D",
  procedureFile: "B5EB320A-8049-4967-B087-0603EFEB60F3",
  supportingFile: "62CA3DF5-BF81-42B2-A4B7-E321198D4E0D",
};

export const procedureJbStatus = {
  "5BB76929-D071-4B7D-831A-09EAA1093C38": "Non JB",
  "E6E26C3F-6B4D-4064-B86F-F0C54FC41208": "JB",
  "": "",
};

export const procedureJbStatusMap = {
  "Non JB": "5BB76929-D071-4B7D-831A-09EAA1093C38",
  JB: "E6E26C3F-6B4D-4064-B86F-F0C54FC41208",
  "": "",
};

export const proposedChangeTags = {
  update: "69E13652-2E1E-43E0-A629-5BB304DCB2CB",
  delete: "CCE916A3-EF52-416C-8E02-D4B9A42819B0",
  new: "EBC990E4-26B0-4269-A9BD-AD66A3EF73BB",
};

export const procedureStatusValues = {
  "33A5AED4-EC36-4663-8547-8A0226A23990": "No Procedure",
  "5BB76929-D071-4B7D-831A-09EAA1093C38": "Non JB",
  "E6E26C3F-6B4D-4064-B86F-F0C54FC41208": "JB",
};

export const applyForJBStatus = {
  None: "00715AFE-F384-4AD9-830F-51923F1D2496",
  PendingApproval: "97AD0F48-3ABA-454E-9990-6C002B191022",
  Active: "6B216AC9-6051-47A9-AEFB-62AF5C02B931",
  Reject: "101C25B3-AF9E-482E-B6F4-32A2B2797FA8",
  Archived: "E690D285-A182-476B-8163-FC3971F0E029",
};

const REGEXP_TAG = "[object RegExp]";
const DATE_TAG = "[object Date]";
const MAP_TAG = "[object Map]";
const SET_TAG = "[object Set]";
const OBJECT_TAG = "[object Object]";
const ARRAY_TAG = "[object Array]";

const getTag = (value) => {
  if (value == null) {
    return value === undefined ? "[object Undefined]" : "[object Null]";
  }
  return Object.prototype.toString.call(value);
};

/**
 * deep clone list just like JSON.Parse(JSON.Stringfy(list))
 */
export const deepClone = (target) => {
  if (typeof target !== "object" || target === null) return target;
  const cloneTarget = [];

  // Record loop reference
  const map = new Map();

  // The upper level object is stored on the stack
  const stack = [
    {
      parent: cloneTarget,
      key: undefined,
      raw: target,
    },
  ];

  while (stack.length) {
    const node = stack.pop();
    const { parent, key, raw } = node;

    // The first copy is directly assigned to the cloneTarget top-level parent object
    let child = parent;
    if (map.has(raw)) {
      // Directly assign an object that has been copied
      child[key] = map.get(raw);
      continue;
    }
    // Records the object to be copied
    map.set(raw, child);
    if (key) {
      // Creates a child object and maintains a parent object reference
      // Initialize the data using the constructor of the original data
      child = parent[key] = new raw.constructor();
    }
    // Processing Set
    if (getTag(child) === SET_TAG) {
      raw.forEach((value) => {
        child.add(deepClone(value));
      });
      continue;
    }
    // Processing Map
    if (getTag(child) === MAP_TAG) {
      raw.forEach((value, key) => {
        child.set(key, deepClone(value));
      });
      continue;
    }
    for (const key in raw) {
      if (raw.hasOwnProperty(key)) {
        const rawVal = raw[key];
        const tag = getTag(rawVal);
        if (
          tag === OBJECT_TAG ||
          tag === ARRAY_TAG ||
          tag === SET_TAG ||
          tag === MAP_TAG
        ) {
          stack.push({
            parent: child,
            key,
            raw: rawVal,
          });
        } else {
          switch (tag) {
            case REGEXP_TAG:
              child[key] = new rawVal.constructor(rawVal);
              break;
            case DATE_TAG:
              child[key] = new rawVal.constructor(rawVal);
              break;
            default:
              // boolean string number undefined null symbol bigint
              child[key] = rawVal;
          }
        }
      }
    }
  }
  return cloneTarget;
};

/**
 * function about judge the revision information state whether pass manatory check
 */
export const handleRevisionInfoManatoryCheck = (ref) => {
  const {
    currentRequestDate,
    currentRequestFrom,
    currentClientValue,
    currentRspValue,
    currentDesciption,
  } = ref.current.state;
  return (
    currentRequestDate === null ||
    (currentRequestFrom === revisionFromList.Client &&
      (currentClientValue === null || currentClientValue.trim() === "")) ||
    (currentRequestFrom === revisionFromList.RSP && currentRspValue === null) ||
    currentDesciption === null ||
    currentDesciption.trim() === "" ||
    currentRequestFrom === null || 
    currentRequestFrom.trim() === ""
  );
};

/**
 * check whether has mandatory field not filled in update procedure file section
 */
export const revisionInfoMandatoryCheck = (revisionInfoRef, content) => {
  const {
    currentCategory,
    currentRequestDate,
    currentRequestFrom,
    currentClientValue,
    currentRspValue,
    currentDesciption,
  } = revisionInfoRef.current.state;

  if (currentCategory === null) {
    revisionInfoRef.current.setCategoryStatus("error");
    content.scrollTop = 0;
  }

  if (currentRequestDate === null) {
    revisionInfoRef.current.setDateStatus("error");
    content.scrollTop = 0;
  }

  if (currentRequestFrom === null || currentRequestFrom.trim() === "") {
    revisionInfoRef.current.setFromStatus("error");
    content.scrollTop = 0;
  }

  if (
    currentRequestFrom === revisionFromList.Client &&
    (currentClientValue === null || currentClientValue.trim() === "")
  ) {
    revisionInfoRef.current.setClientValueStatus("error");
    content.scrollTop = 0;
  }

  if (currentRequestFrom === revisionFromList.RSP && currentRspValue === null) {
    revisionInfoRef.current.setRspValueStatus("error");
    content.scrollTop = 0;
  }

  if (currentDesciption === null || currentDesciption.trim() === "") {
    revisionInfoRef.current.setDescriptionStatus("error");
    content.scrollTop = 0;
  }
};

/**
 * function about detail page sort file
 * @param {*} files table data
 * @param {*} formateMethod formate date method
 * @returns
 */
export const detailTableSortFile = (files, formateMethod) => {
  let arr = [];
  if (files !== null) {
    let newArr = files.sort((a, b) => {
      const prevName = a.fileName;
      const nextName = b.fileName;
      return prevName.localeCompare(nextName);
    });
    let sortedList = newArr.sort((a, b) => {
      const prevTime = formateMethod(a.uploadedOn);
      const nextTime = formateMethod(b.uploadedOn);
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    arr.push(...sortedList);
  }
  return arr;
};

/**
 * function about procdure and supporting file process page default sort file
 * @param {*} files table data
 * @param {*} formateMethod formate date method
 * @returns
 */
export const processDefaultTableSortFile = (files, formateMethod) => {
  let arr = [];
  if (files !== null) {
    let newArr = files.sort((a, b) => {
      const prevName = a.fileName;
      const nextName = b.fileName;
      return prevName.localeCompare(nextName);
    });
    let sortedList = newArr.sort((a, b) => {
      const prevTime = formateMethod(a.uploadedDate);
      const nextTime = formateMethod(b.uploadedDate);
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    arr.push(...sortedList);
  }
  return arr;
};

/**
 * function about procdure and supporting file process page default sort file
 * @param {*} files table data
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const processSortFileWithOldFile = (files, formateMethod, isRevise) => {
  let arr = [];
  if (files !== null) {
    let newArr = files.sort((a, b) => {
      const prevName =
        a.oldFileName !== null && isRevise ? a.oldFileName : a.fileName;
      const nextName =
        b.oldFileName !== null && isRevise ? b.oldFileName : b.fileName;
      return prevName.localeCompare(nextName);
    });
    let sortedList = newArr.sort((a, b) => {
      const prevTime = formateMethod(a.uploadedDate);
      const nextTime = formateMethod(b.uploadedDate);
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    arr.push(...sortedList);
  }
  return arr;
};

/**
 * Sort file by createdDate then sort by fileName
 * @param {array} files
 * @param {function} formateMethod formate date method
 * @returns
 */
export const handleSortFileByNameAndCreateDate = (files, formateMethod) => {
  let arr = [];
  if (files !== null) {
    let newArr = files.sort((a, b) => {
      const prevName = a.fileName;
      const nextName = b.fileName;
      return prevName.localeCompare(nextName);
    });
    let sortedList = newArr.sort((a, b) => {
      const prevTime = formateMethod(a.createdDate);
      const nextTime = formateMethod(b.createdDate);
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    arr.push(...sortedList);
  }
  return arr;
};

/**
 * return procedure and supporting file sort with file tag
 * @param {array} dataList
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const processSortFilesFollowFileTag = (
  dataList,
  formateMethod,
  isRevise
) => {
  const updatedList = [];
  const deletedList = [];
  const newList = [];
  const emptyList = [];
  dataList.forEach((item, index) => {
    const tag = item.fileTagID;
    switch (tag) {
      case fileTags.replaced:
        updatedList.push(item);
        break;
      case fileTags.deleted:
        deletedList.push(item);
        break;
      case fileTags.new:
        newList.push(item);
        break;
      default:
        emptyList.push(item);
        break;
    }
  });
  const updatedNewList = processSortFileWithOldFile(
    updatedList,
    formateMethod,
    isRevise
  );
  const deletedNewList = processSortFileWithOldFile(
    deletedList,
    formateMethod,
    isRevise
  );
  const newNewList = processSortFileWithOldFile(
    newList,
    formateMethod,
    isRevise
  );
  const newEmptyList = processSortFileWithOldFile(
    emptyList,
    formateMethod,
    isRevise
  );
  return [...newEmptyList, ...newNewList, ...updatedNewList, ...deletedNewList];
};

/**
 * return procedure and supporting file sort with status
 * @param {array} list
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const handleSortFileByStatus = (list, formateMethod, isRevise) => {
  const returnList = [];
  const approvedList = [];
  const emptyList = [];
  list.forEach((item, index) => {
    const status = item.dataStatusID;
    if (status === dataStatus.return) {
      returnList.push(item);
    } else if (status === dataStatus.approval) {
      approvedList.push(item);
    } else {
      emptyList.push(item);
    }
  });
  const returnNewList = processSortFilesFollowFileTag(
    returnList,
    formateMethod,
    isRevise
  );
  const approvedNewList = processSortFilesFollowFileTag(
    approvedList,
    formateMethod,
    isRevise
  );
  const emptyNewList = processSortFilesFollowFileTag(
    emptyList,
    formateMethod,
    isRevise
  );
  return [...emptyNewList, ...returnNewList, ...approvedNewList];
};

/**
 * sort file follow file tag
 * @param {array} dataList
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const reviseUpdateSortFilesFollowFileTag = (
  dataList,
  formateMethod,
  isRevise
) => {
  const updatedList = [];
  const deletedList = [];
  const cancelledList = [];
  const newList = [];
  const emptyList = [];
  dataList.forEach((item, index) => {
    const tag = item.fileTagID;

    switch (tag) {
      case fileTags.replaced:
        updatedList.push(item);
        break;
      case fileTags.deleted:
        deletedList.push(item);
        break;
      case fileTags.cancelled:
        cancelledList.push(item);
        break;
      case fileTags.new:
        newList.push(item);
        break;
      default:
        emptyList.push(item);
        break;
    }
  });
  const updatedNewList = processSortFileWithOldFile(
    updatedList,
    formateMethod,
    isRevise
  );
  const deletedNewList = processSortFileWithOldFile(
    deletedList,
    formateMethod,
    isRevise
  );
  const cancelledNewList = processSortFileWithOldFile(
    cancelledList,
    formateMethod,
    isRevise
  );
  const newNewList = processSortFileWithOldFile(
    newList,
    formateMethod,
    isRevise
  );
  const newEmptyList = processSortFileWithOldFile(
    emptyList,
    formateMethod,
    isRevise
  );
  return [
    ...newNewList,
    ...updatedNewList,
    ...deletedNewList,
    ...cancelledNewList,
    ...newEmptyList,
  ];
};

/**
 * sort file follow status
 * @param {array} list
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const handleUpdateReviseSortFileByStatus = (
  list,
  formateMethod,
  isRevise
) => {
  const returnList = [];
  const approvedList = [];
  const emptyList = [];
  list.forEach((item, index) => {
    const status = item.dataStatusID;
    if (status === dataStatus.return) {
      returnList.push(item);
    } else if (status === dataStatus.approval) {
      approvedList.push(item);
    } else {
      emptyList.push(item);
    }
  });
  const returnNewList = reviseUpdateSortFilesFollowFileTag(
    returnList,
    formateMethod,
    isRevise
  );
  const approvedNewList = reviseUpdateSortFilesFollowFileTag(
    approvedList,
    formateMethod,
    isRevise
  );
  const emptyNewList = reviseUpdateSortFilesFollowFileTag(
    emptyList,
    formateMethod,
    isRevise
  );
  return [...emptyNewList, ...returnNewList, ...approvedNewList];
};

/**
 * sort files follow status in porcess page
 * @param {array} files
 * @param {function} formateMethod formate date method
 * @param {boolean} isRevise is revise page
 * @returns
 */
export const handleUpdateReviseSortFile = (files, formateMethod, isRevise) => {
  const noChangeFiles = [];
  const otherFiles = [];
  files.forEach((item, index) => {
    const proposedChange = item.proposedChangeTagID;
    if (proposedChange === null) {
      noChangeFiles.push(item);
    } else {
      otherFiles.push(item);
    }
  });
  const noChangeNewFiles = handleUpdateReviseSortFileByStatus(
    noChangeFiles,
    formateMethod,
    isRevise
  );
  const otherNewFiles = handleUpdateReviseSortFileByStatus(
    otherFiles,
    formateMethod,
    isRevise
  );
  return [...otherNewFiles, ...noChangeNewFiles];
};

/**
 * sort procedure files in approve apply for JB status page
 * @param {array} files
 * @param {function} formateMethod formate date method
 * @returns
 */
export const handleApproveApplyJBSortFile = (files, formateMethod) => {
  const nonJbList = [];
  const jbList = [];
  const emptyList = [];
  files.forEach((item, index) => {
    const applyfor = item.applyfor;
    const jbProcdure = item.jbProcdure;
    // display empty list
    if (applyfor === null || applyfor === jbProcdure) {
      emptyList.push(item);
    } else {
      // non jb
      if (applyfor === procedureJbStatusMap["Non JB"]) {
        nonJbList.push(item);
      } else {
        //jb
        jbList.push(item);
      }
    }
  });
  const newNonJbList = handleSortFileByNameAndCreateDate(
    nonJbList,
    formateMethod
  );
  const newJbList = handleSortFileByNameAndCreateDate(jbList, formateMethod);
  const newEmptyList = handleSortFileByNameAndCreateDate(
    emptyList,
    formateMethod
  );
  return [...newJbList, ...newNonJbList, ...newEmptyList];
};

export const fileUploadErrorMessage = {
  duplicate:
    "The limit of a single upload is 10 files, no larger than 300MB each.",
  single: "The limit of a single attachment is no larger than 300MB.",
};

/**
 * return upload or replace file error based on it's multiple or single
 * @param {*} errorCode
 */
export const handleUploadErrorCode = (errorCode) => {
  switch (errorCode) {
    case 1:
      message.error(fileUploadErrorMessage.duplicate);
      break;
    case 2:
      message.error("File upload failure.");
      break;
    default:
      break;
  }
};

/**
 * alert error when upload file size large than 300M or numbers more than 10.
 * @param {array} fileList
 * @returns
 */
export const handleAlertUploadFileError = (fileList) => {
  if (fileList.length > 10) {
    message.error(fileUploadErrorMessage.duplicate);
    return true;
  }
  for (let fileItem of fileList) {
    if (fileItem.size > 300 * 1024 * 1024) {
      message.error(fileUploadErrorMessage.duplicate);
      return true;
    }
  }
};

/**
 * function about sort select option list
 * @param {array} list
 * @returns
 */
export const sortSelectOptionList = (list) => {
  const sortList = (s, t) => {
    let a = s.name.toLowerCase();
    let b = t.name.toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  return list.sort((s, t) => sortList(s, t));
};

export const getDateString = (date) => {
  const currentDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return currentDate;
};

export const getMomentTimezone = (str) => {
  const city = str?.substring(str.indexOf("(") + 1, str.indexOf(")"));
  if (!city) return "EST";

  // Look up the name of the time zone in the moment library
  const momentCityStr = city.replace(" ", "_");
  const timeZoneArr = moment.tz.names();
  const timeZone = timeZoneArr.find((item) => item.includes(momentCityStr));

  return timeZone;
};

export const getTimeZoneString = (date, dateFormate = "YYYY-MM-DD") => {
  const timezoneStr = JSON.parse(localStorage.getItem("user"))?.identityUserInfo
    .timezone;
  const momentTimezone = getMomentTimezone(timezoneStr);

  const currentdate = new Date();
  const currentTime =
    date +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  const timeZoneString = moment(currentTime)
    .tz(momentTimezone)
    .format(dateFormate);

  return timeZoneString;
};

export const getRequestDateMoment = (momentDate, isChange = false) => {
  const timezoneStr = JSON.parse(localStorage.getItem("user"))?.identityUserInfo
    .timezone;
  const momentTimezone = getMomentTimezone(timezoneStr);

  let currentTime = null;

  if (momentDate) {
    const date = momentDate.format("YYYY-MM-DD");
    const time = isChange
      ? moment().tz(momentTimezone).format("HH:mm:ss")
      : momentDate.format("HH:mm:ss");
    const locationDate = date + " " + time;
    const currentMomentDate = moment.tz(
      locationDate,
      "YYYY-MM-DD HH:mm:ss",
      momentTimezone
    );
    const currentFormatDate = currentMomentDate
      .clone()
      .tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss");
    currentTime = currentFormatDate + "Z";
  } else {
    const formatDate = moment()
      .tz(momentTimezone)
      .format("YYYY-MM-DD HH:mm:ss");
    currentTime = moment(formatDate);
  }

  return currentTime;
};

/**
 * function about get current date
 * @returns
 */
export const getDate = () => {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  if (day < 10) {
    day = "0" + day;
  }

  return month + "/" + day + "/" + year;
};

/**
 * call back about download file
 * @param {*} response
 * @param {*} fileName
 */
const _downloadFileByUrlSuccess = (response, fileName, callback, fileList, index) => {
  if (response !== undefined && response !== "") {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      callback && callback();

      if(fileList.length === index + 1) {
        return;
      }
      _downloadFileItemByUrl(fileList, index+1, callback);
    });
  }
};

const _downloadFileItemByUrl = (fileList, index, callback) => {
  const item = fileList[index];  
  const registerFileShareParam = {
    ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
    storageFileID: [item.fileID],
    expiry: {
      expiry: 1,
      expiryType:  "Days",
    }
  };
  ProcedureDetailService.downloadFile(
    registerFileShareParam,
    (response) => _downloadFileByUrlSuccess(response, item.fileName, callback, fileList, index),
    (error) => console.log('error',error),
    item,
  );
}

/**
 * function about download file
 * @param {*} response
 * @param {*} fileName
 */
export const downloadFileByUrl = (fileList, callback) => {
  _downloadFileItemByUrl(fileList, 0, callback);
}

export const sortUsersList = (list) => {
  const sortList = (s, t) => {
    let a = s.name.toLowerCase();
    let b = t.name.toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
  return list.sort((s, t) => sortList(s, t));
};
