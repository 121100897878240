import React from "react";
import Buttons from "../../../../base/basicComponents/button";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, { ExpendTextArea } from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import NormalModal from "../../../../base/basicComponents/modal";
import styles from "./index.module.scss";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import ChangePOService from "../../../../service/changePO";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import { actions } from "../../../publicDictionaryValues";
import appService from "../../../../service/app";
import LostPermissionModal from "../../lostPermissionModal";

export default class ChangePOProcedureInformation extends BaseProcedureInformation {
  constructor(props) {
    super(props);
    this.state = {
      ppoList: [],
      primaryProcedureOwnerId: null,
      currentPO: null,
      currentReason: "",

      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
      handleModalConfirm: () => {},
      handleModalCancel: () => {},
      noPermission: false,
    };
  }

  handlePOChange = (value, ref) => {
    this.setState({
      currentPO: value,
    });
  };

  handleReasonChange = (value, ref) => {
    this.setState({
      currentReason: value,
    });
  };

  _getPrimaryProcedureOwnerListSuccess = (result) => {
    this.setState({
      ppoList: result,
    });
  };

  _getPrimaryProcedureOwnerListFailed = () => {
    this._alertError("Get Primary Procedure Owner List Failed");
  };

  getChangePOProcedureInformationPrimaryProcedureOwnerList = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.props.procedureTaskId,
      this._getPrimaryProcedureOwnerListSuccess,
      this._getPrimaryProcedureOwnerListFailed
    );
  };

  _checkPoIsExistedSuccess = (result) => {
    if (result) {
      this.setState({ isModalOpen: false });
      this._alertError("This task already has a Procedure Owner");
    } else {
      const {
        procedureID,
        procedureStepID,
        workflowProcessInstanceId,
        procedureOwner,
      } = this.props.procedureData;
      const param = {
        procedureId: procedureID,
        actionID: actions.submit,
        procedureStepID,
        workflowProcessInstanceId,
        procedureOwnerId: this.state.currentPO,
        reason: procedureOwner?.reason,
      };

      this.changeProcedureOwnerProcedureInformationTriggerStepAction(param);

      this.setState({ isModalOpen: false });
    }
  };

  _checkPoIsExistedFailed = () => {
    this._alertError("Check PO Is Existed Failed");
  };

  checkPoIsExisted = () => {
    const param = {
      procedureTaskId: this.props.procedureTaskId,
    };
    ChangePOService.checkPoIsExisted(
      param,
      this._checkPoIsExistedSuccess,
      this._checkPoIsExistedFailed
    );
  };

  changeProcedureOwnerProcedureInformationTriggerStepAction = (param) => {
    ChangePOService.changeProcedureOwnerTriggerStepAction(
      param,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  handleChangePOProcedureInformationCancel = () => {
    this.setState({
      isModalOpen: true,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.props.closePage,
    });
  };

  handleChangePOProcedureInformationApproveConfirm = () => {
    this.props._showLoading();
    this.checkPoIsExisted();
  };

  handelChangePOProcedureInformationApproveClick = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to submit?",
      handleModalConfirm: this.handleChangePOProcedureInformationApproveConfirm,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
    });
  };

  _checkStepPermissionSuccess = (result) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.handelChangePOProcedureInformationApproveClick();
  };

  checkStepPermission = () => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      this._checkStepPermissionSuccess,
      (response) => console.log(response.message)
    );
  };

  componentDidMount() {
    this.props._showLoading();
    this.getChangePOProcedureInformationPrimaryProcedureOwnerList();
  }

  getChangePOProcedureInformationLabelInputComponent = (
    text,
    value,
    disabled
  ) => {
    return (
      <div className={styles.informationItem}>
        <InputLabel text={text} className={styles.informationLabel} />
        <Input value={value} disabled={disabled} />
      </div>
    );
  };

  getChangePOProcedureInformationSortList = () => {};

  getChangePOProcedureInformationSortPPOList = () => {
    const { ppoList } = this.state;

    const PPOList = ppoList.map((item) => {
      return {
        name: item.name,
        value: item.id,
      };
    });
    const sortList = (s, t) => {
      let a = s.name.toLowerCase();
      let b = t.name.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };

    return PPOList.sort((s, t) => sortList(s, t));
  };

  getChangePOProcedureInformationPPOValue = () => {
    const { primaryProcedureOwner } = this.props.procedureData;

    return primaryProcedureOwner?.primaryProcedureOwnerName || null;
  };

  getChangePOProcedureInformationPOValue = () => {
    const { procedureOwner } = this.props.procedureData;
    const { currentPO } = this.state;

    return currentPO || procedureOwner?.procedureOwnerID || procedureOwner?.procedureOwnerName;
  };

  handleChangePOProcedureInformationTitle = () => {
    const { title } = this.props;

    return (
      <div className={styles.procedureInfoTop}>
        <div className={styles.informationTitle}>
          <span className={`${styles.titleText} fs-18`}>{title}</span>
        </div>
      </div>
    );
  };

  handleChangePOProcedureInformationProcedureNote = () => {
    const { procedureData } = this.props;
    const { procedureNote } = procedureData;

    return (
      <div className={styles.informationItem}>
        <InputLabel text="Note" className={styles.informationLabel} />
        <ExpendTextArea
          className={styles.informationExpendTextArea}
          value={procedureNote}
          disabled={true}
        />
      </div>
    );
  };

  handleChangePOProcedureInformationReassignPendingButton = () => {
    const { currentPO } = this.state;

    return (
      <div className={styles.informationBtns}>
        <Buttons
          color="grey"
          size="middle"
          onClick={this.handleChangePOProcedureInformationCancel}
        >
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          size="middle"
          onClick={this.checkStepPermission}
          disabled={!currentPO}
        >
          Submit
        </Buttons>
      </div>
    );
  };

  render() {
    const { currentRef, procedureData, isReassignPending } = this.props;

    const {
      procedureStatus,
      isExcluded,
      procedureOwner,
      workflowProcessStepInfo,
    } = procedureData;

    const {
      isModalOpen,
      modalOkText,
      modalCancelText,
      modalChildContent,
      handleModalConfirm,
      handleModalCancel,
    } = this.state;

    const handleChangePOProcedureInformationOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    return (
      <div className={styles.ProcedureInformation}>
        {this.handleChangePOProcedureInformationTitle()}
        <div className={styles.informationContent}>
          {this.getChangePOProcedureInformationLabelInputComponent(
            "Procedure Status",
            this.getProcedureStatus(procedureStatus),
            true
          )}
          {this.getChangePOProcedureInformationLabelInputComponent(
            "PMP Excluded",
            isExcluded,
            true
          )}
          {this.getChangePOProcedureInformationLabelInputComponent(
            "Primary Procedure Owner",
            this.getChangePOProcedureInformationPPOValue(),
            true
          )}
          <div className={styles.informationItem}>
            <InputLabel
              text="Procedure Owner"
              className={styles.informationLabel}
            />
            {isReassignPending ? (
              <NormalSelect
                optionList={this.getChangePOProcedureInformationSortPPOList()}
                value={this.getChangePOProcedureInformationPOValue()}
                handleChange={(e) => {
                  this.handlePOChange(e, currentRef);
                }}
                showSearch={true}
                handleFilterOption={handleChangePOProcedureInformationOptionList}
                disabled={false}
              />
            ) : (
              <Input value={this.props.procedureData?.procedureOwner?.procedureOwnerName} disabled={true} />
            )}
          </div>
          {(procedureOwner?.reason ||
            workflowProcessStepInfo?.state === "Reassgin PO") && (
            <div className={styles.informationReasonItem}>
              <InputLabel text="Reason" className={styles.informationLabel} />
              <ExpendTextArea
                className={styles.informationExpendTextArea}
                value={procedureOwner?.reason}
                maxLength={1000}
                disabled={true}
              />
            </div>
          )}
          {this.handleChangePOProcedureInformationProcedureNote()}
        </div>
        {isReassignPending &&
          this.handleChangePOProcedureInformationReassignPendingButton()}
        <NormalModal
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={handleModalCancel}
          closable={false}
        />
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
