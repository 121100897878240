import { createRef } from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import Buttons from "../../../../base/basicComponents/button/index";
import UploadFile from "../../../../base/basicComponents/uploadFile/index";
import NormalSpin from "../../../../base/basicComponents/spin";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import SectionEditIcon from "../../../../base/basicComponents/sectionEditIcon";
import BaseProcedureSavedIn from "../../savedInFields/baseSavedInField";
import BaseClientServerPath from "../../serverPathFields/baseServerPathField";
import DetailFileTable from "../../../sections/procedureFileSections/fields/procedureFileTableFields/detailFileTable";
import NormalModal from "../../../../base/basicComponents/modal";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import ProcedureListService from "../../../../service/procedureList/index";
import tlReviewService from "../../../../service/tlReviewService";
import downloadIcon from "../../../../assets/image/downloadIcon.png";
import downloadHoverIcon from "../../../../assets/image/downloadHoverIcon.png";
import shareIcon from "../../../../assets/image/detailShare.png";
import shareHoverIcon from "../../../../assets/image/detailShareHover.png";
import styles from "./index.module.scss";
import "./index.scss";
import AppSetting from "../../../../config/AppSetting";
import {
  deepClone,
  fileTags,
  flowBusinessIdMap,
  procedureJbStatus,
  procedureJbStatusMap,
  savedInRadioValues,
  shareTaskMassage,
  sortUsersList,
  applyForJBStatus,
} from "../../../publicDictionaryValues";
import tablePublicMethods from "../tablePublicMethods";
import TlReviewModal from "../../../sections/TlReviewSection/TlReviewModal";
import TlReviewProcedureModal from "../../../sections/TlReviewSection/TLReviewProcedureModal";
import applyForJbService from "../../../../service/applyForJb/applyForJbService";
import ApplyForJbModal from "../../../sections/applyForJbSection/applyForJbModal";
import { Input, Progress } from "antd";
import ShareDocument from "../../../sections/shareDocumentSection";

const tlReviewProcedureModalRef = createRef();
const tlReviewModalRef = createRef();
const applyForJbModalRef = createRef();
class BaseFileManagement extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      procedureNoteDisabled: false,
      JbProcedureSaveIn: "",
      clientPath: this.props.clientPath,
      prevClientPath: this.props.clientPath,
      lastSaveClientPath: this.props.initClientServerPath,
      lastSubmitClientPath: this.props.initClientServerPath,
      addNewFileDisabled: false,
      deleteFileDisabled: true,
      downloadFileDisabled: true,
      saveDisabled: true,
      submitDisabled: this.props.className === "supportingModalFiles",
      cancelDisabled: true,
      initOrLastSaveData: this.props.initProcedureDataList,
      initOrLastSubmitData: this.props.initProcedureDataList,
      prevProcedureDataList: this.props.initProcedureDataList,
      initDataList: this.props.initProcedureDataList,
      deleteData: [],
      clientPathStatus: "",
      clientPathPlaceholder: "",
      fileTableStatus: "",
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      loading: false,
      downloadIcon: downloadIcon,
      shareIcon: shareIcon,
      savedInRadioValue: this.props.savedInRadioValue,
      serverPathDisabled: true,
      downloadLoading: false,
      downloadNum: 0,
      progress: 1,
      tlReview: {
        isLocked: false,
        lockName: "",
        isTlReviewModalOpen: false,
        tlReviewShowMessage: false,
        isTlReviewToolTipOpen: false,
        isTlReviewProcedureModalOpen: false,
        tlReviewModalRadioValue: null,
        tlReviewModalSubmitDisabled: true,
      },
      applyForJb: {
        applyForJbIsLocked: false,
        applyForJbLockName: "",
        isApplyForJbToolTipOpen: false,
        toolTipMessage: "",
        isApplyForJbModalOpen: false,
      },
      shareDocument: {
        isShareModalOpen: false,
        procedureFiles: [],
        supportingFiles: [],
        shareFiles: [],
        shareData: null,
        activeInternalUsers: [],
      },
    };
    this.deleteList = [];
    this.deleteNumsWithTag = 0;
    this.prevProcedureDataList = [];
    this.user = this.appSetting.localStorageValue.user.get();
  }

  /**
   * control save button disabled
   * @param {*} dataList table data
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   * @returns
   */
  isSaveDisable = (dataList, clientServerPath, procedureSavedInValue) => {
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const initOrLastSaveValue = this.props.initOrLastSaveProcedureSaveInValue;
    let isSaveDisable = false;
    if (this.props.clientPathShow) {
      if (procedureSavedInValue === savedInRadioValues.serverOnly) {
        isSaveDisable =
          clientServerPath === this.state.lastSaveClientPath &&
          procedureSavedInValue === initOrLastSaveValue;
      } else {
        isSaveDisable =
          tablePublicMethods.compaireTwoArray(dataList, initOrLastSaveData) &&
          clientServerPath === this.state.lastSaveClientPath &&
          procedureSavedInValue === initOrLastSaveValue;
      }
    } else {
      isSaveDisable = tablePublicMethods.compaireTwoArray(
        dataList,
        initOrLastSaveData
      );
    }

    return isSaveDisable;
  };

  /**
   * control submit button disabled
   * @param {*} dataList table data
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   * @returns
   */
  isSubmitDisable = (dataList, clientServerPath, procedureSavedInValue) => {
    const initOrLastSubmitData = this.state.initOrLastSubmitData;
    let isSubmitDisable = false;
    if (this.props.clientPathShow) {
      if (dataList.length === 0) {
        isSubmitDisable =
          procedureSavedInValue !== savedInRadioValues.serverOnly ||
          (clientServerPath === this.state.lastSubmitClientPath &&
            procedureSavedInValue ===
              this.props.initOrLastSubmitProcedureSaveInValue);
      } else {
        isSubmitDisable =
          tablePublicMethods.compaireTwoArray(dataList, initOrLastSubmitData) &&
          clientServerPath === this.state.lastSubmitClientPath &&
          procedureSavedInValue ===
            this.props.initOrLastSubmitProcedureSaveInValue;
      }
    } else {
      isSubmitDisable =
        dataList.length === 0 ||
        tablePublicMethods.compaireTwoArray(dataList, initOrLastSubmitData);
    }

    return isSubmitDisable;
  };

  /**
   * control cancel button disabled
   * @param {*} dataList table data
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   * @returns
   */
  isCancelDisable = (dataList, clientServerPath, procedureSavedInValue) => {
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const initOrLastSaveValue = this.props.initOrLastSaveProcedureSaveInValue;
    let isCancelDisable = false;

    if (this.props.clientPathShow) {
      if (procedureSavedInValue === savedInRadioValues.serverOnly) {
        isCancelDisable =
          clientServerPath === this.state.lastSaveClientPath &&
          procedureSavedInValue === initOrLastSaveValue;
      } else {
        isCancelDisable =
          tablePublicMethods.compaireTwoArray(dataList, initOrLastSaveData) &&
          clientServerPath === this.state.lastSaveClientPath &&
          procedureSavedInValue === initOrLastSaveValue;
      }
    } else {
      isCancelDisable = tablePublicMethods.compaireTwoArray(
        dataList,
        initOrLastSaveData
      );
    }

    return isCancelDisable;
  };

  /**
   * control save, submit and cancel buttons disabled
   * @param {*} dataList
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   */
  enableOrDisableSaveSubmitCancleButton = (
    dataList,
    clientServerPath,
    procedureSavedInValue = this.state.savedInRadioValue
  ) => {
    this.clearFileTableStatus();
    const isSaveDisable = this.isSaveDisable(
      dataList,
      clientServerPath,
      procedureSavedInValue
    );
    const isCancelDisable = this.isCancelDisable(
      dataList,
      clientServerPath,
      procedureSavedInValue
    );
    let isSubmitDisabled = false;
    if (this.props.className === "supportingModalFiles") {
      isSubmitDisabled = dataList.length > 0 ? false : true;
    }
    this.setState({
      saveDisabled: isSaveDisable,
      cancelDisabled: isCancelDisable,
      submitDisabled: isSubmitDisabled,
    });
  };

  getClientPath = () => {
    return this.state.clientPath;
  };

  getInitOrLastSaveData = () => {
    return this.state.initOrLastSaveData;
  };

  getLastSaveClientPath = () => {
    return this.state.lastSaveClientPath;
  };

  getDataList = () => {
    if (this.props.fileTableRef.current !== null) {
      return this.props.fileTableRef.current.state.data;
    }
    return [];
  };

  setDeleteList = (dataList) => {
    this.setState({ deleteData: dataList });
  };

  getDeleteList = () => {
    return this.state.deleteData;
  };

  /**
   * set client server path highlight
   * @param {*} status
   * @param {*} palaceholder
   */
  setClientPathStatus = (status, palaceholder) => {
    this.setState({
      clientPathStatus: status,
      clientPathPlaceholder: palaceholder,
    });
  };

  /**
   * clear client server path highlight
   */
  clearClientPathStatus = () => {
    this.setState({ clientPathStatus: "" });
  };

  /**
   * set procedure file table highlight
   * @param {*} status
   */
  setFileTableStatus = (status) => {
    this.setState({
      ...this.state,
      fileTableStatus: status,
    });
  };

  /**
   * clear procedure file table highlight
   */
  clearFileTableStatus = () => {
    this.setState({ fileTableStatus: "" });
  };

  /**
   * set data when click save
   */
  setInitOrLastSaveData = () => {
    let dataList = [];
    if (this.getDataList() !== undefined && this.getDataList().length > 0) {
      dataList = JSON.parse(JSON.stringify(this.getDataList()));
    }
    const clientPath = this.state.clientPath;
    this.setState({
      ...this.state,
      initOrLastSaveData: dataList,
      lastSaveClientPath: clientPath,
      saveDisabled: true,
      submitDisabled: false,
      cancelDisabled: true,
      deleteData: [],
    });
  };

  setInitOrLastSaveSubmitData = () => {
    const dataList = JSON.parse(JSON.stringify(this.getDataList()));
    const clientPath = this.state.clientPath;
    this.setState({
      ...this.state,
      initOrLastSaveData: dataList,
      initOrLastSubmitData: dataList,
      lastSaveClientPath: clientPath,
      lastSubmitClientPath: clientPath,
      saveDisabled: true,
      submitDisabled: false,
      cancelDisabled: true,
      addNewFileDisabled: true,
      deleteFileDisabled: true,
      downloadFileDisabled: true,
      deleteData: [],
    });
  };

  setInitSaveSubmitData = (list) => {
    const clientPath = this.state.clientPath;
    this.setState({
      ...this.state,
      initOrLastSaveData: list,
      initOrLastSubmitData: list,
      lastSaveClientPath: clientPath,
      lastSubmitClientPath: clientPath,
      saveDisabled: true,
      submitDisabled: false,
      cancelDisabled: true,
      addNewFileDisabled: false,
      deleteFileDisabled: true,
      downloadFileDisabled: true,
      deleteData: [],
    });
  };

  updateDataList = (
    dataList,
    clientPath = this.state.clientPath,
    procedureSavedInValue = this.state.savedInRadioValue
  ) => {
    this.enableOrDisableSaveSubmitCancleButton(
      dataList,
      clientPath,
      procedureSavedInValue
    );
    this.props.fileTableRef.current !== null &&
      this.props.fileTableRef.current.updateDataList(dataList);
  };

  updateClientPathAndDeleteData = (clientPath) => {
    this.setState({
      clientPath: clientPath,
      deleteData: [],
      deleteFileDisabled: true,
      downloadFileDisabled: true,
    });
  };

  cancelDeleteData = () => {
    this.setState({
      deleteData: [],
      deleteFileDisabled: true,
      downloadFileDisabled: true,
    });
  };

  _showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  _hideLoading = () => {
    this.setState({
      loading: false,
    });
  };

  _showDownloadLoading = () => {
    this.setState({
      downloadNum: 0,
      downloadLoading: true,
      progress: 1,
    });
  }

  _hideDownloadLoading = () => {
    this.setState({
      downloadLoading: false,
    });
  }

  addNewFile = () => {
    this.props.fileInputRef.current.click();
  };

  hideModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
  };

  _deleteFileOnSuccess = (result, fileId) => {
    // thre is a empty function
  };

  _deleteFileOnFail = (result, fileId) => {
    console.log(result);
    this._alertError("Delete Failed");
  };

  insertDeleteList = (newDeleteData, currentDeleteData) => {
    newDeleteData.forEach(function (item) {
      item = {
        ...item,
        data: item.data.map((x) => {
          if (x.name === "fileOperate") {
            return { name: "fileOperate", value: 3 };
          }

          return x;
        }),
      };
      if (!currentDeleteData.includes(item)) {
        currentDeleteData.push(item);
      }
    });
  };

  SplitSavedAndUnsavedData = (dataList) => {
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const savedData = [];
    const unSavedData = [];
    dataList.forEach((objA) => {
      const fileIdValue = objA.data.find(
        (item) => item.name === "fileId"
      ).value;
      const existsInB = initOrLastSaveData.some((objB) => {
        const fileIdBValue = objB.data.find(
          (item) => item.name === "fileId"
        ).value;
        return fileIdBValue === fileIdValue;
      });
      if (existsInB) {
        savedData.push(objA);
      } else {
        unSavedData.push(objA);
      }
    });

    return {
      savedData,
      unSavedData,
    };
  };

  DeleteUnsavedDeleteData = (unSavedDataList) => {
    if (unSavedDataList.length === 0) {
      return;
    }
    unSavedDataList.forEach((item) => {
      const fileId = item.data.find((x) => x.name === "fileId").value;
      let dto = {
        ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
        StorageFileID: fileId,
      };
      ProcedureDetailService.deleteFile(
        dto,
        this._deleteFileOnSuccess,
        this._deleteFileOnFail,
        fileId
      );
    });
  };

  handleDeleteModalConfirm = () => {
    const dataList = this.props.fileTableRef.current.state.data;
    let deleteList = dataList.filter((x) => {
      return x.checked === true;
    });
    let newDataList = dataList.filter((x) => {
      return x.checked === false;
    });
    this.openOrDisableDeleteButton(newDataList);
    this.enableOrDisableSaveSubmitCancleButton(
      newDataList,
      this.state.clientPath
    );
    this.props.fileTableRef.current.updateDataList(newDataList);
    if (deleteList === undefined) {
      this.setState({
        isModalOpen: false,
        modalChildContent: "",
        handleModalConfirm: () => {
          // thre is a empty function
        },
      });
      return;
    }
    let checkedDeleteData = this.SplitSavedAndUnsavedData(deleteList);
    this.DeleteUnsavedDeleteData(checkedDeleteData.unSavedData);
    let currentDeleteData = this.state.deleteData;
    this.insertDeleteList(checkedDeleteData.savedData, currentDeleteData);
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
      deleteData: currentDeleteData,
    });
  };

  handleReplaceModalConfirm = (replaceList) => {
    this.openOrDisableDeleteButton(replaceList);
    this.enableOrDisableSaveSubmitCancleButton(
      replaceList,
      this.state.clientPath
    );
    let checkedDeleteData = this.SplitSavedAndUnsavedData(replaceList);
    this.DeleteUnsavedDeleteData(checkedDeleteData.unSavedData);
    let currentDeleteData = this.state.deleteData;
    this.insertDeleteList(replaceList, currentDeleteData);
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
      deleteData: currentDeleteData,
    });
  };
  deleteFile = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Do you want to delete selected files?",
      handleModalConfirm: this.handleDeleteModalConfirm,
    });
  };

  replaceFile = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "This file already exists. Do you want to replace it?",
      handleModalConfirm: this.handleDeleteModalConfirm,
    });
  };

  getActiveInternalUsersSuccess = (result) => {
    const usersArr = [];
    result.map((item) => {
      const value = item.userID;
      const name = item.employeeFullName;
      usersArr.push({
        value,
        name,
      });
    });
    this.setState({
      shareDocument: {
        ...this.state.shareDocument,
        activeInternalUsers: sortUsersList(usersArr),
      },
    });
    this._hideLoading();
  };

  shareFile = () => {
    this._showLoading();
    ProcedureListService.getActiveInternalUserInfos(
      this.getActiveInternalUsersSuccess,
      () => this._alertError("Get Procedure Document List Failed")
    );

    const dataList = this.props.fileTableRef.current.state.data;
    const shareList = dataList.filter((x) => {
      return x.checked === true;
    });
    const shareFiles = [];
    shareList.map((data, index) => {
      const fileId = data.data.find((item) => item.name === "fileId").value;
      const fileName = data.data.find((item) => item.name === "fileName").value;
      shareFiles.push({
        fileId,
        fileName,
      });
    });

    const { accountID, accountAbbr, accountName, taskID, taskLabelName } =
      this.props.taskInformationData;

    this.setState({
      shareDocument: {
        ...this.state.shareDocument,
        isShareModalOpen: true,
        shareFiles,
        shareData: {
          procedureId: this.props.procedureId,
          procedureTaskId: this.props.procedureTaskId,
          accountID,
          accountAbbr,
          accountName,
          taskId: taskID,
          taskLabelName,
        },
      },
    });
  };

  handleCheckSavedInChangeToServerOnlyConfirm = (type) => {
    this.setState({ isModalOpen: false });
    type === "save"
      ? this.props.onSave()
      : this.props.handleSubmitModalConfirm();
  };

  checkSavedInChangeToServerOnly = (type) => {
    this.setState({
      isModalOpen: true,
      modalChildContent:
        "You have changed the Procedure Saved In value to Client Server Only, and all of the existing procedure files will be deleted. Please confirm.",
      handleModalConfirm: () =>
        this.handleCheckSavedInChangeToServerOnlyConfirm(type),
    });
  };

  closeWindow = () => {
    const ele = document.createElement("button");
    ele.onclick = () => {
      window.close();
    };
    document.body.appendChild(ele);
    ele.click();
  };

  downloadDetailFilesSuccessfully = () => {
    const dataList = this.props.fileTableRef.current.state.data;
    let downloadList = dataList.filter((x) => {
      return x.checked === true;
    });

    const isDownloadDone = this.state.downloadNum + 1 === downloadList.length;
    const downloadProcess = (this.state.downloadNum + 1) * 100 / downloadList.length;

    if(isDownloadDone) {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: 100,
      })
      setTimeout(() => {
        this.setState({
          downloadLoading: false,
        })
      }, 1000);
    } else {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: parseInt(downloadProcess),
      })
    }
  }

  /**
   * call back about download file
   * @param {*} response
   * @param {*} fileName
   */
  _downloadFileByUrlSuccess = (response, fileName, callback, fileList, index) => {
    if (response !== undefined && response !== "") {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        callback && callback();

        if(fileList.length === index + 1) {
          return;
        }
        this._downloadFileItemByUrl(fileList, index+1, callback);
      });
    }
  };

  _downloadFileItemByUrl=(fileList, index, callback)=>{
    const item = fileList[index];  
    const registerFileShareParam = {
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      storageFileID: [item.fileID],
      expiry: {
        expiry: 1,
        expiryType:  "Days",
      }
    };
    ProcedureDetailService.downloadFile(
      registerFileShareParam,
      (response) => this._downloadFileByUrlSuccess(response, item.fileName, callback, fileList, index),
      (error) => {
        this._alertError("Download File Failed!");
        console.log('error',error);
      },
      item,
    );
  }

  downloadFileByUrl = (fileList, callback) => {
    this._downloadFileItemByUrl(fileList, 0, callback);
  }

  baseDownloadFiles = (callback) => {
    const dataList = this.props.fileTableRef.current.state.data;
    let downloadList = dataList.filter((x) => {
      return x.checked === true;
    });
    let fileList = [];
    downloadList.map((data, index) => {
      const fileID = data.data.find((item) => item.name === "fileId").value;
      const fileName = data.data.find((item) => item.name === "fileName").value;
      fileList.push({
        fileID,
        fileName,
      });
    });
    this.props.fileTableRef.current.updateDataList(dataList);
    this.downloadFileByUrl(fileList, callback);
  };

  /**
   * function about download file
   */
  downloadFile = () => {
    this.baseDownloadFiles();
  }

  /**
   * change client server path
   * @param {*} e
   */
  clientPathOnChange = (e) => {
    this.setState({
      clientPath: e.target.value,
      prevClientPath: e.target.value,
      clientPathStatus: "",
      clientPathPlaceholder: "",
    });
    this.props.fileTableRef.current !== null
      ? this.enableOrDisableSaveSubmitCancleButton(
          this.props.fileTableRef.current.state.data,
          e.target.value
        )
      : this.enableOrDisableSaveSubmitCancleButton([], e.target.value);
  };

  /**
   * control table delete button diabled
   * @param {*} dataList table data
   */
  openOrDisableDeleteButton = (dataList) => {
    const checked = dataList.find((x) => x.checked === true);
    if (checked === undefined) {
      this.setState({
        deleteFileDisabled: true,
        downloadFileDisabled: true,
      });
    } else {
      this.setState({
        deleteFileDisabled: false,
        downloadFileDisabled: false,
      });
    }
  };

  /**
   * function about control table header checked
   * @param {*} lineId current row lineId
   * @returns
   */
  _check = (lineId) => {
    if (this.props.fileTableRef.current !== null) {
      const dataList = this.props.fileTableRef.current.state.data;
      let currentRow =
        dataList !== null &&
        dataList !== undefined &&
        dataList.find(
          (x) => x.data.find((y) => y.name === "lineId").value === lineId
        );
      return !!(
        currentRow !== undefined &&
        currentRow.checked !== undefined &&
        currentRow.checked
      );
    }
  };

  /**
   * function about control table header checked when chehck each row
   * @param {*} lineId current row lineId
   * @param {*} ele current checkbox
   */
  _onCheck = (lineId, ele) => {
    const dataList =
      this.props.fileTableRef.current !== null &&
      this.props.fileTableRef.current.state.data;
    let currentRow = dataList.find(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );
    const currentRowIndex = dataList.findIndex(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );

    if (ele.checked) {
      currentRow = { ...currentRow, checked: true };
    } else {
      currentRow = { ...currentRow, checked: false };
    }

    dataList[currentRowIndex] = currentRow;
    this.openOrDisableDeleteButton(dataList);
    this.props.fileTableRef.current.updateDataList(dataList);
  };

  /**
   * function about control table header checked
   * conditions:
   *  1. procedure status isn't delete
   *  2. procedure status is delete && status isn't null (isn't delete is previous step)
   *  3. procedure status is delete && status isn't null (current step delete this procedure)
   * @param {*} dataList table data
   * @returns
   */
  controlApproveAndRevisePageHeadCheck = (dataList) => {
    return dataList.find(
      (x) =>
        (x.data.find((dataItem) => dataItem.name === "fileName").tagValue !==
          fileTags.deleted ||
          (x.data.find((dataItem) => dataItem.name === "fileName").tagValue ===
            fileTags.deleted &&
            x.data.find((dataItem) => dataItem.name === "status").value !==
              null)) &&
        x.checked !== true
    );
  };

  /**
   * function about control procedure table header checked
   * @returns
   */
  _headCheck = () => {
    if (
      this.props.fileTableRef === undefined ||
      this.props.fileTableRef.current === null
    ) {
      return false;
    }
    const dataList =
      this.props.fileTableRef !== undefined &&
      this.props.fileTableRef.current.state.data;
    if (dataList.length === 0) {
      return false;
    }

    let checked = false;
    checked = dataList.find((x) => x.checked !== true);
    return checked === undefined;
  };

  /**
   * function about get data from table list's data
   * @param {*} index current row index
   * @param {*} name name in data array
   * @param {*} field
   * @returns
   */
  handleGetDataEle = (index, name, field) => {
    const dataList = this.props.fileTableRef.current.state.data;
    return (
      dataList[index].data.find((dataItem) => dataItem.name === name) !==
        undefined &&
      dataList[index].data.find((dataItem) => dataItem.name === name)[field]
    );
  };

  /**
   * function about control row's checkbox when check table header checkbox
   * @param {*} ele table header checkbox element
   */
  _headOnCheck = (ele) => {
    const dataList = this.props.fileTableRef.current.state.data;
    if (ele.checked) {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: true };
      }
    } else {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: false };
      }
    }
    this.openOrDisableDeleteButton(dataList);
    this.props.fileTableRef.current.updateDataList(dataList);
  };

  /**
   * function about input change in file table
   * @param {*} ele current change input element
   */
  inputOnChange = (ele) => {
    const data = this.props.fileTableRef.current.state.data;
    const dataName = ele.getAttribute("dataname");
    const rowIndex = ele.getAttribute("rowindex");
    let currentRow = data[rowIndex];
    currentRow.data.find((x) => x.name === dataName).value = ele.value;
    data[rowIndex] = currentRow;
    if (this.props.hasSave || this.props.pageType === "procedureDetail") {
      this.enableOrDisableSaveSubmitCancleButton(data, this.state.clientPath);
    }
    this.props.fileTableRef.current.updateDataList(data);
  };

  /**
   * return donwload button in table
   * @param {*} isShowDownloadBtn flag of whether show download button
   * @param {*} downloadFileDisabled flag of whether disable download button
   * @returns
   */
  handleReturnDownloadBtn = (isShowDownloadBtn, downloadFileDisabled) => {
    const { downloadLoading, progress } = this.state;
    return (
      isShowDownloadBtn && (
        <Buttons
          color="black"
          size="middle"
          onMouseEnter={() =>
            this.setState({ downloadIcon: downloadHoverIcon })
          }
          onMouseLeave={() => this.setState({ downloadIcon: downloadIcon })}
          onClick={this.downloadFile}
          disabled={downloadFileDisabled || downloadLoading}
        >
          <div className={`${downloadLoading ? "downloadButton" : ""}`}>
            {downloadLoading ? (
              <Progress
                type="circle"
                percent={progress}
                width={18}
              />
            ) : (
              <img
                src={this.state.downloadIcon}
                alt="Download"
                className={styles.downloadIcon}
              />
            )}
            <span>Download</span>
          </div>
        </Buttons>
      )
    );
  };

  /**
   * return share button in table
   * @param {*} isShowShareBtn flag of whether show share button
   * @param {*} isDisabled flag of whether disable share button
   * @returns
   */
  handleReturnShareBtn = (isShowShareBtn, isDisabled) =>
    isShowShareBtn && (
      <Buttons
        color="black"
        size="middle"
        onMouseEnter={() => this.setState({ shareIcon: shareHoverIcon })}
        onMouseLeave={() => this.setState({ shareIcon: shareIcon })}
        onClick={this.shareFile}
        disabled={isDisabled}
      >
        <img
          src={this.state.shareIcon}
          alt="Share"
          className={styles.shareIcon}
        />
        Share
      </Buttons>
    );

  /**
   * return update button in table
   * @param {*} isShowUpdate flag of whether show update button
   * @returns
   */
  handleReturnUpdateBtn = (isShowUpdate) =>
    isShowUpdate && (
      <Buttons color="blue" disabled={this.props.status === 3} size="middle">
        Update
      </Buttons>
    );

  /**
   * return upload button in table
   * @param {*} isShowUploadBtn flag of whether show upload button
   * @param {*} isDisabled flag of whether disable upload button
   * @param {*} handleFileSelect upload file method
   * @param {*} fileSectionDisabled flag of whether tbody diabled
   * @returns
   */
  handleReturnUploadBtn = (isShowUploadBtn, isDisabled, handleFileSelect) =>
    isShowUploadBtn && (
      <>
        <Buttons
          color="blue"
          disabled={isDisabled}
          size="middle"
          onClick={this.addNewFile}
        >
          Upload
        </Buttons>
        <UploadFile
          handleFileSelect={handleFileSelect}
          fileInputRef={this.props.fileInputRef}
          isMultiple={true}
        />
      </>
    );

  /**
   * return delete button in table
   * @param {*} isShowDeleteBtn flag of whether show delete button
   * @param {*} isDisabled flag of whether disable delete button
   * @param {*} fileSectionDisabled flag of whether tbody diabled
   * @returns
   */
  handleReturnDeleteBtn = (isShowDeleteBtn, isDisabled) =>
    isShowDeleteBtn && (
      <Buttons
        color="blue"
        disabled={isDisabled}
        size="middle"
        onClick={this.deleteFile}
      >
        Delete
      </Buttons>
    );

  /**
   * return save, submit and cancel buttons in table
   * @param {*} isShowActionBtns flag of whether show these buttons
   * @param {*} saveDisabled flag of whether disable save button
   * @param {*} onSave method for save
   * @param {*} submitDisabled flag of whether disable submit button
   * @param {*} onSubmit method for submit
   * @param {*} cancelDisabled flag of whether disable cancel button
   * @param {*} onCancel method for cancel
   * @returns
   */
  handleReturnActionBtns = (obj) => {
    const {
      saveDisabled,
      onSave,
      submitDisabled,
      onSubmit,
      cancelDisabled,
      onCancel,
      savedInRadioValue,
      procedureMandatoryCheck,
    } = obj;
    const initSavedInValue = this.props.initOrLastSaveProcedureSaveInValue;
    const shouldShowErrorSystem =
      this.state.savedInRadioValue !== savedInRadioValues.systemOnly &&
      this.state.clientPath !== undefined &&
      this.state.clientPath.trim() === "";
    return (
      <div className={styles.buttonDiv}>
        <Buttons
          color="grey"
          disabled={saveDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer =
              this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
              this.getDataList().length === 0;
            const isShowModal =
              initSavedInValue !== savedInRadioValue &&
              savedInRadioValue === savedInRadioValues.serverOnly &&
              this.prevProcedureDataList.length > 0;
            if (shouldShowErrorServer || shouldShowErrorSystem) {
              procedureMandatoryCheck();
            } else if (isShowModal) {
              this.checkSavedInChangeToServerOnly("save");
            } else {
              onSave();
            }
          }}
        >
          Save as Draft
        </Buttons>
        <Buttons
          color="grey"
          disabled={cancelDisabled}
          size="middle"
          onClick={onCancel}
        >
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer =
              this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
              this.getDataList().length === 0;
            const isShowModal =
              initSavedInValue !== savedInRadioValue &&
              savedInRadioValue === savedInRadioValues.serverOnly &&
              this.prevProcedureDataList.length > 0;
            if (shouldShowErrorServer || shouldShowErrorSystem) {
              procedureMandatoryCheck();
            } else if (isShowModal) {
              this.checkSavedInChangeToServerOnly("submit");
            } else {
              onSubmit();
            }
          }}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };

  /* function get param to use in download file api */
  getAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  updateRadioValue = (value) => {
    if (value === savedInRadioValues.serverOnly || value === null) {
      const dataList = deepClone(this.getDataList());
      this.prevProcedureDataList = dataList;
    }
    this.setState({ savedInRadioValue: value });
  };

  updateServerPathValue = (value) => {
    this.setState({ clientPath: value === null ? "" : value });
  };

  updateJbProcedureSaveIn = (value) => {
    this.setState({ JbProcedureSaveIn: value });
  };

  updateLastSaveClientPath = (value) => {
    this.setState({
      lastSaveClientPath: value,
    });
  };

  updateInitDataList = (list) => {
    this.setState({
      initDataList: list,
    });
  };

  getValue = () => {
    return this.state;
  };

  checkTlReviewWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.setState({
        tlReview: {
          ...this.state.tlReview,
          isLocked: true,
          lockName: lockUser,
          isTlReviewToolTipOpen: true,
        },
      });
    } else {
      this.setState({
        tlReview: {
          ...this.state.tlReview,
          isLocked: false,
          lockName: "",
          isTlReviewToolTipOpen: false,
        },
      });
      this._setLock(this.props.procedureId, "TLReview", true);
      const para = {
        procedureId: this.props.procedureId,
        procedureTaskId: this.props.procedureTaskId,
        procedureAccountId: this.props.procedureAccountId,
      };
      tlReviewService.checkTlReviewEdit(
        para,
        this.checkTlReviewEditSuccess,
        this.checkTlReviewEditFail
      );
    }
  };

  checkApplyForJbWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.setState({
        applyForJb: {
          ...this.state.applyForJb,
          applyForJbIsLocked: true,
          applyForJbLockName: lockUser,
          isApplyForJbToolTipOpen: true,
          toolTipMessage: `${lockUser} is editing this section.`,
        },
      });
    } else {
      this.setState({
        applyForJb: {
          ...this.state.applyForJb,
          applyForJbIsLocked: false,
          applyForJbLockName: "",
          isApplyForJbToolTipOpen: false,
          toolTipMessage: "",
        },
      });
      this._setLock(this.props.procedureId, "ApplyForJb", true);
      this.props.setActionType("ApplyForJb");
      const para = {
        procedureId: this.props.procedureId,
        procedureTaskId: this.props.procedureTaskId,
        procedureAccountId: this.props.procedureAccountId,
        flowBusinessId: flowBusinessIdMap.jBStatus,
      };
      applyForJbService.checkApplyJBStatusSubmit(
        para,
        this.checkApplyJBStatusSuccess,
        this.checkApplyJBStatusFail
      );
    }
  };

  handleApplyForOption = (JbProcedureSaveIn) => {
    if (JbProcedureSaveIn === null) {
      return null;
    }

    const statusOption = JbProcedureSaveIn === "JB" ? "Non JB" : "JB";

    return [
      {
        value: "",
        name: "",
      },
      {
        value: procedureJbStatusMap[statusOption],
        name: statusOption,
      },
    ];
  };

  checkApplyJBStatusSuccess = (response) => {
    if (response.result) {
      this.setState({
        applyForJb: {
          ...this.state.applyForJb,
          isApplyForJbToolTipOpen: false,
          toolTipMessage: "",
          isApplyForJbModalOpen: true,
        },
      });
    } else {
      this.setState({
        applyForJb: {
          ...this.state.applyForJb,
          isApplyForJbToolTipOpen: true,
          toolTipMessage: shareTaskMassage[response.messageCode],
        },
      });
      this.releaseJbStatusLock(() => {
        // thre is a empty function
      });
    }
  };

  checkApplyJBStatusFail = () => {
    this._alertError("Check Apply JB Status");
  };

  checkTlReviewEditSuccess = (response) => {
    if (response.result) {
      this.setState({
        tlReview: {
          ...this.state.tlReview,
          isTlReviewModalOpen: true,
          tlReviewShowMessage: false,
        },
      });
    } else {
      this.setState({
        tlReview: {
          ...this.state.tlReview,
          isTlReviewModalOpen: true,
          tlReviewShowMessage: true,
        },
      });
    }
  };

  checkTlReviewEditFail = () => {
    this._alertError("Check TlReview Edit Fail");
  };

  createTLReviewProcedureSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Submit Successful");
    this.ReleaseLock();
  };

  createTLReviewProcedureFail = () => {
    this._alertError("Create TLReview Procedure Fail");
  };

  createTlReviewToDo = () => {
    this.props._showLoading();
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        isTlReviewModalOpen: false,
        tlReviewModalRadioValue: null,
        tlReviewModalSubmitDisabled: true,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    const para = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureProcessId: null,
      isPendingSubmittal: true,
      reviewResult: null,
      fileList: null,
    };
    tlReviewService.createTLReviewProcedure(
      para,
      this.createTLReviewProcedureSuccess,
      this.createTLReviewProcedureFail
    );
  };

  tlReviewProcedureOnSubmit = (reviewResultText, fileList) => {
    if (reviewResultText.trim() === "") {
      tlReviewProcedureModalRef.current.showInputError();
    } else {
      this.setState({
        isModalOpen: true,
        modalChildContent: "Are you sure to submit?",
        handleModalConfirm: () => {
          this.props._showLoading();
          this.setState({
            isModalOpen: false,
            tlReview: {
              ...this.state.tlReview,
              isTlReviewProcedureModalOpen: false,
            },
          });
          this.submitTlReviewProcedure(reviewResultText, fileList);
        },
      });
    }
  };

  refreshPage = () => {
    window.location.reload();
  };

  releaseJbStatusLock = (onSuccess) => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: "ApplyForJb",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        onSuccess();
      },
      (response) => {
        //empty function
      }
    );
  };

  jbStatusSubmitForApprovalSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Submit Successful");
    this.releaseJbStatusLock(this.refreshPage);
  };

  jbStatusSubmitForApprovalFail = () => {
    this.props._hideLoading();
    this._alertError("Submit Failed");
  };

  submitApplyJbProcedure = (jbModalData, dataList) => {
    const fileList =
      jbModalData.savedInRadioValue !== savedInRadioValues.serverOnly
        ? dataList.map((item) => {
            return {
              procedureFileId: item.data.find(
                (x) => x.name === "procedureFileID"
              ).value,
              jbProcedure:
                procedureJbStatusMap[
                  item.data.find((x) => x.name === "jbProcedure").value
                ],
              applyfor:
                procedureJbStatusMap[
                  item.data.find((x) => x.name === "applyfor").value
                ],
              fileKey: item.data.find((x) => x.name === "fileKey").value,
            };
          })
        : [];
    const para = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureSaveInCategoryID: jbModalData.savedInRadioValue,
      clientServerPath: jbModalData.clientPath,
      jbProcedureSavedIn: jbModalData.JbProcedureSaveIn,
      applyfor:
        jbModalData.savedInRadioValue !== savedInRadioValues.systemOnly
          ? procedureJbStatusMap[jbModalData.applyForValue]
          : "",
      fileList: fileList,
    };
    applyForJbService.jbStatusSubmitForApproval(
      para,
      this.jbStatusSubmitForApprovalSuccess,
      this.jbStatusSubmitForApprovalFail
    );
  };

  checkTableApplyforNoJB = (savedInRadioValue, dataList) => {
    if (savedInRadioValue === savedInRadioValues.serverOnly) {
      return true;
    }

    const nonToJb = dataList.findIndex(
      (x) => x.data.find((x) => x.name === "applyfor").value === "JB"
    );

    const jBNoChange = dataList.findIndex(
      (x) =>
        x.data.find((x) => x.name === "jbProcedure").value === "JB" &&
        x.data.find((x) => x.name === "applyfor").value === ""
    );

    if (nonToJb === -1 && jBNoChange === -1) {
      return true;
    }

    return false;
  };

  checkApplyforNoJB = (savedInRadioValue, JbProcedureSaveIn, applyForValue) => {
    if (savedInRadioValue === savedInRadioValues.systemOnly) {
      return true;
    }

    const nonToJb = applyForValue === "JB";

    const jBNoChange =
      applyForValue === "" && JbProcedureSaveIn === procedureJbStatusMap.JB;

    if (!nonToJb && !jBNoChange) {
      return true;
    }

    return false;
  };

  checkApplyJBSubmitSuccess = (result, returnPara) => {
    if (result.result) {
      const tableApplyforNoJB = this.checkTableApplyforNoJB(
        returnPara.jbModalData.savedInRadioValue,
        returnPara.dataList
      );
      const applyforNoJB = this.checkApplyforNoJB(
        returnPara.jbModalData.savedInRadioValue,
        returnPara.jbModalData.JbProcedureSaveIn,
        returnPara.jbModalData.applyForValue
      );
      if (!tableApplyforNoJB || !applyforNoJB) {
        this.setState({
          isModalOpen: true,
          modalChildContent: "Are you sure to submit?",
          handleModalConfirm: () => {
            this.props._showLoading();
            this.setState({
              isModalOpen: false,
              applyForJb: {
                ...this.state.applyForJb,
                isApplyForJbModalOpen: false,
              },
            });
            this.submitApplyJbProcedure(
              returnPara.jbModalData,
              returnPara.dataList
            );
          },
        });
      } else {
        this.setState({
          isModalOpen: true,
          modalChildContent: (
            <span>
              Are you sure to submit? There is no JB procedure and the{" "}
              <strong>Procedure Status</strong> will be changed to{" "}
              <strong>Non JB</strong>.
            </span>
          ),
          handleModalConfirm: () => {
            this.props._showLoading();
            this.setState({
              isModalOpen: false,
              applyForJb: {
                ...this.state.applyForJb,
                isApplyForJbModalOpen: false,
              },
            });
            this.submitApplyJbProcedure(
              returnPara.jbModalData,
              returnPara.dataList
            );
          },
        });
      }
    } else {
      this._alertError(
        "You cannot submit Apply for JB Status process while there is a Sharing Relationship approval in progress.",
        7
      );
    }
  };

  checkApplyJBSubmitFail = (result) => {
    console.log(result);
  };

  applyForJbCheckSubmit = (jbModalData, dataList) => {
    const para = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureAccountId: this.props.procedureAccountId,
      flowBusinessId: flowBusinessIdMap.shareTask,
    };
    const returnPara = {
      jbModalData: jbModalData,
      dataList: dataList,
    };
    applyForJbService.checkApplyJBStatusSubmit(
      para,
      this.checkApplyJBSubmitSuccess,
      this.checkApplyJBSubmitFail,
      returnPara
    );
  };

  applyForJbOnSubmit = (jbModalData, dataList) => {
    this.applyForJbCheckSubmit(jbModalData, dataList);
  };

  submitTlReviewProcedure = (reviewResultText, fileList) => {
    const list = fileList.map((item) => {
      return {
        fileId: item.storageFileID,
        fileName: item.name,
      };
    });
    const para = {
      procedureId: this.props.procedureId,
      procedureProcessId: null,
      procedureTaskId: this.props.procedureTaskId,
      isPendingSubmittal: false,
      reviewResult: reviewResultText,
      fileList: list,
    };
    tlReviewService.createTLReviewProcedure(
      para,
      this.createTLReviewProcedureOnSuccess,
      this.createTLReviewProcedureOnError
    );
  };

  createTLReviewProcedureOnSuccess = () => {
    this.props._hideLoading();
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        isTlReviewProcedureModalOpen: false,
        tlReviewModalRadioValue: null,
        tlReviewModalSubmitDisabled: true,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    this.ReleaseLock();
    this._alertSuccess("Submit Successful");
    tlReviewProcedureModalRef.current.setDefault();
  };

  createTLReviewProcedureOnError = () => {
    this._alertError("Submit Failed");
  };

  applyForJbOnCancel = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.cancelApplyJbProcedure,
    });
  };

  tlReviewProcedureOnCancel = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.cancelTlReviewProcedure,
    });
  };

  cancelApplyJbProcedure = () => {
    this.setState({
      applyForJb: {
        ...this.state.applyForJb,
        isApplyForJbModalOpen: false,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    this.releaseJbStatusLock(() => {
      // thre is a empty function
    });
    this.props.setActionType(null);
    // applyForJbModalRef.current.setDefault();
  };

  cancelTlReviewProcedure = () => {
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        isTlReviewModalOpen: true,
        isTlReviewProcedureModalOpen: false,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    tlReviewProcedureModalRef.current.setDefault();
  };

  tlReviewOnSubmit = (radioValue) => {
    if (radioValue === 1) {
      this.setState({
        isModalOpen: true,
        modalChildContent: "Are you sure to create a Review Procedure to-do?",
        handleModalConfirm: this.createTlReviewToDo,
      });
    } else {
      this.setState({
        tlReview: {
          ...this.state.tlReview,
          isTlReviewModalOpen: false,
          isTlReviewProcedureModalOpen: true,
        },
        isModalOpen: false,
        modalChildContent: "",
        handleModalConfirm: () => {
          // thre is a empty function
        },
      });
    }
  };

  tlReviewCancel = () => {
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        isTlReviewModalOpen: false,
        tlReviewModalRadioValue: null,
        tlReviewModalSubmitDisabled: true,
      },
    });
    this.ReleaseLock();
  };

  tlReviewModalRadioOnChange = (e) => {
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        tlReviewModalRadioValue: e.target.value,
        tlReviewModalSubmitDisabled: false,
      },
    });
  };

  ReleaseLock = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: "TLReview",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        //this.is an empty function
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  CheckLock = () => {
    this.actionType = "TLReview";
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.actionType,
      timeOutMins: 20,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkTlReviewWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => {
        console.log(response.message);
      }
    );
  };

  CheckApplyForJbLock = () => {
    this.actionType = "ApplyForJb";
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.actionType,
      timeOutMins: 20,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkApplyForJbWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => {
        console.log(response.message);
      }
    );
  };

  tlReviewOnClick = () => {
    this.CheckLock();
  };

  applyForJbOnClick = () => {
    this.CheckApplyForJbLock();
  };

  handleCheckMessage = (isLocked, lockName) => {
    if (isLocked) {
      return `${lockName} is editing the TL Review Procedure Page.`;
    }
    return "";
  };

  handleJbProcedureSavedValue = (JbProcedureSaveIn) => {
    if (!JbProcedureSaveIn || JbProcedureSaveIn.trim() === "") {
      return null;
    }

    return procedureJbStatus[JbProcedureSaveIn];
  };

  getCoverSheetUrl = () => {
    const getCoverSheetParam = {
      ProcedureId: this.props.procedureId,
      ProcedureTaskId: this.props.procedureTaskId,
    };
    ProcedureListService.getCoverSheet(
      getCoverSheetParam,
      (result) => this.getCoverSheetLinkSuccess(result.content),
      () => console.log("Get Cover Sheet Failed")
    );
  };

  getCoverSheetLinkSuccess = (base64String) => {
    this.openPDFOnline(base64String, "application/pdf");
  };

  openPDFOnline = (base64, contentType) => {
    const arr = base64.split(",");
    const contentIndex = arr.length > 1 ? 1 : 0;
    const bstr = atob(arr[contentIndex]);
    let leng = bstr.length;
    let u8arr = new Uint8Array(leng);
    while (leng--) {
      u8arr[leng] = bstr.charCodeAt(leng);
    }
    const blob = new Blob([u8arr], { type: contentType });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  downloadPDF = (base64String, filename) => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64String}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  applyJbButtonOnBlur = () => {
    this.setState({
      applyForJb: {
        ...this.state.applyForJb,
        isApplyForJbToolTipOpen: false,
      },
    });
  };

  tlReviewButtonOnblur = () => {
    this.setState({
      tlReview: {
        ...this.state.tlReview,
        isTlReviewToolTipOpen: false,
      },
    });
  };

  componentDidMount() {
    this.props.initProcedureDataList &&
      this.updateDataList(
        JSON.parse(JSON.stringify(this.props.initProcedureDataList))
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.savedInRadioValue === savedInRadioValues.serverOnly &&
        this.state.savedInRadioValue !== savedInRadioValues.serverOnly) ||
      (prevState.savedInRadioValue === null &&
        this.state.savedInRadioValue !== null)
    ) {
      this.props.fileTableRef.current.updateDataList(
        this.prevProcedureDataList
      );
      this.enableOrDisableSaveSubmitCancleButton(
        this.prevProcedureDataList,
        this.state.clientPath,
        this.state.savedInRadioValue
      );
    }
    if (
      prevState.savedInRadioValue !== this.state.savedInRadioValue &&
      this.state.savedInRadioValue === savedInRadioValues.systemOnly
    ) {
      this.setState({ prevClientPath: this.state.clientPath, clientPath: "" });
    }
    if (
      prevState.savedInRadioValue !== this.state.savedInRadioValue &&
      this.state.savedInRadioValue !== savedInRadioValues.systemOnly &&
      this.state.prevClientPath !== ""
    ) {
      this.setState({ clientPath: this.state.prevClientPath });
    }
    if (this.props.initClientServerPath !== prevProps.initClientServerPath) {
      this.setState({
        clientPath: this.props.initClientServerPath,
        prevClientPath: this.props.initClientServerPath,
      });
    }
    if (this.state.clientPath !== prevState.clientPath) {
      const dataList =
        this.props.fileTableRef.current === null
          ? []
          : this.props.fileTableRef.current.state.data;
      this.enableOrDisableSaveSubmitCancleButton(
        dataList,
        this.state.clientPath,
        this.state.savedInRadioValue
      );
    }
  }

  /**
   * function about return table columns
   * @param {object} obj
   * @returns
   */
  handleReturnColumns = (obj) => {
    const {
      noteRef,
      status,
      noteDisabled,
      savedInRadioValue,
      inModal,
      isProcedureFile,
    } = obj;
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Note",
        dataName: "note",
        type: "input",
        ref: noteRef,
        isShow: status !== 3 && status !== 7 && !this.props.isCopyPage,
        isDisabled: noteDisabled,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: status === 3 || status === 7 || this.props.isCopyPage,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow:
          (status === 3 || status === 7) &&
          savedInRadioValue !== savedInRadioValues.serverOnly &&
          !inModal &&
          isProcedureFile &&
          !this.props.isCopyPage,
      },
    ];
  };

  /**
   * function about return table
   * @param {*} obj
   * @returns
   */
  handleReturnProcedureFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      hasSubmit,
      isScrollData,
      className,
      optionList,
      handleReturnClick,
      handleReplaceFileSelect,
      currentRole,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      fileTableStatus,
      isShowHistoryVersion,
    } = obj;
    return (
      <DetailFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleReplaceFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        fileTableStatus={fileTableStatus}
        isShowHistoryVersion={isShowHistoryVersion}
      />
    );
  };

  handleReturnJbProcedureSavedIn = (
    savedInRadioValue,
    JbProcedureSaveIn,
    status,
    inModal
  ) => {
    return (
      (status === 3 || status === 7) &&
      savedInRadioValue !== savedInRadioValues.systemOnly &&
      !inModal && (
        <div className={styles.jbProcedureDiv}>
          <span>JB Procedure Saved</span>
          <div className={styles.jbProcedureInputDiv}>
            <Input
              value={this.handleJbProcedureSavedValue(JbProcedureSaveIn)}
              disabled
              className="jbProcedureInput"
            />
          </div>
        </div>
      )
    );
  };

  handleSectionEditButton = () => {
    const { 
      handleEditClick, 
      onBlur, hintMsg, 
      isEditToolTipOpen, 
      isShowProcedureFileEdit,
    } = this.props;

    if(isShowProcedureFileEdit) {
      return (
        <NormalToolTip
          element={
            <SectionEditIcon
              handleEditClick={handleEditClick}
              onBlur={onBlur}
              editRef={this.props.editRef}
            />
          }
          title={hintMsg}
          trigger="click"
          open={isEditToolTipOpen}
          placement="left"
          color="#3E6CB5"
        />
      )
    }
  }

  render() {
    const {
      title,
      fileTableRef,
      clientPathShow,
      handleFileSelect,
      onSave,
      onSubmit,
      onCancel,
      hasSubmit,
      handleRadioChange,
      isScrollData,
      className,
      noteRef,
      handleReturnClick,
      currentRole,
      handleReplaceFileSelect,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      isShowDownloadBtn,
      isShowShareBtn,
      isShowUploadBtn,
      isShowDeleteBtn,
      savedInDisabled,
      isShowActionBtns,
      isShowUpdate,
      isShowHistoryVersion,
      status,
      noteDisabled,
      optionList,
      procedureMandatoryCheck,
      isShowTLButton,
      isShowApplyJbButton,
      inModal,
      handleRadioClick,
      isProcedureSection,
      isSupportingSection,
      isProcedureFile,
      procedurePendingProcessId,
      supportingPendingProcessId,
      isShowSavedInIcon,
    } = this.props;

    const {
      addNewFileDisabled,
      deleteFileDisabled,
      downloadFileDisabled,
      saveDisabled,
      submitDisabled,
      cancelDisabled,
      clientPathStatus,
      clientPathPlaceholder,
      fileTableStatus,
      isModalOpen,
      modalChildContent,
      handleModalConfirm,
      loading,
      JbProcedureSaveIn,
    } = this.state;

    const {
      isLocked,
      lockName,
      isTlReviewModalOpen,
      tlReviewShowMessage,
      isTlReviewToolTipOpen,
      isTlReviewProcedureModalOpen,
      tlReviewModalRadioValue,
      tlReviewModalSubmitDisabled,
    } = this.state.tlReview;
    const { isApplyForJbToolTipOpen, toolTipMessage, isApplyForJbModalOpen } =
      this.state.applyForJb;

    const {
      isShareModalOpen,
      procedureFiles,
      supportingFiles,
      shareFiles,
      shareData,
      activeInternalUsers,
    } = this.state.shareDocument;

    const { clientPath, savedInRadioValue } = this.getValue();

    const columns = this.handleReturnColumns({
      noteRef,
      status,
      noteDisabled,
      savedInRadioValue,
      inModal,
      isProcedureFile,
    });

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        zIndex={1500}
        children={
          <>
            <div className={styles.fileManagementDiv}>
              <div className={styles.fileManagementTop}>
                <p className={styles.fileManagementTitle}>
                  <span className={`${styles.titleText} fs-18`}>{title}</span>
                </p>
                <div className={styles.filesPendingMsgWrapper}>
                  {isProcedureSection && procedurePendingProcessId && (
                    <div className={styles.filesPendingMsg}>
                      The new procedure files are pending approval,&nbsp;
                      <a
                        className={styles.filesPendingMsgLink}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"/ProcessDetail/" + procedurePendingProcessId}
                      >
                        click here
                      </a>
                      &nbsp;to view files.
                    </div>
                  )}
                  {isSupportingSection && supportingPendingProcessId && (
                    <div className={styles.filesPendingMsg}>
                      The new supporting files are pending approval,&nbsp;
                      <a
                        className={styles.filesPendingMsgLink}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"/ProcessDetail/" + supportingPendingProcessId}
                      >
                        click here
                      </a>
                      &nbsp;to view files.
                    </div>
                  )}
                </div>
                <div className={styles.fileManagementTopButton}>
                  {isShowApplyJbButton && !inModal && (
                    <NormalToolTip
                      element={
                        <div
                          className={styles.whitBlueButton}
                          onClick={this.applyForJbOnClick}
                          onBlur={this.applyJbButtonOnBlur}
                          tabindex="-1"
                        >
                          Apply for JB Status
                        </div>
                      }
                      title={toolTipMessage}
                      trigger="click"
                      open={isApplyForJbToolTipOpen}
                      placement="left"
                      color="#3E6CB5"
                    />
                  )}
                  {isShowTLButton && !inModal && (
                    <NormalToolTip
                      element={
                        <div
                          className={styles.whitBlueButton}
                          onClick={this.tlReviewOnClick}
                          onBlur={this.tlReviewButtonOnblur}
                          tabindex="-1"
                        >
                          TL Review Procedure
                        </div>
                      }
                      title={this.handleCheckMessage(isLocked, lockName)}
                      trigger="click"
                      open={isTlReviewToolTipOpen}
                      placement="left"
                      color="#3E6CB5"
                    />
                  )}
                  {this.handleSectionEditButton()}
                </div>
              </div>
              {clientPathShow && (
                <>
                  <div className={styles.topDiv}>
                    <BaseProcedureSavedIn
                      handleRadioChange={handleRadioChange}
                      savedInRadioValue={savedInRadioValue}
                      disabled={hasSubmit || savedInDisabled}
                      handleRadioClick={handleRadioClick}
                      isShowSavedInIcon={isShowSavedInIcon}
                    />
                    {!!savedInRadioValue &&
                      savedInRadioValue !== savedInRadioValues.systemOnly && (
                        <BaseClientServerPath
                          procedureSaveInValue={savedInRadioValue}
                          value={clientPath}
                          clientPathOnChange={(e) => this.clientPathOnChange(e)}
                          disabled={hasSubmit || noteDisabled}
                          status={clientPathStatus}
                          placeholder={clientPathPlaceholder}
                        />
                      )}
                  </div>
                  {this.handleReturnJbProcedureSavedIn(
                    savedInRadioValue,
                    JbProcedureSaveIn,
                    status,
                    inModal
                  )}
                </>
              )}
              <div className={styles.buttonCoverSheet}>
                {savedInRadioValue !== savedInRadioValues.serverOnly &&
                  savedInRadioValue !== null && (
                  <div className={styles.buttonDiv}>
                    {this.handleReturnDownloadBtn(
                      isShowDownloadBtn,
                      downloadFileDisabled
                    )}
                    {this.handleReturnShareBtn(
                      isShowShareBtn,
                      deleteFileDisabled
                    )}
                    {this.handleReturnUpdateBtn(isShowUpdate)}
                    {this.handleReturnUploadBtn(
                      isShowUploadBtn,
                      addNewFileDisabled,
                      handleFileSelect
                    )}
                    {this.handleReturnDeleteBtn(
                      isShowDeleteBtn,
                      deleteFileDisabled
                    )}
                  </div>
                )}
                {isProcedureSection && (
                  <div className={styles.coverSheet}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.getCoverSheetUrl();
                      }}
                    >
                      Cover Sheet
                    </a>
                  </div>
                )}
              </div>
              {savedInRadioValue !== savedInRadioValues.serverOnly &&
                savedInRadioValue !== null && (
                  <>
                    {this.handleReturnProcedureFileTable({
                      fileTableRef,
                      columns,
                      hasSubmit,
                      isScrollData,
                      className,
                      optionList,
                      handleReturnClick,
                      handleReplaceFileSelect,
                      currentRole,
                      downloadFile,
                      isSupportingFile,
                      isShowExpendIcon,
                      fileTableStatus,
                      isShowHistoryVersion,
                    })}
                  </>
                )}
              {isShowActionBtns &&
                this.handleReturnActionBtns({
                  saveDisabled,
                  onSave,
                  submitDisabled,
                  onSubmit,
                  cancelDisabled,
                  onCancel,
                  savedInRadioValue,
                  procedureMandatoryCheck,
                })}
            </div>
            <TlReviewModal
              ref={tlReviewModalRef}
              isModalOpen={isTlReviewModalOpen}
              onSubmit={this.tlReviewOnSubmit}
              onCancel={this.tlReviewCancel}
              showMessage={tlReviewShowMessage}
              radioValue={tlReviewModalRadioValue}
              submitDisabled={tlReviewModalSubmitDisabled}
              radioOnChange={this.tlReviewModalRadioOnChange}
              zIndex={900}
            />
            <TlReviewProcedureModal
              ref={tlReviewProcedureModalRef}
              isModalOpen={isTlReviewProcedureModalOpen}
              onSubmit={this.tlReviewProcedureOnSubmit}
              onCancel={this.tlReviewProcedureOnCancel}
              showLoading={this.props._showLoading}
              hideLoading={this.props._hideLoading}
              zIndex={900}
            />
            <ShareDocument
              isModalOpen={isShareModalOpen}
              onCancel={() => {
                this.setState({
                  shareDocument: {
                    ...this.state.shareDocument,
                    isShareModalOpen: false,
                  },
                });
              }}
              procedureFiles={procedureFiles}
              supportingFiles={supportingFiles}
              shareFiles={shareFiles}
              shareData={shareData}
              activeInternalUsers={activeInternalUsers}
              isDetailPage={true}
            />
            <ApplyForJbModal
              ref={applyForJbModalRef}
              isModalOpen={isApplyForJbModalOpen}
              onSubmit={this.applyForJbOnSubmit}
              onCancel={this.applyForJbOnCancel}
              showLoading={this.props._showLoading}
              hideLoading={this.props._hideLoading}
              clientPath={clientPath}
              savedInRadioValue={savedInRadioValue}
              JbProcedureSaveIn={JbProcedureSaveIn}
              getApplyForJbDataList={this.getApplyForJbDataList}
              releaseJbStatusLock={this.releaseJbStatusLock}
              refreshPage={this.refreshPage}
              procedureId={this.props.procedureId}
              zIndex={900}
            />
            <div>
              <NormalModal
                okText="Confirm"
                cancelText="Cancel"
                childContent={<p className="fs-16">{modalChildContent}</p>}
                isModalOpen={isModalOpen}
                handleOk={handleModalConfirm}
                handleCancel={this.hideModal}
              />
            </div>
          </>
        }
      />
    );
  }
}

export default BaseFileManagement;
