import React, { createRef } from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import styles from "../../procedureFileProcess/style/index.module.scss";
import StepService from "../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";

const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class BaseManageShareTaskComplete extends BaseComponent {
  handleReturnProcedureFileSection = () => {
    // this is an empty function
  };

  handleReturnShareTaskSection = () => {
    // this is an empty function
  };

  handleReturnRejectReason = () => {
    // this.is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  handleManageShareTaskPageHeader = () => {
    const { receiveDate, requestByName } = this.props.pageProps;

    return (
      <PageHeader
        title={this.handleReturnPageName()}
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status="Complete"
      />
    );
  };

  handleManageShareTaskTaskInformationSection = (params) => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleManageShareTaskProcedureFileSection = (params) => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={this.handleReturnProcedureFileSection()}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleManageShareTaskSupportingFileSection = (params) => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            supportingFileData={supportingTableData}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleManageShareTaskProcedureInformationSection = (params) => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleManageShareTaskShareTaskSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={this.handleReturnShareTaskSection()}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  render() {
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
    return (
      <div className={styles.approveNewPro}>
        {this.handleManageShareTaskPageHeader()}
        {this.handleManageShareTaskTaskInformationSection(params)}
        {this.handleManageShareTaskProcedureFileSection(params)}
        {this.handleManageShareTaskSupportingFileSection(params)}
        {this.handleManageShareTaskProcedureInformationSection(params)}
        {this.handleManageShareTaskShareTaskSection()}
        {this.handleReturnRejectReason()}
      </div>
    );
  }
}
