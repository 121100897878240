import Buttons from "../../../../../../base/basicComponents/button";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";
import { savedInRadioValues } from "../../../../../publicDictionaryValues";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
import ProcedureListService from "../../../../../../service/procedureList/index";
import DetailFileTable from "../../procedureFileTableFields/detailFileTable";
import DetailModalFileTable from "../../procedureFileTableFields/detailModalFileTable";

class DetailFileManagement extends BaseFileManagement {
  getDetailFileManagementCoverSheetSuccess = (result) => {
    this.downloadPDF(result.content, "Cover Sheet.pdf");
  };

  downloadDetailFileManagementFile = () => {
    if (this.props.isProcedureDetailSection && !this.props.inModal) {
      const getCoverSheetParam = {
        ProcedureId: this.props.procedureId,
        ProcedureTaskId: this.props.procedureTaskId,
      };
      ProcedureListService.getCoverSheet(
        getCoverSheetParam,
        this.getDetailFileManagementCoverSheetSuccess,
        () => this._alertError("Get Cover Sheet Failed")
      );
    }
    this._showDownloadLoading();
    this.baseDownloadFiles(this.downloadDetailFilesSuccessfully);
  };

  downloadFile = () => {
    this.downloadDetailFileManagementFile();
  };

  handleDetailFileManagementFileTable = (obj) => {
    const {
      currentRole,
      downloadFile,
      isScrollData,
      isSupportingFile,
      fileTableRef,
      columns,
      fileTableStatus,
      handleReturnClick,
      handleReplaceFileSelect,
      className,
      optionList,
      hasSubmit,
      isShowExpendIcon,
      isShowHistoryVersion,
    } = obj;

    return (
      <DetailFileTable
        procedureNoteMaxLength={1000}
        inputOnChange={this.inputOnChange}
        fileTableRef={fileTableRef}
        downloadFile={downloadFile}
        ref={fileTableRef}
        columns={columns}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleReplaceFileSelect}
        currentRole={currentRole}
        fileTableStatus={fileTableStatus}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        isShowHistoryVersion={isShowHistoryVersion}
      />
    );
  };

  handleDetailFileManagementModalFileTable = (obj) => {
    const {
      downloadFile,
      isSupportingFile,
      hasSubmit,
      isScrollData,
      className,
      currentRole,
      fileTableStatus,
      isShowExpendIcon,
      fileTableRef,
      columns,
      optionList,
      handleReturnClick,
      handleReplaceFileSelect,
    } = obj;

    return (
      <DetailModalFileTable
        ref={fileTableRef}
        handleReturnClick={handleReturnClick}
        isSupportingFile={isSupportingFile}
        columns={columns}
        inputOnChange={this.inputOnChange}
        optionList={optionList}
        isShowExpendIcon={isShowExpendIcon}
        fileTableStatus={fileTableStatus}
        procedureNoteMaxLength={1000}
        fileTableRef={fileTableRef}
        className={className}
        handleFileSelect={handleReplaceFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isShowTotalFileNum={true}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
      />
    );
  };

  /**
   * function about return table
   * @param {*} obj
   * @returns
   */
  handleReturnProcedureFileTable = (obj) => {
    const { className } = obj;
    return className === "procedureFiles"
      ? this.handleDetailFileManagementFileTable(obj)
      : this.handleDetailFileManagementModalFileTable(obj);
  };

  handleReturnActionBtns = (obj) => {
    const {
      onCancel,
      saveDisabled,
      onSubmit,
      savedInRadioValue,
      onSave,
      submitDisabled,
      procedureMandatoryCheck,
    } = obj;
    const initSavedInValue = this.props.initOrLastSaveProcedureSaveInValue;
    const isShowModal =
      initSavedInValue !== savedInRadioValue &&
      savedInRadioValue === savedInRadioValues.serverOnly &&
      this.prevProcedureDataList.length > 0;
    const shouldShowErrorSystem =
      this.state.savedInRadioValue !== savedInRadioValues.systemOnly &&
      this.state.clientPath.trim() === "";

    return (
      <div className={styles.buttonDiv}>
        {this.props.procedureStatus !== 3 && (
          <Buttons
            color="grey"
            disabled={saveDisabled}
            size="middle"
            onClick={() => {
              const shouldShowErrorServer =
                this.state.savedInRadioValue !==
                  savedInRadioValues.serverOnly &&
                this.getDataList().length === 0;
              if (shouldShowErrorServer || shouldShowErrorSystem) {
                procedureMandatoryCheck();
              } else if (isShowModal) {
                this.checkSavedInChangeToServerOnly("save");
              } else {
                onSave();
              }
            }}
          >
            Save as Draft
          </Buttons>
        )}
        <Buttons color="grey" disabled={false} size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer =
              this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
              this.getDataList().length === 0;
            if (shouldShowErrorServer || shouldShowErrorSystem) {
              procedureMandatoryCheck();
            } else if (isShowModal) {
              this.checkSavedInChangeToServerOnly("submit");
            } else {
              onSubmit();
            }
          }}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };
}

export default DetailFileManagement;
