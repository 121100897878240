import React from "react";
import { Table, Button } from "antd";
import BaseComponent from "../../../../../base/common/BaseComponent";

class ChangeHistoryListTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const tableFilter = filters;
    const tableSorter = sorter;
    let filterArr = [];
    let sort = null;

    if (Object.values(tableFilter).some((value) => value !== null)) {
      filterArr = Object.entries(tableFilter)
        .filter(([key, value]) => value !== null)
        .map(([key, value]) => {
          return {
            filterColumn: this.props.tableFilterMap.get(key),
            filterValue: value,
          };
        });
    }

    if (
      Object.keys(tableSorter).length !== 0 &&
      tableSorter.column !== undefined
    ) {
      sort = {
        sortColumn: tableSorter.column.sortName ?? tableSorter.column.dbName,
        isAscending: tableSorter.order === "ascend" ? true : false,
      };
    }

    this.props.handleFilterSort(filterArr, sort, filters);
  };

  handleExpand = (record) => {
    const { expandedRowKeys } = this.state;
    const isExpanded = expandedRowKeys.includes(record.procedureID);
    if (isExpanded) {
      this.setState({
        expandedRowKeys: expandedRowKeys.filter(
          (key) => key !== record.procedureID
        ),
      });
    } else {
      this.setState({
        expandedRowKeys: [...expandedRowKeys, record.procedureID],
      });
    }
  };

  renderContainerRow = (record) => (
    <tr key={record.procedureID}>
      <td colSpan={100}>
        <div>
          {record.procedureHeader}
          <Button onClick={() => this.handleExpand(record)}>Expand</Button>
        </div>
      </td>
    </tr>
  );

  render() {
    const { data, columns, tab } = this.props;
    const filteredColumns = columns.filter((column) => !column.hidden);
    return (
      <Table
        key={this._generateGUID()}
        columns={filteredColumns}
        dataSource={data}
        pagination={false}
        onChange={this.handleTableChange}
        className={tab === 1 ? "changeHistoryTableGroup" : "changeHistoryTable"}
        rowClassName={(record) => (record.isLeaveProcedure ? "archiveChangeHistory" : "activeChangeHistory")} 
        defaultExpandAllRows={true}
        locale={{
          emptyText: "",
        }}
      />
    );
  }
}

export default ChangeHistoryListTable;
