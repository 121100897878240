import React from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import DelegatedReasonIcon from "./delegatedReasonIcon";
import DelegatedReasonModal from "./delegatedReasonModal";

export default class DelegatedReasonField extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { reason, delegateTo } = this.props;
    const { isModalOpen } = this.state;
    return (
      <div className="delegatedField">
        <DelegatedReasonIcon handleModalOpen={this.handleModalOpen} />
        <DelegatedReasonModal
          reason={reason}
          handleCloseModal={this.handleCloseModal}
          isModalOpen={isModalOpen}
          delegateTo={delegateTo}
        />
      </div>
    );
  }
}
