import Buttons from "../../../../../../base/basicComponents/button";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status";
import UploadFile from "../../../../../../base/basicComponents/uploadFile";
import ApproveProcedureFileTbody from "../../../../procedureFileSections/fields/procedureFileTbodyFields/approveProcedureFileTbody";

export default class ApplyJbFileTbody extends ApproveProcedureFileTbody {
  returnButton = (row, column, rowIndex, status) => {
    if (column.hideActionButton !== undefined && column.hideActionButton(row)) {
      return;
    }
    return (
      <div className="buttonTd-content">
        {
          <>
            <Buttons
              color={column.approveColor}
              size="small"
              onClick={(e) =>
                column.onApproveClick(e.target.attributes.rowindex.value)
              }
              disabled={row.isApproveDisabled}
              rowindex={rowIndex}
            >
              {column.approveText}
            </Buttons>
            <UploadFile
              fileInputRef={row.replaceRef}
              isMultiple={false}
              handleFileSelect={this.props.handleFileSelect}
              rowindex={rowIndex}
              isSupportingFile={false}
            />
          </>
        }
        {
          <Buttons
            color={column.returnColor}
            size="small"
            onClick={() => column.onReturnClick(rowIndex)}
            disabled={row.isReturnDisabled}
            rowindex={rowIndex}
          >
            {column.returnText}
          </Buttons>
        }
      </div>
    );
  };

  returnTag = (column, rowColumn) => {
    return (
      column.dataName === "status" && (
        <>
          <SectionStatusDiv status={rowColumn.value} />
        </>
      )
    );
  };
}
