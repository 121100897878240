const StepBusinessType = {
  procedureFile: {
    dataKey: "procedureFolder",
    settingKey: "getProcedureFolderStepItemActived",
  },
  supportingFile: {
    dataKey: "supportingFolder",
    settingKey: "getCreateSupportingFileStepItemActived",
  },
  shareTask: {
    dataKey: "shareTask",
    settingKey: "getManageShareTaskStepItemActived",
  },
  procedureOwner: {
    dataKey: "procedureOwner",
    settingKey: "getProcedureOwnerStepItemActived",
  },
  primaryProcedureOwner: {
    dataKey: "primaryProcedureOwner",
    settingKey: "getPrimaryProcedureOwnerStepItemActived",
  },
  jBStatus: {
    dataKey: "applyJBStatus",
    settingKey: "getApplyJBStatusStepItem",
  },
  pmpExcluded: {
    dataKey: "applyPmpExcluded",
    settingKey: "getApplyPmpExcludedStepItemActived",
  },
  tLReview: { dataKey: "tlReview", settingKey: "getTLReviewStepItem" },
  signOff: { dataKey: "signOff", settingKey: "getSignOffStepItemActived" },
  taskInfo: { dataKey: "taskInfomation", settingKey: "getTaskInformation" },
};

export default StepBusinessType;
