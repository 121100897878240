import BaseService from "../base";

class MyRequestListService extends BaseService {
  getAccountAbbrList = (success, error, returnPara) => {
    this.sendRequest(
      "getToDoListAccountAbbreviations",
      {},
      success,
      error,
      returnPara
    );
  };

  getAllAccountAbbreviations = (para, success, error, returnPara) => {
    this.sendRequest(
      "getAllAccountAbbreviations",
      para,
      success,
      error,
      returnPara
    );
  };

  getProcessCategoryDDL = (success, error, returnPara) => {
    this.sendRequest("getProcessCategoryDDL", {}, success, error, returnPara);
  };

  getMyRequestTopFilter = (success, error, returnPara) => {
    this.sendRequest("getMyRequestTopFilter", {}, success, error, returnPara);
  };
  
  getMyRequestList = (para, success, error) => {
    this.sendRequest("getMyRequestList", para, success, error);
  };

  getMyRequestFilter = (para, success, error) => {
    this.sendRequest("getMyRequestFilter", para, success, error);
  };

  getProcessDetailData = (para, success, error) => {
    this.sendRequest("getProcessDetailData", para, success, error);
  };
}

const myRequestListService = new MyRequestListService();

export default myRequestListService;
