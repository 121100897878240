import React from "react";
import AppSetting from "../../config/AppSetting";
import { message } from "antd";
import ProcedureDetailService from "../../service/procedureFile/ProcedureDetailService";
import { getDate } from "../../app/publicDictionaryValues";

export default class BaseComponent extends React.Component {
  get appSetting() {
    return AppSetting;
  }

  componentDidUpdate() {
    if (this.state === null || !this.state.isError) {
      return;
    }

    if (this.state.isError) {
      throw Error(this.state.error.message);
    }
  }

  setError(haveError, error) {
    if(this)
      this.setState({ isError: haveError, error: error });
  }

  _showLoading = () => {
    if (this.props.history == null) {
      return;
    }
    if (this.props.history.location.state === undefined) {
      this.props.history.replace({
        ...this.props.history,
        state: { showLoad: 1 },
      });
      return;
    }

    this.props.history.replace({
      ...this.props.history,
      state: { showLoad: this.props.history.location.state.showLoad + 1 },
    });
  };

  _hideLoading = () => {
    if (this.props.history == null) {
      return;
    }

    if (this.props.history.location.state === undefined) {
      return;
    }

    this.props.history.replace({
      ...this.props.history,
      state: { showLoad: this.props.history.location.state.showLoad - 1 },
    });
  };

  _alertSuccess = (msg) => {
    message.success(msg);
  };

  _alertError = (msg, duration = 3) => {
    message.error(msg, duration);
  };

  _alertWarning = (msg) => {
    message.warning(msg);
  };

  _getDate = () => {
    return getDate();
  };

  _formateDate = (date) => {
    if (date === null) {
      return date;
    }
    const dateTime = new Date(date);
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    let day = dateTime.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return month + "/" + day + "/" + year;
  };

  _generateGUID() {
    let crypto = window.crypto || window.msCrypto;
    if (!crypto) {
      console.log("Crypto API not supported");
      return;
    }
    let buf = new Uint16Array(8);
    crypto.getRandomValues(buf);
    return (
      buf[0].toString(16) +
      buf[1].toString(16) +
      "-" +
      buf[2].toString(16) +
      "-" +
      buf[3].toString(16) +
      "-" +
      buf[4].toString(16) +
      "-" +
      buf[5].toString(16) +
      buf[6].toString(16) +
      buf[7].toString(16)
    );
  }

  _getRoutingParam(paramName) {
    if (this.props.match === undefined) {
      return this.props.params ? this.props.params[paramName] : null;
    } else if (this.props.match === null || this.props.match.params == null) {
      return;
    }
    return this.props.match.params[paramName];
  }

  _setLock(id, actionType, isLock) {
    const para = {
      procedureId: id,
      procedureSectionType: actionType,
      setLock: isLock,
      timeOutMins: 20,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        //this.is an empty function
      },
      (response) => {
        console.log(response.message);
      }
    );
  }

  getCurrentDate() {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要加1
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  decodeHTMLEntities(text) {
    var element = document.createElement("div");
    if (text) {
      element.innerHTML = text;
      text = element.textContent;
      element.textContent = "";
    }
    return text;
  }

  richTextEditorValueNotEmpty(input) {
    // 创建一个DOM元素来解析HTML
    var tempElement = document.createElement("div");
    tempElement.innerHTML = input;

    // 提取纯文本内容并解码HTML实体
    var textOnly = this.decodeHTMLEntities(
      tempElement.textContent || tempElement.innerText || ""
    );

    // 去除所有空白字符
    var trimmedInput = textOnly.replace(/\s+/g, "");

    // 检查去除空白字符后的输入长度是否大于零
    return trimmedInput.length > 0;
  }
}
