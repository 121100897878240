import React from "react";
import { Select, Tooltip } from "antd";
import Buttons from "../../../../base/basicComponents/button/index";
import BaseComponent from "../../../../base/common/BaseComponent";
import addIcon from "../../../../assets/image/add.png";
import tickIcon from "../../../../assets/image/tick.png";
import crossIcon from "../../../../assets/image/cross.png";
import warningIcon from "../../../../assets/image/warningGrey.png";
import styles from "./index.module.scss";
import "./addNewShareTask.scss";

class AddNewShareTask extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      taskTreeKey: 0,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.taskTreePathOption !== this.props.taskTreePathOption) {
      this.setState({
        taskTreeKey: this.state.taskTreeKey + 1,
      });
    }
  }
  render() {
    const {
      isAddNewShareTaskShow,
      showAddNewShareTask,
      accountSelectOption,
      accountSelectValue,
      accountSelectOnChange,
      taskTreePathOption,
      taskTreePathValue,
      taskTreePathOnSearch,
      taskTreePathOnChange,
      addTaskOnClick,
      clearAddTaskOnClick,
      showWarning,
      selectDisable,
    } = this.props;
    const { taskTreeKey } = this.state;
    return (
      <div className={styles.addNewShareTaskDiv}>
        {isAddNewShareTaskShow ? (
          <div className={styles.addNewTaskSelectDiv}>
            <Select
              className="antSelect"
              options={accountSelectOption}
              value={accountSelectValue}
              placeholder="Account"
              onChange={accountSelectOnChange}
              style={{
                width: "28.5%",
              }}
              disabled={selectDisable}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            <Select
              className="antSelect"
              key={taskTreeKey}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSearch={taskTreePathOnSearch}
              options={taskTreePathOption}
              value={taskTreePathValue}
              onChange={taskTreePathOnChange}
              placeholder="Enter Task Label Name or Task ID"
              style={{
                width: "47.5%",
              }}
              disabled={selectDisable}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            <Buttons
              color="blue"
              size="small"
              onClick={addTaskOnClick}
              disabled={selectDisable}
            >
              <div className={styles.addTaskBt}>
                <img alt="" src={tickIcon} />
              </div>
            </Buttons>
            <Buttons
              color="grey"
              size="small"
              onClick={clearAddTaskOnClick}
              disabled={selectDisable}
            >
              <div className={styles.addTaskBt}>
                <img alt="" src={crossIcon} />
              </div>
            </Buttons>
          </div>
        ) : (
          <div className={styles.addTaskBtDiv}>
            <div
              className={
                selectDisable
                  ? styles.showAddTaskBtDisable
                  : styles.showAddTaskBt
              }
              onClick={showAddNewShareTask}
            >
              <img className={styles.showAddTaskIcon} src={addIcon} alt="add" />
              <span
                className={`${
                  selectDisable
                    ? styles.showAddTaskTextDisable
                    : styles.showAddTaskText
                } fs-14`}
              >
                Add Task
              </span>
            </div>
            {showWarning && (
              <div className={styles.rejectTipIcon}>
                <Tooltip
                  overlayClassName="addNewTip"
                  placement="bottomLeft"
                  title="Only one Task can be added as the new Main Task"
                  color="#fff"
                >
                  <img src={warningIcon} alt="Warning" />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AddNewShareTask;
