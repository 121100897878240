import AppSetting from "../../config/AppSetting";
import BaseService from "../base";
import ProcedureListMockService from "./mock";

class ProcedureListService extends BaseService {
  getAccountAbbrList = (success, error) => {
    this.sendRequest("accountAbbrList", {}, success, error);
  };

  getAllAccountAbbreviations = (para, success, error) => {
    this.sendRequest("getAllAccountAbbreviations", para, success, error);
  };

  getAccountStatusList = (success, error) => {
    this.sendRequest("accountStatusList", {}, success, error);
  };

  getTaskGroupList = (para, success, error) => {
    this.sendRequest("taskGroupList", para, success, error);
  };

  getAccountTaskGroupsCategoriesMapping = (para, success, error) => {
    this.sendRequest(
      "getAccountTaskGroupsCategoriesMapping",
      para,
      success,
      error
    );
  };

  getCategoryList = (para, success, error) => {
    this.sendRequest("categorieList", para, success, error);
  };

  getTaskStatusList = (success, error) => {
    this.sendRequest("taskStatusList", {}, success, error);
  };

  getProcedureList = (para, success, error) => {
    this.sendRequest("getProcedureList", para, success, error);
  };

  getProcedureFilter = (para, success, error) => {
    this.sendRequest("getProcedureFilter", para, success, error);
  };

  assignPrimaryProcedureOwner = (para, success, error) => {
    this.sendRequest("assignPrimaryProcedureOwner", para, success, error);
  };

  applyPMPExcluded = (para, success, error) => {
    this.sendRequest("applyPMPExcluded", para, success, error);
  };

  getProcedureDocumentList = (para, success, error) => {
    this.sendRequest("getProcedureDocumentList", para, success, error);
  };

  getCoverSheet = (para, success, error) => {
    this.sendRequest("getCoverSheet", para, success, error);
  };

  getActiveInternalUserInfos = (success, error) => {
    this.sendRequest("getActiveInternalUserInfos", {}, success, error);
  };

  shareDocument = (para, success, error) => {
    this.sendRequest("shareDocument", para, success, error);
  };

  downloadCheck = (para, success, error) => {
    this.sendRequest("downloadCheck", para, success, error);
  };

  checkProcedureDetailEdit = (para, success, error) => {
    this.sendRequest("checkProcedureDetailEdit", para, success, error);
  };

  exportProcedureList = (para, success, error) => {
    this.sendRequest("exportProcedureList", para, success, error);
  };

  checkExport = (para) => {
    return this.sendSyncRequest("checkExport", para);
  };
}

const procedureListService = new ProcedureListService();

export default AppSetting.isMock
  ? ProcedureListMockService
  : procedureListService;
