import AppSetting from "../../config/AppSetting";

export default class TokenStorage {
  setAuthenticated = (accessToken, refreshToken, userModel, roleModel) => {
    AppSetting.localStorageValue.setAuthenticated(
      accessToken,
      refreshToken,
      userModel,
      roleModel
    );
  };

  setUnauthenticated = () => {
    AppSetting.localStorageValue.setUnauthenticated();
  };

  refreshToken = (accessToken, refreshToken) => {
    AppSetting.localStorageValue.refreshToken(accessToken, refreshToken);
  };

  setUserModel = (userModel) => {
    AppSetting.localStorageValue.user.set(userModel);
  };

  adfsAccessToken = (accessToken) => {
    AppSetting.localStorageValue.adfsAccessToken(accessToken);
  };
}
