import React from "react";
import CreateSupportingFileReviseSection from "../createSupportingFileReviseSection";
import ReviseUpdateSupportingFileManagement from "../fields/supportingFileManagement/reviseUpdateSupportingFileManagement";
import styles from "../index.module.scss";

export default class UpdateSupportingFileReviseSection extends CreateSupportingFileReviseSection {
  render() {
    const { currentRole, isDisableAll } = this.props.sectionProps;
    const {
      supportingFileTableRef,
      supportingRef,
      reasonRef,
      noteRef,
      supportingFileRef,
      supportingReplaceFileRef,
    } = this.props.refs;
    const {
      setIsTableChange,
      cancelIsTableChange,
      initSupportingTableData,
      isTableBorderError,
    } = this.props;
    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Supporting Files</div>

        <div
          className={`${styles.fileSectionTable} ${
            this.props.isTableBorderError ? styles.border_error : ""
          }`}
        >
          {this.props.isMount && (
            <ReviseUpdateSupportingFileManagement
              data={this.props.data}
              ref={supportingRef}
              procedureRef={supportingRef}
              fileTableRef={supportingFileTableRef}
              className="reviseUpdateSupportingTable"
              pageType="supportingPage"
              reasonRef={reasonRef}
              noteRef={noteRef}
              fileInputRef={supportingFileRef}
              tableReplaceFileInputRef={supportingReplaceFileRef}
              currentRole={currentRole}
              isShowExpendIcon={false}
              initProcedureTableData={[...initSupportingTableData]}
              isDisableAll={isDisableAll}
              isTableBorderError={isTableBorderError}
              handleChangeTableErrorStatus={this.props.handleChangeTableErrorStatus}
              setIsTableChange={setIsTableChange}
              cancelIsTableChange={cancelIsTableChange}
              _showLoading={this.props._showLoading}
              _hideLoading={this.props._hideLoading}
              updateUploadProcedureFileNum={
                this.props.updateUploadProcedureFileNum
              }
              updateProcedureFileNum={this.props.updateSupportingFileNum}
              isCopyPage={this.props.isCopyPage}
            />
          )}
        </div>
      </div>
    );
  }
}
