import RoutingComponent from "../../../base/common/RoutingComponent";
import NormalSpin from "../../../base/basicComponents/spin";

export default class LoginRedirect extends RoutingComponent {
    constructor(props) {
        super(props);
        this.prevUrl = sessionStorage.getItem('prevUrl');
    }

    redirectToUrl = () => {
        sessionStorage.removeItem('prevUrl');
        const currentUrl = window.location.href;
        const isRedirect = (currentUrl.toLowerCase() !== this.prevUrl?.toLowerCase()) && (!this.prevUrl?.toLowerCase().includes('login'));
        if(isRedirect) {
            window.location.href = this.prevUrl;
        } else {
            this.route('/ProcedureList');
        }
    }

    componentDidMount() {
        this.redirectToUrl();
    }
  
    render() {
        return (
            <NormalSpin
                spinning={true}
                size="large"
            >
                <div style={{width: '100%', height: '100%'}}></div>
            </NormalSpin>
        );
    }
} 