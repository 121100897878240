import React, { createRef } from "react";
import { Radio } from "antd";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import { savedInRadioValues } from "../../../../../publicDictionaryValues";
import ApprovedSavedIn from "../approvedSavedIn";
import styles from "../index.module.scss";

const savedInReturnRef = createRef();
export default class ReadOnlyProcedureProcessSavedIn extends ApprovedSavedIn {
  render() {
    const { procedureSavedInRef } = this.props;
    const {
      procedureSaveInCategoryStatusID,
      isSavedInShowEditBtn,
      isSavedInError,
      savedInReturnReasonInputValue,
      savedInRadioValue,
    } = this.state.savedInSectionState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.procedureSavedIn}
          style={{ borderColor: isSavedInError ? "red" : "#b3b3b3" }}
          ref={procedureSavedInRef}
        >
          <div>
            <div className={styles.savedInTop}>
              <div className={styles.procedureSaveInLabel}>
                Procedure Saved In:
              </div>
              <div className={styles.procedureSaveInChoice}>
                <Radio.Group value={savedInRadioValue} disabled={true}>
                  <Radio value={savedInRadioValues.systemOnly}>
                    PMP System Only
                  </Radio>
                  <Radio value={savedInRadioValues.systemAndClientServer}>
                    PMP System & Client Server
                  </Radio>
                  <Radio value={savedInRadioValues.serverOnly}>
                    Client Server Only
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className={styles.savedInContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                <SectionStatusDiv status={procedureSaveInCategoryStatusID} />
              </div>
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Return Reason</div>
              <ReturnReasonInput
                inputRef={savedInReturnRef}
                value={savedInReturnReasonInputValue}
                isDisabled={true}
                isShowEditBtn={isSavedInShowEditBtn}
                isShowReasonLogIcon={
                  this.props.savedInReturnReasonLog.length > 0
                }
                reasonLog={this.props.savedInReturnReasonLog}
              />
            </div>
            <div className={styles.actionBtns}></div>
          </div>
        </div>
      </div>
    );
  }
}
