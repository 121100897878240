import React, { createRef } from "react";
import {
  flowCategoryId,
  handleApproveApplyJBSortFile,
  procedureJbStatus,
} from "../../../publicDictionaryValues";
import ApplyForJbApproveReadOnlySection from "../../../sections/applyForJbSection/applyForJbReadOnlySection/approveSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import BaseApplyJb from "../baseApplyJb";

const procedureFileRef = createRef();

export default class ApproveApplyForJbComplete extends BaseApplyJb {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      loading: false,
    };
  }

  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyJBStatus) {
      return "Copy JB Status";
    }

    return "Approve JB Procedure";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ActiveShareTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleFileData = (files) => {
    const dataList = [];
    if (files === null || files === undefined) {
      return;
    }
    const newFiles = handleApproveApplyJBSortFile(files, this._formateDate);
    newFiles.forEach((item) => {
      const lineId = this._generateGUID();
      dataList.push({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: item.fileID },
          { name: "fileKey", value: item.fileKey },
          {
            name: "fileName",
            value: item.fileName,
            tagValue: item.fileTagID,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(item.createdDate),
          },
          { name: "uploadByName", value: item.uploadByName },
          { name: "uploadBy", value: item.uploadedBy },
          {
            name: "approveDate",
            value: this._formateDate(item.modifiedDate),
          },
          { name: "fileOperate", value: 1 },
          { name: "status", value: item.dataStatusID },
          { name: "previousStatus", value: "" },
          { name: "jbProcedure", value: procedureJbStatus[item.jbProcdure] },
          {
            name: "applyfor",
            value:
              item.jbProcdure !== item.applyfor
                ? procedureJbStatus[item.applyfor]
                : "",
            optionList: [],
          },
          { name: "isDelete", value: item.isDelete },
          { name: "rejectReason", value: item.rejectReason },
          { name: "attachmentFileID", value: item.attachmentFileID },
          {
            name: "attachment",
            value: item.attachmentName,
            fileId: item.attachmentFileID,
          },
        ],
        checked: false,
      });
    });
    return dataList;
  };

  handleReturnProcedureFileSection = () => {
    const { applyJBStatus, isCopyPage, isApproveCompletePage } = this.props;

    return (
      <ApplyForJbApproveReadOnlySection
        procedureFileRef={procedureFileRef}
        serverPathValue={applyJBStatus.clientServerPath}
        savedInRadioValue={applyJBStatus.procedureSaveInCategoryID}
        procedureJbStatusId={applyJBStatus.jbProcedureSavedIn}
        applyfor={
          applyJBStatus.applyfor !== applyJBStatus.jbProcedureSavedIn
            ? applyJBStatus.applyfor
            : ""
        }
        jbProcedureSavedInStatusID={applyJBStatus.jbProcedureSavedInStatusID}
        procedureFileData={this.handleFileData(
          applyJBStatus.applyJBStatusFilesList
        )}
        rejectReason={applyJBStatus.rejectReason}
        isCopyPage={isCopyPage}
        isApproveCompletePage={isApproveCompletePage}
      />
    );
  };
}
