import BaseComponent from "../../../base/common/BaseComponent";

class FormateProcedureInfoData extends BaseComponent {
  setProcedreInfoData = (result) => {
    const {
      procedureNote,
      procedureOwner,
      primaryProcedureOwner,
      applyPmpExcluded,
      procedureStatusID,
    } = result;
    return {
      procedureName: null,
      primaryProcedureOwner: primaryProcedureOwner,
      procedureOwner: procedureOwner,
      procedureNote: procedureNote,
      ppoChange: false,
      poChange: false,
      isExcluded:
        applyPmpExcluded !== null && applyPmpExcluded.isExcluded === true
          ? "Yes"
          : "No",
      procedureStatus: procedureStatusID,
    };
  };
}

const formateProcedureInfoData = new FormateProcedureInfoData();
export default formateProcedureInfoData;
