import React, { createRef } from "react";
import RoutingComponent from "../../../../base/common/RoutingComponent";
import styles from "../index.module.scss";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import ApproveProcedureInformation from "../../../sections/procedureInfoSections/approveProcedureInfoSection";
import { actions, shareTaskMassage } from "../../../publicDictionaryValues";
import TaskInformation from "../../../sections/taskInfoSections";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import Buttons from "../../../../base/basicComponents/button";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import appService from "../../../../service/app";
import LostPermissionModal from "../../../sections/lostPermissionModal";
import StepService from "../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
const procedureInfoRef = createRef();
export default class ApprovePPOPending extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = {
      procedureInfoState: {},
      modalState: {
        isReturnModalOpen: false,
        isRejectModalOpen: false,
        isModalOpen: false,
        modalOkText: "",
        modalCancelText: "",
        modalChildContent: "",
        handleModalConfirm: () => {},
        handleModalCancel: () => {},
      },
      noPermission: false,
    };
    this.procedureTaskId = this.props.procedureTaskId;
    this.procedureID = this.props.procedureID;
    this.procedureStepID = this.props.procedureStepID;
  }

  toggleReturnModal = (value) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isReturnModalOpen: value,
      },
    });
  };

  toggleRejectModal = (value) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isRejectModalOpen: value,
      },
    });
  };

  toggleNormalModal = (value) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalOpen: value,
      },
    });
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) {
      this.props._hideLoading();
      return;
    }
    if (actionType === "reject") {
      this.props._hideLoading();
      this.toggleRejectModal(true);
    } else {
      this.checkPpoLostMembership(actionType);
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.procedureTaskId,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => console.log(response.message)
    );
  };

  _checkPpoLostMembershipSuccess = (response, actionType) => {
    this.props._hideLoading();
    if (response.result) {
      if (actionType === "approve") {
        this.handelApproveClick();
      } else if (actionType === "return") {
        this.toggleReturnModal(true);
      }
    } else {
      const message = shareTaskMassage[response.messageCode];
      this._alertError(message, 7);
    }
  };

  checkPpoLostMembership = (actionType) => {
    const { primaryProcedureOwner } = this.props.stepItemData;
    const param = {
      procedureAccountId: this.props.taskInformationData.procedureAccountID,
      oldPrimaryProcedureOwnerId:
        primaryProcedureOwner.oldPrimaryProcedureOwnerID,
      newPrimaryProcedureOwnerId: primaryProcedureOwner.primaryProcedureOwnerID,
      actionId: actions[actionType],
      action: 0,
      procedureId: this.procedureID,
    };
    ApproveChangePPOService.checkPpoLostMembership(
      param,
      (response) => this._checkPpoLostMembershipSuccess(response, actionType),
      () => this._alertError("CheckPpoLostMembership Failed")
    );
  };

  handelApproveClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalOpen: true,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: "Are you sure to approve this request?",
        handleModalConfirm: this.handleApproveConfirm,
        handleModalCancel: () => this.toggleNormalModal(false),
      },
    });
  };

  handleApproveConfirm = () => {
    this.props._showLoading();
    const { workflowProcessInstanceId } =
      this.props.procedureInformationData.procedureInfoState;
    const param = {
      procedureId: this.procedureID,
      actionID: actions.approve,
      procedureStepID: this.procedureStepID,
      workflowProcessInstanceId,
    };

    ApproveChangePPOService.ChangePrimaryProcedureOwnerTriggerStepAction(
      param,
      this.props.closePage,
      (response) => {
        this._alertError(response.message);
      }
    );

    this.toggleNormalModal(false);
  };

  handleCancelClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalOpen: true,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: "Are you sure to quit without saving?",
        handleModalConfirm: () => this.props.closePage(),
        handleModalCancel: () => this.toggleNormalModal(false),
      },
    });
  };

  render() {
    const {
      procedureInformationData,
      supportingTableData,
      jbProcedureSavedIn,
      taskInformationData,
    } = this.props;
    const { procedureTableData, clientServerPath, savedInRadioValue } =
      this.props.procedureFileData;
    const { receiveDate, requestByName } =
      procedureInformationData.pageHeadState;
    const { modalState } = this.state;
    const procedureData = {
      ...this.props.procedureInformationData.procedureInfoState,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
    };
    const params = {
      TaskId: this.procedureTaskId,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    return (
      <div className={styles.body}>
        <div className={styles.header}>
          <PageHeader
            title="Approve Primary Procedure Owner"
            receiveDate={receiveDate}
            requestBy={requestByName}
            status="Pending"
          />
        </div>
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInformationData} />}
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setTaskInfoData,
              this.setError,
              [StepBusinessType.taskInfo]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlyProcedureFileSection
              procedureFileData={procedureTableData}
              procedureFileRef={procedureFileRef}
              clientServerPath={clientServerPath}
              savedInRadioValue={savedInRadioValue}
              jbProcedureSavedIn={jbProcedureSavedIn}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setProcedureFileData,
              this.setError,
              [StepBusinessType.procedureFile]
            );
          }}
        />
        <ProcedureBlock
          child={
            <ReadOnlySupportingFileSection
              supportingFileRef={supportingFileRef}
              supportingFileTableRef={supportingFileTableRef}
              supportingFileData={supportingTableData}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setSupportingFileData,
              this.setError,
              [StepBusinessType.supportingFile]
            );
          }}
        />
        <ProcedureBlock
          className={styles.ProcedureInfoSection}
          child={
            <ApproveProcedureInformation
              ref={procedureInfoRef}
              procedureData={procedureData}
              submitData={procedureData}
              noteDisabled={true}
              isPPODisabled={true}
              title="Procedure Information"
              className={styles.approveProcedureInformation}
              _showLoading={this.props._showLoading}
              closePage={this.props.closePage}
              isShowActionBtns={true}
              modalState={modalState}
              closeReturnModal={() => this.toggleReturnModal(false)}
              closeRejectModal={() => this.toggleRejectModal(false)}
              closeNormalModal={() => this.toggleNormalModal(false)}
            />
          }
          isShowArrow={true}
          isOpen={true}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly shareTaskData={this.props.shareTask} />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        <div className={styles.buttonsWrapper}>
          <div className={styles.informationBtns}>
            <Buttons
              color="grey"
              size="middle"
              onClick={() => this.checkStepPermission("return")}
            >
              Return to Revise
            </Buttons>
            <Buttons
              color="grey"
              size="middle"
              onClick={this.handleCancelClick}
            >
              Cancel
            </Buttons>
            <Buttons
              color="blue"
              size="middle"
              onClick={() => this.checkStepPermission("approve")}
            >
              Approve
            </Buttons>
            <div className={styles.rejectBtn}>
              <Buttons
                color="red"
                size="middle"
                onClick={() => this.checkStepPermission("reject")}
              >
                Reject
              </Buttons>
              <NormalToolTip
                element={
                  <div className={styles.rejectTipIcon}>
                    <img src={redTipIcon} alt="Reject" />
                  </div>
                }
                title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
                color="#FF5858"
                overlayClassName={styles.rejectTip}
                placement="right"
              />
            </div>
          </div>
        </div>
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
