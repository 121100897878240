import Buttons from "../../../../../../base/basicComponents/button";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
import ProcedureListService from "../../../../../../service/procedureList/index";
import DetailFileTable from "../../../../procedureFileSections/fields/procedureFileTableFields/detailFileTable";
import DetailSupportingFileModalTable from "../../supportingFileTable/detailSupportingFileModalTable";
import NormalToolTip from "../../../../../../base/basicComponents/toolTip";
import SectionEditIcon from "../../../../../../base/basicComponents/sectionEditIcon";

export default class BaseSupportingFileManagement extends BaseFileManagement {
  getBaseSupportingFileManagementCoverSheetSuccess = (result) => {
    this.downloadPDF(result.content, "Cover Sheet.pdf");
  };

  downloadBaseSupportingFileManagementFile = () => {
    if (this.props.isProcedureDetailSection && !this.props.inModal) {
      const getCoverSheetParam = {
        ProcedureId: this.props.procedureId,
        ProcedureTaskId: this.props.procedureTaskId,
      };
      ProcedureListService.getCoverSheet(
        getCoverSheetParam,
        this.getBaseSupportingFileManagementCoverSheetSuccess,
        () => this._alertError("Get Cover Sheet Failed")
      );
    }
    this._showDownloadLoading();
    this.baseDownloadFiles(this.downloadDetailFilesSuccessfully);
  };

  downloadFile = () => {
    this.downloadBaseSupportingFileManagementFile();
  };

  handleBaseSupportingFileManagementFileTable = (obj) => {
    const {
      handleReturnClick,
      handleReplaceFileSelect,
      fileTableRef,
      isScrollData,
      className,
      optionList,
      currentRole,
      isShowExpendIcon,
      columns,
      hasSubmit,
      fileTableStatus,
      downloadFile,
      isSupportingFile,
    } = obj;

    return (
      <DetailFileTable
        columns={columns}
        handleFileSelect={handleReplaceFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        ref={fileTableRef}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        fileTableStatus={fileTableStatus}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
      />
    );
  };

  handleBaseSupportingFileManagementFileModalTable = (obj) => {
    const {
      hasSubmit,
      currentRole,
      downloadFile,
      optionList,
      handleReturnClick,
      isSupportingFile,
      isScrollData,
      className,
      handleReplaceFileSelect,
      isShowExpendIcon,
      fileTableStatus,
      fileTableRef,
      columns,
    } = obj;

    return (
      <DetailSupportingFileModalTable
        isScrollData={isScrollData}
        isShowExpendIcon={isShowExpendIcon}
        ref={fileTableRef}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        procedureNoteMaxLength={1000}
        hasSubmit={hasSubmit}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        fileTableStatus={fileTableStatus}
        columns={columns}
        fileTableRef={fileTableRef}
        className={className}
        handleFileSelect={handleReplaceFileSelect}
        isShowTotalFileNum={true}
      />
    );
  };

  /**
   * function about return table
   * @param {*} obj
   * @returns
   */
  handleReturnProcedureFileTable = (obj) => {
    return this.props.className === "supportingFiles"
      ? this.handleBaseSupportingFileManagementFileTable(obj)
      : this.handleBaseSupportingFileManagementFileModalTable(obj);
  };

  handleReturnActionBtns = (obj) => {
    const {
      saveDisabled,
      procedureMandatoryCheck,
      onSave,
      onSubmit,
      onCancel,
      submitDisabled,
    } = obj;
    return (
      <div className={styles.buttonDiv}>
        <Buttons
          color="grey"
          disabled={saveDisabled}
          size="middle"
          onClick={() => {
            onSave();
          }}
        >
          Save as Draft
        </Buttons>
        <Buttons color="grey" disabled={false} size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer = this.getDataList().length === 0;
            if (shouldShowErrorServer) {
              procedureMandatoryCheck();
            } else {
              onSubmit();
            }
          }}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };

  handleSectionEditButton = () => {
    const { 
      handleEditClick, 
      onBlur, hintMsg, 
      isEditToolTipOpen, 
      isShowSupportingFileEdit,
    } = this.props;

    if(isShowSupportingFileEdit) {
      return (
        <NormalToolTip
          element={
            <SectionEditIcon
              handleEditClick={handleEditClick}
              onBlur={onBlur}
              editRef={this.props.editRef}
            />
          }
          title={hintMsg}
          trigger="click"
          open={isEditToolTipOpen}
          placement="left"
          color="#3E6CB5"
        />
      )
    }
  }

}
