import BaseReadOnlyChangeMainTask from "../baseReadOnlyChangeMainTask";

export default class SubmitChangeMainTaskReadOnly extends BaseReadOnlyChangeMainTask {
  constructor(props) {
    super(props);
    this.state = {
      isTaskInfoModalOpen: false,
      taskInformationData: {},
      isReasonLogModalOpen: false,
      isRequestReasonLogModalOpen: false,
      requestReasonLog: this.transformRequestReasonLogData(
        this.props.shareTaskData.submitReasonLogs
      ),
      reasonValue: this.props.shareTaskData.reason,
      reason: this.props.shareTaskData.returnReason,
    };
  }

  handleSubmitReason = () => {
    return this.handleReviseSubmitReason();
  };
}
