import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";

export default class ActiveShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = {
      isTaskInfoModalOpen: false,
      taskInformationData: {},
    };
  }

  handleDataColumn = () => {
    return [
      this.isMainTaskColumn,
      ...this.commonShareDataColumn,
      this.effectiveDateColumn,
    ];
  };
}
