import BaseService from "../base";
import ApplyForJbStepService from "./applyForJbStepService";
class ApplyForJbService extends BaseService {
  jbStatusSubmitForApproval = (para, success, error) => {
    this.sendRequest("jbStatusSubmitForApproval", para, success, error);
  };

  getApplyJBStatusStepItem = (para, success, error) => {
    ApplyForJbStepService.getStepData(para, success, error);
  };

  applyJBStatusTriggerStepAction = (para, success, error) => {
    this.sendRequest("applyJBStatusTriggerStepAction", para, success, error);
  };

  checkApplyJBStatusSubmit = (para, success, error, returnPara) => {
    this.sendRequest(
      "checkApplyJBStatusSubmit",
      para,
      success,
      error,
      returnPara
    );
  };
}

const applyForJbService = new ApplyForJbService();

export default applyForJbService;
