import React from "react";
import BaseComponent from "../../common/BaseComponent";
import {ModalWithComponent} from "../modal";
import styles from "./index.module.scss";
import InputLabel from "../inputLabel";
import { regularExpressionOptions } from "../../../app/publicDictionaryValues";

export default class ReturnToReviseModal extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalState: {
                closable: false,
                okText: "Confirm",
                cancelText: "Cancel",
                modalTitle: "Reason for Return",
                isCancelBtnDisabled: false,
                maskClosable: false,
                isShowTextArea: true,
                textAreaPlacement: "",
                textAreaMaxLength: 1000,
                textAreaClassName: styles.returnReasonInput,
                modalWidth: 520,
            },

            returnReason: "",
            returnStatus: null,
        };
    }

    handleSetReturnValue = (e) => {
        this.setState({
            modalState: {
                ...this.state.modalState,
            },
            returnReason: e.target.value,
            returnStatus: null,
        });
    };

    handleReturnConfirmClick = () => {
        if(this.state.returnReason.match(regularExpressionOptions.nonempty)) {
            this.setState({
                returnStatus: "error"
            });
        } else{
            this.props.handleReturnConfirm(this.state.returnReason)
            this.setState({
                returnReason: "",
                returnStatus: null,
            });
        }
    }

    handleReturnCancelClick = () => {
        this.props.handleReturnCancel();
        this.setState({
            returnReason: "",
            returnStatus: null,
        });
    }

    render() {
        const { 
            isReturnModalOpen, 
        } = this.props;
        const {
            returnReason,
            returnStatus,
        } = this.state;
        const {
            modalTitle,
            okText,
            cancelText,
            closable,
            isCancelBtnDisabled,
            maskClosable,
            isShowTextArea,
            textAreaPlacement,
            textAreaMaxLength,
            textAreaClassName,
            modalWidth,
        } = this.state.modalState;

        return (
            <ModalWithComponent
                isModalOpen={isReturnModalOpen}
                closable={closable}
                okText={okText}
                cancelText={cancelText}
                title={modalTitle}
                handleOk={this.handleReturnConfirmClick}
                handleCancel={this.handleReturnCancelClick}
                isCancelDisabled={isCancelBtnDisabled}
                maskClosable={maskClosable}
                isShowTextArea={isShowTextArea}
                textAreaValue={returnReason}
                textAreaOnChange={this.handleSetReturnValue}
                textAreaPlacement={textAreaPlacement}
                textAreaMaxLength={textAreaMaxLength}
                textAreaStatus={returnStatus}
                textAreaClassName={textAreaClassName}
                modalWidth={modalWidth}
                label={(
                <InputLabel 
                    text='Please enter Return Reason' 
                    required={true} 
                    className={styles.modalText}
                />)}
            />
        );
    }
}
