import React from "react";
import TransitionWhenHeightChange from "../../../../../../base/basicComponents/auto-height-transition/transition-when-height-change";
import BaseThead from "../../../../../commonComponents/fileTheads/baseFileThead";
import DetailFileTable from "../../../../procedureFileSections/fields/procedureFileTableFields/detailFileTable";
import UpdateSupportingFileTbody from "../../supportingFileTbody/updateSupportingFileTbody";

export default class UpdateSupportingFileTable extends DetailFileTable {
  /* update file table data */
  updateDataList = (dataList) => {
    this.newDataList = [];
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: this.newDataList.length === 0 ? dataList : this.newDataList,
    });
    this.handleDefaultExpendIcon();
  };

  handleReturnTbody = (obj) => {
    const {
      downloadFile,
      isSupportingFile,
      inputOnChange,
      fileSectionDisabled,
      procedureNoteSize,
      hasSubmit,
      fileTableRef,
      optionList,
      isShowReturnReason,
      isExpendData,
      isShowHistoryVersion,
      handleReturnClick,
      data,
      columns,
      currentRole,
      isRevisePage,
      handleFileSelect,
      procedureNoteMaxLength,
    } = obj;
    return (
      <UpdateSupportingFileTbody
        data={data}
        fileTableRef={fileTableRef}
        setIsExpendNote={this.setIsExpendNote}
        currentRole={currentRole}
        isRevisePage={isRevisePage}
        isExpendData={isExpendData}
        isShowHistoryVersion={isShowHistoryVersion}
        isSupportingFile={isSupportingFile}
        handleChangeTableHeight={this.handleChangeTableHeight}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        fileSectionDisabled={fileSectionDisabled}
        optionList={optionList}
        isHasReturnReason={isShowReturnReason}
        procedureNoteMaxLength={procedureNoteMaxLength}
        downloadFile={downloadFile}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteSize={procedureNoteSize}
        hasSubmit={hasSubmit}
      />
    );
  };

  updateSupportingFileTableListControlIcon = () => {
    const { isExpendData, iconSrc } = this.state;
    return (
      <p className="listControlIcon">
        <img
          src={iconSrc}
          alt={isExpendData ? "pick up" : "show all"}
          onMouseEnter={this.handleHoverExpendIcon}
          onMouseLeave={this.handleDefaultExpendIcon}
          onClick={this.handleExpendFlag}
        />
      </p>
    );
  };

  updateSupportingFileTableTableWithTransitionHeightTable = () => {
    const {
      procedureNoteSize,
      inputOnChange,
      isSupportingFile,
      fileTableStatus,
      handleFileSelect,
      currentRole,
      hasSubmit,
      className,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      isRevisePage,
      downloadFile,
      procedureNoteMaxLength,
      fileSectionDisabled,
      isShowHistoryVersion,
      fileTableRef,
    } = this.props;
    const { columns, data, isExpendData } = this.state;
    return (
      <table
        className={`${className} table ${
          fileTableStatus === "error" ? "tableError" : ""
        }`}
      >
        <BaseThead
          columns={columns}
          hasSubmit={hasSubmit}
          fileSectionDisabled={fileSectionDisabled}
        />
        {this.handleReturnTbody({
          inputOnChange,
          procedureNoteMaxLength,
          downloadFile,
          isSupportingFile,
          isExpendData,
          hasSubmit,
          fileTableRef,
          data,
          handleReturnClick,
          handleFileSelect,
          columns,
          isShowReturnReason,
          currentRole,
          isRevisePage,
          isShowHistoryVersion,
          fileSectionDisabled,
          procedureNoteSize,
          optionList,
        })}
      </table>
    );
  };

  updateSupportingFileTableTableWithTransitionHeight = () => {
    const { data, isExpendData, expendHeight } = this.state;
    const height = data.length < 4 ? data.length * 78 + 38 : 350;
    const isExpendNote = true;

    return (
      <TransitionWhenHeightChange
        height={this.handleTransitionHeight(
          data.length,
          isExpendData,
          isExpendNote,
          expendHeight,
          height
        )}
        duretion={300}
        timeFunction={"ease"}
        overflow={this.handleOverFlow(data.length, isExpendData, isExpendNote)}
      >
        {this.updateSupportingFileTableTableWithTransitionHeightTable()}
      </TransitionWhenHeightChange>
    );
  };

  updateSupportingFileTableTableNoTransitionHeight = () => {
    const {
      optionList,
      isRevisePage,
      fileSectionDisabled,
      procedureNoteSize,
      fileTableRef,
      className,
      downloadFile,
      handleFileSelect,
      currentRole,
      procedureNoteMaxLength,
      isSupportingFile,
      isShowHistoryVersion,
      inputOnChange,
      isShowReturnReason,
      handleReturnClick,
      hasSubmit,
    } = this.props;
    const { columns, data, isExpendData } = this.state;
    return (
      <table className={`${className} table`}>
        <BaseThead columns={columns} hasSubmit={hasSubmit} />
        {this.handleReturnTbody({
          procedureNoteSize,
          hasSubmit,
          data,
          columns,
          inputOnChange,
          downloadFile,
          fileSectionDisabled,
          fileTableRef,
          optionList,
          isShowReturnReason,
          currentRole,
          isRevisePage,
          isExpendData,
          isShowHistoryVersion,
          isSupportingFile,
          procedureNoteMaxLength,
          handleReturnClick,
          handleFileSelect,
        })}
      </table>
    );
  };

  render() {
    const { isScrollData, isShowTotalFileNum, isShowExpendIcon } = this.props;
    const { data } = this.state;
    return (
      <>
        <div className="tableTop">
          {data.length > this.defaultShowDataNum &&
            isShowExpendIcon &&
            this.updateSupportingFileTableListControlIcon()}
        </div>
        <div className="scrollTable">
          {isScrollData
            ? this.updateSupportingFileTableTableWithTransitionHeight()
            : this.updateSupportingFileTableTableNoTransitionHeight()}
        </div>
        {isShowTotalFileNum && (
          <p className="fileNumTip" style={{ color: "#333" }}>
            <span className="fileNum">{this.state.data.length}</span>{" "}
            {this.setFileText(this.state.data.length)} in total
          </p>
        )}
      </>
    );
  }
}
