import { combineReducers } from "redux";
import { takeLatest, put, all } from "redux-saga/effects";

// action type

const testType = "testType";
const testSageType = "testSageType";

const initialState = {
  test: "",
};
// synchronization action
export const testAction = (payload) => ({
  type: testType,
  payload,
});

export const testSagaAction = (payload) => ({
  type: testSageType,
  payload,
});

// async saga action
function* testSaga(action) {
  try {
    // do something like call api use call function
    // const result = yield call(api)
    const m = yield 22222;
    console.log(m);
    // when you get data from api ,you can dispatch action use put
    yield put(testAction(m));
  } catch (e) {}
}

export function* watcherGlobalSaga() {
  yield all([takeLatest(testSageType, testSaga)]);
}

// reducer
const test = (state = initialState.test, action) => {
  if (action.type === testType) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  test,
});
