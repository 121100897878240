import { React, createRef } from "react";
import BaseProcedureSavedIn from "../../../../commonComponents/savedInFields/baseSavedInField";
import {
  procedureJbStatus,
  savedInRadioValues,
} from "../../../../publicDictionaryValues";
import downloadIcon from "../../../../../assets/image/downloadIcon.png";
import styles from "../../index.module.scss";
import modalstyles from "../../applyForJbModal/index.module.scss";
import "../../applyForJbApproveSection/approve.scss";
import ApproveApplyJbTable from "../../../procedureFileSections/fields/procedureFileTableFields/approveApplyJbFileTable";
import BaseClientServerPath from "../../../../commonComponents/serverPathFields/baseServerPathField";
import { Input, Select } from "antd";
import BaseApplyForJbSection from "../../baseApplyForJbSection";

class ApplyForJbSubmitReadOnlySection extends BaseApplyForJbSection {
  constructor(props) {
    super(props);
    this.state = {
      clientServerPathReturnReason: this.props.rejectReason,
      isServerPathReturnBtnDisabled: false,
      isServerPathApproveBtnDisabled: false,
      isServerPathReturned: false,
      downloadBtnDisabled: true,
      downloadIcon: downloadIcon,
      serverPathReturnReasonInputValue: "",
      procedureFileTableState: {
        fileTableReasonStatus: "",
        isTableBorderError: false,
        fileTableReturnReasonValue: "",
        isChange: false,
      },
    };

    this.fileTableRef = createRef();
    this.files = [];
  }

  updateApplyForJbSubmitReadOnlyDataList = (dataList) => {
    this.fileTableRef.current.updateDataList(dataList);
  };

  updateDataList = (dataList) => {
    this.updateApplyForJbSubmitReadOnlyDataList(dataList);
  };

  initApplyForJbSubmitReadOnlyDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = this.props.procedureFileData;
    this.fileTableRef.current.updateDataList(dataList);
  };

  getApplyForJbSubmitReadOnlyDataList = () => {
    if (this.fileTableRef.current === null) {
      return;
    }
    const dataList = JSON.parse(
      JSON.stringify(this.fileTableRef.current.state.data)
    );

    return dataList;
  };

  handleApplyForJbSubmitReadOnlyJbProcedureSavedValue = (
    procedureJbStatusId
  ) => {
    if (procedureJbStatusId === null) {
      return null;
    }

    return procedureJbStatus[procedureJbStatusId];
  };

  handleApplyForOption = (procedureJbStatusId) => {
    if (procedureJbStatusId === null) {
      return null;
    }

    const statusOption =
      procedureJbStatus[procedureJbStatusId] === "JB" ? "Non JB" : "JB";

    return [
      {
        value: "",
        label: "",
      },
      {
        value: statusOption,
        label: statusOption,
      },
    ];
  };

  componentDidMount() {
    this.initApplyForJbSubmitReadOnlyDataList();
  }

  handleApplyForJbSubmitReadOnlyColumn = () => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow: true,
      },
      {
        name: "Apply for?",
        dataName: "applyfor",
        type: "select",
        isShow: !this.props.isCopyPage,
        isDisabled: true,
      },
    ];
  };

  handleApplyForJbSubmitReadOnlyJbProcedureSaved = () => {
    const { procedureJbStatusId, applyfor, isCopyPage } = this.props;

    return (
      <div className={modalstyles.secondDiv}>
        <div className={modalstyles.jbFieldDiv}>
          <div className={modalstyles.jbProcedureDiv}>
            <span>JB Procedure Saved</span>
            <Input
              value={this.handleApplyForJbSubmitReadOnlyJbProcedureSavedValue(
                isCopyPage && applyfor ? applyfor : procedureJbStatusId
              )}
              disabled
              className="jbProcedureInput"
            />
          </div>
          {!isCopyPage && (
            <div className={modalstyles.applyForDiv}>
              <span>Apply for?</span>
              <Select
                value={procedureJbStatus[applyfor]}
                options={this.handleApplyForOption(procedureJbStatusId)}
                className="applyForSelect"
                disabled={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  handleApplyForJbSubmitReadOnlyApplyJbTable = () => {
    const columns = this.handleApplyForJbSubmitReadOnlyColumn();

    return (
      <div className={`${styles.downloadButtonDiv} applyJbTable`}>
        <ApproveApplyJbTable
          ref={this.fileTableRef}
          columns={columns}
          procedureNoteMaxLength={1000}
          fileSectionDisabled={false}
          procedureNoteSize="small"
          inputOnChange={this.inputOnChange}
          hasSubmit={false}
          isScrollData={false}
          fileTableRef={this.fileTableRef}
          className="procedureFiles"
          isShowTotalFileNum={true}
          isSupportingFile={false}
          isShowExpendIcon={false}
          downloadFile={this.downloadFile}
          readOnly={true}
          isSubmitCompletePage={this.props.isSubmitCompletePage}
        />
      </div>
    );
  };

  handleApplyForJbSubmitReadOnlyApplyJbTableSection = () => {
    return (
      <>
        {this.handleApplyForJbSectionDownloadButton(this.state.downloadBtnDisabled)}
        {this.handleApplyForJbSubmitReadOnlyApplyJbTable()}
      </>
    );
  };

  render() {
    const { savedInRadioValue, serverPathValue } = this.props;

    return (
      <div className={styles.applyJbSectionsDiv}>
        <div className={styles.applyJbSectionTop}>
          <span className={`${styles.applyJbSectionTitle} fs-18`}>
            Procedure Files
          </span>
        </div>
        <div className={styles.topDiv}>
          <div className={styles.savedInDiv}>
            <BaseProcedureSavedIn
              savedInRadioValue={savedInRadioValue}
              disabled={true}
            />
          </div>
          <div
            className={`${modalstyles.clientPathDiv} ${
              savedInRadioValue !== savedInRadioValues.systemOnly
                ? ""
                : "unvisible"
            }`}
          >
            <BaseClientServerPath
              procedureSaveInValue={savedInRadioValue}
              value={serverPathValue}
              disabled={true}
              status=""
              placeholder=""
              showRequired={false}
            />
          </div>
        </div>
        {savedInRadioValue !== savedInRadioValues.systemOnly &&
          this.handleApplyForJbSubmitReadOnlyJbProcedureSaved()}
        {savedInRadioValue !== savedInRadioValues.serverOnly &&
          this.handleApplyForJbSubmitReadOnlyApplyJbTableSection()}
      </div>
    );
  }
}

export default ApplyForJbSubmitReadOnlySection;
