import React, { createRef } from "react";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import { ExpendTextArea } from "../../../../../../base/basicComponents/input";
import Buttons from "../../../../../../base/basicComponents/button/index";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import reasonEditIcon from "../../../../../../assets/image/reasonEditIcon.png";
import returnReasonLogIcon from "../../../../../../assets/image/returnReasonLogIcon.png";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import { dataStatus } from "../../../../../publicDictionaryValues";
import styles from "../index.module.scss";

const serverPathReturnRef = createRef();
export default class ApproveClientServerPath extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      serverPathSectionState: {
        serverPathReturnReasonValue: this.props.clientServerPathReturnReason,
        serverPathStatus: this.props.serverPathStatusID,
        isServerPathReturnBtnDisabled: this.props.isServerPathReturnBtnDisabled,
        isServerPathApproveBtnDisabled:
          this.props.isServerPathApproveBtnDisabled,
        isServerPathShowEditBtn: false,
        serverPathReasonEditIcon: reasonEditIcon,
        isServerPathReturnReasonDisabled:
          this.props.isServerPathReturnReasonDisabled,
        serverPathReasonLogIcon: returnReasonLogIcon,
        serverPathHide: false,
        isServerPathError: false,
        serverPathReasonStatus: null,
        serverPathReturnReasonInputValue:
          this.props.serverPathReturnReasonInputValue,
        serverPathValue: this.props.serverPathValue,
        isServerPathSectionChange: this.props.isServerPathSectionChange,
      },
      modalState: {
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        modalLabel: "",
        isShowTextArea: false,
        closable: false,
        modalWidth: 520,
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
      },
    };

    this.isServerPathReturned = this.props.isServerPathReturned;
    this.returnModalInputPlacement = "Please enter your return reason.";
    this.modalContentLabels = {
      returnModalLable: (
        <InputLabel
          text="Please fill in the reason for return:"
          required={true}
          className={styles.modalText}
        />
      ),
      approveAfterReturnModalLabel: (
        <span className={styles.modalText}>
          Are you sure to change the selection? Your Return Reason will be
          cleared.
        </span>
      ),
    };
    this.modalTitles = {
      returnModalTitle: <span className="fs-18">Reason for Return</span>,
    };
  }

  /* function about close modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        modalLabel: "",
        isShowTextArea: false,
        closable: false,
        modalWidth: 520,
        isShowSelect: false,
      },
    });
  };

  /* function about click return popUp cancel button */
  handleReturnCancelClick = () => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonValue:
          this.state.serverPathSectionState.serverPathReturnReasonInputValue,
        serverPathReasonStatus: null,
      },
    });
    this.hideModal();
  };

  /* function about click confirm button of return to draft modal in server path section */
  handleServerPathReturnOkClick = () => {
    const serverPathReturnReasonValue =
      this.state.serverPathSectionState.serverPathReturnReasonValue;
    let value =
      serverPathReturnReasonValue !== null &&
      serverPathReturnReasonValue !== undefined
        ? serverPathReturnReasonValue.trim()
        : serverPathReturnReasonValue;
    if (value !== null && value !== "" && value !== undefined) {
      this.hideModal();
      serverPathReturnRef.current.setValue(value);
      this.setState({
        serverPathSectionState: {
          ...this.state.serverPathSectionState,
          serverPathReturnReasonInputValue: value,
          serverPathStatus: dataStatus.return,
          isServerPathReturnBtnDisabled: true,
          isServerPathApproveBtnDisabled: false,
          isServerPathShowEditBtn: true,
          isServerPathReturnReasonDisabled: false,
          isServerPathError: false,
        },
      });

      this.isServerPathReturned = true;
    } else {
      this.setState({
        serverPathSectionState: {
          ...this.state.serverPathSectionState,
          serverPathReasonStatus: "error",
        },
      });
    }
  };

  /* function about click approve popUp cancel button */
  handleSectionApproveCancelClick = () => {
    this.hideModal();
  };

  /**
   * function about set return reason value in server path section
   * @param {*} e
   */
  handleSetServerPathReturnReasonValue = (e) => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonValue: e.target.value,
        serverPathReasonStatus: null,
      },
    });
  };

  /* function about click client server path section return to draft button */
  handleServerPathReturnClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: this.modalTitles.returnModalTitle,
        okText: "Confirm",
        textAreaPlacement: this.returnModalInputPlacement,
        textAreaMaxLength: 1000,
        modalLabel: this.modalContentLabels.returnModalLable,
        isShowTextArea: true,
        closable: false,
        handleOk: this.handleServerPathReturnOkClick,
        handleCancel: this.handleReturnCancelClick,
      },
    });
  };

  /* function about data change click approve in client server path section after clicking return to draft button */
  serverPathApproveAfterReturnDataChange = () => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonInputValue: null,
        serverPathReturnReasonValue: null,
        serverPathStatus: dataStatus.approval,
        isServerPathApproveBtnDisabled: true,
        isServerPathReturnBtnDisabled: false,
        isServerPathShowEditBtn: false,
        isServerPathReturnReasonDisabled: true,
        isServerPathError: false,
      },
    });
    this.isServerPathReturned = false;
    this.hideModal();
    serverPathReturnRef.current.clearValue();
  };

  /* function about click confirm button in approve modal in client server path section after clicking return to draft button */
  handleServerPathApproveAfterReturnOkClick = () => {
    this.serverPathApproveAfterReturnDataChange();
  };

  /* function about click saved in section approve button after i click return to draft before */
  handleApprovedAfterReturned = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: this.modalContentLabels.approveAfterReturnModalLabel,
        okText: "Confirm",
        modalTitle: null,
        closable: false,
        modalWidth: 520,
        handleOk: this.handleServerPathApproveAfterReturnOkClick,
      },
    });
  };

  handleServerPathNoReturnApprove = () => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathStatus: dataStatus.approval,
        isServerPathApproveBtnDisabled: true,
        isServerPathReturnBtnDisabled: false,
        isServerPathExpendNote: false,
        isServerPathShowEditBtn: false,
        isServerPathReturnReasonDisabled: true,
        isServerPathError: false,
      },
    });
    serverPathReturnRef.current?.clearValue();
    this.hideModal();
  };

  /* function about click approve button in client server path section */
  handleServerPathApprovedClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalWidth: 520,
      },
    });
    if (this.isServerPathReturned) {
      this.handleApprovedAfterReturned();
    } else {
      this.handleServerPathNoReturnApprove();
    }
  };

  /**
   * function about set return reason value when it change
   * @param {*} e
   */
  handleServerPathReturnReasonChange = (e) => {
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        serverPathReturnReasonValue: e.target.value,
      },
    });
  };

  /* function about click confirm button in approve modal in client server path section */
  handleServerPathApproveOkClick = () => {
    this.serverPathApproveAfterReturnDataChange();
  };

  /**
   * if server path required not follow will set error state
   * @returns
   */
  setServerPathError = () =>
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        isServerPathError: true,
      },
    });

  cancelServerPathError = () =>
    this.setState({
      serverPathSectionState: {
        ...this.state.serverPathSectionState,
        isServerPathError: false,
      },
    });

  setApproveServerPathTop = () => {
    return (
      <div className={styles.approveServerPathTop}>
        <div className={styles.procedureServerPathLabel}>
          Client Server Path:
        </div>
        <ExpendTextArea
          className={styles.procedureServerPathValue}
          value={this.state.serverPathSectionState.serverPathValue}
          placeholder="Please enter Client Server Path"
          readonly="readonly"
          maxLength={500}
        />
      </div>
    );
  };

  componentDidMount() {
    const { serverPathStatus, isServerPathSectionChange } =
      this.state.serverPathSectionState;

    if (
      !serverPathStatus &&
      isServerPathSectionChange === "ServerPathNoChange"
    ) {
      this.handleServerPathNoReturnApprove();
    }
  }

  createServerPathBtns = (
    isServerPathApproveBtnDisabled,
    isDisableAll,
    handleServerPathApprovedClick,
    isServerPathReturnBtnDisabled,
    handleServerPathReturnClick
  ) => (
    <>
      <div>
        <Buttons
          color="blue"
          disabled={isServerPathApproveBtnDisabled || isDisableAll}
          size="small"
          onClick={handleServerPathApprovedClick}
          btnClassName={styles.approveBtn}
        >
          Approve
        </Buttons>
      </div>
      <div>
        <Buttons
          color="grey"
          disabled={isServerPathReturnBtnDisabled || isDisableAll}
          size="small"
          onClick={handleServerPathReturnClick}
          btnClassName={styles.returnBtn}
        >
          Return to Draft
        </Buttons>
      </div>
    </>
  );

  render() {
    const {
      procedureServerPathRef,
      isDisableAll,
      initServerPathStatus,
      isShow = true,
    } = this.props;
    const {
      serverPathStatus,
      isServerPathReturnReasonDisabled,
      isServerPathShowEditBtn,
      isServerPathApproveBtnDisabled,
      isServerPathReturnBtnDisabled,
      isServerPathError,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      isServerPathSectionChange,
    } = this.state.serverPathSectionState;
    const {
      isModalWithComponentOpen,
      modalTitle,
      okText,
      cancelText,
      textAreaPlacement,
      textAreaMaxLength,
      modalLabel,
      isShowTextArea,
      closable,
      modalWidth,
      handleOk,
      handleCancel,
      isShowSelect,
    } = this.state.modalState;

    return (
      <div
        className={styles.procedureFileTop}
        style={{ display: isShow ? "block" : "none" }}
      >
        <div
          className={styles.clientServerPath}
          style={{ borderColor: isServerPathError ? "red" : "#b3b3b3" }}
          ref={procedureServerPathRef}
        >
          <div>{this.setApproveServerPathTop()}</div>
          <div className={styles.serverPathContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                {isServerPathSectionChange !== "ServerPathNoChange" && (
                  <SectionStatusDiv status={serverPathStatus} />
                )}
              </div>
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Return Reason</div>
              <ReturnReasonInput
                inputRef={serverPathReturnRef}
                value={serverPathReturnReasonInputValue}
                isDisabled={isServerPathReturnReasonDisabled || isDisableAll}
                isShowEditBtn={isServerPathShowEditBtn}
                handleReturnEditClick={() => this.handleServerPathReturnClick()}
                isShowReasonLogIcon={
                  this.props.serverPathReturnReasonLog.length > 0
                }
                reasonLog={this.props.serverPathReturnReasonLog}
              />
            </div>
            <div className={styles.actionBtns}>
              {!isDisableAll &&
                initServerPathStatus !== dataStatus.approval &&
                isServerPathSectionChange !== "ServerPathNoChange" &&
                this.createServerPathBtns(
                  isServerPathApproveBtnDisabled,
                  isDisableAll,
                  this.handleServerPathApprovedClick,
                  isServerPathReturnBtnDisabled,
                  this.handleServerPathReturnClick
                )}
            </div>
          </div>
        </div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          className={styles.approvePageModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isShowTextArea={isShowTextArea}
          textAreaValue={serverPathReturnReasonValue}
          textAreaOnChange={this.handleSetServerPathReturnReasonValue}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={serverPathReasonStatus}
          label={modalLabel}
          modalWidth={modalWidth}
          isShowSelect={isShowSelect}
        />
      </div>
    );
  }
}
