import ShareTaskTable from "../shareTaskTable";
import TaskInformation from "../../taskInfoSections";
import { FileStatusDiv } from "../../../../base/basicComponents/status";
import styles from "../index.module.scss";
import approveStyles from "../approveShareTask/approveShareTask.module.scss";
import BaseShareTask from "../baseShareTask";
import noSharedTaskImg from "../../../../assets/image/noSharedTask.png";
import NormalModal from "../../../../base/basicComponents/modal";

export default class BaseReadOnlyShareTask extends BaseShareTask {
  handleDataColumn = () => {
    return [
      this.isMainTaskColumn,
      ...this.commonShareDataColumn,
      {
        title: "",
        dataIndex: "tag",
        render: (text, record) => (
          <div className={styles.fileStatus}>
            <FileStatusDiv status={this.HandleModalTableTag(record)} />
          </div>
        ),
      },
    ];
  };

  render() {
    const { shareTasks } = this.props.shareTaskData ?? [];

    const {
      isTaskInfoModalOpen,
      taskInformationData,
      isReasonLogModalOpen,
      returnReasonLog,
      isRequestReasonLogModalOpen,
      requestReasonLog,
    } = this.state;

    const tableData = this.transformPendingData(shareTasks);

    return (
      <div className={styles.shareInformation}>
        <div className={approveStyles.approveShareInfoTop}>
          <div className={approveStyles.approveShareTitleDiv}>
            <span className={`${styles.shareTitle} fs-18`}>Shared Tasks</span>
          </div>
          {tableData !== null && this.handleReturnReasonLog()}
        </div>
        {tableData !== null && this.handleReturnReason()}
        <div className={styles.sharedTaskArea}>
          {tableData === null ? (
            <div className={styles.noSharedTaskDiv}>
              <img
                alt="no shared task"
                src={noSharedTaskImg}
                className={styles.noSharedTaskImg}
              />
              <p className={`${styles.noSharedTaskMassage} fs-16`}>
                No Shared Task Yet
              </p>
            </div>
          ) : (
            <>
              <ShareTaskTable
                data={tableData}
                columns={this.handleDataColumn()}
              />
              <p className="fileNumTip" style={{ color: "#333" }}>
                <span className="fileNum">{tableData.length}</span>{" "}
                {this.setFileText(tableData.length)} in total
              </p>
            </>
          )}
        </div>
        {tableData !== null && this.handleSubmitReason()}
        <NormalModal
          centered
          footer={null}
          title="Task Information"
          isModalOpen={isTaskInfoModalOpen}
          handleCancel={this.HandleTaskInfoModalClose}
          width="93rem"
          childContent={
            <TaskInformation data={taskInformationData} isInModal={true} />
          }
          zIndex={990}
          className="taskInfoModal"
          closable={true}
        />
        {this.handleReasonLogModal(
          isReasonLogModalOpen,
          returnReasonLog,
          isRequestReasonLogModalOpen,
          requestReasonLog
        )}
      </div>
    );
  }
}
