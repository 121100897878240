import React from "react";
import ApprovePPOPending from "./approveChangePPOPending";
import ApprovePPOCompleted from "./approveChangePPOCompleted";
import ReviseChangePPOPending from "./reviseChangePPOPending";
import ReviseChangePPOCompleted from "./reviseChangePPOCompleted";
import ApproveChangePPOService from "../../../service/changePPO/ApproveChangePPOService";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import { actions } from "../../publicDictionaryValues";
import ProcessBase from "../../commonComponents/processBase";
import NormalSpin from "../../../base/basicComponents/spin";

export default class PrimaryProcedureOwner extends ProcessBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageHeadState: {
        receiveDate: null,
        requestByName: null,
      },
      procedureInfoState: {
        isPMPExcluded: null,
        procedureStatus: null,
        primaryProcedureOwner: null,
        procedureOwner: null,
        procedureNote: null,
        returnReasonLog: [],
        requestReasonLog: [],
      },
      rejectReason: null,

      pageType: "",
      primaryProcedureOwnerStepItem: {},
      isApprovedCompletedPage: false,
      isSubmittedCompletedPage: false,
      isReturnedCompletedPage: false,
      isRejectedCompletedPage: false,
      shareTask: null,
      procedureFileData: {
        procedureTableData: [],
        clientServerPath: null,
        savedInRadioValue: null,
      },
      supportingFileData: [],
      jbProcedureSavedIn: null,
      taskInformationData: [],
      stepItemData: null,
    };
    this.pageTypeEnums = {
      isApprovePending: "isApprovePending",
      isApproveCompleted: "isApproveCompleted",
      isRevisePending: "isRevisePending",
      isReviseCompleted: "isReviseCompleted",
    };
  }

  _showLoading = () => {
    this.setState({ loading: true });
  };

  _hideLoading = () => {
    this.setState({ loading: false });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  returnReasonLog = (stepHistoryList) => {
    const returnReasonLog = [];
    stepHistoryList.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    stepHistoryList.forEach((item, index) => {
      if (item.actionID === actions.return) {
        returnReasonLog.push({
          key: index,
          returnedBy: item.auditedByName,
          returnDate: this._formateDate(item.modifiedDate),
          returnReasonColumn: item.returnReason,
        });
      }
    });
    return returnReasonLog;
  };

  requestReasonLog = (stepHistoryList) => {
    const requestReasonLog = [];
    stepHistoryList.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    stepHistoryList.forEach((item, index) => {
      if (
        item.actionID === actions.submit ||
        item.actionID === actions.revise
      ) {
        requestReasonLog.push({
          key: index,
          submittedBy: item.requestByName,
          submittedDate: this._formateDate(item.modifiedDate),
          reasonColumn: item.reason,
        });
      }
    });
    return requestReasonLog;
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setState({
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder !== null
              ? result.procedureFolder.procedureSaveInCategoryID
              : null,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
    }
  };

  setShareTaskData = (result) => {
    if (result.shareTask === null || result.shareTask === undefined) {
      return;
    }

    this.setState({
      shareTask: result.shareTask,
    });
  };

  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingFileData: this.supportingTableData,
      });
    }
  };

  _getPrimaryProcedureOwnerStepItemSuccess = (result) => {
    const {
      actionID,
      primaryProcedureOwner,
      workflowProcessStepInfo,
      shareTask,
      procedureFolder,
      reviceDate,
      requestByName,
      procedureOwner,
      procedureNote,
      workflowProcessInstanceId,
      rejectReason,
      applyPmpExcluded,
      sequence,
      procedureStatusID,
    } = result;
    const { procedureStepStatus, actionTaken } = primaryProcedureOwner;
    const isApprove =
      workflowProcessStepInfo.annotations.find(
        (item) => (item.name = "IsRevise")
      ).value === "0";
    const isSubmit = sequence === 1;
    let pageType = "";
    if (procedureStepStatus === "Pending") {
      pageType = isApprove ? "isApprovePending" : "isRevisePending";
    } else if (procedureStepStatus === "Complete") {
      pageType =
        isApprove || isSubmit ? "isApproveCompleted" : "isReviseCompleted";
    }
    const isApprovedCompletedPage = actionTaken === "Approved" || actionTaken === "Automatically Approved";
    const isSubmittedCompletedPage = isSubmit;
    const isReturnedCompletedPage = actionID === actions.return;
    const isRejectedCompletedPage = actionID === actions.reject;
    this.setProcedureTableData(procedureFolder);
    this.setSupportingTableData(result);
    this.setState({
      pageType,
      primaryProcedureOwnerStepItem: result,
      isApprovedCompletedPage,
      isSubmittedCompletedPage,
      isReturnedCompletedPage,
      isRejectedCompletedPage,
      shareTask,
      jbProcedureSavedIn:
        result.procedureFolder === null
          ? null
          : result.procedureFolder.jbProcedureSaveIn,
      pageHeadState: {
        receiveDate: this._formateDate(reviceDate),
        requestByName,
      },
      procedureInfoState: {
        isPMPExcluded: applyPmpExcluded?.isExcluded ? "Yes" : "No",
        procedureStatus: procedureStatusID,
        primaryProcedureOwner,
        procedureOwner,
        procedureNote,
        returnReasonLog: this.returnReasonLog(
          primaryProcedureOwner?.stepHistoryList
        ),
        requestReasonLog: this.requestReasonLog(
          primaryProcedureOwner?.stepHistoryList
        ),
        workflowProcessInstanceId,
      },
      procedureFileData: {
        savedInRadioValue:
          result.procedureFolder?.procedureSaveInCategoryID || null,
        clientServerPath:
          result.procedureFolder !== null
            ? result.procedureFolder.clientServerPath
            : "",
        procedureTableData: this.procedureTableData,
      },
      supportingFileData: this.supportingTableData,
      stepItemData: result,
    });

    if (actionID === actions.reject && !!rejectReason) {
      this.setState({
        rejectReason,
      });
    }

    this._hideLoading();
  };

  _getPrimaryProcedureOwnerStepItemFailed = () => {
    this._alertError("Get Primary Procedure Owner Step Item Failed");
  };

  getPrimaryProcedureOwnerStepItem = () => {
    const parentPageData = this._getRoutingParam("ParentPage");
    const param = {
      taskID: this.taskID,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: parentPageData,
    };

    ApproveChangePPOService.getPrimaryProcedureOwnerStepItem(
      param,
      this._getPrimaryProcedureOwnerStepItemSuccess,
      this._getPrimaryProcedureOwnerStepItemFailed
    );
  };

  /* callback about get task information successed */
  _getTaskInfoSuccess = (result) => {
    const data = result;
    this.setState({
      taskInformationData: data,
    });
  };

  /* callback about get task information failed */
  _getTaskInfoFailed = (result) => {
    // this._alertError("Get Task Information Failed");
    console.log(result);
  };

  /* function about get task information section data */
  getTaskInfo = () => {
    ProcedureDetailService.getTaskInfo(
      this.taskID,
      this._getTaskInfoSuccess,
      this._getTaskInfoFailed
    );
  };

  closePage = () => {
    this.route("/ToDoList");
  };

  componentDidMount() {
    this._showLoading();
    this.getTaskInfo();
    this.getPrimaryProcedureOwnerStepItem();
  }

  render() {
    const {
      loading,
      pageType,
      isApprovedCompletedPage,
      isSubmittedCompletedPage,
      isReturnedCompletedPage,
      isRejectedCompletedPage,
      shareTask,
      pageHeadState,
      procedureInfoState,
      rejectReason,
      procedureFileData,
      supportingFileData,
      jbProcedureSavedIn,
      taskInformationData,
      stepItemData,
    } = this.state;
    const procedureInformationData = {
      pageHeadState,
      procedureInfoState,
      rejectReason,
    };

    return (
      <NormalSpin spinning={loading} size="large" zIndex={1500}>
        {pageType === this.pageTypeEnums.isApprovePending && (
          <ApprovePPOPending
            primaryProcedureOwnerStepItem={
              this.state.primaryProcedureOwnerStepItem
            }
            procedureTaskId={this.taskID}
            procedureID={this.procedureID}
            procedureStepID={this.procedureStepID}
            _showLoading={this._showLoading}
            _hideLoading={this._hideLoading}
            closePage={this.closePage}
            shareTask={shareTask}
            procedureInformationData={procedureInformationData}
            procedureFileData={procedureFileData}
            supportingTableData={supportingFileData}
            jbProcedureSavedIn={jbProcedureSavedIn}
            taskInformationData={taskInformationData}
            stepItemData={stepItemData}
            setProcedureFileData={this.setProcedureFileData}
            setSupportingFileData={this.setSupportingFileData}
            setTaskInfoData={this.setTaskInfoData}
            setProcedureInfoData={this.setProcedureInfoData}
            setShareTaskData={this.setShareTaskData}
          />
        )}
        {pageType === this.pageTypeEnums.isApproveCompleted && (
          <ApprovePPOCompleted
            primaryProcedureOwnerStepItem={
              this.state.primaryProcedureOwnerStepItem
            }
            procedureTaskId={this.taskID}
            procedureID={this.procedureID}
            procedureStepID={this.procedureStepID}
            _showLoading={this._showLoading}
            closePage={this.closePage}
            isApprovedCompletedPage={isApprovedCompletedPage}
            isSubmittedCompletedPage={isSubmittedCompletedPage}
            isReturnedCompletedPage={isReturnedCompletedPage}
            isRejectedCompletedPage={isRejectedCompletedPage}
            shareTask={shareTask}
            procedureInformationData={procedureInformationData}
            procedureFileData={procedureFileData}
            supportingTableData={supportingFileData}
            jbProcedureSavedIn={jbProcedureSavedIn}
            taskInformationData={taskInformationData}
            setProcedureFileData={this.setProcedureFileData}
            setSupportingFileData={this.setSupportingFileData}
            setTaskInfoData={this.setTaskInfoData}
            setProcedureInfoData={this.setProcedureInfoData}
            setShareTaskData={this.setShareTaskData}
          />
        )}
        {pageType === this.pageTypeEnums.isRevisePending && (
          <ReviseChangePPOPending
            primaryProcedureOwnerStepItem={
              this.state.primaryProcedureOwnerStepItem
            }
            procedureTaskId={this.taskID}
            procedureID={this.procedureID}
            procedureStepID={this.procedureStepID}
            _showLoading={this._showLoading}
            _hideLoading={this._hideLoading}
            closePage={this.closePage}
            shareTask={shareTask}
            procedureInformationData={procedureInformationData}
            procedureFileData={procedureFileData}
            supportingTableData={supportingFileData}
            jbProcedureSavedIn={jbProcedureSavedIn}
            taskInformationData={taskInformationData}
            setProcedureFileData={this.setProcedureFileData}
            setSupportingFileData={this.setSupportingFileData}
            setTaskInfoData={this.setTaskInfoData}
            setProcedureInfoData={this.setProcedureInfoData}
            setShareTaskData={this.setShareTaskData}
          />
        )}
        {pageType === this.pageTypeEnums.isReviseCompleted && (
          <ReviseChangePPOCompleted
            primaryProcedureOwnerStepItem={
              this.state.primaryProcedureOwnerStepItem
            }
            procedureTaskId={this.taskID}
            procedureID={this.procedureID}
            procedureStepID={this.procedureStepID}
            _showLoading={this._showLoading}
            closePage={this.closePage}
            shareTask={shareTask}
            procedureInformationData={procedureInformationData}
            procedureFileData={procedureFileData}
            supportingTableData={supportingFileData}
            jbProcedureSavedIn={jbProcedureSavedIn}
            taskInformationData={taskInformationData}
            setProcedureFileData={this.setProcedureFileData}
            setSupportingFileData={this.setSupportingFileData}
            setTaskInfoData={this.setTaskInfoData}
            setProcedureInfoData={this.setProcedureInfoData}
            setShareTaskData={this.setShareTaskData}
          />
        )}
      </NormalSpin>
    );
  }
}
