import { createRef } from "react";
import {
  dataStatus,
  fileTags,
  actions,
  revisionFromList,
  processDefaultTableSortFile,
  getRequestDateMoment,
  savedInRadioValues,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import ProcessBase from "../../commonComponents/processBase/index.jsx";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";

const procedureReplaceFileRef = createRef();

export default class ProcedureFileProcess extends ProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initProcedureFileRevisionInfoState();
    this.initProcedureFileState();
    this.serverPathReturnReasonLog = [];
    this.initProcedureTableData = [];
    this.savedInReturnReasonLog = [];
    this.procedureFileStepID = null;
    this.categoryID = null;
    this.accountID = null;
    this.taskGroupID = null;
    this.taskLabelID = null;
    this.clientServerJB = null;
    this.procedureStatusJB = null;
    this.isLevelCChange = false;
    this.isApprovePendingPage = undefined;
    this.isApproveCompletePage = undefined;
    this.isRevisePendingPage = undefined;
    this.isReviseCompletePage = undefined;
    this.isSubmitCompletePage = undefined;
  }

  initProcedureFileRevisionInfoState = () => {
    this.initRevisionInfoState = {
      revisionFormID: null,
      revisionDescription: null,
      revisionCategory: null,
      requestDate: null,
      requestFrom: null,
      requestFromDesc: null,
      attachmentIds: [],
      attachmentNames: [],
      attachmentFiles: [],
    };
  };

  /**
   * Init Component State
   */
  initProcedureFileState = () => {
    this.state = {
      pageState: {
        flowCategoryID: null,
        requestByID: null,
        requestByName: null,
        receiveDate: null,
        procedureID: null,
        procedureStepID: null,
        procedureAccountID: null,
        procedureStatus: null,
        isSaveDisabled: false,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        procedureFolder: {},
        rejectReason: null,
        rejectStatus: null,
        currentRole: null,
        actionName: null,
        loading: false,
        procedureStepStatus: null,
        uploadProcedureFileNum: 0,
        procedureFileNum: 0,
        procedureNote: "",
      },
      initTableDate: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: this.initProcedureFileTableState,
      revisionInfoState: this.initRevisionInfoState,
      supportingTableData: [],
      isReturnReasonLogOpen: false,
      fileTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      shareTaskData: {},
      ppoList: [],
      fileLoading: { currentNum: 0, allNum: 0 },
    };
  };

  _showLoading = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        loading: true,
      },
    });
  };

  _hideLoading = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        loading: false,
      },
    });
  };

  setProcedureFileSavedInSectionState = (result) => {
    return {
      ...this.state.savedInSectionState,
      procedureSaveInCategoryStatusID:
        result.procedureFolder?.procedureSaveInCategoryStatusID || null,
      isSavedInApproveBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.approval,
      isSavedInReturned:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
      isSavedInReturnBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
      savedInReturnReasonInputValue:
        result.procedureFolder?.procedureSaveInCategoryReturnReason || null,
      savedInRadioValue:
        result.procedureFolder?.procedureSaveInCategoryID || null,
      isSavedInSectionChange: result.procedureFolder?.isChangeProcedureSavedIn
        ? ""
        : "SavedInNoChange",
    };
  };

  setProcedureFileServerPathSectionState = (result) => {
    return {
      ...this.state.serverPathSectionState,
      serverPathValue: result.procedureFolder?.clientServerPath || null,
      serverPathReturnReasonInputValue:
        result.procedureFolder?.clientServerPathReturnReason || null,
      clientServerPathStatusName:
        result.procedureFolder?.clientServerPathStatusName || null,
      clientServerPath: result.procedureFolder?.clientServerPath || "",
      clientServerPathStatusID:
        result.procedureFolder?.clientServerPathStatusID || null,
      isServerPathApproveBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID ===
        dataStatus.approval,
      isServerPathReturnBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      isServerPathReturned:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      isServerPathSectionChange: result.procedureFolder?.isChangeClientServerPath
        ? ""
        : "ServerPathNoChange",
    };
  };

  setSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState: this.setProcedureFileSavedInSectionState(result),
      serverPathSectionState:
        this.setProcedureFileServerPathSectionState(result),
    });
  };

  /* function about adjust data when get data from api */
  handleProcedureFileDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  /* get proceudre file return reason log */
  getProcedureFileReturnReasonLog = (folder, key) => {
    const returnReasonLog = [];
    const seen = new Set();
    let historyList = folder.stepHistoryList;
    historyList.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    historyList.forEach((stepItem, index) => {
      stepItem.actionID === actions.return &&
        stepItem.procedureFiles.forEach((fileItem, index) => {
          if (
            fileItem.fileKey === key &&
            fileItem.dataStatusID === dataStatus.return &&
            fileItem.returnReason !== null &&
            !seen.has(fileItem.uploadedDate)
          ) {
            seen.add(fileItem.uploadedDate);
            returnReasonLog.push({
              key: index,
              returnedBy: stepItem.auditedByName,
              returnDate: this._formateDate(stepItem.modifiedDate),
              returnReasonColumn: fileItem.returnReason,
            });
          }
        });
    });
    return returnReasonLog;
  };

  /* function set approve and return button disabled when init load page */
  handleReturnInitBtnDisabled = (procedureFile, status) =>
    this.isRevisePage
      ? false
      : this.handleProcedureFileDataNull(procedureFile, "dataStatusID") ===
        status;

  /* function about sort approve apge and revise page procedure file list */
  handleSortFile = (result) => {
    return processDefaultTableSortFile(
      result.procedureFolder?.procedureFiles,
      this._formateDate
    );
  };

  /* function about prepare file table data to show */
  setProcedureTableData = (result) => {
    if (result.procedureFolder === null) {
      return;
    }
    const procedureFileList = this.handleSortFile(result);
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    procedureFileList.forEach((procedureFile) => {
      const isReviseOrNoOldFile =
        this.handleProcedureFileDataNull(procedureFile, "oldFileID") === null ||
        !isRevise;
      const isDeleteOrNoOldFile =
        this.handleProcedureFileDataNull(procedureFile, "oldFileID") === null ||
        this.handleProcedureFileDataNull(procedureFile, "fileTagID") ===
          fileTags.deleted;
      if (!procedureFile.isDelete) {
        const lineId = this._generateGUID();
        const uploadedDate = this.handleProcedureFileDataNull(
          procedureFile,
          "uploadedDate"
        );
        const approvedDate = this.handleProcedureFileDataNull(
          procedureFile,
          "approvedDate"
        );
        this.procedureTableData.push({
          data: [
            { name: "lineId", value: lineId },
            {
              name: "fileId",
              value: isReviseOrNoOldFile
                ? this.handleProcedureFileDataNull(procedureFile, "fileID")
                : this.handleProcedureFileDataNull(procedureFile, "oldFileID"),
            },
            {
              name: "procedureFileID",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "procedureFileID"
              ),
            },
            {
              name: "procedureFileStepID",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "procedureFileStepID"
              ),
            },
            {
              name: "fileKey",
              value: this.handleProcedureFileDataNull(procedureFile, "fileKey"),
            },
            {
              name: "fileName",
              value: isReviseOrNoOldFile
                ? this.handleProcedureFileDataNull(procedureFile, "fileName")
                : this.handleProcedureFileDataNull(
                    procedureFile,
                    "oldFileName"
                  ),
              tagValue: this.handleProcedureFileDataNull(
                procedureFile,
                "fileTagID"
              ),
            },
            {
              name: "uploadedDate",
              value: this._formateDate(uploadedDate),
            },
            {
              name: "uploadByName",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "uploadByName"
              ),
            },
            {
              name: "uploadById",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "uploadBy"
              ),
            },
            {
              name: "note",
              value: this.handleProcedureFileDataNull(procedureFile, "note"),
              isDisabled: false,
            },
            {
              name: "proposedChange",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "proposedChangeTagID"
              ),
            },
            {
              name: "status",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "dataStatusID"
              ),
            },
            {
              name: "returnReason",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "returnReason"
              ),
              isDisabled:
                this.handleProcedureFileDataNull(
                  procedureFile,
                  "returnReason"
                ) === null,
              isShowReasonLog:
                this.getProcedureFileReturnReasonLog(
                  result.procedureFolder,
                  procedureFile.fileKey
                ).length > 0,
              reasonLog: this.getProcedureFileReturnReasonLog(
                result.procedureFolder,
                procedureFile.fileKey
              ),
            },
            {
              name: "attachment",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "attachmentName"
              ),
              fileId: this.handleProcedureFileDataNull(
                procedureFile,
                "attachmentFileID"
              ),
              link: "#JavaScript:;",
              isDisabled: false,
            },
            {
              name: "newVersion",
              value: isDeleteOrNoOldFile
                ? null
                : this.handleProcedureFileDataNull(procedureFile, "fileName"),
              fileId: isDeleteOrNoOldFile
                ? null
                : this.handleProcedureFileDataNull(procedureFile, "fileID"),
              link: "#JavaScript:;",
            },
            {
              name: "previousStatus",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "dataStatusID"
              ),
            },
            {
              name: "previousFileTag",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "fileTagID"
              ),
            },
            {
              name: "isReviewed",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "isReviewed"
              ),
            },
            {
              name: "approveDate",
              value: this._formateDate(approvedDate),
            },
            {
              name: "jbProcedure",
              value: this.handleProcedureFileDataNull(
                procedureFile,
                "jbProcedure"
              ),
            },
          ],
          checked: false,
          isApproveDisabled: this.handleReturnInitBtnDisabled(
            procedureFile,
            dataStatus.approval
          ),
          isReturnDisabled: this.handleReturnInitBtnDisabled(
            procedureFile,
            dataStatus.return
          ),
          isReturned: this.handleReturnInitBtnDisabled(
            procedureFile,
            dataStatus.return
          ),
          isError: false,
          replaceRef: procedureReplaceFileRef,
          attachmentEditIcon: reasonEditDefaultIcon,
          isDelete: false,
          isUpdate: false,
        });
      }
    });
  };

  setProcedureFileSavedInReturnReasonLog = (item, index) => {
    if (
      item.procedureSaveInCategoryStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.savedInReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.procedureSaveInCategoryReturnReason,
      });
    }
  };

  setProcedureFileServerPathReturnReasonLog = (item, index) => {
    if (
      item.clientServerPathStatusID === dataStatus.return &&
      item.actionID === actions.return &&
      (item.procedureSaveInCategoryID === savedInRadioValues.systemAndClientServer ||
        item.procedureSaveInCategoryID === savedInRadioValues.serverOnly)
    ) {
      this.serverPathReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.clientServerPathReturnReason,
      });
    }
  };

  setProcedureFileSavedInAndServerPathReturnReasonLog = (list) => {
    list.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    list.forEach((item, index) => {
      this.setProcedureFileSavedInReturnReasonLog(item, index);
      this.setProcedureFileServerPathReturnReasonLog(item, index);
    });
  };

  /**
   * set supporting file section data
   * @param {*} result
   */
  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingTableData: this.supportingTableData,
      });
    }
  };

  setRevisionInfo = (data) => {
    const fileIds = [];
    const fileNames = [];
    const fileList = [];
    data.attachmentFiles !== null &&
      data.attachmentFiles.forEach((item) => {
        fileIds.push(item.fileId);
        fileNames.push(item.fileName);
        fileList.push({ fileId: item.fileId, fileName: item.fileName });
      });
    this.setState({
      revisionInfoState: {
        ...this.state.revisionInfoState,
        revisionFormID: data.revisionFormID,
        revisionDescription: data.revisionDescription,
        revisionCategory: data.revisionCategoryID,
        requestDate: data.requestDate,
        requestFrom: data.requestFromID,
        requestFromDesc: data.requestFromDesc,
        attachmentIds: [...fileIds],
        attachmentNames: [...fileNames],
        attachmentFiles: [...fileList],
      },
    });
  };

  /**
   * function about set revision information data from api
   * @param {obj} data
   * @returns
   */
  buildRevisionInfoState = (data) => {
    const fileIds = [];
    const fileNames = [];
    const fileList = [];
    data.attachmentFiles !== null &&
      data.attachmentFiles.forEach((item) => {
        fileIds.push(item.fileId);
        fileNames.push(item.fileName);
        fileList.push({ fileId: item.fileId, fileName: item.fileName });
      });

    return {
      ...this.state.revisionInfoState,
      revisionFormID: data.revisionFormID,
      revisionDescription: data.revisionDescription,
      revisionCategory: data.revisionCategoryID,
      requestDate: data.requestDate,
      requestFrom: data.requestFromID,
      requestFromDesc: data.requestFromDesc,
      attachmentIds: [...fileIds],
      attachmentNames: [...fileNames],
      attachmentFiles: [...fileList],
    };
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      const historyList = result.procedureFolder?.stepHistoryList.sort((x, y) =>
        y.modifiedDate.localeCompare(x.modifiedDate)
      );

      this.initServerPathSectionState = {
        ...this.initServerPathSectionState,
        ...this.buildProcedureFileServerPathSectionState(
          result.procedureFolder
        ),
      };

      this.initSavedInSectionState = {
        ...this.initSavedInSectionState,
        ...this.buildProcedureFileSavedInSectionState(result.procedureFolder),
      };

      this.initRevisionInfoState = {
        ...this.initRevisionInfoState,
        ...this.buildRevisionInfoState(
          result.procedureFolder?.revisionFormInfo
        ),
      };

      if (historyList.length !== 0) {
        const lastStepHistoryData = historyList[0];
        this.primitiveServerPathSectionState = {
          ...this.primitiveServerPathSectionState,
          ...this.buildProcedureFileServerPathSectionState(lastStepHistoryData),
        };
        this.primitiveSavedInSectionState = {
          ...this.primitiveSavedInSectionState,
          ...this.buildProcedureFileSavedInSectionState(lastStepHistoryData),
        };
      }

      this.procedureFileStepID = result.procedureFolder?.procedureFileStepID;
      this.isLevelCChange = result.procedureFolder?.isChangeLevelC;
      this.categoryID = result.taskInfomation.categoryID;
      this.accountID = result.taskInfomation.accountID;
      this.taskGroupID = result.taskInfomation.taskGroupID;
      this.taskLabelID = result.taskInfomation.taskLabelID;
      this.clientServerJB = result.procedureFolder?.jbProcedureSaveIn;
      this.procedureStatusJB = result.procedureFolder?.procedureJBStatusID;
      this.setProcedureTableData(result);
      this.procedureTableData.forEach((item) => {
        this.initProcedureTableData.push({
          data: JSON.parse(JSON.stringify(item.data)),
          checked: item.checked,
          isApproveDisabled: item.isApproveDisabled,
          isReturnDisabled: item.isReturnDisabled,
          isReturned: item.isReturned,
          isError: item.isError,
          replaceRef: item.replaceRef,
          attachmentEditIcon: reasonEditDefaultIcon,
          isDelete: false,
          isUpdate: false,
        });
      });

      this.setRevisionInfo(result.procedureFolder?.revisionFormInfo);
      this.setState({
        fileTableData: this.procedureTableData,
      });
      this.setProcedureFileSavedInAndServerPathReturnReasonLog(
        result.procedureFolder?.stepHistoryList
      );
      this.setSavedInServerPathState(result);
    }
  };

  /**
   * Build SaveIn Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildProcedureFileSavedInSectionState = (data) => {
    return {
      savedInReturnReasonInputValue: data.procedureSaveInCategoryReturnReason,
      savedInRadioValue: data.procedureSaveInCategoryID,
      isSavedInReturned:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
      procedureSaveInCategoryStatusID: data.procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.approval,
      isSavedInReturnBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildProcedureFileServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
      isServerPathReturned: data.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue: data.clientServerPathReturnReason,
      clientServerPathStatusID: data.clientServerPathStatusID,
      clientServerPathStatusName: data.clientServerPathStatusName,
      clientServerPath: data.clientServerPath,
      isServerPathApproveBtnDisabled:
        data.clientServerPathStatusID === dataStatus.approval,
      isServerPathReturnBtnDisabled:
        data.clientServerPathStatusID === dataStatus.return,
    };
  };

  componentDidMount() {
    this.getInitData(
      ProcedureDetailService.getProcedureFolderStepItem,
      "Get Procedure Files Failed"
    );
  }

  /* function about get data from procedure file table */
  returnProcedureFileDataValue = (name, index, ref) => {
    const procedureFileData = ref.current.getDataList();
    let deleteList = ref.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  /* function about return submit data to use in integrate trigger api */
  handleReturnSubmitData = (
    actionID,
    actionName,
    procedureSavedInRef,
    procedureServerPathRef,
    pageProcedureRef,
    approvePageRef,
    revisionInfoRef,
    isLevelCChangeValue,
    levelCRef
  ) => {
    const fileDtos = [];
    const isChangeLevelC =
      isLevelCChangeValue ||
      (levelCRef ? levelCRef.current.state.isLevelCChange : false);

    if (pageProcedureRef.current !== null) {
      const procedureFileData = pageProcedureRef.current.getDataList();
      let deleteList = pageProcedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) => {
        const replaceUserId = this.returnProcedureFileDataValue(
          "replaceUserId",
          index,
          pageProcedureRef
        )?.value;
        const replaceUserName = this.returnProcedureFileDataValue(
          "replaceUserName",
          index,
          pageProcedureRef
        )?.value;
        fileDtos.push({
          fileID:
            this.returnProcedureFileDataValue(
              "newVersion",
              index,
              pageProcedureRef
            ).fileId === null
              ? this.returnProcedureFileDataValue(
                  "fileId",
                  index,
                  pageProcedureRef
                ).value
              : this.returnProcedureFileDataValue(
                  "newVersion",
                  index,
                  pageProcedureRef
                ).fileId,
          createdDate: this.returnProcedureFileDataValue(
            "uploadedDate",
            index,
            pageProcedureRef
          ).value,
          uploadBy:
            replaceUserId && replaceUserName
              ? replaceUserId
              : this.returnProcedureFileDataValue(
                  "uploadById",
                  index,
                  pageProcedureRef
                ).value,
          uploadByName:
            replaceUserId && replaceUserName
              ? replaceUserName
              : this.returnProcedureFileDataValue(
                  "uploadByName",
                  index,
                  pageProcedureRef
                ).value,
          fileName:
            this.returnProcedureFileDataValue(
              "newVersion",
              index,
              pageProcedureRef
            ).value === null
              ? this.returnProcedureFileDataValue(
                  "fileName",
                  index,
                  pageProcedureRef
                ).value
              : this.returnProcedureFileDataValue(
                  "newVersion",
                  index,
                  pageProcedureRef
                ).value,
          fileKey: this.returnProcedureFileDataValue(
            "fileKey",
            index,
            pageProcedureRef
          ).value,
          dataStatusID: this.returnProcedureFileDataValue(
            "status",
            index,
            pageProcedureRef
          ).value,
          attachmentFileID: this.returnProcedureFileDataValue(
            "attachment",
            index,
            pageProcedureRef
          ).fileId,
          attachmentName: this.returnProcedureFileDataValue(
            "attachment",
            index,
            pageProcedureRef
          ).value,
          returnReason: this.returnProcedureFileDataValue(
            "returnReason",
            index,
            pageProcedureRef
          ).value,
          fileTagID: this.returnProcedureFileDataValue(
            "fileName",
            index,
            pageProcedureRef
          ).tagValue,
          procedureFileStepID: this.procedureFileStepID,
          procedureFileID: this.returnProcedureFileDataValue(
            "procedureFileID",
            index,
            pageProcedureRef
          ).value,
          note: this.returnProcedureFileDataValue(
            "note",
            index,
            pageProcedureRef
          )?.value,
          proposedChangeTagID: this.returnProcedureFileDataValue(
            "proposedChange",
            index,
            pageProcedureRef
          )?.value,
          oldFileID:
            this.returnProcedureFileDataValue(
              "newVersion",
              index,
              pageProcedureRef
            ).fileId === null
              ? null
              : this.returnProcedureFileDataValue(
                  "fileId",
                  index,
                  pageProcedureRef
                ).value,
          oldFileName:
            this.returnProcedureFileDataValue(
              "newVersion",
              index,
              pageProcedureRef
            ).value === null
              ? null
              : this.returnProcedureFileDataValue(
                  "fileName",
                  index,
                  pageProcedureRef
                ).value,
          isReviewed:
            this.returnProcedureFileDataValue(
              "isReviewed",
              index,
              pageProcedureRef
            ).value === undefined
              ? null
              : this.returnProcedureFileDataValue(
                  "isReviewed",
                  index,
                  pageProcedureRef
                ).value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        });
      });
    }

    const {
      savedInRadioValue,
      procedureSaveInCategoryStatusID,
      savedInReturnReasonInputValue,
    } = procedureSavedInRef.current.state.savedInSectionState;

    let serverPathValue = null;
    let serverPathStatus = null;
    let serverPathReturnReasonInputValue = null;
    if (
      procedureServerPathRef.current !== null &&
      !(
        (actionID === actions.submit || actionID === actions.revise) &&
        savedInRadioValue !== savedInRadioValues.systemAndClientServer &&
        savedInRadioValue !== savedInRadioValues.serverOnly
      )
    ) {
      const serverPathState =
        procedureServerPathRef.current.state.serverPathSectionState;
      serverPathValue = serverPathState.serverPathValue;
      serverPathStatus = serverPathState.serverPathStatus;
      serverPathReturnReasonInputValue =
        serverPathState.serverPathReturnReasonInputValue;
    }

    let currentCategory = null;
    let currentRequestDate = null;
    let currentRequestFrom = null;
    let currentDesciption = null;
    let currentAttachment = [];
    let currentClientValue = null;
    let currentRspValue = null;
    let isRequestDateChange = false;

    if (revisionInfoRef !== undefined && revisionInfoRef.current !== null) {
      currentCategory = revisionInfoRef.current.state.currentCategory;
      currentRequestDate = revisionInfoRef.current.state.currentRequestDate;
      currentRequestFrom = revisionInfoRef.current.state.currentRequestFrom;
      currentDesciption = revisionInfoRef.current.state.currentDesciption;
      currentAttachment = revisionInfoRef.current.state.currentAttachment;
      currentClientValue = revisionInfoRef.current.state.currentClientValue;
      currentRspValue = revisionInfoRef.current.state.currentRspValue;
      isRequestDateChange = revisionInfoRef.current.state.isRequestDateChange;
    }

    return {
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      rejectReason:
        approvePageRef === undefined || approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      clientServerPath: serverPathValue,
      clientServerPathStatusID: serverPathStatus,
      clientServerPathReturnReason: serverPathReturnReasonInputValue,
      procedureSaveInCategoryID: savedInRadioValue,
      procedureSaveInCategoryStatusID: procedureSaveInCategoryStatusID,
      procedureSaveInCategoryReturnReason: savedInReturnReasonInputValue,
      isInitial: this.isRevisePage,
      procedureFiles: fileDtos,
      revisionForm: {
        revisionFormID: this.state.revisionInfoState.revisionFormID,
        revisionDescription: currentDesciption,
        revisionCategoryID: currentCategory,
        requestDate: getRequestDateMoment(
          currentRequestDate,
          isRequestDateChange
        ),
        requestFromID: currentRequestFrom,
        requestFromDesc:
          currentRequestFrom === revisionFromList.Client
            ? currentClientValue
            : currentRspValue,
        attachmentFiles: [...currentAttachment],
      },
      procedureFileStepID: this.procedureFileStepID,
      isChangeLevelC: isChangeLevelC,
    };
  };
}
