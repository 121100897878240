import React from "react";
import { Tooltip } from "antd";
import BaseComponent from "../../common/BaseComponent";
class EllipsisToolTip extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOverflow: false,
    };

    this.contentRef = React.createRef();
    this.handleContentOverflow = this.handleContentOverflow.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleContentOverflow();
      window.addEventListener("resize", this.handleContentOverflow);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleContentOverflow);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      setTimeout(() => {
        this.handleContentOverflow();
      });
    }
  }

  handleContentOverflow() {
    const contentNode = this.contentRef.current;
    const isOverflow =
      contentNode !== null &&
      contentNode.scrollHeight > contentNode.clientHeight;
    this.setState({ isOverflow });
  }

  render() {
    const { isOverflow } = this.state;
    const {
      children,
      title,
      placement = "topRight",
      style,
      getPopupContainer = () => document.body,
    } = this.props;

    return (
      <div ref={this.contentRef} className="ellipse">
        {isOverflow ? (
          <Tooltip
            title={title}
            color="#2F61B0"
            overlayInnerStyle={style}
            arrowPointAtCenter={true}
            placement={placement}
            getPopupContainer={getPopupContainer}
          >
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default EllipsisToolTip;
