import React, { createRef } from "react";
import { Radio } from "antd";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import Buttons from "../../../../../../base/basicComponents/button/index";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import reasonEditIcon from "../../../../../../assets/image/reasonEditIcon.png";
import returnReasonLogIcon from "../../../../../../assets/image/returnReasonLogIcon.png";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../../../publicDictionaryValues";
import styles from "../index.module.scss";

const savedInReturnRef = createRef();
export default class ApprovedSavedIn extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      savedInSectionState: {
        savedInReturnReasonInputValue: this.props.savedInReturnReasonInputValue,
        savedInReturnReasonValue: this.props.savedInReturnReasonInputValue,
        procedureSaveInCategoryStatusID:
          this.props.procedureSaveInCategoryStatusID,
        isSavedInReturnBtnDisabled: this.props.isSavedInReturnBtnDisabled,
        isSavedInApproveBtnDisabled: this.props.isSavedInApproveBtnDisabled,
        isSavedInShowEditBtn: false,
        savedInReasonEditIcon: reasonEditIcon,
        isSavedInReturnReasonDisabled: this.props.isSavedInReturnReasonDisabled,
        savedInReasonLogIcon: returnReasonLogIcon,
        isSavedInError: false,
        isSavedInReturned: this.props.isSavedInReturned,
        savedInRadioValue: this.props.savedInRadioValue,
        isSavedInSectionChange: this.props.isSavedInSectionChange,
      },
      modalState: {
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        modalLabel: "",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaValue: "",
        isShowTextArea: false,
        modalWidth: 520,
        handleOk: null,
        handleCancel: null,
      },
    };
    this.modalContentLabels = {
      returnModalLable: (
        <InputLabel
          text="Please fill in the reason for return:"
          required={true}
          className={styles.modalText}
        />
      ),
      approveAfterReturnModalLabel: (
        <span className={styles.modalText}>
          Are you sure to change the selection? Your Return Reason will be
          cleared.
        </span>
      ),
    };

    this.modalTitles = {
      returnModalTitle: <span className="fs-18">Reason for Return</span>,
    };

    this.returnModalInputPlacement = "Please enter your return reason.";
  }

  /* function about close modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalTitle: "",
        okText: "",
        cancelText: "Cancel",
        modalLabel: "",
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaValue: "",
        isShowTextArea: false,
        modalWidth: 520,
        handleOk: null,
        handleCancel: null,
      },
    });
  };

  /**
   * function about set return reason value for savedIn section
   * @param {*} e
   */
  handleSetSavedInReturnReasonValue = (e) => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInReturnReasonValue: e.target.value,
        savedInReasonStatus: null,
      },
    });
  };

  /* function about click return popUp ok button in saved in section */
  handleSavedInReturnOkClick = () => {
    const savedInReturnReason =
      this.state.savedInSectionState.savedInReturnReasonValue;
    let value =
      savedInReturnReason !== null
        ? savedInReturnReason.trim()
        : savedInReturnReason;
    if (value !== null && value !== "") {
      savedInReturnRef.current.setValue(value);
      this.cancelSavedInError();
      this.hideModal();
      this.setState({
        savedInSectionState: {
          ...this.state.savedInSectionState,
          savedInReturnReasonInputValue: value,
          isSavedInReturned: true,
          procedureSaveInCategoryStatusID: dataStatus.return,
          isSavedInReturnBtnDisabled: true,
          isSavedInApproveBtnDisabled: false,
          isSavedInShowEditBtn: true,
          isSavedInReturnReasonDisabled: false,
          isSavedInError: false,
        },
      });
      savedInReturnRef.current.setValue(value);
    } else {
      this.setState({
        savedInSectionState: {
          ...this.state.savedInSectionState,
          savedInReasonStatus: "error",
        },
      });
    }
  };

  /* function about click return popUp cancel button */
  handleReturnCancelClick = () => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInReturnReasonValue:
          this.state.savedInSectionState.savedInReturnReasonInputValue,
        savedInReasonStatus: null,
      },
    });
    this.hideModal();
  };

  /* function about click saved in section return to draft button */
  handleSavedInReturnClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: this.modalTitles.returnModalTitle,
        okText: "Confirm",
        modalLabel: this.modalContentLabels.returnModalLable,
        textAreaPlacement: this.returnModalInputPlacement,
        textAreaMaxLength: 1000,
        isShowTextArea: true,
        handleOk: this.handleSavedInReturnOkClick,
        handleCancel: this.handleReturnCancelClick,
      },
    });
  };

  /**
   * fucntion about set return reason value whtn change it
   * @param {*} e
   */
  handleSavedInReturnReasonChange = (e) => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInReturnReasonValue: e.target.value,
      },
    });
  };

  /* function about click saved in section approve button after i click return to draft before */
  handleApprovedAfterReturned = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: this.modalContentLabels.approveAfterReturnModalLabel,
        okText: "Confirm",
        modalTitle: null,
        closable: false,
        modalWidth: 520,
        handleOk: this.handleSavedInApproveOkClick,
        handleCancel: this.handleSectionApproveCancelClick,
      },
    });
  };

  /* function about click saved in section approve button */
  handleSavedInApprovedClick = () => {
    if (this.state.savedInSectionState.isSavedInReturned) {
      this.handleApprovedAfterReturned();
    } else {
      this.handleSavedInApproveOkClick();
      this.setState({
        modalState: {
          handleOk: this.handleSavedInApproveOkClick,
          handleCancel: this.handleSectionApproveCancelClick,
        },
      });
    }
  };

  /* function about click approve modal Confirm button in saved in section */
  handleSavedInApproveOkClick = () => {
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        savedInReturnReasonInputValue: null,
        savedInReturnReasonValue: null,
        isSavedInReturned: false,
        procedureSaveInCategoryStatusID: dataStatus.approval,
        isSavedInApproveBtnDisabled: true,
        isSavedInReturnBtnDisabled: false,
        isSavedInExpendNote: false,
        isSavedInShowEditBtn: false,
        isSavedInReturnReasonDisabled: true,
        isSavedInError: false,
      },
    });
    this.hideModal();
    savedInReturnRef.current.clearValue();
  };

  /* function about click approve popUp cancel button */
  handleSectionApproveCancelClick = () => {
    this.hideModal();
  };

  /**
   * if saved in required not follow will set error state
   * @returns
   */
  setSavedInError = () =>
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        isSavedInError: true,
      },
    });

  /**
   * remove the saved in error state
   * @returns
   */
  cancelSavedInError = () =>
    this.setState({
      savedInSectionState: {
        ...this.state.savedInSectionState,
        isSavedInError: false,
      },
    });

  /**
   * function about set saved in section buttons
   * @param {*} isSavedInApproveBtnDisabled
   * @param {*} isDisableAll
   * @param {*} handleSavedInApprovedClick
   * @param {*} isSavedInReturnBtnDisabled
   * @param {*} handleSavedInReturnClick
   * @returns
   */
  createSavedInBtns = (
    isSavedInApproveBtnDisabled,
    isDisableAll,
    handleSavedInApprovedClick,
    isSavedInReturnBtnDisabled,
    handleSavedInReturnClick
  ) => (
    <>
      <div>
        <Buttons
          color="blue"
          disabled={isSavedInApproveBtnDisabled || isDisableAll}
          size="small"
          onClick={handleSavedInApprovedClick}
          btnClassName={styles.approveBtn}
        >
          Approve
        </Buttons>
      </div>
      <div>
        <Buttons
          color="grey"
          disabled={isSavedInReturnBtnDisabled || isDisableAll}
          size="small"
          onClick={handleSavedInReturnClick}
          btnClassName={styles.returnBtn}
        >
          Return to Draft
        </Buttons>
      </div>
    </>
  );

  componentDidMount() {
    const {
      procedureSaveInCategoryStatusID, isSavedInSectionChange
    } = this.state.savedInSectionState;

    if(!procedureSaveInCategoryStatusID && isSavedInSectionChange === 'SavedInNoChange') {
      this.handleSavedInApproveOkClick();
    }
  }

  render() {
    const { procedureSavedInRef, isDisableAll, initSavedInStatus } = this.props;
    const {
      procedureSaveInCategoryStatusID,
      isSavedInReturnReasonDisabled,
      isSavedInShowEditBtn,
      isSavedInApproveBtnDisabled,
      isSavedInReturnBtnDisabled,
      isSavedInError,
      savedInReturnReasonValue,
      savedInReturnReasonInputValue,
      savedInReasonStatus,
      savedInRadioValue,
      isSavedInSectionChange,
    } = this.state.savedInSectionState;
    const {
      isModalWithComponentOpen,
      modalTitle,
      okText,
      cancelText,
      modalLabel,
      textAreaPlacement,
      textAreaMaxLength,
      isShowTextArea,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.procedureSavedIn}
          style={{ borderColor: isSavedInError ? "red" : "#b3b3b3" }}
          ref={procedureSavedInRef}
        >
          <div>
            <div className={styles.savedInTop}>
              <div className={styles.procedureSaveInLabel}>
                Procedure Saved In:
              </div>
              <div className={styles.procedureSaveInChoice}>
                <Radio.Group value={savedInRadioValue} disabled={true}>
                  <Radio value={savedInRadioValues.systemOnly}>
                    PMP System Only
                  </Radio>
                  <Radio value={savedInRadioValues.systemAndClientServer}>
                    PMP System & Client Server
                  </Radio>
                  <Radio value={savedInRadioValues.serverOnly}>
                    Client Server Only
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className={styles.savedInContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                {isSavedInSectionChange !== 'SavedInNoChange' && (
                  <SectionStatusDiv status={procedureSaveInCategoryStatusID} />
                )}
              </div>
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Return Reason</div>
              <ReturnReasonInput
                inputRef={savedInReturnRef}
                value={savedInReturnReasonInputValue}
                isDisabled={isSavedInReturnReasonDisabled || isDisableAll}
                isShowEditBtn={isSavedInShowEditBtn}
                handleReturnEditClick={() => this.handleSavedInReturnClick()}
                isShowReasonLogIcon={
                  this.props.savedInReturnReasonLog.length > 0
                }
                reasonLog={this.props.savedInReturnReasonLog}
              />
            </div>
            <div className={styles.actionBtns}>
              {!isDisableAll &&
                initSavedInStatus !== dataStatus.approval &&
                isSavedInSectionChange !== 'SavedInNoChange' &&
                this.createSavedInBtns(
                  isSavedInApproveBtnDisabled,
                  isDisableAll,
                  this.handleSavedInApprovedClick,
                  isSavedInReturnBtnDisabled,
                  this.handleSavedInReturnClick
                )}
            </div>
          </div>
        </div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          className={styles.approvePageModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isShowTextArea={isShowTextArea}
          textAreaValue={savedInReturnReasonValue}
          textAreaOnChange={this.handleSetSavedInReturnReasonValue}
          textAreaPlacement={textAreaPlacement}
          textAreaMaxLength={textAreaMaxLength}
          textAreaStatus={savedInReasonStatus}
          label={modalLabel}
          isMultiple={false}
          isShowDeleteBtn={true}
          modalWidth={modalWidth}
        />
      </div>
    );
  }
}
