import React from "react";
import BaseComponent from "../../common/BaseComponent";
import EllipsisToolTip from "../ellipsisToolTip";
import "./index.scss";

export default class ReadOnlyText extends BaseComponent {
  render() {
    const {
      value,
      marginLeft,
      width = "31.875rem",
      className = "",
    } = this.props;
    return (
      <div className={`${className} textContent`} style={{ width: width }}>
        <EllipsisToolTip
          children={value}
          title={value}
          style={{ width: width, marginLeft: marginLeft }}
        />
      </div>
    );
  }
}
