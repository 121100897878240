import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ProcedureFileStepService extends StepBaseService {
  getProcedureFileStepKey = () => {
    return {
      dataKey: StepBusinessType.procedureFile.dataKey,
      settingKey: "getProcedureFolderStepItem",
    };
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };
}

export default new ProcedureFileStepService();
