import React, { createRef } from "react";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import TlReviewProcedureReadOnlySection from "../../../sections/TlReviewSection/TlReviewProcedureReadOnlySection";
import BaseTlReviewPage from "../basetlReviewPage";

const procedureFileRef = createRef();

export default class TlReviewComplete extends BaseTlReviewPage {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      loading: false,
    };
  }
  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  handleReturnPageName = () => {
    return "TL Review Procedure";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ActiveShareTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleReturnProcedureFileSection = () => {
    const { fileTableData, jbProcedureSavedIn } = this.props;
    const { savedInRadioValue } = this.props.savedInProps;
    const { serverPathValue } = this.props.serverPathProps;

    return (
      <ReadOnlyProcedureFileSection
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
        jbProcedureSavedIn={jbProcedureSavedIn}
      />
    );
  };

  handleReturnTlReviewSection = () => {
    const { tlReviewData } = this.props;
    return (
      <TlReviewProcedureReadOnlySection
        reviewResult={tlReviewData.reviewResult}
        reviewedDate={this._formateDate(tlReviewData.reviewed)}
        fileIds={this.getFileIds(tlReviewData.attachmentFiles)}
        fileNames={this.getFileNames(tlReviewData.attachmentFiles)}
      />
    );
  };

  getFileIds = (attachmentFiles) => {
    if (attachmentFiles === undefined) {
      return [];
    }
    const fileIds = attachmentFiles.map((item) => {
      return item.fileID;
    });
    return fileIds;
  };

  getFileNames = (attachmentFiles) => {
    if (attachmentFiles === undefined) {
      return [];
    }
    const fileNames = attachmentFiles.map((item) => {
      return item.attachmentFileName;
    });
    return fileNames;
  };
}
