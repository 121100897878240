import Login from "../../app/pages/login";
import ToDoList from "../../app/pages/toDoList";
import ProcedureDetail from "../../app/pages/procedureDetail";
import Home from "../../app/pages/home";
import ProcedureList from "../../app/pages/procedureList";
import SignOffConfirmation from "../../app/pages/signOffConfirmation";
import CreateNewProcedure from "../../app/pages/procedureFileProcess/createNewProcedureProcess";
import UpdateProcedureProcess from "../../app/pages/procedureFileProcess/updateProcedureProcess";
import AssignPPOCompleted from "../../app/pages/primaryProcedureOwner/assignPPOCompleted";
import ManageShareTaskProcess from "../../app/pages/manageShareTaskProcess";
import ChangeMainTaskProcess from "../../app/pages/changeMainTaskProcess";
import PrimaryProcedureOwner from "../../app/pages/primaryProcedureOwner";
import ApplyPMPExcluded from "../../app/pages/pmpExcludedProcess/applyPMPExcluded";
import CancelPMPExcluded from "../../app/pages/pmpExcludedProcess/cancelPMPExcluded";
import ChangePOCompleted from "../../app/pages/procedureOwner/changePOCompleted";
import TlReviewProcess from "../../app/pages/tlReviewProcess";
import CreateSupportingFileProcess from "../../app/pages/supportingFileProcess/createSupportingFileProcess";
import UpdateSupportingFileProcess from "../../app/pages/supportingFileProcess/updateSupportingFileProcess";
import MyRequestList from "../../app/pages/myRequestList";
import ProcessDetail from "../../app/pages/myRequestList/processDetail";
import ApplyForJbProcess from "../../app/pages/applyJbProcess";
import ShareDocumentDownloadCheck from "../../app/pages/shareDocument";
import ReassignPPO from "../../app/pages/primaryProcedureOwner/reassignPPO";
import ChangeHistory from "../../app/pages/changeHistory";
import LoginRedirect from "../../app/pages/login/loginRedirect";
import ReportCenter from "../../app/pages/reportCenter";
import NoPermissionPage from "../../app/pages/noPermissionPage";
import ReportIFrame from "../../app/pages/reportCenter/reportIFrame";

/**
 * registor route key and class
 */
export default class RouteStorage {
  static _route = new Map();

  constructor() {
    if (RouteStorage._route.size === 0) {
      this._setRoute();
    }
  }

  getRouteComponent(key) {
    if (RouteStorage._route.has(key)) {
      return RouteStorage._route.get(key);
    }

    // throw Error("Invalid menu key");
  }

  _setRoute() {
    RouteStorage._route.set("LoginRedirect", LoginRedirect);
    RouteStorage._route.set("Login", Login);
    RouteStorage._route.set("Home", Home);
    RouteStorage._route.set("NoPermissionPage", NoPermissionPage);
    RouteStorage._route.set("ProcedureList", ProcedureList);
    RouteStorage._route.set("ToDoList", ToDoList);
    RouteStorage._route.set("ReportCenter", ReportCenter);
    RouteStorage._route.set("CreateProcdureFileProcess", CreateNewProcedure);
    RouteStorage._route.set(
      "UpdateProcdureFileProcess",
      UpdateProcedureProcess
    );
    RouteStorage._route.set("ManageShareTasks", ManageShareTaskProcess);
    RouteStorage._route.set("ChangeMainTask", ChangeMainTaskProcess);
    RouteStorage._route.set("ProcedureDetail", ProcedureDetail);
    RouteStorage._route.set("SignOffConfirmation", SignOffConfirmation);
    RouteStorage._route.set("AssignPrimaryProcedureOwner", AssignPPOCompleted);
    RouteStorage._route.set(
      "ApprovePrimaryProcedureOwner",
      PrimaryProcedureOwner
    );
    RouteStorage._route.set("ApplyPMPExcluded", ApplyPMPExcluded);
    RouteStorage._route.set("CancelPMPExcluded", CancelPMPExcluded);
    RouteStorage._route.set("ChangeProcedureOwner", ChangePOCompleted);
    RouteStorage._route.set("TLReviewProcedure", TlReviewProcess);
    RouteStorage._route.set(
      "CreateSupportingFileProcess",
      CreateSupportingFileProcess
    );
    RouteStorage._route.set(
      "UpdateSupportingFileProcess",
      UpdateSupportingFileProcess
    );
    RouteStorage._route.set("MyRequest", MyRequestList);
    RouteStorage._route.set("ProcessDetail", ProcessDetail);
    RouteStorage._route.set("ApplyforJBStatus", ApplyForJbProcess);
    RouteStorage._route.set("ShareDocument", ShareDocumentDownloadCheck);
    RouteStorage._route.set("ReassignPrimaryProcedureOwner", ReassignPPO);
    RouteStorage._route.set("ChangeHistory", ChangeHistory);
    RouteStorage._route.set("ReportDetail", ReportIFrame);
  }
}
