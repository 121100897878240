import React, { createRef } from "react";
import BaseComponent from "../../../common/BaseComponent";
import HistoricalVersionTable from "../historicalVersionTable";
import NormalModal from "../../modal";
import "./index.scss";

const reasonRef = createRef();
export default class HistoricalVersionModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      historicalVersionDate: null,
    };
  }

  /**
   * Set history version data in state
   * @param {*} data
   */
  handleSetVersionData = (data) => {
    const newData = data.sort((a, b) => {
      const prevTime = a.uploadedDate;
      const nextTime = b.uploadedDate;
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    this.setState({
      historicalVersionDate: newData,
    });
  };

  /**
   * Control modal open
   */
  handleChangeVersionModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    const { isModalOpen, historicalVersionDate } = this.state;
    return (
      <NormalModal
        isModalOpen={isModalOpen}
        closable={true}
        maskClosable={false}
        title="History Version"
        width={900}
        className="historicalVersionModal"
        childContent={
          isModalOpen && (
            <HistoricalVersionTable
              dataSource={historicalVersionDate}
              isModalOpen={isModalOpen}
              reasonRef={reasonRef}
            />
          )
        }
        handleCancel={() => this.handleChangeVersionModal()}
        footer={null}
      />
    );
  }
}
