import BaseComponent from "../../common/BaseComponent";
import "./button.scss";

class Buttons extends BaseComponent {
  render() {
    const {
      color,
      disabled,
      size,
      onClick,
      children,
      onMouseEnter,
      onMouseLeave,
      btnClassName = "",
      rowindex,
      onMouseDown,
    } = this.props;
    const className = `Button ${color} ${size} ${
      disabled ? "disabled" : ""
    } ${btnClassName}`;

    return (
      <button
        className={className}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        rowindex={rowindex}
      >
        {children}
      </button>
    );
  }
}

export default Buttons;
