import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import { ProcessDetailHeaderTopStatusDiv } from "../../../../base/basicComponents/status";
import styles from "./index.module.scss";

export default class ProcessDetailPageHeader extends BaseComponent {
  render() {
    const { title, status } = this.props;
    return (
      <div className={styles.approveHeader}>
        <span className={styles.approveHeaderTitle}>{title}</span>
        <ProcessDetailHeaderTopStatusDiv status={status} />
      </div>
    );
  }
}
