import React from "react";
import BaseComponent from "../../common/BaseComponent";
import procedureDetailHover from "../../../assets/image/detailHover.png";
import procedureDetailEntry from "../../../assets/image/detail.png";
import NormalToolTip from "../toolTip";
import styles from "./index.module.scss";
import { shareTaskMassage } from "../../../app/publicDictionaryValues";
import ProcedureListService from "../../../service/procedureList";

export default class ListEditBtnIcon extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      editBtnHintMsg: "",
      isEditBtnToolTipOpen: false,
      entrySrc: procedureDetailEntry,
    };
  }

  /**
   * Success callback for checkProcedureDetailEdit api
   * @param {object} response api's response
   * @returns
   */
  checkLockSuccess = (response, href) => {
    if (response.result) {
      this.setState({ isEditBtnToolTipOpen: false });
      window.open(href, "_blank");
    } else {
      this.setState({
        editBtnHintMsg: shareTaskMassage[response.messageCode],
        isEditBtnToolTipOpen: true,
      });
    }
  };

  /**
   * Error callback for checkProcedureDetailEdit api
   * @param {object} response api's response
   * @returns
   */
  checkLockError = (error) => {
    this._alertError("Check Procedure Detail Edit Failed!");
    console.log(error);
  };

  /**
   * Check whether can enter procedure detail page
   * @param {object} record current row data
   */
  handleCheckProcedureDetailEdit = (href, record) => {
    const param = {
      procedureId: record.procedureId,
      procedureTaskId: record.procedureTaskId,
      procedureAccountId: record.procedureAccountID,
    };
    ProcedureListService.checkProcedureDetailEdit(
      param,
      (response) => this.checkLockSuccess(response, href),
      this.checkLockError
    );
  };

  /**
   * Close toolTip
   */
  clearCreateState = () => {
    this.setState({
      isEditBtnToolTipOpen: false,
    });
  };

  render() {
    const { className = "", href, record } = this.props;
    return (
      <div className={`${className} ${styles.editBtn}`}>
        <NormalToolTip
          element={
            <div className={`${className} ${styles.editBtn}`}>
              <NormalToolTip
                element={
                  <a
                    tabIndex='0'
                    onClick={() =>
                      this.handleCheckProcedureDetailEdit(href, record)
                    }
                    onBlur={() => {
                      this.clearCreateState();
                    }}
                    onMouseEnter={() =>
                      this.setState({ entrySrc: procedureDetailHover })
                    }
                    onMouseLeave={() =>
                      this.setState({ entrySrc: procedureDetailEntry })
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img alt="" src={this.state.entrySrc} />
                  </a>
                }
                title="Enter Procedure Detail Page"
              />
            </div>
          }
          title={this.state.editBtnHintMsg}
          trigger="click"
          open={this.state.isEditBtnToolTipOpen}
          placement="top"
        />
      </div>
    );
  }
}
