export default function ToDoListConlumnSelectOption() {
  const toDoListConlumnSelectOption = [
    {
      label: "PMP Transaction ID",
      value: "transactionIdShow",
    },
    {
      label: "Delegate to Others",
      value: "delegateToOthersShow",
    },
    {
      label: "Task Label Name",
      value: "taskLabelNameShow",
    },
    {
      label: "Action Completed Date",
      value: "actionCompletedDateShow",
    },
    {
      label: "Process Category",
      value: "processCategoryShow",
    },
    {
      label: "Action Taken",
      value: "actionTakenShow",
    },
    {
      label: "Action Required",
      value: "actionRequiredShow",
    },
    {
      label: "Delegated by",
      value: "delegatedByShow",
    },
    {
      label: "Delegated to",
      value: "delegatedToShow",
    },
    {
      label: "Due Date",
      value: "dueDateShow",
    },
    {
      label: "Receive Date",
      value: "receiveDateShow",
    },
    {
      label: "Request By",
      value: "requestByShow",
    },
    {
      label: "Process Initiate Date",
      value: "processInitiateDateShow",
    },
    {
      label: "Process Initiator",
      value: "processInitiatorShow",
    },
    {
      label: "Account Code",
      value: "accountAbbrShow",
    },
    {
      label: "Account Name",
      value: "accountNameShow",
    },
    {
      label: "Task Group",
      value: "taskGroupShow",
    },
    {
      label: "Category",
      value: "categoryShow",
    },
    {
      label: "Task ID",
      value: "taskIdShow",
    },
    {
      label: "Highly Regulated?",
      value: "highlyRegulatedShow",
    },
  ];

  return toDoListConlumnSelectOption;
}

export function ToDoListColumnSelectValue() {
  const columnSelectValue = [
    "transactionIdShow",
    "delegateToOthersShow",
    "actionCompletedDateShow",
    "processCategoryShow",
    "actionRequiredShow",
    "delegatedByShow",
    "delegatedToShow",
    "actionTakenShow",
    "dueDateShow",
    "receiveDateShow",
    "requestByShow",
    "accountAbbrShow",
    "taskGroupShow",
    "categoryShow",
    "taskLabelNameShow",
  ];

  return columnSelectValue;
}
