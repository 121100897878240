import React from "react";
import BaseComponent from "../../common/BaseComponent";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import reasonEditHoverIcon from "../../../assets/image/reasonEditHoverIcon.png";

export default class EditIcon extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      editIcon: reasonEditDefaultIcon,
    };
  }
  render() {
    const { onClick, rowindex, altText, className } = this.props;
    return (
      <div className={className}>
        <div
          onMouseEnter={() => {
            this.setState({ editIcon: reasonEditHoverIcon });
          }}
          onMouseLeave={() => {
            this.setState({ editIcon: reasonEditDefaultIcon });
          }}
          onClick={onClick}
          rowindex={rowindex}
        >
          <img src={this.state.editIcon} alt={altText} rowindex={rowindex} />
        </div>
      </div>
    );
  }
}
