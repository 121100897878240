import BaseService from "../base";

class ReviseShareService extends BaseService {
  getReviseTaskList = (success, error) => {
    const data = {
      data: [
        {
          accountName: "gf45jh4gj4hgfk",
          taskTreePath: "fd09sag7frdayg8f7dysg8",
          taskLableName: "ghf-h98trw44h3btfaghr",
          taskId: 214543565,
          ppoPo: "fdghjhgfdj",
          procedureSavedIn: "fdsvagrda",
          clientServerPath: "t43q66gtey54",
          effectiveDate: "08/13/2015",
          isMainTask: true,
          procedureTaskId: "fdsg24trggf55",
        },
        {
          accountName: "fsh46wu6hjgf75e87648r",
          taskTreePath: "f4satgr4eq4ebqt5e4qtre",
          taskLableName: "yteiuytenyu45y5",
          taskId: 65967,
          ppoPo: "fdahu6u64u66546u76",
          procedureSavedIn: "ew5r2t4res53ty2",
          clientServerPath: "fw77y29tyryqt3yt9q6",
          effectiveDate: "08/12/2013",
          isMainTask: true,
          procedureTaskId: "fw5w5gfyur238",
        },
        {
          accountName: "2498543quvjht 43tuher",
          taskTreePath: "59736054rft",
          taskLableName: "f275y498thffrie6tyreu645",
          taskId: 343567,
          ppoPo: "egthu6576thnr",
          procedureSavedIn: "fgvr345rht64qu",
          clientServerPath: "gfghfhjrh",
          effectiveDate: "02/13/2022",
          isMainTask: false,
          procedureTaskId: "fdsahty4rwuutgsjytrjyt",
        },
        {
          accountName: "et54e4qb45e6w4t6r4wytr4yt5r4eu5ytey",
          taskTreePath: "dfs98d7suy4bgthfr78qg5487wgt43u1t4",
          taskLableName: "fsg4rr4ag6f4dag8r4eag4r8ea4gr",
          taskId: 7890787,
          ppoPo: "dgrtq3efr3qtre",
          procedureSavedIn: "retyreauy645y645eytfrsyu64",
          clientServerPath: "gsad5475jrpe8hyt50ythrew",
          effectiveDate: "10/15/2021",
          isMainTask: true,
          procedureTaskId: "fyw78ghy3789uigh5897t78h5y",
        },
      ],
      hasReturned: true,
    };

    success(data);
  };

  getAccountDDL = (para, success, error) => {
    this.sendRequest("getAccountDDL", para, success, error);
  };

  getRowData = (para, success, error) => {
    this.sendRequest("getTaskRowInfo", para, success, error);
  };

  delTaskRowInfo = (para, success, error) => {
    this.sendRequest("delTaskRowInfo", para, success, error);
  };
}

const reviseShareService = new ReviseShareService();

export default reviseShareService;
