import LoginPage from "base/page/LoginPage";
import ValidationStateModel from "utils/model/ValidationStateModel";
import { Layout, Form, Button, Input, Row, Col } from "antd";
import { testAction, testSagaAction } from "store/global";
import { connect } from "react-redux";
import styles from "./style/index.module.scss";
import "../../../base/page/Layout/topMenu/topMenu.scss";
import { useMsal } from "@azure/msal-react";
import { openAdfs, loginRequest, msalConfig } from "./authConfig";
import { useEffect } from "react";
import NormalSpin from "../../../base/basicComponents/spin";
import NavLog from "../../../assets/image/PMPlogo-white.png";

const { Header, Content, Footer } = Layout;

function MsalLoginComponent(props) {
  const { storageAdfsAccessToken, adfsLogin, login, reset, error } = props;
  const { instance } = useMsal();

  const activeAccountData = instance.getActiveAccount();
  const activeAccount = activeAccountData?.homeAccountId.toLocaleLowerCase();

  const getAccessToken = (activeAccount) => {
    const storageItem =
      activeAccount +
      "-" +
      activeAccountData.environment +
      "-accesstoken-" +
      msalConfig.auth.clientId +
      "--openid--";
    const accessToken = JSON.parse(sessionStorage.getItem(storageItem))?.secret;
    return accessToken;
  };

  /**
   * use 'setTimeout' to turn the current body of code into a macro task, make sure to call this code after the data is loaded.
   * use 'loginHint' to set default email address: loginRequest.loginHint = 'email address';
   */
  useEffect(() => {
    if (!openAdfs) {
      return;
    }

    const timer = setTimeout(() => {
      if (activeAccount) {
        const accessToken = getAccessToken(activeAccount);
        storageAdfsAccessToken(accessToken);
        const email = activeAccountData.username;
        adfsLogin(email);
      } else {
        instance
          .loginRedirect(loginRequest)
          .catch((error) => console.log("loginRequest", error));
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [activeAccount]);

  if (openAdfs) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <div className={styles.login_button}>
            <div className={styles.button_box}>
              <Button
                className={`${styles.button_style}`}
                type="text"
                htmlType="button"
                onClick={() => login()}
              >
                Log In
              </Button>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.login_button}>
            <div className={styles.button_box}>
              <Button
                className={styles.button_style}
                type="text"
                htmlType="button"
                onClick={() => reset()}
              >
                Reset
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {" "}
          <label className={styles.error_label}>{error["login"]}</label>
        </Col>
      </Row>
    </>
  );
}

class Login extends LoginPage {
  constructor(props) {
    super(props);
    this.onError = this._onError.bind(this);
    this.state = new ValidationStateModel(this.initialStatus);
    this.redirectUrl = window.location.href;
    sessionStorage.setItem("prevUrl", this.redirectUrl);
  }

  render() {
    return (
      <NormalSpin spinning={openAdfs || this.state.loading} size="large">
        {openAdfs ? (
          <MsalLoginComponent
            login={() => this._login()}
            reset={() => this._reset()}
            storageAdfsAccessToken={(e) => this.storageAdfsAccessToken(e)}
            adfsLogin={(e) => this._adfsLogin(e)}
            error={this.state.errors}
          />
        ) : (
          <Row>
            <Col span={24}>
              <Header className={`${styles.header} up-site-layout-background`}>
                <div className="logo">
                  <img className={styles.nav_logo} src={NavLog} />
                </div>
              </Header>
              <Content className={styles.content}>
                <Row>
                  <Col span={12} className={styles.content_left}></Col>
                  <Col span={12} className={styles.content_right}>
                    <Form
                      wrapperCol={{ span: 24 }}
                      size="large"
                      className={styles.form_box}
                    >
                      <Form.Item className={styles.form_item}>
                        <div className={styles.login_input}>
                          <div className={styles.input_box}>
                            <div className={styles.input_left_content}></div>
                            <div className={styles.input_right_content}>
                              <span className={styles.input_title}>
                                User Name
                              </span>
                              <Input
                                className={styles.input}
                                bordered={false}
                                type="text"
                                onChange={this._handleChange.bind(
                                  this,
                                  "account"
                                )}
                                value={this.state.fields["account"]}
                                onKeyDown={this.handleKeyDown}
                              />
                            </div>
                            <label className={styles.error_label}>
                              {this.state.errors["account"]}
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item className={styles.form_item}>
                        <div className={styles.login_input}>
                          <div className={styles.input_box}>
                            <div className={styles.input_left_content}></div>
                            <div className={styles.input_right_content}>
                              <span className={styles.input_title}>
                                Password
                              </span>
                              <Input
                                className={styles.input}
                                bordered={false}
                                type="password"
                                onChange={this._handleChange.bind(
                                  this,
                                  "password"
                                )}
                                value={this.state.fields["password"]}
                                onKeyDown={this.handleKeyDown}
                              />
                            </div>
                            <label className={styles.error_label}>
                              {this.state.errors["password"]}
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <MsalLoginComponent
                          login={() => this._login()}
                          reset={() => this._reset()}
                          error={this.state.errors}
                          storageAdfsAccessToken={(e) =>
                            this.storageAdfsAccessToken(e)
                          }
                          adfsLogin={(e) => this._adfsLogin(e)}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Content>
              <Footer className={styles.footer}>
                <span className={styles.footer_title}>
                  Copyright ©{new Date().getFullYear()} ReSource Pro. All rights
                  reserved.
                </span>
              </Footer>
            </Col>
          </Row>
        )}
      </NormalSpin>
    );
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      this._login();
    }
  };

  _onError = (error) => {
    this._setErrors("login", "Login Failed! " + error.message);
    this._hideLoading();
  };

  _handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  _handleValidation() {
    let fields = this.state.fields;
    let account = fields["account"];
    let password = fields["password"];
    let accountValidation = this._validateAccount(account);
    let passwordValidation = this._validatePassword(password);
    return accountValidation && passwordValidation;
  }

  _validatePassword(password) {
    if (password) {
      return true;
    }

    return this._setErrors("password", "Password can not be empty!");
  }

  _validateAccount(account) {
    if (!account) {
      return this._setErrors("account", "Account can not be empty!");
    }
    return true;
  }

  _setErrors(key, message) {
    let error = this.state.errors;
    error[key] = message;
    this.setState({ errors: error });
    return false;
  }
}

const mapStateToProps = (state) => {
  return {
    test: state.global.test,
  };
};

const mapDispatchToProps = {
  testAction,
  testSagaAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
