import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import { HeaderTopStatusDiv } from "../../../../base/basicComponents/status";
import styles from "./index.module.scss";

export default class PageHeader extends BaseComponent {
  render() {
    const { title, receiveDate, requestBy, status } = this.props;
    return (
      <div className={styles.approveHeader}>
        <span className={styles.approveHeaderTitle}>{title}</span>
        <HeaderTopStatusDiv status={status} />
        <div className={styles.approveHeaderMsgBlock}>
          {receiveDate && (
            <div className={styles.approveHeaderReceiveDate}>
              <span className={styles.receiveDateLabel}>Receive Date:</span>
              <span className={styles.receiveDateText}>
                {receiveDate === null ? "" : receiveDate}
              </span>
            </div>
          )}
          {requestBy && (
            <div className={styles.approveHeaderRequestBy}>
              <span className={styles.requestByLabel}>Request By:</span>
              <span className={styles.requestByText}>{requestBy}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
