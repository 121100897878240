import EllipsisToolTip from "../../../../../../base/basicComponents/ellipsisToolTip";
import BaseTbody from "../../../../../commonComponents/fileTbodys/baseFileTbody";

export default class ProcedureFileModalTbody extends BaseTbody {
  returnFileName = (rowColumn) => {
    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
          getPopupContainer={() =>
            document.getElementsByClassName("procedureModalFiles")[0]
          }
        />
      </div>
    );
  };
}
