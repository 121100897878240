import React, { createRef } from "react";
import NormalSpin from "../../../../base/basicComponents/spin";
import SubmitNewProcedureComplete from "./submitNewProcedureComplete";
import ProcedureFileProcess from "..";
import ApproveNewProcedurePending from "./approveNewProcedurePending";
import ApproveNewProcedureComplete from "./approveNewProcedureComplete";
import ReviseNewProcedureComplete from "./reviseNewProcedureComplete";
import ReviseNewProcedurePending from "./reviseNewProcedurePending";
import styles from "../style/index.module.scss";
import {
  handleSortFileByStatus,
  processDefaultTableSortFile,
  actions,
} from "../../../publicDictionaryValues";
import { flowCategoryId } from "../../../publicDictionaryValues";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();
export default class CreateNewProcedureProcess extends ProcedureFileProcess {
  /* sort procedure files follow status in revise page */
  handleSortFile = (result) => {
    const tiggerTimes = result.sequence;
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    if (tiggerTimes > 1) {
      return handleSortFileByStatus(
        result.procedureFolder?.procedureFiles,
        this._formateDate,
        isRevise
      );
    }
    return processDefaultTableSortFile(
      result.procedureFolder?.procedureFiles,
      this._formateDate
    );
  };

  handleCreateNewProcedurePageType = () => {
    const { procedureStepStatus } = this.state.pageState;

    this.isApprovePendingPage =
      this.isApprovePage && procedureStepStatus === "Pending";
    this.isRevisePendingPage =
      this.isRevisePage && procedureStepStatus === "Pending";
    this.isSubmitCompletePage =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes === 1;
    this.isReviseCompletePage =
      this.isRevisePage &&
      procedureStepStatus === "Complete" &&
      this.triggerTimes > 1;
    this.isApproveCompletePage =
      this.isApprovePage && procedureStepStatus === "Complete";
  };

  handleCreateNewProcedureTableData = () => {
    return JSON.parse(JSON.stringify(this.state.fileTableData));
  };

  handleCreateNewProcedureNewRejectReason = (rejectReason) => {
    const replaceRegex = /(\n\r|\r\n|\r|\n)/g;

    if (rejectReason !== undefined && rejectReason !== null) {
      return rejectReason.replace(replaceRegex, "<br/>");
    }

    return rejectReason;
  };

  handleCreateNewProcedurePageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      currentRole,
      flowCategoryID,
    } = this.state.pageState;

    return {
      flowCategoryID,
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
    };
  };

  handleCreateNewProcedureSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleCreateNewProcedureSavedInProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
    } = this.state.savedInSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
    };
  };

  handleCreateNewProcedureServerPathProps = () => {
    const {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      clientServerPathStatusID,
      serverPathValue,
    } = this.state.serverPathSectionState;

    return {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathReturnReasonLog: this.serverPathReturnReasonLog,
      clientServerPathStatusID,
      serverPathValue,
    };
  };

  handleCreateNewProcedureParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleCreateNewProcedureReviseNewProcedurePending = (procedureTableData) => {
    const { shareTaskData, supportingTableData } = this.state;

    return (
      <ReviseNewProcedurePending
        procedureServerPathRef={procedureServerPathRef}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        pageProps={{
          ...this.handleCreateNewProcedurePageProps(),
          isDisableAll: false,
        }}
        sectionProps={this.handleCreateNewProcedureSectionProps()}
        savedInProps={this.handleCreateNewProcedureSavedInProps()}
        serverPathProps={this.handleCreateNewProcedureServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        data={procedureTableData}
        shareTaskData={shareTaskData}
        supportingTableData={supportingTableData}
        params={this.handleCreateNewProcedureParams()}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        closePage={this.closePage}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  handleCreateNewProcedureReviseCompletePage = (procedureTableData) => {
    const { shareTaskData, supportingTableData } = this.state;
    const isCopyPage =
      this.flowCategoryID &&
      this.flowCategoryID === flowCategoryId.copyProcedure;

    return (
      <ReviseNewProcedureComplete
        pageProps={{
          ...this.handleCreateNewProcedurePageProps(),
          isDisableAll: true,
        }}
        sectionProps={this.handleCreateNewProcedureSectionProps()}
        savedInProps={this.handleCreateNewProcedureSavedInProps()}
        serverPathProps={this.handleCreateNewProcedureServerPathProps()}
        data={procedureTableData}
        params={this.handleCreateNewProcedureParams()}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        updateProcedureFileNum={this.updateProcedureFileNum}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        closePage={this.closePage}
        shareTaskData={shareTaskData}
        supportingTableData={supportingTableData}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        isCopyPage={isCopyPage}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  handleCreateNewProcedureApprovePendingPage = (
    procedureTableData,
    newRejectReason
  ) => {
    const { shareTaskData, supportingTableData } = this.state;

    return (
      <ApproveNewProcedurePending
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        sectionProps={this.handleCreateNewProcedureSectionProps()}
        savedInProps={this.handleCreateNewProcedureSavedInProps()}
        serverPathProps={this.handleCreateNewProcedureServerPathProps()}
        params={this.handleCreateNewProcedureParams()}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        pageProps={{
          ...this.handleCreateNewProcedurePageProps(),
          newRejectReason,
          actionID: this.actionID,
          isDisableAll: false,
        }}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        closePage={this.closePage}
        supportingTableData={supportingTableData}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initProcedureFileTableState={this.initProcedureFileTableState}
        data={procedureTableData}
        shareTaskData={shareTaskData}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  handleCreateNewProcedureApproveCompletePage = (
    procedureTableData,
    newRejectReason
  ) => {
    const { shareTaskData, supportingTableData } = this.state;
    const isCopyPage =
      this.flowCategoryID &&
      this.flowCategoryID === flowCategoryId.copyProcedure;
    const isRejectCompletePage = this.actionID === actions.reject;

    return (
      <ApproveNewProcedureComplete
        ref={approvePageRef}
        approvePageRef={approvePageRef}
        pageProps={{
          ...this.handleCreateNewProcedurePageProps(),
          newRejectReason,
          actionID: this.actionID,
          isDisableAll: true,
        }}
        data={procedureTableData}
        shareTaskData={shareTaskData}
        params={this.handleCreateNewProcedureParams()}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.returnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        closePage={this.closePage}
        supportingTableData={supportingTableData}
        sectionProps={this.handleCreateNewProcedureSectionProps()}
        savedInProps={this.handleCreateNewProcedureSavedInProps()}
        serverPathProps={this.handleCreateNewProcedureServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initProcedureFileTableState={this.initProcedureFileTableState}
        isCopyPage={isCopyPage}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
        isRejectCompletePage={isRejectCompletePage}
      />
    );
  };

  handleCreateNewProcedureSubmitCompletePage = (procedureTableData) => {
    const { shareTaskData, supportingTableData } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    const isCopyPage =
      this.flowCategoryID &&
      this.flowCategoryID === flowCategoryId.copyProcedure;

    return (
      <SubmitNewProcedureComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        params={this.handleCreateNewProcedureParams()}
        pageProps={this.handleCreateNewProcedurePageProps()}
        sectionProps={this.handleCreateNewProcedureSectionProps()}
        shareTaskData={shareTaskData}
        supportingTableData={supportingTableData}
        isCopyPage={isCopyPage}
        setShareTaskData={this.setShareTaskData}
        setProcedureInfoData={this.setProcedureInfoData}
        setSupportingFileData={this.setSupportingFileData}
      />
    );
  };

  render() {
    const { loading, procedureFileNum, uploadProcedureFileNum, rejectReason } =
      this.state.pageState;

    const procedureTableData = this.handleCreateNewProcedureTableData();

    this.handleCreateNewProcedurePageType();

    let newRejectReason =
      this.handleCreateNewProcedureNewRejectReason(rejectReason);

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          procedureFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadProcedureFileNum}/{procedureFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {this.isRevisePendingPage &&
              this.handleCreateNewProcedureReviseNewProcedurePending(
                procedureTableData
              )}
            {this.isReviseCompletePage &&
              this.handleCreateNewProcedureReviseCompletePage(
                procedureTableData
              )}
            {this.isApprovePendingPage &&
              this.handleCreateNewProcedureApprovePendingPage(
                procedureTableData,
                newRejectReason
              )}

            {this.isApproveCompletePage &&
              this.handleCreateNewProcedureApproveCompletePage(
                procedureTableData,
                newRejectReason
              )}

            {this.isSubmitCompletePage &&
              this.handleCreateNewProcedureSubmitCompletePage(
                procedureTableData
              )}
          </>
        }
      />
    );
  }
}
