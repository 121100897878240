import BaseService from "../base";
import TLReviewStepService from "./tLReviewStepService";
class TLReviewService extends BaseService {
  createTLReviewProcedure = (para, success, error) => {
    this.sendRequest("createTLReviewProcedure", para, success, error);
  };

  getTLReviewStepItem = (para, success, error) => {
    TLReviewStepService.getStepData(para, success, error);
  };

  tlReviewTriggerStepAction = (para, success, error) => {
    this.sendRequest("tlReviewTriggerStepAction", para, success, error);
  };

  checkTlReviewEdit = (para, success, error) => {
    this.sendRequest("checkTlReviewEdit", para, success, error);
  };
}

const tlReviewService = new TLReviewService();

export default tlReviewService;
