import { Input } from "antd";
import BaseComponent from "../../common/BaseComponent";
import NormalToolTip from "../toolTip";
import expendIcon from "../../../assets/image/expendIcon.png";
import expendHoverIcon from "../../../assets/image/expendHoverIcon.png";
import foldIcon from "../../../assets/image/foldIcon.png";
import foldHoverIcon from "../../../assets/image/foldHoverIcon.png";
import "./index.scss";
import { createRef } from "react";

export default class NormalInput extends BaseComponent {
  render() {
    const {
      value,
      onChange,
      name = "",
      size = "middle",
      disabled = false,
      type = "text",
      placeholder = "",
      maxLength = "",
      dataname,
      rowindex,
      status,
      isShowToolTip = false,
      tooltipClassName,
      tooltipPlacement = "bottom",
      className,
      inputRef,
    } = this.props;
    return isShowToolTip ? (
      <NormalToolTip
        title={value}
        overlayClassName={tooltipClassName}
        placement={tooltipPlacement}
        color="#40a9ff"
        element={
          <Input
            className={`customInputCompnent ${className}`}
            disabled={disabled}
            size={size}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            dataname={dataname}
            rowindex={rowindex}
            status={status}
            ref={inputRef}
          />
        }
      />
    ) : (
      <Input
        className={`customInputCompnent ${className}`}
        disabled={disabled}
        size={size}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        dataname={dataname}
        rowindex={rowindex}
        status={status}
        ref={inputRef}
      />
    );
  }
}
export class NormalTextArea extends BaseComponent {
  render() {
    const { TextArea } = Input;
    const {
      onChange,
      onFocus,
      placeholder = "",
      autoSize = { minRows: 3 },
      maxLength = 1000,
      width = "",
      status,
      disabled = false,
      className = "",
      value,
      showCount = true,
    } = this.props;

    return (
      <TextArea
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        autoSize={autoSize}
        maxLength={maxLength}
        style={{
          width: width + "rem",
        }}
        className={`normalTextArea fs-14 ${className} ${
          status === "error" ? "error" : "normal"
        }`}
        disabled={disabled}
        showCount={showCount}
      />
    );
  }
}

const InputRef = createRef();
export class ExpendTextArea extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      expendIconSrc: expendIcon,
      foldIconSrc: foldIcon,
      isShowExpendIcon: false,
      isExpendNote: false,
      value: "",
      isShowEllipsisIcon: false,
      autoSize: { minRows: 1, maxRows: 1 },
    };
    this.inputEle = null;
    this.currentBrowser = "";
  }

  componentDidMount() {
    const browserStr = navigator.userAgent.toLowerCase();
    if (browserStr.indexOf("firefox") > -1) {
      this.currentBrowser = "firefox";
    }
    this.inputEle = InputRef.current.resizableTextArea.textArea;
    this.setState({ value: this.props.value });
    this.handleChangeEllipsis();
    this.handleBlur();
  }

  /**
   * function about control ellipsis icon and expend icon show
   */
  handleChangeEllipsis = () => {
    this.inputEle = InputRef.current.resizableTextArea.textArea;
    let lineHeight = window.getComputedStyle(this.inputEle)["lineHeight"];
    lineHeight = Number(lineHeight.replace("px", ""));
    setTimeout(() => {
      if (
        this.inputEle.scrollHeight > this.inputEle.clientHeight &&
        !this.state.isExpendNote
      ) {
        this.setState({
          isShowExpendIcon: true,
          isShowEllipsisIcon: false,
          autoSize: { minRows: 1, maxRows: 1 },
        });
        this.inputEle.classList.add("ellipsisTextArea");
      } else {
        if (this.state.isExpendNote) {
          if (this.inputEle.clientHeight < lineHeight * 2) {
            this.setState({
              isShowExpendIcon: false,
              isShowEllipsisIcon: false,
              autoSize: { minRows: 1, maxRows: 1 },
              isExpendNote: false,
            });
          } else {
            this.setState({
              isShowExpendIcon: false,
              isShowEllipsisIcon: true,
              autoSize: "auto",
            });
          }
        } else {
          this.setState({
            isShowExpendIcon: false,
            isShowEllipsisIcon: false,
            autoSize: { minRows: 1, maxRows: 1 },
          });
        }
        if (
          this.currentBrowser === "firefox" &&
          this.inputEle.innerHTML.trim() !== ""
        ) {
          this.handleFocus(this.inputEle);
          this.handleBlur();
        }
        this.inputEle.classList.remove("ellipsisTextArea");
      }
    });
    this.props.handleTableChange !== undefined &&
      this.props.handleTableChange(this.props.isExpendData);
  };

  /**
   * function about display default icon for textarea
   */
  handleDefaultExpendIcon = () => {
    if (this.state.isExpendNote) {
      this.setState({ foldIconSrc: foldIcon });
    } else {
      this.state.isShowExpendIcon &&
        this.setState({ expendIconSrc: expendIcon });
    }
  };

  /**
   * function about display hover icon for textarea
   */
  handleHoverExpendIcon = () => {
    if (this.state.isExpendNote) {
      this.setState({ foldIconSrc: foldHoverIcon });
    } else {
      this.state.isShowExpendIcon &&
        this.setState({ expendIconSrc: expendHoverIcon });
    }
  };

  /**
   * function about display default icon for textarea when expend icon click
   */
  handleExpendIconClick = () => {
    this.setState({ isExpendNote: !this.state.isExpendNote });
    this.inputEle = InputRef.current.resizableTextArea.textArea;
    if (!this.state.isExpendNote) {
      this.setState({
        autoSize: true,
        isShowExpendIcon: false,
        isShowEllipsisIcon: true,
      });
    } else {
      this.setState({
        autoSize: { minRows: 1, maxRows: 1 },
        isShowExpendIcon: true,
        isShowEllipsisIcon: false,
      });
    }

    setTimeout(() => {
      this.handleBlur();
      this.props.setIsExpendNote !== undefined &&
        this.props.setIsExpendNote(this.state.isExpendNote);
      this.props.handleTableChange !== undefined &&
        this.props.handleTableChange(this.props.isExpendData);
    });
    this.handleDefaultExpendIcon();
  };

  setValue = (value) => {
    this.setState({ value: value });
  };

  clearValue = () => {
    this.setState({ value: "" });
  };

  /**
   * function about change textarea value
   * @param {*} e
   */
  handleChange = (e) => {
    this.props.onChange(e);
    this.setValue(e.target.value);
  };

  /**
   * function about focus textarea
   * @param {*} e
   */
  handleFocus = (ele) => {
    ele.classList.remove("ellipsisTextArea");
  };

  /**
   * line break for firefox browser
   * @param {*} container current textarea element
   * @param {*} maxWidth max width of textarea element
   * @returns
   */
  addLineBreaks(container, maxWidth) {
    const textNode = container;
    const words = textNode.innerHTML.split(" ");
    let line = "";
    const lines = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${this.inputEle.style.fontSize}px`;
    for (const word of words) {
      if (context.measureText(line + word).width > maxWidth) {
        lines.push(line);
        line = "";
      }
      line += (line ? " " : "") + word;
    }

    if (line) {
      lines.push(line);
    }
    return lines;
  }

  /**
   * function about onblur textarea
   * @param {*} e
   */
  handleBlur = () => {
    this.inputEle = InputRef.current.resizableTextArea.textArea;
    this.inputEle.scrollTop = 0;
    const lines =
      this.currentBrowser === "firefox"
        ? this.addLineBreaks(this.inputEle, this.inputEle.clientWidth)
        : this.inputEle.value.split(/\r*\n/);
    const linesCount =
      lines.length - (navigator.userAgent.indexOf("MSIE") !== -1);
    if (
      this.state.isExpendNote ||
      (linesCount === 1 &&
        this.inputEle.scrollHeight === this.inputEle.clientHeight)
    ) {
      this.inputEle.classList.remove("ellipsisTextArea");
    } else {
      this.inputEle.classList.add("ellipsisTextArea");
      if (this.currentBrowser === "firefox" && linesCount > 1) {
        this.setState({
          isShowExpendIcon: true,
          isShowEllipsisIcon: false,
          autoSize: { minRows: 1, maxRows: 1 },
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.value !== this.state.value ||
      prevProps.value !== this.props.value ||
      prevProps.isClientPathShow !== this.props.isClientPathShow ||
      prevProps.isFileTableShow !== this.props.isFileTableShow
    ) {
      this.handleChangeEllipsis();
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    this.inputEle = InputRef.current.resizableTextArea.textArea;
    const lines = this.inputEle.value.split(/\r*\n/);
    const linesCount =
      lines.length - (navigator.userAgent.indexOf("MSIE") !== -1);
    if (this.state.isExpendNote !== prevState.isExpendNote) {
      if (
        this.state.isExpendNote ||
        (linesCount === 1 &&
          this.inputEle.scrollHeight === this.inputEle.clientHeight)
      ) {
        this.inputEle.classList.remove("ellipsisTextArea");
        return;
      }
      this.inputEle.classList.add("ellipsisTextArea");
    }
  }

  render() {
    const { TextArea } = Input;
    const {
      placeholder = "",
      maxLength = 1000,
      dataname,
      rowindex,
      disabled,
      readonly,
      status,
      className = "",
      iconClassName = "",
    } = this.props;

    const {
      isShowExpendIcon,
      isExpendNote,
      isShowEllipsisIcon,
      autoSize,
      value,
    } = this.state;

    return (
      <div
        className={`expendTextArea ${isExpendNote ? "showCount" : "hideCount"}`}
      >
        {
          <>
            <TextArea
              value={value}
              onChange={(e) => this.handleChange(e)}
              placeholder={placeholder}
              autoSize={autoSize}
              maxLength={maxLength}
              dataname={dataname}
              rowindex={rowindex}
              disabled={disabled}
              showCount={true}
              style={{
                minHeight: "2.75rem",
                lineHeight: "2rem",
                borderColor: status === "error" ? "red" : "#d9dce1",
              }}
              className={`expendTextArea fs-14 ${className} ${
                status === "error" ? "error" : "normal"
              }`}
              readOnly={readonly}
              ref={InputRef}
              onFocus={(e) => this.handleFocus(e.target)}
              onBlur={() => this.handleBlur()}
            />
            {isExpendNote && isShowEllipsisIcon && (
              <img
                src={this.state.foldIconSrc}
                alt="expend"
                className={`shrinkTextAreaIcon ${iconClassName}`}
                onClick={() => this.handleExpendIconClick()}
                onMouseEnter={() => this.handleHoverExpendIcon()}
                onMouseLeave={() => this.handleDefaultExpendIcon()}
              />
            )}
            {isShowExpendIcon && (
              <img
                src={this.state.expendIconSrc}
                alt="fold"
                className={`expendTextAreaIcon ${iconClassName}`}
                onClick={() => this.handleExpendIconClick()}
                onMouseEnter={() => this.handleHoverExpendIcon()}
                onMouseLeave={() => this.handleDefaultExpendIcon()}
              />
            )}
          </>
        }
      </div>
    );
  }
}

export class ExpendTextAreaByProps extends ExpendTextArea {
  getValue = () => {
    return this.props.value;
  };
}

export class RetrunReasonLogInput extends ExpendTextArea {
  /**
   * function about focus textarea
   */
  handleFocus = () => {
    return null;
  };
}
