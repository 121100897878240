import ApproveUpdateSupportingFileManagement from "../../../../supportingFileSections/fields/supportingFileManagement/approveUpdateSupportingFileManagement";
import ApproveUpdateProcedureFileTable from "../../procedureFileTableFields/approveUpdateProcedureFileTable";

export default class ApproveUpdateProcedureFileManagement extends ApproveUpdateSupportingFileManagement {
  handleReturnFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      className,
      optionList,
      currentRole,
      downloadFile,
      isDisableAll,
      initProcedureTableData,
      fileTableStatus,
      isTableBorderError,
    } = obj;
    return (
      <ApproveUpdateProcedureFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        fileTableRef={fileTableRef}
        className={className}
        optionList={optionList}
        handleReturnClick={(e) =>
          this.handleProcedureFileTableReturnClick(
            e.target.attributes.rowindex.value
          )
        }
        currentRole={currentRole}
        downloadFile={downloadFile}
        isComplete={isDisableAll}
        initProcedureTableData={initProcedureTableData}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        fileTableStatus={fileTableStatus}
        isTableBorderError={isTableBorderError}
        isShowReturnReason={true}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };
}
