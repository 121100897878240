import React from "react";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import NormalModal from "../../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import NormalInput, {
  NormalTextArea,
} from "../../../../../../base/basicComponents/input";

export default class DelegatedReasonModal extends BaseComponent {
  render() {
    const { reason, isModalOpen, handleCloseModal, delegateTo } = this.props;
    return (
      <NormalModal
        closable={true}
        childContent={
          <div className="delegateContainer">
            <div className="delegatePart">
              <InputLabel
                text="Delegate To"
                className="delegateLabel"
                required={true}
              />
              <NormalInput
                showSearch={true}
                value={delegateTo}
                disabled={true}
              />
            </div>
            <div className="delegateReasonPart">
              <div className="delegateReasonInput">
                <InputLabel text="Reason" className="delegateLabel" />
                <NormalTextArea
                  width="22.2"
                  value={reason}
                  className="delegateReasonText"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        }
        isModalOpen={isModalOpen}
        handleCancel={handleCloseModal}
        footer={null}
        maskClosable={false}
      />
    );
  }
}
