import React from "react";
import BaseComponent from "../../common/BaseComponent";
import procedureUpdateHover from "../../../assets/image/editHover.svg";
import procedureUpdateEntry from "../../../assets/image/edit.svg";
import { shareTaskMassage } from "../../../app/publicDictionaryValues";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import NormalToolTip from "../toolTip";
import styles from "./index.module.scss";

export default class ListUpdateBtnIcon extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      createBtnHintMsg: "",
      isCreateBtnToolTipOpen: false,
      entrySrc: procedureUpdateEntry,
    };
  }

  /**
   * success callback for checkEditSection api
   * @param {object} response
   */
  checkProcedureFileEditOnSuccess = (response) => {
    if (response.result) {
      window.open(this.props.href, "_blank");
    } else {
      this.setState({
        createBtnHintMsg: shareTaskMassage[response.messageCode],
        isCreateBtnToolTipOpen: true,
      });
    }
  };

  /**
   * error callback for checkEditSection api
   * @param {*} error
   */
  checkProcedureFileEditOnError = (error) => {
    console.log(error);
    this._alertError("Check Procedure Files Error!");
  };

  /**
   * check current data whether has PPO
   * @param {object} record
   */
  checkPPO = (record) => {
    if (
      record.firstPrimaryProcedureOwner === null ||
      record.firstPrimaryProcedureOwner.trim() === ""
    ) {
      this.setState({
        createBtnHintMsg: shareTaskMassage[2],
        isCreateBtnToolTipOpen: true,
      });
    } else {
      this.setState({ isCreateBtnToolTipOpen: false });
      const param = {
        procedureAccountId: record.procedureAccountID,
        procedureId: record.procedureId,
        procedureTaskId: record.procedureTaskId,
      };
      ProcedureDetailService.checkEditSection(
        param,
        this.checkProcedureFileEditOnSuccess,
        this.checkProcedureFileEditOnError
      );
    }
  };

  clearCreateState = () => {
    this.setState({
      isCreateBtnToolTipOpen: false,
    });
  };

  render() {
    const { className = "", record } = this.props;
    return (
      <NormalToolTip
        element={
          <div className={`${className} ${styles.createBtn}`}>
            <NormalToolTip
              element={
                <a
                  onClick={() => this.checkPPO(record)}
                  onBlur={() => {
                    this.clearCreateState();
                  }}
                  onMouseEnter={() =>
                    this.setState({ entrySrc: procedureUpdateHover })
                  }
                  onMouseLeave={() => {
                    this.setState({ entrySrc: procedureUpdateEntry })
                    this.clearCreateState();
                  }
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="" src={this.state.entrySrc} />
                </a>
              }
              title="Update Procedure"
            />
          </div>
        }
        title={this.state.createBtnHintMsg}
        trigger="click"
        open={this.state.isCreateBtnToolTipOpen}
        placement="top"
      />
    );
  }
}
