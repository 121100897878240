import React, { createRef } from "react";
import { message } from "antd";
import BaseShare from "../../manageShareTaskProcess/baseShare";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import Buttons from "../../../../base/basicComponents/button";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import { ModalWithComponent } from "../../../../base/basicComponents/modal";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import { actions } from "../../../publicDictionaryValues";
import changeMainTaskService from "../../../../service/changeMainTask/changeMainTaskService";
import ApproveChangeMainTask from "../../../sections/sharedTaskSections/approveChangeMainTask";
import NormalModel from "../../../../base/basicComponents//modal/index";
import styles from "../../procedureFileProcess/style/index.module.scss";
import appService from "../../../../service/app";
import LostPermissionModal from "../../../sections/lostPermissionModal";
import "../../manageShareTaskProcess/index.scss";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const procedureFileRef = createRef();
const approveShareTaskRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ApproveChangeMainTaskProcess extends BaseShare {
  constructor(props) {
    super(props);
    this.state = {
      pageState: this.handleApproveChangeMainTaskPageState(),
      modalState: this.handleApproveChangeMainTaskModalState(),
      returnOrRejectModalTitle: "",
      isReturnOrRejectModalOpen: false,
      confirmModalText: "",
      handleApproveComfirm: () => {
        //this is a empty function
      },
      returnOrRejectInputStatus: "",
      returnOrRejectInputValue: "",
      isApproveComfirmOpen: false,
      noPermission: false,
    };
    this.para = null;
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
    this.modalContentLabels =
      this.handleApproveChangeMainTaskModalContentLabels();
  }

  handleApproveChangeMainTaskPageState = () => {
    const { rejectReason, rejectStatus } = this.props.pageProps;

    return {
      rejectReason,
      rejectStatus,
      isCancelDisabled: false,
      isSubmitDisabled: this.checkSubmitDisable(this.props.shareTaskData),
      isRejectDisabled: false,
      isTableBorderError: false,
    };
  };

  handleApproveChangeMainTaskModalState = () => {
    return {
      modalTitle: "",
      modalLabel: "",
      okText: "",
      cancelText: "",
      actionType: "",
      closable: false,
      uploadDraggerFileType: "",
      rowIndex: 0,
      fileIds: [],
      fileNames: [],
      isOkBtnDisabled: false,
      isCancelBtnDisabled: false,
      maskClosable: false,
      isModalWithComponentOpen: false,
      isShowTextArea: false,
      textAreaPlacement: "",
      textAreaMaxLength: 0,
      textAreaClassName: "",
      isShowSelect: false,
      isShowTextAreaAndUploadDragger: false,
      modalWidth: 520,
    };
  };

  handleApproveChangeMainTaskModalContentLabels = () => {
    return {
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
      rejectModalWarningLabel: (
        <>
          <div className={styles.rejectModalWarning}>
            Are you sure to reject this request? Reject means that the process
            will be terminated. The status will be changed back to the original
            status before this initiation.
          </div>
          <InputLabel text="Reject Reason" required={true} />
        </>
      ),
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
    };
  };

  handleApproveConfirm = () => {
    this.props._showLoading();
    this.setState({
      isApproveComfirmOpen: false,
    });
    const para = this.handleSubmitPara(
      actions.approve,
      "Approve",
      approveShareTaskRef
    );
    changeMainTaskService.manageShareTaskTriggerAction(
      para,
      this.approveSuccess,
      this.approveFailed
    );
  };

  checkApproveChangeMainTaskUploadSignOff = () => {
    const para = this.getCheckUploadSignOffPara(approveShareTaskRef);
    changeMainTaskService.checkHasPendingSignOff(
      para,
      this.checkHasPendingSignOffSuccess,
      this.checkHasPendingSignOffFail
    );
  };

  checkApproveChangeMainTaskApproveSuccess = (result) => {
    if (!result.result) {
      const responseData = result.resultList;
      let inactiveIds = "";

      for (let item of responseData) {
        if (!item.checkResult && item.msgCode === 29) {
          inactiveIds += `${item.taskId}, `;
        }
      }

      if (inactiveIds !== "") {
        const regex = /,\s*$/;
        inactiveIds = inactiveIds.replace(regex, "");
        let msg = `${inactiveIds} task(s) is inactive, please return or reject the request`;
        message.error({
          content: msg,
          className: "inactive-Task-Message",
          duration: 6,
        });
      }
    } else {
      this.checkApproveChangeMainTaskUploadSignOff();
    }
  };

  checkApproveChangeMainTaskApproveError = () => {
    this._alertError("Check Approve Fail");
  };

  checkApproveChangeMainTaskApprove = () => {
    const currentData = JSON.parse(
      JSON.stringify(approveShareTaskRef.current.state.pendingApprovalData)
    );
    const para = {
      procedureId: this.props.params.ProcedureID,
      taskList: currentData,
      procedureStepId: this.props.params.ProcedureStepID,
    };
    changeMainTaskService.checkApprove(
      para,
      this.checkApproveChangeMainTaskApproveSuccess,
      this.checkApproveChangeMainTaskApproveError
    );
  };

  handleApproveChangeMainSubmitClick = () => {
    this.checkApproveChangeMainTaskApprove(
      approveShareTaskRef,
      changeMainTaskService.checkApprove
    );
  };

  /* function about click reject button */
  handleApproveChangeMainTaskRejectClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: <span className="fs-18">Reject</span>,
        isShowTextArea: true,
        modalLabel: this.modalContentLabels.rejectModalWarningLabel,
        textAreaMaxLength: 1000,
        textAreaClassName: styles.rejectReasonInput,
        okText: "Confirm",
        cancelText: "Cancel",
        actionType: "reject",
        modalWidth: 520,
        handleCancel: this.handleApproveShareRejectCancelClick,
        handleOk: () =>
          this.handleApproveShareRejectOkClick(
            changeMainTaskService.manageShareTaskTriggerAction,
            approveShareTaskRef
          ),
      },
    });
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "approve") {
      this.handleApproveChangeMainSubmitClick();
    } else if (actionType === "reject") {
      this.handleApproveChangeMainTaskRejectClick();
    } else if (actionType === "return") {
      this.handleApproveChangeMainTaskReturnClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => this._alertError(response.message)
    );
  };

  handleApproveChangeMainTaskSetRejectValue = (e) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: e.target.value,
        rejectStatus: null,
      },
    });
  };

  handleApproveChangeMainTaskReturnClick = () => {
    this.setState({
      returnOrRejectModalTitle: "Reason for Return",
      isReturnOrRejectModalOpen: true,
    });
  };

  handleApproveChangeMainTaskApproveComfirmCancel = () => {
    this.setState({
      isApproveComfirmOpen: false,
    });
  };

  handleApproveChangeMainTaskApprovalCancel = () => {
    this.setState({
      isApproveComfirmOpen: true,
      confirmModalText: "Are you sure to quit without saving?",
      handleApproveComfirm: this.props.closePage,
    });
  };

  approveChangeMainTaskReturnOrRejectInputOnChange = (e) => {
    this.setState({
      returnOrRejectInputValue: e.value,
      returnOrRejectInputStatus: "",
    });
  };

  handleApproveChangeMainTaskReturnOrRejectCancel = () => {
    this.setState({
      returnOrRejectModalTitle: "",
      isReturnOrRejectModalOpen: false,
      returnOrRejectInputStatus: "",
      returnOrRejectInputValue: "",
    });
  };

  approveChangeMainTaskreturnSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Return Successful");
    this.props.closePage();
  };

  approveChangeMainTaskreturnFailed = () => {
    this.props._hideLoading();
    this._alertError("Return Failed");
  };

  handleApproveChangeMainTaskReturnOrRejectOk = () => {
    if (this.state.returnOrRejectInputValue.trim() === "") {
      this.setState({
        returnOrRejectInputStatus: "error",
      });
    } else {
      this.props._showLoading();
      this.setState({
        isReturnOrRejectModalOpen: false,
      });
      const para = this.handleSubmitPara(
        actions.return,
        "Return",
        approveShareTaskRef
      );
      changeMainTaskService.manageShareTaskTriggerAction(
        para,
        this.approveChangeMainTaskreturnSuccess,
        this.approveChangeMainTaskreturnFailed
      );
    }
  };

  checkSubmitDisable = (data) => {
    if (data === undefined || data === null) {
      return true;
    }
    const shareData = data.shareTasks;

    const oldMainTask = shareData.find((x) => x.isOldMainTask === true);
    const newMainTask = shareData.find((x) => x.isNewMainTask === true);
    if (oldMainTask !== undefined && newMainTask !== undefined) {
      return false;
    } else {
      return true;
    }
  };

  approveChangeMainTaskComponentDidUpdate = (prevProps) => {
    if (prevProps.shareTaskData !== this.props.shareTaskData) {
      if (this.checkSubmitDisable(this.props.shareTaskData)) {
        this.setState({
          pageState: {
            ...this.state.pageState,
            isSubmitDisabled: true,
          },
        });
      } else {
        this.setState({
          pageState: {
            ...this.state.pageState,
            isSubmitDisabled: false,
          },
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    this.approveChangeMainTaskComponentDidUpdate(prevProps);
  }

  handleApproveChangeMainTaskPageHeader = () => {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.props.pageProps;

    return (
      <PageHeader
        title="Approve Main Task"
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status={procedureStepStatus}
      />
    );
  };

  handleApproveChangeMainTaskTaskInformation = () => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleApproveChangeMainTaskProcedureFileSection = () => {
    const {
      serverPathValue,
      jbProcedureSavedIn,
      savedInRadioValue,
      fileTableData,
    } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlyProcedureFileSection
            savedInRadioValue={savedInRadioValue}
            procedureFileData={fileTableData !== undefined ? fileTableData : []}
            procedureFileRef={procedureFileRef}
            clientServerPath={serverPathValue}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
        isOpen={false}
      />
    );
  };

  handleApproveChangeMainTaskSupportFileSection = () => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            supportingFileTableRef={supportingFileTableRef}
            supportingFileRef={supportingFileRef}
            supportingFileData={supportingTableData}
          />
        }
        isOpen={false}
        isShowArrow={true}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleApproveChangeMainTaskProcedureInformationSection = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleApproveChangeMainTaskSection = () => {
    const { shareTaskData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <ApproveChangeMainTask
            shareTaskData={shareTaskData}
            ref={approveShareTaskRef}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleApproveChangeMainTaskButton = () => {
    const { isSubmitDisabled, isRejectDisabled } = this.state.pageState;

    return (
      <div className={styles.actionBtns}>
        <div className={styles.normalBtns}>
          <Buttons
            color="grey"
            size="middle"
            onClick={() => this.checkStepPermission("return")}
            disabled={false}
            btnClassName={styles.approveActionBtn}
          >
            Return to Revise
          </Buttons>
          <Buttons
            color="grey"
            size="middle"
            onClick={this.handleApproveChangeMainTaskApprovalCancel}
            disabled={false}
            btnClassName={styles.approveActionBtn}
          >
            Cancel
          </Buttons>
          <Buttons
            color="blue"
            size="middle"
            onClick={() => this.checkStepPermission("approve")}
            disabled={isSubmitDisabled}
            btnClassName={styles.approveActionBtn}
          >
            Approve
          </Buttons>
        </div>
        <div className={styles.rejectBtn}>
          <Buttons
            color="red"
            size="middle"
            onClick={() => this.checkStepPermission("reject")}
            disabled={isRejectDisabled}
          >
            Reject
          </Buttons>
          <NormalToolTip
            element={
              <div className={styles.rejectTipIcon}>
                <img src={redTipIcon} alt="Reject" />
              </div>
            }
            title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
            color="#FF5858"
            overlayClassName={styles.rejectTip}
          />
        </div>
      </div>
    );
  };

  handleApproveChangeMainTaskRejectModalWithComponent = () => {
    const { rejectReason, rejectStatus } = this.state.pageState;

    const {
      handleOk,
      handleCancel,
      okText,
      cancelText,
      closable,
      isOkBtnDisabled,
      textAreaPlacement,
      textAreaMaxLength,
      isCancelBtnDisabled,
      isModalWithComponentOpen,
      isShowTextArea,
      textAreaClassName,
      modalWidth,
      modalTitle,
      modalLabel,
    } = this.state.modalState;

    return (
      <ModalWithComponent
        cancelText={cancelText}
        textAreaMaxLength={textAreaMaxLength}
        textAreaOnChange={this.handleApproveChangeMainTaskSetRejectValue}
        textAreaPlacement={textAreaPlacement}
        textAreaClassName={textAreaClassName}
        textAreaStatus={rejectStatus}
        textAreaValue={rejectReason}
        label={modalLabel}
        modalWidth={modalWidth}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        isShowTextArea={isShowTextArea}
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
      />
    );
  };

  handleApproveChangeMainTaskReturnModalWithComponent = () => {
    return (
      <ModalWithComponent
        title={this.state.returnOrRejectModalTitle}
        label={
          <InputLabel
            text="Please enter Return Reason"
            required={true}
            className={styles.modalText}
          />
        }
        isModalOpen={this.state.isReturnOrRejectModalOpen}
        okText="Confirm"
        cancelText="Cancel"
        handleOk={this.handleApproveChangeMainTaskReturnOrRejectOk}
        handleCancel={this.handleApproveChangeMainTaskReturnOrRejectCancel}
        closable={false}
        isShowTextArea={true}
        textAreaValue={this.state.returnOrRejectInputValue}
        textAreaOnChange={(e) =>
          this.approveChangeMainTaskReturnOrRejectInputOnChange(e.target)
        }
        textAreaMaxLength={1000}
        textAreaStatus={this.state.returnOrRejectInputStatus}
      />
    );
  };

  handleApproveChangeMainTaskReturnNormalModel = () => {
    return (
      <NormalModel
        okText="Confirm"
        cancelText="Cancel"
        childContent={<p className="fs-16">{this.state.confirmModalText}</p>}
        isModalOpen={this.state.isApproveComfirmOpen}
        handleOk={this.state.handleApproveComfirm}
        handleCancel={this.handleApproveChangeMainTaskApproveComfirmCancel}
      />
    );
  };

  render() {
    const { procedureStepStatus, actionID } = this.props.pageProps;
    const { rejectReason } = this.state.pageState;

    return (
      <div className={styles.approveNewPro}>
        {this.handleApproveChangeMainTaskPageHeader()}
        {this.handleApproveChangeMainTaskTaskInformation()}
        {this.handleApproveChangeMainTaskProcedureFileSection()}
        {this.handleApproveChangeMainTaskSupportFileSection()}
        {this.handleApproveChangeMainTaskProcedureInformationSection()}
        {this.handleApproveChangeMainTaskSection()}
        {procedureStepStatus === "Pending" &&
          this.handleApproveChangeMainTaskButton()}
        {this.handleApproveChangeMainTaskRejectModalWithComponent()}
        {actionID === actions.reject && rejectReason !== null && (
          <div className={styles.rejectReasonBlock}>
            <span className={styles.rejectReasonTip}>Reject Reason</span>
            <p className={styles.rejectReasonContent}>{rejectReason}</p>
          </div>
        )}
        {this.handleApproveChangeMainTaskReturnModalWithComponent()}
        {this.handleApproveChangeMainTaskReturnNormalModel()}
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
