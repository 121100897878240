import React from "react";
import BaseComponent from "../../../common/BaseComponent";
import { Table } from "antd";
import "./index.scss";
import NormalToolTip from "../../toolTip";
import { downloadFileByUrl } from "../../../../app/publicDictionaryValues";

export default class HistoricalVersionTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this.columns = [
      {
        title: "File Name",
        dataIndex: "fileName",
        key: "fileName",
        render: (text, record) => (
          <NormalToolTip
            element={
              <a
                onClick={() => {
                  const fileList = [{
                    fileID: record.fileId,
                    fileName: text,
                  }];
                  downloadFileByUrl(fileList);
                }}
                href="#"
              >
                {text}
              </a>
            }
            title={text}
            placement="top"
          />
        ),
      },
      {
        title: "Uploaded Date",
        dataIndex: "uploadedDate",
        key: "uploadedDate",
        render: (text) => (
          <NormalToolTip
            element={this._formateDate(text)}
            title={this._formateDate(text)}
            placement="top"
          />
        ),
      },
      {
        title: "Uploaded By",
        dataIndex: "uploadedByName",
        key: "uploadedBy",
        render: (text) => (
          <NormalToolTip element={text} title={text} placement="top" />
        ),
      },
    ];
  }
  render() {
    return (
      <div className="historicalVersionTable">
        <Table
          dataSource={this.props.dataSource}
          columns={this.columns}
          pagination={false}
        />
      </div>
    );
  }
}
