import React from "react";
import RoutingComponent from "../../../../base/common/RoutingComponent";
import styles from "./index.module.scss";
import ProcessDetailPageHeader from "../../../commonComponents/pageHeaders/ProcessDetailPageHeader";
import ReadOnlyTable from "../../../commonComponents/readOnlyTable/index";
import ProcessDetailColumn from "../../../sections/myRequestListSections/processDetailColumn";
import myRequestListService from "../../../../service/myRequestListService/index";

export default class ProcessDetail extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = {
      processDetailData: null,
      processDetailTableData: [],
    };
    this.processID = this._getRoutingParam("ProcessID");
  }

  _getProcessDetailDataSuccess = (result) => {
    this.setState({
      processDetailData: result,
      processDetailTableData: result.stepData,
    });
    this._hideLoading();
  };

  _getProcessDetailDataError = (result) => {
    console.log(result);
  };

  getProcessDetailData = () => {
    const para = {
      processID: this.processID,
    };
    myRequestListService.getProcessDetailData(
      para,
      this._getProcessDetailDataSuccess,
      this._getProcessDetailDataError
    );
  };

  componentDidMount() {
    this._showLoading();
    this.getProcessDetailData();
  }

  render() {
    const { processDetailData, processDetailTableData } = this.state;
    const columns = ProcessDetailColumn(processDetailData);

    return (
      <div className={styles.body}>
        <div className={styles.header}>
          <ProcessDetailPageHeader
            title={processDetailData?.processCategoryName}
            status={processDetailData?.processStatus}
          />
        </div>
        <div className={styles.tableDiv}>
          <ReadOnlyTable data={processDetailTableData} columns={columns} />
        </div>
      </div>
    );
  }
}
