import { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import UpdateProcedureFileApproveSection from "../../../../sections/procedureFileSections/updateProcedureFileApproveSection";
import ApproveNewProcedureComplete from "../../createNewProcedureProcess/approveNewProcedureComplete";
import styles from "../../style/index.module.scss";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ApproveUpdateProcedureComplete extends ApproveNewProcedureComplete {
  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, procedureReplaceFileRef } = this.props.pageProps;
    const { CategoryID, AccountID, TaskGroupID, TaskLabelID } =
      this.props.params;
    return (
      <UpdateProcedureFileApproveSection
        sectionProps={{ currentRole, isDisableAll: true }}
        refs={{
          procedureRef: this.props.procedureRef,
          procedureFileTableRef,
          reasonRef,
          noteRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        isMount={this.props.isMount}
        savedInProps={this.props.savedInProps}
        serverPathProps={this.props.serverPathProps}
        initSavedInSectionState={this.props.initSavedInSectionState}
        initServerPathSectionState={this.props.initServerPathSectionState}
        initProcedureTableData={this.props.initProcedureTableData}
        isLevelCChange={this.props.isLevelCChange}
        ptParams={{ CategoryID, AccountID, TaskGroupID, TaskLabelID }}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };

  /**
   * function about return revision information section
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            updateFileNumLoading={this.props.updateFileNumLoading}
            openLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={true}
            revisionInfoData={this.props.revisionInfoData}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Approve Update Procedure";
  };
}
