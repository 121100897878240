import BaseService from "../base";
import ChangePOStepService from "./changePOStepService";

class ChangePOService extends BaseService {
  getProcedureOwnerStepItem = (para, success, error) => {
    ChangePOStepService.getStepData(para, success, error);
  };

  changeProcedureOwnerTriggerStepAction = (para, success, error) => {
    this.sendRequest(
      "changeProcedureOwnerTriggerStepAction",
      para,
      success,
      error
    );
  };

  checkPoIsExisted = (para, success, error) => {
    this.sendRequest("checkPoIsExisted", para, success, error);
  };
}

const changePOService = new ChangePOService();

export default changePOService;
