class ProcedureDetailMockService {
  submitSupprotingFileForApproval = (para, success, error) => {
    const submitSupprotingFileResult = {
      fileNoteList: [
        {
          fileId: "1",
          note: "mock test",
        },
      ],
    };
    success(submitSupprotingFileResult);
  };

  saveSupprotingFileAsDraft = (para, success, error) => {
    const saveSupprotingFileResult = {
      fileNoteList: [
        {
          fileId: "1",
          note: "mock test",
        },
      ],
    };
    success(saveSupprotingFileResult);
  };

  addSupprotingFile = (para, success, error, returnPara) => {
    const addSupprotingResult = {
      fileId: "1",
      succeed: true,
      errorCode: 0,
      procedureId: "C022DBBC-215A-41F5-A93B-B956D87FF33F",
    };
    success(addSupprotingResult);
  };

  getSupportingFileList = (para, success, error) => {
    const supportFileList = "[1,2,3]";
    success(supportFileList);
  };

  submitForApproval = (para, success, error) => {
    const submitResult = {
      procedureId: "C022DBBC-215A-41F5-A93B-B956D87FF33F",
      procedureVersionId: "1",
      procedureSavedIn: 1,
      clientServerPath: "",
      fileNoteList: [
        {
          fileId: "1",
          note: "mock test",
        },
      ],
    };
    success(submitResult);
  };

  saveAsDraft = (para, success, error) => {
    const saveResult = {
      procedureId: "C022DBBC-215A-41F5-A93B-B956D87FF33F",
      procedureVersionId: "1",
      procedureSavedIn: 1,
      clientServerPath: "",
      fileNoteList: [
        {
          fileId: "1",
          note: "mock test",
        },
      ],
    };
    success(saveResult);
  };

  addFile = (para, success, error, returnPara) => {
    const addFileResult = {
      fileId: "1",
      succeed: true,
      errorCode: 0,
    };
    success(addFileResult);
  };

  deleteFile = (para, success, error, returnPara) => {
    success();
  };

  getProcedureFileList = (success, error) => {
    const procedureFileList = "[1,2,3]";
    success(procedureFileList);
  };

  getTaskInfo = (para, success, error) => {
    const taskInfoResult = {
      taskId: 0,
      taskLabelName: "task1",
      accountName:
        "accountName1 test test test test test test test test test test test test test test test test test test",
      accountSupervisor: "accountSupervisor1",
      taskGroupName: "taskGroupName1",
      categoryName: "categoryName1",
      highRegulated: true,
      turnaroundTime: "2023/04/21",
      temporaryTask: true,
      accountAbbr:
        "accountAbbr test test test test test test test test test test test test test test testtesttesttesttest1",
      accountManager:
        "accountManager1 test test test test test test test test test test test test test test test test test test",
      accountTeamLeader: "accountTeamLeader1",
      createdDate: "2023/04/21",
      taskStatus: true,
    };
    success(taskInfoResult);
  };

  submitProcedureInfo = (para, success, error) => {
    const submitResult = {
      procedureID: "C022DBBC-215A-41F5-A93B-B956D87FF33F",
    };
    success(submitResult);
  };
}
const procedureDetailMockService = new ProcedureDetailMockService();
export default procedureDetailMockService;
