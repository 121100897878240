import React from "react";
import Buttons from "../../../../../../base/basicComponents/button";
import {
  ProposedChangeTagDiv,
  UpdateSupportingSectionStatusDiv,
} from "../../../../../../base/basicComponents/status";
import UploadFile from "../../../../../../base/basicComponents/uploadFile";
import {
  dataStatus,
  fileTags,
  proposedChangeTags,
} from "../../../../../publicDictionaryValues";
import ReviseProcedureFileTbody from "../../../../procedureFileSections/fields/procedureFileTbodyFields/reviseProcedureFileTbody";
import "../index.scss";

export default class ReviseUpdateSupportingFileTbody extends ReviseProcedureFileTbody {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: "",
    };
    this.isShowReplaceBtn = false;
    this.isShowDeleteBtn = false;
    this.isShowCancelBtn = false;
  }

  /**
   * function about control the  return to draft and approve buttons show in approve page
   * @param {*} row
   */
  judgeIsShowReplaceAndDeleteBtn = (row) => {
    const proposedChangeTag = row.data.find(
      (item) => item.name === "proposedChange"
    ).value;
    const status = row.data.find((x) => x.name === "status").value;
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;
    const preFileTag = row.data.find(
      (item) => item.name === "previousFileTag"
    ).value;
    const isReviewed = row.data.find(
      (item) => item.name === "isReviewed"
    ).value;
    this.isShowReplaceBtn =
      status === dataStatus.return &&
      ((proposedChangeTag === proposedChangeTags.new &&
        preFileTag !== fileTags.deleted) ||
        (proposedChangeTag === proposedChangeTags.new &&
          preFileTag === fileTags.deleted &&
          isReviewed) ||
        proposedChangeTag === proposedChangeTags.update);

    this.isShowDeleteBtn =
      status !== dataStatus.approval &&
      ((proposedChangeTag === proposedChangeTags.new &&
        preFileTag !== fileTags.deleted) ||
        proposedChangeTag === proposedChangeTags.delete ||
        (tagValue === fileTags.new && proposedChangeTag === null) ||
        (proposedChangeTag === proposedChangeTags.new &&
          preFileTag === fileTags.deleted &&
          isReviewed));

    this.isShowCancelBtn =
      proposedChangeTag === proposedChangeTags.delete &&
      status !== dataStatus.approval;
  };

  /**
   * function about show tag if column type is tag
   * @param {*} column
   * @param {*} rowColumn
   * @param {*} tagValue
   * @returns
   */
  returnTag = (column, rowColumn, tagValue) => {
    return column.dataName === "status" ? (
      <>
        <UpdateSupportingSectionStatusDiv
          status={rowColumn.value}
          tagValue={tagValue}
        />
      </>
    ) : (
      <>
        <ProposedChangeTagDiv status={rowColumn.value} />
      </>
    );
  };

  /**
   * function about show button if column type is button
   * @param {*} row
   * @param {*} column
   * @param {*} rowIndex
   * @param {*} status
   * @returns
   */
  returnButton = (row, column, rowIndex, status) => {
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;
    const isReviewed = row.data.find(
      (item) => item.name === "isReviewed"
    ).value;

    return (
      <div className="buttonTd-content">
        {this.isShowReplaceBtn && (
          <>
            <Buttons
              color={column.approveColor}
              size="small"
              onClick={(e) => column.onApproveClick(e)}
              disabled={row.isApproveDisabled}
              rowindex={rowIndex}
            >
              {column.approveText}
            </Buttons>
            <UploadFile
              fileInputRef={row.replaceRef}
              isMultiple={false}
              handleFileSelect={this.props.handleFileSelect}
              rowindex={rowIndex}
              isSupportingFile={false}
            />
          </>
        )}
        {this.isShowDeleteBtn && (
          <Buttons
            color={column.returnColor}
            size="small"
            onClick={column.onReturnClick(rowIndex)}
            disabled={tagValue === fileTags.deleted && isReviewed}
            rowindex={rowIndex}
          >
            {column.returnText}
          </Buttons>
        )}
        {this.isShowCancelBtn && (
          <Buttons
            color={column.approveColor}
            size="small"
            onClick={column.onCancelClick(rowIndex)}
            disabled={tagValue === fileTags.cancelled && isReviewed}
            rowindex={rowIndex}
          >
            {column.cancelText}
          </Buttons>
        )}
      </div>
    );
  };
}
