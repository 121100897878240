import React, { createRef } from "react";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import { FileUploadDragger } from "../../../../../base/basicComponents/uploadFile";
import { NormalTextArea } from "../../../../../base/basicComponents/input";
import { signOffSelectValues } from "../../../../publicDictionaryValues";
import styles from "../styles/index.module.scss";
import BaseAlignWithClient from "../baseAlignWithClientSection";

const attachmentRef = createRef();
const attachmentInputRef = createRef();
export default class ReadOnlyAlignWithClient extends BaseAlignWithClient {
  componentDidMount() {
    if (attachmentRef.current !== null) {
      attachmentRef.current.loadFiles();
    }
  }

  render() {
    const {
      selectValue,
      reason,
      isReasonError,
      isComplete,
      fileIdList,
      fileNameList,
    } = this.state;

    const { openLoading, hideLoading, updateFileNumLoading } = this.props;

    return (
      <div className={styles.alignWithClientContainer}>
        <div className={styles.awcTitle}>Align with Client</div>
        <div className={styles.awcFieldDiv}>
          <div className={styles.awcSelect}>
            <InputLabel text="Aligned with Client?" />
            <NormalSelect
              value={selectValue}
              optionList={this.optionList}
              disabled={true}
            />
          </div>
          <div className={styles.awcBottomDiv}>
            <div className={styles.attachEmailDiv}>
              <InputLabel
                text="Attach Email"
                required={selectValue === signOffSelectValues.Yes}
              />
              <FileUploadDragger
                ref={attachmentRef}
                className={styles.fileDragger}
                fileInputRef={attachmentInputRef}
                isShowBox={true}
                isMultiple={true}
                isShowDeleteBtn={!isComplete}
                openLoading={openLoading}
                hideLoading={hideLoading}
                updateFileNumLoading={updateFileNumLoading}
                fileIds={fileIdList}
                fileNames={fileNameList}
                isDownload={isComplete}
                fileNameType="link"
              />
            </div>
            <div className={styles.reasonDiv}>
              <InputLabel
                text="Reason"
                required={selectValue === signOffSelectValues.NoNeed}
              />
              <NormalTextArea
                value={reason}
                width="44.75"
                className="reasonInput"
                status={isReasonError ? "error" : ""}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
