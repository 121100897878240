import React from "react";
import { Modal } from "antd";
import RoutingComponent from "../../../base/common/RoutingComponent";
import styles from "./index.module.scss";
import "./index.scss";

class LostPermissionModal extends RoutingComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { isModalOpen } = this.props;
        return (
            <div>
                <Modal 
                    open={isModalOpen}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="OK"
                    centered
                    wrapClassName='lostPermissionModal'
                    onOk={() => window.location = "/ToDoList"}
                >
                    <div className={styles.title}>
                        Please note that you lost the permission to deal with this to-do due to one of the following reasons:
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>1. To-do has been completed by others.</div>
                        <div className={styles.contentItem}>2. To-do has been delegated to others.</div>
                        <div className={styles.contentItem}>3. User role change.</div>
                        <div className={styles.contentItem}>4. Task was inactivated and the process has been terminated by system.</div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default LostPermissionModal;
