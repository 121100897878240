import BaseComponent from "../../../../base/common/BaseComponent";
import downloadIcon from "../../../../assets/image/downloadIcon.png";
import downloadHoverIcon from "../../../../assets/image/downloadHoverIcon.png";
import { downloadFileByUrl } from "../../../publicDictionaryValues";
import Buttons from "../../../../base/basicComponents/button";
import styles from "../index.module.scss";
import { Progress } from "antd";

export default class BaseApplyForJbSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadIcon: downloadIcon,
      downloadBtnDisabled: true,
      downloadNum: 0,
      downloadLoading: false,
      progress: 1,
    };
  }

  /**
   * function about download file
   */
  downloadFile = (fileId, value) => {
    let fileList = [];
    if (fileId && value) {
      fileList.push({
        fileID: fileId,
        fileName: value,
      });
    } else {
      const dataList = this.fileTableRef.current.state.data;
      let downloadList = dataList.filter((x) => {
        return x.checked === true;
      });
      downloadList.map((data, index) => {
        const fileID = data.data.find((item) => item.name === "fileId").value;
        const fileName = data.data.find((item) => item.name === "fileName").value;
        fileList.push({
          fileID,
          fileName,
        });
      });
      this.fileTableRef.current.updateDataList(dataList);
    }
    this.openDownloadLoading();
    downloadFileByUrl(fileList, () => this.downloadDetailFilesSuccessfully(fileList));
  };

  /**
   * change download button style when mouse enter
   */
  handleDownloadBtnMouseEnter = () => {
    this.setState({
      downloadIcon: downloadHoverIcon,
    });
  };

  /**
   * change download button style when mouse leave
   */
  handleDownloadBtnMouseLeave = () => {
    this.setState({
      downloadIcon: downloadIcon,
    });
  };

  /**
   * undisable download btn
   */
  openDownloadBtn = () => {
    this.setState({ downloadBtnDisabled: false });
  };

  /**
   * disable download btn
   */
  disableDownloadBtn = () => {
    this.setState({ downloadBtnDisabled: true });
  };

  /**
   * control table delete button diabled
   * @param {*} dataList table data
   */
  openOrDisableDownloadButton = (dataList) => {
    const checked = dataList.find((x) => x.checked === true);
    if (checked === undefined) {
      this.disableDownloadBtn();
    } else {
      this.openDownloadBtn();
    }
  };
  /**
   * function about control table header checked
   * @param {*} lineId current row lineId
   * @returns
   */
  _check = (lineId) => {
    if (this.fileTableRef.current !== null) {
      const dataList = this.fileTableRef.current.state.data;
      let currentRow =
        dataList !== null &&
        dataList !== undefined &&
        dataList.find(
          (x) => x.data.find((y) => y.name === "lineId").value === lineId
        );
      return !!(
        currentRow !== undefined &&
        currentRow.checked !== undefined &&
        currentRow.checked
      );
    }
  };

  /**
   * function about control table header checked when chehck each row
   * @param {*} lineId current row lineId
   * @param {*} ele current checkbox
   */
  _onCheck = (lineId, ele) => {
    const dataList =
      this.fileTableRef.current !== null &&
      this.fileTableRef.current.state.data;
    let currentRow = dataList.find(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );
    const currentRowIndex = dataList.findIndex(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );

    if (ele.checked) {
      currentRow = { ...currentRow, checked: true };
    } else {
      currentRow = { ...currentRow, checked: false };
    }

    dataList[currentRowIndex] = currentRow;
    this.openOrDisableDownloadButton(dataList);
    this.fileTableRef.current.updateDataList(dataList);
  };

  /**
   * function about control procedure table header checked
   * @returns
   */
  _headCheck = () => {
    if (this.fileTableRef === undefined || this.fileTableRef.current === null) {
      return false;
    }
    const dataList =
      this.fileTableRef !== undefined && this.fileTableRef.current.state.data;
    if (dataList.length === 0) {
      return false;
    }

    let checked = false;
    checked = dataList.find((x) => x.checked !== true);
    return checked === undefined;
  };

  /**
   * function about control row's checkbox when check table header checkbox
   * @param {*} ele table header checkbox element
   */
  _headOnCheck = (ele) => {
    const dataList = this.fileTableRef.current.state.data;
    if (ele.checked) {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: true };
      }
    } else {
      for (let index = 0; index < dataList.length; index++) {
        dataList[index] = { ...dataList[index], checked: false };
      }
    }
    this.openOrDisableDownloadButton(dataList);
    this.fileTableRef.current.updateDataList(dataList);
  };

  openDownloadLoading = () => {
    this.setState({
      downloadNum: 0,
      downloadLoading: true,
      progress: 1,
    })
  }

  downloadDetailFilesSuccessfully = (downloadList) => {
    const isDownloadDone = this.state.downloadNum + 1 === downloadList.length;
    const downloadProcess = (this.state.downloadNum + 1) * 100 / downloadList.length;

    if(isDownloadDone) {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: 100,
      })
      setTimeout(() => {
        this.setState({
          downloadLoading: false,
        })
      }, 1000);
    } else {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: parseInt(downloadProcess),
      })
    }
  }

  /**
   * return download button
   */
  handleApplyForJbSectionDownloadButton = (isDownloadDisabled) => {
    const { downloadLoading, progress } = this.state;
    return (
      <div className={styles.downloadButtonDiv}>
        <Buttons
          color="black"
          size="middle"
          onMouseEnter={this.handleDownloadBtnMouseEnter}
          onMouseLeave={this.handleDownloadBtnMouseLeave}
          onClick={this.downloadFile}
          disabled={isDownloadDisabled || downloadLoading}
        >
          <div className={downloadLoading ? `${styles.downloadButton}` : ""}>
            {downloadLoading ? (
              <Progress
                type="circle"
                percent={progress}
                width={18}
                className={styles.antProgress}
              />
            ) : (
              <img
                src={this.state.downloadIcon}
                alt="Download"
                className={styles.downloadIcon}
              />
            )}
            <span>Download</span>
          </div>
        </Buttons>
      </div>
    );
  };
}