export const STATUS_CODE={
    success:200,
    unauthorized:401,
    notFound:404,
    serverError:500,
}

export const STATUS_Message = { 
    success: "Success", 
    unauthorized: "Unauthorized!", 
    notFound: "Not Found!",
    serverError:"Server have error!"
}