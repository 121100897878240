import React, { createRef } from "react";
import ProcessBaseFn from "../../../commonComponents/processBaseFn";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import styles from "../../procedureFileProcess/style/index.module.scss";
import NormalSpin from "../../../../base/basicComponents/spin";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class BaseApplyJb extends ProcessBaseFn {
  handleReturnProcedureFileSection = () => {
    // this is an empty function
  };

  handleReturnShareTaskSection = () => {
    // this is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  handleConfirmModal = () => {
    // this is an empty function
  };

  handleActionButton = () => {
    // this is an empty function
  };

  handleApplyJbPageHeader = () => {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.props.pageProps;

    return (
      <PageHeader
        title={this.handleReturnPageName()}
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status={procedureStepStatus}
      />
    );
  };

  handleApplyJbTaskInformationSection = (params) => {
    const { taskInfoData } = this.props.sectionProps;
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleApplyJbProcedureFileSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={this.handleReturnProcedureFileSection()}
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleApplyJbSupportingFileSection = (params) => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
            supportingFileData={supportingTableData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleApplyJbProcedureInformationSection = (params) => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            isShowActions={false}
            noteDisabled={true}
            ppoList={ppoList}
            procedureStatus={procedureStatus}
            isPPODisabled={true}
            submitData={procedureInfoData}
            title="Procedure Information"
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleApplyJbShareTaskSection = (params) => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={this.handleReturnShareTaskSection()}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            params,
            this.props.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  render() {
    const { loading } = this.state;
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
    return (
      <NormalSpin
        spinning={loading}
        size="large"
        children={
          <div className={styles.approveNewPro}>
            {this.handleApplyJbPageHeader()}
            {this.handleApplyJbTaskInformationSection(params)}
            {this.handleApplyJbProcedureFileSection()}
            {this.handleApplyJbSupportingFileSection(params)}
            {this.handleApplyJbProcedureInformationSection(params)}
            {this.handleApplyJbShareTaskSection(params)}
            {this.handleActionButton()}
            {this.handleConfirmModal()}
          </div>
        }
      />
    );
  }
}
