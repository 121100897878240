import Router from "../base/control/routing/Router";
import Login from "../app/pages/login";
import appSetting from "./AppSetting";

const LoaderPageSetting = {
  main: Router,
  login: {
    login: Login,
    loginProps: appSetting.defaultPage,
    redirectUrl: "/ProcedureList",
  },
};

export default LoaderPageSetting;
