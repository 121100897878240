import React, { createRef } from "react";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import ReadOnlyReviseProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyReviseProcedureFileSection";
import BaseProcedureComplete from "../../baseProcedure/baseProcedureComplete";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();

export default class ReviseNewProcedureComplete extends BaseProcedureComplete {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
    };
  }
  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  /**
   * function about page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Revise New Procedure";
  };

  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, procedureReplaceFileRef, isDisableAll } =
      this.props.pageProps;

    return (
      <ReadOnlyReviseProcedureFileSection
        sectionProps={{ currentRole }}
        savedInProps={this.props.savedInProps}
        serverPathProps={this.props.serverPathProps}
        initSavedInSectionState={this.props.initSavedInSectionState}
        initServerPathSectionState={this.props.initServerPathSectionState}
        initProcedureTableData={this.props.initProcedureTableData}
        initProcedureFileTableState={this.props.initProcedureFileTableState}
        refs={{
          procedureSavedInRef: this.props.procedureSavedInRef,
          procedureServerPathRef: this.props.procedureServerPathRef,
          procedureRef: this.props.procedureRef,
          procedureFileTableRef,
          reasonRef,
          noteRef,
          procedureFileRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        updateSavedInRadioValue={this.updateSavedInRadioValue}
        updateServerPathValue={this.updateServerPathValue}
        _showLoading={this.props._showLoading}
        _hideLoading={this.props._hideLoading}
        updateUploadProcedureFileNum={this.props.updateUploadProcedureFileNum}
        updateProcedureFileNum={this.props.updateProcedureFileNum}
        isDisableAll={isDisableAll}
        isCopyPage={this.props.isCopyPage}
      />
    );
  };
}
