import React from "react";
import BaseComponent from "../../common/BaseComponent";
import returnReasonLogIcon from "../../../assets/image/returnReasonLogIcon.png";
import returnReasonLogHoverIcon from "../../../assets/image/returnReasonLogHoverIcon.png";

export default class ReasonLogIcon extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      reasonLogIcon: returnReasonLogIcon,
    };
  }
  render() {
    const { className, onClick, altText, rowindex } = this.props;
    return (
      <div
        className={className}
        onClick={onClick}
        onMouseEnter={() =>
          this.setState({ reasonLogIcon: returnReasonLogHoverIcon })
        }
        onMouseLeave={() =>
          this.setState({ reasonLogIcon: returnReasonLogIcon })
        }
      >
        <img src={this.state.reasonLogIcon} alt={altText} rowindex={rowindex} />
      </div>
    );
  }
}
