import { createRef } from "react";
import { actions, flowCategoryId } from "../../../../publicDictionaryValues";
import CreateSupportingFileApproveSection from "../../../../sections/supportingFileSections/createSupportingFileApproveSection";
import BaseNewSupportingFileComplete from "../../baseNewSupportingFile/baseNewSupportingFileComplete";
import styles from "../../style/index.module.scss";

const supportingFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ApproveNewSupportingFileComplete extends BaseNewSupportingFileComplete {
  handleReturnPageName = () => {
    if (
      this.props.pageProps.flowCategoryID === flowCategoryId.copySupportingFile
    ) {
      return "Copy Supporting File";
    }

    return "Approve New Supporting File";
  };

  handleReturnRejectReason = () => {
    const { actionID, newRejectReason } = this.props.pageProps;
    return (
      actionID === actions.reject &&
      newRejectReason !== null && (
        <div className={styles.rejectReasonBlock}>
          <span className={styles.rejectReasonTip}>Reject Reason</span>
          <p
            className={styles.rejectReasonContent}
            dangerouslySetInnerHTML={{ __html: newRejectReason }}
          />
        </div>
      )
    );
  };

  handleReturnSupportingFileSection = () => {
    const { currentRole, supportingReplaceFileRef, isDisableAll } =
      this.props.pageProps;
    return (
      <CreateSupportingFileApproveSection
        sectionProps={{ currentRole, isDisableAll }}
        initSupportingTableData={this.props.initSupportingTableData}
        refs={{
          supportingRef: this.props.supportingRef,
          supportingFileTableRef,
          reasonRef,
          noteRef,
          supportingReplaceFileRef,
        }}
        data={this.props.data}
        isMount={this.props.isMount}
        isCopyPage={this.props.isCopyPage}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };
}
