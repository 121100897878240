import BaseComponent from "../../../base/common/BaseComponent";

export default class ProcessBaseFn extends BaseComponent {
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        modalTitle: "",
        textAreaClassName: "",
        textAreaMaxLength: 0,
        textAreaPlacement: "",
        actionType: "",
        fileIds: [],
        fileNames: [],
        maskClosable: false,
        isShowTextArea: false,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        modalWidth: 520,
      },
    });
  };
}
