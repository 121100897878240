import { Input } from "antd";
import ReadOnlyFileManagement from "../../../../../commonComponents/fileManagementFields/readOnlyFileManagement";
import { savedInRadioValues } from "../../../../../publicDictionaryValues";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
export default class ReadOnlyActiveProcedureFileManagement extends ReadOnlyFileManagement {
  /**
   * function about return table columns
   * @param {object} obj
   * @returns
   */
  handleReturnColumns = (obj) => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow: true,
      },
    ];
  };

  handleReturnJbProcedureSavedIn = (savedInRadioValue, JbProcedureSaveIn) => {
    return (
      savedInRadioValue !== savedInRadioValues.systemOnly &&
      savedInRadioValue !== null && (
        <div className={styles.jbProcedureLine}>
          <div></div>
          <div className={styles.jbProcedureDiv}>
            <span>JB Procedure Saved</span>
            <div className={styles.jbProcedureInputDiv}>
              <Input
                value={this.handleJbProcedureSavedValue(JbProcedureSaveIn)}
                disabled
                className="jbProcedureInput"
              />
            </div>
          </div>
        </div>
      )
    );
  };

  downloadFile = () => {
    this._showDownloadLoading();
    this.baseDownloadFiles(this.downloadDetailFilesSuccessfully);
  };
}
