import BaseComponent from "../../common/BaseComponent";
import approvedIcon from "../../../assets/image/approvedIcon.png";
import returnedIcon from "../../../assets/image/returnedIcon.png";
import rejectedIcon from "../../../assets/image/rejected.png";
import "./statusDiv.scss";
import {
  dataStatus,
  fileTags,
  proposedChangeTags,
} from "../../../app/publicDictionaryValues";

export default class StatusDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case 1:
        className = "draft";
        textContent = "Draft";
        break;
      case 2:
        className = "pending-approval";
        textContent = "Pending Approval";
        break;
      case 3:
        className = "active";
        textContent = "Active";
        break;
      case 4:
        className = "archived";
        textContent = "Archived";
        break;
      case 7:
        className = "updating";
        textContent = "Updating";
        break;
      default:
        className = "";
        textContent = "";
    }

    return <div className={`status-div ${className}`}>{textContent}</div>;
  }
}

export class SectionStatusDiv extends BaseComponent {
  render() {
    const { status, tagValue } = this.props;
    let className, textContent, iconSrc;

    switch (status) {
      case dataStatus.approval:
        className = "approved";
        textContent = "Approved";
        iconSrc = approvedIcon;
        break;
      case dataStatus.return:
        className = "returned";
        textContent = "Returned";
        iconSrc = returnedIcon;
        break;
      case dataStatus.reject:
        className = "rejectedStatus";
        textContent = "Rejected";
        iconSrc = rejectedIcon;
        break;
      default:
        className = "";
        textContent = "";
    }

    if (tagValue === fileTags.deleted) {
      className = "returned";
      textContent = "Returned";
      iconSrc = returnedIcon;
    }
    return (
      <div className={`status-div section-status ${className}`}>
        <div className="iconImg">
          <img src={iconSrc} alt={className} />
        </div>
        {textContent}
      </div>
    );
  }
}

export class UpdateSupportingSectionStatusDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent, iconSrc;

    switch (status) {
      case dataStatus.approval:
        className = "approved";
        textContent = "Approved";
        iconSrc = approvedIcon;
        break;
      case dataStatus.return:
        className = "returned";
        textContent = "Returned";
        iconSrc = returnedIcon;
        break;
      default:
        className = "";
        textContent = "";
    }
    return (
      <div className={`status-div section-status ${className}`}>
        <div className="iconImg">
          <img src={iconSrc} alt={className} />
        </div>
        {textContent}
      </div>
    );
  }
}

export class FileStatusDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case fileTags.replaced:
        className = "replaced";
        textContent = "Replaced";
        break;
      case fileTags.deleted:
        className = "deleted";
        textContent = "Deleted";
        break;
      case fileTags.new:
        className = "new";
        textContent = "New";
        break;
      case fileTags.cancelled:
        className = "cancelled";
        textContent = "Cancelled";
        break;
      default:
        className = "";
        textContent = "";
    }

    return <div className={`${className}`}>{textContent}</div>;
  }
}

export class ChangeMainTaskTag extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case "OldMainTask":
        className = "oldMainTaskTag";
        textContent = "Old Main Task";
        break;
      case "NewMainTask":
        className = "newMainTaskTag";
        textContent = "New Main Task";
        break;
      default:
        className = "";
        textContent = "";
    }

    return <div className={`${className}`}>{textContent}</div>;
  }
}

export class HeaderTopStatusDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case "Pending":
        className = "pending";
        textContent = "pending";
        break;
      case "Complete":
        className = "completed";
        textContent = "completed";
        break;
      case "Pending Approval":
        className = "pendingApproval";
        textContent = "Pending Approval";
        break;
      case "Pending Submission":
        className = "pendingSubmission";
        textContent = "Pending Submission";
        break;
      case "Rejected":
        className = "rejected";
        textContent = "Rejected";
        break;
      case "Approval":
        className = "approval";
        textContent = "Approval";
        break;
      case "System Termination":
        className = "systemTermination";
        textContent = "System Termination";
        break;
      default:
        className = "";
        textContent = "";
    }

    return (
      <div className={`headerTopStatusDiv ${className}`}>{textContent}</div>
    );
  }
}

export class ProcessDetailHeaderTopStatusDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case "Pending Approval":
        className = "pendingApproval";
        textContent = "Pending Approval";
        break;
      case "Pending Submission":
        className = "pendingSubmission";
        textContent = "Pending Submission";
        break;
      case "Rejected":
        className = "rejected";
        textContent = "Rejected";
        break;
      case "Approved":
        className = "approval";
        textContent = "Approved";
        break;
      case "System Termination":
        className = "systemTermination";
        textContent = "System Termination";
        break;
      default:
        className = "";
        textContent = "";
    }

    return (
      <div className={`MyRequestProcessStatusDiv ${className}`}>
        {textContent}
      </div>
    );
  }
}

export class ProposedChangeTagDiv extends BaseComponent {
  render() {
    const { status } = this.props;
    let className, textContent;

    switch (status) {
      case proposedChangeTags.update:
        className = "replaced";
        textContent = "Update";
        break;
      case proposedChangeTags.delete:
        className = "deleted";
        textContent = "Delete";
        break;
      case proposedChangeTags.new:
        className = "new";
        textContent = "Add New";
        break;
      default:
        className = "noChange";
        textContent = "No Change";
    }

    return <div className={`${className}`}>{textContent}</div>;
  }
}
