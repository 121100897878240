export default class UserModel {
    
    constructor(param){
        if (!param) {
            this.userID = '';
            this.userCode = '';
            this.userName = '';
            this.extra = {};
            return;
        }

        this.userID = param.userID;
        this.userCode = param.userCode;
        this.userName = param.userName;
        this.extra = param.extra;
    }
}