import React, { createRef } from "react";
import BaseProcedureComplete from "../../baseProcedure/baseProcedureComplete";
import ReadOnlyApproveProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyApproveProcedureFileSection";
import { actions, flowCategoryId } from "../../../../publicDictionaryValues";
import styles from "../../style/index.module.scss";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
export default class ApproveNewProcedureComplete extends BaseProcedureComplete {
  /**
   * function about return page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Approve New Procedure";
  };

  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, procedureReplaceFileRef, isDisableAll,} =
      this.props.pageProps;
    return (
      <ReadOnlyApproveProcedureFileSection
        sectionProps={{ currentRole, isDisableAll }}
        savedInProps={this.props.savedInProps}
        serverPathProps={this.props.serverPathProps}
        initSavedInSectionState={this.props.initSavedInSectionState}
        initServerPathSectionState={this.props.initServerPathSectionState}
        initProcedureTableData={this.props.initProcedureTableData}
        refs={{
          procedureSavedInRef: this.props.procedureSavedInRef,
          procedureServerPathRef: this.props.procedureServerPathRef,
          procedureRef: this.props.procedureRef,
          procedureFileTableRef,
          reasonRef,
          noteRef,
          procedureFileRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        isCopyPage={this.props.isCopyPage}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };

  /**
   * function about return reject reason
   */
  handleReturnRejectReason = () => {
    const { actionID, newRejectReason } = this.props.pageProps;
    return (
      actionID === actions.reject &&
      newRejectReason !== null && (
        <div className={styles.rejectReasonBlock}>
          <span className={styles.rejectReasonTip}>Reject Reason</span>
          <p
            className={styles.rejectReasonContent}
            dangerouslySetInnerHTML={{ __html: newRejectReason }}
          />
        </div>
      )
    );
  };
}
