import ApporveUpdateSupportingFileTable from "../../../../supportingFileSections/fields/supportingFileTable/approveUpdateSupportingFileTable";
import ApproveUpdateProcedureFileTbody from "../../procedureFileTbodyFields/approveUpdateProcedureFileTbody";

export default class ApproveUpdateProcedureFileTable extends ApporveUpdateSupportingFileTable {
  /* update file table data */
  updateDataList = (dataList) => {
    this.newDataList = [];
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: this.newDataList.length === 0 ? dataList : this.newDataList,
    });
    this.handleDefaultExpendIcon();
  };

  /**
   * return procedure file table's tbody
   * @returns
   */
  handleReturnTbody = () => {
    const {
      procedureNoteMaxLength,
      procedureNoteSize,
      inputOnChange,
      fileTableRef,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isComplete,
      columns,
      isExpendData,
    } = this.props;
    const { data } = this.state;
    return (
      <ApproveUpdateProcedureFileTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        procedureNoteSize={procedureNoteSize}
        fileTableRef={fileTableRef}
        optionList={optionList}
        isShowReturnReason={isShowReturnReason}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isComplete={isComplete}
        isExpendData={isExpendData}
      />
    );
  };
}
