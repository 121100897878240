/* eslint-disable array-callback-return */
import React from "react";
import { useMount, useSetState } from "ahooks";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import Buttons from "../../../../../base/basicComponents/button";
import procedureListService from "../../../../../service/procedureList";
import TopFilterBase from "../../../../commonComponents/listBase/TopFilterBase";
import styles from "./index.module.scss";
import "../../../../../assets/css/style.css";

let accountTaskGroupCategoryMapping = [];
export default function TopFilter(props) {
  const {
    handleOptionList,
    sortList,
    handleChange,
    handleDeSelect,
    handleFilterOption,
  } = TopFilterBase();

  /* state about selects' data */
  const [filterOptionList, setFilterOptionList] = useSetState({
    accountAbbr: [],
    accountStatus: [],
    taskGroup: [],
    category: [],
    taskStatus: [],
  });

  /* state about select's selected data */
  const [selectOptions, setSelectOptions] = useSetState({
    accountAbbr: [],
    accountStatus: [],
    taskGroup: [],
    category: [],
    taskStatus: [],
  });

  const [defaultValue, setDefaultValue] = useSetState({
    accountAbbr: [],
    accountStatus: ["1"],
    taskGroup: [],
    category: [],
    taskStatus: ["1"],
  });

  const removeDuplicate = (result) => {
    return result.reduce((acc, curr) => {
      const existingItem = acc.find((item) => item.value === curr.value);
      if (!existingItem) {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  const setCategoryOption = (taskGroupIds, setDefault) => {
    let option = [];
    let select = [];
    for (const item of accountTaskGroupCategoryMapping) {
      if (taskGroupIds.includes(item.taskGroupId)) {
        const { categoryId, categoryName } = item;
        if (categoryId !== 0 && categoryId !== null && categoryId !== "") {
          option.push({ value: categoryId, name: categoryName });
        }
      }
    }
    option = removeDuplicate(option).sort((s, t) => sortList(s, t));
    select = option.map((x) => {
      return x.value;
    });
    setFilterOptionList({
      category: option,
    });
    setSelectOptions({
      category: select,
    });
    if (setDefault) {
      setDefaultValue({
        category: select,
      });
    }
  };

  const setTaskGroupOption = (accountIds, setDefault) => {
    let option = [];
    let select = [];
    for (const item of accountTaskGroupCategoryMapping) {
      if (accountIds.includes(item.accountId)) {
        const { taskGroupId, taskGroupName } = item;
        if (taskGroupId !== 0 && taskGroupId !== null && taskGroupId !== "") {
          option.push({ value: taskGroupId, name: taskGroupName });
        }
      }
    }
    option = removeDuplicate(option).sort((s, t) => sortList(s, t));
    select = option.map((x) => {
      return x.value;
    });
    setFilterOptionList({
      taskGroup: option,
    });
    setSelectOptions({
      taskGroup: select,
    });
    if (setDefault) {
      setDefaultValue({
        taskGroup: select,
      });
    }
    setCategoryOption(select, setDefault);
  };

  const getTaskGroupsCategoriesOptionSuccess = (result) => {
    accountTaskGroupCategoryMapping = result;
    loadGetAccountAbbr(1, true);
  };

  const getTaskGroupsCategoriesMapping = () => {
    procedureListService.getAccountTaskGroupsCategoriesMapping(
      { isOpex: props.isOpex },
      getTaskGroupsCategoriesOptionSuccess,
      onError
    );
  };

  const handleAccountStatusChange = (accountStatus, setDefault) => {
    procedureListService.getAllAccountAbbreviations(
      accountStatus,
      (data) => {
        onSuccess("accountAbbr", data, setDefault, false);
      },
      onError
    );
  }

  /* function about get Account Abbreviation select options */
  const loadGetAccountAbbr = (accountStatus, setDefault) => {
    if (props.isOpex) {
      procedureListService.getAllAccountAbbreviations(
        accountStatus,
        (data) => {
          onSuccess("accountAbbr", data, setDefault);
        },
        onError
      );
    } else {
      procedureListService.getAccountAbbrList((data) => {
        onSuccess("accountAbbr", data, setDefault);
      }, onError);
    }
  };

  /* function about get Account Status select options */
  const loadGetAccountStatus = () => {
    procedureListService.getAccountStatusList((data) => {
      onSuccess("accountStatus", data);
    }, onError);
  };

  /* function about get Task Status select options */
  const loadGetTaskStatus = () => {
    procedureListService.getTaskStatusList((data) => {
      onSuccess("taskStatus", data);
    }, onError);
  };

  /**
   * callback about call api success
   * @param {*} type: select type
   * @param {*} response: call api's response
   */
  const onSuccess = (type, response, setDefault, isToFilter = true) => {
    let option;
    switch (type) {
      case "accountAbbr":
        option = handleOptionList(response, true);
        const defaultValue = option.length === 0 ? [] : [option[0].value];
        if (setDefault) {
          setDefaultValue({
            accountAbbr: defaultValue,
          });
        }

        const filterOptionPara = {
          accountAbbr: option,
        };
        const selectOptionsPara = {
          accountAbbr: defaultValue,
          accountStatus: ["1"],
          taskStatus: ["1"],
        };
        isToFilter && props.handleFilterClick(filterOptionPara, selectOptionsPara);

        setFilterOptionList({ accountAbbr: option });
        setSelectOptions({ accountAbbr: defaultValue });
        setTaskGroupOption(defaultValue, setDefault);
        break;
      case "accountStatus":
        option = [
          { name: "Active", value: "1" },
          { name: "Inactive", value: "0" },
          { name: "Both", value: "2" },
        ];
        setFilterOptionList({
          accountStatus: option,
        });
        setSelectOptions({ accountStatus: ["1"] });
        break;
      case "taskStatus":
        option = [
          { name: "Active", value: "1" },
          { name: "Inactive", value: "0" },
          { name: "Both", value: "2" },
        ];
        setFilterOptionList({ taskStatus: option });
        setSelectOptions({ taskStatus: ["1"] });
        break;
      default:
        break;
    }
  };

  /* callback about when call api error */
  const onError = (error) => {
    console.log(error);
  };

  const handleFilterClick = () => {
    props.handleFilterClick(filterOptionList, selectOptions);
  };

  const handleClearClick = () => {
    setFilterOptionList({
      taskGroup: [],
      category: [],
    });
    setSelectOptions({
      accountAbbr: [],
      taskGroup: [],
      category: [],
      accountStatus: ["1"],
      taskStatus: ["1"],
    });
    props.handleClear();
  };

  const handleSelectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    const allOptionsValue = optionList.map(item => item.value);

    setSelectOptions({
      [filterName]: allOptionsValue,
    });
    setFilterOptionList({
      [filterName]: [
        ...handleChange(optionList, allOptionsValue),
      ],
    });

    filterName === "accountAbbr" && setTaskGroupOption(allOptionsValue, false);
    filterName === "taskGroup" && setCategoryOption(allOptionsValue, false);
  }

  const handleDeselectAll = (filterName) => {
    const optionList = filterOptionList[filterName];
    
    setSelectOptions({
      [filterName]: [],
    });
    setFilterOptionList({
      [filterName]: [
        ...optionList.sort((s, t) => sortList(s, t)),
      ],
    });

    filterName === "accountAbbr" && setTaskGroupOption([], false);
    filterName === "taskGroup" && setCategoryOption([], false);
  }

  /* function about when page init get filter selects option list */
  useMount(() => {
    getTaskGroupsCategoriesMapping();
    loadGetAccountStatus();
    loadGetTaskStatus();
  });

  return (
    <div className={styles.topFilterContainer}>
      <div className={styles.topFilterSelects}>
        {props.isOpex && (
          <div className={styles.topFilterSelectItem}>
            <InputLabel
              text="Account Status"
              className={`${styles.topFilterSelectLabel} fs-14`}
            />
            <NormalSelect
              value={selectOptions.accountStatus}
              optionList={filterOptionList.accountStatus}
              className="topFilterSelectInput"
              showArrow={true}
              maxTagCount="responsive"
              handleChange={(e) => {
                setSelectOptions({
                  accountStatus: e,
                });
                handleAccountStatusChange(parseInt(e), false);
              }}
            />
          </div>
        )}
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Account Code"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.accountAbbr}
            optionList={filterOptionList.accountAbbr}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.accountAbbr;
              setSelectOptions({
                accountAbbr: e,
              });
              setFilterOptionList({
                accountAbbr: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
              setTaskGroupOption(e, false);
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.accountAbbr];
              setSelectOptions({ accountAbbr: handleDeSelect(e, selectList) });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.accountAbbr.length > 0}
            handleSelectAll={() => handleSelectAll('accountAbbr')}
            handleDeselectAll={() => handleDeselectAll('accountAbbr')}
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Task Group"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.taskGroup}
            optionList={filterOptionList.taskGroup}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.taskGroup;
              setSelectOptions({
                taskGroup: e,
              });
              setFilterOptionList({
                taskGroup: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
              setCategoryOption(e, false);
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.taskGroup];
              setSelectOptions({ taskGroup: handleDeSelect(e, selectList) });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.taskGroup.length > 0}
            handleSelectAll={() => handleSelectAll('taskGroup')}
            handleDeselectAll={() => handleDeselectAll('taskGroup')}
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Category"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.category}
            optionList={filterOptionList.category}
            className="topFilterSelectInput"
            mode="multiple"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              const optionList = filterOptionList.category;
              setSelectOptions({
                category: e,
              });
              setFilterOptionList({
                category: [
                  ...handleChange(optionList, e),
                  ...optionList.sort((s, t) => sortList(s, t)),
                ],
              });
            }}
            handleDeSelect={(e) => {
              const selectList = [...selectOptions.category];
              setSelectOptions({ category: handleDeSelect(e, selectList) });
            }}
            handleFilterOption={(input, option) =>
              handleFilterOption(input, option)
            }
            isNeedSelectAll={filterOptionList.category.length > 0}
            handleSelectAll={() => handleSelectAll('category')}
            handleDeselectAll={() => handleDeselectAll('category')}
          />
        </div>
        <div className={styles.topFilterSelectItem}>
          <InputLabel
            text="Task Status"
            className={`${styles.topFilterSelectLabel} fs-14`}
          />
          <NormalSelect
            value={selectOptions.taskStatus}
            optionList={filterOptionList.taskStatus}
            className="topFilterSelectInput"
            showArrow={true}
            maxTagCount="responsive"
            handleChange={(e) => {
              setSelectOptions({
                taskStatus: e,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.topFilterBtns}>
        <Buttons color="blue" size="middle" onClick={handleFilterClick}>
          Filter
        </Buttons>
        <Buttons color="grey" size="large" onClick={handleClearClick}>
          Clear Query
        </Buttons>
      </div>
    </div>
  );
}
