import { React, createRef } from "react";
import { Input } from "antd";
import ReadOnlyText from "../../../../base/basicComponents/readOnly-text";
import BaseComponent from "../../../../base/common/BaseComponent";
import styles from "../TlReviewProcedureSection/index.module.scss";
import "./index.scss";
import { FileUploadDragger } from "../../../../base/basicComponents/uploadFile";

const attachmentRef = createRef();
const attachmentInputRef = createRef();
class TlReviewProcedureReadOnlySection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      reviewResultText: "",
      submitDisabled: false,
      reviewResultStatus: "normal",
      fileList: [],
    };
  }

  showInputError = () => {
    this.setState({
      reviewResultStatus: "error",
    });
  };

  setDefault = () => {
    this.setState({
      reviewResultStatus: "normal",
      reviewResultText: "",
    });
  };

  handleReasonTextChange = (value) => {
    this.setState({
      reviewResultStatus: "normal",
      reviewResultText: value,
    });
  };

  handleFileListChange = (list) => {
    this.setState({ fileList: list });
  };

  render() {
    const { reviewResult, reviewedDate, fileIds, fileNames } = this.props;

    return (
      <div className={styles.tlReviewProcedureSectionDiv}>
        <div className={styles.tlReviewProcedureSectionTop}>
          <span className={`${styles.tlReviewProcedureSectionTitle} fs-18`}>
            Review Information
          </span>
        </div>
        <div className={styles.tlReviewProcedureSectionContent}>
          <div className={styles.fieldArea}>
            <div className={styles.tlReviewField}>
              <span className={styles.filedTilte}>Reviewed Date</span>
              <ReadOnlyText
                value={reviewedDate}
                marginLeft="-1rem"
                width="30rem"
              />
            </div>
            <div className={styles.tlReviewField}>
              <span className={styles.filedTilte}>Review Result</span>
              <Input.TextArea
                readOnly={true}
                value={reviewResult}
                autoSize={{ minRows: 3 }}
                maxLength={1000}
                className="readOnlyTextArea"
                disabled={true}
                style={{
                  width: "30rem",
                }}
              />
            </div>
          </div>
          <div className={styles.attachmentArea}>
            <span className={styles.filedTilte}>Attachment</span>
            <FileUploadDragger
              ref={attachmentRef}
              className={styles.fileDragger}
              fileInputRef={attachmentInputRef}
              isShowBox={true}
              isMultiple={true}
              fileIds={fileIds}
              fileNames={fileNames}
              isDownload={true}
              fileNameType="link"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TlReviewProcedureReadOnlySection;
