import DetailFileManagement from "../detailProcedureFileManagement";
import Buttons from "../../../../../../base/basicComponents/button";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
import ProcedureDetailService from "../../../../../../service/procedureFile/ProcedureDetailService";
import AppSetting from "../../../../../../config/AppSetting";
import {
  deepClone,
  fileTags,
  handleAlertUploadFileError,
  proposedChangeTags,
  revisionFromList,
  savedInRadioValues,
  procedureJbStatusMap,
} from "../../../../../publicDictionaryValues";
import UpdateProcedureFileTable from "../../procedureFileTableFields/updateProcedureFileTable";
import UpdateProcedureFileModalTable from "../../procedureFileTableFields/updateProcedureFileModalTable";
import "./index.scss";

export default class UpdateProcedureFileManagement extends DetailFileManagement {
  /**
   * update saved in radio value
   * @param {string} value current saved in value
   * @param {string} prevValue last saved in value
   */
  updateRadioValue = (value, prevValue) => {
    if (
      (value === savedInRadioValues.serverOnly || value === null) &&
      prevValue !== savedInRadioValues.serverOnly &&
      prevValue !== null
    ) {
      const dataList = deepClone(this.getDataList());
      this.prevProcedureDataList = dataList;
    }
    this.setState({ savedInRadioValue: value });
  };

  setUpdateProcedureFileManagementSaveSubmitCancleButton = (prevState) => {
    if (
      (prevState.savedInRadioValue === savedInRadioValues.serverOnly &&
        this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
        this.state.savedInRadioValue !== null) ||
      (prevState.savedInRadioValue === null &&
        this.state.savedInRadioValue !== null &&
        this.state.savedInRadioValue !== savedInRadioValues.serverOnly)
    ) {
      this.props.fileTableRef.current.updateDataList(
        this.prevProcedureDataList
      );
      this.enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton(
        this.prevProcedureDataList,
        this.state.clientPath,
        this.state.savedInRadioValue
      );
    }
  };

  updateProcedureFileManagementSavedInRadioValueChangeSystemOnly = (
    prevState
  ) => {
    if (
      prevState.savedInRadioValue !== this.state.savedInRadioValue &&
      this.state.savedInRadioValue === savedInRadioValues.systemOnly
    ) {
      this.setState({ clientPath: "" });
    }
  };

  updateProcedureFileManagementSavedInRadioValueChangeNotSystemOnly = (
    prevState
  ) => {
    if (
      prevState.savedInRadioValue !== this.state.savedInRadioValue &&
      this.state.savedInRadioValue !== savedInRadioValues.systemOnly
    ) {
      this.setState({ clientPath: this.state.prevClientPath });
    }
  };

  updateProcedureFileManagementInitClientServerPathChange = (prevProps) => {
    if (this.props.initClientServerPath !== prevProps.initClientServerPath) {
      this.setState({
        clientPath: this.props.initClientServerPath,
        prevClientPath: this.props.initClientServerPath,
      });
    }
  };

  updateProcedureFileManagementClientServerPathChange = (prevState) => {
    if (this.state.clientPath !== prevState.clientPath) {
      const dataList =
        this.props.fileTableRef.current === null
          ? []
          : this.props.fileTableRef.current.state.data;
      this.enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton(
        dataList,
        this.state.clientPath,
        this.state.savedInRadioValue
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.setUpdateProcedureFileManagementSaveSubmitCancleButton(prevState);
    this.updateProcedureFileManagementSavedInRadioValueChangeSystemOnly(
      prevState
    );
    this.updateProcedureFileManagementSavedInRadioValueChangeNotSystemOnly(
      prevState
    );
    this.updateProcedureFileManagementInitClientServerPathChange(prevProps);
    this.updateProcedureFileManagementClientServerPathChange(prevState);
  }

  setUpdateProcedureFileManagementExistsInB = (
    initOrLastSaveData,
    lineIdValue
  ) => {
    return initOrLastSaveData.some((objB) => {
      const lineIdBValue = objB.data.find(
        (item) => item.name === "lineId"
      ).value;
      return lineIdBValue === lineIdValue;
    });
  };

  setUpdateProcedureFileManagementLineIdValue = (dataList, currentRowIndex) => {
    return dataList[currentRowIndex].data.find((item) => item.name === "lineId")
      .value;
  };

  setUpdateProcedureFileManagementDeleteList = (dataList, currentRowIndex) => {
    this.deleteList.push({ ...dataList[currentRowIndex], isDelete: true });
  };

  updateProcedureFileManagementDeleteFile = (dataList, currentRowIndex) => {
    const fileId = dataList[currentRowIndex].data.find(
      (x) => x.name === "fileId"
    ).value;
    let dto = {
      ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
      StorageFileID: fileId,
    };
    ProcedureDetailService.deleteFile(
      dto,
      this._deleteFileOnSuccess,
      this._deleteFileOnFail,
      fileId
    );
  };

  /**
   * function about click confim when click delete each row
   * @param {*} currentRowIndex current row index
   */
  handleUpdateProcedureFileManagementRowFileDeleteModalConfirm = (
    currentRowIndex
  ) => {
    const dataList = this.getDataList();
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const lineIdValue = this.setUpdateProcedureFileManagementLineIdValue(
      dataList,
      currentRowIndex
    );
    const existsInB = this.setUpdateProcedureFileManagementExistsInB(
      initOrLastSaveData,
      lineIdValue
    );
    if (existsInB) {
      this.setUpdateProcedureFileManagementDeleteList(
        dataList,
        currentRowIndex
      );
    } else {
      this.updateProcedureFileManagementDeleteFile(dataList, currentRowIndex);
    }
    dataList.splice(currentRowIndex, 1);
    this.props.fileTableRef.current.updateDataList(dataList);
    this.enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton(
      dataList
    );
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: null,
    });
  };

  updateProcedureFileManagementOpenDeleteModal = (e) => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to delete this file?",
      handleModalConfirm: () =>
        this.handleUpdateProcedureFileManagementRowFileDeleteModalConfirm(
          e.target.attributes.rowindex.nodeValue
        ),
    });
  };

  updateProcedureFileManagementReturnInitDataListRow = (
    fileNameObj,
    dataList,
    index
  ) => {
    const initFileKeyObj = dataList[index].data.find(
      (item) => item.name === "initFileKey"
    );
    const initFileNameObj = dataList[index].data.find(
      (item) => item.name === "initFileName"
    );
    const fileKeyObj = dataList[index].data.find(
      (item) => item.name === "fileKey"
    );
    const initFileIdObj = dataList[index].data.find(
      (item) => item.name === "initFileId"
    );
    const fileIdObj = dataList[index].data.find(
      (item) => item.name === "fileId"
    );
    fileNameObj.value = initFileNameObj.value;
    fileIdObj.value = initFileIdObj.value;
    fileKeyObj.value = initFileKeyObj.value;
    dataList[index].data.find((item) => item.name === "fileName").tagValue =
      fileTags.deleted;
    dataList[index].isUpdate = false;
    dataList[index].isReturnDisabled = true;
    dataList[index].isApproveDisabled = false;
    dataList[index].data.find((item) => item.name === "proposedChange").value =
      proposedChangeTags.delete;
    if (dataList[index].isError) {
      dataList[index].isError = false;
    }
    this.props.fileTableRef.current.updateDataList(dataList);
    this.enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton(
      dataList
    );
  };

  /**
   * delete procedure when click each row's delete button in revise page
   * @param {*} e
   */
  deleteUpdateProcedureFileManagementRowFile = (e) => {
    const dataList = this.getDataList();
    const index = e.target.attributes.rowindex.nodeValue;

    const fileNameObj = dataList[index].data.find(
      (item) => item.name === "fileName"
    );

    const replaceUserId = dataList[index].data.find((item) => item.name === "replaceUserId");
    const replaceUserName = dataList[index].data.find((item) => item.name === "replaceUserName");
    let dataArray = [];
    if(replaceUserId && replaceUserName) {
      dataArray = dataList[index].data.filter(item => item.name !== "replaceUserId" && item.name !== "replaceUserName");
      dataList[index].data = [...dataArray];
    }

    const isNew = fileNameObj.tagValue === fileTags.new;
    if (isNew) {
      this.updateProcedureFileManagementOpenDeleteModal(e);
    } else {
      this.updateProcedureFileManagementReturnInitDataListRow(
        fileNameObj,
        dataList,
        index
      );
    }
  };

  /**
   * click replace button in revise page
   * @param {*} e
   */
  replaceUpdateProcedureFileManagementRowFile = (e) => {
    e.target.nextSibling.click();
  };

  /* rollback for procedure file table replace success */
  _replaceUpdateProcedureFileManagementRowFileOnSuccess = (result, obj) => {
    this.props._hideLoading();
    const { index, fileName } = obj;
    const tableDataList = this.getDataList();
    tableDataList[index].data.find(
      (item) => item.name === "proposedChange"
    ).value = proposedChangeTags.update;
    tableDataList[index].data.find((item) => item.name === "fileId").value =
      result.storageFileID;
    tableDataList[index].data.find(
      (item) => item.name === "fileName"
    ).tagValue = fileTags.replaced;
    tableDataList[index].data.find((item) => item.name === "fileName").value =
      fileName;
    const replaceUserId = tableDataList[index].data.find((item) => item.name === "replaceUserId");
    const replaceUserName = tableDataList[index].data.find((item) => item.name === "replaceUserName");
    if(replaceUserId && replaceUserName) {
      tableDataList[index].data.find((item) => item.name === "replaceUserId").value = this.user.identityUserInfo.internalUserID;
      tableDataList[index].data.find((item) => item.name === "replaceUserName").value = this.user.identityUserInfo.englishName;
    } else {
      tableDataList[index].data.push({name: "replaceUserId", value: this.user.identityUserInfo.internalUserID});
      tableDataList[index].data.push({name: "replaceUserName", value: this.user.identityUserInfo.englishName});
    }
    tableDataList[index].isUpdate = true;
    tableDataList[index].isReturnDisabled = false;
    if (tableDataList[index].isError) {
      tableDataList[index].isError = false;
    }
    this.props.fileTableRef.current.updateDataList(tableDataList);
    this.enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton(
      tableDataList
    );
  };

  /* rollback for procedure file table replace error */
  _replaceUpdateProcedureFileManagementRowFileOnError = (error) => {
    console.log(error);
    this._alertError("Replace failed");
    this.props._hideLoading();
  };

  handleUpdateProcedureFileManagementRowReplaceFileDuplicate = (
    tableList,
    item
  ) => {
    tableList.forEach((data) => {
      if (data.data.find((i) => i.name === "fileName").value === item.name) {
        this._alertError(`${item.name} already exists.`);
        this.isFileDuplicate = true;
      }
    });
  };

  handleUpdateProcedureFileManagementRowReplaceFile = (returnPara, item) => {
    this.props._showLoading();
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(item);
    fileForm.append("formFile", item);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    ProcedureDetailService.addFile(
      fileForm,
      (response) => {
        this._replaceUpdateProcedureFileManagementRowFileOnSuccess(
          response,
          returnPara
        );
      },
      this._replaceUpdateProcedureFileManagementRowFileOnError
    );
  };

  /* function about procedure file table replace button */
  handleUpdateProcedureFileManagementRowReplaceFileSelect = (event, index) => {
    const files = [...event.target.files];
    const tableData = this.getDataList();
    const tableList = [...tableData];
    tableList.splice(index, 1);
    this.isFileDuplicate = false;
    for (let item of files) {
      this.handleUpdateProcedureFileManagementRowReplaceFileDuplicate(
        tableList,
        item
      );
      if (handleAlertUploadFileError(files)) {
        return;
      }
      const returnPara = { index: index, fileName: item.name };
      if (!this.isFileDuplicate) {
        this.handleUpdateProcedureFileManagementRowReplaceFile(
          returnPara,
          item
        );
      } else {
        return;
      }
    }
  };

  /**
   * control save, submit and cancel buttons disabled
   * @param {*} dataList
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   */
  enableOrDisableUpdateProcedureFileManagementSaveSubmitCancleButton = (
    dataList,
    clientServerPath,
    procedureSavedInValue = this.state.savedInRadioValue
  ) => {
    this.clearFileTableStatus();
    const isCancelDisable = this.isCancelDisable(
      dataList,
      clientServerPath,
      procedureSavedInValue
    );
    let isSubmitDisabled = true;
    dataList.forEach((dataItem) => {
      const proposedChangeItem = dataItem.data.find(
        (item) => item.name === "proposedChange"
      );
      if (proposedChangeItem.value !== "") {
        isSubmitDisabled = false;
      }
    });
    this.setState({
      cancelDisabled: isCancelDisable,
      submitDisabled: isSubmitDisabled,
    });
  };

  updateSubmitCheck = (procedureMandatoryCheck) => {
    const shouldShowErrorSystem =
      this.state.savedInRadioValue !== savedInRadioValues.systemOnly &&
      this.state.savedInRadioValue !== null &&
      this.state.clientPath.trim() === "";

    const fileList = this.getDataList();
    let isFileAllDelete = true;
    fileList.forEach(file => {
      const proposedChange = file.data.find(item => item.name === "proposedChange").value;
      if(proposedChange !== proposedChangeTags.delete) {
        isFileAllDelete = false;
      }
    });

    const shouldShowErrorServer =
      this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
      this.state.savedInRadioValue !== null &&
      (fileList.length === 0 || isFileAllDelete)

    const shouldShowErrorRevisionInfo = this.checkRevisionInfoError();

    if (
      shouldShowErrorServer ||
      shouldShowErrorSystem ||
      shouldShowErrorRevisionInfo
    ) {
      procedureMandatoryCheck();
    } else {
      this.handleReturnSubmitDoubleCheckTipModal();
    }
  }

  /**
   * check whether has mandatory field not filled in update procedure file section
   * @returns boolean
   */
  checkRevisionInfoError = () => {
    if (
      this.props.revisionInfoRef !== undefined &&
      this.props.revisionInfoRef.current !== null
    ) {
      const {
        currentCategory,
        currentRequestDate,
        currentRequestFrom,
        currentClientValue,
        currentRspValue,
        currentDesciption,
      } = this.props.revisionInfoRef.current.state;
      return (
        currentCategory === null ||
        currentRequestDate === null ||
        currentRequestFrom === null ||
        (currentRequestFrom === revisionFromList.Client &&
          (currentClientValue === null || currentClientValue.trim() === "")) ||
        (currentRequestFrom === revisionFromList.RSP &&
          currentRspValue === null) ||
        currentDesciption === null ||
        currentDesciption.trim() === ""
      );
    }
  };

  handleReturnSubmitDoubleCheckTipModal = () => {
    const currentDataList = this.getDataList();
    const fileJBStatusList = currentDataList.map(file => {
      const JBvalue = file.data.find(item => item.name === 'jbProcedure')?.value;
      const proposedChange = file.data.find(item => item.name === 'proposedChange')?.value;
      return proposedChange === proposedChangeTags.delete ? "" : JBvalue
    });
    const isExistJBFile = fileJBStatusList.includes('JB');
    const isProcedureJB = this.props.procedureStatusJB === procedureJbStatusMap['JB'];
    const isClientServerJB = (this.state.savedInRadioValue === savedInRadioValues.systemAndClientServer
      || this.state.savedInRadioValue === savedInRadioValues.serverOnly)
      && this.props.clientServerJB === procedureJbStatusMap['JB'];

    if (isProcedureJB && !isExistJBFile && !isClientServerJB && this.state.savedInRadioValue) {
      this.setState({
        isModalOpen: true,
        modalChildContent:
          <span>
            Are you sure to submit? There is no JB procedure and the <b>Procedure Status</b> will be changed to <b>Non JB</b>.<br/>
            Please ensure that the updates made in the Procedure File section are consistent with the selected 
            revision category, especially for "Procedure Storage Change".
          </span>,
        handleModalConfirm: this.props.handleSubmitModalConfirm,
      });
    } else {
      this.props.onSubmit();
    }
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  /**
   * return save, submit and cancel buttons in table
   * @param {*} isShowActionBtns flag of whether show these buttons
   * @param {*} saveDisabled flag of whether disable save button
   * @param {*} onSave method for save
   * @param {*} submitDisabled flag of whether disable submit button
   * @param {*} onSubmit method for submit
   * @param {*} cancelDisabled flag of whether disable cancel button
   * @param {*} onCancel method for cancel
   * @returns
   */
  handleReturnActionBtns = (obj) => {
    const { onCancel, procedureMandatoryCheck } = obj;
    
    return (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" disabled={false} size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={false}
          size="middle"
          onClick={() => this.updateSubmitCheck(procedureMandatoryCheck)}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };

  /**
   * function about return table columns
   * @param {object} obj
   * @returns
   */
  handleReturnColumns = (obj) => {
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: !this.props.inModal,
      },
      {
        name: "Proposed Change",
        dataName: "proposedChange",
        type: "tag",
        isShow: this.props.inModal,
      },

      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow:
          this.state.savedInRadioValue !== savedInRadioValues.serverOnly &&
          !this.props.inModal,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Replace",
        returnText: "Delete",
        approveColor: "blue",
        returnColor: "greyRed",
        onApproveClick: this.replaceUpdateProcedureFileManagementRowFile,
        onReturnClick: () => this.deleteUpdateProcedureFileManagementRowFile,
        isShow: this.props.inModal,
      },
    ];
  };

  /**
   * function about return table
   * @param {*} obj
   * @returns
   */
  handleReturnProcedureFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      hasSubmit,
      isScrollData,
      className,
      optionList,
      handleReturnClick,
      currentRole,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      fileTableStatus,
      isShowHistoryVersion,
    } = obj;
    return className === "procedureFiles" ? (
      <UpdateProcedureFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={
          this.handleUpdateProcedureFileManagementRowReplaceFileSelect
        }
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        isShowHistoryVersion={isShowHistoryVersion}
        fileTableStatus={fileTableStatus}
      />
    ) : (
      <UpdateProcedureFileModalTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        handleFileSelect={
          this.handleUpdateProcedureFileManagementRowReplaceFileSelect
        }
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        fileTableStatus={fileTableStatus}
      />
    );
  };
}
