import React, { Component, createRef } from "react";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import ReasonLogIcon from "../reasonLogIcon";
import HistoricalVersionModal from "./historicalVersionModal";
import NormalToolTip from "../toolTip";

const modalRef = createRef();
export default class HistoricalVersionComponent extends Component {
  /**
   * Success callback for getHistoricalFiles api
   */
  getHistoricalVersionSuccess = (response) => {
    if (modalRef.current !== null) {
      modalRef.current.handleSetVersionData(response);
      modalRef.current.handleChangeVersionModal(response);
    }
  };

  /**
   * Error callback for getHistoricalFiles api
   */
  getHistoricalVersionError = (error) => {
    console.log(error);
    this._alertError("Get Historcal Version Failed!");
  };

  /**
   * Get history version data from api
   * @param {string} fileId
   * @param {string} fileKey
   * @param {string} businessId
   */
  handleGetHistoricalVersion = (fileId, fileKey, businessId) => {
    const params = {
      FileKey: fileKey,
      FileID: fileId,
      FlowBusinessID: businessId,
    };
    ProcedureDetailService.getHistoricalFiles(
      params,
      (response) => this.getHistoricalVersionSuccess(response),
      this.getHistoricalVersionError
    );
  };

  render() {
    const { fileId, fileKey, flowBusinessId } = this.props;
    return (
      <>
        <NormalToolTip
          element={
            <div>
              <ReasonLogIcon
                className="historicalVersionIcon"
                onClick={() =>
                  this.handleGetHistoricalVersion(fileId, fileKey, flowBusinessId)
                }
              />
            </div>
          }
          title="View History Version"
          placement="right"
          color="#3E6CB5"
        />
        <HistoricalVersionModal
          handleCloseVersionModal={this.handleCloseVersionModal}
          ref={modalRef}
        />
      </>
    );
  }
}
