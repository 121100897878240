import React, { createRef } from "react";
import moment from "moment";
import NormalDatePicker from "../../../../../../base/basicComponents/datePicker";
import {
  ExpendTextArea,
  NormalTextArea,
} from "../../../../../../base/basicComponents/input";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../../base/basicComponents/select";
import { RevisionInfoUploadFile } from "../../../../../../base/basicComponents/uploadFile";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import ProcedureDetailService from "../../../../../../service/procedureFile/ProcedureDetailService";
import {
  getDateString,
  getTimeZoneString,
  revisionCategories,
  revisionFromList,
  sortSelectOptionList,
  getRequestDateMoment,
} from "../../../../../publicDictionaryValues";
import styles from "./index.module.scss";
import redTipIcon from "../../../../../../assets/image/redTipIcon.png";
import NormalToolTip from "../../../../../../base/basicComponents/toolTip";

const attachmentRef = createRef();
const attachmentInputRef = createRef();
export default class DetailRevisionInformation extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentCategory: null,
      currentRequestDate: null,
      currentRequestFrom: null,
      currentDesciption: null,
      currentAttachment: [],
      attachmentIds: [],
      attachmentNames: [],
      currentClientValue: null,
      currentRspValue: null,
      categoryOptionList: [],
      requestFromOptionList: [],
      clientMemberList: [],
      deleteIds: [],
      categoryStatus: null,
      dateStatus: null,
      fromStatus: null,
      clientValueStatus: null,
      rspValueStatus: null,
      discriptionStatus: null,
      initRevisionInfoState: null,
      isRequestDateChange: false,
    };
    this.isShowClientList = [
      revisionCategories.clientRequested,
      revisionCategories.systemTransition,
    ];
    this.isShowRSPList = [
      revisionCategories.rspSuggested,
      revisionCategories.procedureRefinement,
    ];
  }

  componentDidMount() {
    if (
      this.props.revisionInfoData !== null &&
      this.props.revisionInfoData !== undefined
    ) {
      const {
        revisionCategory,
        requestDate,
        requestFrom,
        revisionDescription,
        requestFromDesc,
        attachmentIds,
        attachmentNames,
        attachmentFiles,
      } = this.props.revisionInfoData;
      this.setState({
        currentCategory: revisionCategory,
        currentRequestDate:  requestDate === '0001-01-01T00:00:00' ? null : moment(requestDate),
        currentRequestFrom: requestFrom,
        currentDesciption: revisionDescription,
        currentClientValue:
          requestFrom === revisionFromList.Client ? requestFromDesc : null,
        currentRspValue:
          requestFrom === revisionFromList.RSP ? requestFromDesc : null,
        attachmentIds: attachmentIds,
        attachmentNames: attachmentNames,
        currentAttachment: [...attachmentFiles],
        initRevisionInfoState:
          this.props.initRevisionInfoState !== undefined
            ? this.props.initRevisionInfoState
            : null,
      });
      this.handleReturnRequestFromListFromProps(revisionCategory);
    }

    ProcedureDetailService.getRevisionCategory(
      {},
      (response) => {
        const newList = [];
        response.forEach((item) =>
          newList.push({ value: item.id, name: item.name })
        );
        this.setState({ categoryOptionList: newList });
      },
      () => {
        this._alertError("Get revision category failed!");
      }
    );
    ProcedureDetailService.getClientMembers(
      { accountId: this.props.accountId },
      (response) => {
        const newList = [];
        response.forEach((item) =>
          newList.push({ value: item.id, name: item.name })
        );
        this.setState({ clientMemberList: newList });
      },
      () => {
        this._alertError("Get client members failed!");
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state) !== JSON.stringify(prevState) &&
      this.props.initRevisionInfoState !== undefined
    ) {
      this.handleCheckIsRevisionInfoChange();
    }
  }

  /**
   * function about check whether revision information has changed
   */
  handleCheckIsRevisionInfoChange = () => {
    const {
      revisionCategory,
      requestDate,
      requestFrom,
      revisionDescription,
      requestFromDesc,
      attachmentFiles,
    } = this.props.initRevisionInfoState;
    const {
      currentCategory,
      currentRequestDate,
      currentRequestFrom,
      currentDesciption,
      currentClientValue,
      currentRspValue,
      currentAttachment,
      deleteIds,
    } = this.state;
    const currentRequestFromDesc =
      currentClientValue !== null ? currentClientValue : currentRspValue;
    const isChange =
      revisionCategory !== currentCategory ||
      JSON.stringify(moment(requestDate)) !==
        JSON.stringify(currentRequestDate) ||
      requestFrom !== currentRequestFrom ||
      revisionDescription !== currentDesciption ||
      requestFromDesc !== currentRequestFromDesc ||
      JSON.stringify(attachmentFiles) !== JSON.stringify(currentAttachment) ||
      deleteIds.length > 0;
    if (isChange) {
      this.props.setIsRevisionInfoChange();
    } else {
      this.props.cancelIsRevisionInfoChange();
    }
  };

  handleChangeDeleteIds = (id) => {
    this.setState({ deleteIds: [...this.state.deleteIds, id] });
  };

  handleRequestDateChange = (date) => {
    this.setState({
      currentRequestDate: date,
      isRequestDateChange: true,
      dateStatus: null,
    });
  };

  handleReturnRequestFromListFromProps = (value) => {
    if (this.isShowRSPList.includes(value)) {
      this.setState({
        requestFromOptionList: [{ value: revisionFromList.RSP, name: "RSP" }],
      });
    } else if (this.isShowClientList.includes(value)) {
      this.setState({
        requestFromOptionList: [
          { value: revisionFromList.Client, name: "Client" },
        ],
      });
    } else {
      this.setState({
        requestFromOptionList: [
          { value: revisionFromList.Client, name: "Client" },
          { value: revisionFromList.RSP, name: "RSP" },
        ],
      });
    }
  };

  handleReturnRequestFromList = (value) => {
    if (this.isShowRSPList.includes(value)) {
      this.setState({
        currentRequestFrom: revisionFromList.RSP,
        requestFromOptionList: [{ value: revisionFromList.RSP, name: "RSP" }],
      });
    } else if (this.isShowClientList.includes(value)) {
      this.setState({
        currentRequestFrom: revisionFromList.Client,
        requestFromOptionList: [
          { value: revisionFromList.Client, name: "Client" },
        ],
      });
    } else {
      this.setState({
        currentRequestFrom: null,
        requestFromOptionList: [
          { value: revisionFromList.Client, name: "Client" },
          { value: revisionFromList.RSP, name: "RSP" },
        ],
      });
    }
  };

  handleCategoryChange = (e) => {
    this.setState({
      currentCategory: e,
      categoryStatus: null,
      fromStatus: null,
      rspValueStatus: null,
      clientValueStatus: null,
    });
    this.handleReturnRequestFromList(e);
  };

  handleRequestFromChange = (e) => {
    this.setState({
      currentRequestFrom: e,
      fromStatus: null,
      rspValueStatus: null,
      clientValueStatus: null,
    });
  };

  handleDescriptionChange = (e) => {
    this.setState({
      currentDesciption: e.target.value,
      discriptionStatus: null,
    });
  };

  handleClientTextChange = (e) => {
    this.setState({
      currentClientValue: e.target.value,
      clientValueStatus: null,
    });
  };

  handleRspValueChange = (e) => {
    this.setState({
      currentRspValue: e,
      rspValueStatus: null,
    });
  };

  handleDisabledDate = (current) => {
    const currentDate = getRequestDateMoment();

    return current > currentDate.endOf('day');
  };

  handleAttachmentChange = (list) => {
    const newList = [];
    list.forEach((item) =>{
      const id = this.state.currentAttachment.find(file => file.fileName === item.name)?.fileId;
      newList.push({
        fileId: item.storageFileID || id,
        fileName: item.name,
      })
    });
    this.setState({ currentAttachment: newList });
  };

  handleSetAttachmentFromProps = (list) => {
    this.setState({ currentAttachment: list });
  };

  setCategoryStatus = (status) => {
    this.setState({ categoryStatus: status });
  };

  setDateStatus = (status) => {
    this.setState({ dateStatus: status });
  };

  setFromStatus = (status) => {
    this.setState({ fromStatus: status });
  };

  setClientValueStatus = (status) => {
    this.setState({ clientValueStatus: status });
  };

  setRspValueStatus = (status) => {
    this.setState({ rspValueStatus: status });
  };

  setDescriptionStatus = (status) => {
    this.setState({ discriptionStatus: status });
  };

  handleOptionList = (inputValue, option) =>
    (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

  revisionMessage = () => {
    return (
      <div className={styles.messageWrapper}>
        <div className={styles.title}>
          The system only supports submitting change(s) for one revision category at a time. For change(s) in multiple revision categories, please submit them separately.
        </div>
      </div>
    );
  };

  revisionMessageIcon = () => {
    const isProcedureDetailPage = window.location.href.toLowerCase().indexOf("/proceduredetail");
    if(isProcedureDetailPage > -1)
      return (
        <NormalToolTip
          element={
            <div className={styles.disabledTipIcon}>
              <img src={redTipIcon} alt="Reject" />
            </div>
          }
          title={this.revisionMessage()}
          color="#FFFFFF"
          overlayStyle={{ minWidth: "29.3rem" }}
          placement="right"
        />
      );
  };

  render() {
    const { title } = this.props;

    const {
      currentCategory,
      currentRequestDate,
      currentRequestFrom,
      currentDesciption,
      currentClientValue,
      currentRspValue,
      requestFromOptionList,
      categoryOptionList,
      categoryStatus,
      dateStatus,
      fromStatus,
      clientValueStatus,
      rspValueStatus,
      discriptionStatus,
      clientMemberList,
      attachmentIds,
      attachmentNames,
      currentAttachment,
    } = this.state;

    const { isDisabledAll } = this.props;

    return (
      <div className={styles.revisionInformation}>
        <div className={styles.revisionInfoTop}>
          <div className={styles.revisionTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        <div className={styles.revisionContent}>
          <div className={styles.revisionLeft}>
            <div className={styles.revisionItem}>
              <InputLabel
                text="Revision Category"
                className={styles.revisionLabel}
                required={true}
              />
              {this.revisionMessageIcon()}
              <NormalSelect
                value={currentCategory}
                optionList={categoryOptionList}
                handleChange={this.handleCategoryChange}
                status={categoryStatus}
                className={styles.revisionCategory}
                disabled={isDisabledAll}
              />
            </div>
            <div className={styles.revisionItem}>
              <InputLabel
                text="Request Date"
                className={styles.revisionLabel}
                required={true}
              />
              <NormalDatePicker
                value={currentRequestDate}
                onChange={this.handleRequestDateChange}
                disabledDate={this.handleDisabledDate}
                status={dateStatus}
                disabled={isDisabledAll}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                allowClear={false}
              />
            </div>
            <div className={styles.revisionItem}>
              <InputLabel
                text="Request From"
                className={styles.revisionLabel}
                required={true}
              />
              <NormalSelect
                value={currentRequestFrom}
                optionList={requestFromOptionList}
                handleChange={this.handleRequestFromChange}
                className={styles.fromSelect}
                status={fromStatus}
                disabled={isDisabledAll}
              />
              {currentRequestFrom === revisionFromList.Client && (
                <ExpendTextArea
                  value={currentClientValue}
                  onChange={this.handleClientTextChange}
                  status={clientValueStatus}
                  disabled={isDisabledAll}
                />
              )}
              {currentRequestFrom === revisionFromList.RSP && (
                <NormalSelect
                  value={currentRspValue}
                  optionList={sortSelectOptionList(clientMemberList)}
                  className={styles.rspSelect}
                  status={rspValueStatus}
                  handleChange={this.handleRspValueChange}
                  disabled={isDisabledAll}
                  showSearch={true}
                  handleFilterOption={this.handleOptionList}
                />
              )}
            </div>
            <div className={styles.revisionItem}>
              <InputLabel
                text="Revision Description"
                className={styles.revisionLabel}
                required={true}
              />
              <NormalTextArea
                value={currentDesciption}
                width="31.8125"
                onChange={this.handleDescriptionChange}
                disabled={isDisabledAll}
                status={discriptionStatus}
              />
            </div>
          </div>
          <div className={styles.revisionRight}>
            <div
              className={`${styles.revisionAttachment} ${styles.revisionItem}`}
            >
              <InputLabel
                text="Attachment"
                className={styles.revisionAttachmentLabel}
              />
              <RevisionInfoUploadFile
                isMultiple={true}
                className={styles.attachment}
                isShowDeleteBtn={!isDisabledAll}
                ref={attachmentRef}
                fileInputRef={attachmentInputRef}
                handleFileListChange={this.handleAttachmentChange}
                handleChangeDeleteIds={this.handleChangeDeleteIds}
                openLoading={this.props.openLoading}
                hideLoading={this.props.hideLoading}
                fileNameType="link"
                isDownload={true}
                fileIds={attachmentIds}
                fileNames={attachmentNames}
                updateFileNumLoading={this.props.updateFileNumLoading}
                files={currentAttachment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
