/* eslint-disable react/jsx-no-undef */
import RoutingComponent from "../../common/RoutingComponent";
import RouteStorage from "../../../utils/store/RouteStorage";
import RouteModel from "../../../utils/model/RouteModel";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Layout from "../../page/Layout";
import appService from "../../../service/app";
import { RoutingMap, userRoles } from "../../../app/publicDictionaryValues";
import AppSetting from "../../../config/AppSetting";

export default class Router extends RoutingComponent {
  constructor(props) {
    super(props);
    
    this.routeStorage = new RouteStorage();
    this.state = { route: []};
  }

  getRoutingPageName = () => {
    const pathname = window.location.pathname;
    const pageName = pathname.split('/')[1];
    return pageName;
  }

  getRoutingID = () => {
    const pathname = window.location.pathname;
    const pathParams = pathname.split('/');
    const routeName = pathParams[1]?.toLowerCase();

    const routing = RoutingMap[routeName];
    const routingID = routing.routingID;

    const routingParams = [];
    routing.routingParams.forEach((item,index) => {
      routingParams[item] = pathParams[index+2];
    })

    return {
      routing: routingID,
      ...routingParams
    };
  }

  handleUserRole = (roleModel) => {
    if (roleModel?.length === undefined || roleModel?.length === 0) {
      return false;
    }
    return roleModel.some(
      (x) => x.roleID === userRoles.OpExAndTOO || x.roleID === userRoles.Opex
    );
  };

  _checkPagePermissionSuccess = (response) => {
    this._hideLoading();
    const { result, pagePermissionReminder } = response.data;
    if(result) return;
    if(pagePermissionReminder === 1) {
      window.location = "/NoPermissionPage/noAccess";
    } else if (pagePermissionReminder === 2) {
      window.location = "/NoPermissionPage/needRefresh";
    }
  }

  checkPagePermission = async () => {
    const isOpex = this.handleUserRole(AppSetting.localStorageValue.role.get());
    const params = this.getRoutingID();

    const checkParams = {
      isOpex,
      ...params
    }

    await appService.getPagePermission(
      checkParams,
      this._checkPagePermissionSuccess,
      () => this._alertError('Check Page Permission Failed'),
    )
  }

  async componentDidMount() {
    this._showLoading();
    const pageName = this.getRoutingPageName()?.toLowerCase();
    const pageNameArr = Object.keys(RoutingMap);
    if(pageNameArr.includes(pageName)) {
      await this.checkPagePermission();
    }
    this._loadRoute();
  
  }

  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>{this.state.route}</Switch>
        </Layout>
      </BrowserRouter>
    );
  }

  _loadRoute() {
    const routes= AppSetting.localStorageValue.routes.get();
    if(routes!==undefined && routes.length>0){
      this.setState({ route: this._setRouteElement(routes) });
      this._hideLoading();
      return;
    }
    
    appService.getRouting(
      this.appSetting.localStorageValue.user.get(),
      this._onSuccess,
      this._onError
    );
  }

  _onSuccess = (response) => {
    let routeModels = this._formatRouteData(response);
    let routeElement = this._setRouteElement(routeModels);

    AppSetting.localStorageValue.routes.set(routeModels)
    this.setState({ route: routeElement });
  };

  _formatRouteData(response) {
    let route = [];
    response.forEach((element) => {
      route.push(new RouteModel(element));
    });
    return route;
  }

  _setRouteElement(routeModels) {
    let elements = [];
    routeModels.forEach((e) => {
      const ItemComponent = this.routeStorage.getRouteComponent(e.key);
      elements.push(
        <Route
          key={e.key}
          exact={true}
          path={e.url}
          component={ItemComponent}
          {...e.param}
        ></Route>
      );
    });

    elements.push(
      <Redirect key="defaultPage" to={this.appSetting.defaultPage} />
    );
    return elements;
  }

  _onError = (error) => {
    const errorObj = {error};
    const errorCode = errorObj.error.message;
    if(errorCode === '401') {
      this.appSetting.localStorageValue.setUnauthenticated();
      window.location.href = "/login";
    } else {
      this.setError(true, error);
    }
  };
}
