import UpdateSupportingFileModalTbody from "../../supportingFileTbody/updateSupportingFileModalTbody";
import UpdateSupportingFileTable from "../updateSupportingFileTable";

export default class UpdateSupportingFileModalTable extends UpdateSupportingFileTable {
  handleReturnTbody = (obj) => {
    const {
      data,
      columns,
      inputOnChange,
      procedureNoteMaxLength,
      fileSectionDisabled,
      procedureNoteSize,
      hasSubmit,
      fileTableRef,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      isRevisePage,
      downloadFile,
      isSupportingFile,
      isExpendData,
    } = obj;
    return (
      <UpdateSupportingFileModalTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        fileSectionDisabled={fileSectionDisabled}
        procedureNoteSize={procedureNoteSize}
        hasSubmit={hasSubmit}
        fileTableRef={fileTableRef}
        optionList={optionList}
        isHasReturnReason={isShowReturnReason}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        isRevisePage={isRevisePage}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        handleChangeTableHeight={this.handleChangeTableHeight}
        setIsExpendNote={this.setIsExpendNote}
        isExpendData={isExpendData}
      />
    );
  };
}
