import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ApplyPMPExcludedStepService extends StepBaseService {
  getPrimaryProcedureOwnerStepKey = () => {
    return StepBusinessType.primaryProcedureOwner;
  };

  getProcedureOwnerStepKey = () => {
    return StepBusinessType.procedureOwner;
  };

  getPMPExcludedStepKey = () => {
    return {
      dataKey: StepBusinessType.pmpExcluded.dataKey,
      settingKey: "getApplyPmpExcludedStepItem",
    };
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };
}
export default new ApplyPMPExcludedStepService();
