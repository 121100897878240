import EllipsisToolTip from "../../../../../../base/basicComponents/ellipsisToolTip";
import { FileStatusDiv } from "../../../../../../base/basicComponents/status";
import UpdateSupportingFileTbody from "../updateSupportingFileTbody";

export default class UpdateSupportingFileModalTbody extends UpdateSupportingFileTbody {
  returnFileName = (rowColumn) => {
    const popupContainer = document.getElementsByClassName("procedureModalFiles")[0] || document.getElementsByClassName("supportingModalFiles")[0]
    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
          getPopupContainer={() => popupContainer}
        />
        {rowColumn.tagValue !== null && (
          <FileStatusDiv status={rowColumn.tagValue} />
        )}
      </div>
    );
  };
}
