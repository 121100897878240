import React from "react";
import { Radio } from "antd";
import { savedInRadioValues } from "../../../publicDictionaryValues";
import BaseComponent from "../../../../base/common/BaseComponent";
import styles from "./index.module.scss";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import NormalToolTip from "../../../../base/basicComponents/toolTip";

export default class BaseProcedureSavedIn extends BaseComponent {
  savedInMessage = () => {
    return (
      <div className={styles.messageWrapper}>
        <div className={styles.title}>
          Attention! If the "Procedure Saved In" value is changed to "Client Server Only", 
          all procedure files will be deleted upon submission. Please double check whether you want to make the change.
        </div>
      </div>
    );
  };

  savedInMessageIcon = () => {
    if(this.props.isShowSavedInIcon)
      return (
        <NormalToolTip
          element={
            <div className={styles.disabledTipIcon}>
              <img src={redTipIcon} alt="Reject" />
            </div>
          }
          title={this.savedInMessage()}
          color="#FFFFFF"
          overlayStyle={{ minWidth: "29.8rem" }}
          placement="right"
        />
      );
  };

  render() {
    const { handleRadioChange, handleRadioClick, savedInRadioValue, disabled } =
      this.props;
    return (
      <div className={styles.savedInContainer}>
        <div className={styles.savedInLabel}>
          <div className={styles.procedureSaveIn}>Procedure Saved In</div>
          {this.savedInMessageIcon()}
        </div>
        <div className={styles.radioBLock}>
          <Radio.Group
            onChange={(e) => handleRadioChange(e)}
            value={savedInRadioValue}
            defaultValue={savedInRadioValue}
            disabled={disabled}
          >
            <Radio
              value={savedInRadioValues.systemOnly}
              onClick={(e) => handleRadioClick(e)}
            >
              PMP System Only
            </Radio>
            <Radio
              value={savedInRadioValues.systemAndClientServer}
              onClick={(e) => handleRadioClick(e)}
            >
              PMP System & Client Server
            </Radio>
            <Radio
              value={savedInRadioValues.serverOnly}
              onClick={(e) => handleRadioClick(e)}
            >
              Client Server Only
            </Radio>
          </Radio.Group>
        </div>
      </div>
    );
  }
}
