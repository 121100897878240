import BaseFileManagement from "../baseFileManagement";

export default class ReadOnlyFileManagement extends BaseFileManagement {
  getValue = () => {
    return this.props;
  };

  downloadFile = () => {
    this._showDownloadLoading();
    this.baseDownloadFiles(this.downloadDetailFilesSuccessfully);
  };
}
