import BaseComponent from "../../common/BaseComponent";
import { Divider, Select, Space, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Buttons from "../button";
import ReadOnlyText from "../readOnly-text";
import TopFilterBase from "../../../app/commonComponents/listBase/TopFilterBase";
import "./index.scss";
import { createRef } from "react";
const scrollSelectRef = createRef();

export default class NormalSelect extends BaseComponent {
  render() {
    const {
      optionList,
      placeholder,
      placement = "bottomLeft",
      disabled = false,
      handleChange,
      handleSelect,
      handleDeSelect,
      handleSearch,
      handleFilterOption,
      handleFocus,
      defaultValue,
      mode,
      maxTagCount,
      showArrow,
      showSearch,
      className = "",
      value,
      status,
      handleMouseEnter,
      loading,
      getPopupContainer,
      onDropdownVisibleChange,
      open,
      dropdownStyle,
      isNeedSelectAll,
      handleSelectAll,
      handleDeselectAll,
    } = this.props;

    return (
      <Select
        dropdownStyle={dropdownStyle}
        value={value}
        className={`${status} customSelectCompnent ${className}`}
        defaultValue={defaultValue}
        onChange={handleChange}
        placeholder={placeholder}
        placement={placement}
        disabled={disabled}
        mode={mode}
        maxTagCount={maxTagCount}
        showArrow={showArrow}
        showSearch={showSearch}
        onSelect={handleSelect}
        onDeselect={handleDeSelect}
        onSearch={handleSearch}
        onFocus={handleFocus}
        filterOption={handleFilterOption}
        onMouseEnter={handleMouseEnter}
        loading={loading}
        getPopupContainer={
          getPopupContainer
            ? getPopupContainer === "null"
              ? undefined
              : getPopupContainer
            : (triggerNode) => triggerNode.parentElement
        }
        onDropdownVisibleChange={onDropdownVisibleChange}
        open={open}
        dropdownRender={(menu) => (
          <>
            {isNeedSelectAll && (
              <>
                <div className="selectWithButtonButtonDiv">
                  <div
                    className="selectAllButton"
                    onClick={handleSelectAll}
                  >
                    Select All
                  </div>
                  <div
                    className="selectAllButton"
                    onClick={handleDeselectAll}
                  >
                    Deselect All
                  </div>
                </div>
                <Divider/>
              </>
            )}
            {menu}
          </>
        )}
      >
        {optionList.map((item, idx) => (
          <Select.Option
            value={item.value}
            key={`${item.value}${idx}`}
            title={item.name}
            className="customSelectOption"
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export class ScrollSelect extends BaseComponent {
  render() {
    const {
      optionList,
      placeholder,
      placement = "bottomLeft",
      disabled = false,
      handleChange,
      handleSelect,
      handleDeSelect,
      handleSearch,
      handleFilterOption,
      handleFocus,
      defaultValue,
      mode,
      maxTagCount,
      showArrow,
      showSearch,
      className = "",
      value,
      status,
      handleMouseEnter,
      loading,
      key,
    } = this.props;

    return (
      <Select
        key={key}
        value={value}
        className={`${status} customScrollSelectComponent ${className}`}
        defaultValue={defaultValue}
        onChange={handleChange}
        placeholder={placeholder}
        placement={placement}
        disabled={disabled}
        mode={mode}
        maxTagCount={maxTagCount}
        showArrow={showArrow}
        showSearch={showSearch}
        onSelect={handleSelect}
        onDeselect={handleDeSelect}
        onSearch={handleSearch}
        onFocus={handleFocus}
        filterOption={handleFilterOption}
        onMouseEnter={handleMouseEnter}
        loading={loading}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        {optionList.map((item, idx) => (
          <Select.Option
            value={item.value}
            key={`${item.value}${idx}`}
            title={item.name}
            className="customSelectOption"
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

const { sortList, handleFilterOption } = TopFilterBase();
export class SelectWithButton extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      optionList: this.props.optionList,
      selectValue: this.props.value,
      open: false,
      focusOption: "",
      loading: false,
      disabled: this.props.isDisabled,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectValue: this.props.value,
      });
    }
    if (
      JSON.stringify(prevProps.optionList) !==
      JSON.stringify(this.props.optionList)
    ) {
      this.setState({ optionList: this.props.optionList });
    }
    if (
      prevProps.isDisabled !== this.props.isDisabled ||
      this.props.value !== prevProps.value
    ) {
      this.setState({
        disabled: this.props.isDisabled,
        open: false,
      });
    }
  }

  closeOptionList = () => {
    this.setState({
      ...this.state,
      open: false,
      focusOption: "",
      optionList: [],
      loading: false,
    });
  };

  _getOptionListSuccess = (result) => {
    const list = result;
    const newList = list.map((item) => {
      return {
        name: item.name,
        value: item.id,
      };
    });
    this.setState({
      optionList: newList.sort((s, t) => sortList(s, t)),
      open: true,
      loading: false,
    });
    scrollSelectRef.current.focus();
  };

  _getOptionListFailed = (msg) => {
    message.error(msg);
  };

  _submitSuccess = (response) => {
    if (!response.succeeded) {
      message.error(response.message);
      this.closeOptionList();
    } else {
      this.props.onDropdownVisibleChange(
        this.props.taskId,
        this._getOptionListSuccess,
        this._getOptionListFailed
      );
      const list = [...this.props.dataList];
      list.find(
        (item) => item.procedureTaskId === this.props.taskId
      ).procedureId = response.data.procedureID;
      list.find(
        (item) => item.procedureTaskId === this.props.taskId
      ).primaryProcedureOwner = response.data.primaryProcedureOwnerID;
      list.find(
        (item) => item.procedureTaskId === this.props.taskId
      ).primaryProcedureOwnerName = this.state.optionList.find(
        (item) => item.value === response.data.primaryProcedureOwnerID
      )?.name;
      this.props.setProcedureIdToList(list);
      this.setState({
        loading: false,
        disabled: true,
        open: false,
        focusOption: "",
        optionList: [],
      });
    }
  };

  _submitFaild = (msg) => {
    message.error(msg);
    this.closeOptionList();
  };

  render() {
    const {
      placeholder,
      cancelDisabled,
      showSearch,
      onSubmit,
      onDropdownVisibleChange,
      taskId,
      getPopupContainer,
    } = this.props;

    const { selectValue, open, focusOption, optionList, loading, disabled } =
      this.state;

    return disabled ? (
      <ReadOnlyText
        value={optionList.find((item) => item.value === selectValue)?.name}
        width="10.5rem"
        className="readOnlyInput"
      />
    ) : (
      <Select
        ref={scrollSelectRef}
        style={{
          width: 165,
        }}
        value={selectValue}
        defaultValue={null}
        placeholder={placeholder}
        showSearch={showSearch}
        filterOption={(input, option) => handleFilterOption(input, option)}
        open={open}
        loading={loading}
        disabled={selectValue === null || selectValue.trim()}
        onChange={() => this.setState({ open: true })}
        getPopupContainer={getPopupContainer}
        onBlur={() => {
          this.closeOptionList();
        }}
        autoFocus={false}
        popupClassName="selectWithButtonPopUp"
        onDropdownVisibleChange={(open) => {
          if (open) {
            this.setState({ loading: true });
            onDropdownVisibleChange(
              taskId,
              this._getOptionListSuccess,
              this._getOptionListFailed
            );
          }
        }}
        dropdownMatchSelectWidth={false}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <div className="selectWithButtonButtonDiv">
              <Buttons
                color="grey"
                disabled={cancelDisabled}
                size="small"
                onMouseDown={() => {
                  this.setState({
                    focusOption: "",
                    open: false,
                    optionList: [],
                  });
                }}
              >
                Cancel
              </Buttons>
              <Buttons
                color="blue"
                disabled={focusOption.trim() === ""}
                size="small"
                className="confirmBtn"
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.setState({ loading: true, open: false });
                  onSubmit(
                    {
                      primaryProcedureOwnerId: focusOption,
                      procedureTaskId: taskId,
                    },
                    this._submitSuccess,
                    this._submitFaild
                  );
                }}
              >
                Confirm
              </Buttons>
            </div>
          </div>
        )}
      >
        {optionList.map((item) => {
          return (
            <Select.Option
              value={item.value}
              key={item.value}
              title={item.name}
              className={`${
                focusOption === item.value ? "focusOption" : ""
              } selectWithBtnOption customSelectOption ${item.value}`}
              onMouseDown={() => {
                const list = [...this.state.optionList];
                let newOptionList = [];
                list.forEach((listItem) => {
                  if (listItem.value !== item.value) {
                    newOptionList.push(listItem);
                  }
                });
                newOptionList.sort((s, t) => sortList(s, t)).unshift(item);
                this.setState({
                  focusOption: item.value,
                  optionList: newOptionList,
                  open: true,
                });
                const selectOptionContainers = document.getElementsByClassName(
                  "rc-virtual-list-holder"
                );
                Array.from(selectOptionContainers).forEach(
                  (item) => (item.scrollTop = 0)
                );
              }}
            >
              <div className="optionItem">
                <span data_id={item.value}>{item.name}</span>
                {focusOption === item.value && (
                  <CheckOutlined className="checkIcon" />
                )}
              </div>
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
