import AppSetting from "../../config/AppSetting";
import RequestOption from "../../utils/rest/RequestOption";
import RestRequest from "../../utils/rest/RestRequest";

export default class BaseService {
  creatRequestOption = (obj) => {
    const {
      prefixName,
      headers,
      params,
      onSuccess,
      onError,
      returnPara,
      formRequest = false,
      keepalive = false,
      noEnCode = false,
    } = obj;
    return new RequestOption({
      method: AppSetting.urlPrefix[prefixName].method,
      headers,
      body: params == null || formRequest ? params : JSON.stringify(params),
      requireAuth: AppSetting.urlPrefix[prefixName].requireAuth,
      onSuccess,
      onError,
      returnPara,
      keepalive,
      noEnCode,
    });
  };

  sendRequest = (
    prefixName,
    params,
    onSuccess,
    onError,
    returnPara,
    formRequest,
    keepalive,
  ) => {
    const url =
      AppSetting.endpoint["common"] + AppSetting.urlPrefix[prefixName].url;
    const headers = formRequest ? {} : { "Content-Type": "application/json" };
    const requestOption = this.creatRequestOption({
      prefixName,
      headers,
      params,
      onSuccess,
      onError,
      returnPara,
      formRequest,
      keepalive,
    });
    return new RestRequest(url, requestOption).launchAsyncRequest();
  };

  sendSyncRequest = (
    prefixName,
    params,
    onSuccess,
    onError,
    returnPara,
    formRequest,
    keepalive
  ) => {
    const url =
      AppSetting.endpoint["common"] + AppSetting.urlPrefix[prefixName].url;
    const headers = formRequest ? {} : { "Content-Type": "application/json" };
    const requestOption = this.creatRequestOption({
      prefixName,
      headers,
      params,
      onSuccess,
      onError,
      returnPara,
      formRequest,
      keepalive,
    });
    return new RestRequest(url, requestOption).launchSyncRequest();
  };

  sendExternalRequest = (
    prefixName,
    params,
    onSuccess,
    onError,
    returnPara,
    formRequest,
    keepalive = false,
    noEnCode = false,
    // contentDisposition
  ) => {
    const url = AppSetting.urlPrefix[prefixName].url;
    const headers = formRequest ? {} : { "Content-Type": "application/json" };
    const requestOption = this.creatRequestOption({
      prefixName,
      headers,
      params,
      onSuccess,
      onError,
      returnPara,
      formRequest,
      keepalive,
      noEnCode,
    });
    return new RestRequest(url, requestOption).launchAsyncRequest();
  };

  sendExternalDownloadRequest = (
    prefixName,
    url,
    onSuccess,
    onError,
    returnPara,
    formRequest
    // contentDisposition
  ) => {
    const headers = formRequest
      ? {}
      : { "Content-Type": "application/octet-stream"};
    const requestOption = this.creatRequestOption({
      prefixName,
      headers,
      param:null,
      onSuccess,
      onError,
      returnPara,
      formRequest
    });
    new RestRequest(url, requestOption).launchAsyncRequest();
  };
}
