class TableFilterMap {
  constructor() {
    this.map = new Map();
    this.map.set("accountManager", "AccountManagerID");
    this.map.set("accountSupervisor", "AccountSupervisorID");
    this.map.set("accountTeamLeader", "AccountTeamLeaderID");
    this.map.set("taskLabelName", "TaskLabelName");
    this.map.set("taskId", "TaskId");
    this.map.set("highRegulated", "HighRegulated");
    this.map.set("pmpExcluded", "PmpExcluded");
    this.map.set("temporaryTask", "TemporaryTask");
    this.map.set("procedureStatus", "ProcedureStatusID");
    this.map.set("procedureSaveInCategory", "ProcedureSaveInCategoryID");
    this.map.set("primaryProcedureOwner", "PrimaryProcedureOwner");
    this.map.set("procedureFolderStatus", "ProcedureFolderStatusID");
    this.map.set("sharedTask", "SharedTask");
    this.map.set("sharedTaskIds", "SharedTaskIDs");
    this.map.set("sharedTaskNames", "SharedTaskNames");
    this.map.set("procedureOwner", "ProcedureOwner");
  }

  set(key, value) {
    this.map.set(key, value);
  }

  get(key) {
    return this.map.get(key);
  }
}

const tableFilterMap = new TableFilterMap();

export default tableFilterMap;
