import ERROR_CONFIG from "../../config/ErrorConfig";

export default class ErrorStorage {

    static getError(key) {
        if (ERROR_CONFIG.has(key)) {
            return ERROR_CONFIG.get(key);
        }

        return null;
    }

    static setError(key,value) {
        if (!ERROR_CONFIG.has(key)) {
            ERROR_CONFIG.set(key, value);
        }
    }
}