import ReviseProcedureFileSection from "../reviseProcedureFileSection";
import { savedInRadioValues } from "../../../publicDictionaryValues";
import ReviseUpdateProcedureFileManagement from "../fields/procedureFileManagementFields/reviseUpdateProcedureFileManagement";
import styles from "../index.module.scss";
import ReviseUpdateSavedIn from "../fields/savedInFields/reviseUpdateSavedIn";

export default class UpdateProcedureFileReviseSection extends ReviseProcedureFileSection {
  handleReturnSavedIn = (isDisableAll) => {
    const {
      savedInReturnReasonValue,
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled,
      isSavedInReturnBtnDisabled,
      initSavedInStatus,
      savedInReturnReasonLog,
      savedInRadioValue,
      isSavedInSectionChange,
    } = this.props.savedInProps;
    const { procedureSavedInRef, procedureRef } = this.props.refs;
    return (
      <ReviseUpdateSavedIn
        ref={procedureSavedInRef}
        savedInReturnReasonInputValue={savedInReturnReasonInputValue}
        procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
        isSavedInReturnBtnDisabled={isSavedInReturnBtnDisabled}
        isSavedInApproveBtnDisabled={isSavedInApproveBtnDisabled}
        isSavedInReturnReasonDisabled={true}
        savedInRadioValue={savedInRadioValue}
        initSavedInStatus={initSavedInStatus}
        savedInReturnReasonLog={savedInReturnReasonLog}
        savedInReturnReasonValue={savedInReturnReasonValue}
        updateSavedInRadioValue={this.props.updateSavedInRadioValue}
        procedureRef={procedureRef}
        isDisableAll={isDisableAll}
        isSavedInSectionChange={isSavedInSectionChange}
      />
    );
  };

  render() {
    const { currentRole } = this.props.sectionProps;
    const {
      procedureRef,
      procedureFileTableRef,
      reasonRef,
      noteRef,
      procedureFileRef,
      procedureReplaceFileRef,
    } = this.props.refs;
    const { savedInRadioValue } = this.props.savedInProps;
    const {
      setIsTableChange,
      cancelIsTableChange,
      initProcedureTableData,
      isDisableAll,
      isTableBorderError,
    } = this.props;
    const isShow =
      savedInRadioValue !== savedInRadioValues.serverOnly &&
      savedInRadioValue !== null;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Procedure Files</div>
        <div className={styles.fileSectionTop}>
          {this.handleReturnSavedIn(isDisableAll)}
          {this.handleReturnServerPath(isDisableAll)}
        </div>
        <div
          className={styles.fileSectionTable}
          hidden={savedInRadioValue === savedInRadioValues.serverOnly}
        >
          <ReviseUpdateProcedureFileManagement
            isShow={isShow}
            data={this.props.data}
            ref={procedureRef}
            procedureRef={procedureRef}
            fileTableRef={procedureFileTableRef}
            className="reviseProcedureTable"
            reasonRef={reasonRef}
            noteRef={noteRef}
            fileInputRef={procedureFileRef}
            tableReplaceFileInputRef={procedureReplaceFileRef}
            currentRole={currentRole}
            isDisableAll={isDisableAll}
            initProcedureTableData={[...initProcedureTableData]}
            setIsTableChange={setIsTableChange}
            cancelIsTableChange={cancelIsTableChange}
            isTableBorderError={isTableBorderError}
            handleChangeTableErrorStatus={
              this.props.handleChangeTableErrorStatus
            }
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            updateProcedureFileNum={this.props.updateProcedureFileNum}
          />
        </div>
      </div>
    );
  }
}
