import { Checkbox, Divider, Button, Spin } from "antd";
import CustomButton from "../../../../base/basicComponents/button";
import "./index.scss";

export const GetColumnCheckboxGroupFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
  filterLoading,
}) => {
  const onChange = (checkedValues) => {
    setSelectedKeys(checkedValues);
  };
  const clear = () => {
    setSelectedKeys([]);
    clearFilters();
  };
  const onOk = () => {
    confirm();
  };

  const selectAll = () => {
    const allValues = filters?.map(item => item.value) || []
    setSelectedKeys([...allValues]);
  }
  
  return (
    <Spin spinning={filterLoading} size="small">
      <div className="filter-content">
        <div className="filter-content-body">
          <Checkbox.Group
            onChange={onChange}
            value={selectedKeys}
            style={{ width: "100%" }}
          >
            {filters?.map((item) => (
              <div className="checkboxItem" key={item.value}>
                <Checkbox value={item.value} style={{ width: "100%" }}>
                  {item.text}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </div>
        <Divider />
        <div className="footer">
          <div className="footer-selectBtn">
            <Button
              type="link"
              size="small"
              disabled={
                filters?.length === 0 
                || selectedKeys?.length === filters.length
              }
              onClick={selectAll}
            >
              Select All
            </Button>
            <Button
              type="link"
              size="small"
              disabled={!selectedKeys?.length}
              onClick={clear}
              className="footer-ResetBtn"
            >
              Reset
            </Button>
          </div>
          <CustomButton color="blue" size="tiny" onClick={onOk}>
            OK
          </CustomButton>
        </div>
      </div>
    </Spin>
  );
};
