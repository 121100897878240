import { Tooltip } from "antd";
import ListColumnBase from "../../commonComponents/listBase/listColumnBase";
import { processDetailRouteMap } from "../../publicDictionaryValues";
export default function ProcessDetailColumn(processDetailData) {
  const { formatDateTime, formatDate } = ListColumnBase();

  const routeMap = (record) => {
    const { processCategory, procedureTaskID, procedureID } = processDetailData;
    const { procedureStepID } = record;
    const ParentPage = "ProcessDetail";
    const routeUrl =
      processDetailRouteMap[processCategory] +
      procedureTaskID +
      "/" +
      procedureID +
      "/" +
      procedureStepID +
      "/" +
      ParentPage;
    return routeUrl;
  };

  const columns = [
    {
      title: "Step",
      dataIndex: "step",
      dbName: "Step",
      ellipsis: {
        showTitle: false,
      },
      render: (step, record) => {
        if (record.status === "Completed") {
          return (
            <Tooltip placement="topLeft" title={step}>
              <a
                href={routeMap(record)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {step}
              </a>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="topLeft" title={step}>
            {step}
          </Tooltip>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      dbName: "Status",
      ellipsis: {
        showTitle: false,
      },
      render: (status) => (
        <Tooltip placement="topLeft" title={status}>
          {status}
        </Tooltip>
      ),
    },
    {
      title: "Action Taken",
      dataIndex: "actionTaken",
      dbName: "ActionTaken",
      ellipsis: {
        showTitle: false,
      },
      render: (actionTaken) => (
        <Tooltip placement="topLeft" title={actionTaken}>
          {actionTaken}
        </Tooltip>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      dbName: "DueDate",
      ellipsis: {
        showTitle: false,
      },
      render: (dueDate) => (
        <Tooltip placement="topLeft" title={formatDateTime(dueDate)}>
          {dueDate ? formatDateTime(dueDate) : ""}
        </Tooltip>
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      dbName: "Owner",
      ellipsis: {
        showTitle: false,
      },
      render: (owner) => (
        <Tooltip placement="topLeft" title={owner}>
          {owner}
        </Tooltip>
      ),
    },
    {
      title: "Processed By",
      dataIndex: "processedBy",
      dbName: "ProcessedBy",
      ellipsis: {
        showTitle: false,
      },
      render: (processedBy) => (
        <Tooltip placement="topLeft" title={processedBy}>
          {processedBy}
        </Tooltip>
      ),
    },
    {
      title: "Completed Date",
      dataIndex: "completedDate",
      dbName: "CompletedDate",
      ellipsis: {
        showTitle: false,
      },
      render: (completedDate) => (
        <Tooltip placement="topLeft" title={formatDate(completedDate)}>
          {formatDate(completedDate)}
        </Tooltip>
      ),
    },
  ];

  return columns;
}
