import React from "react";
import ProcedureProcessFileThead from "../../approveAndReviseFileHeadFields";
import ApproveProcedureFileTbody from "../../procedureFileTbodyFields/approveProcedureFileTbody";
import { fileTags } from "../../../../../publicDictionaryValues";
import BaseFileTable from "../../../../../commonComponents/fileTables/baseFileTable";

export default class ApproveProcedureFileTable extends BaseFileTable {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: this.props.columns,
      isExpendData: false,
      isExpendNote: false,
      expendHeight: 40,
      isTableBorderError: this.props.isTableBorderError,
    };
    this.approveNumber = 0;
  }

  /* update file table data */
  updateDataList = (dataList) => {
    this.newDataList = [];
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: dataList,
    });
    this.handleDefaultExpendIcon();
  };

  /* function about set need approved file number in approve page */
  setNeedApproveDataNumber = (data) => {
    this.approveNumber = 0;
    for (let index in data) {
      if (
        data[index].data.find((i) => i.name === "status").value === null &&
        data[index].data.find((i) => i.name === "fileName").tagValue !==
          fileTags.deleted
      ) {
        this.approveNumber += 1;
      }
    }
  };

  render() {
    const {
      procedureNoteMaxLength,
      procedureNoteSize,
      inputOnChange,
      fileTableRef,
      className,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isComplete,
      columns,
      isExpendData,
      isRejectCompletePage,
    } = this.props;
    const { data } = this.state;
    this.setNeedApproveDataNumber(data);
    return (
      <>
        <div className="scrollTable">
          <table className={`${className} table autoHeightChangeContainer`}>
            <ProcedureProcessFileThead columns={columns} />
            <ApproveProcedureFileTbody
              data={data}
              columns={columns}
              inputOnChange={inputOnChange}
              procedureNoteMaxLength={procedureNoteMaxLength}
              procedureNoteSize={procedureNoteSize}
              fileTableRef={fileTableRef}
              optionList={optionList}
              isShowReturnReason={isShowReturnReason}
              handleReturnClick={handleReturnClick}
              handleFileSelect={handleFileSelect}
              currentRole={currentRole}
              downloadFile={downloadFile}
              isComplete={isComplete}
              isExpendData={isExpendData}
            />
          </table>
        </div>
        <p
          className={`${this.props.className} fileNumTip`}
          style={{ color: "#FF9921" }}
        >
          <span className="fileNum">{isRejectCompletePage ? 0 : this.approveNumber}</span>{" "}
          {this.setFileText(isRejectCompletePage ? 0 : this.approveNumber)} pending approval
        </p>
      </>
    );
  }
}
