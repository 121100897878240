import { Checkbox } from "antd";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import styles from "../index.module.scss";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";

export default class LevelCSectionComponent extends BaseFileManagement {
    constructor(props) {
        super(props);
        this.state = {
            isLevelCChange: this.props.isLevelCChange,
        };
    }

    handleLevelCChange = (e) => {
        this.setState({ isLevelCChange: e.target.checked });
    };

    render() {
        const { CategoryID, AccountID, TaskGroupID, TaskLabelID } =
            this.props.ptParams;
            
        const currentHref =
            process.env.SUPERVISOR_PORTAL_ADDRESS +
            "TaskCreateTaskNew.aspx?catid=" + CategoryID +
            "&tgid=" + TaskGroupID +
            "&aid=" + AccountID +
            "&vtid=" + TaskLabelID;

        return (
            <>
                <div className={styles.levelCLink}>
                    <InputLabel
                        text="Review/Update level C: "
                        className={styles.linkTip}
                    />
                    <a
                        href={currentHref}
                        className={styles.linkContent}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Click here to view task detail page
                    </a>
                </div>
                <div className={styles.levelCChange}>
                    <InputLabel
                        text="Level C is changed: "
                        className={styles.changeTip}
                    />
                    <Checkbox
                        onChange={this.handleLevelCChange}
                        checked={this.state.isLevelCChange}
                        disabled={this.props.isDisableAll}
                        className={styles.changeCheckbox}
                    ></Checkbox>
                </div>
            </>
        );
    };
}