class ProcedureListMockService {
  getAccountAbbrList = (success, error) => {
    const accountAbbrList = [
      {
        id: "1",
        name: "Abbr1",
      },
      {
        id: "2",
        name: "Abbr2",
      },
      {
        id: "3",
        name: "Abbr3",
      },
      {
        id: "4",
        name: "Abbr4",
      },
      {
        id: "5",
        name: "Abbr5",
      },
      {
        id: "6",
        name: "Abbr6",
      },
    ];

    success(accountAbbrList);
  };

  getAccountStatusList = (success, error) => {
    const accountStatusList = [
      { id: "0", name: "Inactive" },
      { id: "1", name: "Active" },
      { id: "2", name: "Both" },
    ];
    success(accountStatusList);
  };

  getTaskGroupList = (success, error) => {
    const taskGroupList = [
      {
        id: "1",
        name: "TaskGroupName",
      },
      {
        id: "2",
        name: "TaskGroupName2",
      },
      {
        id: "3",
        name: "TaskGroupName3",
      },
      {
        id: "4",
        name: "TaskGroupName4",
      },
      {
        id: "5",
        name: "TaskGroupName5",
      },
    ];
    success(taskGroupList);
  };
  getCategoryList = (success, error) => {
    const categoryList = [
      {
        id: "1",
        name: "CategoryName1",
      },
      {
        id: "2",
        name: "CategoryName2",
      },
      {
        id: "3",
        name: "CategoryName3",
      },
      {
        id: "4",
        name: "CategoryName4",
      },
      {
        id: "5",
        name: "CategoryName5",
      },
    ];
    success(categoryList);
  };
  getTaskStatusList = (success, error) => {
    const taskStatusList = [
      { id: "0", name: "Inactive" },
      { id: "1", name: "Active" },
      { id: "2", name: "Both" },
    ];
    success(taskStatusList);
  };

  getProcedureList = (para, success, error) => {
    const procedureList = {
      data: [
        {
          clientAbbr: "ClientAbbr1",
          clientName: "ClientName1",
          accountAbbr: "AccountAbbr1",
          accountName: "AccountName",
          accountManager: "AccountManager1",
          accountSupervisor: "AccountSupervisor1",
          accountTeamLeader: "AccountTeamLeader1",
          accountStatus: "AccountStatus1",
          taskStatus: true,
          taskGroupName: "taskGroupName1",
          categoryName: "categoryName1",
          taskLabelName: "taskLabelName1",
          taskId: 1,
          taskCreatedDate: "2023/4/21",
          highRegulated: true,
          turnaroundTime: "2023/4/21",
          temporaryTask: true,
          sharedTask: true,
          sharedTaskIds: "",
          sharedTaskNames: "",
          procedureStatus: "1",
          procedureStatusOrder: 0,
          procedureSaveInCategory: "",
          clientServerPath: "",
          primaryProcedureOwner: "",
          procedureOwner: "",
          procedureFolderCreateDate: "2023/4/21",
          lastUpdatedDate: "2023/4/21",
          procedureFolderStatus: "",
        },
        {
          clientAbbr: "lkjk;lk2",
          clientName: ";lkfvcvx23",
          accountAbbr: "cxvcxvx2",
          accountName: "kjh2",
          accountManager: "jhhjr4",
          accountSupervisor: "hgfhf2",
          accountTeamLeader: "vcxvcxv2",
          accountStatus: "ffdsgfdh2",
          taskStatus: false,
          taskGroupName: "fdsfdsg1",
          categoryName: "nbvnbvmnb1",
          taskLabelName: "trjghgjhg1",
          taskId: 4354,
          taskCreatedDate: "2023/6/28",
          highRegulated: true,
          turnaroundTime: "2022/5/1",
          temporaryTask: true,
          sharedTask: false,
          sharedTaskIds: "fdsgfdh",
          sharedTaskNames: "eerrreyyuu",
          procedureStatus: "34",
          procedureStatusOrder: 5,
          procedureSaveInCategory: "refds",
          clientServerPath: "dsas",
          primaryProcedureOwner: "q4587",
          procedureOwner: "545tg",
          procedureFolderCreateDate: "2022/1/11",
          lastUpdatedDate: "2023/2/2",
          procedureFolderStatus: "ds",
        },
        {
          clientAbbr: "44trgf1",
          clientName: "6556tyg3",
          accountAbbr: "dfsfr1",
          accountName: "bfgtu",
          accountManager: "fdsahw",
          accountSupervisor: "re4yggv",
          accountTeamLeader: "fdgy765rd",
          accountStatus: "vbhuytrf",
          taskStatus: true,
          taskGroupName: "412wdr",
          categoryName: "8kmh56",
          taskLabelName: "ygfdsdt",
          taskId: 1,
          taskCreatedDate: "2023/3/15",
          highRegulated: true,
          turnaroundTime: "2021/6/23",
          temporaryTask: false,
          sharedTask: false,
          sharedTaskIds: "r43efgv",
          sharedTaskNames: "ki765rvf",
          procedureStatus: "rewsbhy",
          procedureStatusOrder: 3,
          procedureSaveInCategory: "56tg",
          clientServerPath: "2w",
          primaryProcedureOwner: "ky67un",
          procedureOwner: "dfsbtr",
          procedureFolderCreateDate: "2020/1/17",
          lastUpdatedDate: "2022/8/25",
          procedureFolderStatus: "fdfh",
        },
      ],
      totalRecord: 20,
      totalPage: 2,
    };
    success(procedureList);
  };

  getProcedureFilter = (para, success, error) => {
    const procedureFilterList = {
      filterFields: [
        {
          filterColumn: "accountSupervisor",
          filterValue: "[AccountSupervisor3]",
        },
      ],
      taskId: "",
      taskLabelName: "",
    };

    success(procedureFilterList);
  };
}

const procedureListMockService = new ProcedureListMockService();
export default procedureListMockService;
