import BaseService from "../base";
import procedureDetailMockService from "./mock";
import AppSetting from "../../config/AppSetting";
import ProcedureFileStepService from "./procedureFileStepService";
import SignOffStepService from "./signOffStepService";

class ProcedureDetailService extends BaseService {
  submitSupprotingFileForApproval = (para, success, error) => {
    this.sendRequest("submitSupprotingFileForApproval", para, success, error);
  };

  updateSupprotingFileForApproval = (para, success, error) => {
    this.sendRequest("updateSupprotingFileForApproval", para, success, error);
  };

  saveSupprotingFileAsDraft = (para, success, error) => {
    this.sendRequest("saveSupprotingFileAsDraft", para, success, error);
  };

  getSupportingFileList = (para, success, error) => {
    this.sendRequest("getSupportingFileList", para, success, error);
  };

  submitForApproval = (para, success, error) => {
    this.sendRequest("submitForApproval", para, success, error);
  };

  updateForApproval = (para, success, error) => {
    this.sendRequest("updateForApproval", para, success, error);
  };

  saveAsDraft = (para, success, error) => {
    this.sendRequest("saveAsDraft", para, success, error);
  };

  addFile = (para, success, error, returnPara, number = 0) => {
    this.sendExternalRequest(
      "addFile",
      para,
      success,
      error,
      returnPara,
      true,
    ).catch((ex) => {
      if (number < 10) {
        number++;
        this.addFile(para, success, error, returnPara, number);
      }

      throw ex;
    })
  };

  deleteFile = (para, success, error, returnPara) => {
    this.sendExternalRequest("deleteFile", para, success, error, returnPara);
  };

  downloadFile = (para, success, error, returnPara) => {
    return this.registerFileShare(
      para,
      response => response,
      error,
      returnPara,
    ).then((result) => {
        return this.sendExternalDownloadRequest("registerFileShare", result[0].sasShareUrl,  success, error, returnPara);
    }).catch(error => console.log(error));
  };

  getProcedureFileList = (para, success, error) => {
    this.sendRequest("getProcedureFileList", para, success, error);
  };

  getTaskInfo = (para, success, error) => {
    this.sendRequest("getTaskInfo", para, success, error);
  };

  submitProcedureInfo = (para, success, error) => {
    this.sendRequest("submitProcedureInfo", para, success, error);
  };

  getPrimaryProcedureOwnerDDL = (para, success, error) => {
    this.sendRequest("getPrimaryProcedureOwnerDDL", para, success, error);
  };

  checkSectionLock = (para, success, error) => {
    this.sendRequest("checkSectionLock", para, success, error);
  };

  setSectionLock = (para, success, error) => {
    this.sendRequest("setSectionLock", para, success, error, {}, false, true);
  };

  getProcedureFileSection = (para, success, error) => {
    this.sendRequest("getProcedureFileSection", para, success, error);
  };

  getSupportingFileSection = (para, success, error) => {
    this.sendRequest("getSupportingFileSection", para, success, error);
  };

  checkSupportingFileEdit = (para, success, error) => {
    this.sendRequest("checkSupportingFileEdit", para, success, error);
  };

  getProcedureInfoSection = (para, success, error) => {
    this.sendRequest("getProcedureInfoSection", para, success, error);
  };

  getProcedureProcessStatus = (para, success, error) => {
    this.sendRequest("getProcedureProcessStatus", para, success, error);
  };

  getSignOffStepItem = (para, success, error) => {
    SignOffStepService.getStepData(para, success, error);
  };

  signOffTriggerAction = (para, success, error) => {
    this.sendRequest("signOffTriggerAction", para, success, error);
  };

  checkPPOEdit = (para, success, error) => {
    this.sendRequest("checkPPOEdit", para, success, error);
  };

  checkPOEdit = (para, success, error) => {
    this.sendRequest("checkPOEdit", para, success, error);
  };

  checkEditSection = (para, success, error) => {
    this.sendRequest("checkEditSection", para, success, error);
  };

  supportingFileUpdateCheck = (para, success, error) => {
    this.sendRequest("supportingFileUpdateCheck", para, success, error);
  };

  supportingFileSubmitCheck = (para, success, error) => {
    this.sendRequest("supportingFileSubmitCheck", para, success, error);
  };

  getRevisionCategory = (para, success, error) => {
    this.sendRequest("getRevisionCategory", para, success, error);
  };

  getClientMembers = (para, success, error) => {
    this.sendRequest("getClientMembers", para, success, error);
  };

  getProcedureFolderStepItem = (para, success, error) => {
    ProcedureFileStepService.getStepData(para, success, error);
  };

  createProcedureTriggerApprovePageAction = (para, success, error) => {
    this.sendRequest(
      "createProcedureTriggerApprovePageAction",
      para,
      success,
      error
    );
  };

  createProcedureTriggerRevisePageAction = (para, success, error) => {
    this.sendRequest(
      "createProcedureTriggerRevisePageAction",
      para,
      success,
      error
    );
  };

  updateProcedureTriggerApprovePageAction = (para, success, error) => {
    this.sendRequest(
      "updateProcedureTriggerApprovePageAction",
      para,
      success,
      error
    );
  };

  updateProcedureTriggerRevisePageAction = (para, success, error) => {
    this.sendRequest(
      "updateProcedureTriggerRevisePageAction",
      para,
      success,
      error
    );
  };

  getHistoricalFiles = (para, success, error) => {
    this.sendRequest("getHistoricalFiles", para, success, error);
  };

  checkCreateProcedureFileSubmit = (para, success, error) => {
    this.sendRequest("checkCreateProcedureFileSubmit", para, success, error);
  };

  checkUpdateProcedureFileSubmit = (para, success, error) => {
    this.sendRequest("checkUpdateProcedureFileSubmit", para, success, error);
  };

  registerFileShare = (para, success, error, returnPara) => {
    return this.sendExternalRequest("registerFileShare", para, success, error, returnPara);
  };

  getShowEditPermission = (para, success, error) => {
    this.sendRequest("getShowEditPermission", para, success, error);
  };
}

const procedureDetailService = new ProcedureDetailService();

export default AppSetting.isMock
  ? procedureDetailMockService
  : procedureDetailService;
