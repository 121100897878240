import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";

export default class SubmitShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = {
      isTaskInfoModalOpen: false,
      taskInformationData: {},
      reasonValue: this.props.shareTaskData.reason,
      reason: this.props.shareTaskData.returnReason,
      isReasonLogModalOpen: false,
      isRequestReasonLogModalOpen: false,
      requestReasonLog: this.transformRequestReasonLogData(
        this.props.shareTaskData.submitReasonLogs
      ),
    };
  }

  handleSubmitReason = () => {
    return this.handleReviseSubmitReason();
  };
}
