import BaseService from "../base";
import ApplyPMPExcludedStepService from "./applyPMPExcludedStepService";

class ApplyPMPExcludedService extends BaseService {
  getApplyPmpExcludedStepItem = (para, success, error) => {
    ApplyPMPExcludedStepService.getStepData(para, success, error);
  };

  applyPMPExcludedTriggerStepAction = (para, success, error) => {
    this.sendRequest("applyPMPExcludedTriggerStepAction", para, success, error);
  };

  checkApplyPmpExcludedEdit = (para, success, error) => {
    this.sendRequest("checkApplyPmpExcludedEdit", para, success, error);
  };
}

const applyPMPExcludedService = new ApplyPMPExcludedService();

export default applyPMPExcludedService;
