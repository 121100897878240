class ChangeHistoryFilterMap {
  constructor() {
    this.map = new Map();
    this.map.set("processCategory", "ProcessCategory");
    this.map.set("triggerProcess", "TriggerProcess");
    this.map.set("processStatus", "ProcessStatus");
    this.map.set("processInitiateDate", "ProcessInitiateDate");
    this.map.set("processInitiator", "ProcessInitiator");
    this.map.set("currentProcessor", "CurrentProcessor");
    this.map.set("processCompletedDate", "ProcessCompletedDate");
  }

  set(key, value) {
    this.map.set(key, value);
  }

  get(key) {
    return this.map.get(key);
  }
}

const changeHistoryFilterMap = new ChangeHistoryFilterMap();

export default changeHistoryFilterMap;
