import React from "react";
import ReasonLogIcon from "../../../../../../base/basicComponents/reasonLogIcon";
import NormalToolTip from "../../../../../../base/basicComponents/toolTip";
import BaseComponent from "../../../../../../base/common/BaseComponent";
import "../index.scss";

export default class DelegatedReasonIcon extends BaseComponent {
  render() {
    return (
      <NormalToolTip
        title={
          <span style={{ color: "#333" }}>Click to view Delegate Reason.</span>
        }
        element={
          <div>
            <ReasonLogIcon
              className="delegatedReasonIcon"
              onClick={() => this.props.handleModalOpen()}
            />
          </div>
        }
        placement="right"
        color="#fff"
      />
    );
  }
}
