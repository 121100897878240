import EllipsisToolTip from "../../../../../../base/basicComponents/ellipsisToolTip";
import HistoricalVersionComponent from "../../../../../../base/basicComponents/historicalVersionComponent";
import { FileStatusDiv } from "../../../../../../base/basicComponents/status";
import { flowBusinessIdMap } from "../../../../../publicDictionaryValues";
import UpdateSupportingFileTbody from "../../../../supportingFileSections/fields/supportingFileTbody/updateSupportingFileTbody";

export default class UpdateProcedureFileTbody extends UpdateSupportingFileTbody {
  /**
   * function about show link if column type is link
   * @param {*} rowColumn
   * @param {*} column
   * @returns
   */
  returnFileName = (rowColumn, column, row) => {
    const fileId = row.data.find((item) => item.name === "fileId").value;
    const fileKey = row.data
      .find((item) => item.name === "fileKey")
      .value.trim();
    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
        />
        {this.props.isShowHistoryVersion && (
          <HistoricalVersionComponent
            fileId={fileId}
            fileKey={fileKey}
            flowBusinessId={flowBusinessIdMap.procedureFile}
          />
        )}
        {rowColumn.tagValue !== null && (
          <FileStatusDiv status={rowColumn.tagValue} />
        )}
      </div>
    );
  };
}
