import React from "react";
import { Modal, Input, Popover, Tooltip, Switch } from "antd";
import Buttons from "../../../../base/basicComponents/button/index";
import ShareTaskTable from "../shareTaskTable";
import BaseShareOnModal from "../baseShareOnModal";
import AddNewShareTask from "../addNewShareTask";
import {
  FileStatusDiv,
  ChangeMainTaskTag,
} from "../../../../base/basicComponents/status";
import { shareTaskMassage } from "../../../publicDictionaryValues";
import changeMainTaskService from "../../../../service/changeMainTask/changeMainTaskService";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import requiredWarning from "../../../../assets/image/required-warning.png";
import styles from "./index.module.scss";
import sectionStyles from "../index.module.scss";
import controlPageBehavior from "../../../../base/basicComponents/modal/controlPageBehavior";
import "../share.scss";
import fullScreenIcon from "../../../../assets/image/fullScreen.png";
import fullScreenHoverIcon from "../../../../assets/image/fullScreenHover.png";
import miniScreenIcon from "../../../../assets/image/miniScreen.png";
import miniScreenHoverIcon from "../../../../assets/image/miniScreenHover.png";

const { TextArea } = Input;

class ChangeMainTaskModal extends BaseShareOnModal {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: true,
      initChangeMainTaskModalTableData: [],
      changeMainTaskModalTableData: [],
      isChangeMainTaskModalOpen: false,
      accountSelectOption: [],
      accountSelectValue: null,
      taskTreePathOption: [],
      taskTreePathValue: null,
      isAddNewShareTaskShow: false,
      reseanValue: "",
      addNewSelectDisable: false,
      switchDisable: false,
      reasonInputStatus: "",
      isExpendData: false,
      iconSrc: fullScreenIcon,
      tableKey: 0,
      selectTaskDisable: false,
    };
  }

  GetModalTableDataFail = (result) => {
    console.log(result);
  };

  GetChangeMainTaskModalTableDataSuccess = (result) => {
    this.setState({
      isChangeMainTaskModalOpen: true,
      changeMainTaskModalTableData: result,
      initChangeMainTaskModalTableData: result,
    });
    this._setLock(this.props.procedureId, "ShareTask", true);
    this.props.setActionType("ShareTask");
  };

  ShowModal = () => {
    const para = this.props.procedureId;
    changeMainTaskService.getActiveShareTaskList(
      para,
      this.GetChangeMainTaskModalTableDataSuccess,
      this.GetModalTableDataFail
    );
    const accountPara = {
      procedureTaskId: this.props.procedureTaskId,
      procedureStepId: null,
    };
    changeMainTaskService.getAccountDDL(
      accountPara,
      this.GetAccountDDLOnSuccess,
      this.GetAccountDDLOnError
    );
  };

  ChangeMainTaskModalOpen = () => {
    this.setState({
      isChangeMainTaskModalOpen: true,
      isExpendData: false,
      iconSrc: fullScreenIcon,
    });
  };

  ChangeMainTaskModalClose = () => {
    this.setState({
      isChangeMainTaskModalOpen: false,
    });
  };

  ModalOnSubmitOnSuccess = (result) => {
    this.props.hideLoading();
    this._alertSuccess("Submit Successful");
    this.props.refreshPage();
  };

  ModalOnSubmitOnError = () => {
    this.props.hideLoading();
    this._alertError("Submit Failed");
  };

  ModalCheckSubmitOnSuccess = (result) => {
    let currentData = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );

    if (!result.result) {
      const errorMsg =
        "There are some tasks that do not meet the conditions for adding to or removing from the sharing relationship. Please check the specific reasons in the task list.";
      this._alertError(errorMsg, 6);
      const responseData = result.resultList;
      for (let index = 0; index < currentData.length; index++) {
        const responseIndex = responseData.findIndex(
          (x) => x.taskId === currentData[index].taskId && !x.checkResult
        );
        if (responseIndex > -1) {
          currentData[index] = {
            ...currentData[index],
            hasError: true,
            errorMessage: shareTaskMassage[responseData[responseIndex].msgCode],
          };
        } else {
          currentData[index] = {
            ...currentData[index],
            hasError: false,
            errorMessage: "",
          };
        }
      }
      this.setState({
        changeMainTaskModalTableData: currentData,
      });
    } else {
      const data = currentData.map((item) => {
        if (item.hasError === true) {
          return {
            ...item,
            hasError: false,
            errorMessage: "",
          };
        }

        return item;
      });
      this.setState({
        changeMainTaskModalTableData: data,
      });
      const message = "Are you sure to submit?";
      this.props.openConfirmModal(this.HandleComfirm, message);
    }
  };

  HandleComfirm = () => {
    this.props.showLoading();
    this.setState({
      isChangeMainTaskModalOpen: false,
    });
    this.props.cancelConfilmModal();
    const currentData = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    const para = {
      tasks: currentData,
      reason: this.state.reseanValue,
      procedureId: this.props.procedureId,
      proceduretaskId: this.props.procedureTaskId,
    };
    changeMainTaskService.submitForApproval(
      para,
      this.ModalOnSubmitOnSuccess,
      this.ModalOnSubmitOnError
    );
  };

  ModalCheckSubmitOnError = () => {
    this._alertError("Check Failed");
  };

  OnSubmit = () => {
    if (this.state.reseanValue.trim() === "") {
      this.setState({
        reasonInputStatus: "error",
      });
      return;
    }
    let currentData = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    let newMainTask = currentData.find((item) => item.isNewMainTask === true);
    let oldMainTask = currentData.find((item) => item.isOldMainTask === true);
    if (
      currentData.length === 2 &&
      oldMainTask !== undefined &&
      oldMainTask.isDeleted
    ) {
      this._alertError(
        "Please note that the Change Main Task process cannot be initiated when there is no active shared task.",
        6
      );
      return;
    }
    const para = {
      procedureId: this.props.procedureId,
      oldMainTask: oldMainTask,
      newMainTask: newMainTask,
      taskList: currentData,
    };
    changeMainTaskService.checkSubmit(
      para,
      this.ModalCheckSubmitOnSuccess,
      this.ModalCheckSubmitOnError
    );
  };

  HandleCancel = () => {
    this.setState({
      isChangeMainTaskModalOpen: false,
      changeMainTaskModalTableData: [],
      accountSelectValue: this.state.accountSelectOption[0].value,
      taskTreePathValue: null,
      isAddNewShareTaskShow: false,
      submitDisabled: true,
      reseanValue: "",
      reasonInputStatus: "",
      addNewSelectDisable: false,
      switchDisable: false,
      isExpendData: false,
      iconSrc: fullScreenIcon,
      tableKey: this.state.tableKey + 1,
    });
    this.props.cancelConfilmModal();
    this.props.releaseLock();
    this.props.setActionType(null);
  };

  OnCancel = () => {
    const message = "Are you sure to quit without saving?";
    this.props.openConfirmModal(this.HandleCancel, message);
  };

  AddTaskOnClickOnSuccess = (result) => {
    const newLine = { ...result, isNew: true };
    let currentModalTableData = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    const duplicateLineIndex = currentModalTableData.findIndex(
      (x) => x.taskId === newLine.taskId
    );
    if (duplicateLineIndex > -1) {
      this._alertError("This Task already exists.");
      this.setState({
        taskTreePathValue: null,
      });
      return;
    }
    currentModalTableData.push(newLine);
    currentModalTableData = this.HandleSwitchMainTaskTableData(
      currentModalTableData,
      newLine.taskId
    );
    this.setState({
      changeMainTaskModalTableData: currentModalTableData,
      addNewSelectDisable: true,
      switchDisable: true,
      isAddNewShareTaskShow: false,
      taskTreePathValue: null,
      accountSelectValue: this.state.accountSelectOption[0].value,
    });
    this.setTaskTreePathDDL(this.state.accountSelectOption[0].value);
    this.CheckSubmitDisable(currentModalTableData);
  };

  AddTaskOnClickOnError = (result) => {};

  AddTaskOnClick = () => {
    const para = this.state.taskTreePathValue;
    changeMainTaskService.getRowData(
      para,
      this.AddTaskOnClickOnSuccess,
      this.AddTaskOnClickOnError
    );
  };

  ShowAddNewShareTask = () => {
    if (this.state.addNewSelectDisable) {
      return;
    }
    this.setState({
      isAddNewShareTaskShow: true,
    });
  };

  HandleSwitchMainTaskTableData = (tableData, taskId) => {
    const newMainTask = tableData.find((item) => item.isMainTask === true);

    if (newMainTask) {
      newMainTask.isMainTask = false;

      if (
        newMainTask.hasOwnProperty("isNewMainTask") &&
        newMainTask.isNewMainTask === true
      ) {
        newMainTask.isNewMainTask = false;
        newMainTask.isOldMainTask = null;
      } else {
        newMainTask.isOldMainTask = true;
      }
    }

    const selectedTask = tableData.find((item) => item.taskId === taskId);

    if (selectedTask) {
      selectedTask.isMainTask = true;

      if (
        selectedTask.hasOwnProperty("isOldMainTask") &&
        selectedTask.isOldMainTask === true
      ) {
        selectedTask.isOldMainTask = false;
        delete selectedTask["isDeleted"];
      } else {
        selectedTask.isNewMainTask = true;
        selectedTask.isOldMainTask = false;
      }
    }

    return tableData;
  };

  SwitchMainTask = (checked, e, record) => {
    if (!checked) {
      return;
    }
    let tableData = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    const currentMainTask = tableData.find((item) => item.isMainTask === true);

    if (currentMainTask && currentMainTask.taskId === record.taskId) {
      return;
    }

    tableData = this.HandleSwitchMainTaskTableData(tableData, record.taskId);

    this.setState({
      changeMainTaskModalTableData: tableData,
    });
    this.CheckSubmitDisable(tableData);
  };

  CheckSubmitDisable = (currentData) => {
    const oldMainTask = currentData.find((x) => x.isOldMainTask === true);
    const newMainTask = currentData.find((x) => x.isNewMainTask === true);
    if (oldMainTask !== undefined || newMainTask !== undefined) {
      this.setState({
        submitDisabled: false,
      });
    } else {
      this.setState({
        submitDisabled: true,
        reasonInputStatus: "",
      });
    }
  };

  ModalTableOnRevoke = (record) => {
    let data = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    const index = data.findIndex((x) => x.taskId === record.taskId);
    delete data[index]["isDeleted"];
    this.setState({
      changeMainTaskModalTableData: data,
    });
    this.CheckSubmitDisable(data);
  };

  ModalTableOnDelete = (record) => {
    let data = JSON.parse(
      JSON.stringify(this.state.changeMainTaskModalTableData)
    );
    const index = data.findIndex((x) => x.taskId === record.taskId);
    if (record.isNew) {
      this.setState({
        changeMainTaskModalTableData:
          this.state.initChangeMainTaskModalTableData,
        addNewSelectDisable: false,
        switchDisable: false,
        submitDisabled: true,
        reasonInputStatus: "",
      });
    } else {
      data[index] = { ...data[index], isDeleted: true };
      this.setState({
        changeMainTaskModalTableData: data,
      });
      this.CheckSubmitDisable(data);
    }
  };

  handleDefaultExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData ? miniScreenIcon : fullScreenIcon,
    });
  };

  handleHoverExpendIcon = () => {
    this.setState({
      iconSrc: this.state.isExpendData
        ? miniScreenHoverIcon
        : fullScreenHoverIcon,
    });
  };

  handleExpendFlag = () => {
    const flag = !this.state.isExpendData;
    this.setState({ isExpendData: flag });
  };

  setFileText = (num) => (num <= 1 ? "task" : "tasks");

  sharedTaskTableListControlIcon = () => {
    const { isExpendData, iconSrc } = this.state;
    return (
      <p className="listControlIcon">
        <img
          src={iconSrc}
          alt={isExpendData ? "pick up" : "show all"}
          onMouseEnter={this.handleHoverExpendIcon}
          onMouseLeave={this.handleDefaultExpendIcon}
          onClick={this.handleExpendFlag}
        />
      </p>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isChangeMainTaskModalOpen !==
      this.state.isChangeMainTaskModalOpen
    ) {
      if (this.state.isChangeMainTaskModalOpen) {
        const modalElement = document.getElementsByClassName(
          "change-main-tasks-modal"
        )[0];
        controlPageBehavior.addEleListener(modalElement);
        controlPageBehavior.resetListener(modalElement, this.props.refreshPage);
      } else {
        controlPageBehavior.clearListener();
      }
    } else if(
      this.state.changeMainTaskModalTableData.length > 4 && 
      this.state.changeMainTaskModalTableData.length > prevState.changeMainTaskModalTableData.length
    ) {
      const tbody = document.querySelector('.modal-change-main-task-table .ant-table-body');
      if(tbody) {
        tbody.scrollTop = tbody.scrollHeight;
      }
    }
  }

  render() {
    const { zIndex } = this.props;
    const {
      isChangeMainTaskModalOpen,
      submitDisabled,
      changeMainTaskModalTableData,
      accountSelectOption,
      accountSelectValue,
      taskTreePathOption,
      taskTreePathValue,
      isAddNewShareTaskShow,
      reseanValue,
      addNewSelectDisable,
      switchDisable,
      reasonInputStatus,
      isExpendData,
      selectTaskDisable,
    } = this.state;

    const tableColumn = [
      {
        title: "",
        dataIndex: "switchMainTask",
        render: (text, record) => (
          <div>
            <Popover
              content={
                <span className={`${sectionStyles.errorPopContent} fs-14`}>
                  {record.errorMessage}
                </span>
              }
              title={null}
              trigger="click"
              placement="topLeft"
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode.parentNode.parentNode.parentNode
              }
              overlayClassName="shareTooltip"
            >
              <img
                src={redTipIcon}
                alt="error"
                className={
                  record.hasError
                    ? sectionStyles.redTipIconShow
                    : sectionStyles.redTipIconHide
                }
              />
            </Popover>
            <Tooltip
              placement="bottomLeft"
              title={
                switchDisable
                  ? "Please delete the New Task if you want to select other Tasks as Main Task."
                  : ""
              }
              overlayClassName="shareTooltip"
            >
              <Switch
                checked={record.isMainTask}
                disabled={switchDisable}
                onChange={(checked, e) =>
                  this.SwitchMainTask(checked, e, record)
                }
              />
            </Tooltip>
            {record.isMainTask ? (
              <span className={styles.switchTextMainTask}>Main Task</span>
            ) : (
              <span className={styles.switchTextSharedTask}>Shared Task</span>
            )}
          </div>
        ),
        width: "11%",
      },
      {
        title: "",
        dataIndex: "changeMainTaskTag",
        render: (text, record) => (
          <div className={sectionStyles.fileStatus}>
            <ChangeMainTaskTag status={this.HandleChangeMainTaskTag(record)} />
          </div>
        ),
        width: "8.5%",
      },
      {
        title: "Account",
        dataIndex: "account",
        ellipsis: {
          showTitle: false,
        },
        width: "5.6%",
        render: (account, record) => (
          <Tooltip
            placement="bottomLeft"
            title={account}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>
              {account}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Task Tree Path",
        dataIndex: "taskTreePath",
        ellipsis: {
          showTitle: false,
        },
        width: "8.5%",
        render: (taskTreePath) => (
          <Tooltip
            placement="bottomLeft"
            title={taskTreePath}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>
              {taskTreePath}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Task Label Name",
        dataIndex: "taskLabelName",
        ellipsis: {
          showTitle: false,
        },
        render: (taskLabelName) => (
          <Tooltip
            placement="bottomLeft"
            title={taskLabelName}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>
              {taskLabelName}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Task ID",
        dataIndex: "taskId",
        ellipsis: {
          showTitle: false,
        },
        width: "5.8%",
        render: (taskId) => (
          <Tooltip
            placement="bottomLeft"
            title={taskId}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>{taskId}</span>
          </Tooltip>
        ),
      },
      {
        title: "Primary Procedure Owner",
        dataIndex: "ppo",
        ellipsis: {
          showTitle: false,
        },
        width: "12.8%",
        render: (ppo) => (
          <Tooltip
            placement="bottomLeft"
            title={ppo}
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{ppo}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Owner",
        dataIndex: "po",
        ellipsis: {
          showTitle: false,
        },
        render: (po) => (
          <Tooltip
            placement="bottomLeft"
            title={po}
            overlayClassName="shareTooltip"
          >
            <span className={`${styles.tableText} fs-14`}>{po}</span>
          </Tooltip>
        ),
      },
      {
        title: "Procedure Saved In",
        dataIndex: "procedureSavedIn",
        ellipsis: {
          showTitle: false,
        },
        width: "10.4%",
        render: (procedureSavedIn) => (
          <Tooltip
            placement="bottomLeft"
            title={procedureSavedIn}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>
              {procedureSavedIn}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Client Server Path",
        dataIndex: "clientServerPath",
        ellipsis: {
          showTitle: false,
        },
        render: (clientServerPath) => (
          <Tooltip
            placement="bottomLeft"
            title={clientServerPath}
            overlayClassName="shareTooltip"
          >
            <span className={`${sectionStyles.tableText} fs-14`}>
              {clientServerPath}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "",
        dataIndex: "tag",
        width: "5.8%",
        render: (text, record) => (
          <div className={sectionStyles.fileStatus}>
            <FileStatusDiv status={this.HandleModalTableTag(record)} />
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "5.8%",
        render: (text, record) => {
          if (!(record.isOldMainTask || record.isNew)) {
            return;
          }

          if (record.isDeleted) {
            return (
              <div
                className={`${sectionStyles.modalTableActionIcon} ${sectionStyles.sectionRevokeIcon}`}
                onClick={() => this.ModalTableOnRevoke(record)}
              />
            );
          }

          return (
            <div
              className={`${sectionStyles.modalTableActionIcon} ${sectionStyles.sectionDeleteIcon}`}
              onClick={() => this.ModalTableOnDelete(record)}
            />
          );
        },
      },
    ];

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={this.OnCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={this.OnSubmit}
        >
          Submit for Approval
        </Buttons>
      </div>
    );

    return (
      <Modal
        title="Change Main Task"
        centered
        open={isChangeMainTaskModalOpen}
        footer={footer}
        closable={false}
        maskClosable={false}
        width="100rem"
        zIndex={zIndex}
        className="change-main-tasks-modal antModal"
      >
        {changeMainTaskModalTableData.length > 4 && (
          <div className="sharedTaskTableTop">
            {this.sharedTaskTableListControlIcon()}
          </div>
        )}
        <ShareTaskTable
          className="modal-change-main-task-table"
          tableKey={this.state.tableKey}
          data={changeMainTaskModalTableData}
          columns={tableColumn}
          scroll={{
            y:
              isExpendData || changeMainTaskModalTableData.length <= 4
                ? null
                : "14.3rem",
          }}
        />
        <p className="fileNumTip" style={{ color: "#333" }}>
          <span className="fileNum">{changeMainTaskModalTableData.length}</span>{" "}
          {this.setFileText(changeMainTaskModalTableData.length)} in total
        </p>
        <AddNewShareTask
          isAddNewShareTaskShow={isAddNewShareTaskShow}
          showAddNewShareTask={this.ShowAddNewShareTask}
          accountSelectOption={accountSelectOption}
          accountSelectValue={accountSelectValue}
          accountSelectOnChange={this.AccountSelectOnChange}
          taskTreePathOption={taskTreePathOption}
          taskTreePathValue={taskTreePathValue}
          taskTreePathOnSearch={this.TaskTreePathOnSearch}
          taskTreePathOnChange={this.TaskTreePathOnChange}
          addTaskOnClick={this.AddTaskOnClick}
          clearAddTaskOnClick={this.ClearAddTaskOnClick}
          showWarning={true}
          selectDisable={addNewSelectDisable || selectTaskDisable}
        />
        <p className={`${styles.reasonTitle} fs-14`}>
          Reason
          <img
            src={requiredWarning}
            alt="required"
            className={styles.requiredWarning}
          />
        </p>
        <div className={styles.reasonInputDiv}>
          <TextArea
            className="antTextare"
            autoSize={{
              minRows: 4,
              maxRows: 6,
            }}
            maxLength={1000}
            showCount={true}
            value={reseanValue}
            onChange={(e) => this.ReseanOnChange(e.target)}
            status={reasonInputStatus}
          />
        </div>
      </Modal>
    );
  }
}

export default ChangeMainTaskModal;
