import { createRef } from "react";
import { ExpendTextAreaByProps } from "../../../../../../base/basicComponents/input";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import ApproveUpdateSupportingFileTbody from "../../../../supportingFileSections/fields/supportingFileTbody/approveUpdateSupportingFileTbody";

const ReturnResonRef = createRef();
export default class ApproveUpdateProcedureFileTbody extends ApproveUpdateSupportingFileTbody {
  /**
   * function about show input if column type is input
   * @param {*} row
   * @param {*} rowColumn
   * @param {*} column
   * @param {*} rowIndex
   * @param {*} isShowReturnReason
   * @param {*} procedureNoteMaxLength
   * @returns
   */
  returnInput = (
    row,
    rowColumn,
    column,
    rowIndex,
    isShowReturnReason,
    procedureNoteMaxLength
  ) => {
    return (
      <>
        {rowColumn.name === "returnReason" ? (
          isShowReturnReason && (
            <>
              <ReturnReasonInput
                inputRef={ReturnResonRef}
                value={rowColumn.value}
                rowindex={rowIndex}
                isDisabled={
                  rowColumn.isDisabled ||
                  column.isDisabled ||
                  row.data.find((item) => item.name === "returnReason")
                    .value === null ||
                  row.data.find((item) => item.name === "returnReason")
                    .value === ""
                }
                isShowEditBtn={rowColumn.value !== null && column.showEdit}
                handleReturnEditClick={(e) => {
                  this.props.handleReturnClick(e);
                }}
                isShowReasonLogIcon={rowColumn.isShowReasonLog}
                reasonLog={rowColumn.reasonLog}
                isTable={true}
              />
            </>
          )
        ) : (
          <ExpendTextAreaByProps
            value={rowColumn.value}
            onChange={(ele) => this.props.inputOnChange(ele.target)}
            maxLength={procedureNoteMaxLength}
            disabled={true}
            dataname={column.dataName}
            rowindex={rowIndex}
            style={{
              display: column.isShow ? "table-cell" : "none",
            }}
            ref={column.ref}
            readOnly={rowColumn.readOnly}
            handleTableChange={this.props.handleChangeTableHeight}
            isExpendData={this.props.isExpendData}
          />
        )}
      </>
    );
  };
}
