import BaseService from "../base";
import ChangeMainTaskStepService from "./changeMainTaskStepService";

class ChangeMainTaskService extends BaseService {
  getActiveShareTaskList = (para, success, error) => {
    this.sendRequest("getChangeMainTaskList", para, success, error);
  };

  getRowData = (para, success, error) => {
    this.sendRequest("getTaskRowInfo", para, success, error);
  };

  getAccountDDL = (para, success, error) => {
    this.sendRequest("getAccountDDL", para, success, error);
  };

  submitForApproval = (para, success, error) => {
    this.sendRequest("changeMainTaskSubmitForApproval", para, success, error);
  };

  checkSubmit = (para, success, error) => {
    this.sendRequest("checkChangeMainTaskSubmit", para, success, error);
  };

  getChangeMainTaskStepItem = (para, success, error) => {
    ChangeMainTaskStepService.getStepData(para, success, error);
  };

  manageShareTaskTriggerAction = (para, success, error) => {
    this.sendRequest("changeMainTaskTriggerAction", para, success, error);
  };

  checkApprove = (para, success, error) => {
    this.sendRequest("checkChangeMainTaskApprove", para, success, error);
  };

  checkHasPendingSignOff = (para, success, error) => {
    this.sendRequest("checkHasPendingSignOff", para, success, error);
  };
}

const changeMainTaskService = new ChangeMainTaskService();

export default changeMainTaskService;
