import "./assets/css/globle/App.scss";
import LoaderPage from "./base/page/LoaderPage";
import LoaderPageSetting from "./config/LoaderPageSetting";
import ErrorComponent from "./base/common/ErrorComponent";

function App() {
  return (
    <div>
      <ErrorComponent>
        <LoaderPage
          login={LoaderPageSetting.login.login}
          loginProps={LoaderPageSetting.login.loginProps}
          main={LoaderPageSetting.main}
        />
      </ErrorComponent>
    </div>
  );
}

export default App;
