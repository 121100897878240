import React, { createRef } from "react";
import { ModalWithComponent } from "../../../../base/basicComponents/modal";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import BaseApplyJb from "../baseApplyJb";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ApplyForJbApproveSection from "../../../sections/applyForJbSection/applyForJbApproveSection";
import {
  actions,
  dataStatus,
  fileTags,
  handleApproveApplyJBSortFile,
  procedureJbStatus,
  savedInRadioValues,
} from "../../../publicDictionaryValues";
import Buttons from "../../../../base/basicComponents/button";
import styles from "../index.module.scss";
import applyForJbService from "../../../../service/applyForJb/applyForJbService";
import appService from "../../../../service/app";

const procedureFileRef = createRef();
const applyJbApproveRef = createRef();

export default class ApproveApplyJb extends BaseApplyJb {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      loading: false,
      modalState: {
        modalTitle: "",
        modalLabel: "",
        okText: "",
        cancelText: "",
        actionType: "",
        closable: false,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isModalWithComponentOpen: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 0,
        textAreaClassName: "",
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
      },
    };
    this.modalContentLabels = {
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
    };
  }

  handleFileData = (files) => {
    const dataList = [];
    if (files === null || files === undefined) {
      return;
    }
    const newFiles = handleApproveApplyJBSortFile(files, this._formateDate);
    newFiles.forEach((item) => {
      const lineId = this._generateGUID();
      dataList.push({
        data: [
          { name: "lineId", value: lineId },
          { name: "procedureFileID", value: item.procedureFileID },
          { name: "fileId", value: item.fileID },
          { name: "fileKey", value: item.fileKey },
          {
            name: "fileName",
            value: item.fileName,
            tagValue: item.fileTagID,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(item.createdDate),
          },
          { name: "uploadByName", value: item.uploadByName },
          { name: "uploadBy", value: item.uploadedBy },
          {
            name: "approveDate",
            value: this._formateDate(item.modifiedDate),
          },
          { name: "fileOperate", value: 1 },
          { name: "status", value: "" },
          { name: "dataStatusID", value: "" },
          { name: "previousStatus", value: "" },
          { name: "jbProcedure", value: procedureJbStatus[item.jbProcdure] },
          {
            name: "applyfor",
            value:
              item.jbProcdure !== item.applyfor
                ? procedureJbStatus[item.applyfor]
                : "",
            optionList: [],
          },
          { name: "isDelete", value: item.isDelete },
          { name: "rejectReason", value: item.rejectReason },
          { name: "attachmentFileID", value: item.attachmentFileID },
          {
            name: "attachment",
            value: item.attachmentName,
            fileId: item.attachmentFileID,
          },
        ],
        checked: false,
      });
    });
    return dataList;
  };

  handleSubmitFileData = (data, actionID) => {
    if (data === undefined) {
      return [];
    }

    return data.map((item) => {
      return {
        procedureFileId: item.data.find((x) => x.name === "procedureFileID")
          .value,
        rejectReason: item.data.find((x) => x.name === "rejectReason").value,
        attachmentFileID: item.data.find((x) => x.name === "attachmentFileID")
          .value,
        attachmentName: item.data.find((x) => x.name === "attachment").value,
        actionID: actionID,
        dataStatusID: item.data.find((x) => x.name === "status").value,
      };
    });
  };

  handleReturnPageName = () => {
    return "Approve JB Procedure";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ActiveShareTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleReturnProcedureFileSection = () => {
    const { applyJBStatus } = this.props;

    return (
      <ApplyForJbApproveSection
        ref={applyJbApproveRef}
        procedureFileRef={procedureFileRef}
        serverPathValue={applyJBStatus.clientServerPath}
        savedInRadioValue={applyJBStatus.procedureSaveInCategoryID}
        procedureJbStatusId={applyJBStatus.jbProcedureSavedIn}
        applyfor={
          applyJBStatus.applyfor !== applyJBStatus.jbProcedureSavedIn
            ? applyJBStatus.applyfor
            : ""
        }
        procedureFileData={this.handleFileData(
          applyJBStatus.applyJBStatusFilesList
        )}
        rejectReason={applyJBStatus.rejectReason}
      />
    );
  };

  handleCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: modalTitle,
        modalLabel: modalLabel,
        okText: "Confirm",
        cancelText: "Cancel",
        modalWidth: 520,
        handleOk: okMethod,
        handleCancel: cancelMethod,
      },
    });
  };

  handleCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideModal();
    this.props.closePage();
  };

  handleCancelClick = () =>
    this.handleCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleCancelOkClick,
      this.hideModal
    );

  handleReturnCancelButton = (isCancelDisabled) => (
    <Buttons
      color="grey"
      size="middle"
      onClick={this.handleCancelClick}
      disabled={isCancelDisabled}
      btnClassName={styles.approveActionBtn}
    >
      Cancel
    </Buttons>
  );

  handleControlErrorScroll = (top) => window.scrollTo(0, top || 0);

  handleCheckServerPathHighLight = () => {
    applyJbApproveRef.current.setServerPathError();
    applyJbApproveRef.current !== null &&
      this.handleControlErrorScroll(
        applyJbApproveRef.current.getServerPathOffset()
      );
  };

  handleApproveSubmitClickCheckRequired = () => {
    this.handleCheckServerPathHighLight();
  };

  handleReturnSubmitData = (actionID, actionName, listData) => {
    const applyJbData = listData;

    return {
      procedureProcessId: this.props.pageProps.procedureProcessId,
      procedureId: this.props.params.ProcedureID,
      procedureTaskId: this.props.params.ProcedureTaskId,
      workflowProcessInstanceId: this.props.params.WorkflowProcessInstanceId,
      flowCategoryID: this.props.params.FlowCategoryID,
      procedureStepID: this.props.params.ProcedureStepID,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      rejectReason: applyJbApproveRef.current.getServerPathReturnReasonValue(),
      jbProcedureSavedIn: this.props.applyJBStatus.jbProcedureSavedIn,
      applyfor:
        this.props.applyJBStatus.applyfor !==
        this.props.applyJBStatus.jbProcedureSavedIn
          ? this.props.applyJBStatus.applyfor
          : "",
      jBProcedureSavedInStatusID:
        applyJbApproveRef.current.getServerPathStatus(),
      fileList: this.handleSubmitFileData(applyJbData, actionID),
    };
  };

  _applyJBStatusTriggerSuccess = (response) => {
    if (response.succeeded === false) {
      console.log(response.message);
    } else {
      this.props.closePage();
    }
    this._hideLoading();
  };

  _applyJBStatusTriggerError = (response) => {
    console.log(response);
  };

  applyJBStatusTriggerAction = (para) => {
    applyForJbService.applyJBStatusTriggerStepAction(
      para,
      this._applyJBStatusTriggerSuccess,
      this._applyJBStatusTriggerError
    );
  };

  handleSubmitOkClick = (procedureFileData) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    let isTableDataReturn = false;
    const isServerPathReturn =
      applyJbApproveRef.current !== null &&
      applyJbApproveRef.current.getServerPathStatus() === dataStatus.reject;
    const fileList = procedureFileData;
    try {
      fileList.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        isTableDataReturn = statusData.value === dataStatus.reject;
        if (isTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }
    let para;
    if (isTableDataReturn || isServerPathReturn) {
      para = this.handleReturnSubmitData(actions.reject, "Reject", fileList);
    } else {
      para = this.handleReturnSubmitData(actions.approve, "Approve", fileList);
    }
    this.applyJBStatusTriggerAction(para);
  };

  handleApproveSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    const errorIndexList = [];
    this.props.applyJBStatus.procedureSaveInCategoryID !==
      savedInRadioValues.serverOnly &&
      procedureFileData.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const applyForValue = fileData.data.find(
          (item) => item.name === "applyfor"
        ).value;
        if (
          (statusData.value === null || statusData.value === "") &&
          applyForValue === "JB"
        ) {
          procedureFileData[index].isError = true;
          isTableError = true;
          errorIndexList.push(index);
          applyJbApproveRef.current.updateDataList(procedureFileData);
          tableErrorList.push(statusData);
        }
      });
    const applyfor =
      this.props.applyJBStatus.applyfor !==
      this.props.applyJBStatus.jbProcedureSavedIn
        ? this.props.applyJBStatus.applyfor.trim()
        : "";
    if (
      this.props.applyJBStatus.procedureSaveInCategoryID !==
        savedInRadioValues.systemOnly &&
      procedureJbStatus[applyfor] === "JB" &&
      (applyJbApproveRef.current.getServerPathStatus() === null ||
        applyJbApproveRef.current.getServerPathStatus() === undefined)
    ) {
      this.handleApproveSubmitClickCheckRequired();
    } else if (
      isTableError &&
      this.props.applyJBStatus.procedureSaveInCategoryID !==
        savedInRadioValues.serverOnly
    ) {
      const firstErrorTr = document.getElementById(
        `table-tr-${errorIndexList[0]}`
      );
      this.handleControlErrorScroll(
        firstErrorTr.offsetTop + firstErrorTr.clientHeight
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        () => this.handleSubmitOkClick(procedureFileData),
        this.hideModal
      );
    }
  };

  handleSubmitClick = () => {
    const procedureFileData =
      applyJbApproveRef.current === null
        ? []
        : applyJbApproveRef.current.getDataList();
    let tableErrorList = [];
    this.handleApproveSubmitClickCheck(procedureFileData, tableErrorList);
  };

  _checkStepPermissionSuccess = (result) => {
    this.hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.props.setPermission(noPermission);
    if (noPermission) return;
    this.handleSubmitClick();
  };

  checkStepPermission = () => {
    this.showLoading();
    const params = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      params,
      this._checkStepPermissionSuccess,
      (response) => console.log(response.message)
    );
  };

  handleReturnSubmitButton = (isSubmitDisabled) => (
    <Buttons
      color="blue"
      size="middle"
      onClick={this.checkStepPermission}
      disabled={isSubmitDisabled}
      btnClassName={styles.approveActionSubmitBtn}
    >
      Submit
    </Buttons>
  );

  handleActionButton = () => {
    return (
      <div className={styles.actionBtns}>
        <div className={styles.normalBtns}>
          {this.handleReturnCancelButton(this.state.pageState.isCancelDisabled)}
          {this.handleReturnSubmitButton(this.state.pageState.isSubmitDisabled)}
        </div>
      </div>
    );
  };

  handleConfirmModal = () => {
    const {
      modalTitle,
      modalLabel,
      okText,
      cancelText,
      closable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      isShowTextArea,
      textAreaPlacement,
      textAreaMaxLength,
      textAreaClassName,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;
    return (
      <ModalWithComponent
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
        cancelText={cancelText}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        maskClosable={maskClosable}
        isShowTextArea={isShowTextArea}
        textAreaOnChange={this.handleSetRejectValue}
        textAreaPlacement={textAreaPlacement}
        textAreaMaxLength={textAreaMaxLength}
        textAreaClassName={textAreaClassName}
        label={modalLabel}
        modalWidth={modalWidth}
      />
    );
  };

  ReleaseLock = () => {
    const para = {
      procedureId: this.props.params.ProcedureID,
      procedureSectionType: "ApplyForJB",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  hideLoading = () => {
    this.setState({
      loading: false,
    });
  };

  bindEvent = (procedureID) => {
    window.addEventListener("beforeunload", function (event) {
      event.preventDefault();

      const para = {
        procedureId: procedureID,
        procedureSectionType: "ApplyForJB",
        setLock: false,
      };
      ProcedureDetailService.setSectionLock(
        para,
        () => {
          (event || window.event).returnValue = null;
          return null; // IE/Edge
        },
        (response) => {
          console.log(response.message);
        }
      );
    });
  };
}
