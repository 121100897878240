import React, { createRef } from "react";
import ReadOnlyFileManagement from "../../../commonComponents/fileManagementFields/readOnlyFileManagement";
import SupportingFileSection from "../supportingFileSection";

const supportingFileTableRef = createRef();
export default class ReadOnlyCreateSupportingFileSection extends SupportingFileSection {
  componentDidMount() {
    supportingFileTableRef.current !== null &&
      supportingFileTableRef.current.updateDataList(
        this.props.supportingFileData
      );
  }

  render() {
    const { supportingFileRef } = this.props;
    return (
      <ReadOnlyFileManagement
        fileInputRef={supportingFileRef}
        fileTableRef={supportingFileTableRef}
        title={"Supporting Files"}
        clientPathShow={false}
        isScrollData={true}
        className="supportingFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        hasSubmit={false}
        data={this.props.supportingFileData}
        isCopyPage={this.props.isCopyPage}
      />
    );
  }
}
