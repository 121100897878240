import { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import DetailFileManagement from "../fields/procedureFileManagementFields/detailProcedureFileManagement";
import {
  detailTableSortFile,
  fileTags,
  getTimeZoneString,
  handleUploadErrorCode,
  openSections,
  proposedChangeTags,
  revisionFromList,
  revisionInfoMandatoryCheck,
  savedInRadioValues,
  shareTaskMassage,
  userRoles,
  getRequestDateMoment,
} from "../../../publicDictionaryValues";
import FileSectionBase from "../../../commonComponents/fileBase";
import createProcedureTableData from "../createProcedureTableData";
import NormalModal, {
  ModalWithScroll,
} from "../../../../base/basicComponents/modal";
import { timeOutMins } from "../../../../base/basicComponents/modal/controlPageBehavior";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import "../../../pages/procedureDetail/createProcedureDetail/style/index.module.scss";
import RevisionInformation from "../fields/revisionInformationFields/detailRevisionInformation";
import UpdateProcedureFileManagement from "../fields/procedureFileManagementFields/updateProcedureFileManagement";
import AppSetting from "../../../../config/AppSetting";

const ref = createRef();
const procedureModalRef = createRef();
const procedureFileRef = createRef();
const procedureFileTableRef = createRef();
const procedureFileModalTableRef = createRef();
const revisionInfoRef = createRef();
const editRef = createRef();

export default class ProcedureFileSection extends FileSectionBase {
  constructor(props) {
    super(props);
    this.state = {
      procedureFlientPathDisabled: false,
      procedureSaveInValue: savedInRadioValues.systemOnly,
      initOrLastSaveProcedureSaveInValue: savedInRadioValues.systemOnly,
      initOrLastSubmitProcedureSaveInValue: savedInRadioValues.systemOnly,
      procedureSubmited: false,
      procedureSaved: false,
      initProcedureDataList: [],
      initClientServerPath: "",
      procedureFileStepId: null,
      isModalOpen: false,
      modalChildContent: "",
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      isScrollModalOpen: false,
      scrollModalContent: "",
      scrollModalTitle: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
      isProcedureFileEditToolTipOpen: false,
      isShowTLButton: this.handleIsShowTLButton(),
      isShowApplyJbButton: this.handleIsShowApplyJbButton(),
      procedurePendingProcessId: null,
      applyForJBProcessStatus: null,
      clientServerJB: null,
      procedureStatusJB: null,
    };
    this.replaceList = [];
    this.tableFiles = [];
    this.fileList = [];
  }

  getRecordRoles = () => {
    const loginUserRoles = JSON.parse(localStorage.getItem("user"))?.roles;
    const recordRoles = [];
    loginUserRoles?.forEach((item) => {
      if (item.projectID === this.props.procedureAccountId) {
        recordRoles.push(item.roleID);
      }
    });

    return recordRoles;
  };

  isTlAndAbove = () => {
    const recordRoles = this.getRecordRoles();
    const rolesList = [
      userRoles.Manager,
      userRoles.Assistant,
      userRoles.TeamLeader,
    ];
    return (
      recordRoles?.findIndex(
        (roleItem) => rolesList.findIndex((item) => item === roleItem) !== -1
      ) > -1
    );
  };

  isAsAndAbove = () => {
    const recordRoles = this.getRecordRoles();
    const rolesList = [
      userRoles.Manager,
      userRoles.Assistant,
      userRoles.TeamLeader,
      userRoles.Specialist,
    ];
    return (
      recordRoles?.findIndex(
        (roleItem) => rolesList.findIndex((item) => item === roleItem) !== -1
      ) > -1
    );
  };

  handleIsShowTLButton = () => {
    if (
      this.isTlAndAbove() &&
      (this.props.procedureStatus === 3 || this.props.procedureStatus === 7)
    ) {
      return true;
    }

    return false;
  };

  handleIsShowApplyJbButton = () => {
    if (
      this.isAsAndAbove() &&
      (this.props.procedureStatus === 3 || this.props.procedureStatus === 7)
    ) {
      return true;
    }

    return false;
  };

  getFileList = () => {
    return this.fileList;
  };

  handleUploadLoading = (type) => {
    this.props.handleUploadLoading();
  };

  setUploadFileNum = () => {
    this.props.setUploadFileNum();
  };

  setFileNum = () => {
    this.props.setFileNum();
  };

  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    if (this.props.procedureStatus === 3) {
      dataList.unshift({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: fileId },
          { name: "fileKey", value: fileKey },
          {
            name: "fileName",
            value: fileName,
            tagValue: fileTags.new,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(getTimeZoneString(this._getDate())),
          },
          {
            name: "uploadByName",
            value: this.user.identityUserInfo.englishName,
          },
          {
            name: "uploadBy",
            value: this.user.identityUserInfo.internalUserID,
          },
          {
            name: "proposedChange",
            value: proposedChangeTags.new,
          },
          { name: "note", value: "" },
          { name: "approveDate", value: null },
          { name: "approveTime", value: null },
          { name: "fileOperate", value: 1 },
        ],
        checked: false,
      });
    } else {
      dataList.unshift({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: fileId },
          { name: "fileKey", value: fileKey },
          {
            name: "fileName",
            value: fileName,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(getTimeZoneString(this._getDate())),
          },
          {
            name: "uploadByName",
            value: this.user.identityUserInfo.englishName,
          },
          {
            name: "uploadBy",
            value: this.user.identityUserInfo.internalUserID,
          },
          { name: "note", value: "" },
          { name: "approveDate", value: null },
          { name: "fileOperate", value: 1 },
        ],
        checked: false,
      });
    }
  };

  uploadFileOnSuccess = (result, returnPara, ref) => {
    handleUploadErrorCode(result.errorCode);
    const procedureFileData = ref.current.getDataList();
    this.createLine(
      procedureFileData,
      result.storageFileID,
      returnPara.fileName
    );
    this.handleUploadLoading();
    ref.current.updateDataList(procedureFileData);
    if (returnPara.fileIndex < this.fileList.length - 1) {
      this.uploadFile(returnPara.fileIndex + 1, ref);
    }
  };

  _procedureSaveSuccess = (result) => {
    this.setState({
      procedureSaved: true,
      initOrLastSaveProcedureSaveInValue: this.state.procedureSaveInValue,
    });
    this.props.setIsProcedureFileModalChange();
    this.handleResetLock("ProcedureFile");
    this.props.procedureSaveSuccess(result);
  };

  procedureFileOnSave = (ref) => {
    this.props.showLoading();
    let combineDatalist = [];
    if (ref.current.getDataList() !== undefined) {
      let datalist = JSON.parse(JSON.stringify(ref.current.getDataList()));
      let lastSavedDataList = ref.current.getInitOrLastSaveData();
      let deleteList = ref.current.getDeleteList();
      if (datalist.length > 0) {
        if (this.state.procedureSaveInValue === savedInRadioValues.serverOnly) {
          datalist = this.checkDeleteDatalist(
            this.tableFiles,
            this.state.initProcedureDataList
          );
          combineDatalist = datalist.concat(deleteList);
        } else {
          datalist = this.checkUpdateDatalist(datalist, lastSavedDataList);
          combineDatalist = datalist.concat(deleteList);
        }
      } else {
        combineDatalist = [];
      }
    }
    let convertDataList = this.ConvertToBack(combineDatalist, ref);
    ProcedureDetailService.saveAsDraft(
      convertDataList,
      (response) => this._procedureSaveSuccess(response, ref),
      this._saveError
    );
  };

  /**
   * return table data when user upload files and click save then open modal
   * and change procedure saved in to client server only and click save or submit button
   * @returns
   */
  handleResetDataListInServerOnly = () => {
    const dataList = JSON.parse(
      JSON.stringify(this.state.initProcedureDataList)
    );
    const deleteList = [];
    dataList.forEach((item) => {
      deleteList.push({
        fileOperate: 3,
        fileId: this.findDataListDataItem("fileId", item),
        fileKey: this.findDataListDataItem("fileKey", item),
        fileName: this.findDataListDataItem("fileName", item),
        uploadBy: this.findDataListDataItem("uploadBy", item),
        note: this.findDataListDataItem("note", item),
        proposeChangeTagID: this.findDataListDataItem("proposedChange", item),
        approvedDate: this.findDataListDataItem("approveDate", item),
        fileTagID: item.data.find((item) => item.name === "fileName").tagValue,
      });
    });
    return deleteList;
  };

  ConvertToBack = (datalist, ref) => {
    let newDataList = this.getNewDataList(datalist);
    const clientPath = ref.current !== null && ref.current.getClientPath();
    const deleteList = this.handleResetDataListInServerOnly();
    return {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureFileStepId: this.state.procedureFileStepId,
      procedureSavedIn: this.state.procedureSaveInValue,
      clientServerPath: clientPath,
      fileList:
        this.state.procedureSaveInValue === savedInRadioValues.serverOnly
          ? deleteList
          : newDataList,
    };
  };

  _procedureSubmitSuccess = (result) => {
    this.setState({
      procedureSubmited: true,
      initOrLastSubmitProcedureSaveInValue: this.state.procedureSaveInValue,
    });
    this.props.setIsProcedureFileModalChange();
    this.props.procedureSubmitSuccess(result);
    this.handleResetLock("ProcedureFile");
  };

  hideAllModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  findDataListDataItem = (name, DataItem) => {
    return DataItem.data.find((item) => item.name === name) === null ||
      DataItem.data.find((item) => item.name === name) === undefined
      ? ""
      : DataItem.data.find((item) => item.name === name).value;
  };

  getNewUpdateDataList = (datalist) => {
    const newDataList = [];
    datalist.forEach((item) => {
      const replaceUserId = this.findDataListDataItem("replaceUserId", item);
      const replaceUserName = this.findDataListDataItem(
        "replaceUserName",
        item
      );
      const uploadByValue =
        replaceUserId && replaceUserName
          ? replaceUserId
          : this.findDataListDataItem("uploadBy", item);
      newDataList.push({
        fileOperate: 1,
        fileId: this.findDataListDataItem("fileId", item),
        fileKey: this.findDataListDataItem("fileKey", item),
        fileName: this.findDataListDataItem("fileName", item),
        uploadBy: uploadByValue,
        note: this.findDataListDataItem("note", item),
        proposeChangeTagID: this.findDataListDataItem("proposedChange", item),
        fileTagID: item.data.find((item) => item.name === "fileName").tagValue,
      });
    });
    return newDataList;
  };

  UpdateCovertToBack = (datalist, ref) => {
    let newDataList = this.getNewUpdateDataList(datalist);
    const clientPath = ref.current !== null && ref.current.getClientPath();
    const isClearDataList =
      this.state.procedureSaveInValue === null ||
      this.state.procedureSaveInValue === savedInRadioValues.serverOnly;
    const {
      currentCategory,
      currentRequestDate,
      currentRequestFrom,
      currentDesciption,
      currentAttachment,
      currentClientValue,
      currentRspValue,
    } = revisionInfoRef.current.state;
    return {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureFileStepId: null,
      procedureSavedIn: this.state.procedureSaveInValue,
      clientServerPath:
        this.state.procedureSaveInValue === null ? null : clientPath,
      fileList: isClearDataList ? [] : newDataList,
      revisionForm: {
        revisionDescription: currentDesciption,
        revisionCategoryID: currentCategory,
        requestDate: getRequestDateMoment(currentRequestDate, true),
        requestFromID: currentRequestFrom,
        requestFromDesc:
          currentRequestFrom === revisionFromList.Client
            ? currentClientValue
            : currentRspValue,
        attachmentFiles: [...currentAttachment],
      },
    };
  };

  handleSubmitClickAdjustDeleteFile = (convertDataList) => {
    const deleteIds = revisionInfoRef.current.state.deleteIds;
    const revisionAttachments = convertDataList.revisionForm.attachmentFiles.filter(item => !deleteIds.includes(item?.fileId));
    convertDataList.revisionForm.attachmentFiles = revisionAttachments;

    ProcedureDetailService.updateForApproval(
      convertDataList,
      (response) => this._procedureSubmitSuccess(response),
      this._submitError
    );
  }

  checkProcedureFileEditOnSuccess = (response, ref) => {
    if (response.result) {
      let combineDatalist = [];
      let deleteList = ref.current.getDeleteList();
      if (ref.current.getDataList() !== undefined) {
        let datalist = JSON.parse(JSON.stringify(ref.current.getDataList()));
        let lastSavedDataList = ref.current.getInitOrLastSaveData();
        const clientPath = ref.current !== null && ref.current.getClientPath();
        if (
          this.state.procedureSaveInValue === savedInRadioValues.serverOnly &&
          clientPath.trim() !== ""
        ) {
          datalist = this.checkDeleteDatalist(
            this.tableFiles,
            this.state.initProcedureDataList
          );
        } else {
          datalist = this.checkUpdateDatalist(datalist, lastSavedDataList);
        }
        combineDatalist = datalist.concat(deleteList);
      }
      if (
        revisionInfoRef !== undefined &&
        revisionInfoRef.current !== null &&
        this.props.procedureStatus === 3
      ) {
        let convertDataList = this.UpdateCovertToBack(combineDatalist, ref);
        this.handleSubmitClickAdjustDeleteFile(convertDataList);
      } else {
        let convertDataList = this.ConvertToBack(combineDatalist, ref);
        ProcedureDetailService.submitForApproval(
          convertDataList,
          (response) => this._procedureSubmitSuccess(response),
          this._submitError
        );
      }
    } else {
      if (this.props.procedureStatus === 3) {
        this._alertError(shareTaskMassage[35]);
      } else {
        this._alertError(shareTaskMassage[34]);
      }
      this.props.hideLoading();
    }
  };

  checkProcedureFileEditOnError = (error) => {
    console.log(error);
    // this._alertError("Check Procedure Files Section Edit Error!");
  };

  handleSubmitModalConfirm = (ref) => {
    this.hideAllModal();
    ref.current.handleCloseModal !== undefined &&
      ref.current.handleCloseModal();
    this.props.showLoading();
    const param = {
      procedureAccountId: this.props.procedureAccountId,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
    };
    ProcedureDetailService.checkEditSection(
      param,
      (result) => this.checkProcedureFileEditOnSuccess(result, ref),
      this.checkProcedureFileEditOnError
    );
  };

  procedureMandatoryCheck = (ref) => {
    let datalist = ref.current.getDataList();
    let lastSavedDataList = ref.current.getInitOrLastSaveData();
    if (datalist.length > 0) {
      datalist = this.checkUpdateDatalist(datalist, lastSavedDataList);
    }
    let deleteList = ref.current.getDeleteList();
    let combineDatalist = datalist.concat(deleteList);
    let convertDataList = this.ConvertToBack(combineDatalist, ref);

    let isFileAllDelete = true;
    datalist.forEach(file => {
      const proposedChange = file.data.find(item => item.name === "proposedChange").value;
      if(proposedChange !== proposedChangeTags.delete) {
        isFileAllDelete = false;
      }
    });
    const shouldShowErrorServer =
      this.state.procedureSaveInValue !== savedInRadioValues.serverOnly &&
      this.state.procedureSaveInValue !== null &&
      (datalist.length === 0 || isFileAllDelete);
    
    const shouldShowErrorSystem =
      this.state.procedureSaveInValue !== savedInRadioValues.systemOnly &&
      convertDataList.clientServerPath.trim() === "";

    if (revisionInfoRef !== undefined && revisionInfoRef.current !== null) {
      const modalContent = document.getElementsByClassName("modalContent")[0];
      revisionInfoMandatoryCheck(revisionInfoRef, modalContent);
    }

    if (shouldShowErrorServer) {
      ref.current.setFileTableStatus("error");
    }
    if (shouldShowErrorSystem) {
      ref.current.setClientPathStatus(
        "error",
        "Please Enter Client Server Path"
      );
      this.hideModal();
      this.props.hideLoading();
    }
  };

  handleProcedureSubmitCheckSuccess = (response, ref, isUpdateProcedure) => {
    const modalChildContent = isUpdateProcedure ?
      <span>Are you sure to submit?<br/>Please ensure that the updates made in the 
      Procedure File section are consistent with the selected revision category, 
      especially for "Procedure Storage Change".</span>
      : "Are you sure to submit?";
    if (response.result) {
      this.setState({
        isModalOpen: true,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: modalChildContent,
        handleModalConfirm: () => this.handleSubmitModalConfirm(ref),
        handleModalCancel: () => {},
      });
    } else {
      const message = shareTaskMassage[response.messageCode];
      this._alertError(message);
    }
  };

  handleProcedureSubmitCheckError = (error) => {
    console.log(error);
    this._alertError("Check Failed!");
  };

  handleSubmitProcedureCheck = (ref) => {
    const param = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      procedureAccountId: this.props.procedureAccountId,
    };
    if (this.props.procedureStatus === 3) {
      ProcedureDetailService.checkUpdateProcedureFileSubmit(
        param,
        (response) => this.handleProcedureSubmitCheckSuccess(response, ref, true),
        this.handleProcedureSubmitCheckError
      );
    } else {
      ProcedureDetailService.checkCreateProcedureFileSubmit(
        param,
        (response) => this.handleProcedureSubmitCheckSuccess(response, ref, false),
        this.handleProcedureSubmitCheckError
      );
    }
  };

  procedureFileOnSubmit = (ref) => {
    if (ref.current !== null) {
      this.handleSubmitProcedureCheck(ref);
    }
  };

  checkEditSectionLock = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.props.actionType,
      timeOutMins: timeOutMins,
    };
    ProcedureDetailService.checkSectionLock(
      para,
      (response) =>
        this.checkProcedureFileWhetherLock(
          response.data.isLocked,
          response.data.lockUser
        ),
      (response) => console.log(response.message)
    );
  };

  /**
   * success callback for checkEditSection api
   * @param {object} response
   */
  checkUpdateProcedureFileEditOnSuccess = (response) => {
    if (response.procedureProcessStatus !== this.props.procedureStatus) {
      window.location.reload();
      return;
    }
    if (response.result) {
      this.checkEditSectionLock();
    } else {
      if (response.messageCode === 25) {
        this.props.setLock("This section is pending approval.");
      } else {
        this.props.setLock(shareTaskMassage[response.messageCode]);
      }
      this.setEditToolTipOpen(true);
      editRef.current.focus();
    }
  };

  /**
   * error callback for checkEditSection api
   * @param {*} error
   */
  checkUpdateProcedureFileEditOnError = (error) => {
    console.log(error);
    // this._alertError("Check Procedure Files Edit Error!");
  };

  handleEditCheck = () => {
    const param = {
      procedureAccountId: this.props.procedureAccountId,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
    };
    ProcedureDetailService.checkEditSection(
      param,
      this.checkUpdateProcedureFileEditOnSuccess,
      this.checkUpdateProcedureFileEditOnError
    );
  };

  handleClientPathDisabled = (value) => {
    let tempClientPathDisabled;
    switch (value) {
      case savedInRadioValues.systemOnly:
        tempClientPathDisabled = true;
        break;
      case savedInRadioValues.systemAndClientServer:
        tempClientPathDisabled = false;
        break;
      case savedInRadioValues.serverOnly:
        tempClientPathDisabled = false;
        break;
      default:
        tempClientPathDisabled = true;
    }

    return tempClientPathDisabled;
  };

  _handleUpdateRadioOnClick = (value, ref) => {
    let currentValue = null;
    const dataList = ref.current.getDataList();
    this.tableFiles = JSON.parse(JSON.stringify(dataList));
    let tempClientPathDisabled = this.handleClientPathDisabled(value);
    if (value !== this.state.procedureSaveInValue) {
      currentValue = value;
    }
    
    ref.current.updateRadioValue(
      currentValue,
      this.state.procedureSaveInValue
    );
    this.setState({
      procedureSaveInValue: currentValue,
      procedureFlientPathDisabled: tempClientPathDisabled,
    });
    ref.current.enableOrDisableSaveSubmitCancleButton(
      ref.current.getDataList(),
      ref.current.getClientPath(),
      value
    );
  };

  updateRadioOnClick = (e, ref) => {
    this._handleUpdateRadioOnClick(e.target.value, ref);
    ref.current.clearClientPathStatus();
  };

  radioOnChange = (e, ref) => {
    this._handleRadioOnChange(e.target.value, ref);
    ref.current.clearClientPathStatus();
  };

  /* funtion about click confirm when saved in radio button changed to client server only */
  handleChangeSavedInCofirm = (value, ref) => {
    this.props.procedureStatus === 3
      ? ref.current.updateRadioValue(value, this.state.procedureSaveInValue)
      : ref.current.updateRadioValue(value);
    this.setState({
      procedureSaveInValue: value,
      procedureFlientPathDisabled: false,
      isModalOpen: false,
      modalChildContent: "",
      okText: "",
    });
  };

  /* funtion about saved in radio button change */
  _handleRadioOnChange = (value, ref) => {
    const dataList = ref.current.getDataList();
    this.tableFiles = JSON.parse(JSON.stringify(dataList));
    let tempClientPathDisabled = this.handleClientPathDisabled(value);
    ref.current.updateRadioValue(value);
    this.setState({
      procedureSaveInValue: value,
      procedureFlientPathDisabled: tempClientPathDisabled,
    });
    ref.current.enableOrDisableSaveSubmitCancleButton(
      ref.current.getDataList(),
      ref.current.getClientPath(),
      value
    );
  };

  handleCancelModalConfirm = (ref) => {
    this.hideModal();
    this.hideScrollModal();
    const dataList = JSON.parse(
      JSON.stringify(ref.current.getInitOrLastSaveData())
    );
    const clientPath = ref.current.getLastSaveClientPath();
    this._handleRadioOnChange(
      this.state.initOrLastSaveProcedureSaveInValue,
      ref
    );
    ref.current.updateDataList(
      dataList,
      clientPath,
      this.state.initOrLastSaveProcedureSaveInValue
    );
    ref.current.updateClientPathAndDeleteData(clientPath);
    this.handleResetLockWithoutRefresh("ProcedureFile");
    this.props.setActionType(null);
  };

  procedureFileOnCancel = (ref) => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: () => this.handleCancelModalConfirm(ref),
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  /* function about upload depulicate file when upload file */
  handleReplaceFile = (event, ref) => {
    this.fileList = [...event.target.files];
    if (this.fileList.length > 10) {
      this._alertError(
        "The limit of a single upload is 10 files, no larger than 300MB each."
      );
      return;
    }

    for (let fileItem of this.fileList) {
      if (fileItem.size > 300 * 1024 * 1024) {
        this._alertError(
          "The limit of a single upload is 10 files, no larger than 300MB each."
        );
        return;
      }
    }
    const tableDataList = ref.current.getDataList();
    let dataList = [];
    tableDataList.forEach((data) => {
      const item = data.data.find((dataItem) => dataItem.name === "fileName");
      dataList.push(item);
    });

    if (this.props.procedureStatus === 3) {
      this.updateFileProcess(0, dataList, tableDataList, ref);
    } else {
      this.FileProcess(0, dataList, tableDataList, ref);
    }
  };

  createFiles = (createFilesRef = ref, openModal) => {
    this.props.showLoading();
    const procedureId = this.props.procedureId;
    ProcedureDetailService.getProcedureFileSection(
      { procedureId: procedureId },
      (response) => {
        const newFiles = detailTableSortFile(
          response.procedureFiles,
          this._formateDate
        );
        const dataList =
          this.props.procedureStatus === 3
            ? createProcedureTableData.createUpdateProcedureData(
                newFiles,
                createFilesRef === "modal" ? null : createFilesRef
              )
            : createProcedureTableData.createProcedureData(
                newFiles,
                createFilesRef === "modal" ? null : createFilesRef
              );
        let radioValue =
          response.procedureSaveInCategoryID ?? savedInRadioValues.systemOnly;
        const clientServerPath =
          response.clientServerPath === null ? "" : response.clientServerPath;

        this.setState(
          {
            procedureSaveInValue: radioValue,
            initProcedureDataList: JSON.parse(JSON.stringify(dataList)),
            initClientServerPath: clientServerPath,
            initOrLastSaveProcedureSaveInValue:
              response.procedureSaveInCategoryID === null &&
              this.props.procedureStatus !== 3
                ? savedInRadioValues.systemOnly
                : response.procedureSaveInCategoryID,
            procedureFileStepId: response.procedureFileStepId,
            applyForJBProcessStatus: response.applyforJBProcessStatus,
            clientServerJB: response.jbProcedureSaveIn,
            procedureStatusJB: response.procedureJBStatusID,
          },
          () => openModal && openModal()
        );
        if (createFilesRef?.current) {
          const procedureFileData = JSON.parse(
            JSON.stringify(createFilesRef.current.getDataList())
          );
          createFilesRef.current.updateRadioValue(radioValue);
          createFilesRef.current.updateServerPathValue(clientServerPath);
          createFilesRef.current.updateLastSaveClientPath(clientServerPath);
          createFilesRef.current.updateJbProcedureSaveIn(
            response.jbProcedureSaveIn
          );
          createFilesRef.current.updateInitDataList(procedureFileData);
        }
        this.setState({
          procedurePendingProcessId: response.pendingProcessId,
        });
        this.props.hideLoading();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  setEditToolTipOpen = (isOpen) => {
    this.setState({
      isProcedureFileEditToolTipOpen: isOpen,
    });
  };

  handleProcedureFileLock = () => {
    this.setEditToolTipOpen(true);
  };

  handleReturnModalProcedureFileTable = (isShowShareBtn, isShowDeleteBtn) => {
    const {
      procedureFlientPathDisabled,
      procedureSaveInValue,
      initOrLastSaveProcedureSaveInValue,
      initOrLastSubmitProcedureSaveInValue,
      procedureSubmited,
      procedureSaved,
      procedurePendingProcessId,
    } = this.state;
    return (
      <ProcedureBlock
        child={this.handleReturnProcedureFileTable({
          procedureFlientPathDisabled: procedureFlientPathDisabled,
          procedureSaveInValue: procedureSaveInValue,
          initOrLastSaveProcedureSaveInValue:
            initOrLastSaveProcedureSaveInValue,
          initOrLastSubmitProcedureSaveInValue:
            initOrLastSubmitProcedureSaveInValue,
          procedureSubmited: procedureSubmited,
          procedureSaved: procedureSaved,
          isShowUploadBtn: true,
          isShowDeleteBtn: isShowDeleteBtn,
          isShowDownloadBtn: true,
          isShowShareBtn: isShowShareBtn,
          isShowActionBtns: true,
          isShowProcedureFileEdit: false,
          isShowUpdate: false,
          isShowHistoryVersion: false,
          savedInDisabled: false,
          className: "procedureModalFiles",
          noteDisabled: false,
          ref: procedureModalRef,
          fileTableRef: procedureFileModalTableRef,
          pageType: this.props.pageType,
          title: "",
          hintMsg: this.props.hintMsg,
          inModal: true,
          isProcedureSection: false,
          procedurePendingProcessId: procedurePendingProcessId,
        })}
        isShowArrow={false}
        className="processProcedureModalBlock procedureFileModalBlock"
        isOpen={true}
      />
    );
  };

  openCreateModal = () => {
    this.setState({
      isScrollModalOpen: true,
      isProcedureFileEditToolTipOpen: false,
      scrollModalContent: this.handleReturnModalProcedureFileTable(false, true),
      scrollModalTitle: "Procedure Files",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  handleProcedureFileUnlock = () => {
    this.createFiles("modal", this.openCreateModal);
  };

  openUpdateModal = () => {
    this.setState({
      isScrollModalOpen: true,
      isProcedureFileEditToolTipOpen: false,
      scrollModalContent: (
        <>
          <ProcedureBlock
            child={
              <RevisionInformation
                title="Revision Information"
                updateFileNumLoading={this.props.updateFileNumLoading}
                openLoading={this.props.showLoading}
                hideLoading={this.props.hideLoading}
                accountId={this.props.procedureAccountID}
                ref={revisionInfoRef}
              />
            }
            isShowArrow={false}
            className="procedureRevisionBlock processProcedureModalBlock"
            isOpen={true}
          />
          {this.handleReturnModalProcedureFileTable(false, false)}
        </>
      ),
      scrollModalTitle: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  handleUpdateProcedureFileUnlock = () => {
    this.createFiles(procedureModalRef, this.openUpdateModal);
  };

  checkProcedureFileWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.procedureStatus === 3
        ? this.props.setLock(`${lockUser} is updating this procedure.`)
        : this.props.setLock(`${lockUser} is editing this section.`);
      this.handleProcedureFileLock();
    } else {
      this.props.setActionType(this.props.actionType);
      this.props.setLock("");
      if (this.props.procedureStatus === 3) {
        this.handleUpdateProcedureFileUnlock();
      } else {
        this.handleProcedureFileUnlock();
      }
    }
  };

  handleReturnProcedureFileTable = (obj) => {
    const {
      procedureFlientPathDisabled,
      procedureSaveInValue,
      initOrLastSaveProcedureSaveInValue,
      initOrLastSubmitProcedureSaveInValue,
      procedureSubmited,
      procedureSaved,
      isShowUploadBtn,
      isShowDeleteBtn,
      isShowDownloadBtn,
      isShowShareBtn,
      isShowActionBtns,
      isShowProcedureFileEdit,
      isShowUpdate,
      isShowHistoryVersion,
      savedInDisabled,
      className,
      noteDisabled,
      ref,
      fileTableRef,
      pageType,
      title,
      hintMsg,
      inModal,
      isProcedureSection,
    } = obj;

    const {
      initClientServerPath,
      isProcedureFileEditToolTipOpen,
      initProcedureDataList,
      isShowApplyJbButton,
      isShowJbProcedureSaved,
      isShowTableJbProcedure,
      isShowTLButton,
      procedurePendingProcessId,
      applyForJBProcessStatus,
      clientServerJB,
      procedureStatusJB,
    } = this.state;
    const {
      procedureId,
      procedureTaskId,
      procedureAccountId,
      procedureStatus,
      procedureJbStatusId,
      taskInformationData,
      showLoading,
      hideLoading,
      setActionType,
    } = this.props;

    const componentProps = {
      fileInputRef: procedureFileRef,
      fileTableRef: fileTableRef,
      title: title,
      clientPathShow: true,
      clientPathDisabled: procedureFlientPathDisabled,
      handleFileSelect: (e) => this.handleReplaceFile(e, ref),
      savedInRadioValue: procedureSaveInValue,
      clientPath: initClientServerPath,
      initOrLastSaveProcedureSaveInValue: initOrLastSaveProcedureSaveInValue,
      initOrLastSubmitProcedureSaveInValue:
        initOrLastSubmitProcedureSaveInValue,
      onSave: () => this.procedureFileOnSave(ref),
      onSubmit: () => this.procedureFileOnSubmit(ref),
      onCancel: () => this.procedureFileOnCancel(ref),
      handleSubmitModalConfirm: () => this.handleSubmitModalConfirm(ref),
      hasSubmit: procedureSubmited,
      hasSave: procedureSaved,
      isScrollData: true,
      className: className,
      isShowExpendIcon: true,
      isShowUploadBtn: isShowUploadBtn,
      isShowDeleteBtn: isShowDeleteBtn,
      isShowDownloadBtn: isShowDownloadBtn,
      isShowShareBtn: isShowShareBtn,
      isShowActionBtns: isShowActionBtns,
      isShowProcedureFileEdit: isShowProcedureFileEdit,
      isShowUpdate: isShowUpdate,
      isShowHistoryVersion: isShowHistoryVersion,
      handleEditClick: () => this.handleEditCheck(),
      savedInDisabled: savedInDisabled,
      hintMsg: hintMsg,
      isEditToolTipOpen: isProcedureFileEditToolTipOpen,
      onBlur: () => this.setEditToolTipOpen(false),
      noteDisabled: noteDisabled,
      pageType: pageType,
      status: procedureStatus,
      initProcedureDataList: initProcedureDataList,
      initClientServerPath: initClientServerPath,
      procedureMandatoryCheck: () => this.procedureMandatoryCheck(ref),
      isShowTLButton: isShowTLButton,
      isShowApplyJbButton: isShowApplyJbButton,
      isShowJbProcedureSaved: isShowJbProcedureSaved,
      isShowTableJbProcedure: isShowTableJbProcedure,
      procedureId: procedureId,
      procedureTaskId: procedureTaskId,
      procedureAccountId: procedureAccountId,
      procedureStatus: procedureStatus,
      revisionInfoRef: revisionInfoRef,
      inModal: inModal,
      procedureJbStatusId: procedureJbStatusId,
      taskInformationData: taskInformationData,
      isProcedureSection: isProcedureSection,
      isProcedureFile: true,
      _showLoading: showLoading,
      _hideLoading: hideLoading,
      setActionType: setActionType,
      procedurePendingProcessId: procedurePendingProcessId,
      isProcedureDetailSection: true,
      applyForJBProcessStatus: applyForJBProcessStatus,
      editRef: editRef,
      isShowSavedInIcon: !isProcedureSection && procedureStatus === 3,
      clientServerJB: clientServerJB,
      procedureStatusJB: procedureStatusJB,
    };

    return procedureStatus === 3 ? (
      <UpdateProcedureFileManagement
        ref={ref}
        handleRadioChange={() => {}}
        handleRadioClick={(e) => {
          this.updateRadioOnClick(e, ref);
        }}
        {...componentProps}
        title="Procedure Files"
      />
    ) : (
      <DetailFileManagement
        ref={ref}
        handleRadioClick={() => {
          // empty function
        }}
        handleRadioChange={(e) => {
          this.radioOnChange(e, ref);
        }}
        {...componentProps}
      />
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.procedureStatus !== this.props.procedureStatus ||
      prevProps.procedureAccountId !== this.props.procedureAccountId
    ) {
      if (
        this.isTlAndAbove() &&
        (this.props.procedureStatus === 3 || this.props.procedureStatus === 7)
      ) {
        this.setState({
          isShowTLButton: true,
        });
      }

      if (
        this.isAsAndAbove() &&
        (this.props.procedureStatus === 3 || this.props.procedureStatus === 7)
      ) {
        this.setState({
          isShowApplyJbButton: true,
        });
      }

      /**
       * open procedure section when click 'Update Procedure' button on Procedure list Table
       */
      if (
        this.props.openSection === openSections.procedureFile &&
        this.props.procedureAccountID !== undefined
      ) {
        this.handleEditCheck();
      }
    }
  }

  render() {
    const { isShowTable, pageType } = this.props;
    const modalWidth =
      document.getElementsByClassName("procedureBlock")[0] !== undefined &&
      document.getElementsByClassName("procedureBlock")[0].offsetWidth;
    return (
      <>
        <ProcedureBlock
          child={
            isShowTable &&
            this.handleReturnProcedureFileTable({
              procedureFlientPathDisabled: true,
              procedureSaveInValue: this.state.procedureSaveInValue,
              initOrLastSaveProcedureSaveInValue:
                this.state.initOrLastSaveProcedureSaveInValue,
              initOrLastSubmitProcedureSaveInValue:
                this.state.initOrLastSubmitProcedureSaveInValue,
              procedureSubmited: this.state.procedureSubmited,
              procedureSaved: this.state.procedureSaved,
              isShowUploadBtn: false,
              isShowDeleteBtn: false,
              isShowDownloadBtn: true,
              isShowShareBtn: true,
              isShowActionBtns: false,
              isShowProcedureFileEdit: this.props.isShowProcedureFileEdit,
              isShowUpdate: false,
              isShowHistoryVersion: true,
              savedInDisabled: true,
              className: "procedureFiles",
              noteDisabled: true,
              ref: ref,
              fileTableRef: procedureFileTableRef,
              pageType: pageType,
              title: "Procedure Files",
              hintMsg: this.props.hintMsg,
              inModal: false,
              isProcedureSection: true,
              procedurePendingProcessId: this.state.procedurePendingProcessId,
            })
          }
          isShowArrow={false}
          className="processProcedureBlock procedureFileBlock"
          isOpen={true}
        />
        <NormalModal
          okText={this.state.modalOkText}
          cancelText={this.state.modalCancelText}
          childContent={<p className="fs-16">{this.state.modalChildContent}</p>}
          isModalOpen={this.state.isModalOpen}
          handleOk={this.state.handleModalConfirm}
          handleCancel={() => {
            this.state.handleModalCancel();
            this.hideModal();
          }}
          closable={false}
        />
        <ModalWithScroll
          isModalOpen={this.state.isScrollModalOpen}
          modalContent={this.state.scrollModalContent}
          title={this.state.scrollModalTitle}
          width={modalWidth}
          actionType={this.props.actionType}
        />
      </>
    );
  }
}
