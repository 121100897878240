import React, { createRef } from "react";
import NormalModal from "../../../../base/basicComponents/modal";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import TlReviewProcedureSection from "../../../sections/TlReviewSection/TlReviewProcedureSection";
import BaseTlReviewPage from "../basetlReviewPage";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import tlReviewService from "../../../../service/tlReviewService";
import { actions } from "../../../publicDictionaryValues";

const procedureFileRef = createRef();
const tlReviewProcedureModalRef = createRef();

export default class TlReviewPending extends BaseTlReviewPage {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      loading: false,
    };
  }
  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  handleReturnPageName = () => {
    return "TL Review Procedure";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return <ActiveShareTaskReadOnly shareTaskData={shareTaskData} />;
  };

  handleReturnProcedureFileSection = () => {
    const { fileTableData, jbProcedureSavedIn } = this.props;
    const { savedInRadioValue } = this.props.savedInProps;
    const { serverPathValue } = this.props.serverPathProps;

    return (
      <ReadOnlyProcedureFileSection
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
        jbProcedureSavedIn={jbProcedureSavedIn}
      />
    );
  };

  handleReturnTlReviewSection = () => {
    const { tlReviewData } = this.props;
    return (
      <TlReviewProcedureSection
        ref={tlReviewProcedureModalRef}
        onSubmit={this.tlReviewOnSubmit}
        onCancel={this.tlReviewOnCancel}
        showLoading={this.showLoading}
        hideLoading={this.hideLoading}
        reviewedDate={this._formateDate(tlReviewData.reviewed)}
        isInModal={false}
        params={this.props.params}
      />
    );
  };

  handleConfirmModal = () => {
    const { isModalOpen, modalChildContent, handleModalConfirm } = this.state;
    return (
      <div>
        <NormalModal
          okText="Confirm"
          cancelText="Cancel"
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={this.hideModal}
        />
      </div>
    );
  };

  hideModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
  };

  ReleaseLock = () => {
    const para = {
      procedureId: this.props.params.ProcedureID,
      procedureSectionType: "TLReview",
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  tlReviewOnSubmit = (reviewResultText, fileList) => {
    if (reviewResultText.trim() === "") {
      tlReviewProcedureModalRef.current.showInputError();
    } else {
      this.setState({
        isModalOpen: true,
        modalChildContent: "Are you sure to submit?",
        handleModalConfirm: () =>
          this.submitTlReviewProcedure(reviewResultText, fileList),
      });
    }
  };

  submitTlReviewProcedure = (reviewResultText, fileList) => {
    this.setState({
      loading: true,
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    const list = fileList.map((item) => {
      return {
        fileId: item.storageFileID,
        fileName: item.name,
      };
    });
    const para = {
      procedureProcessId: this.props.pageProps.procedureProcessId,
      workflowProcessInstanceId: this.props.params.WorkflowProcessInstanceId,
      flowCategoryID: this.props.params.FlowCategoryID,
      actionName: "Submit",
      actionID: actions.submit,
      procedureStepID: this.props.params.ProcedureStepID,
      rejectReason: null,
      tlReviewStepId: this.props.tlReviewData.tlReviewStepID,
      reviewResult: reviewResultText,
      fileList: list,
    };
    tlReviewService.tlReviewTriggerStepAction(
      para,
      this.createTLReviewProcedureOnSuccess,
      this.createTLReviewProcedureOnError
    );
  };

  createTLReviewProcedureOnSuccess = () => {
    this.hideLoading();
    this._alertSuccess("Submit Successful");
    this.ReleaseLock();
  };

  createTLReviewProcedureOnError = () => {
    this.hideLoading();
    this._alertError("Submit Failed");
  };

  tlReviewOnCancel = () => {
    this.setState({
      isModalOpen: true,
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.cancelTlReviewProcedure,
    });
  };

  cancelTlReviewProcedure = () => {
    this.setState({
      loading: true,
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        // thre is a empty function
      },
    });
    tlReviewProcedureModalRef.current.setDefault();
    this.ReleaseLock();
  };

  showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  hideLoading = () => {
    this.setState({
      loading: false,
    });
  };

  bindEvent = (procedureID) => {
    window.onbeforeunload = () => {
      if (this.actionType === null) {
        return;
      }
      this._setLock(procedureID, "TLReview", false);
    };
  };

  componentDidMount() {
    this._setLock(this.props.params.ProcedureID, "TLReview", true);
    this.bindEvent(this.props.params.ProcedureID);
  }
}
