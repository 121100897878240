import React, { createRef } from "react";
import NormalSpin from "../../../base/basicComponents/spin";
import ApproveShareService from "../../../service/shareTask/approveShareService";
import {
  dataStatus,
  actions,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import styles from "../procedureFileProcess/style/index.module.scss";
import ShareProcessBase from "../../commonComponents/shareProcessBase";
import ReviseShareTaskProcess from "./reviseShareTask";
import ApproveShareTaskProcess from "./approveShareTask";
import ReviseShareTaskComplete from "./reviseShareTaskComplete";
import ApproveShareTaskComplete from "./approveShareTaskComplete";
import SubmitShareTaskComplete from "./submitShareTaskComplete";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();

export default class ManageShareTaskProcess extends ShareProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initManageShareTaskProcedureFileState();
    this.procedureFileStepID = null;
    this.savedInReturnReasonLog = [];
    this.serverPathReturnReasonLog = [];
    this.initProcedureTableData = [];
  }

  handleManageShareTaskPageState = () => {
    return {
      requestByID: null,
      requestByName: null,
      receiveDate: null,
      procedureID: null,
      procedureStepID: null,
      procedureStatus: null,
      isSaveDisabled: false,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      procedureFolder: {},
      rejectReason: null,
      rejectStatus: null,
      currentRole: null,
      actionName: null,
      loading: false,
      procedureStepStatus: null,
      uploadProcedureFileNum: 0,
      procedureFileNum: 0,
      procedureNote: "",
    };
  };

  initManageShareTaskProcedureFileState = () => {
    this.state = {
      pageState: this.handleManageShareTaskPageState(),
      initTableDate: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: this.initProcedureFileTableState,
      isReturnReasonLogOpen: false,
      fileTableData: [],
      supportingTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      shareTaskData: {},
      ppoList: [],
      jbProcedureSavedIn: null,
    };
  };

  handleManageShareTaskSavedInSectionState = (result) => {
    return {
      ...this.state.savedInSectionState,
      savedInReturnReasonInputValue:
        result.procedureFolder?.procedureSaveInCategoryReturnReason || null,
      savedInRadioValue:
        result.procedureFolder?.procedureSaveInCategoryID || null,
      isSavedInReturned:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
      procedureSaveInCategoryStatusID:
        result.procedureFolder?.procedureSaveInCategoryStatusID || null,
      isSavedInApproveBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.approval,
      isSavedInReturnBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
    };
  };

  handleManageShareTaskServerPathSectionStateState = (result) => {
    return {
      ...this.state.serverPathSectionState,
      serverPathValue: result.procedureFolder?.clientServerPath || null,
      isServerPathReturned:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue:
        result.procedureFolder?.clientServerPathReturnReason || null,
      clientServerPathStatusID:
        result.procedureFolder?.clientServerPathStatusID || null,
      clientServerPathStatusName:
        result.procedureFolder?.clientServerPathStatusName || null,
      clientServerPath: result.procedureFolder?.clientServerPath || "",
      isServerPathApproveBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID ===
        dataStatus.approval,
      isServerPathReturnBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
    };
  };

  setManageShareTaskSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState:
        this.handleManageShareTaskSavedInSectionState(result),
      serverPathSectionState:
        this.handleManageShareTaskServerPathSectionStateState(result),
    });
  };

  setManageShareTaskSavedInReturnReasonLog = (item, index) => {
    if (
      item.procedureSaveInCategoryStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.savedInReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.procedureSaveInCategoryReturnReason,
      });
    }
  };

  setManageShareTaskServerPathReturnReasonLog = (item, index) => {
    if (
      item.clientServerPathStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.serverPathReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.clientServerPathReturnReason,
      });
    }
  };

  setManageShareTaskSavedInAndServerPathReturnReasonLog = (list) => {
    list.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    list.forEach((item, index) => {
      this.setManageShareTaskSavedInReturnReasonLog(item, index);
      this.setManageShareTaskServerPathReturnReasonLog(item, index);
    });
  };

  initManageShareTaskServerPathSectionState = (result) => {
    this.initServerPathSectionState = {
      ...this.initServerPathSectionState,
      ...this.buildManageShareTaskServerPathSectionState(
        result.procedureFolder
      ),
    };
  };

  initManageShareTaskSavedInSectionState = (result) => {
    this.initSavedInSectionState = {
      ...this.initSavedInSectionState,
      ...this.buildManageShareTaskSavedInSectionState(result.procedureFolder),
    };
  };

  initManageShareTaskPrimitiveServerPathSectionState = (
    lastStepHistoryData
  ) => {
    this.primitiveServerPathSectionState = {
      ...this.primitiveServerPathSectionState,
      ...this.buildManageShareTaskServerPathSectionState(lastStepHistoryData),
    };
  };

  initManageShareTaskPrimitiveSavedInSectionState = (lastStepHistoryData) => {
    this.primitiveSavedInSectionState = {
      ...this.primitiveSavedInSectionState,
      ...this.buildManageShareTaskSavedInSectionState(lastStepHistoryData),
    };
  };

  setInitManageShareTaskProcedureTableData = () => {
    this.procedureTableData.forEach((item) => {
      this.initProcedureTableData.push({
        data: JSON.parse(JSON.stringify(item.data)),
        checked: item.checked,
        isApproveDisabled: item.isApproveDisabled,
        isReturnDisabled: item.isReturnDisabled,
        isReturned: item.isReturned,
        isError: item.isError,
        replaceRef: item.replaceRef,
        attachmentEditIcon: reasonEditDefaultIcon,
        isDelete: false,
        isUpdate: false,
      });
    });
  };

  setManageShareTaskProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      const historyList = result.procedureFolder?.stepHistoryList.sort((x, y) =>
        y.modifiedDate.localeCompare(x.modifiedDate)
      );

      this.initManageShareTaskServerPathSectionState(result);

      this.initManageShareTaskSavedInSectionState(result);

      if (historyList.length !== 0) {
        const lastStepHistoryData = historyList[0];
        this.initManageShareTaskPrimitiveServerPathSectionState(
          lastStepHistoryData
        );
        this.initManageShareTaskPrimitiveSavedInSectionState(
          lastStepHistoryData
        );
      }

      this.procedureFileStepID = result.procedureFolder.procedureFileStepID;
      this.setProcedureTableData(result.procedureFolder);
      this.setInitManageShareTaskProcedureTableData();

      this.setState({
        fileTableData: this.procedureTableData,
        jbProcedureSavedIn: result.procedureFolder.jbProcedureSaveIn,
      });

      this.setManageShareTaskSavedInAndServerPathReturnReasonLog(
        result.procedureFolder.stepHistoryList
      );
      this.setManageShareTaskSavedInServerPathState(result);
    }
  };

  setProcedureFileData = (result) => {
    this.setManageShareTaskProcedureFileData(result);
  };

  buildManageShareTaskSavedInSectionState = (data) => {
    return {
      savedInReturnReasonInputValue: data.procedureSaveInCategoryReturnReason,
      savedInRadioValue: data.procedureSaveInCategoryID,
      isSavedInReturned:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
      procedureSaveInCategoryStatusID: data.procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.approval,
      isSavedInReturnBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildManageShareTaskServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
      isServerPathReturned: data.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue: data.clientServerPathReturnReason,
      clientServerPathStatusID: data.clientServerPathStatusID,
      clientServerPathStatusName: data.clientServerPathStatusName,
      clientServerPath: data.clientServerPath,
      isServerPathApproveBtnDisabled:
        data.clientServerPathStatusID === dataStatus.approval,
      isServerPathReturnBtnDisabled:
        data.clientServerPathStatusID === dataStatus.return,
    };
  };

  componentDidMount() {
    this.getInitData(
      ApproveShareService.getManageShareTaskStepItem,
      "Get Shared Tasks Failed"
    );
  }

  /* function about get data from procedure file table */
  manageShareTaskReturnFileDataValue = (name, index) => {
    const procedureFileData = procedureRef.current.getDataList();
    let deleteList = procedureRef.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  setManageShareTaskServerPathObj = () => {
    let serverPathValue = null;
    let serverPathStatus = null;
    let serverPathReturnReasonInputValue = null;

    if (procedureServerPathRef.current !== null) {
      const serverPathState =
        procedureServerPathRef.current.state.serverPathSectionState;
      serverPathValue = serverPathState.serverPathValue;
      serverPathStatus = serverPathState.serverPathStatus;
      serverPathReturnReasonInputValue =
        serverPathState.serverPathReturnReasonInputValue;
    }

    return {
      serverPathValue: serverPathValue,
      serverPathStatus: serverPathStatus,
      serverPathReturnReasonInputValue: serverPathReturnReasonInputValue,
    };
  };

  manageShareTaskSubmitDataPara = (fileDtos, actionID, actionName) => {
    const serverPathObj = this.setManageShareTaskServerPathObj();

    const {
      savedInRadioValue,
      procedureSaveInCategoryStatusID,
      savedInReturnReasonInputValue,
    } = procedureSavedInRef.current.state.savedInSectionState;
    return {
      isInitial: this.isRevisePage,
      clientServerPath: serverPathObj.serverPathValue,
      clientServerPathStatusID: serverPathObj.serverPathStatus,
      clientServerPathReturnReason:
        serverPathObj.serverPathReturnReasonInputValue,
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      procedureFiles: fileDtos,
      procedureFileStepID: this.procedureFileStepID,
      rejectReason:
        approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      procedureSaveInCategoryID: savedInRadioValue,
      procedureSaveInCategoryStatusID: procedureSaveInCategoryStatusID,
      procedureSaveInCategoryReturnReason: savedInReturnReasonInputValue,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
    };
  };

  /* function about return submit data to use in integrate trigger api */
  manageShareTaskhandleReturnSubmitData = (actionID, actionName) => {
    const fileDtos = [];
    if (procedureRef.current !== null) {
      const procedureFileData = procedureRef.current.getDataList();
      let deleteList = procedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) =>
        fileDtos.push({
          fileID:
            this.manageShareTaskReturnFileDataValue("newVersion", index)
              .fileId === null
              ? this.manageShareTaskReturnFileDataValue("fileId", index).value
              : this.manageShareTaskReturnFileDataValue("newVersion", index)
                  .fileId,
          createdDate: this.manageShareTaskReturnFileDataValue(
            "uploadedDate",
            index
          ).value,
          uploadBy: this.manageShareTaskReturnFileDataValue("uploadById", index)
            .value,
          uploadByName: this.manageShareTaskReturnFileDataValue(
            "uploadByName",
            index
          ).value,
          fileName:
            this.manageShareTaskReturnFileDataValue("newVersion", index)
              .value === null
              ? this.manageShareTaskReturnFileDataValue("fileName", index).value
              : this.manageShareTaskReturnFileDataValue("newVersion", index)
                  .value,
          fileKey: this.manageShareTaskReturnFileDataValue("fileKey", index)
            .value,
          dataStatusID: this.manageShareTaskReturnFileDataValue("status", index)
            .value,
          attachmentFileID: this.manageShareTaskReturnFileDataValue(
            "attachment",
            index
          ).fileId,
          attachmentName: this.manageShareTaskReturnFileDataValue(
            "attachment",
            index
          ).value,
          returnReason: this.manageShareTaskReturnFileDataValue(
            "returnReason",
            index
          ).value,
          fileTagID: this.manageShareTaskReturnFileDataValue("fileName", index)
            .tagValue,
          procedureFileStepID: this.manageShareTaskReturnFileDataValue(
            "procedureFileStepID",
            index
          ).value,
          procedureFileID: this.manageShareTaskReturnFileDataValue(
            "procedureFileID",
            index
          ).value,
          note: this.manageShareTaskReturnFileDataValue("note", index).value,
          oldFileID:
            this.manageShareTaskReturnFileDataValue("newVersion", index)
              .fileId === null
              ? null
              : this.manageShareTaskReturnFileDataValue("fileId", index).value,
          oldFileName:
            this.manageShareTaskReturnFileDataValue("newVersion", index)
              .value === null
              ? null
              : this.manageShareTaskReturnFileDataValue("fileName", index)
                  .value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        })
      );
    }

    return this.manageShareTaskSubmitDataPara(fileDtos, actionID, actionName);
  };

  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: this.supportingTableData,
    });
  };

  setManageShareTaskPageType = () => {
    this.setSharePageType();
  };

  handleManageShareTaskPageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      rejectReason,
    } = this.state.pageState;

    return {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
      rejectReason,
      actionID: this.actionID,
    };
  };

  handleManageShareTaskSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleManageShareTaskSavedInProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
    } = this.state.savedInSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
    };
  };

  handleManageShareTaskServerPathProps = () => {
    const {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      clientServerPathStatusID,
      serverPathValue,
    } = this.state.serverPathSectionState;

    return {
      clientServerPathStatusName,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathReturnReasonLog: this.serverPathReturnReasonLog,
      clientServerPathStatusID,
      serverPathValue,
    };
  };

  handleManageShareTaskServerParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleManageShareTaskRevisePendingPage = (procedureTableData) => {
    const { shareTaskData, jbProcedureSavedIn } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ReviseShareTaskProcess
        pageProps={this.handleManageShareTaskPageProps()}
        sectionProps={this.handleManageShareTaskSectionProps()}
        savedInProps={this.handleManageShareTaskSavedInProps()}
        serverPathProps={this.handleManageShareTaskServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        shareTaskData={shareTaskData}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        data={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        params={this.handleManageShareTaskServerParams()}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        updateProcedureFileNum={this.updateProcedureFileNum}
        fileTableData={procedureTableData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        returnFileDataValue={this.manageShareTaskReturnFileDataValue}
        handleReturnSubmitData={this.manageShareTaskhandleReturnSubmitData}
        closePage={this.closePage}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleManageShareTaskReviseCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ReviseShareTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        shareTaskData={shareTaskData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        params={this.handleManageShareTaskServerParams()}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleManageShareTaskApprovePendingPage = (procedureTableData) => {
    const { shareTaskData, jbProcedureSavedIn } = this.state;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ApproveShareTaskProcess
        ref={approvePageRef}
        pageProps={this.handleManageShareTaskPageProps()}
        sectionProps={this.handleManageShareTaskSectionProps()}
        savedInProps={this.handleManageShareTaskSavedInProps()}
        serverPathProps={this.handleManageShareTaskServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureTableData={[...this.initProcedureTableData]}
        initProcedureFileTableState={this.initProcedureFileTableState}
        data={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        params={this.handleManageShareTaskServerParams()}
        closePage={this.closePage}
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        shareTaskData={shareTaskData}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.manageShareTaskReturnFileDataValue}
        handleReturnSubmitData={this.manageShareTaskhandleReturnSubmitData}
        fileTableData={procedureTableData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleManageShareTaskApproveCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <ApproveShareTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        shareTaskData={shareTaskData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        params={this.handleManageShareTaskServerParams()}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleManageShareTaskSubmitCompletePage = (procedureTableData) => {
    const {
      taskInfoData,
      procedureInfoData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;

    const {
      receiveDate,
      requestByName,
      procedureStatus,
      rejectStatus,
      rejectReason,
    } = this.state.pageState;

    const { savedInRadioValue } = this.state.savedInSectionState;
    const { serverPathValue } = this.state.serverPathSectionState;

    return (
      <SubmitShareTaskComplete
        serverPathValue={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        pageProps={{
          receiveDate,
          requestByName,
          procedureStatus,
          rejectReason,
          rejectStatus,
          actionID: this.actionID,
        }}
        sectionProps={{
          taskInfoData,
          procedureInfoData,
          ppoList: this.state.ppoList,
        }}
        shareTaskData={shareTaskData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        params={this.handleManageShareTaskServerParams()}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  setManageShareTaskProcedureTableData = () => {
    return JSON.parse(JSON.stringify(this.state.fileTableData));
  };

  render() {
    const { loading, procedureFileNum, uploadProcedureFileNum } =
      this.state.pageState;

    const procedureTableData = this.setManageShareTaskProcedureTableData();

    this.setManageShareTaskPageType();

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          procedureFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadProcedureFileNum}/{procedureFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {this.isRevisePendingPage &&
              this.handleManageShareTaskRevisePendingPage(procedureTableData)}
            {this.isReviseCompletePage &&
              this.handleManageShareTaskReviseCompletePage(procedureTableData)}
            {this.isApprovePendingPage &&
              this.handleManageShareTaskApprovePendingPage(procedureTableData)}
            {this.isApproveCompletePage &&
              this.handleManageShareTaskApproveCompletePage(procedureTableData)}
            {this.isSubmitCompletePage &&
              this.handleManageShareTaskSubmitCompletePage(procedureTableData)}
          </>
        }
      />
    );
  }
}
