export default class UrlParameterEncoder {
    constructor(object, prefix){
        this.object = object;
        this.prefix = prefix;
    }

    serialize(obj, prefix, noEnCode) {
        let str = [];
        for (let p in obj) {
          if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
            let vValue = noEnCode ? v : encodeURIComponent(v);
            str.push((v !== null && typeof v === "object") ?
              this.serialize(v, k) :
              encodeURIComponent(k) + "=" + vValue);
          }
        }
        
        return str.join("&");
    }

    getQueryString(){
        return this.serialize(this.object, this.prefix, false);
    }

    getNotEncodeQueryString(){
        return this.serialize(this.object, this.prefix, true);
    }
}