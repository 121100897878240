import React, { createRef } from "react";
import BaseShare from "../baseShare";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../sections/taskInfoSections";
import ProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import Buttons from "../../../../base/basicComponents/button";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import { ModalWithComponent } from "../../../../base/basicComponents/modal";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import redTipIcon from "../../../../assets/image/redTipIcon.png";
import { actions } from "../../../publicDictionaryValues";
import approveShareService from "../../../../service/shareTask/approveShareService";
import ApproveShareTask from "../../../sections/sharedTaskSections/approveShareTask/index";
import NormalModel from "../../../../base/basicComponents//modal/index";
import styles from "../../procedureFileProcess/style/index.module.scss";
import appService from "../../../../service/app";
import LostPermissionModal from "../../../sections/lostPermissionModal";
import "../index.scss";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const procedureFileRef = createRef();
const approveShareTaskRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ApproveShareTaskProcess extends BaseShare {
  constructor(props) {
    super(props);
    this.state = {
      pageState: this.handleApproveShareTaskPageState(),
      modalState: this.handleApproveShareTaskModalState(),
      returnOrRejectModalTitle: "",
      isReturnOrRejectModalOpen: false,
      isApproveComfirmOpen: false,
      confirmModalText: "",
      handleApproveComfirm: () => {
        //this is a empty function
      },
      returnOrRejectInputStatus: "",
      returnOrRejectInputValue: "",
      noPermission: false,
    };
    this.para = null;
    this.modalContentLabels = this.handleApproveShareTaskModalContentLabels();
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  handleApproveShareTaskPageState = () => {
    const { rejectReason, rejectStatus } = this.props.pageProps;

    return {
      rejectReason,
      rejectStatus,
      isCancelDisabled: false,
      isSubmitDisabled: this.checkSubmitDisable(this.props.shareTaskData),
      isRejectDisabled: false,
      isTableBorderError: false,
    };
  };

  handleApproveShareTaskModalState = () => {
    return {
      modalTitle: "",
      modalLabel: "",
      okText: "",
      cancelText: "",
      actionType: "",
      uploadDraggerFileType: "",
      rowIndex: 0,
      fileIds: [],
      fileNames: [],
      modalWidth: 520,
      closable: false,
      isOkBtnDisabled: false,
      isCancelBtnDisabled: false,
      maskClosable: false,
      isModalWithComponentOpen: false,
      isShowTextArea: false,
      textAreaPlacement: "",
      textAreaMaxLength: 0,
      textAreaClassName: "",
      isShowSelect: false,
      isShowTextAreaAndUploadDragger: false,
    };
  };

  handleApproveShareTaskModalContentLabels = () => {
    return {
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      rejectModalWarningLabel: (
        <>
          <div className={styles.rejectModalWarning}>
            Are you sure to reject this request? Reject means that the process
            will be terminated. The status will be changed back to the original
            status before this initiation.
          </div>
          <InputLabel text="Reject Reason" required={true} />
        </>
      ),
    };
  };

  handleControlErrorScroll = (top) => window.scrollTo(0, top || 0);

  /* function about click submit button in revise page if server path doesn't enter required field*/
  handleCheckServerPathHighLight = () => {
    this.props.procedureServerPathRef.current.setServerPathError();
    this.props.procedureServerPathRef.current !== null &&
      this.handleControlErrorScroll(
        this.props.procedureServerPathRef.current.offsetTop
      );
  };

  handleApproveConfirm = () => {
    this.props._showLoading();
    this.setState({
      isApproveComfirmOpen: false,
    });
    const para = this.handleSubmitPara(
      actions.approve,
      "Approve",
      approveShareTaskRef
    );
    approveShareService.manageShareTaskTriggerAction(
      para,
      this.approveSuccess,
      this.approveFailed
    );
  };

  checkUploadSignOff = () => {
    const para = this.getCheckUploadSignOffPara(approveShareTaskRef);
    approveShareService.checkHasPendingSignOff(
      para,
      this.checkHasPendingSignOffSuccess,
      this.checkHasPendingSignOffFail
    );
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "approve") {
      this.handleApproveShareTaskSubmitClick();
    } else if (actionType === "reject") {
      this.handleApproveShareTaskRejectClick();
    } else if (actionType === "return") {
      this.handleApproveShareTaskReturnClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => this._alertError(response.message)
    );
  };

  handleApproveShareTaskSubmitClick = () => {
    this.checkApprove(approveShareTaskRef, approveShareService.checkApprove);
  };

  handleApproveShareTaskRejectClick = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalTitle: <span className="fs-18">Reject</span>,
        isShowTextArea: true,
        modalLabel: this.modalContentLabels.rejectModalWarningLabel,
        textAreaMaxLength: 1000,
        textAreaClassName: styles.rejectReasonInput,
        okText: "Confirm",
        cancelText: "Cancel",
        actionType: "reject",
        modalWidth: 520,
        handleCancel: this.handleApproveShareRejectCancelClick,
        handleOk: () =>
          this.handleApproveShareRejectOkClick(
            approveShareService.manageShareTaskTriggerAction,
            approveShareTaskRef
          ),
      },
    });
  };

  handleApproveShareTaskSetRejectValue = (e) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        rejectReason: e.target.value,
        rejectStatus: null,
      },
    });
  };

  handleApproveShareTaskReturnClick = () => {
    this.setState({
      returnOrRejectModalTitle: "Reason for Return",
      isReturnOrRejectModalOpen: true,
    });
  };

  handleApproveShareTaskApproveComfirmCancel = () => {
    this.setState({
      isApproveComfirmOpen: false,
    });
  };

  handleApproveShareTaskApprovalCancel = () => {
    this.setState({
      isApproveComfirmOpen: true,
      confirmModalText: "Are you sure to quit without saving?",
      handleApproveComfirm: this.props.closePage,
    });
  };

  approveShareTaskreturnOrRejectInputOnChange = (e) => {
    this.setState({
      returnOrRejectInputValue: e.value,
      returnOrRejectInputStatus: "",
    });
  };

  handleApproveShareTaskReturnOrRejectCancel = () => {
    this.setState({
      returnOrRejectModalTitle: "",
      isReturnOrRejectModalOpen: false,
      returnOrRejectInputStatus: "",
      returnOrRejectInputValue: "",
    });
  };

  approveShareTaskreturnSuccess = () => {
    this.props._hideLoading();
    this._alertSuccess("Return Successful");
    this.props.closePage();
  };

  approveShareTaskreturnFailed = () => {
    this.props._hideLoading();
    this._alertError("Return Failed");
  };

  handleApproveShareTaskReturnOrRejectOk = () => {
    if (this.state.returnOrRejectInputValue.trim() === "") {
      this.setState({
        returnOrRejectInputStatus: "error",
      });
    } else {
      this.props._showLoading();
      this.setState({
        isReturnOrRejectModalOpen: false,
      });
      const para = this.handleSubmitPara(
        actions.return,
        "Return",
        approveShareTaskRef
      );
      approveShareService.manageShareTaskTriggerAction(
        para,
        this.approveShareTaskreturnSuccess,
        this.approveShareTaskreturnFailed
      );
    }
  };

  checkSubmitDisable = (data) => {
    if (data === undefined || data === null || Object.keys(data).length === 0) {
      return true;
    }

    const deleteIndex = data.shareTasks.findIndex(
      (x) => x.isDel !== undefined && x.isDel === true
    );
    const newIndex = data.shareTasks.findIndex(
      (x) => x.isNew !== undefined && x.isNew === true
    );

    if (deleteIndex > -1 || newIndex > -1) {
      return false;
    }

    return true;
  };

  approveShareTaskComponentDidUpdate = (prevProps) => {
    if (prevProps.shareTaskData !== this.props.shareTaskData) {
      if (this.checkSubmitDisable(this.props.shareTaskData)) {
        this.setState({
          pageState: {
            ...this.state.pageState,
            isSubmitDisabled: true,
          },
        });
      } else {
        this.setState({
          pageState: {
            ...this.state.pageState,
            isSubmitDisabled: false,
          },
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    this.approveShareTaskComponentDidUpdate(prevProps);
  }

  handleApproveShareTaskPageHeader = () => {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.props.pageProps;

    return (
      <PageHeader
        title="Approve Shared Tasks"
        receiveDate={this._formateDate(receiveDate)}
        requestBy={requestByName}
        status={procedureStepStatus}
      />
    );
  };

  handleApproveShareTaskTaskInformation = () => {
    const { taskInfoData } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInfoData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleApproveShareTaskProcedureFileSection = () => {
    const {
      serverPathValue,
      savedInRadioValue,
      fileTableData,
      jbProcedureSavedIn,
    } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlyProcedureFileSection
            procedureFileRef={procedureFileRef}
            clientServerPath={serverPathValue}
            savedInRadioValue={savedInRadioValue}
            procedureFileData={fileTableData !== undefined ? fileTableData : []}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleApproveShareTaskSupportingFileSection = () => {
    const { supportingTableData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ReadOnlySupportingFileSection
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
            supportingFileData={supportingTableData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleApproveShareTaskProcedureInfoSection = () => {
    const { procedureStatus } = this.props.pageProps;
    const { procedureInfoData, ppoList } = this.props.sectionProps;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <ProcedureInformation
            ppoList={ppoList}
            isShowActions={false}
            noteDisabled={true}
            procedureStatus={procedureStatus}
            title="Procedure Information"
            submitData={procedureInfoData}
            isPPODisabled={true}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setProcedureInfoData,
            this.setError,
            [
              StepBusinessType.procedureOwner,
              StepBusinessType.pmpExcluded,
              StepBusinessType.primaryProcedureOwner,
            ]
          );
        }}
      />
    );
  };

  handleApproveShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <ApproveShareTask
            shareTaskData={shareTaskData}
            ref={approveShareTaskRef}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleApproveShareTaskButton = () => {
    const { isSubmitDisabled, isRejectDisabled } = this.state.pageState;

    return (
      <div className={styles.actionBtns}>
        <div className={styles.normalBtns}>
          <Buttons
            color="grey"
            size="middle"
            onClick={() => this.checkStepPermission("return")}
            disabled={false}
            btnClassName={styles.approveActionBtn}
          >
            Return to Revise
          </Buttons>
          <Buttons
            color="grey"
            size="middle"
            onClick={this.handleApproveShareTaskApprovalCancel}
            disabled={false}
            btnClassName={styles.approveActionBtn}
          >
            Cancel
          </Buttons>
          <Buttons
            color="blue"
            size="middle"
            onClick={() => this.checkStepPermission("approve")}
            disabled={isSubmitDisabled}
            btnClassName={styles.approveActionBtn}
          >
            Approve
          </Buttons>
        </div>
        <div className={styles.rejectBtn}>
          <Buttons
            color="red"
            size="middle"
            onClick={() => this.checkStepPermission("reject")}
            disabled={isRejectDisabled}
          >
            Reject
          </Buttons>
          <NormalToolTip
            element={
              <div className={styles.rejectTipIcon}>
                <img src={redTipIcon} alt="Reject" />
              </div>
            }
            title="Reject means that the process will be terminated. The status will be changed back to the original status before this initiation."
            color="#FF5858"
            overlayClassName={styles.rejectTip}
          />
        </div>
      </div>
    );
  };

  handleApproveShareTaskRejectModalWithComponent = () => {
    const {
      textAreaPlacement,
      textAreaMaxLength,
      textAreaClassName,
      modalTitle,
      modalLabel,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      isModalWithComponentOpen,
      isShowTextArea,
      modalWidth,
      handleOk,
      handleCancel,
      cancelText,
      closable,
      okText,
    } = this.state.modalState;
    const { rejectReason, rejectStatus } = this.state.pageState;

    return (
      <ModalWithComponent
        isModalOpen={isModalWithComponentOpen}
        closable={closable}
        okText={okText}
        cancelText={cancelText}
        label={modalLabel}
        modalWidth={modalWidth}
        isOkDisabled={isOkBtnDisabled}
        isCancelDisabled={isCancelBtnDisabled}
        isShowTextArea={isShowTextArea}
        textAreaMaxLength={textAreaMaxLength}
        textAreaStatus={rejectStatus}
        textAreaClassName={textAreaClassName}
        textAreaValue={rejectReason}
        textAreaOnChange={this.handleApproveShareTaskSetRejectValue}
        textAreaPlacement={textAreaPlacement}
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    );
  };

  handleApproveShareTaskReturnModalWithComponent = () => {
    return (
      <ModalWithComponent
        title={this.state.returnOrRejectModalTitle}
        label={
          <InputLabel
            text="Please enter Return Reason"
            required={true}
            className={styles.modalText}
          />
        }
        isModalOpen={this.state.isReturnOrRejectModalOpen}
        okText="Confirm"
        cancelText="Cancel"
        handleOk={this.handleApproveShareTaskReturnOrRejectOk}
        handleCancel={this.handleApproveShareTaskReturnOrRejectCancel}
        closable={false}
        isShowTextArea={true}
        textAreaValue={this.state.returnOrRejectInputValue}
        textAreaOnChange={(e) =>
          this.approveShareTaskreturnOrRejectInputOnChange(e.target)
        }
        textAreaMaxLength={1000}
        textAreaStatus={this.state.returnOrRejectInputStatus}
      />
    );
  };

  handleApproveShareTaskNomalComponent = () => {
    return (
      <NormalModel
        okText="Confirm"
        cancelText="Cancel"
        childContent={<p className="fs-16">{this.state.confirmModalText}</p>}
        isModalOpen={this.state.isApproveComfirmOpen}
        handleOk={this.state.handleApproveComfirm}
        handleCancel={this.handleApproveShareTaskApproveComfirmCancel}
      />
    );
  };

  render() {
    const { procedureStepStatus, actionID } = this.props.pageProps;
    const { rejectReason } = this.state.pageState;

    return (
      <div className={styles.approveNewPro}>
        {this.handleApproveShareTaskPageHeader()}
        {this.handleApproveShareTaskTaskInformation()}
        {this.handleApproveShareTaskProcedureFileSection()}
        {this.handleApproveShareTaskSupportingFileSection()}
        {this.handleApproveShareTaskProcedureInfoSection()}
        {this.handleApproveShareTaskSection()}
        {procedureStepStatus === "Pending" &&
          this.handleApproveShareTaskButton()}
        {this.handleApproveShareTaskRejectModalWithComponent()}
        {actionID === actions.reject && rejectReason !== null && (
          <div className={styles.rejectReasonBlock}>
            <span className={styles.rejectReasonTip}>Reject Reason</span>
            <p className={styles.rejectReasonContent}>{rejectReason}</p>
          </div>
        )}
        {this.handleApproveShareTaskReturnModalWithComponent()}
        {this.handleApproveShareTaskNomalComponent()}
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
