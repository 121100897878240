import React from "react";
import { Input as AntdInput } from "antd";
import Buttons from "../../../../base/basicComponents/button";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, {
  NormalTextArea,
  ExpendTextArea,
} from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import NormalModal from "../../../../base/basicComponents/modal";
import styles from "./index.module.scss";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import returnReasonLogIcon from "../../../../assets/image/returnReasonLogIcon.png";
import ReturnReasonLogModal from "../../../../base/basicComponents/returnReasonLogModal";
import RequestReasonLogModal from "../../../../base/basicComponents/requestReasonLogModal";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import { actions } from "../../../publicDictionaryValues";
import appService from "../../../../service/app";
import LostPermissionModal from "../../lostPermissionModal";

export default class ReviseProcedureInformation extends BaseProcedureInformation {
  constructor(props) {
    super(props);
    this.state = {
      ppoList: [],
      primaryProcedureOwnerId: null,
      currentPPO: null,
      currentReason: null,
      isReasonLogModalOpen: false,
      isRequestReasonLogModalOpen: false,

      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
      handleModalConfirm: () => {},
      handleModalCancel: () => {},
      noPermission: false,
    };
  }

  handlePPOChange = (value, ref) => {
    this.setState({
      currentPPO: value,
    });
  };

  handleStringTrim = (value) => {
    return value?.trim() ? value.trim() : null;
  };

  handleReasonChange = (value, ref) => {
    this.setState({
      currentReason: this.handleStringTrim(value),
    });
  };

  _getPrimaryProcedureOwnerListSuccess = (result) => {
    this.setState({
      ppoList: result,
    });
    this._hideLoading();
  };

  _getPrimaryProcedureOwnerListFailed = () => {
    this._alertError("Get Primary Procedure Owner Failed");
  };

  getPrimaryProcedureOwnerList = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.props.procedureTaskId,
      this._getPrimaryProcedureOwnerListSuccess,
      this._getPrimaryProcedureOwnerListFailed
    );
  };

  handleReturnReasonLogModal = () => {
    this.setState({
      isReasonLogModalOpen: !this.state.isReasonLogModalOpen,
    });
  };

  handleRequestReasonLogModal = () => {
    this.setState({
      isRequestReasonLogModalOpen: !this.state.isRequestReasonLogModalOpen,
    });
  };

  changePrimaryProcedureOwnerTriggerStepAction = (param) => {
    ApproveChangePPOService.ChangePrimaryProcedureOwnerTriggerStepAction(
      param,
      () => {
        this.props.closePage();
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: this.props.closePage,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
    });
  };

  _checkStepPermissionSuccess = (result) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.handelApproveClick();
  };

  checkStepPermission = () => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      this._checkStepPermissionSuccess,
      (response) => console.log(response.message)
    );
  };

  handleApproveConfirm = () => {
    this._showLoading();
    const { procedureID, procedureStepID, workflowProcessInstanceId } =
      this.props.procedureData;
    const param = {
      procedureId: procedureID,
      actionID: actions.revise,
      procedureStepID,
      workflowProcessInstanceId,
      primaryProcedureOwnerId: this.state.currentPPO,
      reason: this.state.currentReason,
    };

    this.changePrimaryProcedureOwnerTriggerStepAction(param);

    this.setState({ isModalOpen: false });
    this.props.closePage();
  };

  handelApproveClick = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to submit?",
      handleModalConfirm: this.handleApproveConfirm,
      handleModalCancel: () => {
        this.setState({ isModalOpen: false });
      },
    });
  };

  componentDidMount() {
    this._showLoading();
    this.getPrimaryProcedureOwnerList();
  }

  componentWillUpdate() {
    if (!this.state.currentPPO) {
      this.setState({
        currentPPO:
          this.props.procedureData?.primaryProcedureOwner
            ?.oldPrimaryProcedureOwnerID,
      });
    }
  }

  render() {
    const { currentRef, title, procedureData, isRevisePending } = this.props;

    const {
      procedureStatus,
      isPMPExcluded,
      primaryProcedureOwner,
      procedureOwner,
      procedureNote,
      returnReasonLog,
      requestReasonLog,
    } = procedureData;

    const {
      ppoList,
      isModalOpen,
      modalOkText,
      modalCancelText,
      modalChildContent,
      handleModalConfirm,
      handleModalCancel,
      currentPPO,
      currentReason,
    } = this.state;

    const labelInputComponent = (text, value, disabled) => {
      return (
        <div className={styles.informationItem}>
          <InputLabel text={text} className={styles.informationLabel} />
          <Input value={value} disabled={disabled} />
        </div>
      );
    };

    const sortPPOList = () => {
      const PPOList = ppoList.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      });

      const sortList = (s, t) => {
        let a = s.name.toLowerCase();
        let b = t.name.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };
      return PPOList.sort((s, t) => sortList(s, t));
    };

    const handleOptionList = (inputValue, option) =>
      (option?.title || "").toLowerCase().includes(inputValue.toLowerCase());

    const newCurrentPPO =
      sortPPOList().find((item) => item.value === currentPPO) === undefined
        ? null
        : currentPPO;

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        {returnReasonLog.length > 0 && (
          <div className={styles.reviseInfo}>
            <div className={styles.reviseInfoTop}>
              <span className={styles.returnReasonTitle}>Return Reason</span>
              <NormalToolTip
                element={
                  <div
                    className={styles.returnReasonLogIcon}
                    onClick={() =>
                      this.setState({ isReasonLogModalOpen: true })
                    }
                  >
                    <img src={returnReasonLogIcon} alt="PPO" />
                  </div>
                }
                title="View previous Return Reason"
                placement="right"
                color="#3E6CB5"
              />
            </div>
            <div className="returnReasonWrapper">
              <AntdInput.TextArea
                readOnly={true}
                value={returnReasonLog[0]?.returnReasonColumn}
                style={{
                  height: 120,
                  resize: "none",
                }}
                maxLength={1000}
                showCount={true}
              />
            </div>
          </div>
        )}
        <div className={styles.informationContent}>
          {labelInputComponent(
            "Procedure Status",
            this.getProcedureStatus(procedureStatus),
            true
          )}
          {labelInputComponent("PMP Excluded", isPMPExcluded, true)}
          <div className={styles.informationItem}>
            {isRevisePending ? (
              <>
                <InputLabel
                  text="Primary Procedure Owner"
                  className={styles.informationLabel}
                />
                <NormalSelect
                  optionList={sortPPOList()}
                  value={newCurrentPPO}
                  handleChange={(e) => {
                    this.handlePPOChange(e, currentRef);
                  }}
                  showSearch={true}
                  handleFilterOption={handleOptionList}
                />
              </>
            ) : (
              <>
                {labelInputComponent(
                  "Primary Procedure Owner",
                  primaryProcedureOwner?.primaryProcedureOwnerName,
                  true
                )}
              </>
            )}
          </div>
          <div className={styles.informationReasonItem}>
            <InputLabel 
              text="Reason" 
              className={styles.informationLabel}
             />
            {isRevisePending ? (
              <ExpendTextArea
                className={styles.informationExpendTextArea}
                value={currentReason}
                onChange={(e) => {
                  this.handleReasonChange(e.target.value, currentRef);
                }}
                maxLength={1000}
                placeholder="Please enter your reason."
              />
            ) : (
              <ExpendTextArea
                className={styles.informationExpendTextArea}
                value={primaryProcedureOwner?.reason}
                maxLength={1000}
                disabled={true}
              />
            )}
            <NormalToolTip
              element={
                <div
                  className={styles.returnReasonLogIcon}
                  onClick={() =>
                    this.setState({ isRequestReasonLogModalOpen: true })
                  }
                >
                  <img src={returnReasonLogIcon} alt="Reason" />
                </div>
              }
              title="View previous Reason"
              placement="right"
              color="#3E6CB5"
            />
          </div>
          {labelInputComponent(
            "Procedure Owner",
            procedureOwner?.procedureOwnerName,
            true
          )}
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={procedureNote}
              disabled={true}
            />
          </div>
        </div>
        {isRevisePending && (
          <div className={styles.informationBtns}>
            <Buttons color="grey" size="middle" onClick={this.handleCancel}>
              Cancel
            </Buttons>
            <Buttons
              color="blue"
              size="middle"
              onClick={this.checkStepPermission}
              disabled={!currentPPO}
            >
              Submit for Approval
            </Buttons>
          </div>
        )}
        <ReturnReasonLogModal
          isReasonLogModalOpen={this.state.isReasonLogModalOpen}
          handleReturnReasonLog={this.handleReturnReasonLogModal}
          returnReasonLogData={returnReasonLog}
        />
        <RequestReasonLogModal
          isReasonLogModalOpen={this.state.isRequestReasonLogModalOpen}
          handleRequestReasonLog={this.handleRequestReasonLogModal}
          requestReasonLogData={requestReasonLog}
        />
        <NormalModal
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={handleModalConfirm}
          handleCancel={handleModalCancel}
          closable={false}
        />
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
