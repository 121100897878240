import Stack from "../../utils/structs/Stack";
import BaseComponent from "./BaseComponent";

export default class RoutingComponent extends BaseComponent {
    
    static _backward = new Stack();
    
    static _forward = new Stack();

    route(route) {
        RoutingComponent._backward.push(window.location.href);
        this.props.history.push(route);
    }

    goBack(){
        if (RoutingComponent._backward.isEmpty()) {
            return;
        }

        RoutingComponent._forward.push(window.location.href);
        window.location.href = RoutingComponent._backward.pop();
    }

    goFurther() {
        if (RoutingComponent._forward.isEmpty()){
            return ;
        }

        RoutingComponent._backward.push(window.location.href);
        window.location.href = RoutingComponent._forward.pop();
    }
}

