class ToDoListTableFilterMap {
  constructor() {
    this.map = new Map();
    this.map.set("transactionId", "TransactionID");
    this.map.set("actionCompletedDate", "ActionCompletedDate");
    this.map.set("dueDate", "DueDate");
    this.map.set("receiveDate", "ReceiveDate");
    this.map.set("requestBy", "RequestBy");
    this.map.set("processInitiateDate", "ProcessInitiateDate");
    this.map.set("processInitiator", "ProcessInitiator");
    this.map.set("accountName", "AccountID");
    this.map.set("taskGroup", "ProcedureTaskGroupID");
    this.map.set("category", "Path");
    this.map.set("taskId", "TaskID");
    this.map.set("taskLabelName", "TaskLabelName");
    this.map.set("highlyRegulated", "HighRegulated");
    this.map.set("delegatedBy", "DelegatedBy");
    this.map.set("delegateTo", "DelegatedTo");
  }

  set(key, value) {
    this.map.set(key, value);
  }

  get(key) {
    return this.map.get(key);
  }
}

const toDoListTableFilterMap = new ToDoListTableFilterMap();

export default toDoListTableFilterMap;
