import React, { createRef } from "react";
import ReadOnlyUpdateProcedureFileManagement from "../fields/procedureFileManagementFields/readOnlyUpdateProcedureFileManagement";
import ProcedureFileSection from "../procedureFileSection";

const procedureFileTableRef = createRef();
export default class ReadOnlyUpdateProcedureFileSection extends ProcedureFileSection {
  componentDidMount() {
    procedureFileTableRef.current !== null &&
      procedureFileTableRef.current.updateDataList(
        this.props.procedureFileData
      );
  }

  render() {
    const { procedureFileRef } = this.props;
    return (
      <ReadOnlyUpdateProcedureFileManagement
        fileInputRef={procedureFileRef}
        fileTableRef={procedureFileTableRef}
        title={"Procedure Files"}
        clientPathShow={true}
        isScrollData={true}
        className="procedureFiles"
        isShowExpendIcon={true}
        isShowDownloadBtn={true}
        isShowShareBtn={false}
        isShowActionBtns={false}
        savedInDisabled={true}
        clientPathDisabled={true}
        noteDisabled={true}
        hasSubmit={false}
        savedInRadioValue={this.props.savedInRadioValue}
        clientPath={this.props.clientServerPath}
        isShowApplyJbButton={false}
        inModal={true}
        status={3}
      />
    );
  }
}
