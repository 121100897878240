import React, { createRef } from "react";
import { actions } from "../../../publicDictionaryValues";
import styles from "../../procedureFileProcess/style/index.module.scss";
import BaseChangeMainTaskComplete from "../baseChangeMainTaskComplete";
import ApproveChangeMainTaskReadOnly from "../../../sections/sharedTaskSections/approveChangeMainTaskReadOnly";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";

const procedureFileRef = createRef();
export default class ApproveChangeMainTaskComplete extends BaseChangeMainTaskComplete {
  handleReturnPageName = () => {
    return "Approve Main Task";
  };

  handleReturnShareTaskSection = () => {
    const { shareTaskData } = this.props;

    return (
      <ApproveChangeMainTaskReadOnly
        shareTaskData={shareTaskData}
        actionID={this.props.pageProps.actionID}
      />
    );
  };

  handleReturnProcedureFileSection = () => {
    const {
      serverPathValue,
      savedInRadioValue,
      fileTableData,
      jbProcedureSavedIn,
    } = this.props;
    return (
      <ReadOnlyProcedureFileSection
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
        jbProcedureSavedIn={jbProcedureSavedIn}
      />
    );
  };

  handleReturnRejectReason = () => {
    const { actionID, rejectReason } = this.props.pageProps;
    return (
      actionID === actions.reject &&
      rejectReason !== null && (
        <div className={styles.rejectReasonBlock}>
          <span className={styles.rejectReasonTip}>Reject Reason</span>
          <p className={styles.rejectReasonContent}>{rejectReason}</p>
        </div>
      )
    );
  };
}
