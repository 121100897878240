import React from "react";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, { NormalTextArea, ExpendTextArea } from "../../../../base/basicComponents/input";
import NormalSelect from "../../../../base/basicComponents/select";
import {
  pmpExcludedReasonSelectOptions,
  pmpExcludedReasonSelects,
} from "../../../publicDictionaryValues";
import styles from "../index.module.scss";

export default class PMPExcludedProcedureInformation extends BaseProcedureInformation {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.submitData.isPMPExcluded !== this.props.submitData.isPMPExcluded
    ) {
      this.updateState(this.props.submitData);
    }
  }

  excludedSelectOptions = [
    { value: false, name: "No" },
    { value: true, name: "Yes" },
  ];

  getPMPExcludedProcedureInformationLabelInputComponent = (text, value, disabled) => {
    return (
      <div className={styles.informationItem}>
        <InputLabel text={text} className={styles.informationLabel} />
        <Input value={value} disabled={disabled} />
      </div>
    );
  };

  render() {
    const {
      noteDisabled,
      ppoList,
      procedureStatus,
      title,
      isDisabledAll = false,
    } = this.props;

    const {
      currentPPOName,
      currentPOName,
      currentNote,
      currentPMPExcluded,
      currentPMPExcludedReasonSelect,
      currentPMPExcludedReasonInput,
      currentPMPExcludedReasonInputStatus,
    } = this.state;

    const PMPExcludedValue = this.excludedSelectOptions.find(item => item.value === currentPMPExcluded)?.name || "No";
    const PMPExcludedReasonValue = pmpExcludedReasonSelectOptions.find(item => item.value === currentPMPExcludedReasonSelect)?.name || "";

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        <div className={styles.informationContent}>
          <div className={styles.informationLeft}>
            <div className={styles.informationList}>
              {this.getPMPExcludedProcedureInformationLabelInputComponent(
                "Procedure Status",
                procedureStatus,
                true,
              )}
              {this.getPMPExcludedProcedureInformationLabelInputComponent(
                "PMP Excluded",
                PMPExcludedValue,
                true,
              )}
              {currentPMPExcluded && (
                <div className={styles.informationItem}>
                  <InputLabel
                    text="Reason"
                    className={styles.informationLabel}
                    required={true}
                  />
                  {isDisabledAll ? (
                    <Input value={PMPExcludedReasonValue} disabled={true} />
                  ) : (
                    <NormalSelect
                      optionList={pmpExcludedReasonSelectOptions}
                      value={currentPMPExcludedReasonSelect}
                      handleChange={(e) => {
                        this.updatePMPExcludedReasonSelect(e);
                        this.updatePMPExcludedReasonInputStatus("");
                      }}
                      disabled={false}
                    />
                  )}
                </div>
              )}
              {((currentPMPExcluded &&
                currentPMPExcludedReasonSelect ===
                  pmpExcludedReasonSelects.otherReason) ||
                !currentPMPExcluded) && (
                <div className={styles.informationItem}>
                  {currentPMPExcludedReasonSelect ? (
                    <InputLabel text="" className={styles.informationLabel} />
                  ): (
                    <InputLabel
                      text="Reason"
                      className={styles.informationLabel}
                      required={true}
                    />
                  )}
                  <NormalTextArea
                    value={currentPMPExcludedReasonInput}
                    width="31.8125"
                    onChange={(e) => {
                      this.updatePMPExcludedReasonInput(e.target.value);
                      this.updatePMPExcludedReasonInputStatus("");
                    }}
                    disabled={isDisabledAll}
                    status={currentPMPExcludedReasonInputStatus}
                  />
                </div>
              )}
              {this.getPMPExcludedProcedureInformationLabelInputComponent(
                "Primary Procedure Owner",
                currentPPOName || null,
                true,
              )}
              {this.getPMPExcludedProcedureInformationLabelInputComponent(
                "Procedure Owner",
                currentPOName || null,
                true,
              )}
            </div>
          </div>
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={currentNote}
              disabled={noteDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
