import { Table } from "antd";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";
import "./antReadOnlyTableCustom.scss";

class ReadOnlyTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, columns, tableWidth } = this.props;

    const filteredColumns = columns.filter((column) => !column.hidden);

    return (
      <div className={`${styles.tableDiv} readOnlyTableDiv`}>
        <Table
          columns={filteredColumns}
          rowKey={this._generateGUID()}
          dataSource={data}
          pagination={false}
          scroll={{
            x: tableWidth,
          }}
          rowClassName={(record) => (record.isHighlight ? "red-row" : "")}
        />
      </div>
    );
  }
}

export default ReadOnlyTable;
