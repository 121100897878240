import BaseService from "../base";
class ShareTaskService extends BaseService {
  checkEdit = (para, success, error) => {
    this.sendRequest("checkEdit", para, success, error);
  };

  // 1: no process 2: have process, no active 3: have process, have active 4: all active
  getSharingProcessStatus = (para, success, error) => {
    this.sendRequest("getShareProcessStatus", para, success, error);
  };

  getActiveShareTaskList = (para, success, error) => {
    this.sendRequest("getProcedureTaskList", para, success, error);
  };

  getMainTaskInfo = (para, success, error) => {
    this.sendRequest("getTaskRowList", para, success, error);
  };

  getAccountDDL = (para, success, error) => {
    this.sendRequest("getAccountDDL", para, success, error);
  };

  getTaskTreePathDDL = (para, success, error) => {
    this.sendRequest("getTaskTreePathDdl", para, success, error);
  };

  getRowData = (para, success, error) => {
    this.sendRequest("getTaskRowInfo", para, success, error);
  };

  checkSubmit = (para, success, error) => {
    this.sendRequest("checkSubmit", para, success, error);
  };

  submitForApproval = (para, success, error) => {
    this.sendRequest("shareTaskSubmitForApproval", para, success, error);
  };
}

const shareTaskService = new ShareTaskService();

export default shareTaskService;
