import { Tooltip } from "antd";
import BaseComponent from "../../common/BaseComponent";
export default class NormalToolTip extends BaseComponent {
  render() {
    const {
      element,
      title,
      arrowPointAtCenter = true,
      color,
      placement = "bottomLeft",
      overlayClassName = "",
      trigger = "hover",
      open,
      overlayStyle,
      getPopupContainer,
    } = this.props;
    return (
      <Tooltip
        title={title}
        arrowPointAtCenter={arrowPointAtCenter}
        color={color}
        placement={placement}
        overlayClassName={overlayClassName}
        className="tooltip"
        trigger={trigger}
        open={open}
        overlayStyle={overlayStyle}
        getPopupContainer={getPopupContainer}
      >
        {element}
      </Tooltip>
    );
  }
}
