import BaseService from "../base";
import StepBusinessType from "./stepBusinessType";

/**
 * Provides services related to step base data, extending BaseService.
 */
export default class StepBaseService extends BaseService {
  // Stores step base data
  stepBaseData = {
    procedureFolder: null,
    supportingFolder: null,
    shareTask: null,
    procedureOwner: null,
    primaryProcedureOwner: null,
    applyJBStatus: null,
    applyPmpExcluded: null,
    tlReview: null,
    signOff: null,
    taskInfomation: null,
  };

  /**
   * Retrieves the extension key.
   * @returns {null} Returns null, overridden by subclasses for specific implementation.
   */
  getExtensionKey = () => {
    return null;
  };

  /**
   * Retrieves the PMP Excluded step key.
   * @returns {string} Returns the PMP Excluded step key.
   */
  getPMPExcludedStepKey = () => {
    return null;
  };

  /**
   * Retrieves the task information key.
   * @returns {string} Returns the task information key.
   */
  getTaskInfoKey = () => {
    return null;
  };

  /**
   * Retrieves the procedure file step key.
   * @returns {string} Returns the procedure file step key.
   */
  getProcedureFileStepKey = () => {
    return null;
  };

  /**
   * Retrieves the supporting file step key.
   * @returns {string} Returns the supporting file step key.
   */
  getSupportingFileStepKey = () => {
    return null;
  };

  /**
   * Retrieves the primary procedure owner step key.
   * @returns {string} Returns the primary procedure owner step key.
   */
  getPrimaryProcedureOwnerStepKey = () => {
    return null;
  };

  /**
   * Retrieves the procedure owner step key.
   * @returns {string} Returns the procedure owner step key.
   */
  getProcedureOwnerStepKey = () => {
    return null;
  };

  /**
   * Retrieves the shared task step key.
   * @returns {string} Returns the shared task step key.
   */
  getShareTaskStepKey = () => {
    return null;
  };

  /**
   * Retrieves the list of step business keys.
   * @returns {Array} Returns an array containing all step business keys.
   */
  getStepBusinessKeyList = () => {
    let list = [
      this.getProcedureFileStepKey(),
      this.getSupportingFileStepKey(),
      this.getPrimaryProcedureOwnerStepKey(),
      this.getProcedureOwnerStepKey(),
      this.getShareTaskStepKey(),
      this.getPMPExcludedStepKey(),
      this.getTaskInfoKey(),
    ];

    list = list.filter((x) => x != null);
    const extensionKey = this.getExtensionKey();
    if (extensionKey !== null) {
      list.push(extensionKey);
    }

    return list;
  };

  /**
   * Retrieves all step business data.
   * @param {object} param Parameters object to be sent.
   * @returns {Promise} Returns a Promise that resolves with all step business data asynchronously.
   */
  getStepBusinessData = (param, interfaceList) => {
    const stepBusinessKeyList = interfaceList ?? this.getStepBusinessKeyList();
    return Promise.all(
      this.getAllRestSend(
        stepBusinessKeyList,
        param,
        interfaceList === undefined || interfaceList === null
      )
    );
  };

  /**
   * Sends requests for all steps and collects data.
   * @param {Array} stepBusinessKeyList Array of step business keys.
   * @param {object} param Parameters object.
   * @returns {Array} Returns an array of asynchronous request actions.
   */
  getAllRestSend = (stepBusinessKeyList, param, loadFlowData) => {
    const allAction = stepBusinessKeyList.map((stepBusinessKey) => {
      return this.sendRequest(
        stepBusinessKey.settingKey,
        param,
        (response) => {
          this.stepBaseData[stepBusinessKey.dataKey] = response;
        },
        (error) => {
          console.log(error);
        }
      );
    });

    if (loadFlowData) {
      allAction.push(this.getFlowStepData(param));
    }

    return allAction;
  };

  /**
   * Retrieves flow step data.
   * @param {object} param Parameters object.
   * @returns {Promise} Returns a Promise that resolves with the flow step data asynchronously.
   */
  getFlowStepData = (param) => {
    return this.sendRequest(
      "getFlowStepData",
      param,
      (response) => {
        if (response === null) {
          return;
        }

        for (const key in response) {
          this.stepBaseData[key] = response[key];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /**
   * Retrieves step data, calling success callback on success and error callback on failure.
   * @param {object} param Parameters object.
   * @param {Function} success Callback function called on success.
   * @param {Function} error Callback function called on failure.
   */
  getStepData = (param, success, error, interfaceList) => {
    return this.getStepBusinessData(param, interfaceList)
      .then(() => {
        success(this.stepBaseData);
      })
      .catch((err) => {
        error(err);
      });
  };
}
