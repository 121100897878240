import BaseService from "../base";
import ApproveChangePPOStepService from "./approveChangePPOStepService";

class ApproveChangePPOService extends BaseService {
  getPrimaryProcedureOwnerStepItem = (para, success, error) => {
    ApproveChangePPOStepService.getStepData(para, success, error);
  };
  ChangePrimaryProcedureOwnerTriggerStepAction = (para, success, error) => {
    this.sendRequest(
      "changePrimaryProcedureOwnerTriggerStepAction",
      para,
      success,
      error
    );
  };
  CheckPpoIsExisted = (para, success, error) => {
    this.sendRequest("checkPpoIsExisted", para, success, error);
  };
  checkPpoLostMembership = (para, success, error) => {
    this.sendRequest("checkPpoLostMembership", para, success, error);
  };
}

const approveChangePPOService = new ApproveChangePPOService();

export default approveChangePPOService;
