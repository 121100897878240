import React from "react";
import NormalPagination from "../../../../../base/basicComponents/pagination";

export default function FooterPagination(props) {
  return (
    <NormalPagination
      total={props.totalRecord}
      // showTotal={(props) => `Total ${props.totalRecord} items`}
      current={props.currentPage.pageNumber}
      pageSize={props.currentPage.pageSize}
      onChange={props.handlePageNumberChange}
      selectComponentClass={props.selectComponentClass}
    />
  );
}
