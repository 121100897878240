import React, { createRef } from "react";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import ReadOnlyUpdateSupportingFileSection from "../../../../sections/supportingFileSections/readOnlyUpdateSupportingFileSection";
import BaseNewSupportingFileComplete from "../../baseNewSupportingFile/baseNewSupportingFileComplete";

const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class UpdateSupportingFileSubmitComplete extends BaseNewSupportingFileComplete {
  handleReturnPageName = () => {
    if (
      this.props.pageProps.flowCategoryID === flowCategoryId.copySupportingFile
    ) {
      return "Copy Supporting File";
    }

    return "Update Supporting File";
  };

  handleReturnSupportingFileSection = () => {
    const { data } = this.props;
    return (
      <ReadOnlyUpdateSupportingFileSection
        supportingFileRef={supportingFileRef}
        supportingFileTableRef={supportingFileTableRef}
        supportingFileData={data}
        isCopyPage={this.props.isCopyPage}
      />
    );
  };
}
