import { createRef } from "react";
import UpdateProcedureFileReviseSection from "../../../../sections/procedureFileSections/updateProcedureFileReviseSection";
import styles from "../../style/index.module.scss";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import {
  actions,
  dataStatus,
  deepClone,
  fileTags,
  handleRevisionInfoManatoryCheck,
  proposedChangeTags,
  revisionInfoMandatoryCheck,
  savedInRadioValues,
  procedureJbStatusMap,
} from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import ReviseNewProcedurePending from "../../createNewProcedureProcess/reviseNewProcedurePending";
import AppSetting from "../../../../../config/AppSetting";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class ReviseUpdateProcedurePending extends ReviseNewProcedurePending {
  /* function about control save button disable when procedure file table change */
  setIsRevisionInfoChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isRevisionInfoChange: true,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  cancelIsRevisionInfoChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isRevisionInfoChange: false,
      },
    });
  };

  /**
   * function about return page name
   * @returns
   */
  handleReturnPageName = () => {
    return "Revise Update Procedure";
  };

  /**
   * function about update saved in value state
   * @param {string} value current saved in value
   * @param {string} prevValue last saved in value
   */
  updateSavedInRadioValue = (value, prevValue) => {
    const dataList = this.props.procedureRef.current !== null
      ? deepClone(this.props.procedureRef.current.getDataList())
      : [];
    if (
      (value === savedInRadioValues.serverOnly || value === null) &&
      prevValue !== savedInRadioValues.serverOnly &&
      prevValue !== null
    ) {
      this.prevProcedureDataList = dataList;
    }
    this.setState({
      savedInState: { ...this.state.savedInState, savedInRadioValue: value },
    });
    dataList.forEach((item, index) => {
      dataList[index].isError = false;
    });
    procedureFileTableRef.current && procedureFileTableRef.current.updateDataList(dataList);
    this.isTableBorderError = false;
    this.setState({
      pageState: { ...this.state.pageState, isTableBorderError: false },
    });
    this.props.procedureServerPathRef.current.cancelServerPathError();
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={`${styles.approveProcedureFile} revisionInfoSection`}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            updateFileNumLoading={this.props.updateFileNumLoading}
            openLoading={this.props._showLoading}
            hideLoading={this.props._hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={false}
            revisionInfoData={this.props.revisionInfoData}
            initRevisionInfoState={this.props.initRevisionInfoState}
            setIsRevisionInfoChange={this.setIsRevisionInfoChange}
            cancelIsRevisionInfoChange={this.cancelIsRevisionInfoChange}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return procedure file section
   * @param {object} obj
   * @returns
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, isDisableAll, procedureReplaceFileRef } =
      this.props.pageProps;
    const {
      data,
      downloadFile,
      isMount,
      initSavedInSectionState,
      initServerPathSectionState,
      initProcedureTableData,
      updateFileNumLoading,
      procedureSavedInRef,
      procedureServerPathRef,
      procedureRef,
    } = this.props;
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <UpdateProcedureFileReviseSection
            sectionProps={{ currentRole, isDisableAll }}
            refs={{
              procedureSavedInRef: procedureSavedInRef,
              procedureServerPathRef: procedureServerPathRef,
              procedureRef: procedureRef,
              procedureFileTableRef,
              reasonRef,
              noteRef,
              procedureFileRef,
              procedureReplaceFileRef,
            }}
            data={data}
            isTableBorderError={this.isTableBorderError}
            handleChangeTableErrorStatus={this.handleChangeTableErrorStatus}
            downloadFile={downloadFile}
            isMount={isMount}
            savedInProps={this.state.savedInState}
            serverPathProps={this.state.serverPathState}
            initSavedInSectionState={initSavedInSectionState}
            initServerPathSectionState={initServerPathSectionState}
            initProcedureTableData={initProcedureTableData}
            cancelIsTableChange={this.cancelIsTableChange}
            setIsTableChange={this.setIsTableChange}
            updateProcedureFileNum={updateFileNumLoading}
            updateSavedInRadioValue={this.updateSavedInRadioValue}
            updateServerPathValue={this.updateServerPathValue}
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleSubmitTip = () => {
    const dataList = this.props.procedureRef.current !== null
      ? deepClone(this.props.procedureRef.current.getDataList())
      : [];
    const fileJBStatusList = dataList.map(file => {
      const JBvalue = file.data.find(item => item.name === 'jbProcedure')?.value;
      const tagValue = file.data.find(item => item.name === 'fileName')?.tagValue;
      return tagValue === fileTags.deleted ? "" : JBvalue
    });

    const isExistJBFile = fileJBStatusList.includes(procedureJbStatusMap['JB']) && 
      (this.state.savedInState.savedInRadioValue === savedInRadioValues.systemOnly
      || this.state.savedInState.savedInRadioValue === savedInRadioValues.systemAndClientServer);
    const isProcedureJB = this.props.procedureStatusJB === procedureJbStatusMap['JB'];
    const isClientServerJB = (this.state.savedInState.savedInRadioValue === savedInRadioValues.systemAndClientServer
      || this.state.savedInState.savedInRadioValue === savedInRadioValues.serverOnly)
      && this.props.clientServerJB === procedureJbStatusMap['JB'];
      
    if (isProcedureJB && !isExistJBFile && !isClientServerJB && this.state.savedInState.savedInRadioValue) {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.JBStatusChangeWarningLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.updateSubmitConfirmModalLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    }
  };

  handleRevisionInfoCheck = () => {
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    if (isRevisonInfoError) {
      const revisionInfo = document.getElementsByClassName(
        "revisionInfoSection"
      )[0];
      revisionInfoMandatoryCheck(this.props.revisionInfoRef, revisionInfo);
      this.handleControlErrorScroll(revisionInfo.offsetTop);
    }
  };

  /* function about click submit button in revise page to scroll page if table has error */
  constrolTableScrollWhenSubmit = (
    isTableError,
    procedureFileData,
    errorIndexList,
    isOnlyHeaderError=false,
  ) => {
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    const tableEle = document.getElementsByClassName(
      "reviseProcedureTable"
    )[0];
    const revisionInfo = document.getElementsByClassName(
      "revisionInfoSection"
    )[0];

    if (isRevisonInfoError) {
      this.handleRevisionInfoCheck();
    } else if (isTableError) {
      if (this.isTableBorderError) {
        if(!isOnlyHeaderError) {
          procedureFileData.forEach((item, index) => {
            procedureFileData[index].isError = true;
          });
        }
        tableEle !== undefined &&
          this.handleControlErrorScroll(
            tableEle.offsetTop +
              tableEle.clientHeight +
              revisionInfo.clientHeight
          );
        this.isTableBorderError = true;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: true },
        });
      } else {
        const firstErrorTr = document.getElementById(
          `table-tr-${errorIndexList[0]}`
        );
        this.handleControlErrorScroll(
          firstErrorTr.offsetTop +
            firstErrorTr.clientHeight +
            revisionInfo.clientHeight
        );
      }
      procedureFileTableRef.current.updateDataList(procedureFileData);
    } else {
      procedureFileData.forEach((item, index) => {
        procedureFileData[index].isError = false;
      });
      this.isTableBorderError = false;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: false },
        });
      this.handleSubmitTip();
    }
  };

  /* function about click submit button in revise page if returned files don't do change */
  handleCheckFileTableHighLight = (
    procedureFileData,
    tableErrorList,
    isTableError
  ) => {
    let errorIndexList = [];
    let deleteList = [];
    let isOnlyHeaderError = false;
    procedureFileData.map((fileData, index) => {
      const isReviewed = fileData.data.find(
        (file) => file.name === "isReviewed"
      ).value;
      const proposedChange = fileData.data.find(
        (file) => file.name === "proposedChange"
      ).value;
      const fileTag = fileData.data.find(
        (file) => file.name === "fileName"
      ).tagValue;
      const status = fileData.data.find((file) => file.name === "status").value;
      if (
        !isReviewed &&
        status !== dataStatus.approval &&
        !(
          proposedChange === null ||
          (proposedChange === proposedChangeTags.new &&
            fileTag === fileTags.deleted) ||
          (status === null && proposedChange === proposedChangeTags.new)
        )
      ) {
        errorIndexList = [];
        tableErrorList.push(procedureFileData[index]);
        errorIndexList.push(index);
        isTableError = true;
        procedureFileData[index].isError = true;
      }
      
      const tagValue = fileData.data.find((file) => file.name === "fileName").tagValue;
      if(tagValue === fileTags.deleted) {
        deleteList.push(fileData);
      }
    });

    if (
      tableErrorList.length === procedureFileData.length &&
      procedureFileData.length > 0
    ) {
      this.isTableBorderError = false;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: false },
      });
      isTableError = true;
    } else if (
      tableErrorList.length === 0 &&
      (procedureFileData.length === 0 ||
      deleteList.length === procedureFileData.length)
    ) {
      this.isTableBorderError = true;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: true },
      });
      isTableError = true;
      isOnlyHeaderError = true;
    }

    this.constrolTableScrollWhenSubmit(
      isTableError,
      procedureFileData,
      errorIndexList,
      isOnlyHeaderError,
    );
  };

  handleCheckServerPathHighLight = () => {
    this.props.procedureServerPathRef.current.setServerPathError();
    this.props.procedureServerPathRef.current !== null &&
      this.handleControlErrorScroll(
        this.props.procedureServerPathRef.current.getOffsetTop()
      );
  };

  /* function about click submit button in revise page */
  handleReviseSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    const isRevisonInfoError = handleRevisionInfoManatoryCheck(
      this.props.revisionInfoRef
    );
    if (this.state.savedInState.savedInRadioValue === null) {
      if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else {
        this.handleSubmitTip();
      }
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemOnly
    ) {
      this.handleRevisionInfoCheck();
      this.handleCheckFileTableHighLight(
        procedureFileData,
        tableErrorList,
        isTableError
      );
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemAndClientServer
    ) {
      if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else {
        this.handleCheckFileTableHighLight(
          procedureFileData,
          tableErrorList,
          isTableError
        );
      }
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.serverOnly
    ) {
      if (isRevisonInfoError) {
        this.handleRevisionInfoCheck();
      } else if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else {
        this.handleSubmitTip();
      }
    }
  };

  /* function about click submit or save button to trigger api */
  createProcedureFileTriggerAction = () => {
    ProcedureDetailService.updateProcedureTriggerRevisePageAction(
      this.para,
      this._triggerSuccess,
      this._triggerError
    );
  };

  handleSaveOrSubmitClickAdjustDeleteFile = (actionType, actionName) => {
    const deleteIds = this.props.revisionInfoRef.current.state.deleteIds;
    const revisionAttachments = this.props.revisionInfoRef.current.state.currentAttachment.filter(item => !deleteIds.includes(item?.fileId));
    this.props.revisionInfoRef.current.state.currentAttachment = revisionAttachments;

    this.para = this.props.handleReturnSubmitData(
      actionType,
      actionName,
      this.props.procedureSavedInRef,
      this.props.procedureServerPathRef,
      this.props.procedureRef,
      undefined,
      this.props.revisionInfoRef,
      this.props.isLevelCChange
    );
    this.createProcedureFileTriggerAction();
  };

  /* function about click save button */
  handleSaveClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        actionID: actions.save,
      },
    });
    this.isSaveDisabled = true;
    this.props._showLoading();
    this.handleSaveOrSubmitClickAdjustDeleteFile(actions.save, "Save");
  };

  /* function about click submit button in submit modal */
  handleSubmitOkClick = (actionType, actionName) => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    this.handleSaveOrSubmitClickAdjustDeleteFile(actions.revise, "Revise");
  };

  /**
   * function about control save as draft button disabled
   * @param {*} isChange flag about is table change
   * @param {*} savedInRadioValue the value of saved in radio
   */
  controlIsSaveDisabled = (isChange, savedInRadioValue) => {
    if (this.props.procedureSavedInRef.current !== null) {
      const initSavedInRadioValue =
        this.props.initSavedInSectionState.savedInRadioValue;
      const { isRevisionInfoChange } = this.state.pageState;
      if (savedInRadioValue === savedInRadioValues.systemAndClientServer) {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          !isChange &&
          savedInRadioValue === initSavedInRadioValue &&
          ((currentServerPath === initServerPath && currentServerPath && initServerPath)
            || (!currentServerPath && !initServerPath)) &&
          !isRevisionInfoChange;
      } else if (savedInRadioValue === savedInRadioValues.systemOnly) {
        this.isSaveDisabled =
          !isChange &&
          savedInRadioValue === initSavedInRadioValue &&
          !isRevisionInfoChange;
      } else if (savedInRadioValue === null) {
        this.isSaveDisabled =
          savedInRadioValue === initSavedInRadioValue && !isRevisionInfoChange;
      } else {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          savedInRadioValue === initSavedInRadioValue &&
          ((currentServerPath === initServerPath && currentServerPath && initServerPath)
            || (!currentServerPath && !initServerPath)) &&
          !isRevisionInfoChange;
      }
    }
  };
}
