import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ApproveChangePPOStepService extends StepBaseService {
  getPrimaryProcedureOwnerStepKey = () => {
    return {
      dataKey: StepBusinessType.primaryProcedureOwner.dataKey,
      settingKey: "getPrimaryProcedureOwnerStepItem",
    };
  };

  getProcedureOwnerStepKey = () => {
    return StepBusinessType.procedureOwner;
  };

  getPMPExcludedStepKey = () => {
    return StepBusinessType.pmpExcluded;
  };
}

export default new ApproveChangePPOStepService();
