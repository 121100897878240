import React from "react";
import { Modal, Radio } from "antd";
import Buttons from "../../../../base/basicComponents/button/index";
import BaseComponent from "../../../../base/common/BaseComponent";
import warningIcon from "../../../../assets/image/warning.png";
import styles from "./index.module.scss";
import "../tlReview.scss";

class TlReviewModal extends BaseComponent {
  render() {
    const {
      isModalOpen,
      onSubmit,
      onCancel,
      showMessage,
      radioValue,
      submitDisabled,
      zIndex,
    } = this.props;

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => onSubmit(radioValue)}
        >
          Submit
        </Buttons>
      </div>
    );

    return (
      <Modal
        title="TL Review Procedure"
        centered
        open={isModalOpen}
        footer={footer}
        closable={false}
        width="45rem"
        className="tlReviewModal"
        onCancel={onCancel}
        zIndex={zIndex}
        maskClosable={false}
      >
        <div className={styles.tlReviewModalContent}>
          {showMessage && (
            <div className={styles.tlReviewModalMessageDiv}>
              <img
                src={warningIcon}
                alt=""
                className={styles.tlReviewModalMessageIcon}
              />
              <p className={styles.tlReviewModalMessage}>
                There is a TL Review Procedure process going on, you can
                initiate a new process if it is needed.
              </p>
            </div>
          )}
          <Radio.Group onChange={this.props.radioOnChange} value={radioValue}>
            <Radio value={1}>Create a Review Procedure to-do</Radio>
            <Radio value={2}>Enter Review Results</Radio>
          </Radio.Group>
        </div>
      </Modal>
    );
  }
}

export default TlReviewModal;
