import { Tooltip } from "antd";
import NormalToolTip from "../../../../base/basicComponents/toolTip";
import StatusDiv from "../../../../base/basicComponents/status/index";
import { GetColumnCheckboxGroupFilterDropdown } from "../../../commonComponents/listTable/CustomFilter";
import ListEditBtnIcon from "../../../../base/basicComponents/listEditBtnIcon";
import shareProcedureDetailEntry from "../../../../assets/image/share.svg";
import editProcedureDetailDisable from "../../../../assets/image/updateDisable.png";
import downloadProcedureDetailEntry from "../../../../assets/image/download.svg";
import changeHistoryEntry from "../../../../assets/image/changeHistory.svg";
import { SelectWithButton } from "../../../../base/basicComponents/select";
import ListColumnBase from "../../../commonComponents/listBase/listColumnBase";
import PMPExcludedEditField from "./pmpExcludeEditField";
import styles from "../index.module.scss";
import ListUpdateBtnIcon from "../../../../base/basicComponents/listUpdateBtnIcon";
import { openSections } from "../../../publicDictionaryValues";
import "./index.scss";

export default function ProcedureListListColumn({
  procedureListFilterData,
  filteredInfo,
  clientAbbrShow,
  clientNameShow,
  accountAbbrShow,
  accountNameShow,
  accountManagerShow,
  accountSupervisorShow,
  accountTeamLeaderShow,
  taskStatusShow,
  taskGroupNameShow,
  categoryNameShow,
  taskLabelNameShow,
  taskIdShow,
  taskCreatedDateShow,
  highRegulatedShow,
  turnaroundTimeShow,
  temporaryTaskShow,
  sharedTaskShow,
  sharedTaskIdsShow,
  sharedTaskNamesShow,
  procedureStatusShow,
  pmpExcludedShow,
  procedureSaveInCategoryShow,
  clientServerPathShow,
  primaryProcedureOwnerShow,
  procedureOwnerShow,
  procedureFolderCreateDateShow,
  lastUpdatedDateShow,
  procedureFolderStatusShow,
  handleSubmitPPO,
  setProcedureIdToList,
  getPpoOptionsList,
  controlPmpExcludedEditShow,
  procedureListData,
  mapStatus,
  tableSortData,
  handleDownloadClick,
  handleShareClick,
  handleColumnFilterClick,
  filterLoading,
}) {
  const { mapFilter, handleWidth, handleTableSortOrder, convertedFilter } =
    ListColumnBase();
  const columns = [
    {
      title: "Client Abbreviation",
      dataIndex: "clientAbbr",
      dbName: "ClientAbbr",
      sorter: true,
      sortOrder: handleTableSortOrder("ClientAbbr", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (clientAbbr) => (
        <Tooltip placement="topLeft" title={clientAbbr}>
          {clientAbbr}
        </Tooltip>
      ),
      width: handleWidth(!clientAbbrShow, 180),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      dbName: "ClientName",
      ellipsis: {
        showTitle: false,
      },
      render: (clientName) => (
        <Tooltip placement="topLeft" title={clientName}>
          {clientName}
        </Tooltip>
      ),
      width: handleWidth(!clientNameShow, 220),
    },
    {
      title: "Account Code",
      dataIndex: "accountAbbr",
      dbName: "AccountAbbr",
      sorter: true,
      sortOrder: handleTableSortOrder("AccountAbbr", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (accountAbbr) => (
        <Tooltip placement="topLeft" title={accountAbbr}>
          {accountAbbr}
        </Tooltip>
      ),
      width: handleWidth(!accountAbbrShow, 145),
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
      dbName: "AccountName",
      ellipsis: {
        showTitle: false,
      },
      render: (accountName) => (
        <Tooltip placement="topLeft" title={accountName}>
          {accountName}
        </Tooltip>
      ),
      width: handleWidth(!accountNameShow, 220),
    },
    {
      title: "Account Manager",
      dataIndex: "accountManager",
      dbName: "AccountManagerID",
      filters: mapFilter(procedureListFilterData.AccountManager),
      filteredValue: filteredInfo.accountManager || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "AccountManager"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (accountManager) => (
        <Tooltip placement="topLeft" title={accountManager}>
          {accountManager}
        </Tooltip>
      ),
      width: handleWidth(!accountManagerShow, 170),
    },
    {
      title: "Account Supervisor",
      dataIndex: "accountSupervisor",
      dbName: "AccountSupervisorID",
      filters: mapFilter(procedureListFilterData.AccountSupervisor),
      filteredValue: filteredInfo.accountSupervisor || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "AccountSupervisor"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (accountSupervisor) => (
        <Tooltip placement="topLeft" title={accountSupervisor}>
          {accountSupervisor}
        </Tooltip>
      ),
      width: handleWidth(!accountSupervisorShow, 180),
    },
    {
      title: "Team Leader",
      dataIndex: "accountTeamLeader",
      dbName: "AccountTeamLeaderID",
      filters: mapFilter(procedureListFilterData.AccountTeamLeader),
      filteredValue: filteredInfo.accountTeamLeader || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "AccountTeamLeader"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (accountTeamLeader) => (
        <Tooltip placement="topLeft" title={accountTeamLeader}>
          {accountTeamLeader}
        </Tooltip>
      ),
      width: handleWidth(!accountTeamLeaderShow, 140),
    },
    {
      title: "Task Status",
      dataIndex: "taskStatus",
      dbName: "TaskStatusName",
      sorter: true,
      sortOrder: handleTableSortOrder("TaskStatusName", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (taskStatus) => (
        <Tooltip placement="topLeft" title={taskStatus}>
          {taskStatus}
        </Tooltip>
      ),
      width: handleWidth(!taskStatusShow, 135),
    },
    {
      title: "Task Group",
      dataIndex: "taskGroupName",
      dbName: "TaskGroupName",
      sorter: true,
      sortOrder: handleTableSortOrder("TaskGroupName", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (taskGroupName) => (
        <Tooltip placement="topLeft" title={taskGroupName}>
          {taskGroupName}
        </Tooltip>
      ),
      width: handleWidth(!taskGroupNameShow, 220),
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      dbName: "Path",
      sorter: true,
      sortOrder: handleTableSortOrder("Path", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (categoryName) => (
        <Tooltip placement="topLeft" title={categoryName}>
          {categoryName}
        </Tooltip>
      ),
      width: handleWidth(!categoryNameShow, 220),
    },
    {
      title: "Task Label Name",
      dataIndex: "taskLabelName",
      dbName: "TaskLabelName",
      sorter: true,
      sortOrder: handleTableSortOrder("TaskLabelName", tableSortData),
      filters: mapFilter(procedureListFilterData.TaskLabelName),
      filteredValue: filteredInfo.taskLabelName || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "TaskLabelName"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (taskLabelName) => (
        <Tooltip placement="topLeft" title={taskLabelName}>
          {taskLabelName}
        </Tooltip>
      ),
      width: handleWidth(!taskLabelNameShow, 220),
    },
    {
      title: "Task ID",
      dataIndex: "taskId",
      dbName: "TaskId",
      filters: mapFilter(procedureListFilterData.TaskId),
      filteredValue: filteredInfo.taskId || null,
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, "TaskId"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (taskId) => (
        <Tooltip placement="topLeft" title={taskId}>
          {taskId}
        </Tooltip>
      ),
      width: handleWidth(!taskIdShow, 105),
    },
    {
      title: "Procedure Folder Status",
      dataIndex: "procedureFolderStatus",
      dbName: "ProcedureFolderStatusID",
      filters: mapFilter(procedureListFilterData.ProcedureFolderStatus),
      filteredValue: filteredInfo.procedureFolderStatus || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "ProcedureFolderStatus"),
      ellipsis: {
        showTitle: false,
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      render: (text) => (
        <Tooltip placement="topLeft" title={mapStatus(text)}>
          <StatusDiv status={mapStatus(text)}></StatusDiv>
        </Tooltip>
      ),
      width: handleWidth(!procedureFolderStatusShow, 220),
    },
    {
      title: "Task Created Date",
      dataIndex: "taskCreatedDate",
      dbName: "TaskCreatedDate",
      sorter: true,
      sortOrder: handleTableSortOrder("TaskCreatedDate", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (taskCreatedDate) => (
        <Tooltip placement="topLeft" title={taskCreatedDate}>
          {taskCreatedDate}
        </Tooltip>
      ),
      width: handleWidth(!taskCreatedDateShow, 175),
    },
    {
      title: "Highly Regulated?",
      dataIndex: "highRegulated",
      dbName: "HighRegulated",
      filters: mapFilter(
        convertedFilter(procedureListFilterData.HighRegulated)
      ),
      filteredValue: filteredInfo.highRegulated || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "HighRegulated"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (highRegulated) => (
        <Tooltip placement="topLeft" title={highRegulated}>
          {highRegulated}
        </Tooltip>
      ),
      width: handleWidth(!highRegulatedShow, 175),
    },
    {
      title: "Turnaround Time",
      dataIndex: "turnaroundTime",
      dbName: "TurnaroundTime",
      ellipsis: {
        showTitle: false,
      },
      render: (turnaroundTime) => (
        <Tooltip placement="topLeft" title={turnaroundTime}>
          {turnaroundTime}
        </Tooltip>
      ),
      width: handleWidth(!turnaroundTimeShow, 142),
    },
    {
      title: "Temporary Task",
      dataIndex: "temporaryTask",
      dbName: "TemporaryTask",
      filters: mapFilter(
        convertedFilter(procedureListFilterData.TemporaryTask)
      ),
      filteredValue: filteredInfo.temporaryTask || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "TemporaryTask"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (temporaryTask) => (
        <Tooltip placement="topLeft" title={temporaryTask}>
          {temporaryTask}
        </Tooltip>
      ),
      width: handleWidth(!temporaryTaskShow, 160),
    },
    {
      title: "Shared Task?",
      dataIndex: "sharedTask",
      filters: mapFilter(convertedFilter(procedureListFilterData.SharedTask)),
      filteredValue: filteredInfo.sharedTask || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "SharedTask"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (sharedTask) => (
        <Tooltip placement="topLeft" title={sharedTask}>
          {sharedTask}
        </Tooltip>
      ),
      width: handleWidth(!sharedTaskShow, 145),
    },
    {
      title: "Shared Task(s) ID",
      dataIndex: "sharedTaskIds",
      ellipsis: {
        showTitle: false,
      },
      filters: mapFilter(procedureListFilterData.SharedTaskIds),
      filteredValue: filteredInfo.sharedTaskIds || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "SharedTaskIds"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      render: (sharedTaskIds) => (
        <Tooltip placement="topLeft" title={sharedTaskIds}>
          {sharedTaskIds}
        </Tooltip>
      ),
      width: handleWidth(!sharedTaskIdsShow, 220),
    },
    {
      title: "Shared Task(s) Name",
      dataIndex: "sharedTaskNames",
      ellipsis: {
        showTitle: false,
      },
      filters: mapFilter(procedureListFilterData.SharedTaskNames),
      filteredValue: filteredInfo.sharedTaskNames || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "SharedTaskNames"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      render: (sharedTaskNames) => (
        <Tooltip placement="topLeft" title={sharedTaskNames}>
          {sharedTaskNames}
        </Tooltip>
      ),
      width: handleWidth(!sharedTaskNamesShow, 220),
    },
    {
      title: "Procedure Status",
      dataIndex: "procedureStatus",
      dbName: "ProcedureStatusID",
      filters: mapFilter(procedureListFilterData.ProcedureStatus),
      filteredValue: filteredInfo.procedureStatus || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "ProcedureStatus"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (procedureStatus) => (
        <Tooltip placement="topLeft" title={procedureStatus}>
          {procedureStatus}
        </Tooltip>
      ),
      width: handleWidth(!procedureStatusShow, 170),
    },
    {
      title: "PMP Excluded",
      dataIndex: "pmpExcluded",
      dbName: "PmpExcluded",
      filters: mapFilter(convertedFilter(procedureListFilterData.PmpExcluded)),
      filteredValue: filteredInfo.pmpExcluded || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "PmpExcluded"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const isTaskActive = record.taskStatus === "Active";

        return (
          <div className={styles.pmpExcluded}>
            <Tooltip placement="topLeft" title={record.pmpExcluded}>
              {record.pmpExcluded}
            </Tooltip>
            {controlPmpExcludedEditShow(record) && (
              <PMPExcludedEditField
                isDisabled={!isTaskActive}
                procedureTaskId={record.procedureTaskId}
                procedureId={record.procedureId}
                procedureAccountId={record.procedureAccountID}
                pmpExcluded={record.pmpExcluded}
                setProcedureIdToList={setProcedureIdToList}
                dataList={procedureListData}
              />
            )}
          </div>
        );
      },
      width: handleWidth(!pmpExcludedShow, 150),
    },
    {
      title: "Procedure Saved In?",
      dataIndex: "procedureSaveInCategory",
      dbName: "ProcedureSaveInCategoryID",
      filters: mapFilter(procedureListFilterData.ProcedureSaveInCategory),
      filteredValue: filteredInfo.procedureSaveInCategory || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "ProcedureSaveInCategory"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (procedureSaveInCategory) => (
        <Tooltip placement="topLeft" title={procedureSaveInCategory}>
          {procedureSaveInCategory}
        </Tooltip>
      ),
      width: handleWidth(!procedureSaveInCategoryShow, 220),
    },
    {
      title: "Procedure Path (Server)",
      dataIndex: "clientServerPath",
      dbName: "ClientServerPath",
      ellipsis: {
        showTitle: false,
      },
      render: (clientServerPath) => (
        <Tooltip placement="topLeft" title={clientServerPath}>
          {clientServerPath}
        </Tooltip>
      ),
      width: handleWidth(!clientServerPathShow, 220),
    },
    {
      title: "Primary Procedure Owner",
      dataIndex: "primaryProcedureOwner",
      dbName: "PrimaryProcedureOwner",
      filters: mapFilter(procedureListFilterData.PrimaryProcedureOwner),
      filteredValue: filteredInfo.primaryProcedureOwner || null,
      ellipsis: {
        showTitle: false,
      },
      className: primaryProcedureOwnerShow ? "ppoColumn" : "",
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "PrimaryProcedureOwner"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      render: (text, record) => {
        const isTaskActive = record.taskStatus === "Active";

        return (
          <div className="listSelect">
            <SelectWithButton
              placeholder=""
              value={
                isTaskActive
                  ? record.primaryProcedureOwner !== null &&
                    record.primaryProcedureOwner.trim()
                  : null
              }
              optionList={
                record.primaryProcedureOwner !== null &&
                record.primaryProcedureOwner.trim() !== ""
                  ? [
                      {
                        name: record.primaryProcedureOwnerName,
                        value: record.primaryProcedureOwner,
                      },
                    ]
                  : []
              }
              showSearch={true}
              onSubmit={handleSubmitPPO}
              taskId={record.procedureTaskId}
              onDropdownVisibleChange={getPpoOptionsList}
              id={record.primaryProcedureOwner}
              isDisabled={!record.ppoEditable || !isTaskActive}
              setProcedureIdToList={setProcedureIdToList}
              dataList={procedureListData}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode.parentNode
              }
            />
          </div>
        );
      },
      width: handleWidth(!primaryProcedureOwnerShow, 220),
    },
    {
      title: "Procedure Owner",
      dataIndex: "procedureOwner",
      dbName: "ProcedureOwner",
      filters: mapFilter(procedureListFilterData.ProcedureOwner),
      filteredValue: filteredInfo.procedureOwner || null,
      onFilterDropdownOpenChange: (e) =>
        handleColumnFilterClick(e, "ProcedureOwner"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      ellipsis: {
        showTitle: false,
      },
      render: (procedureOwner) => (
        <Tooltip placement="topLeft" title={procedureOwner}>
          {procedureOwner}
        </Tooltip>
      ),
      width: handleWidth(!procedureOwnerShow, 172),
    },
    {
      title: "Procedure Folder Create Date",
      dataIndex: "procedureFolderCreateDate",
      dbName: "ProcedureFolderCreateDate",
      sorter: true,
      sortOrder: handleTableSortOrder(
        "ProcedureFolderCreateDate",
        tableSortData
      ),
      ellipsis: {
        showTitle: false,
      },
      render: (procedureFolderCreateDate) => (
        <Tooltip placement="topLeft" title={procedureFolderCreateDate}>
          {procedureFolderCreateDate}
        </Tooltip>
      ),
      width: handleWidth(!procedureFolderCreateDateShow, 248),
    },
    {
      title: "Last Updated Date",
      dataIndex: "lastUpdatedDate",
      dbName: "LastUpdatedDate",
      sorter: true,
      sortOrder: handleTableSortOrder("LastUpdatedDate", tableSortData),
      ellipsis: {
        showTitle: false,
      },
      render: (lastUpdatedDate) => (
        <Tooltip placement="topLeft" title={lastUpdatedDate}>
          {lastUpdatedDate}
        </Tooltip>
      ),
      width: handleWidth(!lastUpdatedDateShow, 175),
    },
    {
      title: "Quick Access",
      key: "operation",
      fixed: "right",
      align: "center",
      width: 160,
      render: (text, record) => {
        const procedureDetailUrl = `/ProcedureDetail/${record.procedureId}/${record.procedureTaskId}/${openSections.default}`;
        const updateProcedureDetailUrl = `/ProcedureDetail/${record.procedureId}/${record.procedureTaskId}/${openSections.procedureFile}`;
        const changeHistory = `/ChangeHistory/${record.procedureTaskId}/${record.procedureId}`;
        const isTaskActive = record.taskStatus === "Active";
        return (
          <div className={styles.quickAccessDiv}>
            {isTaskActive && (
              <>
                <NormalToolTip
                  element={
                    <ListEditBtnIcon
                      href={procedureDetailUrl}
                      record={record}
                    />
                  }
                  title="Enter Procedure Detail Page"
                />
                <NormalToolTip
                  element={
                    <img
                      alt=""
                      src={shareProcedureDetailEntry}
                      className={styles.shareProcedureDetailEntry}
                      onClick={() => handleShareClick(record)}
                    />
                  }
                  title="Share Document"
                />
                {record.procedureFolderStatus === "Active" ||
                record.procedureFolderStatus === "Updating" ? (
                  <ListUpdateBtnIcon
                    href={updateProcedureDetailUrl}
                    record={record}
                  />
                ) : (
                  <NormalToolTip
                    element={
                      <img
                        alt=""
                        src={editProcedureDetailDisable}
                        className={styles.editProcedureDetailDisable}
                      />
                    }
                    title="Update Procedure"
                  />
                )}
                <NormalToolTip
                  element={
                    <img
                      alt=""
                      src={downloadProcedureDetailEntry}
                      className={styles.downloadProcedureDetailEntry}
                      onClick={() => handleDownloadClick(record)}
                    />
                  }
                  title="Download Document"
                />
              </>
            )}
            <NormalToolTip
              element={
                <a
                  href={changeHistory}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt=""
                    src={changeHistoryEntry}
                    className={styles.changeHistoryEntry}
                  />
                </a>
              }
              title="View Change History"
            />
          </div>
        );
      },
    },
  ];

  return columns;
}
