import React from "react";
import { STATUS_CODE } from "../../utils/enum/ResponseEnum";
import ErrorStorage from "../../utils/store/ErrorStorage";
import BaseComponent from "./BaseComponent";

export default class ErrorComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    error.statusCode =
      typeof parseInt(error.message) === "number" &&
      parseInt(error.message) % 1 === 0
        ? parseInt(error.message)
        : error.statusCode;
    error.message =
      ErrorStorage.getError(parseInt(error.message)) ?? error.message;

    return { error: error };
  }

  componentDidCatch(error, errorInfo) {
    if (
      parseInt(error.message) === STATUS_CODE.unauthorized ||
      error.statusCode === STATUS_CODE.unauthorized
    ) {
      this.appSetting.localStorageValue.setUnauthenticated();
      window.location.href = "/login";
    }

    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.message}
            <br />
            {/* {this.state.errorInfo.componentStack} */}
          </details>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}
