export const poll = (pollFunction, onComplete, interval = 2000, onError) => {
  let intervalId = null;

  const startPolling = async () => {
    stopPolling(); // Stop any existing polling

    intervalId = setInterval(async () => {
      try {
        const result = await pollFunction();
        if (result) {
          onComplete(result);
          stopPolling();
        }
      } catch (error) {
        console.error("Polling error:", error);
        if (onError) onError(error);
        stopPolling();
      }
    }, interval);
  };

  const stopPolling = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  startPolling();

  // Return a function to stop polling manually if needed
  return stopPolling;
};
