import React from "react";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";

export default class NoPermissionPage extends BaseComponent {
    getMessage = () => {
        const reminder = this._getRoutingParam("Reminder");
        if(reminder === "noAccess") {
            return "You don't have permission to access this page."
        } else if(reminder === "needRefresh") {
            return "This task has a sharing relationship change, please go back to the Procedure List page and refresh."
        }
    }
    
    render() {
        return (
            <div className={styles.messageWrapper}>
                <div className={styles.messages}>
                    <div>{this.getMessage()}</div>
                </div>
            </div>
        );
    }
}