import React, { createRef } from "react";
import CreateProcedureDetail from "./createProcedureDetail";
import NormalModal from "../../../base/basicComponents/modal";
import "./style/index.scss";
import RoutingComponent from "../../../base/common/RoutingComponent";

const procedureFilesRef = createRef();
const supportingFilesRef = createRef();
const procedureInformationRef = createRef();
const shareTaskRef = createRef();
export default class ProcedureDetail extends RoutingComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.procedureId = this._getRoutingParam("ProcedureId");
    this.procedureTaskId = this._getRoutingParam("ProcedureTaskId");
    this.openSection = this._getRoutingParam("OpenSection");
  }
  render() {
    const { isModalOpen } = this.state;
    return (
      <div className="procedureDetailPage">
        <CreateProcedureDetail
          isShowUploadBtn={false}
          isShowDeleteBtn={false}
          isShowDownloadBtn={true}
          isShowShareBtn={true}
          isShowActionBtns={false}
          procedureFilesRef={procedureFilesRef}
          supportingFilesRef={supportingFilesRef}
          procedureInformationRef={procedureInformationRef}
          shareTaskRef={shareTaskRef}
          title="Procedure Detail Page"
          procedureId={this.procedureId}
          procedureTaskId={this.procedureTaskId}
          openSection={this.openSection}
          pageType="procedureDetail"
        />
        <NormalModal
          open={isModalOpen}
          closable={true}
          okText=""
          cancelText=""
        />
      </div>
    );
  }
}
