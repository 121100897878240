import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import { actions, savedInRadioValues } from "../../../publicDictionaryValues";
import ReviseProcedureFileManagement from "../fields/procedureFileManagementFields/reviseProcedureFileManagement";
import ReviseProcedureSavedIn from "../fields/savedInFields/reviseSavedIn";
import ReviseProcedureClientServerPath from "../fields/serverPathFields/reviseServerPath";
import styles from "../index.module.scss";

export default class ReviseProcedureFileSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  _hideLoading = () => this.setState({ loading: false });

  _showLoading = () => this.setState({ loading: true });

  handleReturnSavedIn = (isDisableAll) => {
    const {
      savedInReturnReasonValue,
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled,
      isSavedInReturnBtnDisabled,
      initSavedInStatus,
      savedInReturnReasonLog,
      savedInRadioValue,
      isSavedInSectionChange,
    } = this.props.savedInProps;
    const { procedureSavedInRef, procedureRef } = this.props.refs;
    return (
      <ReviseProcedureSavedIn
        ref={procedureSavedInRef}
        savedInReturnReasonInputValue={savedInReturnReasonInputValue}
        procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
        isSavedInReturnBtnDisabled={isSavedInReturnBtnDisabled}
        isSavedInApproveBtnDisabled={isSavedInApproveBtnDisabled}
        isSavedInReturnReasonDisabled={true}
        savedInRadioValue={savedInRadioValue}
        initSavedInStatus={initSavedInStatus}
        savedInReturnReasonLog={savedInReturnReasonLog}
        savedInReturnReasonValue={savedInReturnReasonValue}
        updateSavedInRadioValue={this.props.updateSavedInRadioValue}
        procedureRef={procedureRef}
        isDisableAll={isDisableAll}
        isSavedInSectionChange={isSavedInSectionChange}
      />
    );
  };

  handleReturnServerPath = (isDisableAll) => {
    const {
      clientServerPathStatusID,
      serverPathValue,
      serverPathReturnReasonInputValue,
      isServerPathApproveBtnDisabled,
      isServerPathReturnBtnDisabled,
      clientServerPathReturnReason,
      serverPathReturnReasonLog,
      isServerPathSectionChange,
    } = this.props.serverPathProps;
    const { currentRole } = this.props.sectionProps;
    const { procedureServerPathRef } = this.props.refs;
    const { savedInRadioValue } = this.props.savedInProps;
    const isShow =
      savedInRadioValue !== savedInRadioValues.systemOnly &&
      savedInRadioValue !== null;
    return (
      <ReviseProcedureClientServerPath
        isShow={isShow}
        ref={procedureServerPathRef}
        clientServerPathReturnReason={clientServerPathReturnReason}
        serverPathStatusID={clientServerPathStatusID}
        isServerPathReturnBtnDisabled={isServerPathReturnBtnDisabled}
        isServerPathApproveBtnDisabled={isServerPathApproveBtnDisabled}
        isServerPathReturnReasonDisabled={true}
        initServerPathStatus={
          this.props.initServerPathSectionState.clientServerPathStatusID
        }
        serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
        isServerPathReturned={clientServerPathStatusID === actions.return}
        currentRole={currentRole}
        serverPathReturnReasonLog={serverPathReturnReasonLog}
        serverPathValue={serverPathValue}
        updateServerPathValue={this.props.updateServerPathValue}
        isDisableAll={isDisableAll}
        isServerPathSectionChange={isServerPathSectionChange}
      />
    );
  };

  render() {
    const { currentRole } = this.props.sectionProps;
    const {
      procedureRef,
      procedureFileTableRef,
      reasonRef,
      noteRef,
      procedureFileRef,
      procedureReplaceFileRef,
    } = this.props.refs;
    const { savedInRadioValue } = this.props.savedInProps;
    const {
      setIsTableChange,
      cancelIsTableChange,
      initProcedureTableData,
      isDisableAll,
      isCopyPage,
    } = this.props;
    const isShow =
      savedInRadioValue !== savedInRadioValues.serverOnly &&
      savedInRadioValue !== null;

    return (
      <div className={styles.fileSection}>
        <div className={styles.fileSectionTitle}>Procedure Files</div>
        <div className={styles.fileSectionTop}>
          {this.handleReturnSavedIn(isDisableAll)}
          {this.handleReturnServerPath(isDisableAll)}
        </div>
        <div
          className={styles.fileSectionTable}
          hidden={savedInRadioValue === savedInRadioValues.serverOnly}
        >
          {/* {savedInRadioValue !== savedInRadioValues.serverOnly && ( */}
          <ReviseProcedureFileManagement
            isShow={isShow}
            data={this.props.data}
            ref={procedureRef}
            procedureRef={procedureRef}
            fileTableRef={procedureFileTableRef}
            className="reviseProcedureTable"
            reasonRef={reasonRef}
            noteRef={noteRef}
            fileInputRef={procedureFileRef}
            tableReplaceFileInputRef={procedureReplaceFileRef}
            currentRole={currentRole}
            isDisableAll={isDisableAll}
            initProcedureTableData={[...initProcedureTableData]}
            setIsTableChange={setIsTableChange}
            cancelIsTableChange={cancelIsTableChange}
            isTableBorderError={this.props.isTableBorderError}
            handleChangeTableErrorStatus={
              this.props.handleChangeTableErrorStatus
            }
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            updateProcedureFileNum={this.props.updateProcedureFileNum}
            isCopyPage={isCopyPage}
          />
          {/* )} */}
        </div>
      </div>
    );
  }
}
