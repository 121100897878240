import React, { createRef } from "react";
import BaseComponent from "../../common/BaseComponent";
import NormalModal from "../modal";
import ReturnReasonLogTable from "../returnReasonLogTable";
import "./index.scss";

const reasonRef = createRef();
export default class ReturnReasonLogModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      closable: true,
      okText: "",
      cancelText: "",
      tableKey: 0,
      modalTitle: "Return Reason Log",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isReasonLogModalOpen !== this.props.isReasonLogModalOpen) {
      this.setState({
        tableKey: this.state.tableKey + 1,
        isModalOpen: this.props.isReasonLogModalOpen,
      });
    }
  }

  render() {
    const {
      tableKey,
      isModalOpen,
      closable,
      okText,
      cancelText,
      modalTitle,
      destroyOnClose,
    } = this.state;
    return (
      <NormalModal
        isModalOpen={isModalOpen}
        closable={closable}
        okText={okText}
        cancelText={cancelText}
        title={modalTitle}
        width={900}
        destroyOnClose={destroyOnClose}
        className="returnReasonLogModal"
        childContent={
          isModalOpen && (
            <ReturnReasonLogTable
              tableKey={tableKey}
              dataSource={this.props.returnReasonLogData}
              isModalOpen={isModalOpen}
              reasonRef={reasonRef}
            />
          )
        }
        handleCancel={() => this.props.handleReturnReasonLog()}
        footer={null}
      />
    );
  }
}
