import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class SignOffStepService extends StepBaseService {
  getExtensionKey = () => {
    return {
      dataKey: StepBusinessType.signOff.dataKey,
      settingKey: "getSignOffStepItem",
    };
  };
}

export default new SignOffStepService();
