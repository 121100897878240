import BaseComponent from "../../../../base/common/BaseComponent";
import fullScreenIcon from "../../../../assets/image/fullScreen.png";
import fullScreenHoverIcon from "../../../../assets/image/fullScreenHover.png";
import miniScreenIcon from "../../../../assets/image/miniScreen.png";
import miniScreenHoverIcon from "../../../../assets/image/miniScreenHover.png";
import "../index.scss";

class BaseFileTable extends BaseComponent {
  /* function about change table height when expend table */
  handleChangeTableHeight = (flag) => {
    if (this.props.fileTableRef.current !== null) {
      const trs = document.getElementsByClassName(
        this.props.fileTableRef.current.props.className
      )[0].children[1].children;
      const trList = [...trs];
      let exHeight = 40;
      if (flag) {
        trList.forEach((val) => (exHeight += val.offsetHeight));
        setTimeout(() => {
          this.setState({ expendHeight: exHeight });
        });
      }
    }
  };

  /* function about sort approve apge and revise page procedure file list */
  sortDataList = (list) => {
    let arr = [];
    let newArr = list.sort((a, b) => {
      const prevName = a.data.find((item) => item.name === "fileName").value;
      const nextName = b.data.find((item) => item.name === "fileName").value;
      return prevName.localeCompare(nextName);
    });
    let sortedList = newArr.sort((a, b) => {
      const prevTime = a.data.find(
        (item) => item.name === "uploadedDate"
      ).value;
      const nextTime = b.data.find(
        (item) => item.name === "uploadedDate"
      ).value;
      return new Date(prevTime).getTime() - new Date(nextTime).getTime();
    });
    arr.push(...sortedList);
    return arr;
  };

  /* update file table data */
  updateDataList = (dataList) => {
    if (dataList.length === 0) {
      this.setState({ isExpendData: false });
    }
    this.setState({
      data: dataList,
    });
    this.handleDefaultExpendIcon();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.handleChangeTableHeight(this.state.isExpendData);
    }
    if (prevState.expendHeight !== this.state.expendHeight) {
      this.handleChangeTableHeight(this.state.isExpendData);
    }
  }

  handleExpendFlag = () => {
    const flag = !this.state.isExpendData;
    this.setState({ isExpendData: flag, isExpendNote: false });
    this.handleChangeTableHeight(flag);
  };

  handleNotExpendIcon = () => {
    this.setState({ iconSrc: fullScreenIcon });
  };

  handleDefaultExpendIcon = () => {
    this.state.isExpendData
      ? this.setState({ iconSrc: miniScreenIcon })
      : this.setState({ iconSrc: fullScreenIcon });
  };

  handleHoverExpendIcon = () => {
    this.state.isExpendData
      ? this.setState({ iconSrc: miniScreenHoverIcon })
      : this.setState({ iconSrc: fullScreenHoverIcon });
  };

  setIsExpendNote = (value) => {
    this.setState({ isExpendNote: value });
  };

  /* function about add animation when expend or fold table */
  handleTransitionHeight(
    length,
    isExpendData,
    isExpendNote,
    expendHeight,
    height
  ) {
    if (length <= 4) {
      return "auto";
    } else {
      if (isExpendData) {
        if (isExpendNote) {
          return "auto";
        } else {
          return expendHeight;
        }
      } else {
        return height;
      }
    }
  }

  handleOverFlow(length, isExpendData) {
    if (length <= 4) {
      return "hidden";
    } else {
      if (isExpendData) {
        return "hidden";
      } else {
        return "auto";
      }
    }
  }

  /**
   * function about return file(s) text
   * @param {*} num file number
   * @returns
   */
  setFileText = (num) => (num <= 1 ? "file" : "files");
}

export default BaseFileTable;
