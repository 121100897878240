import React, { createRef } from "react";
import BaseFileManagement from "../../../../../commonComponents/fileManagementFields/baseFileManagement";
import { ModalWithComponent } from "../../../../../../base/basicComponents/modal";
import InputLabel from "../../../../../../base/basicComponents/inputLabel";
import styles from "./index.module.scss";
import ApproveProcedureFileTable from "../../procedureFileTableFields/approveProcedureFileTable";
import downloadIcon from "../../../../../../assets/image/downloadIcon.png";
import { dataStatus } from "../../../../../publicDictionaryValues";
const procedureReplaceFileRef = createRef();
const procedureReturnAddFileRef = createRef();
export default class ApproveProcedureFileManagement extends BaseFileManagement {
  constructor(props) {
    super(props);
    this.state = {
      modalState: {
        isModalWithComponentOpen: false,
        closable: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        handleOk: null,
        handleCancel: null,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        textAreaMaxLength: 1000,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        uploadDraggerFileType: "",
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
        isTableBorderError: this.props.isTableBorderError,
      },
      downloadFileDisabled: true,
      downloadIcon: downloadIcon,
      procedureFileTableState: this.props.initProcedureTableData,
      isTableChange: false,
      isLevelCChange: this.props.isLevelCChange,
    };
    this.isShowDragger = false;
    this.procedureFileCurrentRowIndex = 0;
  }

  /* function about hide modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: false,
        modalLabel: "",
        handleOk: null,
        handleCancel: null,
        isShowSelect: false,
        okText: "",
        cancelText: "",
        modalTitle: "",
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isShowTextArea: false,
        textAreaPlacement: "",
        isShowTextAreaAndUploadDragger: false,
      },
    });
  };

  /**
   * function about data change when click approve after clicking return to draft
   * @param {*} procedureFileData
   * @param {*} index
   */
  procedureFileTableApproveAfterReturnDataChange = (
    procedureFileData,
    index
  ) => {
    procedureFileData[index].data.find((item) => item.name === "status").value =
      dataStatus.approval;
    procedureFileData[index].data.find(
      (item) => item.name === "returnReason"
    ).value = null;
    procedureFileData[index].data.find(
      (item) => item.name === "returnReason"
    ).isDisabled = true;
    procedureFileData[index].data.find(
      (item) => item.name === "attachment"
    ).isDisabled = true;
    procedureFileData[index].data.find(
      (item) => item.name === "attachment"
    ).value = null;
    procedureFileData[index].data.find(
      (item) => item.name === "attachment"
    ).fileId = null;
    procedureFileData[index].isApproveDisabled = true;
    procedureFileData[index].isReturnDisabled = false;
    procedureFileData[index].isError = false;
    this.props.fileTableRef.current.updateDataList(procedureFileData);
    this.hideModal();
  };

  /* function about procedure file table click confirm in approve modal */
  handleProcedureFileTableApproveOkClick = () => {
    const index = this.procedureFileCurrentRowIndex;
    const procedureFileData = this.getDataList();
    this.procedureFileTableApproveAfterReturnDataChange(
      procedureFileData,
      index
    );
  };

  /* function about click cancel button in approve modal */
  handleProcedureFileTableApproveCancelClick = () => {
    this.hideModal();
  };

  /* function about click confirm button in the modal which is click approve after clicking return to draft */
  handleProcedureFileTableApproveAfterReturnOkClick = () => {
    const index = this.procedureFileCurrentRowIndex;
    const procedureFileData = this.getDataList();
    this.handleProcedureFileNoReturnApprove(procedureFileData, index);
  };

  /* function about click saved in section approve button after i click return to draft before */
  handleApprovedAfterReturned = (type) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        modalLabel: (
          <span className={styles.modalText}>
            Are you sure to change the selection? Your Return Reason and
            Attachment will be cleared.
          </span>
        ),
        okText: "Confirm",
        modalTitle: null,
        actionType: type,
        closable: false,
        modalWidth: 520,
        handleOk: this.handleProcedureFileTableApproveAfterReturnOkClick,
        handleCancel: this.handleProcedureFileTableApproveCancelClick,
      },
    });
  };

  /**
   * function about click approve button without click return to draft before
   * @param {*} procedureFileData
   * @param {*} value
   */
  handleProcedureFileNoReturnApprove = (procedureFileData, value) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        modalWidth: 520,
      },
    });
    procedureFileData[value].isReturned = false;
    procedureFileData[value].data.find((item) => item.name === "status").value =
      dataStatus.approval;
    procedureFileData[value].data.find(
      (item) => item.name === "returnReason"
    ).value = null;
    procedureFileData[value].data.find(
      (item) => item.name === "returnReason"
    ).isDisabled = true;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).isDisabled = true;
    procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value = null;
    procedureFileData[value].isApproveDisabled = true;
    procedureFileData[value].isReturnDisabled = false;
    procedureFileData[value].isError = false;
    this.props.fileTableRef.current.updateDataList(procedureFileData);
    this.hideModal();
  };

  /* function about click approve button in procedure file table */
  handleProcedureFileTableApprovedClick = (value) => {
    this.procedureFileCurrentRowIndex = value;
    const procedureFileData = this.props.procedureRef.current.getDataList();
    if (procedureFileData[value].isReturned) {
      this.handleApprovedAfterReturned();
    } else {
      this.handleProcedureFileNoReturnApprove(procedureFileData, value);
    }
  };

  /**
   * function about control data change whtn delete file
   * @param {*} index
   */
  handleFileDelete = (index) => {
    const procedureFileData = this.getDataList();
    procedureFileData[index].data.find(
      (item) => item.name === "attachment"
    ).fileId = null;
    procedureFileData[index].data.find(
      (item) => item.name === "attachment"
    ).value = null;
  };

  /* function about procedure file table click confirm in return modal, then change current row data */
  setReturnReasonValue = (tableDataList, index, returnValue) => {
    tableDataList[index].data.find((item) => item.name === "status").value =
      dataStatus.return;
    tableDataList[index].data.find(
      (item) => item.name === "returnReason"
    ).value = returnValue;
    tableDataList[index].data.find(
      (item) => item.name === "returnReason"
    ).isDisabled = false;
    tableDataList[index].data.find(
      (item) => item.name === "attachment"
    ).fileList = procedureReturnAddFileRef.current.state.fileList;
    this.setState({
      procedureFileTableState: {
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
    });
  };

  /* function about procedure file table click confirm in return modal */
  handleProcedureFileTableReturnOkClick = () => {
    const fileTableReturnValue =
      this.state.procedureFileTableState.fileTableReturnReasonValue;
    let returnValue =
      fileTableReturnValue !== null
        ? fileTableReturnValue.trim()
        : fileTableReturnValue;
    const tableDataList = this.getDataList();
    const index = this.procedureFileCurrentRowIndex;
    const files = procedureReturnAddFileRef.current.state.fileList;
    const fileIds = procedureReturnAddFileRef.current.state.fileIdList;
    if (returnValue !== null && returnValue) {
      this.hideModal();
      if (procedureReturnAddFileRef.current.state.fileList.length === 0) {
        this.handleFileDelete(procedureReturnAddFileRef.current.props.rowindex);
      }
      tableDataList[index].isReturned = true;
      if (files.length > 0) {
        files.forEach((item, i) => {
          this.setReturnReasonValue(tableDataList, index, returnValue);
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachment"
          ).value = item.name;
          tableDataList[index].data.find(
            (dataItem) => dataItem.name === "attachment"
          ).fileId = fileIds[i];
          tableDataList[index].isApproveDisabled = false;
          tableDataList[index].isReturnDisabled = true;
          tableDataList[index].isReturned = true;
          tableDataList[index].isError = false;
          this.setState({
            procedureFileTableState: {
              fileTableReturnReasonValue: null,
              fileTableReasonStatus: null,
            },
          });
          procedureReturnAddFileRef.current.clearFileAttach();
        });
      } else {
        this.setReturnReasonValue(tableDataList, index, returnValue);
        tableDataList[index].isApproveDisabled = false;
        tableDataList[index].isReturnDisabled = true;
        tableDataList[index].isReturned = true;
        tableDataList[index].isError = false;
      }
      this.props.fileTableRef.current.updateDataList(tableDataList);
    } else {
      this.setState({
        procedureFileTableState: {
          ...this.state.procedureFileTableState,
          fileTableReasonStatus: "error",
        },
      });
    }
  };

  /* function about procedure file table click cancel in return modal */
  handleProcedureFileTableReturnCancelClick = () => {
    procedureReturnAddFileRef.current.clearFileAttach();
    const tableDataList = this.getDataList();
    this.props.fileTableRef.current.updateDataList(tableDataList);
    this.hideModal();
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: null,
        fileTableReasonStatus: null,
      },
    });
  };

  /* function about create return to draft modal in procedure file table */
  handleSetReturnModal = (value) => {
    const procedureFileData = this.getDataList();
    const fileId = procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).fileId;
    const fileName = procedureFileData[value].data.find(
      (item) => item.name === "attachment"
    ).value;
    const fileIds = fileId == null ? [] : [fileId];
    const fileNames = fileName == null ? [] : [fileName];
    this.setState({
      modalState: {
        ...this.state.modalState,
        isModalWithComponentOpen: true,
        isShowTextAreaAndUploadDragger: true,
        uploadDraggerFileType: "text",
        modalTitle: <span className="fs-18">Reason for Return</span>,
        modalLabel: (
          <InputLabel
            text="Please fill in the reason for return:"
            required={true}
            className={styles.modalText}
          />
        ),
        textAreaPlacement: this.returnModalInputPlacement,
        maskClosable: false,
        rowIndex: value,
        okText: "Confirm",
        closable: false,
        fileIds: fileIds,
        fileNames: fileNames,
        modalWidth: 700,
        handleOk: this.handleProcedureFileTableReturnOkClick,
        handleCancel: this.handleProcedureFileTableReturnCancelClick,
      },
    });
  };

  /* function about procedure file table click return to draft button */
  handleProcedureFileTableReturnClick = (value) => {
    this.isShowDragger = !this.isShowDragger;
    const procedureFileData = this.props.procedureRef.current.getDataList();
    const returnReasonValue = procedureFileData[value].data.find(
      (item) => item.name === "returnReason"
    ).value;
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: returnReasonValue,
      },
    });
    this.procedureFileCurrentRowIndex = value;
    this.handleSetReturnModal(value);
  };

  /* function about control confirm button disabled in the modal */
  _openModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: true,
        isCancelBtnDisabled: true,
      },
    });
  };

  /* function about control cancel button disabled in the modal */
  _hideModalBtnsDisabled = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        isChange: true,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        isChange: false,
      },
    });
  };

  /* function about set file's return reason */
  handleSetProcedureFileReturnReason = (e) => {
    this.setState({
      procedureFileTableState: {
        ...this.state.procedureFileTableState,
        fileTableReturnReasonValue: e.target.value,
        fileTableReasonStatus: null,
      },
    });
  };

  downloadFile = () => {
    this._showDownloadLoading();
    this.baseDownloadFiles(this.downloadDetailFilesSuccessfully);
  };

  handleReturnColumns = (obj) => {
    const { isDisableAll, noteRef, status, reasonRef } = obj;
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: isDisableAll,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Note",
        dataName: "note",
        type: "input",
        ref: noteRef,
        isShow: status !== 3 && !this.props.isCopyPage,
        isDisabled: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: status === 3 || this.props.isCopyPage,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Return Reason",
        dataName: "returnReason",
        type: "input",
        ref: reasonRef,
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
        isDisabled: isDisableAll,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Approve",
        returnText: "Return to Draft",
        approveColor: "blue",
        returnColor: "grey",
        onApproveClick: this.handleProcedureFileTableApprovedClick,
        onReturnClick: this.handleProcedureFileTableReturnClick,
        isShow: !isDisableAll && !this.props.isCopyPage,
      },
    ];
  };

  handleReturnFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      className,
      optionList,
      currentRole,
      downloadFile,
      isDisableAll,
      initProcedureTableData,
      fileTableStatus,
      isTableBorderError,
    } = obj;
    return (
      <ApproveProcedureFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        fileTableRef={fileTableRef}
        className={className}
        optionList={optionList}
        handleReturnClick={(e) =>
          this.handleProcedureFileTableReturnClick(
            e.target.attributes.rowindex.value
          )
        }
        currentRole={currentRole}
        downloadFile={downloadFile}
        isComplete={isDisableAll}
        initProcedureTableData={initProcedureTableData}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        fileTableStatus={fileTableStatus}
        isTableBorderError={isTableBorderError}
        isShowReturnReason={true}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };

  componentDidMount() {
    if (this.props.fileTableRef !== undefined) {
      this.props.fileTableRef.current.updateDataList(this.props.data);
      this.setInitSaveSubmitData(this.props.data);
    }
  }

  render() {
    const {
      noteRef,
      reasonRef,
      currentRole,
      isDisableAll,
      status,
      fileTableRef,
      className,
      downloadFile,
      initProcedureTableData,
      fileTableStatus,
      optionList,
      isShow = true,
    } = this.props;
    const {
      okText,
      cancelText,
      modalTitle,
      closable,
      maskClosable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      isModalWithComponentOpen,
      modalLabel,
      textAreaPlacement,
      textAreaMaxLength,
      isShowTextArea,
      isShowSelect,
      rowIndex,
      fileIds,
      fileNames,
      isShowTextAreaAndUploadDragger,
      uploadDraggerFileType,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;

    const {
      fileTableReasonStatus,
      isTableBorderError,
      fileTableReturnReasonValue,
    } = this.state.procedureFileTableState;

    const columns = this.handleReturnColumns({
      isDisableAll,
      noteRef,
      status,
      reasonRef,
    });

    return (
      <div style={{ display: isShow ? "block" : "none" }}>
        <div className={styles.fileManagementDiv}>
          <div className={styles.buttonDiv}>
            {this.handleReturnDownloadBtn(
              true,
              this.state.downloadFileDisabled
            )}
          </div>
          {this.handleReturnFileTable({
            fileTableRef,
            columns,
            className,
            optionList,
            currentRole,
            downloadFile,
            isDisableAll,
            initProcedureTableData,
            fileTableStatus,
            isTableBorderError,
          })}
        </div>
        <div>
          <ModalWithComponent
            isModalOpen={isModalWithComponentOpen}
            closable={closable}
            okText={okText}
            cancelText={cancelText}
            title={modalTitle}
            handleOk={handleOk}
            handleCancel={handleCancel}
            isOkDisabled={isOkBtnDisabled}
            isCancelDisabled={isCancelBtnDisabled}
            maskClosable={maskClosable}
            isShowTextArea={isShowTextArea}
            textAreaValue={fileTableReturnReasonValue}
            textAreaOnChange={this.handleSetProcedureFileReturnReason}
            textAreaPlacement={textAreaPlacement}
            textAreaMaxLength={textAreaMaxLength}
            textAreaStatus={fileTableReasonStatus}
            label={modalLabel}
            isShowSelect={isShowSelect}
            selectOptionList={optionList}
            fileUploadRef={procedureReplaceFileRef}
            fileUploadInputRef={procedureReturnAddFileRef}
            rowindex={rowIndex}
            fileIds={fileIds}
            fileNames={fileNames}
            isShowInnerDragger={this.isShowDragger}
            isMultiple={false}
            isShowDeleteBtn={true}
            hideLoading={this._hideModalBtnsDisabled}
            openLoading={this._openModalBtnsDisabled}
            isLoading={isOkBtnDisabled}
            isShowTextAreaAndUploadDragger={isShowTextAreaAndUploadDragger}
            uploadDraggerFileType={uploadDraggerFileType}
            modalWidth={modalWidth}
          />
        </div>
      </div>
    );
  }
}
