import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ShareTaskStepService extends StepBaseService {
  getShareTaskStepKey = () => {
    return {
      dataKey: StepBusinessType.shareTask.dataKey,
      settingKey: "getManageShareTaskStepItem",
    };
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };
}

export default new ShareTaskStepService();
