import { DatePicker, Space } from "antd";
import moment from "moment-timezone";
import React from "react";
import { getMomentTimezone } from "../../../app/publicDictionaryValues";
import BaseComponent from "../../common/BaseComponent";
import "./index.scss";

export default class NormalDatePicker extends BaseComponent {
  render() {
    const dateFormat = "MM/DD/YYYY";
    const {
      allowClear = true,
      disabled = false,
      disabledDate,
      placeholder = "",
      placement,
      value,
      onChange,
      status = "",
      className = "",
      getPopupContainer,
    } = this.props;
    const timezoneStr = JSON.parse(localStorage.getItem("user"))
      ?.identityUserInfo.timezone;
    const momentTimezone = getMomentTimezone(timezoneStr);
    const defaultValue = moment.tz("", dateFormat, momentTimezone);
    return (
      <Space direction="vertical" size={12}>
        <DatePicker
          defaultValue={defaultValue}
          className={`${status} ${className}`}
          format={dateFormat}
          allowClear={allowClear}
          disabled={disabled}
          disabledDate={disabledDate}
          placeholder={placeholder}
          placement={placement}
          value={value}
          onChange={onChange}
          showToday={false}
          getPopupContainer={getPopupContainer}
          inputReadOnly={true}
        />
      </Space>
    );
  }
}
