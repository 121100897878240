export default function ProcedureListColumnSelectOption() {
  const procedureListcolumnSelectOption = [
    {
      label: "Client Abbreviation",
      value: "clientAbbrShow",
    },
    {
      label: "Client Name",
      value: "clientNameShow",
    },
    {
      label: "Account Code",
      value: "accountAbbrShow",
    },
    {
      label: "Account Name",
      value: "accountNameShow",
    },
    {
      label: "Account Manager",
      value: "accountManagerShow",
    },
    {
      label: "Account Supervisor",
      value: "accountSupervisorShow",
    },
    {
      label: "Team Leader",
      value: "accountTeamLeaderShow",
    },
    {
      label: "Task Status",
      value: "taskStatusShow",
    },
    {
      label: "Task Group",
      value: "taskGroupNameShow",
    },
    {
      label: "Category",
      value: "categoryNameShow",
    },
    {
      label: "Task Label Name",
      value: "taskLabelNameShow",
    },
    {
      label: "Task ID",
      value: "taskIdShow",
    },
    {
      label: "Procedure Folder Status",
      value: "procedureFolderStatusShow",
    },
    {
      label: "Task Created Date",
      value: "taskCreatedDateShow",
    },
    {
      label: "Highly Regulated?",
      value: "highRegulatedShow",
    },
    {
      label: "Turnaround Time",
      value: "turnaroundTimeShow",
    },
    {
      label: "Temporary Task",
      value: "temporaryTaskShow",
    },
    {
      label: "Shared Task?",
      value: "sharedTaskShow",
    },
    {
      label: "Shared Task(s) ID",
      value: "sharedTaskIdsShow",
    },
    {
      label: "Shared Task(s) Name",
      value: "sharedTaskNamesShow",
    },
    {
      label: "Procedure Status",
      value: "procedureStatusShow",
    },
    {
      label: "PMP Excluded",
      value: "pmpExcludedShow",
    },
    {
      label: "Procedure Saved In?",
      value: "procedureSaveInCategoryShow",
    },
    {
      label: "Procedure Path (Server)",
      value: "clientServerPathShow",
    },
    {
      label: "Primary Procedure Owner",
      value: "primaryProcedureOwnerShow",
    },
    {
      label: "Procedure Owner",
      value: "procedureOwnerShow",
    },
    {
      label: "Procedure Folder Create Date",
      value: "procedureFolderCreateDateShow",
    },
    {
      label: "Last Updated Date",
      value: "lastUpdatedDateShow",
    },
  ];

  return procedureListcolumnSelectOption;
}

export function ProcedureListColumnSelectValue() {
  const columnSelectValue = [
    "accountAbbrShow",
    "accountNameShow",
    // "accountSupervisorShow",
    "accountTeamLeaderShow",
    "taskStatusShow",
    "taskGroupNameShow",
    "categoryNameShow",
    "taskLabelNameShow",
    "taskIdShow",
    "taskCreatedDateShow",
    "highRegulatedShow",
    "temporaryTaskShow",
    "sharedTaskShow",
    "procedureStatusShow",
    "pmpExcludedShow",
    "procedureSaveInCategoryShow",
    "primaryProcedureOwnerShow",
    "procedureOwnerShow",
    "procedureFolderCreateDateShow",
    "lastUpdatedDateShow",
    "procedureFolderStatusShow",
  ];

  return columnSelectValue;
}
