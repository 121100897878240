import React from "react";
import BaseComponent from "../../common/BaseComponent";
import {ModalWithComponent} from "../modal";
import styles from "./index.module.scss";
import InputLabel from "../inputLabel";
import { regularExpressionOptions } from "../../../app/publicDictionaryValues";

export default class RejectModal extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalState: {
                closable: false,
                okText: "Confirm",
                cancelText: "Cancel",
                modalTitle: "Reject",
                isCancelBtnDisabled: false,
                maskClosable: false,
                isShowTextArea: true,
                textAreaPlacement: "",
                textAreaMaxLength: 1000,
                textAreaClassName: styles.rejectReasonInput,
                modalWidth: 520,
            },

            rejectReason: "",
            rejectStatus: null,
        };
    }

    modalLabelComponent = () => {
        return (
            <>
                <div className={styles.rejectModalWarning}>
                    Are you sure to reject this request? Reject means that the process will be terminated. 
                    The status will be changed back to the original status before this initiation.
                </div>
                <InputLabel text="Reject Reason" required={true} />
            </>
        )
    }

    handleSetRejectValue = (e) => {
        this.setState({
            rejectReason: e.target.value,
            rejectStatus: null,
        });
    };

    handleRejectConfirmClick = () => {
        if(this.state.rejectReason.match(regularExpressionOptions.nonempty)) {
            this.setState({
                rejectStatus: "error",
            });
        } else {
            this.props.handleRejectConfirm(this.state.rejectReason)
            this.setState({
                rejectReason: "",
                rejectStatus: null,
            });
        }
    }

    handleRejectCancelClick = () => {
        this.props.handleRejectCancel();
        this.setState({
            rejectReason: "",
            rejectStatus: null,
        });
    }

    render() {
        const { 
            isRejectModalOpen, 
        } = this.props;
        const {
            rejectReason,
            rejectStatus,
        } = this.state;
        const {
            modalTitle,
            okText,
            cancelText,
            closable,
            // isOkDisabled,
            isCancelBtnDisabled,
            maskClosable,
            isShowTextArea,
            textAreaPlacement,
            textAreaMaxLength,
            textAreaClassName,
            modalWidth,
        } = this.state.modalState;

        return (
            <ModalWithComponent
                isModalOpen={isRejectModalOpen}
                closable={closable}
                okText={okText}
                cancelText={cancelText}
                title={modalTitle}
                handleOk={this.handleRejectConfirmClick}
                handleCancel={this.handleRejectCancelClick}
                // isOkDisabled={isOkDisabled}
                isCancelDisabled={isCancelBtnDisabled}
                maskClosable={maskClosable}
                isShowTextArea={isShowTextArea}
                textAreaValue={rejectReason}
                textAreaOnChange={this.handleSetRejectValue}
                textAreaPlacement={textAreaPlacement}
                textAreaMaxLength={textAreaMaxLength}
                textAreaStatus={rejectStatus}
                textAreaClassName={textAreaClassName}
                modalWidth={modalWidth}
                label={this.modalLabelComponent()}
            />
        );
    }
}
