import React from "react";
import PMPExcludedProcess from "..";
import NormalSpin from "../../../../base/basicComponents/spin";
import CancelPMPExcludedComplete from "./cancelPMPExcludedComplete";

export default class CancelPMPExcluded extends PMPExcludedProcess {
  render() {
    const {
      procedureStepStatus,
      procedureStatus,
      receiveDate,
      requestByName,
      loading,
    } = this.state.pageState;

    const isComplete = procedureStepStatus === "Complete";
    const {
      taskInfoData,
      procedureInfoData,
      ppoList,
      fileTableData,
      shareTaskData,
      jbProcedureSavedIn,
    } = this.state;
    const serverPathValue =
      this.state.serverPathSectionState !== undefined
        ? this.state.serverPathSectionState.serverPathValue
        : "";
    const savedInRadioValue =
      this.state.savedInSectionState !== undefined
        ? this.state.savedInSectionState.savedInRadioValue
        : null;

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        children={
          isComplete && (
            <CancelPMPExcludedComplete
              params={{
                ProcedureTaskId: this.taskID,
                ProcedureID: this.procedureID,
                ProcedureStepID: this.procedureStepID,
              }}
              pageProps={{
                procedureStatus,
                receiveDate,
                requestByName,
              }}
              sectionProps={{
                taskInfoData,
                procedureInfoData,
                ppoList,
                fileTableData,
                jbProcedureSavedIn,
              }}
              serverPathValue={serverPathValue}
              savedInRadioValue={savedInRadioValue}
              shareTaskData={shareTaskData}
              supportingTableData={this.state.supportingTableData}
              setProcedureFileData={this.setProcedureFileData}
              setSupportingFileData={this.setSupportingFileData}
              setTaskInfoData={this.setTaskInfoData}
              setProcedureInfoData={this.setProcedureInfoData}
              setShareTaskData={this.setShareTaskData}
            />
          )
        }
      />
    );
  }
}
