import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";
import { actions } from "../../../publicDictionaryValues";

export default class ApproveShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = this.initReadOnlyState(false);
  }

  handleReturnReasonLog = () => {
    if (this.props.actionID !== actions.return) {
      return this.handleApproveReturnReasonLog();
    }
  };

  handleReturnReason = () => {
    if (this.props.actionID === actions.return) {
      return this.handleReviseReturnReason();
    }
  };

  handleSubmitReason = () => {
    return this.handleApproveSubmitReason();
  };
}
