import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ApplyForJbStepService extends StepBaseService {
  getExtensionKey = () => {
    return StepBusinessType.jBStatus;
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };
}

export default new ApplyForJbStepService();
