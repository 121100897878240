import BaseService from "../base";
import ShareTaskStepService from "./shareTaskStepService";

class ApproveShareService extends BaseService {
  manageShareTaskTriggerAction = (para, success, error) => {
    this.sendRequest("manageShareTaskTriggerAction", para, success, error);
  };

  getManageShareTaskStepItem = (para, success, error) => {
    ShareTaskStepService.getStepData(para, success, error);
  };

  checkApprove = (para, success, error) => {
    this.sendRequest("checkManageShareTaskApprove", para, success, error);
  };

  checkHasPendingSignOff = (para, success, error) => {
    this.sendRequest("checkHasPendingSignOff", para, success, error);
  };

  getPendingApprovalShareTaskList = (success, error) => {
    const data = {
      data: [
        {
          accountName: "54T8RFUB4UVBUIRU8T4",
          taskTreePath: "35GTBR5RFET4TET443T423TRRE",
          taskLableName: "T4TGREY53YT54274276464W",
          taskId: 325456547,
          ppoPo: "TGT3YH43tgre43yhteq53q",
          procedureSavedIn: "3qy464dayu593jswuyt64q",
          clientServerPath: "5490ujgrnkgyuygwaly54w",
          effectiveDate: "08/13/1947",
          isMainTask: true,
          procedureTaskId: "r242trfeay5rwuyru4",
        },
        {
          accountName: "534ytrfesw4q53q6534q76rye74wutrs",
          taskTreePath: "reau65eu652hgfdki7ywrtrqwu6w",
          taskLableName: "gfkiuyrjy5e4thyhfkyt",
          taskId: 67678765,
          ppoPo: "ffhgfsyueuyzhge76ythgfiuyaty4oikjmxsau",
          procedureSavedIn: "htrw7utfsu6rjnhuyrsw",
          clientServerPath: "y56wuyfsi765iuhe3q7y5",
          effectiveDate: "04/21/2005",
          isMainTask: true,
          procedureTaskId: "ew63trfd4wq64",
        },
        {
          accountName: "reytrwuy6rwu6eu758",
          taskTreePath: "4378r4823154783614gt743",
          taskLableName: "415s4greatgr4eagra",
          taskId: 3776385,
          ppoPo: "gd5sh4g6s8h4gf84shg5fs",
          procedureSavedIn: "fd4ah6t4ahgf51g14erjughrepqthriht",
          clientServerPath: "1fd2s3t1yufdghuq",
          effectiveDate: "09/05/2009",
          isMainTask: false,
          procedureTaskId: "rwe45hr6gr3y54rwe",
          isDeleted: false,
        },
        {
          accountName: "feswtrgygfugrtgyrueq5478gt483greqr",
          taskTreePath: "tre54a64trwu4t34t34t343tr",
          taskLableName: "r15dh1gfd2sh1g2fs1h35t",
          taskId: 768905378,
          ppoPo: "fd1h5gfs35hg4f3d4hjgf3",
          procedureSavedIn: "c7s98+few3t1r4ds",
          clientServerPath: "3gr1dar",
          effectiveDate: "10/05/2006",
          isMainTask: true,
          procedureTaskId: "fd78s5g4rfd1bv52s4y5t",
          isNew: true,
        },
      ],
      hasReturned: true,
    };

    success(data);
  };
}

const approveShareService = new ApproveShareService();

export default approveShareService;
