import AppSetting from "../../config/AppSetting";
import TokenStorage from "../store/TokenStorage";
import UrlParameterEncoder from "../encoding/UrlParameterEncoder";
import ErrorStorage from "../store/ErrorStorage";
import EncryptUtils from "../encoding/EncryptUtils.js"

export default class RestRequest {
  /**
   * @param {string} url
   * @param {RequestOption} requestOption
   */
  constructor(url, requestOption) {
    this.url = url;
    this.requestOption = requestOption;
  }

  configAsyncRequestHeaders(isAuthenticated) {
    if(isAuthenticated){
      this.requestOption.headers["request_page"] = window.location.href;
      this.requestOption.headers["request_secret"] = EncryptUtils.encryptMD5(window.location.href + AppSetting.localStorageValue.user.get().identityUserInfo.internalUserID);
    }

    if (this.requestOption.requireAuth) {
      this.requestOption.headers["Authorization"] =
        "Bearer " + AppSetting.localStorageValue.token.accessToken.get();
      this.requestOption.headers["RefreshToken"] =
        AppSetting.localStorageValue.token.refreshToken.get();
    }
  }

  configAsyncRequestBody() {
    if (
      this.nonRequestBodyVerbs[this.requestOption.method.toLowerCase()] &&
      this.requestOption.body
    ) {
      let queryObject;
      try {
        queryObject = JSON.parse(this.requestOption.body);
      } catch (error) {
        queryObject = this.requestOption.body;
      }
      if(this.requestOption.noEnCode) {
        this.url += "?" + new UrlParameterEncoder(queryObject).getNotEncodeQueryString();
      } else {
        this.url += "?" + new UrlParameterEncoder(queryObject).getQueryString();
      }
      delete this.requestOption.body;
    }
  }

  launchAsyncRequest() {
    const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
    const isLogin = this.url.toLowerCase().includes("login");
    if (!isAuthenticated && !isLogin) {
      window.location.reload();
      return;
    }

    this.configAsyncRequestHeaders(isAuthenticated);
    this.configAsyncRequestBody();

    return fetch(this.url, this.requestOption)
      .then((response) => {
        if (response.status === 401) {
          AppSetting.localStorageValue.setUnauthenticated();
          window.location.reload();
          return;
        }
        this._checkStatus(response) && this._refreshAccessToken(response);
        if (response.headers.get("content-type")?.includes("application/json")) {
          return response.json();
        } else {
          return response;
        }
      })
      .then((result) => {
        return this.requestOption.onSuccess(result, this.requestOption.returnPara);
      })
      .catch((error) => {
        this.requestOption.onError(error, this.requestOption.returnPara);
      });
  }

  async launchSyncRequest() {
    try {
      const isAuthenticated = JSON.parse(
        localStorage.getItem("isAuthenticated")
      );
      const isLogin = this.url.toLowerCase().includes("login");
      if (!isAuthenticated && !isLogin) {
        window.location.reload();
        return;
      }

      if (this.requestOption.requireAuth) {
        this.requestOption.headers["Authorization"] =
          "Bearer " + AppSetting.localStorageValue.token.accessToken.get();
        this.requestOption.headers["RefreshToken"] =
          AppSetting.localStorageValue.token.refreshToken.get();
      }

      if (
        this.nonRequestBodyVerbs[this.requestOption.method.toLowerCase()] &&
        this.requestOption.body
      ) {
        let queryObject = JSON.parse(this.requestOption.body);
        if(this.requestOption.noEnCode) {
          this.url += "?" + new UrlParameterEncoder(queryObject).getNotEncodeQueryString();
        } else {
          this.url += "?" + new UrlParameterEncoder(queryObject).getQueryString();
        }
        delete this.requestOption.body;
      }

      const response = await fetch(this.url, this.requestOption);
      if (!response.ok) {
        ErrorStorage.setError(response.status, response.statusText);
        this.requestOption.onError(response.status);
        return;
      }

      this._refreshAccessToken(response);
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  _refreshAccessToken(response) {
    if (!this.requestOption.requireAuth) {
      return;
    }

    let headerKey = Object.keys(response.headers);

    if (
      !!headerKey.find((element) => element === "Authorization") &&
      !!headerKey.find((element) => element === "RefreshToken")
    ) {
      let access = response.headers["Authorization"].replace("Bearer ", "");
      let refresh = response.headers["RefreshToken"];
      new TokenStorage().refreshToken(access, refresh);
    }
  }

  _checkStatus(response) {
    if (!response.ok) {
      ErrorStorage.setError(response.status, response.statusText);
      throw Error(response.status);
    }

    return true;
  }

  get nonRequestBodyVerbs() {
    return {
      post: false,
      head: true,
      get: true,
      put: false,
      patch: false,
      delete: false,
      options: true,
      connect: true,
      trace: true,
      Patch: false,
    };
  }
}
