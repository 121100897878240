import requiredWarning from "../../../assets/image/required-warning.png";
import BaseComponent from "../../common/BaseComponent";
import styles from "./index.module.scss";

export default class InputLabel extends BaseComponent {
  render() {
    const { text, required = false, className } = this.props;
    return (
      <div className={className}>
        <span className={styles.inputLabelText}>{text}</span>
        {required && (
          <img
            src={requiredWarning}
            alt="required enter"
            className={styles.inputLabelRequiredIcon}
          />
        )}
      </div>
    );
  }
}
