import React from "react";
import ApproveProcedureFileSection from "../approveProcedureFileSection";
import styles from "../index.module.scss";
import {
  dataStatus,
  savedInRadioValues,
} from "../../../publicDictionaryValues";
import ReadOnlyProcedureProcessSavedIn from "../fields/savedInFields/readOnlySavedIn";
import ReadOnlyProcedureProcessClientServerPath from "../fields/serverPathFields/readOnlyServerPath";

export default class ReadOnlyApproveProcedureFileSection extends ApproveProcedureFileSection {
  handleReadOnlyApproveProcedureFileSectionSavedIn = () => {
    const {
      procedureSaveInCategoryStatusID,
      initSavedInStatus,
      savedInReturnReasonInputValue,
      savedInRadioValue,
      savedInReturnReasonLog,
    } = this.props.savedInProps;
    const { procedureSavedInRef } = this.props.refs;

    return (
      <ReadOnlyProcedureProcessSavedIn
        ref={procedureSavedInRef}
        initSavedInStatus={initSavedInStatus}
        savedInReturnReasonLog={savedInReturnReasonLog}
        isSavedInReturnReasonDisabled={
          savedInReturnReasonInputValue === null ||
          procedureSaveInCategoryStatusID === dataStatus.approval
        }
        savedInReturnReasonInputValue={savedInReturnReasonInputValue}
        savedInRadioValue={savedInRadioValue}
        procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
      />
    );
  };

  handleReadOnlyApproveProcedureFileSectionClientServerPath = () => {
    const {
      clientServerPathReturnReason,
      clientServerPathStatusID,
      serverPathReturnReasonInputValue,
      clientServerPathStatusName,
      serverPathValue,
      serverPathReturnReasonLog,
    } = this.props.serverPathProps;
    const { currentRole } = this.props.sectionProps;
    const { procedureServerPathRef } = this.props.refs;

    return (
      <ReadOnlyProcedureProcessClientServerPath
        isServerPathReturnReasonDisabled={
          serverPathReturnReasonInputValue === null ||
          clientServerPathStatusID === dataStatus.approval
        }
        serverPathValue={serverPathValue}
        ref={procedureServerPathRef}
        serverPathStatusID={clientServerPathStatusID}
        serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
        currentRole={currentRole}
        serverPathReturnReasonLog={serverPathReturnReasonLog}
        clientServerPathReturnReason={clientServerPathReturnReason}
        initServerPathStatus={
          this.props.initServerPathSectionState.clientServerPathStatusID
        }
        isServerPathReturned={clientServerPathStatusName === "Return"}
      />
    );
  };

  handleReturnSectionTop = () => {
    const { savedInRadioValue } = this.props.savedInProps;

    return (
      <div className={styles.fileSectionTop}>
        {this.handleReadOnlyApproveProcedureFileSectionSavedIn()}
        {savedInRadioValue !== savedInRadioValues.systemOnly &&
          this.handleReadOnlyApproveProcedureFileSectionClientServerPath()}
      </div>
    );
  };
}
