import React from "react";
import BaseComponent from "../../common/BaseComponent";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "./index.scss";
import { Skeleton } from "antd";

export default class ProcedureBlock extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isExpend: this.props.isOpen,
      isFirstLoadData: !this.props.isOpen,
      loading: false,
    };
  }
  handleArrowChange = () => {
    this.setState({ isExpend: !this.state.isExpend });
  };

  handleFirstLoadData = () => {
    if (
      !this.state.isFirstLoadData ||
      this.props.onShowDataLoad === undefined
    ) {
      return true;
    }

    this.setState({ loading: true });
    this.setState({ isFirstLoadData: false });
    this.props?.onShowDataLoad().then((value) => {
      this.setState({ loading: false });
    });

    return true;
  };

  render() {
    const { child, isShowArrow, className } = this.props;
    const { isExpend } = this.state;
    return (
      <div
        className={`procedureBlock ${className} ${
          isExpend ? "expend" : "fold"
        }`}
      >
        {isShowArrow && (
          <div
            className="arrowIcon"
            onClick={() =>
              this.handleFirstLoadData() && this.handleArrowChange()
            }
          >
            <RightOutlined
              style={{
                color: "#3971CA",
                display: isExpend ? "none" : "block",
              }}
            />
            <DownOutlined
              style={{
                color: "#3971CA",
                display: isExpend ? "block" : "none",
              }}
            />
          </div>
        )}
        <div className="blockContent">
          {this.state.loading ? <Skeleton active /> : child}
        </div>
      </div>
    );
  }
}
