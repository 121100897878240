import React from "react";
import InputLabel from "../../../../base/basicComponents/inputLabel";
import Input, { ExpendTextArea } from "../../../../base/basicComponents/input";
import BaseProcedureInformation from "../baseProcedureInfoSection";
import styles from "../index.module.scss";

export default class ReadOnlyProcedureInformation extends BaseProcedureInformation {
  render() {
    const { noteDisabled, title, submitData, procedureData } =
      this.props;

    const PPOValue = () => {
      const currentPPO = submitData?.primaryProcedureOwner || procedureData?.primaryProcedureOwner;
      return currentPPO?.primaryProcedureOwnerName || null;
    }

    const POValue = () => {
      const currentPO = submitData?.procedureOwner || procedureData?.procedureOwner;
      return currentPO?.procedureOwnerName || null;
    }

    const labelInputComponent = (text, value) => {
      return (
        <div className={styles.informationItem}>
          <InputLabel text={text} className={styles.informationLabel} />
          <Input value={value} disabled={true} />
        </div>
      );
    };

    return (
      <div className={styles.ProcedureInformation}>
        <div className={styles.procedureInfoTop}>
          <div className={styles.informationTitle}>
            <span className={`${styles.titleText} fs-18`}>{title}</span>
          </div>
        </div>
        <div className={styles.informationContent}>
          {labelInputComponent(
              "Procedure Status",
              this.getProcedureStatus(submitData?.procedureStatus),
          )}
          {labelInputComponent(
              "PMP Excluded",
              submitData?.isExcluded,
          )}
          {labelInputComponent(
              "Primary Procedure Owner",
              PPOValue(),
          )}
          {labelInputComponent(
              "Procedure Owner",
              POValue(),
          )}
          <div className={styles.informationItem}>
            <InputLabel text="Note" className={styles.informationLabel} />
            <ExpendTextArea
              className={styles.informationExpendTextArea}
              value={submitData?.procedureNote}
              disabled={noteDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
