import { createRef } from "react";
import ApplyPMPExcludedService from "../../../service/applyPmpExcluded";
import ProcessBase from "../../commonComponents/processBase";
import { pmpExcludedReasonSelects } from "../../publicDictionaryValues";
const procedureRef = createRef();
export default class PMPExcludedProcess extends ProcessBase {
  constructor(props) {
    super(props);
    this.initProcedureFileState();
    this.taskExcludedProcedureStepID = null;
    this.initProcedureTableData = [];
  }

  /**
   * Init Component State
   */
  initProcedureFileState = () => {
    this.state = {
      pageState: {
        requestByID: null,
        requestByName: null,
        receiveDate: null,
        procedureID: null,
        procedureStepID: null,
        procedureFolder: {},
        currentRole: null,
        loading: false,
        procedureStepStatus: null,
        procedureStatus: null,
        procedureNote: "",
      },
      initTableDate: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: this.initProcedureFileTableState,
      fileTableData: [],
      supportingTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      shareTaskData: {},
      ppoList: [],
      jbProcedureSavedIn: null,
    };
  };

  setSavedInServerPathState = (result) => {
    result.procedureFolder !== null &&
      this.setState({
        savedInSectionState: {
          ...this.state.savedInSectionState,
          savedInRadioValue:
            result.procedureFolder?.procedureSaveInCategoryID || null,
        },
        serverPathSectionState: {
          ...this.state.serverPathSectionState,
          serverPathValue: result.procedureFolder?.clientServerPath || null,
        },
      });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  setProcedureFileData = (result) => {
    this.procedureFileStepID = this.taskExcludedProcedureStepID;
    this.setProcedureTableData(result.procedureFolder);

    this.setState({
      fileTableData: JSON.parse(JSON.stringify(this.procedureTableData)),
      jbProcedureSavedIn:
        result.procedureFolder === null
          ? null
          : result.procedureFolder.jbProcedureSaveIn,
    });

    this.setSavedInServerPathState(result);
  };

  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: JSON.parse(JSON.stringify(this.supportingTableData)),
    });
  };

  setProcedureInfoData = (result) => {
    this.setState({
      procedureInfoData: {
        processId: result.processID,
        primaryProcedureOwnerId:
          result.primaryProcedureOwner?.primaryProcedureOwnerID,
        primaryProcedureOwnerName: 
          result.primaryProcedureOwner?.primaryProcedureOwnerName,
        procedureOwnerId: result.procedureOwner?.procedureOwnerID,
        procedureOwnerName: result.procedureOwner?.procedureOwnerName,
        isPMPExcluded: result.applyPmpExcluded.isExcluded,
        pmpReasonSelect: result.applyPmpExcluded.excludedReasonID,
        pmpReasonInput: result.applyPmpExcluded.otherReason,
        note: result.procedureNote,
      },
    });
    this.taskExcludedProcedureStepID =
      result.applyPmpExcluded.taskExcludedProcedureStepID;
  };

  /**
   * Build SaveIn Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildSavedInSectionState = (data) => {
    return {
      savedInRadioValue: data.procedureSaveInCategoryID,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
    };
  };

  componentDidMount() {
    this.getInitData(
      ApplyPMPExcludedService.getApplyPmpExcludedStepItem,
      "Get Procedure Information Failed"
    );
  }

  /* function about get data from procedure file table */
  returnFileDataValue = (name, index) => {
    const procedureFileData = procedureRef.current.getDataList();
    let deleteList = procedureRef.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  /* function about return submit data to use in integrate trigger api */
  handleReturnSubmitData = (actionID, actionName, infoCurrent, pageCurrent) => {
    let isPMPExcluded = null;
    let pmpReasonSelect = null;
    let pmpReasonInput = null;
    let rejectReason = null;
    if (infoCurrent !== null && actionName ==='Approve') {
      isPMPExcluded = infoCurrent.state.currentPMPExcluded;
      pmpReasonSelect = infoCurrent.state.currentPMPExcludedReasonSelect;
      pmpReasonInput = infoCurrent.state.currentPMPExcludedReasonInput;
    } else if (infoCurrent !== null && actionName ==='Reject') {
      isPMPExcluded = this.state.procedureInfoData.isPMPExcluded;
      pmpReasonSelect = this.state.procedureInfoData.pmpReasonSelect;
      pmpReasonInput = this.state.procedureInfoData.pmpReasonInput;
    }
    if (pageCurrent !== null) {
      rejectReason = pageCurrent.state.pageState.rejectReason;
    }

    return {
      procedureProcessId: null,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      procedureStepID: this.procedureStepID,
      rejectReason: rejectReason,
      action: 0,
      taskExcludedProcedureStepID: this.taskExcludedProcedureStepID,
      isExcluded: isPMPExcluded,
      excludedReasonId: pmpReasonSelect,
      reason:
        pmpReasonSelect === pmpExcludedReasonSelects.otherReason
          ? pmpReasonInput
          : null,
    };
  };
}
