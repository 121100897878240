import React, { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import TaskInformation from "../../../sections/taskInfoSections";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import styles from "../index.module.scss";
import ChangePOService from "../../../../service/changePO";
import ChangePOProcedureInformation from "../../../sections/procedureInfoSections/changePOProcedureInfoSection";
import ProcessBase from "../../../commonComponents/processBase";
import NormalSpin from "../../../../base/basicComponents/spin";
import StepService from "../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const procedureInfoRef = createRef();
export default class ChangePOCompleted extends ProcessBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageHeadState: this.initChangePOCompletedPageHeadState(),
      procedureFileData: this.initChangePOCompletedProcedureFileData(),
      supportingFileData: [],
      procedureInfoState: {},
      taskInformationData: [],
      shareTask: this.initChangePOCompletedShareTask(),
      jbProcedureSavedIn: null,
    };
    this.params = {
      TaskId: this._getRoutingParam("ProcedureTaskId"),
      ProcedureID: this._getRoutingParam("ProcedureID"),
      ProcedureStepID: this._getRoutingParam("ProcedureStepID"),
    };
  }

  initChangePOCompletedPageHeadState = () => {
    return {
      receiveDate: null,
      requestByName: null,
      procedureStepStatus: null,
    };
  };

  initChangePOCompletedProcedureFileData = () => {
    return {
      procedureTableData: [],
      clientServerPath: null,
      savedInRadioValue: null,
    };
  };

  initChangePOCompletedShareTask = () => {
    return {
      mainTaskAccountId: null,
      procedureProcessID: null,
      reason: null,
      returnReasonLogs: null,
      shareTaskAccountId: null,
      shareTaskProcedureStepID: null,
      shareTasks: [],
      submitReasonLogs: null,
    };
  };

  _showChangePOCompletedLoading = () => {
    this.setState({ loading: true });
  };

  _hideChangePOCompletedLoading = () => {
    this.setState({ loading: false });
  };

  closeChangePOCompletedPage = () => {
    window.location = "/ToDoList";
  };

  /* callback about get task information successed */
  _getChangePOCompletedTaskInfoSuccess = (result) => {
    const data = result;
    this.setState({
      taskInformationData: data,
    });
  };

  /* callback about get task information failed */
  _getChangePOCompletedTaskInfoFailed = (result) => {
    // this._alertError("Get Task Information Failed");
    console.log(result);
  };

  /* function about get task information section data */
  getChangePOCompletedTaskInfo = () => {
    ProcedureDetailService.getTaskInfo(
      this.taskID,
      this._getChangePOCompletedTaskInfoSuccess,
      this._getChangePOCompletedTaskInfoFailed
    );
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setState({
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder !== null
              ? result.procedureFolder.procedureSaveInCategoryID
              : null,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
    }
  };

  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingFileData: this.supportingTableData,
      });
    }
  };

  setShareTaskData = (result) => {
    if (result.shareTask === null || result.shareTask === undefined) {
      return;
    }

    this.setState({
      shareTask: result.shareTask,
    });
  };

  _getProcedureOwnerStepItemSuccess = (result) => {
    const {
      statusCode,
      message,
      reviceDate,
      requestByName,
      primaryProcedureOwner,
      procedureOwner,
      procedureNote,
      shareTask,
      applyPmpExcluded,
      procedureFolder,
      procedureStepStatus,
      workflowProcessInstanceId,
      workflowProcessStepInfo,
      procedureStatusID,
    } = result;
    if (statusCode === 401) {
      this._alertError(message);
    } else {
      this.setProcedureTableData(procedureFolder);
      this.setSupportingTableData(result);
      this.setState({
        pageHeadState: {
          receiveDate: this._formateDate(reviceDate),
          requestByName,
          procedureStepStatus,
        },
        procedureInfoState: {
          ...this.state.procedureInfoState,
          primaryProcedureOwner,
          procedureOwner: procedureOwner,
          workflowProcessInstanceId,
          isExcluded: applyPmpExcluded?.isExcluded ? "Yes" : "No",
          procedureNote,
          workflowProcessStepInfo,
          procedureStatus: procedureStatusID,
        },
        shareTask,
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder === null
              ? null
              : result.procedureFolder.procedureSaveInCategoryID,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
      this._hideChangePOCompletedLoading();
    }
  };

  _getProcedureOwnerStepItemFailed = () => {
    this._alertError("Get Procedure Owner Step Item Failed");
  };

  getProcedureOwnerStepItem = () => {
    const parentPageData = this._getRoutingParam("ParentPage");
    const param = {
      taskID: this.taskID,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: parentPageData,
    };

    ChangePOService.getProcedureOwnerStepItem(
      param,
      this._getProcedureOwnerStepItemSuccess,
      this._getProcedureOwnerStepItemFailed
    );
  };

  _getPrimaryProcedureOwnerDDLSuccess = (result) => {
    this.setState({
      procedureInfoState: {
        ...this.state.procedureInfoState,
        ppoList: result,
      },
    });
  };

  _getPrimaryProcedureOwnerDDLFailed = () => {
    this._alertError("Get Primary Procedure Owner List Failed");
  };

  getPrimaryProcedureOwnerDDL = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.taskID,
      this._getPrimaryProcedureOwnerDDLSuccess,
      this._getPrimaryProcedureOwnerDDLFailed
    );
  };

  componentDidMount() {
    this._showChangePOCompletedLoading();
    this.getProcedureOwnerStepItem();
    this.getChangePOCompletedTaskInfo();
    this.getPrimaryProcedureOwnerDDL();
  }

  handleChangePOCompletedPageHeader = () => {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.state.pageHeadState;
    const isReassignPending = procedureStepStatus === "Pending";

    return (
      <div className={styles.header}>
        <PageHeader
          title="Change Procedure Owner"
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status={
            procedureStepStatus
              ? isReassignPending
                ? "Pending"
                : "Complete"
              : ""
          }
        />
      </div>
    );
  };

  handleChangePOCompletedTaskInformation = () => {
    const { taskInformationData } = this.state;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<TaskInformation data={taskInformationData} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.setTaskInfoData,
            this.setError,
            [StepBusinessType.taskInfo]
          );
        }}
      />
    );
  };

  handleChangePOCompletedProcedureFileSection = () => {
    const { jbProcedureSavedIn } = this.state;

    const { procedureTableData, clientServerPath, savedInRadioValue } =
      this.state.procedureFileData;

    return (
      <ProcedureBlock
        child={
          <ReadOnlyProcedureFileSection
            procedureFileData={procedureTableData}
            procedureFileRef={procedureFileRef}
            clientServerPath={clientServerPath}
            savedInRadioValue={savedInRadioValue}
            jbProcedureSavedIn={jbProcedureSavedIn}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.setProcedureFileData,
            this.setError,
            [StepBusinessType.procedureFile]
          );
        }}
      />
    );
  };

  handleChangePOCompletedSupportingFileSection = () => {
    return (
      <ProcedureBlock
        child={
          <ReadOnlySupportingFileSection
            supportingFileRef={supportingFileRef}
            supportingFileData={this.state.supportingFileData}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  handleChangePOCompletedProcedureInformation = () => {
    const { procedureStepStatus } = this.state.pageHeadState;
    const { procedureInfoState } = this.state;
    const procedureData = {
      ...procedureInfoState,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
    };
    const params = {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };
    const isReassignPending = procedureStepStatus === "Pending";

    return (
      <ProcedureBlock
        className={styles.ProcedureInfoSection}
        child={
          <ChangePOProcedureInformation
            ref={procedureInfoRef}
            procedureTaskId={this.taskID}
            procedureData={procedureData}
            submitData={procedureInfoState}
            noteDisabled={true}
            isPPODisabled={true}
            title="Procedure Information"
            closePage={this.closeChangePOCompletedPage}
            isReassignPending={isReassignPending}
            _showLoading={this._showChangePOCompletedLoading}
            _hideLoading={this._hideChangePOCompletedLoading}
            params={params}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleChangePOCompletedShareTaskSection = () => {
    const { shareTask } = this.state;

    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={<ActiveShareTaskReadOnly shareTaskData={shareTask} />}
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.setShareTaskData,
            this.setError,
            [StepBusinessType.shareTask]
          );
        }}
      />
    );
  };

  render() {
    const { loading } = this.state;

    return (
      <NormalSpin spinning={loading} size="large" zIndex={1500}>
        <div className={styles.body}>
          {this.handleChangePOCompletedPageHeader()}
          {this.handleChangePOCompletedTaskInformation()}
          {this.handleChangePOCompletedProcedureFileSection()}
          {this.handleChangePOCompletedSupportingFileSection()}
          {this.handleChangePOCompletedProcedureInformation()}
          {this.handleChangePOCompletedShareTaskSection()}
        </div>
      </NormalSpin>
    );
  }
}
