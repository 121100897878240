import React from "react";
import NormalModal from "../../../../../../base/basicComponents/modal";
import SectionEditIcon from "../../../../../../base/basicComponents/sectionEditIcon";
import NormalSpin from "../../../../../../base/basicComponents/spin";
import NormalToolTip from "../../../../../../base/basicComponents/toolTip";
import {
  fileTags,
  handleAlertUploadFileError,
  proposedChangeTags,
} from "../../../../../publicDictionaryValues";
import ProcedureDetailService from "../../../../../../service/procedureFile/ProcedureDetailService";
import UpdateSupportingFileTable from "../../supportingFileTable/updateSupportingFileTable";
import Buttons from "../../../../../../base/basicComponents/button";
import BaseSupportingFileManagement from "../baseSupportingFileManagement";
import styles from "../../../../../commonComponents/fileManagementFields/baseFileManagement/index.module.scss";
import "./index.scss";
import AppSetting from "../../../../../../config/AppSetting";
import UpdateSupportingFileModalTable from "../../supportingFileTable/updateSupportingFileModalTable";
import ShareDocument from "../../../../shareDocumentSection";

export default class UpdateSupportingFileManagement extends BaseSupportingFileManagement {
  /**
   * function about click confim when click delete each row
   * @param {*} currentRowIndex current row index
   */
  handleRowFileDeleteModalConfirm = (currentRowIndex) => {
    const dataList = this.getDataList();
    const lineIdValue = dataList[currentRowIndex].data.find(
      (item) => item.name === "lineId"
    ).value;
    const initOrLastSaveData = this.state.initOrLastSaveData;
    const existsInB = initOrLastSaveData.some((objB) => {
      const lineIdBValue = objB.data.find(
        (item) => item.name === "lineId"
      ).value;
      return lineIdBValue === lineIdValue;
    });
    if (existsInB) {
      this.deleteList.push({ ...dataList[currentRowIndex], isDelete: true });
    } else {
      const fileId = dataList[currentRowIndex].data.find(
        (x) => x.name === "fileId"
      ).value;
      let dto = {
        ProductKey: AppSetting.urlPrefix.deleteFile.ProductKey,
        StorageFileID: fileId,
      };
      ProcedureDetailService.deleteFile(
        dto,
        this._deleteFileOnSuccess,
        this._deleteFileOnFail,
        fileId
      );
    }
    dataList.splice(currentRowIndex, 1);
    this.props.fileTableRef.current.updateDataList(dataList);
    this.enableOrDisableSaveSubmitCancleButton(dataList);
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: null,
    });
  };

  /**
   * delete procedure when click each row's delete button in revise page
   * @param {*} e
   */
  deleteRowFile = (e) => {
    const dataList = this.getDataList();
    const index = e.target.attributes.rowindex.nodeValue;
    const fileNameObj = dataList[index].data.find(
      (item) => item.name === "fileName"
    );
    const initFileNameObj = dataList[index].data.find(
      (item) => item.name === "initFileName"
    );
    const fileIdObj = dataList[index].data.find(
      (item) => item.name === "fileId"
    );
    const initFileIdObj = dataList[index].data.find(
      (item) => item.name === "initFileId"
    );
    const fileKeyObj = dataList[index].data.find(
      (item) => item.name === "fileKey"
    );
    const initFileKeyObj = dataList[index].data.find(
      (item) => item.name === "initFileKey"
    );

    const replaceUserId = dataList[index].data.find((item) => item.name === "replaceUserId");
    const replaceUserName = dataList[index].data.find((item) => item.name === "replaceUserName");
    let dataArray = [];
    if(replaceUserId && replaceUserName) {
      dataArray = dataList[index].data.filter(item => item.name !== "replaceUserId" && item.name !== "replaceUserName");
      dataList[index].data = [...dataArray];
    }

    const isNew = fileNameObj.tagValue === fileTags.new;
    if (isNew) {
      this.setState({
        isModalOpen: true,
        modalChildContent: "Are you sure to delete this file?",
        handleModalConfirm: () =>
          this.handleRowFileDeleteModalConfirm(
            e.target.attributes.rowindex.nodeValue
          ),
      });
    } else {
      fileNameObj.value = initFileNameObj.value;
      fileIdObj.value = initFileIdObj.value;
      fileKeyObj.value = initFileKeyObj.value;
      dataList[index].data.find((item) => item.name === "fileName").tagValue =
        fileTags.deleted;
      dataList[index].isUpdate = false;
      dataList[index].isReturnDisabled = true;
      dataList[index].isApproveDisabled = false;
      dataList[index].data.find(
        (item) => item.name === "proposedChange"
      ).value = proposedChangeTags.delete;
      if (dataList[index].isError) {
        dataList[index].isError = false;
      }
      this.props.fileTableRef.current.updateDataList(dataList);
      this.enableOrDisableSaveSubmitCancleButton(dataList);
    }
  };

  /**
   * click replace button in revise page
   * @param {*} e
   */
  replaceRowFile = (e) => {
    e.target.nextSibling.click();
  };

  /* rollback for procedure file table replace success */
  _replaceRowFileOnSuccess = (result, obj) => {
    this.props._hideLoading();
    const { index, fileName } = obj;
    const tableDataList = this.getDataList();
    tableDataList[index].data.find(
      (item) => item.name === "fileName"
    ).tagValue = fileTags.replaced;
    tableDataList[index].data.find((item) => item.name === "fileId").value =
      result.storageFileID;
    tableDataList[index].data.find((item) => item.name === "fileName").value =
      fileName;
    tableDataList[index].data.find(
      (item) => item.name === "proposedChange"
    ).value = proposedChangeTags.update;
    const replaceUserId = tableDataList[index].data.find(
      (item) => item.name === "replaceUserId"
    );
    const replaceUserName = tableDataList[index].data.find(
      (item) => item.name === "replaceUserName"
    );
    if (replaceUserId && replaceUserName) {
      tableDataList[index].data.find(
        (item) => item.name === "replaceUserId"
      ).value = this.user.identityUserInfo.internalUserID;
      tableDataList[index].data.find(
        (item) => item.name === "replaceUserName"
      ).value = this.user.identityUserInfo.englishName;
    } else {
      tableDataList[index].data.push({
        name: "replaceUserId",
        value: this.user.identityUserInfo.internalUserID,
      });
      tableDataList[index].data.push({
        name: "replaceUserName",
        value: this.user.identityUserInfo.englishName,
      });
    }
    tableDataList[index].isReturnDisabled = false;
    tableDataList[index].isUpdate = true;
    if (tableDataList[index].isError) {
      tableDataList[index].isError = false;
    }
    this.props.fileTableRef.current.updateDataList(tableDataList);
    this.enableOrDisableSaveSubmitCancleButton(tableDataList);
  };

  /* rollback for procedure file table replace error */
  _replaceRowFileOnError = (error) => {
    console.log(error);
    this._alertError("Replace failed");
    this.props._hideLoading();
  };

  /* function about procedure file table replace button */
  handleRowReplaceFileSelect = (event, index) => {
    this.isFileDuplicate = false;
    const files = [...event.target.files];
    const tableData = this.getDataList();
    const tableList = [...tableData];
    tableList.splice(index, 1);
    for (let item of files) {
      tableList.forEach((data) => {
        if (data.data.find((i) => i.name === "fileName").value === item.name) {
          this._alertError(`${item.name} already exists.`);
          this.isFileDuplicate = true;
        }
      });
      if (handleAlertUploadFileError(files)) {
        return;
      }
      const returnPara = { index: index, fileName: item.name };
      if (!this.isFileDuplicate) {
        this.props._showLoading();
        let fileForm = new FormData();
        let addFileDto = this.getAddFileDto(item);
        fileForm.append("formFile", item);
        fileForm.append("addFileDto", JSON.stringify(addFileDto));
        ProcedureDetailService.addFile(
          fileForm,
          (response) => {
            this._replaceRowFileOnSuccess(response, returnPara);
          },
          this._replaceRowFileOnError
        );
      } else {
        return;
      }
    }
  };

  /**
   * control save, submit and cancel buttons disabled
   * @param {*} dataList
   * @param {*} clientServerPath
   * @param {*} procedureSavedInValue
   */
  enableOrDisableSaveSubmitCancleButton = (
    dataList,
    clientServerPath,
    procedureSavedInValue = this.state.savedInRadioValue
  ) => {
    this.clearFileTableStatus();
    const isCancelDisable = this.isCancelDisable(
      dataList,
      clientServerPath,
      procedureSavedInValue
    );
    let isSubmitDisabled = true;
    dataList.forEach((dataItem) => {
      const proposedChangeItem = dataItem.data.find(
        (item) => item.name === "proposedChange"
      );
      if (proposedChangeItem && proposedChangeItem.value !== "") {
        isSubmitDisabled = false;
      }
    });
    this.setState({
      cancelDisabled: isCancelDisable,
      submitDisabled: isSubmitDisabled,
    });
  };

  /**
   * return save, submit and cancel buttons in table
   * @param {*} isShowActionBtns flag of whether show these buttons
   * @param {*} saveDisabled flag of whether disable save button
   * @param {*} onSave method for save
   * @param {*} submitDisabled flag of whether disable submit button
   * @param {*} onSubmit method for submit
   * @param {*} cancelDisabled flag of whether disable cancel button
   * @param {*} onCancel method for cancel
   * @returns
   */
  handleReturnActionBtns = (obj) => {
    const { submitDisabled, onSubmit, onCancel, procedureMandatoryCheck } = obj;
    return (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" disabled={false} size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => {
            const shouldShowErrorServer = this.getDataList().length === 0;
            if (shouldShowErrorServer) {
              procedureMandatoryCheck();
            } else {
              onSubmit();
            }
          }}
        >
          Submit for Approval
        </Buttons>
      </div>
    );
  };

  handleReturnProcedureFileTable = (obj) => {
    const {
      fileTableRef,
      columns,
      hasSubmit,
      isScrollData,
      className,
      optionList,
      handleReturnClick,
      currentRole,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      fileTableStatus,
      isShowHistoryVersion,
    } = obj;
    return className === "supportingFiles" ? (
      <UpdateSupportingFileTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        isShowHistoryVersion={isShowHistoryVersion}
        fileTableStatus={fileTableStatus}
        handleFileSelect={this.handleRowReplaceFileSelect}
      />
    ) : (
      <UpdateSupportingFileModalTable
        ref={fileTableRef}
        columns={columns}
        procedureNoteMaxLength={1000}
        fileSectionDisabled={hasSubmit}
        procedureNoteSize="small"
        inputOnChange={this.inputOnChange}
        hasSubmit={hasSubmit}
        isScrollData={isScrollData}
        fileTableRef={fileTableRef}
        className={className}
        isShowTotalFileNum={true}
        optionList={optionList}
        handleReturnClick={handleReturnClick}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isSupportingFile={isSupportingFile}
        isShowExpendIcon={isShowExpendIcon}
        fileTableStatus={fileTableStatus}
        handleFileSelect={this.handleRowReplaceFileSelect}
      />
    );
  };

  render() {
    const {
      title,
      fileTableRef,
      handleFileSelect,
      onSubmit,
      onCancel,
      hasSubmit,
      isScrollData,
      className,
      noteRef,
      handleReturnClick,
      currentRole,
      downloadFile,
      isSupportingFile,
      isShowExpendIcon,
      isShowDownloadBtn,
      isShowShareBtn,
      isShowUploadBtn,
      isShowDeleteBtn,
      isShowActionBtns,
      isShowSupportingFileEdit,
      isShowUpdate,
      isShowHistoryVersion,
      handleEditClick,
      isEditToolTipOpen,
      onBlur,
      status,
      noteDisabled,
      optionList,
      procedureMandatoryCheck,
      hintMsg,
      isUpdateSection,
    } = this.props;

    const {
      addNewFileDisabled,
      deleteFileDisabled,
      downloadFileDisabled,
      submitDisabled,
      cancelDisabled,
      fileTableStatus,
      isModalOpen,
      modalChildContent,
      handleModalConfirm,
      loading,
    } = this.state;

    const {
      isShareModalOpen,
      procedureFiles,
      supportingFiles,
      shareFiles,
      shareData,
      activeInternalUsers,
    } = this.state.shareDocument;

    const { savedInRadioValue } = this.getValue();

    const columns = [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow:
          (!this.props.inModal && !isUpdateSection) || this.props.isCopyPage,
      },
      {
        name: "Proposed Change",
        dataName: "proposedChange",
        type: "tag",
        isShow:
          (this.props.inModal || isUpdateSection) && !this.props.isCopyPage,
      },
      {
        name: "Note",
        dataName: "note",
        type: "input",
        ref: noteRef,
        isShow: status !== 3 && !this.props.isCopyPage,
        isDisabled: noteDisabled,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Replace",
        returnText: "Delete",
        approveColor: "blue",
        returnColor: "greyRed",
        onApproveClick: this.replaceRowFile,
        onReturnClick: () => this.deleteRowFile,
        isShow: !noteDisabled && !this.props.isCopyPage,
      },
    ];

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        children={
          <>
            <div className={styles.fileManagementDiv}>
              <div className={styles.fileManagementTop}>
                <p className={styles.fileManagementTitle}>
                  <span className={`${styles.titleText} fs-18`}>{title}</span>
                </p>
                <div className={styles.fileManagementTopButton}>
                  {isShowSupportingFileEdit && (
                    <NormalToolTip
                      element={
                        <SectionEditIcon
                          handleEditClick={handleEditClick}
                          onBlur={onBlur}
                        />
                      }
                      title={hintMsg}
                      trigger="click"
                      open={isEditToolTipOpen}
                      placement="left"
                      color="#3E6CB5"
                    />
                  )}
                </div>
              </div>
              <>
                <div className={styles.buttonDiv}>
                  {this.handleReturnDownloadBtn(
                    isShowDownloadBtn,
                    downloadFileDisabled
                  )}
                  {this.handleReturnShareBtn(
                    isShowShareBtn,
                    downloadFileDisabled
                  )}
                  {this.handleReturnUpdateBtn(isShowUpdate)}
                  {this.handleReturnUploadBtn(
                    isShowUploadBtn,
                    addNewFileDisabled,
                    handleFileSelect
                  )}
                  {this.handleReturnDeleteBtn(
                    isShowDeleteBtn,
                    deleteFileDisabled
                  )}
                </div>
                {this.handleReturnProcedureFileTable({
                  fileTableRef,
                  columns,
                  hasSubmit,
                  isScrollData,
                  className,
                  optionList,
                  handleReturnClick,
                  currentRole,
                  downloadFile,
                  isSupportingFile,
                  isShowExpendIcon,
                  fileTableStatus,
                  isShowHistoryVersion,
                })}
              </>
              {isShowActionBtns &&
                this.handleReturnActionBtns({
                  submitDisabled,
                  onSubmit,
                  cancelDisabled,
                  onCancel,
                  savedInRadioValue,
                  procedureMandatoryCheck,
                })}
            </div>
            <ShareDocument
              isModalOpen={isShareModalOpen}
              onCancel={() => {
                this.setState({
                  shareDocument: {
                    ...this.state.shareDocument,
                    isShareModalOpen: false,
                  },
                });
              }}
              procedureFiles={procedureFiles}
              supportingFiles={supportingFiles}
              shareFiles={shareFiles}
              shareData={shareData}
              activeInternalUsers={activeInternalUsers}
              isDetailPage={true}
            />
            <div>
              <NormalModal
                okText="Confirm"
                cancelText="Cancel"
                childContent={<p className="fs-16">{modalChildContent}</p>}
                isModalOpen={isModalOpen}
                handleOk={handleModalConfirm}
                handleCancel={this.hideModal}
              />
            </div>
          </>
        }
      />
    );
  }
}
