import React, { createRef } from "react";
import { SectionStatusDiv } from "../../../../../../base/basicComponents/status/index";
import ReturnReasonInput from "../../../../../../base/basicComponents/returnReasonInput";
import { ExpendTextArea } from "../../../../../../base/basicComponents/input";
import styles from "../index.module.scss";
import ApproveClientServerPath from "../approveSeverPath";

const serverPathReturnRef = createRef();
export default class ReadOnlyProcedureProcessClientServerPath extends ApproveClientServerPath {
  render() {
    const { procedureServerPathRef } = this.props;
    const {
      serverPathStatus,
      isServerPathShowEditBtn,
      isServerPathError,
      serverPathReturnReasonInputValue,
    } = this.state.serverPathSectionState;

    return (
      <div className={styles.procedureFileTop}>
        <div
          className={styles.clientServerPath}
          style={{ borderColor: isServerPathError ? "red" : "#b3b3b3" }}
          ref={procedureServerPathRef}
        >
          <div className={styles.approveServerPathTop}>
            <div className={styles.procedureServerPathLabel}>
              Client Server Path:
            </div>
            <ExpendTextArea
              className={styles.procedureServerPathValue}
              value={this.state.serverPathSectionState.serverPathValue}
              placeholder="Please enter Client Server Path"
              readonly="readonly"
              maxLength={500}
            />
          </div>
          <div className={styles.serverPathContent}>
            <div className={styles.statusBlock}>
              <div className={styles.statusLabel}>Status</div>
              <div className={styles.statusContent}>
                <SectionStatusDiv status={serverPathStatus} />
              </div>
            </div>
            <div className={styles.returnReasonBlock}>
              <div className={styles.returnReasonLabel}>Return Reason</div>
              <ReturnReasonInput
                inputRef={serverPathReturnRef}
                value={serverPathReturnReasonInputValue}
                isDisabled={true}
                isShowEditBtn={isServerPathShowEditBtn}
                isShowReasonLogIcon={
                  this.props.serverPathReturnReasonLog.length > 0
                }
                reasonLog={this.props.serverPathReturnReasonLog}
              />
            </div>
            <div className={styles.actionBtns}></div>
          </div>
        </div>
      </div>
    );
  }
}
