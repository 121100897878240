import { Modal, Spin } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import BaseComponent from "../../common/BaseComponent";
import { NormalTextArea } from "../input";
import NormalSelect from "../select";
import { FileUploadDragger } from "../uploadFile";
import controlPageBehavior from "./controlPageBehavior";
import "./index.scss";
import React from "react";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";

export default class NormalModal extends BaseComponent {
  render() {
    const {
      isModalOpen,
      handleOk,
      handleCancel,
      afterClose,
      closable = false,
      okText,
      cancelText,
      title,
      childContent,
      className = "",
      isOkDisabled,
      isCancelDisabled,
      width,
      confirmLoading = false,
      footer,
      zIndex = 1000,
      centered,
    } = this.props;
    return (
      <div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={afterClose}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={title}
          wrapClassName={`customModal ${className}`}
          okButtonProps={{ disabled: isOkDisabled }}
          cancelButtonProps={{ disabled: isCancelDisabled }}
          width={width}
          confirmLoading={confirmLoading}
          maskClosable={false}
          footer={footer}
          zIndex={zIndex}
          centered={centered}
        >
          {childContent}
        </Modal>
      </div>
    );
  }
}

export class ModalWithComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.tinyMceEditor = null;
  }

  setRichTextValue = (value) => {
    if (this.tinyMceEditor !== null) {
      this.tinyMceEditor.setContent(value === null ? "" : value, {
        silent: true,
      });
    }
  };

  render() {
    const {
      isModalOpen,
      handleOk,
      handleCancel,
      afterClose,
      closable = false,
      okText,
      cancelText,
      title,
      className = "",
      isOkDisabled,
      isCancelDisabled,
      maskClosable = false,
      modalWidth,
      confirmLoading = false,
      isShowTextArea,
      textAreaValue,
      textAreaOnChange,
      textAreaPlacement,
      textAreaStatus,
      textAreaMaxLength,
      textAreaClassName,
      label,
      isShowSelect,
      selectOptionList,
      selectDefaultValue,
      selectValue,
      selectOnChange,
      selectStatus,
      isShowUploadDragger,
      fileUploadRef,
      fileUploadInputRef,
      rowindex,
      fileIds,
      fileNames,
      isShowInnerDragger,
      isMultiple,
      isShowDeleteBtn,
      hideLoading,
      openLoading,
      isLoading,
      isShowTextAreaAndUploadDragger,
      uploadDraggerFileType,
      isShowRichTextEditor,
      isShowRichTextEditorUploadDragger,
      richTextEditorUploadDraggerLabel,
      richTextValue,
      handleEditorChange,
      centered,
      footer,
      richTextDisabled,
    } = this.props;

    return (
      <div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={afterClose}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={title}
          wrapClassName={`customModal ${className}`}
          okButtonProps={{ disabled: isOkDisabled }}
          cancelButtonProps={{ disabled: isCancelDisabled }}
          width={modalWidth}
          confirmLoading={confirmLoading}
          maskClosable={false}
          centered={centered}
          footer={footer}
        >
          {label}
          {isShowTextArea && (
            <NormalTextArea
              value={textAreaValue}
              onChange={(e) => textAreaOnChange(e)}
              placeholder={textAreaPlacement}
              maxLength={textAreaMaxLength}
              status={textAreaStatus}
              className={textAreaClassName}
            />
          )}
          {isShowRichTextEditor && (
            <Spin spinning={isLoading}>
              <Editor
                tinymceScriptSrc={
                  process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                }
                onInit={(evt, editor) => {
                  this.tinyMceEditor = editor;
                }}
                inline={false}
                value={richTextValue}
                disabled={richTextDisabled}
                onEditorChange={handleEditorChange}
                init={{
                  selector: "#forum-addedit-richtext",
                  menubar: false,
                  plugins:
                    "searchreplace table charmap pagebreak nonbreaking insertdatetime lists wordcount emoticons autosave",
                  toolbar:
                    "forecolor backcolor bold italic underline strikethrough lineheight| alignleft aligncenter alignright alignjustify outdent indent | bullist numlist  | fontfamily fontsize blocks | blockquote subscript superscript removeformat table charmap",
                  toolbar_mode: "wrap",
                  ui_mode: "split",
                  max_height: 800,
                  min_height: 500,
                  fontsize_formats:
                    "12px 14px 16px 18px 24px 36px 48px 56px 72px",
                  font_formats:
                    "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
                  importcss_append: true,
                  branding: false,
                  elementpath: false,
                  convert_urls: false,
                  autosave_ask_before_unload: false,
                }}
              />
              {isShowRichTextEditorUploadDragger && (
                <FileUploadDragger
                  fileUploadDraggerLabel={richTextEditorUploadDraggerLabel}
                  fileInputRef={fileUploadRef}
                  ref={fileUploadInputRef}
                  rowindex={rowindex}
                  fileIds={fileIds}
                  fileNames={fileNames}
                  isShowDragger={isShowInnerDragger}
                  isMultiple={isMultiple}
                  isShowDeleteBtn={isShowDeleteBtn}
                  hideLoading={hideLoading}
                  openLoading={openLoading}
                  loading={isLoading}
                  fileNameType={uploadDraggerFileType}
                />
              )}
            </Spin>
          )}
          {isShowSelect && (
            <NormalSelect
              optionList={selectOptionList}
              defaultValue={selectDefaultValue}
              value={selectValue}
              handleChange={(e) => {
                selectOnChange(e);
              }}
              status={selectStatus}
            />
          )}
          {isShowUploadDragger && (
            <FileUploadDragger
              fileInputRef={fileUploadRef}
              ref={fileUploadInputRef}
              rowindex={rowindex}
              fileIds={fileIds}
              fileNames={fileNames}
              isShowDragger={isShowInnerDragger}
              isMultiple={isMultiple}
              isShowDeleteBtn={isShowDeleteBtn}
              hideLoading={hideLoading}
              openLoading={openLoading}
              loading={isLoading}
              fileNameType={uploadDraggerFileType}
            />
          )}
          {isShowTextAreaAndUploadDragger && (
            <Spin spinning={isLoading}>
              <div>
                <NormalTextArea
                  value={textAreaValue}
                  onChange={(e) => textAreaOnChange(e)}
                  placeholder={textAreaPlacement}
                  maxLength={textAreaMaxLength}
                  status={textAreaStatus}
                  className={textAreaClassName}
                />
                <FileUploadDragger
                  fileInputRef={fileUploadRef}
                  ref={fileUploadInputRef}
                  rowindex={rowindex}
                  fileIds={fileIds}
                  fileNames={fileNames}
                  isShowDragger={isShowInnerDragger}
                  isMultiple={isMultiple}
                  isShowDeleteBtn={isShowDeleteBtn}
                  hideLoading={hideLoading}
                  openLoading={openLoading}
                  loading={isLoading}
                  fileNameType={uploadDraggerFileType}
                />
              </div>
            </Spin>
          )}
        </Modal>
      </div>
    );
  }
}

export class ModalWithScroll extends BaseComponent {
  refreshPage = () => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: this.props.actionType,
      setLock: false,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        window.location.reload();
      },
      (response) => {
        //empty function
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isModalOpen !== this.props.isModalOpen) {
      if (this.props.isModalOpen) {
        const modalElement =
          document.getElementsByClassName("modalWithScroll")[0];
        controlPageBehavior.addEleListener(modalElement);
        controlPageBehavior.resetListener(modalElement, this.refreshPage);
      } else {
        controlPageBehavior.clearListener();
      }
    }
  }
  render() {
    const { modalContent, width, isModalOpen, title } =
      this.props;
    const maskWidth = document.body.clientWidth;
    return (
      <div
        className="modalMask"
        style={{ width: maskWidth, display: isModalOpen ? "block" : "none" }}
      >
        <div className="modalWithScroll" style={{ width: width }}>
          <div className="modalTop">
            <p className="modalTitle">{title}</p>
          </div>
          <div className="modalContent">{modalContent}</div>
        </div>
      </div>
    );
  }
}
