import React from "react";
import BaseComponent from "../../common/BaseComponent";
import styles from "./style/index.module.scss";
import editIcon from "../../../assets/image/edit.png";
import editHoverIcon from "../../../assets/image/editHover.png";
import downArrowIcon from "../../../assets/image/downArrow.png";

export default class SectionEditIcon extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      editIcon: editIcon,
    };
  }
  render() {
    return (
      <div
        className={`${styles.editBtn} ${this.props.className}`}
        onClick={this.props.handleEditClick}
        onMouseEnter={() => {
          this.setState({ editIcon: editHoverIcon });
        }}
        onMouseLeave={() => this.setState({ editIcon: editIcon })}
        onBlur={this.props.onBlur}
        tabindex="-1"
        ref={this.props.editRef}
      >
        <div className={styles.editIcon}>
          <img src={this.state.editIcon} alt="Edit" />
        </div>
        <span className={styles.editText}>Edit</span>
        {this.props.showDownArrow && (
          <img src={downArrowIcon} className={styles.downArrowIcon} />
        )}
      </div>
    );
  }
}
