import BaseShareTask from "../baseShareTask";

export default class BaseReviseShare extends BaseShareTask {
  getDeleteData = () => {
    return this.deleteData;
  };

  updateReviseData = (data) => {
    this.setState({
      reviseData: data,
    });
  };

  ReasonOnchange = (e) => {
    this.setState({
      reasonValue: e.value,
      reasonInputStatus: "",
    });
  };
}
