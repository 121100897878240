import BaseService from "../base";

class ToDoListService extends BaseService {
  getAccountAbbrList = (success, error, returnPara) => {
    this.sendRequest(
      "getToDoListAccountAbbreviations",
      {},
      success,
      error,
      returnPara
    );
  };

  getAllAccountAbbreviations = (para, success, error, returnPara) => {
    this.sendRequest(
      "getAllAccountAbbreviations",
      para,
      success,
      error,
      returnPara
    );
  };

  getActionRequiredDDL = (success, error, returnPara) => {
    this.sendRequest("getActionRequiredDDL", {}, success, error, returnPara);
  };

  getActionTakenDDL = (success, error, returnPara) => {
    this.sendRequest("getActionTakenDDL", {}, success, error, returnPara);
  };

  getProcessCategoryDDL = (success, error, returnPara) => {
    this.sendRequest("getProcessCategoryDDL", {}, success, error, returnPara);
  };

  getPendingList = (para, success, error) => {
    this.sendRequest("getPendingList", para, success, error);
  };

  getPendingListFilter = (para, success, error) => {
    this.sendRequest("getPendingListFilter", para, success, error);
  };

  getCompletedList = (para, success, error) => {
    this.sendRequest("getCompletedList", para, success, error);
  };

  getCompletedListFilter = (para, success, error) => {
    this.sendRequest("getCompletedListFilter", para, success, error);
  };

  submitDelegate = (para, success, error) => {
    this.sendRequest("submitDelegate", para, success, error);
  };

  delegateCheck = (para, success, error) => {
    this.sendRequest("delegateCheck", para, success, error);
  };

  exportPendingTodoList = (para, success, error) => {
    this.sendRequest("exportPendingTodoList", para, success, error);
  };

  exportCompletedTodoList = (para, success, error) => {
    this.sendRequest("exportCompletedTodoList", para, success, error);
  };

  checkCompletedToDoListExport = (para) => {
    return this.sendSyncRequest("checkCompletedToDoListExport", para);
  };

  getDelegateToList = (para, success, error) => {
    this.sendRequest("getDelegateToList", para, success, error);
  };
}

const toDoListService = new ToDoListService();

export default toDoListService;
