import { Checkbox } from "antd";
import BaseComponent from "../../../../base/common/BaseComponent";
import EllipsisToolTip from "../../../../base/basicComponents/ellipsisToolTip";
import { ExpendTextAreaByProps } from "../../../../base/basicComponents/input/index";
import Buttons from "../../../../base/basicComponents/button";
import NormalSelect from "../../../../base/basicComponents/select";
import { SectionStatusDiv } from "../../../../base/basicComponents/status";
import UploadFile from "../../../../base/basicComponents/uploadFile";
import { fileTags, flowBusinessIdMap, downloadFileByUrl } from "../../../publicDictionaryValues";
import "../index.scss";
import HistoricalVersionComponent from "../../../../base/basicComponents/historicalVersionComponent";
class BaseTbody extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: "",
    };
    this.isHideReplaceBtn = false;
    this.isHideDeleteBtn = false;
    this.isHideApproveBtn = false;
    this.isHideReturnBtn = false;
  }
  returnCheckBox = (column, row, hasSubmit, fileSectionDisabled) => {
    const lineId = row.data.find((item) => item.name === "lineId").value;
    return (
      <td key={lineId} className="checkboxtd">
        <Checkbox
          checked={
            column.check(row.data.find((x) => x.name === "lineId").value)
              ? true
              : false
          }
          onChange={(e) =>
            column.onCheck(
              row.data.find((x) => x.name === "lineId").value,
              e.target
            )
          }
          disabled={hasSubmit || fileSectionDisabled}
        />
      </td>
    );
  };

  returnLink = (rowColumn, isSupportingFile) => {
    return (
      <div className="linkContainer">
        <a
          href="#JavaScript:;"
          className="linkName"
          onClick={() => {
            const fileList = [{
              fileID: rowColumn.fileId,
              fileName: rowColumn.value,
            }]
            downloadFileByUrl(fileList);
          }}
        >
          <EllipsisToolTip
            children={rowColumn.value}
            title={rowColumn.value}
            placement="top"
          />
        </a>
      </div>
    );
  };

  returnFileName = (rowColumn, column, row) => {
    const fileId = row.data.find((item) => item.name === "fileId").value;
    const fileKey = row.data
      .find((item) => item.name === "fileKey")
      .value.trim();
    return (
      <div className="fileNameContainer">
        <EllipsisToolTip
          children={rowColumn.value}
          title={rowColumn.value}
          placement="top"
        />
        {this.props.isShowHistoryVersion && (
          <HistoricalVersionComponent
            fileId={fileId}
            fileKey={fileKey}
            flowBusinessId={flowBusinessIdMap.procedureFile}
          />
        )}
      </div>
    );
  };

  returnInput = (
    rowColumn,
    column,
    rowIndex,
    procedureNoteMaxLength,
    fileSectionDisabled
  ) => {
    return (
      <>
        <ExpendTextAreaByProps
          value={rowColumn.value}
          onChange={(ele) => this.props.inputOnChange(ele.target)}
          maxLength={procedureNoteMaxLength}
          disabled={fileSectionDisabled || column.isDisabled}
          dataname={column.dataName}
          rowindex={rowIndex}
          style={{
            display: column.isShow ? "table-cell" : "none",
          }}
          ref={column.ref}
          readOnly={rowColumn.readOnly}
          handleTableChange={this.props.handleChangeTableHeight}
          isExpendData={this.props.isExpendData}
          setIsExpendNote={this.props.setIsExpendNote}
        />
      </>
    );
  };

  returnSelect = (rowColumn, column, row) => {
    return (
      <>
        <NormalSelect
          optionList={rowColumn.optionList}
          disabled={rowColumn.isDisabled || column.isDisabled || row.isReturned}
          value={rowColumn.value}
          handleChange={(e) =>
            column.onChange(row.data.find((x) => x.name === "lineId").value, e)
          }
          getPopupContainer="null"
        />
      </>
    );
  };

  returnButton = (row, column, rowIndex, isSupportingFile, status) => {
    const fileNameData = row.data.find((item) => item.name === "fileName");
    const tagValue = fileNameData && fileNameData.tagValue;

    return (
      <div className="buttonTd-content">
        {!this.isHideReplaceBtn &&
          !(tagValue === fileTags.deleted && status.value === null) && (
            <>
              <Buttons
                color={column.approveColor}
                size="small"
                onClick={(e) => column.onApproveClick(e)}
                disabled={row.isApproveDisabled}
                rowindex={rowIndex}
              >
                {column.approveText}
              </Buttons>
              <UploadFile
                fileInputRef={row.replaceRef}
                isMultiple={false}
                handleFileSelect={this.props.handleFileSelect}
                rowindex={rowIndex}
                isSupportingFile={isSupportingFile}
              />
            </>
          )}
        {!this.isHideDeleteBtn &&
          !(tagValue === fileTags.deleted && status.value === null) && (
            <Buttons
              color={column.returnColor}
              size="small"
              onClick={column.onReturnClick(rowIndex)}
              disabled={row.isReturnDisabled || tagValue === fileTags.deleted}
              rowindex={rowIndex}
            >
              {column.returnText}
            </Buttons>
          )}
      </div>
    );
  };

  returnTag = (column, rowColumn, tagValue) => {
    return (
      column.dataName === "status" && (
        <>
          <SectionStatusDiv status={rowColumn.value} tagValue={tagValue} />
        </>
      )
    );
  };

  judgeIsShowText = (column) => {
    return (
      column.type !== "link" &&
      column.type !== "input" &&
      column.type !== "select" &&
      column.type !== "tag" &&
      column.type !== "button" &&
      column.type !== "fileName"
    );
  };

  returnTrErrorClass = (row) => (row.isError ? "red" : "normal");

  columnShowStyle = (column) => (column.isShow ? "table-cell" : "none");

  render() {
    const {
      data,
      columns,
      procedureNoteMaxLength,
      fileSectionDisabled,
      hasSubmit,
      isSupportingFile,
    } = this.props;

    const typeToClassName = {
      link: "linkTd",
      input: "inputTd",
      default: "textTd",
      tag: "tagTd",
      select: "selectTd",
      button: "buttonTd",
      fileName: "fileNameTd",
    };

    return (
      <>
        <tbody>
          {data.map((row, rowIndex) => {
            const status = row.data.find((item) => item.name === "status");
            return (
              <tr
                key={row.data.find((item) => item.name === "lineId").value}
                className={`table-tr ${this.returnTrErrorClass(row)}`}
                id={`table-tr-${rowIndex}`}
              >
                {columns.map((column, columnIndex) => {
                  if (!column.isShow) {
                    return;
                  }
                  const rowColumn = row.data.find(
                    (x) => x.name === column.dataName
                  );
                  const tagValue = row.data.find(
                    (x) => x.name === "fileName"
                  ).tagValue;
                  if (column.type === "checkbox") {
                    return this.returnCheckBox(
                      column,
                      row,
                      hasSubmit,
                      fileSectionDisabled
                    );
                  } else {
                    return (
                      <td
                        key={column.dataName}
                        style={{
                          display: this.columnShowStyle(column),
                        }}
                        className={
                          typeToClassName[column.type] ||
                          typeToClassName.default
                        }
                      >
                        {column.type === "link" &&
                          this.returnLink(rowColumn, isSupportingFile)}
                        {column.type === "fileName" &&
                          this.returnFileName(rowColumn, column, row)}
                        {column.type === "input" &&
                          this.returnInput(
                            rowColumn,
                            column,
                            rowIndex,
                            procedureNoteMaxLength,
                            fileSectionDisabled
                          )}
                        {column.type === "select" &&
                          this.returnSelect(rowColumn, column, row, rowIndex)}
                        {column.type === "button" &&
                          this.returnButton(
                            row,
                            column,
                            rowIndex,
                            isSupportingFile,
                            status
                          )}
                        {column.type === "tag" &&
                          this.returnTag(column, rowColumn, tagValue)}
                        {this.judgeIsShowText(column) && rowColumn.value}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}

export default BaseTbody;
