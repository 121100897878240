import { createRef } from "react";
import {
  dataStatus,
  fileTags,
  actions,
  processDefaultTableSortFile,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import ProcessBase from "../../commonComponents/processBase/index.jsx";
import createSupportingFileService from "../../../service/supportingFile/createSupportingFileService";

const procedureReplaceFileRef = createRef();

export default class SupportingFileProcess extends ProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initProcedureFileState();
    this.supportingFileStepID = null;
    this.isSubmitComplete = undefined;
    this.isApprovePending = undefined;
    this.isApproveComplete = undefined;
    this.isRevisePending = undefined;
    this.isReviseComplete = undefined;
  }

  /**
   * Set Component Global Variables
   */
  setGlobalVariables = () => {
    this.initSavedInSectionState = {
      savedInRadioValue: null,
    };

    this.initServerPathSectionState = {
      serverPathValue: null,
    };

    this.primitiveServerPathSectionState = JSON.parse(
      JSON.stringify(this.initServerPathSectionState)
    );

    this.initProcedureFileTableState = [];

    this.initSupportingTableData = [];

    this.initSupportingTableState = {
      returnedList: [],
      fileTableJbStatus: null,
      fileTableReturned: false,
      fileTableReturnReasonValue: null,
      fileTableReasonStatus: null,
      attachment: {},
    };
  };

  /**
   * Init Component State
   */
  initProcedureFileState = () => {
    this.state = {
      pageState: {
        flowCategoryID: null,
        requestByID: null,
        requestByName: null,
        receiveDate: null,
        procedureID: null,
        procedureStepID: null,
        procedureStatus: null,
        isSaveDisabled: false,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        procedureFolder: {},
        rejectReason: null,
        rejectStatus: null,
        currentRole: null,
        actionName: null,
        loading: false,
        procedureStepStatus: null,
        uploadSupportingFileNum: 0,
        supportingFileNum: 0,
        procedureNote: "",
      },
      initTableDate: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: [],
      isReturnReasonLogOpen: false,
      fileTableData: [],
      supportingTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      supportingFileData: this.initSupportingTableState,
      shareTaskData: {},
      ppoList: [],
      isMount: false,
      jbProcedureSavedIn: null,
    };
  };

  /**
   * set saved in and client server path data in procedure file section
   * @param {*} result
   */
  setSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState: {
        savedInRadioValue:
          result.procedureFolder?.procedureSaveInCategoryID || null,
      },
      serverPathSectionState: {
        serverPathValue:
          result.procedureFolder !== null
            ? result.procedureFolder.clientServerPath
            : "",
      },
    });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  /* get supporting file return reason log */
  getFileReturnReasonLog = (folder, key) => {
    const returnReasonLog = [];
    const historyList = folder.stepHistoryList;
    historyList.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    historyList.forEach((stepItem, index) => {
      stepItem.actionID === actions.return &&
        stepItem.supportingFiles.forEach((fileItem, index) => {
          if (
            fileItem.fileKey === key &&
            fileItem.dataStatusID === dataStatus.return &&
            fileItem.returnReason !== null
          ) {
            returnReasonLog.push({
              key: index,
              returnedBy: stepItem.auditedByName,
              returnDate: this._formateDate(stepItem.modifiedDate),
              returnReasonColumn: fileItem.returnReason,
            });
          }
        });
    });
    return returnReasonLog;
  };

  /* function set approve and return button disabled when init load page */
  handleReturnInitBtnDisabled = (procedureFile, status) => {
    return this.isRevisePage
      ? false
      : this.handleDataNull(procedureFile, "dataStatusID") === status;
  };

  handleSortFile = (result) => {
    return processDefaultTableSortFile(
      result.supportingFolder.supportingFiles,
      this._formateDate
    );
  };

  /**
   * set supporting file table data
   * @param {*} folder
   */
  setSupportingTableData = (result) => {
    const supportingFileList = this.handleSortFile(result);
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    supportingFileList.forEach((supportingFile) => {
      const isReviseOrNoOldFile =
        this.handleDataNull(supportingFile, "oldFileID") === null || !isRevise;
      const isDeleteOrNoOldFile =
        this.handleDataNull(supportingFile, "oldFileID") === null ||
        this.handleDataNull(supportingFile, "fileTagID") === fileTags.deleted;
      if (!supportingFile.isDelete) {
        const lineId = this._generateGUID();
        const uploadedDate = this.handleDataNull(
          supportingFile,
          "uploadedDate"
        );
        const approvedDate = this.handleDataNull(
          supportingFile,
          "approvedDate"
        );
        this.supportingTableData.push({
          data: [
            { name: "lineId", value: lineId },
            {
              name: "fileId",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(supportingFile, "fileID")
                : this.handleDataNull(supportingFile, "oldFileID"),
            },
            {
              name: "supportingFileID",
              value: this.handleDataNull(supportingFile, "supportingFileID"),
            },
            {
              name: "supportingFileStepID",
              value: this.handleDataNull(
                supportingFile,
                "supportingFileStepID"
              ),
            },
            {
              name: "fileKey",
              value: this.handleDataNull(supportingFile, "fileKey"),
            },
            {
              name: "fileName",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(supportingFile, "fileName")
                : this.handleDataNull(supportingFile, "oldFileName"),
              tagValue: this.handleDataNull(supportingFile, "fileTagID"),
            },
            {
              name: "uploadedDate",
              value: this._formateDate(uploadedDate),
            },
            {
              name: "uploadByName",
              value: this.handleDataNull(supportingFile, "uploadByName"),
            },
            {
              name: "uploadById",
              value: this.handleDataNull(supportingFile, "uploadBy"),
            },
            {
              name: "approveDate",
              value: this._formateDate(approvedDate),
            },
            {
              name: "approveTime",
              value: approvedDate,
            },
            {
              name: "note",
              value: this.handleDataNull(supportingFile, "note"),
              isDisabled: false,
            },
            {
              name: "proposedChange",
              value: this.handleDataNull(supportingFile, "proposedChangeTagID"),
            },
            {
              name: "status",
              value: this.handleDataNull(supportingFile, "dataStatusID"),
            },
            {
              name: "returnReason",
              value: this.handleDataNull(supportingFile, "returnReason"),
              isDisabled:
                this.handleDataNull(supportingFile, "returnReason") === null,
              isShowReasonLog:
                this.getFileReturnReasonLog(
                  result.supportingFolder,
                  supportingFile.fileKey
                ).length > 0,
              reasonLog: this.getFileReturnReasonLog(
                result.supportingFolder,
                supportingFile.fileKey
              ),
            },
            {
              name: "attachment",
              value: this.handleDataNull(supportingFile, "attachmentName"),
              fileId: this.handleDataNull(supportingFile, "attachmentFileID"),
              link: "#JavaScript:;",
              isDisabled: false,
            },
            {
              name: "newVersion",
              value: isDeleteOrNoOldFile
                ? null
                : this.handleDataNull(supportingFile, "fileName"),
              fileId: isDeleteOrNoOldFile
                ? null
                : this.handleDataNull(supportingFile, "fileID"),
              link: "#JavaScript:;",
            },
            {
              name: "previousStatus",
              value: this.handleDataNull(supportingFile, "dataStatusID"),
            },
            {
              name: "previousFileTag",
              value: this.handleDataNull(supportingFile, "fileTagID"),
            },
            {
              name: "isReviewed",
              value: this.handleDataNull(supportingFile, "isReviewed"),
            },
          ],
          checked: false,
          isApproveDisabled: this.handleReturnInitBtnDisabled(
            supportingFile,
            dataStatus.approval
          ),
          isReturnDisabled: this.handleReturnInitBtnDisabled(
            supportingFile,
            dataStatus.return
          ),
          isReturned: this.handleReturnInitBtnDisabled(
            supportingFile,
            dataStatus.return
          ),
          isError: false,
          replaceRef: procedureReplaceFileRef,
          attachmentEditIcon: reasonEditDefaultIcon,
          isDelete: false,
          isUpdate: false,
        });
      }
    });
  };

  /**
   * set supporting file section data
   * @param {*} result
   */
  setSupportingFileData = (result) => {
    this.supportingFileStepID = result.supportingFolder.supportingFileStepID;
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: this.supportingTableData,
      isMount: true,
    });
    this.supportingTableData.forEach((item) => {
      this.initSupportingTableData.push({
        data: JSON.parse(JSON.stringify(item.data)),
        checked: item.checked,
        isApproveDisabled: item.isApproveDisabled,
        isReturnDisabled: item.isReturnDisabled,
        isReturned: item.isReturned,
        isError: item.isError,
        replaceRef: item.replaceRef,
        attachmentEditIcon: reasonEditDefaultIcon,
        isDelete: false,
        isUpdate: false,
      });
    });
  };

  /**
   * set procedure file section data
   * @param {*} result
   */
  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setSavedInServerPathState(result);
      this.setState({
        fileTableData: this.procedureTableData,
        jbProcedureSavedIn: result.procedureFolder?.jbProcedureSaveIn || null,
      });
    }
  };

  componentDidMount() {
    this.getInitData(
      createSupportingFileService.getCreateSupportingFileStepItem,
      "Get Supporting Files Failed"
    );
  }

  updateSupportingFileNum = async (num1, num2) => {
    await new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          pageState: {
            ...prevState.pageState,
            loading: true,
            supportingFileNum: num1,
            uploadSupportingFileNum: num2,
          },
        }),
        resolve
      );
    });
  };

  /* function about get data from procedure file table */
  returnFileDataValue = (name, index, ref) => {
    const procedureFileData = ref.current.getDataList();
    let deleteList = ref.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  /* function about return submit data to use in integrate trigger api */
  handleReturnSubmitData = (
    actionID,
    actionName,
    pageProcedureRef,
    approvePageRef
  ) => {
    const fileDtos = [];
    if (pageProcedureRef.current !== null) {
      const procedureFileData = pageProcedureRef.current.getDataList();
      let deleteList = pageProcedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) => {
        const replaceUserId = this.returnFileDataValue(
          "replaceUserId",
          index,
          pageProcedureRef
        )?.value;
        const replaceUserName = this.returnFileDataValue(
          "replaceUserName",
          index,
          pageProcedureRef
        )?.value;
        fileDtos.push({
          fileID:
            this.returnFileDataValue("newVersion", index, pageProcedureRef)
              .fileId === null
              ? this.returnFileDataValue("fileId", index, pageProcedureRef)
                  .value
              : this.returnFileDataValue("newVersion", index, pageProcedureRef)
                  .fileId,
          createdDate: this.returnFileDataValue(
            "uploadedDate",
            index,
            pageProcedureRef
          ).value,
          uploadBy:
            replaceUserId && replaceUserName
              ? replaceUserId
              : this.returnFileDataValue("uploadById", index, pageProcedureRef)
                  .value,
          uploadByName:
            replaceUserId && replaceUserName
              ? replaceUserName
              : this.returnFileDataValue(
                  "uploadByName",
                  index,
                  pageProcedureRef
                ).value,
          fileName:
            this.returnFileDataValue("newVersion", index, pageProcedureRef)
              .value === null
              ? this.returnFileDataValue("fileName", index, pageProcedureRef)
                  .value
              : this.returnFileDataValue("newVersion", index, pageProcedureRef)
                  .value,
          fileKey: this.returnFileDataValue("fileKey", index, pageProcedureRef)
            .value,
          dataStatusID: this.returnFileDataValue(
            "status",
            index,
            pageProcedureRef
          ).value,
          attachmentFileID: this.returnFileDataValue(
            "attachment",
            index,
            pageProcedureRef
          ).fileId,
          attachmentName: this.returnFileDataValue(
            "attachment",
            index,
            pageProcedureRef
          ).value,
          returnReason: this.returnFileDataValue(
            "returnReason",
            index,
            pageProcedureRef
          ).value,
          fileTagID: this.returnFileDataValue(
            "fileName",
            index,
            pageProcedureRef
          ).tagValue,
          supportingFileStepID: this.returnFileDataValue(
            "supportingFileStepID",
            index,
            pageProcedureRef
          ).value,
          supportingFileID: this.returnFileDataValue(
            "supportingFileID",
            index,
            pageProcedureRef
          ).value,
          note: this.returnFileDataValue("note", index, pageProcedureRef).value,
          proposedChangeTagID:
            this.returnFileDataValue(
              "proposedChange",
              index,
              pageProcedureRef
            ) === undefined
              ? null
              : this.returnFileDataValue(
                  "proposedChange",
                  index,
                  pageProcedureRef
                ).value,
          oldFileID:
            this.returnFileDataValue("newVersion", index, pageProcedureRef)
              .fileId === null
              ? null
              : this.returnFileDataValue("fileId", index, pageProcedureRef)
                  .value,
          oldFileName:
            this.returnFileDataValue("newVersion", index, pageProcedureRef)
              .value === null
              ? null
              : this.returnFileDataValue("fileName", index, pageProcedureRef)
                  .value,
          isReviewed:
            this.returnFileDataValue("isReviewed", index, pageProcedureRef)
              .value === undefined
              ? null
              : this.returnFileDataValue("isReviewed", index, pageProcedureRef)
                  .value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        });
      });
    }
    return {
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      flowCategoryID: this.flowCategoryID,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      rejectReason:
        approvePageRef === undefined || approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      isInitial: this.isRevisePage,
      supportingFiles: fileDtos,
      createSupportingFileStepID: this.supportingFileStepID,
    };
  };
}
