import { Select, Input, Progress } from "antd";
import BaseComponent from "../../../../../base/common/BaseComponent";
import Buttons from "../../../../../base/basicComponents/button";
import searchIcon from "../../../../../assets/image/search.png";
import exportIcon from "../../../../../assets/image/export.png";
import "./searchAndColumnSelect.scss";

class SearchAndColumnSelect extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnSelectValue: this.props.columnSelectValue,
    };
  }

  handleColumnSearchSelect = (valueArr) => {
    const newColumnSelectValue = JSON.parse(
      JSON.stringify(this.state.columnSelectValue)
    );
    for (let value of valueArr) {
      if (newColumnSelectValue.find((x) => x === value) !== undefined) {
        continue;
      }
      newColumnSelectValue.push(value);
    }

    this.setState({
      columnSelectValue: newColumnSelectValue,
    });

    this.props.handleColumnSearchSelect(valueArr);
  };

  handleColumnSearchDeSelect = (value) => {
    const newColumnSelectValue = this.state.columnSelectValue;
    const index = newColumnSelectValue.indexOf(value);
    if (index > -1) {
      newColumnSelectValue.splice(index, 1);
    }
    this.setState({
      columnSelectValue: newColumnSelectValue,
    });
    this.props.handleColumnSearchDeSelect(value);
  };

  getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  componentDidUpdate(preProps) {
    if (this.props.tab !== preProps.tab) {
      this.setState({
        columnSelectValue: this.props.columnSelectValue,
      });
    }
  }

  render() {
    const { columnSelectValue } = this.state;
    const {
      transactionId,
      taskLabelName,
      taskId,
      handleSearch,
      handleExport,
      handleTaskLabelNameChange,
      handleTaskIdChange,
      handleTransactionIdChange,
      columnSelectOption,
      showExport,
      exportDisabled,
      progress,
      startAnimation,
    } = this.props;

    return (
      <div className="topLine">
        <div className="topLineLeft">
          <Select
            options={columnSelectOption}
            placeholder="0 Column(s) Selected"
            onSelect={(value) => this.handleColumnSearchSelect([value])}
            onDeselect={this.handleColumnSearchDeSelect}
            mode="multiple"
            showArrow
            maxTagCount={0}
            value={columnSelectValue}
            maxTagPlaceholder={columnSelectValue.length + " Column(s) Selected"}
            getPopupContainer={this.getPopupContainer}
          />
          {showExport && (
            <div style={{ position: "relative" }}>
              <Buttons
                color="white-grey-line"
                size="small"
                onClick={handleExport}
                disabled={exportDisabled}
              >
                <div className="export" id="exportButton">
                  {exportDisabled ? (
                    <Progress
                      type="circle"
                      percent={progress}
                      width={26}
                      className={`progress-fly ${
                        startAnimation ? "start" : ""
                      }`}
                    />
                  ) : (
                    <img alt="" src={exportIcon} />
                  )}
                  {exportDisabled ? (
                    <span className="exportText">Exporting</span>
                  ) : (
                    <span className="exportText">Export</span>
                  )}
                </div>
              </Buttons>
              {/* {progress > 0 && (
                <Progress
                  type="circle"
                  percent={progress}
                  width={40}
                  className={`progress-fly ${startAnimation ? "start" : ""}`}
                />
              )} */}
            </div>
          )}
        </div>
        <div className="topLineRight">
          {transactionId !== undefined && (
            <Input
              placeholder="PMP Transaction ID"
              value={transactionId}
              onChange={(e) => {
                handleTransactionIdChange(e.target);
              }}
              onPressEnter={handleSearch}
            />
          )}
          <Input
            placeholder="Task Label Name"
            value={taskLabelName}
            onChange={(e) => {
              handleTaskLabelNameChange(e.target);
            }}
            onPressEnter={handleSearch}
          />
          <Input
            placeholder="Task ID"
            value={taskId}
            onChange={(e) => {
              handleTaskIdChange(e.target);
            }}
            onPressEnter={handleSearch}
          />
          <Buttons color="blue" size="small" onClick={handleSearch}>
            <div className="export">
              <img alt="" src={searchIcon} />
              <span>Search</span>
            </div>
          </Buttons>
        </div>
      </div>
    );
  }
}

export default SearchAndColumnSelect;
