import React, { createRef } from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import styles from "../../style/index.module.scss";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const supportingFileRef = createRef();
const supportingFileTableRef = createRef();
export default class BaseProcedureComplete extends BaseComponent {
  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    // this is an empty function
  };

  handleReturnRejectReason = () => {
    // this.is an empty function
  };

  handleReturnRevisionInformation = () => {
    // this.is an empty function
  };

  handleReturnPageName = () => {
    // this is an empty function
  };

  render() {
    const { procedureStatus, receiveDate, requestByName } =
      this.props.pageProps;
    const { taskInfoData, procedureInfoData, ppoList } =
      this.props.sectionProps;
    const params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };

    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title={this.handleReturnPageName()}
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Complete"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
        />
        {this.handleReturnRevisionInformation()}
        <ProcedureBlock
          className={styles.approveProcedureFile}
          child={this.handleReturnProcedureFileSection()}
          isShowArrow={true}
          isOpen={true}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ReadOnlySupportingFileSection
              supportingFileData={this.props.supportingTableData}
              supportingFileRef={supportingFileRef}
              supportingFileTableRef={supportingFileTableRef}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setSupportingFileData,
              this.setError,
              [StepBusinessType.supportingFile]
            );
          }}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ProcedureInformation
              isShowActions={false}
              noteDisabled={true}
              ppoList={ppoList}
              procedureStatus={procedureStatus}
              isPPODisabled={true}
              submitData={procedureInfoData}
              title="Procedure Information"
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setProcedureInfoData,
              this.setError,
              [
                StepBusinessType.procedureOwner,
                StepBusinessType.pmpExcluded,
                StepBusinessType.primaryProcedureOwner,
              ]
            );
          }}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly
              shareTaskData={this.props.shareTaskData}
            />
          }
          isShowArrow={true}
          onShowDataLoad={() => {
            return StepService.getStepData(
              params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        {this.handleReturnRejectReason()}
      </div>
    );
  }
}
