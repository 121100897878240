import { createRef } from "react";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import DetailRevisionInformation from "../../../../sections/procedureFileSections/fields/revisionInformationFields/detailRevisionInformation";
import UpdateProcedureFileReviseSection from "../../../../sections/procedureFileSections/updateProcedureFileReviseSection";
import BaseProcedureComplete from "../../baseProcedure/baseProcedureComplete";
import styles from "../../style/index.module.scss";

const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ReviseUpdateProcedureComplete extends BaseProcedureComplete {
  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, isDisableAll, procedureReplaceFileRef } =
      this.props.pageProps;
    return (
      <UpdateProcedureFileReviseSection
        sectionProps={{ currentRole, isDisableAll }}
        refs={{
          supportingRef: this.props.supportingRef,
          procedureFileTableRef,
          reasonRef,
          noteRef,
          procedureReplaceFileRef,
        }}
        data={this.props.data}
        isMount={this.props.isMount}
        savedInProps={this.props.savedInProps}
        serverPathProps={this.props.serverPathProps}
        initSavedInSectionState={this.props.initSavedInSectionState}
        initServerPathSectionState={this.props.initServerPathSectionState}
        initProcedureTableData={this.props.initProcedureTableData}
        isDisableAll={true}
      />
    );
  };

  /**
   * function about return revision information section
   */
  handleReturnRevisionInformation = () => {
    return (
      <ProcedureBlock
        className={styles.approveProcedureBlock}
        child={
          <DetailRevisionInformation
            title="Revision Information"
            updateFileNumLoading={this.props.updateFileNumLoading}
            openLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            accountId={this.props.pageProps.procedureAccountID}
            ref={this.props.revisionInfoRef}
            isDisabledAll={true}
            revisionInfoData={this.props.revisionInfoData}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  /**
   * function about return page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Revise Update Procedure";
  };
}
