import { Checkbox } from "antd";
import BaseComponent from "../../../../base/common/BaseComponent";
import "../index.scss";

class BaseThead extends BaseComponent {
  render() {
    const { columns, hasSubmit, fileSectionDisabled } = this.props;

    return (
      <thead className="table-head">
        <tr>
          {columns.map((column, index) => {
            if (!column.isShow) {
              return;
            }
            return column.dataName === "action" ? (
              <th
                key={crypto.randomUUID()}
                style={{ textAlign: "center" }}
                className="actionTh"
              >
                {column.name}
              </th>
            ) : (
              <th key={crypto.randomUUID()}>
                {column.type === "checkbox" ? (
                  <Checkbox
                    checked={column.headCheck()}
                    onChange={(ele) => column.headOnCheck(ele.target)}
                    disabled={hasSubmit || fileSectionDisabled}
                  />
                ) : (
                  column.name
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default BaseThead;
