import React, { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import TaskInformation from "../../../sections/taskInfoSections";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import styles from "../index.module.scss";
import ReadOnlyProcedureInformation from "../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import ProcessBase from "../../../commonComponents/processBase";
import NormalSpin from "../../../../base/basicComponents/spin";
import StepService from "../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const procedureInfoRef = createRef();
export default class AssignPPOCompleted extends ProcessBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageHeadState: {
        receiveDate: null,
        requestByName: null,
        procedureStepStatus: null,
      },
      procedureFileData: {
        procedureTableData: [],
        clientServerPath: null,
        savedInRadioValue: null,
      },
      supportingFileData: [],
      procedureInfoState: {
        procedureData: null,
        procedureInfoData: {},
        ppoList: [],
      },
      taskInformationData: [],
      shareTask: {
        mainTaskAccountId: null,
        procedureProcessID: null,
        reason: null,
        returnReasonLogs: null,
        shareTaskAccountId: null,
        shareTaskProcedureStepID: null,
        shareTasks: [],
        submitReasonLogs: null,
      },
      jbProcedureSavedIn: null,
    };
    this.taskID = this._getRoutingParam("ProcedureTaskId");
    this.procedureID = this._getRoutingParam("ProcedureID");
    this.procedureStepID = this._getRoutingParam("ProcedureStepID");
  }

  _showLoading = () => {
    this.setState({ loading: true });
  };

  _hideLoading = () => {
    this.setState({ loading: false });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setState({
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder !== null
              ? result.procedureFolder.procedureSaveInCategoryID
              : null,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
    }
  };

  setShareTaskData = (result) => {
    if (result.shareTask === null || result.shareTask === undefined) {
      return;
    }

    this.setState({
      shareTask: result.shareTask,
    });
  };

  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingFileData: this.supportingTableData,
      });
    }
  };

  /* callback about get task information successed */
  _getTaskInfoSuccess = (result) => {
    const data = result;
    this.setState({
      taskInformationData: data,
    });
  };

  /* callback about get task information failed */
  _getTaskInfoFailed = (result) => {
    // this._alertError("Get Task Information Failed");
    console.log(result);
  };

  /* function about get task information section data */
  getTaskInfo = () => {
    ProcedureDetailService.getTaskInfo(
      this.taskID,
      this._getTaskInfoSuccess,
      this._getTaskInfoFailed
    );
  };

  _getPrimaryProcedureOwnerStepItemSuccess = (result) => {
    const {
      reviceDate,
      requestByName,
      primaryProcedureOwner,
      procedureOwner,
      procedureNote,
      shareTask,
      applyPmpExcluded,
      procedureFolder,
      procedureStatusID,
    } = result;
    this.setProcedureTableData(procedureFolder);
    this.setSupportingTableData(result);
    this.setState({
      pageHeadState: {
        receiveDate: this._formateDate(reviceDate),
        requestByName,
      },
      procedureInfoState: {
        ...this.state.procedureInfoState,
        procedureData: {
          primaryProcedureOwner: {
            primaryProcedureOwnerID:
              primaryProcedureOwner?.oldPrimaryProcedureOwnerID,
            primaryProcedureOwnerName:
              primaryProcedureOwner?.oldPrimaryProcedureOwnerName,
          },
          procedureOwner: procedureOwner,
          procedureNote,
        },
        procedureInfoData: {
          isExcluded: applyPmpExcluded?.isExcluded ? "Yes" : "No",
          procedureStatus: procedureStatusID,
          procedureNote,
        },
      },
      shareTask,
      procedureFileData: {
        savedInRadioValue:
          result.procedureFolder !== null
            ? result.procedureFolder.procedureSaveInCategoryID
            : null,
        clientServerPath:
          result.procedureFolder !== null
            ? result.procedureFolder.clientServerPath
            : "",
        procedureTableData: this.procedureTableData,
      },
      supportingFileData: this.supportingTableData,
      jbProcedureSavedIn:
        result.procedureFolder === null
          ? null
          : result.procedureFolder.jbProcedureSaveIn,
    });

    this._hideLoading();
  };

  _getPrimaryProcedureOwnerStepItemFailed = () => {
    this._alertError("Get Primary Procedure Owner Step Item Failed");
  };

  getPrimaryProcedureOwnerStepItem = () => {
    const parentPageData = this._getRoutingParam("ParentPage");
    const param = {
      taskID: this.taskID,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: parentPageData,
    };

    ApproveChangePPOService.getPrimaryProcedureOwnerStepItem(
      param,
      this._getPrimaryProcedureOwnerStepItemSuccess,
      this._getPrimaryProcedureOwnerStepItemFailed
    );
  };

  _getPrimaryProcedureOwnerDDLSuccess = (result) => {
    this.setState({
      procedureInfoState: {
        ...this.state.procedureInfoState,
        ppoList: result,
      },
    });
  };

  _getPrimaryProcedureOwnerDDLFailed = () => {
    this._alertError("Get Primary Procedure Owner Failed");
  };

  getPrimaryProcedureOwnerDDL = () => {
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.taskID,
      this._getPrimaryProcedureOwnerDDLSuccess,
      this._getPrimaryProcedureOwnerDDLFailed
    );
  };

  componentDidMount() {
    this._showLoading();
    this.getPrimaryProcedureOwnerStepItem();
    this.getTaskInfo();
    this.getPrimaryProcedureOwnerDDL();
  }

  render() {
    const { receiveDate, requestByName } = this.state.pageHeadState;
    const { procedureData, procedureInfoData, ppoList } =
      this.state.procedureInfoState;
    const { loading, taskInformationData, shareTask, jbProcedureSavedIn } =
      this.state;
    const { procedureTableData, clientServerPath, savedInRadioValue } =
      this.state.procedureFileData;
    const params = {
      TaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    return (
      <NormalSpin spinning={loading} size="large" zIndex={1500}>
        <div className={styles.body}>
          <div className={styles.header}>
            <PageHeader
              title="Change Primary Procedure Owner"
              receiveDate={this._formateDate(receiveDate)}
              requestBy={requestByName}
              status="Complete"
            />
          </div>
          <ProcedureBlock
            className={styles.approveProcedureBlock}
            child={<TaskInformation data={taskInformationData} />}
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setTaskInfoData,
                this.setError,
                [StepBusinessType.taskInfo]
              );
            }}
          />
          <ProcedureBlock
            child={
              <ReadOnlyProcedureFileSection
                procedureFileData={procedureTableData}
                procedureFileRef={procedureFileRef}
                clientServerPath={clientServerPath}
                savedInRadioValue={savedInRadioValue}
                jbProcedureSavedIn={jbProcedureSavedIn}
              />
            }
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setProcedureFileData,
                this.setError,
                [StepBusinessType.procedureFile]
              );
            }}
          />
          <ProcedureBlock
            child={
              <ReadOnlySupportingFileSection
                supportingFileRef={supportingFileRef}
                supportingFileData={this.state.supportingFileData}
              />
            }
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setSupportingFileData,
                this.setError,
                [StepBusinessType.supportingFile]
              );
            }}
          />
          <ProcedureBlock
            className={styles.ProcedureInfoSection}
            child={
              <ReadOnlyProcedureInformation
                ref={procedureInfoRef}
                isShowActions={false}
                procedureData={procedureData}
                submitData={procedureInfoData}
                noteDisabled={true}
                isPPODisabled={true}
                title="Procedure Information"
                ppoList={ppoList}
              />
            }
            isShowArrow={true}
            isOpen={true}
          />
          <ProcedureBlock
            className={styles.approveProcedureBlock}
            child={<ActiveShareTaskReadOnly shareTaskData={shareTask} />}
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setShareTaskData,
                this.setError,
                [StepBusinessType.shareTask]
              );
            }}
          />
        </div>
      </NormalSpin>
    );
  }
}
