import React from "react";
import { Modal, Input, Select, Progress } from "antd";
import Buttons from "../../../../base/basicComponents/button/index";
import styles from "./index.module.scss";
// import "../applyJb.scss";
import BaseProcedureSavedIn from "../../../commonComponents/savedInFields/baseSavedInField";
import BaseClientServerPath from "../../../commonComponents/serverPathFields/baseServerPathField";
import {
  procedureJbStatus,
  savedInRadioValues,
  procedureJbStatusMap,
  detailTableSortFile,
} from "../../../publicDictionaryValues";
import downloadIcon from "../../../../assets/image/downloadIcon.png";
import DetailFileTable from "../../procedureFileSections/fields/procedureFileTableFields/detailFileTable";
import { createRef } from "react";
import "./applyJb.scss";
import controlPageBehavior from "../../../../base/basicComponents/modal/controlPageBehavior";
import BaseApplyForJbSection from "../baseApplyForJbSection";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import NormalSpin from "../../../../base/basicComponents/spin";

class ApplyForJbModal extends BaseApplyForJbSection {
  constructor(props) {
    super(props);
    this.state = {
      applyForValue: "",
      submitDisabled: true,
      downloadBtnDisabled: true,
      downloadIcon: downloadIcon,
      clientPath: "",
      savedInRadioValue: "",
      JbProcedureSaveIn: "",
      loading: false,
      downloadNum: 0,
      downloadLoading: false,
      progress: 1,
    };
    this.fileTableRef = createRef();
  }

  openDownloadLoading = () => {
    this.setState({
      downloadNum: 0,
      downloadLoading: true,
      progress: 1,
    })
  }

  downloadDetailFilesSuccessfully = (downloadList) => {
    const isDownloadDone = this.state.downloadNum + 1 === downloadList.length;
    const downloadProcess = (this.state.downloadNum + 1) * 100 / downloadList.length;

    if(isDownloadDone) {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: 100,
      })
      setTimeout(() => {
        this.setState({
          downloadLoading: false,
        })
      }, 1000);
    } else {
      this.setState({
        downloadNum: this.state.downloadNum + 1,
        progress: parseInt(downloadProcess),
      })
    }
  }

  setDefault = () => {
    this.setState({
      applyForValue: "",
      submitDisabled: true,
    });
  };

  checkSubmitDisabled = (dataList, applyForValue) => {
    // const dataList = this.getDataList();
    if (this.state.savedInRadioValue !== savedInRadioValues.systemOnly) {
    }
    const applyforNull =
      this.state.savedInRadioValue !== savedInRadioValues.serverOnly
        ? dataList.findIndex(
            (x) => x.data.find((y) => y.name === "applyfor").value !== ""
          )
        : -1;
    if (applyforNull === -1 && applyForValue === "") {
      this.setState({
        submitDisabled: true,
      });
    } else {
      this.setState({
        submitDisabled: false,
      });
    }
  };

  getApplyForJbDataList = (procedureFiles) => {
    const newFiles = detailTableSortFile(
      procedureFiles,
      this._formateDate
    );

    const dataList = [];
    newFiles.forEach((item) => {
        const lineId = this._generateGUID();
        dataList.push({
          data: [
            { name: "lineId", value: lineId },
            { name: "procedureFileID", value: item.procedureFileID },
            { name: "initFileId", value: item.fileId },
            { name: "fileId", value: item.fileId },
            { name: "initFileKey", value: item.fileKey },
            { name: "fileKey", value: item.fileKey },
            { name: "initFileName", value: item.fileName },
            {
              name: "fileName",
              value: item.fileName,
            },
            { name: "uploadedDate", value: this._formateDate(item.uploadedOn) },
            { name: "uploadByName", value: item.uploadedByName },
            { name: "uploadBy", value: item.uploadedBy },
            { name: "proposedChange", value: "" },
            {
              name: "approveDate",
              value: this._formateDate(item.approvedDate),
            },
            { name: "approveTime", value: item.approvedDate },
            { name: "fileOperate", value: 1 },
            { name: "jbProcedure", value: procedureJbStatus[item.jbProcedure] },
          ],
          checked: false,
        });
      });
    return this.handleApplyForJbDataList(dataList);
  };

  handleApplyForJbDataList = (dataList) => {
    return dataList.map((item) => {
      const jbProcedure = item.data.find((x) => x.name === "jbProcedure").value;
      const applyFor = {
        name: "applyfor",
        value: "",
        optionList: this.handleApplyForOption(procedureJbStatusMap[jbProcedure]),
      };
      item.data.push(applyFor);
      return item;
    });
  };

  updataDataList = (procedureFiles) => {
    const dataList = this.getApplyForJbDataList(procedureFiles);
    if (this.fileTableRef.current === null) {
      return;
    }
    this.fileTableRef.current.updateDataList(dataList);
  };

  getDataList = () => {
    if (this.fileTableRef.current === null) {
      return [];
    }
    const dataList = JSON.parse(
      JSON.stringify(this.fileTableRef.current.state.data)
    );

    return dataList;
  };

  handleJbProcedureSavedValue = (JbProcedureSaveIn) => {
    if (JbProcedureSaveIn === null) {
      return null;
    }

    return procedureJbStatus[JbProcedureSaveIn];
  };

  handleApplyForOption = (JbProcedureSaveIn) => {
    if (JbProcedureSaveIn === null) {
      return null;
    }

    const statusOption =
      procedureJbStatus[JbProcedureSaveIn] === "JB" ? "Non JB" : "JB";

    return [
      {
        value: "",
        label: "",
      },
      {
        value: statusOption,
        label: statusOption,
      },
    ];
  };

  applyForOnChang = (value) => {
    this.setState({
      applyForValue: value,
    });
    const dataList = this.getDataList();
    this.checkSubmitDisabled(dataList, value);
  };

  applyForOnChange = (lineId, value) => {
    const dataList =
      this.fileTableRef.current !== null &&
      this.fileTableRef.current.state.data;
    let currentRow = dataList.find(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );
    const currentRowIndex = dataList.findIndex(
      (x) => x.data.find((y) => y.name === "lineId").value === lineId
    );

    currentRow.data.find((x) => x.name === "applyfor").value = value;

    dataList[currentRowIndex] = currentRow;
    this.fileTableRef.current.updateDataList(dataList);
    this.checkSubmitDisabled(dataList, this.state.applyForValue);
  };

  _getProcedureFileSectionSuccess = (response) => {
    if(response) {
      this.updataDataList(response.procedureFiles || []);

      const radioValue = response.procedureSaveInCategoryID ?? savedInRadioValues.systemOnly;
      this.setState({
        loading: false,
        clientPath: response?.clientServerPath || "",
        savedInRadioValue: radioValue,
        JbProcedureSaveIn: response.jbProcedureSaveIn,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isModalOpen !== this.props.isModalOpen) {
      this.setDefault();
      if (this.props.isModalOpen) {
        this.setState({loading: true});
        ProcedureDetailService.getProcedureFileSection(
          { procedureId: this.props.procedureId },
          this._getProcedureFileSectionSuccess,
          () => this._alertError("GetProcedureFileSection")
        );

        const modalElement = document.getElementsByClassName("applyJbModal")[0];
        controlPageBehavior.addEleListener(modalElement);
        controlPageBehavior.resetListener(modalElement, () =>
          this.props.releaseJbStatusLock(this.props.refreshPage)
        );
      } else {
        controlPageBehavior.clearListener();
      }
    }
  }

  render() {
    const {
      isModalOpen,
      onSubmit,
      onCancel,
      zIndex,
    } = this.props;
    const { 
      applyForValue, 
      submitDisabled, 
      downloadBtnDisabled,
      savedInRadioValue,
      clientPath,
      JbProcedureSaveIn,
      loading,
      downloadLoading,
      progress,
    } = this.state;

    const jbModalData = { 
      applyForValue,
      savedInRadioValue, 
      clientPath, 
      JbProcedureSaveIn
    };

    const option = this.handleApplyForOption(JbProcedureSaveIn);

    const footer = (
      <div className={styles.buttonDiv}>
        <Buttons color="grey" size="middle" onClick={onCancel}>
          Cancel
        </Buttons>
        <Buttons
          color="blue"
          disabled={submitDisabled}
          size="middle"
          onClick={() => onSubmit(jbModalData, this.getDataList())}
        >
          Submit for Approval
        </Buttons>
      </div>
    );

    const columns = [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: false,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: true,
      },
      {
        name: "JB Procedure",
        dataName: "jbProcedure",
        type: "text",
        isShow: true,
      },
      {
        name: "Apply for?",
        dataName: "applyfor",
        type: "select",
        isShow: true,
        onChange: this.applyForOnChange,
      },
    ];

    return (
      <Modal
        // key={isModalOpen}
        title="Procedure Files"
        centered
        open={isModalOpen}
        footer={footer}
        closable={false}
        width="93.75rem"
        className="applyJbModal"
        onCancel={onCancel}
        maskClosable={false}
        zIndex={zIndex}
      >
        <NormalSpin
          spinning={loading}
          size="large"
        >
          <div className={styles.applyJbModalDiv}>
            <div className={styles.topDiv}>
              <div>
                <BaseProcedureSavedIn
                  // handleRadioChange={handleRadioChange}
                  savedInRadioValue={savedInRadioValue}
                  disabled={true}
                />
              </div>
              <div
                className={`${styles.clientPathDiv} ${
                  savedInRadioValue !== savedInRadioValues.systemOnly
                    ? ""
                    : "unvisible"
                }`}
              >
                <BaseClientServerPath
                  procedureSaveInValue={savedInRadioValue}
                  value={clientPath}
                  // clientPathOnChange={(e) => this.clientPathOnChange(e)}
                  disabled={true}
                  status=""
                  placeholder=""
                  showRequired={false}
                />
              </div>
            </div>
            {savedInRadioValue !== savedInRadioValues.systemOnly && (
              <div className={styles.secondDiv}>
                <div className={styles.jbFieldDiv}>
                  <div className={styles.jbProcedureDiv}>
                    <span>JB Procedure Saved</span>
                    <Input
                      value={this.handleJbProcedureSavedValue(JbProcedureSaveIn)}
                      disabled
                      className="jbProcedureInput"
                    />
                  </div>
                  <div className={styles.applyForDiv}>
                    <span>Apply for?</span>
                    <Select
                      // key={isModalOpen}
                      value={applyForValue}
                      options={option}
                      onChange={this.applyForOnChang}
                      className="applyForSelect"
                    />
                  </div>
                </div>
              </div>
            )}
            {savedInRadioValue !== savedInRadioValues.serverOnly && (
              <>
                <div className={styles.downloadBtDiv}>
                  <Buttons
                    color="black"
                    size="middle"
                    onMouseEnter={this.handleDownloadBtnMouseEnter}
                    onMouseLeave={this.handleDownloadBtnMouseLeave}
                    onClick={this.downloadFile}
                    disabled={downloadBtnDisabled || downloadLoading}
                  >
                    <div className={`${downloadLoading ? "downloadButton" : ""}`}>
                      {downloadLoading ? (
                        <Progress
                          type="circle"
                          percent={progress}
                          width={18}
                        />
                      ) : (
                        <img
                          src={this.state.downloadIcon}
                          alt="Download"
                          className={styles.downloadIcon}
                        />
                      )}
                      <span>Download</span>
                    </div>
                  </Buttons>
                </div>
                <DetailFileTable
                  ref={this.fileTableRef}
                  columns={columns}
                  procedureNoteMaxLength={1000}
                  fileSectionDisabled={false}
                  procedureNoteSize="small"
                  inputOnChange={this.inputOnChange}
                  hasSubmit={false}
                  isScrollData={true}
                  fileTableRef={this.fileTableRef}
                  className="procedureFiles"
                  isShowTotalFileNum={true}
                  isSupportingFile={false}
                  isShowExpendIcon={true}
                  expendClassName="ApplyForJBExpend"
                />
              </>
            )}
          </div>
        </NormalSpin>
      </Modal>
    );
  }
}

export default ApplyForJbModal;
