import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class ChangePOStepService extends StepBaseService {
  getProcedureOwnerStepKey = () => {
    return {
      dataKey: StepBusinessType.procedureOwner.dataKey,
      settingKey: "getProcedureOwnerStepItem",
    };
  };

  getPrimaryProcedureOwnerStepKey = () => {
    return StepBusinessType.primaryProcedureOwner;
  };

  getPMPExcludedStepKey = () => {
    return StepBusinessType.pmpExcluded;
  };
}

export default new ChangePOStepService();
