import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class TLReviewStepService extends StepBaseService {
  getExtensionKey = () => {
    return StepBusinessType.tLReview;
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };

  getProcedureFileStepKey = () => {
    return StepBusinessType.procedureFile;
  };
}
const service = new TLReviewStepService();
export default service;
