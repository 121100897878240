import { createRef } from "react";
import {
  deepClone,
  fileTags,
  getTimeZoneString,
  proposedChangeTags,
  savedInRadioValues,
} from "../../../../../publicDictionaryValues";
import ReviseUpdateSupportingFileManagement from "../../../../supportingFileSections/fields/supportingFileManagement/reviseUpdateSupportingFileManagement";
import reasonEditDefaultIcon from "../../../../../../assets/image/reasonEditIcon.png";

const procedureReplaceFileRef = createRef();
export default class ReviseUpdateProcedureFileManagement extends ReviseUpdateSupportingFileManagement {
  /**
   * function about update saved in value state
   * @param {string} value current saved in value
   * @param {string} prevValue last saved in value
   */
  updateRadioValue = (value, prevValue) => {
    if (
      (value === savedInRadioValues.serverOnly || value === null) &&
      prevValue !== savedInRadioValues.serverOnly &&
      prevValue !== null
    ) {
      const dataList = deepClone(this.getDataList());
      this.prevProcedureDataList = dataList;
    }
    this.setState({ savedInRadioValue: value });
  };

  /* function about show loading when upload file */
  handleUploadLoadiing = () => {
    this.uploadProcedureFileNum += 1;
    this.props.updateProcedureFileNum({
      currentNum: this.uploadProcedureFileNum,
      allNum: this.procedureFileNum,
    });
    if (this.uploadProcedureFileNum === this.procedureFileNum) {
      this.uploadProcedureFileNum = 0;
      this.procedureFileNum = 0;
      this.props.updateProcedureFileNum({
        currentNum: 0,
        allNum: 0,
      });
      this.props._hideLoading();
    }
  };

  handleChangeUploadTip = () => {
    this.props.updateProcedureFileNum({
      currentNum: this.uploadProcedureFileNum,
      allNum: this.procedureFileNum,
    });
  };

  /**
   * function about add data in table when upload files
   * @param {array} dataList
   * @param {string} fileId
   * @param {string} fileName
   */
  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    const newFileItme = {
      data: [
        { name: "lineId", value: lineId },
        { name: "fileId", value: fileId },
        { name: "procedureFileID", value: null },
        {
          name: "procedureFileStepID",
          value: null,
        },
        { name: "fileKey", value: fileKey },
        {
          name: "fileName",
          value: fileName,
          tagValue: fileTags.new,
        },
        {
          name: "uploadedDate",
          value: this._formateDate(getTimeZoneString(this._getDate())),
        },
        { name: "uploadByName", value: this.user.identityUserInfo.englishName },
        {
          name: "uploadById",
          value: this.user.identityUserInfo.internalUserID,
        },
        { name: "proposedChange", value: proposedChangeTags.new },
        { name: "status", value: null },
        {
          name: "returnReason",
          value: null,
          isDisabled: true,
          isShowReasonLog: false,
        },
        {
          name: "attachment",
          value: null,
          isDisabled: false,
          link: null,
          fileId: null,
        },
        { name: "newVersion", value: null, link: null, fileId: null },
        { name: "note", value: null },
        {
          name: "previousStatus",
          value: null,
        },
        {
          name: "previousFileTag",
          value: null,
        },
        {
          name: "isReviewed",
          value: false,
        },
      ],
      checked: false,
      isApproveDisabled: false,
      isReturnDisabled: false,
      isReturned: false,
      isError: false,
      replaceRef: procedureReplaceFileRef,
      attachmentEditIcon: reasonEditDefaultIcon,
      isDelete: false,
      isUpdate: false,
    };
    dataList.unshift(newFileItme);
  };
}
