import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";
import "../../../commonComponents/readOnlyTable/antReadOnlyTableCustom.scss";

export default class ReviseShareTaskReadOnly extends BaseReadOnlyShareTask {
  constructor(props) {
    super(props);
    this.state = this.initReadOnlyState(true);
  }

  handleReturnReason = () => {
    return this.handleReviseReturnReason();
  };

  handleSubmitReason = () => {
    return this.handleReviseSubmitReason();
  };
}
