import RoutingComponent from "../../../base/common/RoutingComponent";
import formateTaskInfoData from "../../sections/taskInfoSections/formateTaskInfoData";
import formateProcedureInfoData from "../../sections/procedureInfoSections/formateProcedureInfoData";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import {
  procedureJbStatus,
  procedureStatusValues,
  processDefaultTableSortFile,
} from "../../publicDictionaryValues";

export default class ProcessBase extends RoutingComponent {
  constructor(props) {
    super(props);
    this.user = this.appSetting.localStorageValue.user.get();
    this.supportingFileNum = 0;
    this.flowCategoryID = null;
    this.workflowProcessInstanceId = null;
    this.isRevisePage = false;
    this.isApprovePage = true;
    this.tiggerTimes = 0;
    this.taskID = this._getRoutingParam("ProcedureTaskId");
    this.procedureID = this._getRoutingParam("ProcedureID");
    this.procedureStepID = this._getRoutingParam("ProcedureStepID");
    this.actionID = null;
    this.initProcedureTableData = [];
    this.initSupportingTableData = [];
    this.procedureTableData = [];
    this.supportingTableData = [];
    this.isApprovePendingPage = undefined;
    this.isApproveCompletePage = undefined;
    this.isRevisePendingPage = undefined;
    this.isReviseCompletePage = undefined;
    this.isSubmitCompletePage = undefined;
  }

  /**
   * Set Component Global Variables
   */
  setGlobalVariables = () => {
    this.initSavedInSectionState = {
      savedInReturnReasonValue: null,
      savedInReturnReasonInputValue: null,
      savedInReasonStatus: null,
      procedureSaveInCategoryStatusID: null,
      savedInRadioValue: null,
      isSavedInReturned: false,
      isSavedInApproveBtnDisabled: false,
      isSavedInReturnBtnDisabled: false,
      isSavedInError: false,
    };

    this.primitiveSavedInSectionState = JSON.parse(
      JSON.stringify(this.initSavedInSectionState)
    );

    this.initServerPathSectionState = {
      serverPathValue: null,
      serverPathReturnReasonValue: null,
      serverPathReturnReasonInputValue: null,
      serverPathReasonStatus: null,
      clientServerPathStatusID: null,
      clientServerPathStatusName: null,
      clientServerPath: null,
      isServerPathReturned: false,
      isServerPathApproveBtnDisabled: false,
      isServerPathReturnBtnDisabled: false,
    };

    this.primitiveServerPathSectionState = JSON.parse(
      JSON.stringify(this.initServerPathSectionState)
    );

    this.initProcedureFileTableState = {
      returnedList: [],
      fileTableJbStatus: null,
      fileTableReturned: false,
      fileTableReturnReasonValue: null,
      fileTableReasonStatus: null,
      attachment: {},
    };
  };

  /* function about click action buttons to jeep list page */
  closePage = () => {
    window.location = "/ToDoList";
  };

  getProcedureStatus = (statusId) => {
    return procedureStatusValues[statusId];
  };

  setPageData = (result, fn) => {
    if (result.procedureID === null || result.procedureID === undefined) {
      return;
    }

    this.setState(
      {
        pageState: {
          ...this.state.pageState,
          requestByID: result.requestByID,
          requestByName: result.requestByName,
          receiveDate: result.reviceDate,
          procedureID: result.procedureID,
          procedureProcessId: result.processID,
          procedureStepID: result.procedureStepID,
          currentRole: result.workflowStepName,
          procedureStepStatus: result.procedureStepStatus,
          rejectReason: result.rejectReason,
          procedureStatus: this.getProcedureStatus(result.procedureStatusID),
          procedureStatusID: result.procedureStatusID,
          flowCategoryID: result.flowCategoryID,
          procedureAccountID: result.taskInfomation.procedureAccountID,
          procedureNote: result.procedureNote,
        },
      },
      fn
    );
  };

  setTaskInfoData = (result) => {
    if (result.taskInfomation === null || result.taskInfomation === undefined) {
      return;
    }

    this.setState({
      taskInfoData: formateTaskInfoData.setTaskInfoData(result),
    });
  };

  setProcedureInfoData = (result) => {
    if (
      result.procedureStatusID === null ||
      result.procedureStatusID === undefined
    ) {
      result.procedureStatusID = this.state.pageState.procedureStatusID;
    }

    if (
      result.procedureNote === null ||
      result.procedureNote === undefined ||
      result.procedureNote === ""
    ) {
      result.procedureNote = this.state.pageState.procedureNote;
    }

    this.setState({
      procedureInfoData: formateProcedureInfoData.setProcedreInfoData(result),
    });
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID" || key === "supportingFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  /**
   * set procedure file table data to a constant
   * @param {*} folder
   */
  setProcedureTableData = (folder) => {
    if (folder === null) {
      return;
    }
    const procedureFileList = processDefaultTableSortFile(
      folder.procedureFiles,
      this._formateDate
    );
    procedureFileList.forEach((procedureFile) => {
      const isReviseOrNoOldFile =
        this.handleDataNull(procedureFile, "oldFileID") === null ||
        !this.isRevisePage;
      const approvedDate = this.handleDataNull(procedureFile, "approvedDate");
      if (!procedureFile.isDelete) {
        const lineId = this._generateGUID();
        const uploadedDate = this.handleDataNull(procedureFile, "uploadedDate");
        this.procedureTableData.push({
          data: [
            { name: "lineId", value: lineId },
            {
              name: "fileId",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(procedureFile, "fileID")
                : this.handleDataNull(procedureFile, "oldFileID"),
            },
            {
              name: "procedureFileID",
              value: this.handleDataNull(procedureFile, "procedureFileID"),
            },
            {
              name: "procedureFileStepID",
              value: this.handleDataNull(procedureFile, "procedureFileStepID"),
            },
            {
              name: "fileKey",
              value: this.handleDataNull(procedureFile, "fileKey"),
            },
            {
              name: "fileName",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(procedureFile, "fileName")
                : this.handleDataNull(procedureFile, "oldFileName"),
            },
            {
              name: "uploadedDate",
              value: this._formateDate(uploadedDate),
            },
            {
              name: "uploadByName",
              value: this.handleDataNull(procedureFile, "uploadByName"),
            },
            {
              name: "uploadById",
              value: this.handleDataNull(procedureFile, "uploadBy"),
            },
            {
              name: "approveDate",
              value: this._formateDate(approvedDate),
            },
            {
              name: "jbProcedure",
              value:
                procedureJbStatus[
                  this.handleDataNull(procedureFile, "jbProcedure")
                ],
            },
          ],
          checked: false,
        });
      }
    });
  };

  /**
   * set supporting file table data to a constant
   * @param {*} folder
   */
  setSupportingTableData = (result) => {
    if (result.supportingFolder === null) {
      return;
    }
    const supportingFileList = processDefaultTableSortFile(
      result.supportingFolder.supportingFiles,
      this._formateDate
    );
    let isRevise = false;
    if (
      result.workflowProcessStepInfo !== undefined &&
      result.workflowProcessStepInfo !== null &&
      result.workflowProcessStepInfo.annotations.length !== 0
    ) {
      isRevise =
        result.workflowProcessStepInfo.annotations.find(
          (item) => (item.name = "IsRevise")
        ).value === "1";
    }
    supportingFileList.forEach((supportingFile) => {
      const isReviseOrNoOldFile =
        this.handleDataNull(supportingFile, "oldFileID") === null || !isRevise;
      if (!supportingFile.isDelete) {
        const lineId = this._generateGUID();
        const uploadedDate = this.handleDataNull(
          supportingFile,
          "uploadedDate"
        );
        const approvedDate = this.handleDataNull(
          supportingFile,
          "approvedDate"
        );
        this.supportingTableData.push({
          data: [
            { name: "lineId", value: lineId },
            {
              name: "fileId",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(supportingFile, "fileID")
                : this.handleDataNull(supportingFile, "oldFileID"),
            },
            {
              name: "supportingFileID",
              value: this.handleDataNull(supportingFile, "supportingFileID"),
            },
            {
              name: "supportingFileStepID",
              value: this.handleDataNull(
                supportingFile,
                "supportingFileStepID"
              ),
            },
            {
              name: "fileKey",
              value: this.handleDataNull(supportingFile, "fileKey"),
            },
            {
              name: "fileName",
              value: isReviseOrNoOldFile
                ? this.handleDataNull(supportingFile, "fileName")
                : this.handleDataNull(supportingFile, "oldFileName"),
              tagValue: this.handleDataNull(supportingFile, "fileTagID"),
            },
            {
              name: "uploadedDate",
              value: this._formateDate(uploadedDate),
            },
            {
              name: "uploadByName",
              value: this.handleDataNull(supportingFile, "uploadByName"),
            },
            {
              name: "uploadById",
              value: this.handleDataNull(supportingFile, "uploadBy"),
            },
            {
              name: "approveDate",
              value: this._formateDate(approvedDate),
            },
            {
              name: "approveTime",
              value: approvedDate,
            },
          ],
          checked: false,
        });
      }
    });
  };

  /**
   * set procedure file table data to state
   * @param {*} result
   */
  setProcedureFileData = (result) => {
    this.setProcedureTableData(result.procedureFolder);
  };

  /**
   * set supporting file table data to state
   * @param {*} result
   */
  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
  };

  setShareTaskData = (result) => {
    if (result.shareTask === null || result.shareTask === undefined) {
      return;
    }

    this.setState({
      shareTaskData: result.shareTask,
    });
  };

  setTlReviewData = (result) => {
    if (result.tlReview === null || result.tlReview === undefined) {
      return;
    }

    this.setState({
      tlReviewData: result.tlReview,
    });
  };

  setApplyJbData = (result) => {
    // this is an empty function
  };

  setInitState = (result, isRevise, fn) => {
    this.setPageData(result, fn);
    this.setTaskInfoData(result);
    this.setProcedureInfoData(result);
    this.setProcedureFileData(result);
    this.setSupportingFileData(result);
    this.setShareTaskData(result);
    this.setTlReviewData(result);
    this.setApplyJbData(result);
  };

  _getProcedureFolderSuccess = (result) => {
    if (result.statusCode === 401) {
      console.log(result.message);
    } else {
      let isRevise = false;
      let isApprove = true;

      if (
        result.workflowProcessStepInfo !== null &&
        result.workflowProcessStepInfo.length !== 0
      ) {
        this.tiggerTimes = result.sequence;
      }

      if (
        result.workflowProcessStepInfo !== null &&
        result.workflowProcessStepInfo.annotations.length !== 0
      ) {
        isRevise =
          result.workflowProcessStepInfo.annotations.find(
            (item) => (item.name = "IsRevise")
          ).value === "1";
        isApprove =
          result.workflowProcessStepInfo.annotations.find(
            (item) => (item.name = "IsRevise")
          ).value === "0";
      }
      if (result.procedureID !== null) {
        this.flowCategoryID = result.flowCategoryID;
        this.actionID = result.actionID;
        this.workflowProcessInstanceId = result.workflowProcessInstanceId;
        this.setInitState(result, isRevise, this._hideLoading);
        this.isRevisePage = isRevise;
        this.isApprovePage = isApprove;
        this.triggerTimes = result.sequence;
      } else {
        console.log(result.message);
        this._hideLoading();
      }
    }
  };

  _getProcedureFolderError = (error, msg) => {
    console.log(error);
    this._alertError(msg);
    this._hideLoading();
  };

  _getPrimaryProcedureOwnerDDLSuccess = (result) => {
    this.setState({
      ppoList: result,
    });
  };

  _getPrimaryProcedureOwnerDDLFailed = () => {
    this._alertError("Get Primary Procedure Owner Failed");
  };

  /* function about show loading */
  _showLoading = () => {
    this.setState({
      pageState: { ...this.state.pageState, loading: true },
    });
  };

  _hideLoading = () => {
    this.setState({
      pageState: { ...this.state.pageState, loading: false },
    });
  };

  updateProcedureFileNum = async (num1, num2) => {
    await new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          pageState: {
            ...prevState.pageState,
            loading: true,
            procedureFileNum: num1,
            uploadProcedureFileNum: num2,
          },
        }),
        resolve
      );
    });
  };

  getInitData = (initDataFun, msg) => {
    const parentPageData = this._getRoutingParam("ParentPage");
    const para = {
      taskID: this.taskID,
      procedureID: this.procedureID?.trim() === "" ? null : this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: parentPageData,
    };
    this._showLoading();
    initDataFun(para, this._getProcedureFolderSuccess, (error) =>
      this._getProcedureFolderError(error, msg)
    );

    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.taskID,
      this._getPrimaryProcedureOwnerDDLSuccess,
      this._getPrimaryProcedureOwnerDDLFailed
    );
  };
}
