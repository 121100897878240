import React, { createRef } from "react";
import ReadOnlyCreateProcedureFileSection from "../../../../sections/procedureFileSections/readOnlyCreateProcedureFileSection";
import BaseProcedureComplete from "../../baseProcedure/baseProcedureComplete";
import { flowCategoryId } from "../../../../publicDictionaryValues";

const procedureFileRef = createRef();

export default class SubmitNewProcedureComplete extends BaseProcedureComplete {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
    };
  }

  /**
   * function about return page name
   */
  handleReturnPageName = () => {
    if (this.props.pageProps.flowCategoryID === flowCategoryId.copyProcedure) {
      return "Copy Procedure";
    }

    return "Create New Procedure";
  };

  /**
   * function about return procedure file section
   */
  handleReturnProcedureFileSection = () => {
    const { serverPathValue, savedInRadioValue, fileTableData, isCopyPage } = this.props;
    return (
      <ReadOnlyCreateProcedureFileSection
        procedureFileRef={procedureFileRef}
        clientServerPath={serverPathValue}
        savedInRadioValue={savedInRadioValue}
        procedureFileData={fileTableData !== undefined ? fileTableData : []}
        isCopyPage={isCopyPage}
      />
    );
  };
}
