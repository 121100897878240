import React, { createRef } from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import ProcedureBlock from "../../../../../base/basicComponents/procedureBlock";
import PageHeader from "../../../../commonComponents/pageHeaders/PageHeader";
import TaskInformation from "../../../../sections/taskInfoSections";
import ProcedureInformation from "../../../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ReviseProcedureFileSection from "../../../../sections/procedureFileSections/reviseProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../../sections/supportingFileSections/readOnlySupportingFileSection";
import Buttons from "../../../../../base/basicComponents/button";
import { ModalWithComponent } from "../../../../../base/basicComponents/modal";
import {
  actions,
  dataStatus,
  deepClone,
  fileTags,
  savedInRadioValues,
} from "../../../../publicDictionaryValues";
import styles from "../../style/index.module.scss";
import ActiveShareTaskReadOnly from "../../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import appService from "../../../../../service/app";
import LostPermissionModal from "../../../../sections/lostPermissionModal";
import StepService from "../../../../../service/stepBase/StepService";
import StepBusinessType from "../../../../../service/stepBase/stepBusinessType";

const procedureReturnAddFileRef = createRef();
const procedureFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const supportingFileTableRef = createRef();

export default class ReviseNewProcedurePending extends BaseComponent {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    const {
      savedInReturnReasonValue,
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      savedInReasonStatus,
      savedInReturnReasonLog,
      isSavedInSectionChange,
    } = this.props.savedInProps;
    const {
      clientServerPathStatusName,
      clientServerPathStatusID,
      serverPathValue,
      serverPathReturnReasonValue,
      serverPathReasonStatus,
      serverPathReturnReasonInputValue,
      serverPathReturnReasonLog,
      isServerPathSectionChange,
    } = this.props.serverPathProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
        isRevisionInfoChange: false,
      },
      savedInState: {
        savedInReturnReasonValue,
        savedInReturnReasonInputValue,
        procedureSaveInCategoryStatusID,
        savedInRadioValue,
        savedInReasonStatus,
        savedInReturnReasonLog,
        isSavedInSectionChange,
      },
      serverPathState: {
        clientServerPathStatusName,
        clientServerPathStatusID,
        serverPathValue,
        serverPathReturnReasonValue,
        serverPathReasonStatus,
        serverPathReturnReasonInputValue,
        serverPathReturnReasonLog,
        isServerPathSectionChange,
      },
      modalState: {
        modalTitle: "",
        modalLabel: "",
        okText: "",
        cancelText: "",
        closable: false,
        isOkBtnDisabled: false,
        isCancelBtnDisabled: false,
        maskClosable: false,
        isModalWithComponentOpen: false,
        isShowSelect: false,
        rowIndex: 0,
        fileIds: [],
        fileNames: [],
        modalWidth: 520,
      },
      noPermission: false,
    };
    this.prevProcedureDataList = [];
    this.para = null;
    this.isTableBorderError = false;
    this.isSaveDisabled = true;
    this.modalContentLabels = {
      cancelConfimModalLabel: (
        <span className={styles.modalText}>
          Are you sure to quit without saving?
        </span>
      ),
      savedInAndClientServerPathUnChangeWarningLabel: (
        <span className={styles.modalText}>
          Procedure Saved In value and Client Server Path value have not been
          revised. Are you sure to submit?
        </span>
      ),
      savedInUnChangeWarningLabel: (
        <span className={styles.modalText}>
          Procedure Saved In value has not been revised. Are you sure to submit?
        </span>
      ),
      clientServerPathUnChangeWarningLabel: (
        <span className={styles.modalText}>
          Client Server Path value has not been revised. Are you sure to submit?
        </span>
      ),
      submitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?</span>
      ),
      updateSubmitConfirmModalLabel: (
        <span className={styles.modalText}>Are you sure to submit?<br/>
        If the status of "Procedure Saved In" and/or "Client Server Path" is marked as "Returned",
         please ensure that the revision is made accordingly.</span>
      ),
      JBStatusChangeWarningLabel: (
        <span className={styles.modalText}>
          Are you sure to submit? There is no JB procedure and the <b>Procedure Status</b> will be changed to <b>Non JB</b>.<br/>
          If the status of “Procedure Saved In” and/or “Client Server Path” is marked as “Returned”, please ensure that the revision is made accordingly.
        </span>
      ),
    };
    this.params = {
      TaskId: this.props.params.ProcedureTaskId,
      ProcedureID: this.props.params.ProcedureID,
      ProcedureStepID: this.props.params.ProcedureStepID,
    };
  }

  /* function about get data from procedure file table */
  returnFileDataValue = (name, index) => {
    if (this.props.procedureRef.current !== null) {
      const procedureFileData = this.props.procedureRef.current.getDataList();
      let deleteList = this.props.procedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      return combineDatalist[index].data.find((item) => item.name === name);
    }
  };

  /* success callback about click submit or save button to trigger api */
  _triggerSuccess = (response) => {
    if (response.succeeded === false) {
      this._alertError(response.message);
    } else {
      this.props.closePage();
    }
    this.props._hideLoading();
  };

  /* error callback about click submit or save button to trigger api */
  _triggerError = (response) => {
    this._alertError(response);
  };

  /* function about click submit or save button to trigger api */
  createProcedureFileTriggerAction = () => {
    ProcedureDetailService.createProcedureTriggerRevisePageAction(
      this.para,
      this._triggerSuccess,
      this._triggerError
    );
  };

  /* function about click submit button in submit modal */
  handleSubmitOkClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isSubmitDisabled: true,
      },
    });
    this.props._showLoading();
    this.hideModal();
    let isTableDataReturn = false;
    try {
      this.props.data.forEach((fileData, index) => {
        const statusData = fileData.data.find((item) => item.name === "status");
        const file = fileData.data.find((item) => item.name === "fileName");
        isTableDataReturn =
          statusData.value === dataStatus.return &&
          file.tagValue !== fileTags.deleted;
        if (isTableDataReturn) {
          throw new Error();
        }
      });
    } catch (e) {
      console.log(e);
    }
    this.para = this.props.handleReturnSubmitData(
      actions.revise,
      "Revise",
      this.props.procedureSavedInRef,
      this.props.procedureServerPathRef,
      this.props.procedureRef,
      undefined,
      this.props.revisionInfoRef
    );
    this.createProcedureFileTriggerAction();
  };

  /* function about click submit button in cancel action modal */
  handleCancelOkClick = () => {
    this.setState({
      pageState: { ...this.state.pageState, isCancelDisabled: true },
    });
    this.hideModal();
    this.props.closePage();
  };

  handleCancelClick = () =>
    this.handleCreateActionClickSectionCheckModal(
      "",
      this.modalContentLabels.cancelConfimModalLabel,
      this.handleCancelOkClick,
      this.hideModal
    );

  /* function about close modal */
  hideModal = () => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        actionType: "",
        isModalWithComponentOpen: false,
        textAreaClassName: "",
        textAreaMaxLength: 0,
        textAreaPlacement: "",
        isShowTextArea: false,
        isShowSelect: false,
        isShowTextAreaAndUploadDragger: false,
        modalTitle: "",
        modalLabel: "",
        maskClosable: false,
        uploadDraggerFileType: "",
        modalWidth: 520,
        fileIds: [],
        fileNames: [],
      },
    });
  };

  /* function about click submit button in revise page to show the tip modal */
  handleCreateActionClickSectionCheckModal = (
    modalTitle,
    modalLabel,
    okMethod,
    cancelMethod
  ) => {
    this.setState({
      modalState: {
        ...this.state.modalState,
        modalWidth: 520,
        isModalWithComponentOpen: true,
        modalTitle: modalTitle,
        modalLabel: modalLabel,
        handleOk: okMethod,
        handleCancel: cancelMethod,
        okText: "Confirm",
        cancelText: "Cancel",
      },
    });
  };

  /* function about click submit button in revise page to check whether some return section don't change */
  handleSavedInAndServerPathUnChangeCheck = () => {
    const stateSaveInRadioValue = this.state.savedInState.savedInRadioValue;
    const initSaveInRadioValue =
      this.props.primitiveSavedInSectionState.savedInRadioValue;
    const stateServerPath = this.state.serverPathState.serverPathValue;
    const initServerPath =
      this.props.primitiveServerPathSectionState.serverPathValue;
    if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === dataStatus.return &&
      stateSaveInRadioValue === initSaveInRadioValue &&
      stateSaveInRadioValue !== savedInRadioValues.systemOnly &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === dataStatus.return &&
      stateServerPath === initServerPath
    ) {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.savedInAndClientServerPathUnChangeWarningLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    } else if (
      this.props.procedureSavedInRef.current.state.savedInSectionState
        .procedureSaveInCategoryStatusID === dataStatus.return &&
      stateSaveInRadioValue === initSaveInRadioValue
    ) {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.savedInUnChangeWarningLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    } else if (
      stateSaveInRadioValue !== savedInRadioValues.systemOnly &&
      this.props.procedureServerPathRef.current.state.serverPathSectionState
        .serverPathStatus === dataStatus.return &&
      stateServerPath === initServerPath &&
      stateSaveInRadioValue !== savedInRadioValues.systemOnly
    ) {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.clientServerPathUnChangeWarningLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    } else {
      this.handleCreateActionClickSectionCheckModal(
        "",
        this.modalContentLabels.submitConfirmModalLabel,
        this.handleSubmitOkClick,
        this.hideModal
      );
    }
  };

  handleControlErrorScroll = (top) => window.scrollTo(0, top || 0);

  handleChangeTableErrorStatus = (value) => {
    this.isTableBorderError = value;
    this.setState({
      pageState: {
        ...this.state.pageState,
        isTableBorderError: value,
      },
    });
  };

  /* function about click submit button in revise page to scroll page if table has error */
  constrolTableScrollWhenSubmit = (
    isTableError,
    procedureFileData,
    errorIndexList,
    tableBorderErrorFlag
  ) => {
    if (isTableError) {
      if (this.isTableBorderError) {
        procedureFileData.forEach((item, index) => {
          procedureFileData[index].isError = true;
        });
        const tableEle = document.getElementsByClassName(
          "reviseProcedureTable"
        )[0];
        tableEle !== undefined &&
          this.handleControlErrorScroll(
            tableEle.offsetTop + tableEle.clientHeight
          );
        this.isTableBorderError = tableBorderErrorFlag;
        this.setState({
          pageState: {
            ...this.state.pageState,
            isTableBorderError: tableBorderErrorFlag,
          },
        });
      } else {
        const firstErrorTr = document.getElementById(
          `table-tr-${errorIndexList[0]}`
        );
        this.handleControlErrorScroll(
          firstErrorTr.offsetTop + firstErrorTr.clientHeight
        );
      }
      procedureFileTableRef.current.updateDataList(procedureFileData);
    } else {
      procedureFileData.forEach((item, index) => {
        procedureFileData[index].isError = false;
      });
      this.handleSavedInAndServerPathUnChangeCheck();
    }
  };

  /* function about click submit button in revise page if returned files don't do change */
  handleCheckFileTableHighLight = (
    procedureFileData,
    tableErrorList,
    isTableError
  ) => {
    let errorIndexList = [];
    let deleteList = [];
    let tableBorderErrorFlag = false;
    if (procedureFileData.length === 0) {
      isTableError = true;
      this.isTableBorderError = true;
      this.setState({
        pageState: { ...this.state.pageState, isTableBorderError: true },
      });
    } else if (procedureFileData.length === 1) {
      const isReturned =
        procedureFileData[0].data.find((file) => file.name === "status")
          .value === dataStatus.return;
      const fileTag = procedureFileData[0].data.find(
        (file) => file.name === "fileName"
      ).tagValue;
      if (isReturned && fileTag !== (fileTags.replaced || fileTags.deleted)) {
        errorIndexList = [];
        tableErrorList.push(procedureFileData[0]);
        isTableError = true;
        procedureFileData[0].isError = true;
        errorIndexList.push(0);
      }
    } else {
      procedureFileData.map((fileData, index) => {
        const isReturned =
          fileData.data.find((file) => file.name === "status").value ===
          dataStatus.return;
        const fileTag = fileData.data.find(
          (file) => file.name === "fileName"
        ).tagValue;
        const isReviewed = fileData.data.find(
          (file) => file.name === "isReviewed"
        ).value;
        if (isReturned && !isReviewed && fileTag !== fileTags.deleted) {
          errorIndexList = [];
          tableErrorList.push(procedureFileData[index]);
          errorIndexList.push(index);
          isTableError = true;
          procedureFileData[index].isError = true;
        } else if (fileTag === fileTags.deleted) {
          deleteList.push(index);
        }
      });
      if (
        tableErrorList.length === procedureFileData.length ||
        deleteList.length === procedureFileData.length
      ) {
        this.isTableBorderError = true;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: true },
        });
        isTableError = true;
      } else if (
        tableErrorList.length > 0 &&
        tableErrorList.length !== procedureFileData.length
      ) {
        this.isTableBorderError = false;
        this.setState({
          pageState: { ...this.state.pageState, isTableBorderError: false },
        });
        isTableError = true;
      }
    }
    if (procedureFileData.length === 0) {
      tableBorderErrorFlag = true;
    }
    this.constrolTableScrollWhenSubmit(
      isTableError,
      procedureFileData,
      errorIndexList,
      tableBorderErrorFlag
    );
  };

  /* function about click submit button in revise page if server path doesn't enter required field*/
  handleCheckServerPathHighLight = () => {
    this.props.procedureServerPathRef.current.setServerPathError();
    this.props.procedureServerPathRef.current !== null &&
      this.handleControlErrorScroll(
        this.props.procedureServerPathRef.current.offsetTop
      );
  };

  /* function about click submit button in revise page */
  handleReviseSubmitClickCheck = (procedureFileData, tableErrorList) => {
    let isTableError = false;
    if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemOnly
    ) {
      this.handleCheckFileTableHighLight(
        procedureFileData,
        tableErrorList,
        isTableError
      );
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.systemAndClientServer
    ) {
      if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else {
        this.handleCheckFileTableHighLight(
          procedureFileData,
          tableErrorList,
          isTableError
        );
      }
    } else if (
      this.state.savedInState.savedInRadioValue ===
      savedInRadioValues.serverOnly
    ) {
      if (
        this.state.serverPathState.serverPathValue === null ||
        this.state.serverPathState.serverPathValue.trim() === ""
      ) {
        this.handleCheckServerPathHighLight();
      } else {
        this.handleSavedInAndServerPathUnChangeCheck();
      }
    }
  };

  _checkStepPermissionSuccess = (result, actionType) => {
    this.props._hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    if (actionType === "submit") {
      this.handleSubmitClick();
    } else if (actionType === "save") {
      this.handleSaveClick();
    }
  };

  checkStepPermission = (actionType) => {
    this.props._showLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      (response) => this._checkStepPermissionSuccess(response, actionType),
      (response) => this._alertError(response.message)
    );
  };

  /* function about click submit button in approve page */
  handleSubmitClick = () => {
    const procedureFileData =
      this.props.procedureRef.current === null
        ? []
        : this.props.procedureRef.current.getDataList();
    let tableErrorList = [];
    this.handleReviseSubmitClickCheck(procedureFileData, tableErrorList);
  };

  /* function about click save button */
  handleSaveClick = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        actionID: actions.save,
      },
    });
    this.isSaveDisabled = true;
    this.props._showLoading();
    this.para = this.props.handleReturnSubmitData(
      actions.save,
      "Save",
      this.props.procedureSavedInRef,
      this.props.procedureServerPathRef,
      this.props.procedureRef,
      undefined,
      this.props.revisionInfoRef
    );

    this.createProcedureFileTriggerAction();
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /**
   * function about control save as draft button disabled
   * @param {*} isChange flag about is table change
   * @param {*} savedInRadioValue the value of saved in radio
   */
  controlIsSaveDisabled = (isChange, savedInRadioValue) => {
    if (this.props.procedureSavedInRef.current !== null) {
      const initSavedInRadioValue =
        this.props.initSavedInSectionState.savedInRadioValue;
      if (savedInRadioValue === savedInRadioValues.systemAndClientServer) {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          !isChange &&
          savedInRadioValue === initSavedInRadioValue &&
          ((currentServerPath === initServerPath && currentServerPath && initServerPath)
            || (!currentServerPath && !initServerPath));
      } else if (savedInRadioValue === savedInRadioValues.systemOnly) {
        this.isSaveDisabled =
          !isChange && savedInRadioValue === initSavedInRadioValue;
      } else {
        const currentServerPath = this.state.serverPathState.serverPathValue;
        const initServerPath =
          this.props.initServerPathSectionState.serverPathValue;
        this.isSaveDisabled =
          savedInRadioValue === initSavedInRadioValue &&
          ((currentServerPath === initServerPath && currentServerPath && initServerPath)
            || (!currentServerPath && !initServerPath));
      }
    }
  };

  /**
   * function about update saved in value state
   * @param {*} value
   */
  updateSavedInRadioValue = (value) => {
    const dataList =
      this.props.procedureRef.current !== null
        ? deepClone(this.props.procedureRef.current.getDataList())
        : [];
    if (value === savedInRadioValues.serverOnly || value === null) {
      this.prevProcedureDataList = dataList;
    }
    dataList.forEach((item, index) => {
      dataList[index].isError = false;
    });
    procedureFileTableRef.current &&
      procedureFileTableRef.current.updateDataList(dataList);
    this.isTableBorderError = false;
    this.setState({
      savedInState: { ...this.state.savedInState, savedInRadioValue: value },
      pageState: { ...this.state.pageState, isTableBorderError: false },
    });
    this.props.procedureServerPathRef.current.cancelServerPathError();
  };

  /**
   * function about update client server path value state
   * @param {*} value
   */
  updateServerPathValue = (value) => {
    this.setState({
      serverPathState: {
        ...this.state.serverPathState,
        serverPathValue: value,
      },
    });
  };

  /**
   * function about return page name
   * @returns
   */
  handleReturnPageName = () => {
    return "Revise New Procedure";
  };

  /**
   * function about return revision information section
   * @returns
   */
  handleReturnRevisionInformation = () => {
    return null;
  };

  /**
   * function about return procedure file section
   * @param {object} obj
   * @returns
   */
  handleReturnProcedureFileSection = () => {
    const { currentRole, isDisableAll, procedureReplaceFileRef } =
      this.props.pageProps;
    return (
      <ProcedureBlock
        className={styles.approveProcedureFile}
        child={
          <ReviseProcedureFileSection
            sectionProps={{ currentRole }}
            savedInProps={this.state.savedInState}
            serverPathProps={this.state.serverPathState}
            initSavedInSectionState={this.props.initSavedInSectionState}
            initServerPathSectionState={this.props.initServerPathSectionState}
            initProcedureTableData={this.props.initProcedureTableData}
            initProcedureFileTableState={this.props.initProcedureFileTableState}
            refs={{
              procedureSavedInRef: this.props.procedureSavedInRef,
              procedureServerPathRef: this.props.procedureServerPathRef,
              procedureRef: this.props.procedureRef,
              procedureFileTableRef,
              reasonRef,
              noteRef,
              procedureFileRef,
              procedureReplaceFileRef,
            }}
            data={this.props.data}
            isTableBorderError={this.isTableBorderError}
            handleChangeTableErrorStatus={this.handleChangeTableErrorStatus}
            setIsTableChange={this.setIsTableChange}
            cancelIsTableChange={this.cancelIsTableChange}
            updateSavedInRadioValue={this.updateSavedInRadioValue}
            updateServerPathValue={this.updateServerPathValue}
            _showLoading={this.props._showLoading}
            _hideLoading={this.props._hideLoading}
            updateUploadProcedureFileNum={
              this.props.updateUploadProcedureFileNum
            }
            updateProcedureFileNum={this.props.updateProcedureFileNum}
            isDisableAll={isDisableAll}
          />
        }
        isShowArrow={true}
        isOpen={true}
      />
    );
  };

  handleReturnSupportingFileSection = () => {
    return (
      <ProcedureBlock
        className={styles.approveSupportingFile}
        child={
          <ReadOnlySupportingFileSection
            supportingFileData={this.props.supportingTableData}
            supportingFileRef={supportingFileRef}
            supportingFileTableRef={supportingFileTableRef}
          />
        }
        isShowArrow={true}
        isOpen={false}
        onShowDataLoad={() => {
          return StepService.getStepData(
            this.params,
            this.props.setSupportingFileData,
            this.setError,
            [StepBusinessType.supportingFile]
          );
        }}
      />
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      ((prevState.savedInState.savedInRadioValue ===
        savedInRadioValues.serverOnly &&
        this.state.savedInState.savedInRadioValue !==
          savedInRadioValues.serverOnly) ||
        (prevState.savedInState.savedInRadioValue === null &&
          this.state.savedInState.savedInRadioValue !== null)) &&
      this.props.procedureRef.current !== null &&
      this.prevProcedureDataList !== null &&
      this.prevProcedureDataList !== undefined &&
      this.prevProcedureDataList.length > 0
    ) {
      this.props.procedureRef.current.updateDataList(
        this.prevProcedureDataList
      );
    }
  }

  render() {
    const {
      procedureStatus,
      receiveDate,
      requestByName,
      procedureReplaceFileRef,
    } = this.props.pageProps;

    const { isCancelDisabled, isSubmitDisabled, isChange } =
      this.state.pageState;
    const { taskInfoData, procedureInfoData, ppoList } =
      this.props.sectionProps;
    const {
      modalTitle,
      modalLabel,
      okText,
      cancelText,
      closable,
      isOkBtnDisabled,
      isCancelBtnDisabled,
      maskClosable,
      isModalWithComponentOpen,
      rowIndex,
      fileIds,
      fileNames,
      modalWidth,
      handleOk,
      handleCancel,
    } = this.state.modalState;
    const { savedInRadioValue } = this.state.savedInState;
    this.controlIsSaveDisabled(isChange, savedInRadioValue);
    return (
      <div className={styles.approveNewPro}>
        <PageHeader
          title={this.handleReturnPageName()}
          receiveDate={this._formateDate(receiveDate)}
          requestBy={requestByName}
          status="Pending"
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={<TaskInformation data={taskInfoData} />}
          isShowArrow={true}
          isOpen={false}
        />
        {this.handleReturnRevisionInformation()}
        {this.handleReturnProcedureFileSection()}
        {this.handleReturnSupportingFileSection()}
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ProcedureInformation
              title="Procedure Information"
              submitData={procedureInfoData}
              isShowActions={false}
              noteDisabled={true}
              isPPODisabled={true}
              ppoList={ppoList}
              procedureStatus={procedureStatus}
            />
          }
          onShowDataLoad={() => {
            return StepService.getStepData(
              this.params,
              this.props.setProcedureInfoData,
              this.setError,
              [
                StepBusinessType.procedureOwner,
                StepBusinessType.pmpExcluded,
                StepBusinessType.primaryProcedureOwner,
              ]
            );
          }}
          isShowArrow={true}
          isOpen={false}
        />
        <ProcedureBlock
          className={styles.approveProcedureBlock}
          child={
            <ActiveShareTaskReadOnly
              shareTaskData={this.props.shareTaskData}
            />
          }
          isShowArrow={true}
          isOpen={false}
          onShowDataLoad={() => {
            return StepService.getStepData(
              this.params,
              this.props.setShareTaskData,
              this.setError,
              [StepBusinessType.shareTask]
            );
          }}
        />
        <div className={styles.actionBtns}>
          <div className={styles.normalBtns}>
            <Buttons
              color="grey"
              size="middle"
              onClick={() => this.checkStepPermission("save")}
              disabled={this.isSaveDisabled}
              btnClassName={styles.approveActionBtn}
            >
              Save as Draft
            </Buttons>
            <Buttons
              color="grey"
              size="middle"
              onClick={this.handleCancelClick}
              disabled={isCancelDisabled}
              btnClassName={styles.approveActionBtn}
            >
              Cancel
            </Buttons>
            <Buttons
              color="blue"
              size="middle"
              onClick={() => this.checkStepPermission("submit")}
              disabled={isSubmitDisabled}
              btnClassName={styles.approveActionSubmitBtn}
            >
              Submit for Approval
            </Buttons>
          </div>
        </div>
        <ModalWithComponent
          isModalOpen={isModalWithComponentOpen}
          closable={closable}
          okText={okText}
          cancelText={cancelText}
          title={modalTitle}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isOkDisabled={isOkBtnDisabled}
          isCancelDisabled={isCancelBtnDisabled}
          maskClosable={maskClosable}
          label={modalLabel}
          fileUploadRef={procedureReplaceFileRef}
          fileUploadInputRef={procedureReturnAddFileRef}
          rowindex={rowIndex}
          fileIds={fileIds}
          fileNames={fileNames}
          isLoading={isOkBtnDisabled}
          modalWidth={modalWidth}
        />
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
