import React from "react";
import BaseComponent from "../../../../../base/common/BaseComponent";
import PMPExcludedEditIcon from "./pmpExcludedEditIcon";
import PMPExcludedModal from "./pmpExcludedModal";

export default class PMPExcludedEditField extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isEditDisabled: false,
    };
  }
  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleEditDisabled = () => {
    this.setState({
      isEditDisabled: true,
    });
  };

  render() {
    const { isModalOpen, isEditDisabled } = this.state;
    const {
      setProcedureIdToList,
      dataList,
      procedureTaskId,
      procedureId,
      procedureAccountId,
      isDisabled
    } = this.props;
    return (
      <div>
        <PMPExcludedEditIcon
          handleModalOpen={this.handleModalOpen}
          disabled={isDisabled}
          isEditDisabled={isEditDisabled}
          procedureTaskId={procedureTaskId}
          procedureId={procedureId}
          procedureAccountId={procedureAccountId}
          pmpExcluded={this.props.pmpExcluded}
        />
        <PMPExcludedModal
          isModalOpen={isModalOpen}
          handleCloseModal={this.handleCloseModal}
          procedureTaskId={this.props.procedureTaskId}
          procedureId={this.props.procedureId}
          handleEditDisabled={this.handleEditDisabled}
          pmpExcluded={this.props.pmpExcluded}
          setProcedureIdToList={setProcedureIdToList}
          dataList={dataList}
        />
      </div>
    );
  }
}
