import BaseService from "../base";
import CreateSupportingFileStepService from "./createSupportingFileStepService";

class CreateSupportingFileService extends BaseService {
  getCreateSupportingFileStepItem = (para, success, error) => {
    CreateSupportingFileStepService.getStepData(para, success, error);
  };

  createSupportingFileTriggerRevisePageAction = (para, success, error) => {
    this.sendRequest(
      "createSupportingFileTriggerRevisePageAction",
      para,
      success,
      error
    );
  };

  createSupportingFileTriggerApprovePageAction = (para, success, error) => {
    this.sendRequest(
      "createSupportingFileTriggerApprovePageAction",
      para,
      success,
      error
    );
  };

  updateSupportingFileTriggerRevisePageAction = (para, success, error) => {
    this.sendRequest(
      "updateSupportingFileTriggerRevisePageAction",
      para,
      success,
      error
    );
  };

  updateSupportingFileTriggerApprovePageAction = (para, success, error) => {
    this.sendRequest(
      "updateSupportingFileTriggerApprovePageAction",
      para,
      success,
      error
    );
  };
}

const createSupportingFileService = new CreateSupportingFileService();

export default createSupportingFileService;
