import BaseReadOnlyShareTask from "../baseReadOnlyShareTask";

export default class BaseReadOnlyChangeMainTask extends BaseReadOnlyShareTask {
  handleDataColumn = () => {
    return [
      this.changeMainTagText,
      ...this.commonShareDataColumn,
      this.tagColumn('6.5%'),
    ];
  };
}
