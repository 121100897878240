import ProcessBase from "../processBase";
export default class ShareProcessBase extends ProcessBase {
  setSharePageType = () => {
    this.isApprovePendingPage =
      this.isApprovePage &&
      this.state.pageState.procedureStepStatus === "Pending";
    this.isApproveCompletePage =
      this.isApprovePage &&
      this.state.pageState.procedureStepStatus === "Complete";
    this.isRevisePendingPage =
      this.isRevisePage &&
      this.state.pageState.procedureStepStatus === "Pending";
    this.isReviseCompletePage =
      this.isRevisePage &&
      this.state.pageState.procedureStepStatus === "Complete" &&
      this.triggerTimes > 1;
    this.isSubmitCompletePage =
      this.isRevisePage &&
      this.state.pageState.procedureStepStatus === "Complete" &&
      this.triggerTimes === 1;
  };
}
