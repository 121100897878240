import BaseShareTask from "../baseShareTask";

export default class BaseShareOnModal extends BaseShareTask {
  ReseanOnChange = (e) => {
    this.setState({
      reseanValue: e.value,
      reasonInputStatus: "",
    });
  };
}
