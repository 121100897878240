import { Checkbox } from "antd";
import BaseComponent from "../../../../../base/common/BaseComponent";
import "./index.scss";

class ProcedureProcessFileThead extends BaseComponent {
  render() {
    const { columns } = this.props;

    return (
      <thead className="table-head">
        <tr>
          {columns.map((column, index) => {
            if (!column.isShow) {
              return;
            }
            return column.dataName === "action" ? (
              <th key={crypto.randomUUID()} style={{ textAlign: "center" }}>
                {column.name}
              </th>
            ) : (
              <th key={crypto.randomUUID()}>
                {column.type === "checkbox" ? (
                  <Checkbox
                    checked={column.headCheck()}
                    onChange={(ele) => column.headOnCheck(ele.target)}
                    disabled={false}
                  />
                ) : (
                  column.name
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default ProcedureProcessFileThead;
