import React from "react";
import BaseComponent from "../../../../base/common/BaseComponent";
import requiredWarning from "../../../../assets/image/required-warning.png";
import { ExpendTextArea } from "../../../../base/basicComponents/input";
import styles from "./index.module.scss";

export default class BaseClientServerPath extends BaseComponent {
  render() {
    const {
      procedureSaveInValue,
      value,
      clientPathOnChange,
      disabled,
      status,
      placeholder,
      showRequired = true,
    } = this.props;

    return (
      <div className={styles.clientPathDiv}>
        <div className={styles.clientPathLabel}>
          <span className={`${styles.clientPathTitle} fs-14`}>
            Client Server Path
          </span>
          <img
            style={{
              visibility:
                procedureSaveInValue !== 1 && showRequired
                  ? "visible"
                  : "hidden",
            }}
            src={requiredWarning}
            alt="required enter"
            className={styles.inputLabelRequiredIcon}
          />
        </div>
        <ExpendTextArea
          value={value}
          onChange={clientPathOnChange}
          disabled={disabled}
          status={status}
          placeholder={placeholder}
          maxLength={500}
          isExpend={true}
        />
      </div>
    );
  }
}
