export default class Stack {

    constructor() {
        this.storage = [];
    }

    push(element) {
        this.storage.push(element);
    }

    pop() {
        if (this.isEmpty()) {
            return null;
        }
    
        return this.storage.pop();
    }

    isEmpty() {
        return this.storage.length === 0;
    }
  }