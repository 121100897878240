import React from "react";
import { fileTags } from "../../../../../publicDictionaryValues";
import ProcedureProcessFileThead from "../../../../procedureFileSections/fields/approveAndReviseFileHeadFields";
import ApproveProcedureFileTable from "../../../../procedureFileSections/fields/procedureFileTableFields/approveProcedureFileTable";
import ApproveUpdateSupportingFileTbody from "../../supportingFileTbody/approveUpdateSupportingFileTbody";

export default class ApporveUpdateSupportingFileTable extends ApproveProcedureFileTable {
  /* sort procedure files in approved page */
  sortApprovedPageFiles = (dataList) => {
    const replaceList = [];
    const newFileList = [];
    const deleteList = [];
    const cancelList = [];
    const emptyList = [];
    dataList.forEach((item, index) => {
      const tagStatus = item.data.find(
        (dataItem) => dataItem.name === "fileName"
      ).tagValue;
      if (tagStatus === fileTags.replaced) {
        replaceList.push(item);
      } else if (tagStatus === fileTags.new) {
        newFileList.push(item);
      } else if (tagStatus === fileTags.deleted) {
        deleteList.push(item);
      } else if (tagStatus === fileTags.cancelled) {
        cancelList.push(item);
      } else {
        emptyList.push(item);
      }
    });
    const replaceNewList = this.sortDataList(replaceList);
    const newList = this.sortDataList(newFileList);
    const deleteNewList = this.sortDataList(deleteList);
    const cancelNewList = this.sortDataList(cancelList);
    const emptyNewList = this.sortDataList(emptyList);
    this.newDataList = [
      ...replaceNewList,
      ...deleteNewList,
      ...cancelNewList,
      ...newList,
      ...emptyNewList,
    ];
  };

  /* function about set need approved file number in approve page */
  setNeedApproveDataNumber = (data) => {
    this.approveNumber = 0;
    for (let index in data) {
      if (
        data[index].data.find((i) => i.name === "status").value === null &&
        data[index].data.find((i) => i.name === "fileName").tagValue !== null
      ) {
        this.approveNumber += 1;
      }
    }
  };

  /**
   * return supporting file table's tbody
   * @returns
   */
  handleReturnTbody = () => {
    const {
      procedureNoteMaxLength,
      procedureNoteSize,
      inputOnChange,
      fileTableRef,
      optionList,
      isShowReturnReason,
      handleReturnClick,
      handleFileSelect,
      currentRole,
      downloadFile,
      isComplete,
      columns,
      isExpendData,
    } = this.props;
    const { data } = this.state;
    return (
      <ApproveUpdateSupportingFileTbody
        data={data}
        columns={columns}
        inputOnChange={inputOnChange}
        procedureNoteMaxLength={procedureNoteMaxLength}
        procedureNoteSize={procedureNoteSize}
        fileTableRef={fileTableRef}
        optionList={optionList}
        isShowReturnReason={isShowReturnReason}
        handleReturnClick={handleReturnClick}
        handleFileSelect={handleFileSelect}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isComplete={isComplete}
        isExpendData={isExpendData}
      />
    );
  };

  render() {
    const { className, columns, isRejectCompletePage } = this.props;
    const { data } = this.state;
    this.setNeedApproveDataNumber(data);
    return (
      <>
        <div className="scrollTable">
          <table className={`${className} table autoHeightChangeContainer`}>
            <ProcedureProcessFileThead columns={columns} />
            {this.handleReturnTbody()}
          </table>
        </div>
        <p
          className={`${this.props.className} fileNumTip`}
          style={{ color: "#FF9921" }}
        >
          <span className="fileNum">{isRejectCompletePage ? 0 : this.approveNumber}</span>{" "}
          {this.setFileText(isRejectCompletePage ? 0 : this.approveNumber)} pending approval
        </p>
      </>
    );
  }
}
