import ReadOnlyText from "../../../base/basicComponents/readOnly-text";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./style/index.module.scss";
class TaskInformation extends BaseComponent {
  taskInfoFieldComponent = (title, value) => {
    return (
      <div className={styles.taskInfoField}>
        <span className={styles.taskInfoFieldTitle}>{title}</span>
        <ReadOnlyText value={value} />
      </div>
    )
  }

  render() {
    const { data, isInModal = false } = this.props;
    return (
      <div className={styles.taskInfoDiv}>
        {!isInModal && <p className={styles.taskInfoTitle}>Task Information</p>}
        <div 
          className={
            isInModal ? styles.taskInfoModalFieldDiv : styles.taskInfoFieldDiv
          }
        >
          {this.taskInfoFieldComponent("Account Code", data.accountAbbr)}
          {this.taskInfoFieldComponent("Account Name", data.accountName)}
          {this.taskInfoFieldComponent("Account Manager", data.accountManager)}
          {this.taskInfoFieldComponent("Account Supervisor", data.accountSupervisor)}
          {this.taskInfoFieldComponent("Team Leader", data.accountTeamLeader)}
          {this.taskInfoFieldComponent("Task Group", data.taskGroupName)}
          {this.taskInfoFieldComponent("Category", data.categoryName)}
          {this.taskInfoFieldComponent("Task Label Name", data.taskLabelName)}
          {this.taskInfoFieldComponent("Task ID", data.taskID)}
          {this.taskInfoFieldComponent("Task Status", data.taskStatus)}
          {this.taskInfoFieldComponent(
            "Task Created Date", 
            data.createdDate !== undefined && data.createdDate !== null && data.createdDate.trim() !== "" && this._formateDate(data.createdDate)
          )}
          {this.taskInfoFieldComponent("Highly Regulated?", data.highRegulated)}
          {this.taskInfoFieldComponent("Turnaround Time", data.turnaroundTime)}
          {this.taskInfoFieldComponent("Temporary Task", data.temporaryTask)}
        </div>
      </div>
    );
  }
}

export default TaskInformation;
