import StepBaseService from "../stepBase";
import StepBusinessType from "../stepBase/stepBusinessType";

class CreateSupportingFileStepService extends StepBaseService {
  getSupportingFileStepKey = () => {
    return {
      dataKey: StepBusinessType.supportingFile.dataKey,
      settingKey: "getCreateSupportingFileStepItem",
    };
  };

  getTaskInfoKey = () => {
    return StepBusinessType.taskInfo;
  };
}

export default new CreateSupportingFileStepService();
