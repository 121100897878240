import React, { createRef } from "react";
import TaskInformation from "../../sections/taskInfoSections";
import ProcedureInformation from "../../sections/procedureInfoSections/readOnlyProcedureInfoSection";
import ActiveShareTaskReadOnly from "../../sections/sharedTaskSections/activeShareTaskReadOnly";
import PageHeader from "../../commonComponents/pageHeaders/PageHeader";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import formateTaskInfoData from "../../sections/taskInfoSections/formateTaskInfoData";
import formateProcedureInfoData from "../../sections/procedureInfoSections/formateProcedureInfoData";
import NormalSpin from "../../../base/basicComponents/spin";
import ProcedureBlock from "../../../base/basicComponents/procedureBlock";
import BaseAlignWithClient from "./alignWithClientSections/baseAlignWithClientSection";
import ReadOnlyAlignWithClient from "./alignWithClientSections/readOnlyAlignWithClientSection";
import ReadOnlyProcedureFileSection from "../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../sections/supportingFileSections/readOnlySupportingFileSection";
import ProcessBase from "../../commonComponents/processBase";
import styles from "./styles/index.module.scss";
import StepService from "../../../service/stepBase/StepService";
import StepBusinessType from "../../../service/stepBase/stepBusinessType";
const procedureFileRef = createRef();
const supportingFileRef = createRef();
const procedureFileTableRef = createRef();
const supportingFileTableRef = createRef();
const procedureInfoRef = createRef();

export default class SignOffConfirmation extends ProcessBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      taskInformationData: [],
      isMounted: false,
      ppoList: [],
      procedureInfoData: {},
      alignWithClientValue: null,
      reasonValue: null,
      attachmentFiles: [],
      attachmentFileIds: [],
      attachmentFileNames: [],
      shareTaskData: {},
      status: null,
      reviceDate: null,
      requestBy: null,
      requestByName: null,
      fileLoading: { currentNum: 0, allNum: 0 },
      clientServerPath: "",
      savedInRadioValue: null,
      procedureTableData: [],
      supportingTableData: [],
      jbProcedureSavedIn: null,
      procedureStatusID: null,
      procedureNote: "",
    };
    this.workflowProcessInstanceId = null;
    this.flowCategoryID = null;
    this.signOffStepID = null;
  }

  /* function about adjust null data in api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  /* callback about get sign off data api success */
  _getSignOffStepSuccess = (result) => {
    const fileIds = [];
    const fileNames = [];
    result.signOff.attachEmailFiles.forEach((item) => {
      fileIds.push(item.fileID);
      fileNames.push(item.attachEmailFileName);
    });

    this.workflowProcessInstanceId = result.workflowProcessInstanceId;
    this.flowCategoryID = result.flowCategoryID;
    this.signOffStepID = result.signOff.signOffStepID;
    this.setState({
      procedureStatusID: result.procedureStatusID,
      status: result.signOff.procedureStepStatus,
      reviceDate: result.reviceDate,
      requestBy: result.requestBy,
      requestByName: result.requestByName,
      isMounted: true,
      alignWithClientValue: result.signOff.alignedWithClientID,
      reasonValue: result.signOff.reason,
      attachmentFiles: result.signOff.attachEmailFiles,
      attachmentFileIds: [...fileIds],
      attachmentFileNames: [...fileNames],
      procedureNote: result.procedureNote,
    });
    this.hideLoading();
  };

  setProcedureInfoData = (result) => {
    if (
      result.procedureStatusID === null ||
      result.procedureStatusID === undefined
    ) {
      result.procedureStatusID = this.state.procedureStatusID;
    }
    result.procedureNote = this.state.procedureNote;

    this.setState({
      procedureInfoData: formateProcedureInfoData.setProcedreInfoData(result),
    });
  };

  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingTableData: this.supportingTableData,
      });
    }
  };

  setSavedInServerPathState = (result) => {
    this.setState({
      savedInRadioValue:
        result.procedureFolder?.procedureSaveInCategoryID || null,
      clientServerPath:
        result.procedureFolder !== null
          ? result.procedureFolder.clientServerPath
          : "",
    });
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setSavedInServerPathState(result);
      this.setState({
        procedureTableData: this.procedureTableData,
        jbProcedureSavedIn: result.procedureFolder?.jbProcedureSaveIn || null,
      });
    }
  };

  setTaskInfoData = (result) => {
    if (result.taskInfomation === null || result.taskInfomation === undefined) {
      return;
    }

    this.setState({
      taskInformationData: formateTaskInfoData.setTaskInfoData(result),
    });
  };

  /* callback about get sign off data api failed */
  _getSignOffStepFailed = (error) => {
    this._alertError(error);
  };

  /* callback about get primary procedure owner option list api success */
  _getPrimaryProcedureOwnerDDLSuccess = (result) => {
    this.setState({
      ppoList: result,
    });
  };

  /* callback about get primary procedure owner option list api failed */
  _getPrimaryProcedureOwnerDDLFailed = () => {
    this._alertError("Get Primary Procedure Owner Failed");
  };

  /* function about close modal */
  hideModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
    });
  };

  /* function about open spin loading */
  openLoading = () => {
    this.setState({ loading: true });
  };

  /* function about close spin loading */
  hideLoading = () => {
    this.setState({ loading: false });
  };

  /**
   * Change update attachment loading ui
   * @param {*} fileLoading
   */
  updateFileNumLoading = (fileLoading) => {
    this.setState({
      fileLoading: fileLoading,
      loading: fileLoading.allNum !== fileLoading.currentNum,
    });
  };

  /* function about get data through call api */
  componentDidMount() {
    this.openLoading();
    const parentPageData = this._getRoutingParam("ParentPage");
    const para = {
      TaskID: this.taskID,
      ProcedureId: this.procedureID,
      ProcedureStepId: this.procedureStepID,
      parentPage: parentPageData,
    };
    ProcedureDetailService.getSignOffStepItem(
      para,
      this._getSignOffStepSuccess,
      (error) => {
        this._getSignOffStepFailed(error);
      }
    );
    ProcedureDetailService.getPrimaryProcedureOwnerDDL(
      this.taskID,
      this._getPrimaryProcedureOwnerDDLSuccess,
      this._getPrimaryProcedureOwnerDDLFailed
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.procedureInfoData !== this.state.procedureInfoData) {
      const { primaryProcedureOwner, note, procedureOwner } =
        this.state.procedureInfoData;
      if (procedureInfoRef.current !== null) {
        procedureInfoRef.current.updatePPO(primaryProcedureOwner);
        procedureInfoRef.current.updatePO(procedureOwner);
        procedureInfoRef.current.updateNote(note);
      }
    }
  }

  closePage = () => {
    this.route("/ToDoList");
  };

  render() {
    const {
      loading,
      taskInformationData,
      ppoList,
      procedureInfoData,
      alignWithClientValue,
      reasonValue,
      attachmentFiles,
      attachmentFileIds,
      attachmentFileNames,
      status,
      reviceDate,
      requestByName,
      isMounted,
      clientServerPath,
      savedInRadioValue,
      shareTaskData,
      procedureTableData,
      supportingTableData,
      jbProcedureSavedIn,
    } = this.state;

    const params = {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    const getStepParams = {
      TaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          this.state.fileLoading.allNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {this.state.fileLoading.currentNum}/
              {this.state.fileLoading.allNum} file
            </p>
          ) : undefined
        }
        children={
          <div className={styles.body}>
            <div className={styles.header}>
              {isMounted && (
                <PageHeader
                  title="Upload Sign-off Confirmation"
                  status={status}
                  receiveDate={this._formateDate(reviceDate)}
                  requestBy={requestByName}
                />
              )}
            </div>
            <ProcedureBlock
              child={<TaskInformation data={taskInformationData} />}
              isShowArrow={true}
              isOpen={false}
              onShowDataLoad={() => {
                return StepService.getStepData(
                  getStepParams,
                  this.setTaskInfoData,
                  this.setError,
                  [StepBusinessType.taskInfo]
                );
              }}
            />
            <ProcedureBlock
              className={styles.alignWithClient}
              child={
                isMounted && status === "Complete" ? (
                  <ReadOnlyAlignWithClient
                    status={status}
                    openLoading={this.openLoading}
                    hideLoading={this.hideLoading}
                    alignWithClientValue={alignWithClientValue}
                    reasonValue={reasonValue}
                    attachmentFiles={attachmentFiles}
                    attachmentFileIds={attachmentFileIds}
                    attachmentFileNames={attachmentFileNames}
                    isComplete={true}
                    updateFileNumLoading={this.updateFileNumLoading}
                  />
                ) : (
                  <BaseAlignWithClient
                    status={status}
                    openLoading={this.openLoading}
                    hideLoading={this.hideLoading}
                    alignWithClientValue={alignWithClientValue}
                    reasonValue={reasonValue}
                    attachmentFiles={attachmentFiles}
                    attachmentFileIds={attachmentFileIds}
                    attachmentFileNames={attachmentFileNames}
                    isComplete={false}
                    updateFileNumLoading={this.updateFileNumLoading}
                    closePage={this.closePage}
                    submitIds={{
                      procedureId: this.procedureID,
                      workflowProcessInstanceId: this.workflowProcessInstanceId,
                      flowCategoryID: this.flowCategoryID,
                      procedureStepID: this.procedureStepID,
                      signOffStepID: this.signOffStepID,
                    }}
                    params={params}
                  />
                )
              }
              isShowArrow={true}
              isOpen={true}
            />
            <ProcedureBlock
              child={
                <ReadOnlyProcedureFileSection
                  procedureFileData={procedureTableData}
                  procedureFileRef={procedureFileRef}
                  procedureFileTableRef={procedureFileTableRef}
                  clientServerPath={clientServerPath}
                  savedInRadioValue={savedInRadioValue}
                  jbProcedureSavedIn={jbProcedureSavedIn}
                />
              }
              isShowArrow={true}
              isOpen={false}
              onShowDataLoad={() => {
                return StepService.getStepData(
                  getStepParams,
                  this.setProcedureFileData,
                  this.setError,
                  [StepBusinessType.procedureFile]
                );
              }}
            />
            <ProcedureBlock
              child={
                <ReadOnlySupportingFileSection
                  supportingFileRef={supportingFileRef}
                  supportingFileTableRef={supportingFileTableRef}
                  supportingFileData={supportingTableData}
                />
              }
              isShowArrow={true}
              isOpen={false}
              onShowDataLoad={() => {
                return StepService.getStepData(
                  getStepParams,
                  this.setSupportingFileData,
                  this.setError,
                  [StepBusinessType.supportingFile]
                );
              }}
            />
            <ProcedureBlock
              child={
                <ProcedureInformation
                  ref={procedureInfoRef}
                  isShowActions={false}
                  ppoList={ppoList}
                  submitData={procedureInfoData}
                  noteDisabled={true}
                  isPPODisabled={true}
                  title="Procedure Information"
                />
              }
              isShowArrow={true}
              isOpen={false}
              onShowDataLoad={() => {
                return StepService.getStepData(
                  getStepParams,
                  this.setProcedureInfoData,
                  this.setError,
                  [
                    StepBusinessType.procedureOwner,
                    StepBusinessType.pmpExcluded,
                    StepBusinessType.primaryProcedureOwner,
                  ]
                );
              }}
            />
            <ProcedureBlock
              child={
                <ActiveShareTaskReadOnly
                  title="Shared Task"
                  shareTaskData={shareTaskData}
                />
              }
              isShowArrow={true}
              isOpen={false}
              onShowDataLoad={() => {
                return StepService.getStepData(
                  getStepParams,
                  this.setShareTaskData,
                  this.setError,
                  [StepBusinessType.shareTask]
                );
              }}
            />
          </div>
        }
      />
    );
  }
}
