import React from "react";
import ReviseProcedureFileSection from "../reviseProcedureFileSection";
import { actions, savedInRadioValues } from "../../../publicDictionaryValues";
import ReadOnlyProcedureProcessSavedIn from "../fields/savedInFields/readOnlySavedIn";
import ReadOnlyProcedureProcessClientServerPath from "../fields/serverPathFields/readOnlyServerPath";
import styles from "../index.module.scss";

export default class ReadOnlyReviseProcedureFileSection extends ReviseProcedureFileSection {
  handleReturnFileSectionTop = () => {
    const {
      savedInReturnReasonInputValue,
      procedureSaveInCategoryStatusID,
      initSavedInStatus,
      savedInReturnReasonLog,
    } = this.props.savedInProps;

    const {
      clientServerPathStatusID,
      serverPathValue,
      serverPathReturnReasonInputValue,
      clientServerPathReturnReason,
      serverPathReturnReasonLog,
    } = this.props.serverPathProps;

    const { currentRole } = this.props.sectionProps;

    const { procedureSavedInRef, procedureServerPathRef } = this.props.refs;

    const { savedInRadioValue } = this.props.savedInProps;

    return (
      <div className={styles.fileSectionTop}>
        <ReadOnlyProcedureProcessSavedIn
          ref={procedureSavedInRef}
          savedInReturnReasonInputValue={savedInReturnReasonInputValue}
          procedureSaveInCategoryStatusID={procedureSaveInCategoryStatusID}
          isSavedInReturnReasonDisabled={true}
          savedInRadioValue={savedInRadioValue}
          initSavedInStatus={initSavedInStatus}
          savedInReturnReasonLog={savedInReturnReasonLog}
        />
        {savedInRadioValue !== savedInRadioValues.systemOnly && (
          <ReadOnlyProcedureProcessClientServerPath
            clientServerPathReturnReason={clientServerPathReturnReason}
            serverPathStatusID={clientServerPathStatusID}
            isServerPathReturnReasonDisabled={true}
            initServerPathStatus={
              this.props.initServerPathSectionState.clientServerPathStatusID
            }
            serverPathReturnReasonInputValue={serverPathReturnReasonInputValue}
            isServerPathReturned={clientServerPathStatusID === actions.return}
            currentRole={currentRole}
            ref={procedureServerPathRef}
            serverPathReturnReasonLog={serverPathReturnReasonLog}
            serverPathValue={serverPathValue}
          />
        )}
      </div>
    );
  };
}
