import BaseComponent from "../../../base/common/BaseComponent";
import AppSetting from "../../../config/AppSetting";
import ProcedureDetailService from "../../../service/procedureFile/ProcedureDetailService";
import {
  getTimeZoneString,
  handleAlertUploadFileError,
  procedureStatusValues,
} from "../../publicDictionaryValues";

export default class FileSectionBase extends BaseComponent {
  constructor(props) {
    super(props);
    this.user = this.appSetting.localStorageValue.user.get();
  }

  setUploadFileNum = () => {
    //this is a virtual function
  };

  uploadFileOnSuccess = (result, returnPara) => {
    //this is a virtual function
  };

  handleUploadLoading = () => {
    //this is a virtual function
  };

  setFileNum = () => {
    //this is a virtual function
  };

  createFiles = () => {
    //this is a virtual function
  };

  setReturnDataList = () => {
    //this is a virtual function
  };

  uploadFileOnError = (result) => {
    this.handleUploadLoading();
    console.log(result);
  };

  _saveError = (result) => {
    console.log(result);
    this._alertError("Save Failed");
  };

  _submitError = (result) => {
    console.log(result);
    this._alertError("Submit Failed");
  };

  hideScrollModal = () => {
    this.setState({
      scrollModalContent: "",
      scrollModalTitle: "",
      isScrollModalOpen: false,
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  handleResetLock = (actionType) => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: actionType,
      setLock: false,
      timeOutMins: 20,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        const timer = setTimeout(() => {
          window.location.reload();
        }, 0);
        return () => clearTimeout(timer);
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  handleResetLockWithoutRefresh = (actionType) => {
    const para = {
      procedureId: this.props.procedureId,
      procedureSectionType: actionType,
      setLock: false,
      timeOutMins: 20,
    };
    ProcedureDetailService.setSectionLock(
      para,
      () => {
        //this.is an empty function
      },
      (response) => {
        console.log(response.message);
      }
    );
  };

  getProcedureStatus = (statusId) => {
    return procedureStatusValues[statusId];
  };

  getNewDataList = (datalist) => {
    if (datalist !== undefined) {
      const dataListFilter = datalist.filter((item) => item !== undefined);
      let newDataList = [];
      if (dataListFilter.length > 0) {
        newDataList = dataListFilter.map((item) => {
          return item.data.reduce((result, { name, value }) => {
            result[name] = value;
            return result;
          }, {});
        });
      }
      return newDataList;
    } else {
      return [];
    }
  };

  checkDeleteDatalist = (datalist, lastSavedDataList) => {
    return datalist.map((item, index) => {
      const fileId = item.data.find((x) => x.name === "fileId").value;
      const existLine = lastSavedDataList.some((line) => {
        const savedId = line.data.find((x) => x.name === "fileId").value;
        return savedId === fileId;
      });

      if (existLine) {
        return {
          ...item,
          data: item.data.map((x) => {
            if (x.name === "fileOperate") {
              return { name: "fileOperate", value: 3 };
            }

            return x;
          }),
        };
      }
    });
  };

  checkUpdateDatalist = (datalist, lastSavedDataList) => {
    return datalist.map((item) => {
      const fileId = item.data.find((x) => x.name === "fileId").value;
      const existLine = lastSavedDataList.some((line) => {
        const savedId = line.data.find((x) => x.name === "fileId").value;
        return savedId === fileId;
      });

      if (existLine) {
        return {
          ...item,
          data: item.data.map((x) => {
            if (x.name === "fileOperate") {
              return { name: "fileOperate", value: 2 };
            }

            return x;
          }),
        };
      }
      return item;
    });
  };

  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    dataList.unshift({
      data: [
        { name: "lineId", value: lineId },
        { name: "fileId", value: fileId },
        { name: "fileKey", value: fileKey },
        {
          name: "fileName",
          value: fileName,
        },
        {
          name: "uploadedDate",
          value: this._formateDate(getTimeZoneString(this._getDate())),
        },
        { name: "uploadByName", value: this.user.identityUserInfo.englishName },
        { name: "uploadBy", value: this.user.identityUserInfo.internalUserID },
        { name: "note", value: "" },
        { name: "approveDate", value: "" },
        { name: "fileOperate", value: 1 },
      ],
      checked: false,
    });
  };

  hideModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  getAddFileDto = (item) => {
    return {
      FileName: item.name,
      ContainerName: AppSetting.urlPrefix.addFile.ContainerName,
      AccountName: AppSetting.urlPrefix.addFile.AzureAccountName,
      ProductKey: AppSetting.urlPrefix.addFile.ProductKey,
      FolderName: AppSetting.urlPrefix.addFile.ProcedureFileFolder,
    };
  };

  uploadFile = (fileIndex, ref) => {
    let fileForm = new FormData();
    let addFileDto = this.getAddFileDto(this.fileList[fileIndex]);
    fileForm.append("formFile", this.fileList[fileIndex]);
    fileForm.append("addFileDto", JSON.stringify(addFileDto));
    let returnPara = {
      fileName: this.fileList[fileIndex].name,
      fileIndex: fileIndex,
    };

    this.setUploadFileNum();

    ProcedureDetailService.addFile(
      fileForm,
      (result) => this.uploadFileOnSuccess(result, returnPara, ref),
      this.uploadFileOnError,
      returnPara
    );
  };

  handleFileSelect = (files) => {
    this.props.showLoading();
    this.setFileNum();
  };

  FileProcess = (index, dataList, tableDataList, ref) => {
    if (index >= this.fileList.length) {
      this.fileList.length > 0 && this.uploadFile(0, ref);
      if (this.replaceList.length) {
        ref.current.handleReplaceModalConfirm(this.replaceList);
        this.replaceList = [];
      }
      return;
    }
    if (
      dataList.findIndex(
        (dataItem) => dataItem.value === this.fileList[index].name
      ) !== -1
    ) {
      this.setState({
        isModalOpen: true,
        modalChildContent: `${this.fileList[index].name} already exists. Do you want to replace it?`,
        modalOkText: "Replace",
        modalCancelText: "Skip",
        handleModalConfirm: () => {
          const dataIndex = tableDataList.findIndex(
            (dataItem) =>
              dataItem.data.findIndex(
                (item) => item.value === this.fileList[index].name
              ) !== -1
          );

          if (dataIndex !== -1) {
            this.replaceList.push(tableDataList[dataIndex]);
            tableDataList.splice(dataIndex, 1);
          }

          ref.current.updateDataList(tableDataList);
          this.hideModal();
          this.handleFileSelect([this.fileList[index]]);
          this.FileProcess(index + 1, dataList, tableDataList, ref);
        },

        handleModalCancel: () => {
          this.fileList.splice(index, 1);
          this.hideModal();
          setTimeout(() =>
            this.FileProcess(index, dataList, tableDataList, ref)
          );
        },
      });
    } else {
      this.handleFileSelect([this.fileList[index]]);
      this.FileProcess(index + 1, dataList, tableDataList, ref);
    }
  };

  /**
   * click upload button to upload file for update process
   * @param {Number} index
   * @param {Array} dataList
   * @param {Array} tableDataList
   * @param {obj} ref
   * @returns
   */
  updateFileProcess = (index, dataList, tableDataList, ref) => {
    let isDuplicate = false;
    let duplicateIndex = 0;
    if (index >= this.fileList.length) {
      this.fileList.length > 0 && this.uploadFile(0, ref);
      return;
    }
    this.fileList.forEach((item, index) => {
      if (
        dataList.findIndex((dataItem) => dataItem.value === item.name) !== -1
      ) {
        isDuplicate = true;
        duplicateIndex = index;
      }
    });
    if (isDuplicate) {
      this._alertError(`${this.fileList[duplicateIndex].name} already exists.`);
    } else {
      this.handleFileSelect([this.fileList[index]]);
      this.updateFileProcess(index + 1, dataList, tableDataList, ref);
    }
  };

  handleReplaceFile = (event, ref) => {
    this.fileList = [...event.target.files];
    if (handleAlertUploadFileError(this.fileList)) {
      return;
    }
    const tableDataList = ref.current.getDataList();
    let dataList = [];
    tableDataList.map((data) => {
      const item = data.data.find((dataItem) => dataItem.name === "fileName");
      dataList.push(item);
    });

    this.FileProcess(0, dataList, tableDataList, ref);
  };
}
