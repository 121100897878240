import { createRef } from "react";
import {
  fileTags,
  getTimeZoneString,
} from "../../../../../publicDictionaryValues";
import ReviseProcedureFileManagement from "../../../../procedureFileSections/fields/procedureFileManagementFields/reviseProcedureFileManagement";
import reasonEditDefaultIcon from "../../../../../../assets/image/reasonEditIcon.png";

const procedureReplaceFileRef = createRef();
export default class ReviseSupportingFileManagement extends ReviseProcedureFileManagement {
  /* function about add data in table when upload files */
  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    const newFileItme = {
      data: [
        { name: "lineId", value: lineId },
        { name: "fileId", value: fileId },
        { name: "supportingFileID", value: 0 },
        {
          name: "supportingFileStepID",
          value: null,
        },
        { name: "fileKey", value: fileKey },
        {
          name: "fileName",
          value: fileName,
          tagValue: fileTags.new,
        },
        {
          name: "uploadedDate",
          value: this._formateDate(getTimeZoneString(this._getDate())),
        },
        { name: "uploadByName", value: this.user.identityUserInfo.englishName },
        {
          name: "uploadById",
          value: this.user.identityUserInfo.internalUserID,
        },
        { name: "note", value: null, isDisabled: false },
        { name: "status", value: null },
        {
          name: "returnReason",
          value: null,
          isDisabled: true,
          isShowReasonLog: false,
        },
        {
          name: "attachment",
          value: null,
          isDisabled: false,
          link: null,
          fileId: null,
        },
        { name: "newVersion", value: null, link: null, fileId: null },
        {
          name: "previousStatus",
          value: null,
        },
        {
          name: "previousFileTag",
          value: null,
        },
        {
          name: "isReviewed",
          value: false,
        },
      ],
      checked: false,
      isApproveDisabled: false,
      isReturnDisabled: false,
      isReturned: false,
      isError: false,
      replaceRef: procedureReplaceFileRef,
      attachmentEditIcon: reasonEditDefaultIcon,
      isDelete: false,
      isUpdate: false,
    };
    dataList.unshift(newFileItme);
  };

  /**
   * return share button in table
   */
  handleReturnShareBtn = () => {
    return null;
  };
}
