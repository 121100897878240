import React, { Component } from "react";
import requestAnimationFrameExact from "./requestAnimationFrameExact.js";
import PropTypes from "prop-types";

class TransitionWhenHeightChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: { height: props.height },
      placeholderHeight: null,
    };

    this.el = { current: null };
  }

  UNSAFE_componentWillReceiveProps(nextP) {
    if (nextP.height !== this.props.height) {
      // fold
      if (nextP.height) {
        this.setState(
          {
            style: {
              height: this.el.current.clientHeight,
            },
          },
          this.afterCollapse
        );
      }
      // open
      else {
        this.setState(
          {
            placeholderHeight: this.el.current.clientHeight,
            style: {
              height: null,
            },
          },
          this.afterExpand
        );
      }
    }
  }
  afterExpand() {
    requestAnimationFrameExact(() => {
      const height = this.el.current.clientHeight;
      this.setState(
        {
          placeholderHeight: null,
          style: {
            height: this.state.placeholderHeight,
          },
        },
        () => {
          requestAnimationFrameExact(() => {
            this.setState({
              style: {
                height: height,
                transitionDuration: this.props.duration + "ms",
              },
            });
          });
        }
      );
    });
  }
  afterCollapse() {
    requestAnimationFrameExact(() => {
      this.setState({
        style: {
          height: this.props.height,
        },
      });
    });
  }
  render() {
    const state = this.state;
    return (
      <div
        style={{
          height: state.placeholderHeight,
          overflowY: "auto",
          borderRadius: "4px",
          border: "1px solid #e2e2e2",
        }}
      >
        <div
          ref={this.el}
          className="autoHeightChangeContainer"
          style={Object.assign(
            {
              transitionProperty: "height",
              overflow: this.props.overflow,
              transitionDuration: this.props.duration + "ms",
              transitionTimingFunction: this.props.timeFunction,
            },
            this.state.style
          )}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

TransitionWhenHeightChange.defaultProps = {
  duration: 300,
  timeFunction: "ease",
  overflow: "hidden",
};

TransitionWhenHeightChange.propTypes = {
  duration: PropTypes.number,
  timeFunction: PropTypes.string,
  overflow: PropTypes.string,
};

export default TransitionWhenHeightChange;
