export default function MyRequestListColumnSelectOption() {
  const myRequestListColumnSelectOption = [
    {
      label: "PMP Transaction ID",
      value: "transactionIdShow",
    },
    {
      label: "Account Code",
      value: "accountAbbrShow",
    },
    {
      label: "Account Name",
      value: "accountNameShow",
    },
    {
      label: "Task Group",
      value: "taskGroupShow",
    },
    {
      label: "Category",
      value: "categoryShow",
    },
    {
      label: "Task ID",
      value: "taskIdShow",
    },
    {
      label: "Task Label Name",
      value: "taskLabelNameShow",
    },
    {
      label: "Highly Regulated?",
      value: "highlyRegulatedShow",
    },
    {
      label: "Process Category",
      value: "processCategoryShow",
    },
    {
      label: "Trigger Process",
      value: "triggerProcessShow",
    },
    {
      label: "Process Status",
      value: "processStatusShow",
    },
    {
      label: "Current Processor",
      value: "currentProcessorShow",
    },
    {
      label: "Process Initiate Date",
      value: "processInitiateDateShow",
    },
    {
      label: "Process Initiator",
      value: "processInitiatorShow",
    },
    {
      label: "Process Completed Date",
      value: "processCompletedDateShow",
    },
  ];

  return myRequestListColumnSelectOption;
}

export function MyRequestListColumnSelectValue() {
  const columnSelectValue = [
    "transactionIdShow",
    "processCategoryShow",
    "accountAbbrShow",
    "taskGroupShow",
    "categoryShow",
    "taskLabelNameShow",
    "taskIdShow",
    "triggerProcessShow",
    "processStatusShow",
    "currentProcessorShow",
    "processInitiateDateShow",
    "processInitiatorShow",
    "processCompletedDateShow",
  ];

  return columnSelectValue;
}
