import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import globalReducer, { watcherGlobalSaga } from "./global";
const LogoutType = "LogoutType";
const defaultState = {
  global: {
    test: "",
  },
};

const appReducer = combineReducers({
  global: globalReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === LogoutType) {
    return defaultState;
  }
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([watcherGlobalSaga()]);
}
