import React, { createRef } from "react";
import NormalSpin from "../../../base/basicComponents/spin";
import tlReviewService from "../../../service/tlReviewService";
import {
  dataStatus,
  actions,
} from "../../publicDictionaryValues";
import reasonEditDefaultIcon from "../../../assets/image/reasonEditIcon.png";
import styles from "../procedureFileProcess/style/index.module.scss";
import ProcessBase from "../../commonComponents/processBase/index.jsx";
import TlReviewPending from "./tlReviewPendingPage";
import TlReviewComplete from "./tlReviewCompletePage";

const procedureReplaceFileRef = createRef();
const procedureRef = createRef();
const procedureServerPathRef = createRef();
const procedureSavedInRef = createRef();
const approvePageRef = createRef();

export default class TlReviewProcess extends ProcessBase {
  constructor(props) {
    super(props);
    this.setGlobalVariables();
    this.initTlReviewProcedureFileState();
    this.procedureFileStepID = null;
    this.savedInReturnReasonLog = [];
    this.serverPathReturnReasonLog = [];
    this.initProcedureTableData = [];
  }

  handleTlReviewPageState = () => {
    return {
      isSaveDisabled: false,
      isCancelDisabled: false,
      isSubmitDisabled: false,
      isRejectDisabled: false,
      procedureID: null,
      procedureProcessId: null,
      procedureStepID: null,
      procedureStatus: null,
      requestByID: null,
      requestByName: null,
      receiveDate: null,
      procedureStepStatus: null,
      uploadProcedureFileNum: 0,
      procedureFileNum: 0,
      currentRole: null,
      actionName: null,
      loading: false,
      rejectReason: null,
      rejectStatus: null,
      procedureFolder: {},
      procedureNote: "",
    };
  };

  initTlReviewProcedureFileState = () => {
    this.state = {
      pageState: this.handleTlReviewPageState(),
      initTableDate: {},
      isReturnReasonLogOpen: false,
      ppoList: [],
      supportingTableData: [],
      jbProcedureSavedIn: null,
      fileTableData: [],
      taskInfoData: {},
      procedureInfoData: {},
      procedureFileData: {},
      shareTaskData: {},
      tlReviewData: {},
      savedInSectionState: this.initSavedInSectionState,
      serverPathSectionState: this.initServerPathSectionState,
      procedureFileTableState: this.initProcedureFileTableState,
    };
  };

  setTlReviewSavedInSectionState = (result) => {
    return {
      ...this.state.savedInSectionState,
      isSavedInApproveBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.approval,
      isSavedInReturnBtnDisabled:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
      procedureSaveInCategoryStatusID:
        result.procedureFolder?.procedureSaveInCategoryStatusID || null,
      savedInReturnReasonInputValue:
        result.procedureFolder?.procedureSaveInCategoryReturnReason || null,
      savedInRadioValue:
        result.procedureFolder?.procedureSaveInCategoryID || null,
      isSavedInReturned:
        result.procedureFolder?.procedureSaveInCategoryStatusID ===
        dataStatus.return,
    };
  };

  setTlReviewServerPath = (result) => {
    return {
      ...this.state.serverPathSectionState,
      isServerPathReturned:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue:
        result.procedureFolder?.clientServerPathReturnReason || null,
      serverPathValue: result.procedureFolder?.clientServerPath || null,
      isServerPathApproveBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID ===
        dataStatus.approval,
      isServerPathReturnBtnDisabled:
        result.procedureFolder?.clientServerPathStatusID === dataStatus.return,
      clientServerPathStatusID:
        result.procedureFolder?.clientServerPathStatusID || null,
      clientServerPathStatusName:
        result.procedureFolder?.clientServerPathStatusName || null,
      clientServerPath: result.procedureFolder?.clientServerPath || "",
    };
  };

  setTlReviewSavedInServerPathState = (result) => {
    this.setState({
      savedInSectionState: this.setTlReviewSavedInSectionState(result),
      serverPathSectionState: this.setTlReviewServerPath(result),
    });
  };

  setTlReviewSavedInReturnReasonLog = (item, index) => {
    if (
      item.procedureSaveInCategoryStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.savedInReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.procedureSaveInCategoryReturnReason,
      });
    }
  };

  setTlReviewServerPathReturnReasonLog = (item, index) => {
    if (
      item.clientServerPathStatusID === dataStatus.return &&
      item.actionID === actions.return
    ) {
      this.serverPathReturnReasonLog.push({
        key: index,
        returnedBy: item.auditedByName,
        returnDate: this._formateDate(item.modifiedDate),
        returnReasonColumn: item.clientServerPathReturnReason,
      });
    }
  };

  setTlReviewSavedInAndServerPathReturnReasonLog = (list) => {
    list.sort((a, b) => {
      return b.modifiedDate.localeCompare(a.modifiedDate);
    });
    list.forEach((item, index) => {
      this.setTlReviewSavedInReturnReasonLog(item, index);
      this.setTlReviewServerPathReturnReasonLog(item, index);
    });
  };

  initTlReviewServerPathSectionState = (result) => {
    this.initServerPathSectionState = {
      ...this.initServerPathSectionState,
      ...this.buildTlReviewServerPathSectionState(result.procedureFolder),
    };
  };

  initTlReviewSavedInSectionState = (result) => {
    this.initSavedInSectionState = {
      ...this.initSavedInSectionState,
      ...this.buildTlReviewSavedInSectionState(result.procedureFolder),
    };
  };

  initTlReviewPrimitiveServerPathSectionState = (lastStepHistoryData) => {
    this.primitiveServerPathSectionState = {
      ...this.primitiveServerPathSectionState,
      ...this.buildTlReviewServerPathSectionState(lastStepHistoryData),
    };
  };

  initTlReviewPrimitiveSavedInSectionState = (lastStepHistoryData) => {
    this.primitiveSavedInSectionState = {
      ...this.primitiveSavedInSectionState,
      ...this.buildTlReviewSavedInSectionState(lastStepHistoryData),
    };
  };

  setInitTlReviewProcedureTableData = () => {
    this.procedureTableData.forEach((item) => {
      this.initProcedureTableData.push({
        data: JSON.parse(JSON.stringify(item.data)),
        checked: item.checked,
        isApproveDisabled: item.isApproveDisabled,
        isReturnDisabled: item.isReturnDisabled,
        isReturned: item.isReturned,
        isError: item.isError,
        replaceRef: item.replaceRef,
        attachmentEditIcon: reasonEditDefaultIcon,
        isDelete: false,
        isUpdate: false,
      });
    });
  };

  setTlReviewProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      const historyList = result.procedureFolder?.stepHistoryList.sort((x, y) =>
        y.modifiedDate.localeCompare(x.modifiedDate)
      );

      this.initTlReviewServerPathSectionState(result);

      this.initTlReviewSavedInSectionState(result);

      if (historyList.length !== 0) {
        const lastStepHistoryData = historyList[0];
        this.initTlReviewPrimitiveServerPathSectionState(lastStepHistoryData);
        this.initTlReviewPrimitiveSavedInSectionState(lastStepHistoryData);
      }

      this.procedureFileStepID = result.procedureFolder.procedureFileStepID;
      this.setProcedureTableData(result.procedureFolder);
      this.setInitTlReviewProcedureTableData();

      this.setState({
        fileTableData: this.procedureTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });

      this.setTlReviewSavedInAndServerPathReturnReasonLog(
        result.procedureFolder.stepHistoryList
      );
      this.setTlReviewSavedInServerPathState(result);
    }
  };

  setProcedureFileData = (result) => {
    this.setTlReviewProcedureFileData(result);
  };

  /**
   * Build SaveIn Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildTlReviewSavedInSectionState = (data) => {
    return {
      savedInReturnReasonInputValue: data.procedureSaveInCategoryReturnReason,
      savedInRadioValue: data.procedureSaveInCategoryID,
      isSavedInReturned:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
      procedureSaveInCategoryStatusID: data.procedureSaveInCategoryStatusID,
      isSavedInApproveBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.approval,
      isSavedInReturnBtnDisabled:
        data.procedureSaveInCategoryStatusID === dataStatus.return,
    };
  };

  /**
   * Build ServerPath Section Data From ProcedureFolder
   * @param {*} data
   * @param {*} isRevise
   * @returns
   */
  buildTlReviewServerPathSectionState = (data) => {
    return {
      ...this.primitiveServerPathSectionState,
      serverPathValue: data.clientServerPath,
      isServerPathReturned: data.clientServerPathStatusID === dataStatus.return,
      serverPathReturnReasonInputValue: data.clientServerPathReturnReason,
      clientServerPathStatusID: data.clientServerPathStatusID,
      clientServerPathStatusName: data.clientServerPathStatusName,
      clientServerPath: data.clientServerPath,
      isServerPathApproveBtnDisabled:
        data.clientServerPathStatusID === dataStatus.approval,
      isServerPathReturnBtnDisabled:
        data.clientServerPathStatusID === dataStatus.return,
    };
  };

  setSupportingFileData = (result) => {
    this.setSupportingTableData(result);
    this.setState({
      supportingTableData: this.supportingTableData,
    });
  };

  componentDidMount() {
    this.getInitData(
      tlReviewService.getTLReviewStepItem,
      "Get Procedure Files Failed"
    );
  }

  /* function about get data from procedure file table */
  tlReviewReturnFileDataValue = (name, index) => {
    const procedureFileData = procedureRef.current.getDataList();
    let deleteList = procedureRef.current.getDeleteList();
    let combineDatalist = procedureFileData.concat(deleteList);
    return combineDatalist[index].data.find((item) => item.name === name);
  };

  setTlReviewServerPathObj = () => {
    let serverPathValue = null;
    let serverPathStatus = null;
    let serverPathReturnReasonInputValue = null;

    if (procedureServerPathRef.current !== null) {
      const serverPathState =
        procedureServerPathRef.current.state.serverPathSectionState;
      serverPathValue = serverPathState.serverPathValue;
      serverPathStatus = serverPathState.serverPathStatus;
      serverPathReturnReasonInputValue =
        serverPathState.serverPathReturnReasonInputValue;
    }

    return {
      serverPathReturnReasonInputValue: serverPathReturnReasonInputValue,
      serverPathValue: serverPathValue,
      serverPathStatus: serverPathStatus,
    };
  };

  tlReviewSubmitDataPara = (fileDtos, actionID, actionName) => {
    const serverPathObj = this.setTlReviewServerPathObj();

    const {
      savedInRadioValue,
      procedureSaveInCategoryStatusID,
      savedInReturnReasonInputValue,
    } = procedureSavedInRef.current.state.savedInSectionState;
    return {
      procedureId: this.procedureId,
      workflowProcessInstanceId: this.workflowProcessInstanceId,
      procedureSaveInCategoryID: savedInRadioValue,
      procedureSaveInCategoryStatusID: procedureSaveInCategoryStatusID,
      procedureSaveInCategoryReturnReason: savedInReturnReasonInputValue,
      flowCategoryID: this.flowCategoryID,
      procedureFileStepID: this.procedureFileStepID,
      rejectReason:
        approvePageRef.current === null
          ? null
          : approvePageRef.current.state.pageState.rejectReason,
      procedureStepID: this.procedureStepID,
      clientServerPath: serverPathObj.serverPathValue,
      clientServerPathStatusID: serverPathObj.serverPathStatus,
      clientServerPathReturnReason:
        serverPathObj.serverPathReturnReasonInputValue,
      actionName: actionName,
      actionID: actionID,
      accountID: null,
      isInitial: this.isRevisePage,
      procedureFiles: fileDtos,
    };
  };

  /* function about return submit data to use in integrate trigger api */
  handleReturnSubmitData = (actionID, actionName) => {
    const fileDtos = [];
    if (procedureRef.current !== null) {
      const procedureFileData = procedureRef.current.getDataList();
      let deleteList = procedureRef.current.getDeleteList();
      let combineDatalist = procedureFileData.concat(deleteList);
      combineDatalist.forEach((item, index) =>
        fileDtos.push({
          fileID:
            this.tlReviewReturnFileDataValue("newVersion", index).fileId ===
            null
              ? this.tlReviewReturnFileDataValue("fileId", index).value
              : this.tlReviewReturnFileDataValue("newVersion", index).fileId,
          createdDate: this.tlReviewReturnFileDataValue("uploadedDate", index)
            .value,
          uploadBy: this.tlReviewReturnFileDataValue("uploadById", index).value,
          uploadByName: this.tlReviewReturnFileDataValue("uploadByName", index)
            .value,
          fileName:
            this.tlReviewReturnFileDataValue("newVersion", index).value === null
              ? this.tlReviewReturnFileDataValue("fileName", index).value
              : this.tlReviewReturnFileDataValue("newVersion", index).value,
          fileKey: this.tlReviewReturnFileDataValue("fileKey", index).value,
          dataStatusID: this.tlReviewReturnFileDataValue("status", index).value,
          attachmentFileID: this.tlReviewReturnFileDataValue(
            "attachment",
            index
          ).fileId,
          attachmentName: this.tlReviewReturnFileDataValue("attachment", index)
            .value,
          returnReason: this.tlReviewReturnFileDataValue("returnReason", index)
            .value,
          fileTagID: this.tlReviewReturnFileDataValue("fileName", index)
            .tagValue,
          procedureFileStepID: this.tlReviewReturnFileDataValue(
            "procedureFileStepID",
            index
          ).value,
          procedureFileID: this.tlReviewReturnFileDataValue(
            "procedureFileID",
            index
          ).value,
          note: this.tlReviewReturnFileDataValue("note", index).value,
          oldFileID:
            this.tlReviewReturnFileDataValue("newVersion", index).fileId ===
            null
              ? null
              : this.tlReviewReturnFileDataValue("fileId", index).value,
          oldFileName:
            this.tlReviewReturnFileDataValue("newVersion", index).value === null
              ? null
              : this.tlReviewReturnFileDataValue("fileName", index).value,
          isDelete: item.isDelete,
          isUpdate: item.isUpdate,
        })
      );
    }

    return this.tlReviewSubmitDataPara(fileDtos, actionID, actionName);
  };

  handleTlReviewPageProps = () => {
    const {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureProcessId,
    } = this.state.pageState;

    return {
      receiveDate,
      requestByName,
      procedureStepStatus,
      procedureStatus,
      rejectStatus,
      currentRole,
      procedureReplaceFileRef,
      procedureProcessId,
    };
  };

  handleTlReviewSectionProps = () => {
    const { taskInfoData, procedureInfoData } = this.state;

    return {
      taskInfoData,
      procedureInfoData,
      ppoList: this.state.ppoList,
    };
  };

  handleTlReviewSavedInProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
    } = this.state.savedInSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
    };
  };

  handleTlReviewServerPathProps = () => {
    const {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
    } = this.state.savedInSectionState;
    const { clientServerPath } = this.state.serverPathSectionState;

    return {
      savedInReturnReasonInputValue,
      savedInReturnReasonValue,
      savedInReasonStatus,
      procedureSaveInCategoryStatusID,
      savedInRadioValue,
      initSavedInStatus:
        this.initSavedInSectionState.procedureSaveInCategoryStatusID,
      savedInReturnReasonLog: this.savedInReturnReasonLog,
      serverPathValue: clientServerPath,
    };
  };

  handleTlReviewParams = () => {
    return {
      ProcedureTaskId: this.taskID,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
      ProcedureFileStepID: this.procedureFileStepID,
      WorkflowProcessInstanceId: this.workflowProcessInstanceId,
      FlowCategoryID: this.flowCategoryID,
    };
  };

  handleTlReviewPendingPage = (procedureTableData) => {
    const { shareTaskData, tlReviewData, jbProcedureSavedIn } = this.state;

    return (
      <TlReviewPending
        params={this.handleTlReviewParams()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        shareTaskData={shareTaskData}
        tlReviewData={tlReviewData}
        pageProps={this.handleTlReviewPageProps()}
        sectionProps={this.handleTlReviewSectionProps()}
        savedInProps={this.handleTlReviewSavedInProps()}
        serverPathProps={this.handleTlReviewServerPathProps()}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.tlReviewReturnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        closePage={this.closePage}
        jbProcedureSavedIn={jbProcedureSavedIn}
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  handleTlReviewCompletePage = (procedureTableData) => {
    const { shareTaskData, tlReviewData, jbProcedureSavedIn } = this.state;

    return (
      <TlReviewComplete
        _showLoading={this._showLoading}
        _hideLoading={this._hideLoading}
        closePage={this.closePage}
        primitiveSavedInSectionState={this.primitiveSavedInSectionState}
        primitiveServerPathSectionState={this.primitiveServerPathSectionState}
        pageProps={{ ...this.handleTlReviewPageProps(), isDisableAll: true }}
        sectionProps={this.handleTlReviewSectionProps()}
        savedInProps={this.handleTlReviewSavedInProps()}
        serverPathProps={this.handleTlReviewServerPathProps()}
        initSavedInSectionState={this.initSavedInSectionState}
        initServerPathSectionState={this.initServerPathSectionState}
        initProcedureFileTableState={this.initProcedureFileTableState}
        initProcedureTableData={[...this.initProcedureTableData]}
        fileTableData={procedureTableData}
        supportingTableData={this.state.supportingTableData}
        shareTaskData={shareTaskData}
        tlReviewData={tlReviewData}
        params={this.handleTlReviewParams()}
        updateProcedureFileNum={this.updateProcedureFileNum}
        returnFileDataValue={this.tlReviewReturnFileDataValue}
        handleReturnSubmitData={this.handleReturnSubmitData}
        jbProcedureSavedIn={jbProcedureSavedIn}
        procedureRef={procedureRef}
        procedureSavedInRef={procedureSavedInRef}
        procedureServerPathRef={procedureServerPathRef}
        setProcedureFileData={this.setProcedureFileData}
        setSupportingFileData={this.setSupportingFileData}
        setTaskInfoData={this.setTaskInfoData}
        setProcedureInfoData={this.setProcedureInfoData}
        setShareTaskData={this.setShareTaskData}
      />
    );
  };

  setTlReviewProcedureTableData = () => {
    return JSON.parse(JSON.stringify(this.state.fileTableData));
  };

  render() {
    const {
      loading,
      procedureStepStatus,
      procedureFileNum,
      uploadProcedureFileNum,
    } = this.state.pageState;

    const isPendingPage = procedureStepStatus === "Pending";
    const isCompletePage = procedureStepStatus === "Complete";

    const procedureTableData = this.setTlReviewProcedureTableData();

    return (
      <NormalSpin
        spinning={loading}
        size="large"
        indicator={
          procedureFileNum > 0 ? (
            <p className={styles.loadingText}>
              uploading {uploadProcedureFileNum}/{procedureFileNum} file
            </p>
          ) : undefined
        }
        children={
          <>
            {isPendingPage &&
              this.handleTlReviewPendingPage(procedureTableData)}
            {isCompletePage &&
              this.handleTlReviewCompletePage(procedureTableData)}
          </>
        }
      />
    );
  }
}
