import { React, createRef } from "react";
import { Modal } from "antd";
import BaseComponent from "../../../../base/common/BaseComponent";
import TlReviewProcedureSection from "../TlReviewProcedureSection";
import "../tlReview.scss";

const tlReviewProcedureSectionRef = createRef();
class TlReviewProcedureModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { radioValue: null, submitDisabled: true };
  }

  showInputError = () => {
    tlReviewProcedureSectionRef.current.showInputError();
  };

  setDefault = () => {
    tlReviewProcedureSectionRef.current.setDefault();
  };

  updateFileNumLoading = () => {};

  render() {
    const {
      isModalOpen,
      onSubmit,
      onCancel,
      showLoading,
      hideLoading,
      zIndex,
    } = this.props;

    return (
      <Modal
        title={null}
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        onCancel={onCancel}
        width="75rem"
        className="tlReviewModal"
        zIndex={zIndex}
        maskClosable={false}
      >
        <TlReviewProcedureSection
          ref={tlReviewProcedureSectionRef}
          onSubmit={onSubmit}
          onCancel={onCancel}
          showLoading={showLoading}
          hideLoading={hideLoading}
          isInModal={true}
        />
      </Modal>
    );
  }
}

export default TlReviewProcedureModal;
