import { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import FileSectionBase from "../../../commonComponents/fileBase";
import createProcedureTableData from "../../procedureFileSections/createProcedureTableData";
import NormalModal, {
  ModalWithScroll,
} from "../../../../base/basicComponents/modal";
import { timeOutMins } from "../../../../base/basicComponents/modal/controlPageBehavior";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import "../../../pages/procedureDetail/createProcedureDetail/style/index.scss";
import {
  detailTableSortFile,
  fileTags,
  getTimeZoneString,
  handleAlertUploadFileError,
  handleUploadErrorCode,
  pageStatus,
  proposedChangeTags,
} from "../../../publicDictionaryValues";
import UpdateSupportingFileManagement from "../fields/supportingFileManagement/updateSupportingFileManagement";
import BaseSupportingFileManagement from "../fields/supportingFileManagement/baseSupportingFileManagement";

const ref = createRef();
const supportingFileRef = createRef();
const supportingModalRef = createRef();
const supportingFileTableRef = createRef();
const supportingFileModalTableRef = createRef();

export default class SupportingFileSection extends FileSectionBase {
  constructor(props) {
    super(props);
    this.state = {
      supportingSubmited: false,
      supportingSaved: false,
      initSupportingDataList: [],
      isModalOpen: false,
      modalChildContent: "",
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      isScrollModalOpen: false,
      scrollModalContent: "",
      scrollModalTitle: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
      isSupportingFileEditToolTipOpen: false,
      supportingFileStepId: null,
      status: 0,
      isMount: false,
      supportingPendingProcessId: null,
    };
    this.replaceList = [];
    this.fileList = [];
  }

  /**
   * get files
   * @returns
   */
  getFileList = () => {
    return this.fileList;
  };

  /**
   * control upload file loading
   * @param {*} type
   */
  handleUploadLoading = (type) => {
    this.props.handleUploadLoading();
  };

  /**
   * update upload file loading number
   */
  setUploadFileNum = () => {
    this.props.setUploadFileNum();
  };

  /**
   * set total file number
   */
  setFileNum = () => {
    this.props.setFileNum();
  };

  /**
   * success callback for click save as draft button
   * @param {*} result
   */
  _supportingSaveSuccess = (result) => {
    this.setState({
      supportingSaved: true,
      supportingFileStepId: result.supportingFileStepId,
    });
    this.props.setIsSupportingFileModalChange();
    this.props.saveSuccess(result);
    this._alertSuccess("Save Successful");
    this.handleResetLock("SupportingFile");
  };

  /**
   * function about click save as draft button in supporting file modal
   * @param {*} ref
   */
  supportingFileOnSave = (ref) => {
    this.props.showLoading();
    let datalist = JSON.parse(JSON.stringify(ref.current.getDataList()));
    let lastSavedDataList = ref.current.getInitOrLastSaveData();
    datalist = this.checkUpdateDatalist(datalist, lastSavedDataList);
    let deleteList = ref.current.getDeleteList();
    let combineDatalist = datalist.concat(deleteList);
    let convertDataList = this.ConvertToBack(combineDatalist);

    ProcedureDetailService.saveSupprotingFileAsDraft(
      convertDataList,
      (response) => this._supportingSaveSuccess(response, ref),
      this._saveError
    );
  };

  findDataListDataItem = (name, DataItem) => {
    return DataItem.data.find((item) => item.name === name) === null ||
      DataItem.data.find((item) => item.name === name) === undefined
      ? ""
      : DataItem.data.find((item) => item.name === name).value;
  };

  getNewUpdateDataList = (datalist) => {
    const newDataList = [];
    datalist.forEach((item) => {
      const tagValue = item.data.find(
        (item) => item.name === "fileName"
      ).tagValue;
      const proposedChangeValue = this.findDataListDataItem(
        "proposedChange",
        item
      );
      const replaceUserId = this.findDataListDataItem("replaceUserId", item);
      const replaceUserName = this.findDataListDataItem(
        "replaceUserName",
        item
      );
      const uploadByValue =
        replaceUserId && replaceUserName
          ? replaceUserId
          : this.findDataListDataItem("uploadBy", item);
      newDataList.push({
        fileOperate: 1,
        fileId: this.findDataListDataItem("fileId", item),
        fileKey: this.findDataListDataItem("fileKey", item),
        fileName: this.findDataListDataItem("fileName", item),
        uploadBy: uploadByValue,
        note: this.findDataListDataItem("note", item),
        proposeChangeTagID: proposedChangeValue,
        fileTagID: tagValue,
        approvedDate:
          proposedChangeValue === null || proposedChangeValue === ""
            ? item.data.find((item) => item.name === "approveTime").value
            : null,
      });
    });
    return newDataList;
  };

  ConvertToBack = (datalist) => {
    let newDataList = this.getNewDataList(datalist);
    return {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      supportingFileStepId: this.state.supportingFileStepId,
      fileList: newDataList,
    };
  };

  UpdateCovertToBack = (datalist) => {
    let newDataList = this.getNewUpdateDataList(datalist);
    return {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      supportingFileStepId: this.state.supportingFileStepId,
      fileList: newDataList,
    };
  };

  /**
   * success callback for submit
   * @param {*} result
   */
  _supportingSubmitSuccess = (result) => {
    this.setState({
      supportingSubmited: true,
    });
    this.props.setIsSupportingFileModalChange();
    this.props.submitSuccess(result);
    this._alertSuccess("Submit Successful");
    this.handleResetLock("SupportingFile");
  };

  /**
   * close modal
   */
  hideAllModal = () => {
    this.setState({
      isModalOpen: false,
      modalChildContent: "",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  /**
   * function about click confirm in submit modal
   * @param {*} ref
   */
  handleSubmitSupportingModalConfirm = (ref) => {
    this.hideAllModal();
    this.props.showLoading();
    let datalist = JSON.parse(JSON.stringify(ref.current.getDataList()));
    let lastSavedDataList = ref.current.getInitOrLastSaveData();
    datalist = this.checkUpdateDatalist(datalist, lastSavedDataList);
    let deleteList = ref.current.getDeleteList();
    let combineDatalist = datalist.concat(deleteList);
    if (this.state.status === 3) {
      let convertDataList = this.UpdateCovertToBack(combineDatalist, true, ref);
      ProcedureDetailService.updateSupprotingFileForApproval(
        convertDataList,
        (response) => this._supportingSubmitSuccess(response, ref),
        this._submitError
      );
    } else {
      let convertDataList = this.ConvertToBack(combineDatalist, true, ref);
      ProcedureDetailService.submitSupprotingFileForApproval(
        convertDataList,
        (response) => this._supportingSubmitSuccess(response, ref),
        this._submitError
      );
    }
  };

  /**
   * error callback for submit check
   * @param {*} error
   */
  handleSupportingFileSubmitCheckError = (error) => {
    console.log(error);
    this._alertError("Check Failed!");
  };

  /**
   * success callback for submit check
   * @param {*} response
   * @param {*} ref
   */
  handleSupportingFileSubmitCheckSuccess = (response, ref) => {
    if (response.result) {
      this.setState({
        isModalOpen: true,
        modalOkText: "Confirm",
        modalCancelText: "Cancel",
        modalChildContent: "Are you sure to submit?",
        handleModalConfirm: () => this.handleSubmitSupportingModalConfirm(ref),
        handleModalCancel: () => {
          //there is a empty function
        },
      });
    } else {
      switch (response.messageCode) {
        case 21:
          this._alertError(
            "You cannot submit Create New Supporting File process while there is a Sharing Relationship approval in progress. Please save all as draft and try again later."
          );
          break;
        case 22:
          this._alertError(
            "You cannot submit Update Supporting File process while there is a Sharing Relationship approval in progress."
          );
          break;
        default:
          break;
      }
    }
  };

  /**
   * check whether there is a share task pending approval when click submit for approval button.
   * @param {*} ref
   */
  handleSubmitSupportingCheck = (ref) => {
    const param = {
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
    };
    if (this.state.status === 3) {
      ProcedureDetailService.supportingFileUpdateCheck(
        param,
        (response) =>
          this.handleSupportingFileSubmitCheckSuccess(response, ref),
        this.handleSupportingFileSubmitCheckError
      );
    } else {
      ProcedureDetailService.supportingFileSubmitCheck(
        param,
        (response) =>
          this.handleSupportingFileSubmitCheckSuccess(response, ref),
        this.handleSupportingFileSubmitCheckError
      );
    }
  };

  /**
   * function about click submit for approval in supporting file edit modal
   * @param {*} ref
   */
  supportingFileOnSubmit = (ref) => {
    if (ref.current !== null) {
      const dataList = ref.current.getDataList();
      if (dataList.length === 0) {
        ref.current.setFileTableStatus("error");
      } else {
        this.hideModal();
        this.handleSubmitSupportingCheck(ref);
      }
    }
  };

  /**
   * function about click confirm button in cancel modal
   * @param {*} ref
   */
  handleCancelSupportingModalConfirm = (ref) => {
    this.hideModal();
    this.hideScrollModal();
    const dataList = JSON.parse(
      JSON.stringify(ref.current.getInitOrLastSaveData())
    );
    ref.current.updateDataList(dataList);
    ref.current.cancelDeleteData();
    this.handleResetLockWithoutRefresh("SupportingFile");
    this.props.setActionType(null);
  };

  /**
   * function about click cancel button in supporting file edit modal
   * @param {*} ref
   */
  supportingFileOnCancel = (ref) => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: "Are you sure to quit without saving?",
      handleModalConfirm: () => this.handleCancelSupportingModalConfirm(ref),
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  createSupporting = (createFilesRef, response, customStatus, openModal) => {
    const newFiles = detailTableSortFile(
      response.supportingFiles,
      this._formateDate
    );
    const dataList =
      customStatus === 3
        ? createProcedureTableData.createUpdateSupportingData(
            newFiles,
            createFilesRef === "modal" ? null : createFilesRef
          )
        : createProcedureTableData.createSupportingData(
            newFiles,
            createFilesRef === "modal" ? null : createFilesRef
          );
    this.setState(
      {
        initSupportingDataList: JSON.parse(JSON.stringify(dataList)),
        supportingFileStepId: response.supportingFileStepId,
      },
      () => openModal && openModal()
    );
    this.props.hideLoading();
  };

  /**
   * create files after get files
   * @param {*} createFilesRef
   */
  createFiles = (createFilesRef = ref, openModal) => {
    this.props.showLoading();
    const procedureId = this.props.procedureId;
    const procedureTaskId = this.props.procedureTaskId;
    ProcedureDetailService.getSupportingFileSection(
      { procedureId: procedureId, procedureTaskId: procedureTaskId },
      (response) => {
        let customStatus = 0;
        switch (response.processStatusID) {
          case pageStatus.draft:
            customStatus = 1;
            break;
          case pageStatus.pendingApproval:
            customStatus = 2;
            break;
          case pageStatus.active:
            customStatus = 3;
            break;
          case pageStatus.archived:
            customStatus = 4;
            break;
          default:
            break;
        }
        this.setState(
          {
            status: customStatus,
            supportingPendingProcessId: response.pendingProcessId,
            isMount: true,
          },
          () =>
            this.createSupporting(
              createFilesRef,
              response,
              customStatus,
              openModal
            )
        );
      },
      (error) => {
        console.log(error);
        // this._alertError("Get supporting files error!");
      }
    );
  };

  /**
   * success callback for click edit check
   * @param {*} response
   */
  checkSupportingFileEditOnSuccess = (response) => {
    if (response.result) {
      const para = {
        procedureId: this.props.procedureId,
        procedureSectionType: this.props.actionType,
        timeOutMins: timeOutMins,
      };
      ProcedureDetailService.checkSectionLock(
        para,
        (response) =>
          this.checkSupportingFileWhetherLock(
            response.data.isLocked,
            response.data.lockUser
          ),
        (response) => console.log(response.message)
      );
    } else {
      switch (response.messageCode) {
        case 11:
          this.props.setLock("This section is pending approval.");
          this.handleSupportingFileLock();
          break;
        case 4:
          this.props.setLock(
            "Sharing Relationship Process is pending approval."
          );
          this.handleSupportingFileLock();
          break;
        case 2:
          this.props.setLock("Please assign a Primary Procedure Owner first.");
          this.handleSupportingFileLock();
          break;
        default:
          break;
      }
    }
  };

  /**
   * error callback for click edit check
   * @param {*} error
   */
  checkSupportingFileEditOnError = (error) => {
    console.log(error);
    // this._alertError("Check Supporting Files Section Edit Error!");
  };

  /**
   * function about click edit icon in supporting file section
   */
  handleSupportingFileEditClick = () => {
    const param = {
      procedureAccountId: this.props.procedureAccountId,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
    };
    ProcedureDetailService.checkSupportingFileEdit(
      param,
      this.checkSupportingFileEditOnSuccess,
      this.checkSupportingFileEditOnError
    );
  };

  /**
   * control tooltip open
   * @param {*} isOpen
   */
  setScrollModalOpen = (isOpen) => {
    this.setState({
      isSupportingFileEditToolTipOpen: isOpen,
    });
  };

  /**
   * manatory check
   * @param {*} ref
   */
  procedureMandatoryCheck = (ref) => {
    let datalist = ref.current.getDataList();
    if (datalist.length === 0) {
      ref.current.setFileTableStatus("error");
    }
  };

  /**
   * function about edit check callback lock
   */
  handleSupportingFileLock = () => {
    this.setScrollModalOpen(true);
  };

  handleReturnModalSupportingFileTable = (isShowDeleteBtn, isShowShareBtn) => {
    return this.handleReturnSupportingFileTable({
      supportingSubmited: this.state.supportingSubmited,
      supportingSaved: this.state.supportingSaved,
      isShowUploadBtn: true,
      isShowDownloadBtn: true,
      isShowDeleteBtn: isShowDeleteBtn,
      isShowShareBtn: isShowShareBtn,
      isShowActionBtns: true,
      isShowSupportingFileEdit: false,
      isShowUpdate: false,
      isShowHistoryVersion: false,
      savedInDisabled: false,
      className: "supportingModalFiles",
      ref: supportingModalRef,
      fileTableRef: supportingFileModalTableRef,
      pageType: this.props.pageType,
      title: "",
      hintMsg: "",
      inModal: true,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      isSupportingSection: false,
      supportingPendingProcessId: this.state.supportingPendingProcessId,
    });
  };

  openSupportingfileCreateModal = () => {
    this.setState({
      isScrollModalOpen: true,
      isSupportingFileEditToolTipOpen: false,
      scrollModalContent: (
        <ProcedureBlock
          child={this.handleReturnModalSupportingFileTable(true, false)}
          isShowArrow={false}
          className="processProcedureModalBlock supportingFileModalBlock"
          isOpen={true}
        />
      ),
      scrollModalTitle: "Supporting Files",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  /**
   * function about edit check callback unlock
   */
  handleSupportingFileUnlock = () => {
    this.createFiles("modal", this.openSupportingfileCreateModal);
  };

  openSupportingfileUpdateModal = () => {
    this.setState({
      isScrollModalOpen: true,
      isSupportingFileEditToolTipOpen: false,
      scrollModalContent: (
        <ProcedureBlock
          child={this.handleReturnModalSupportingFileTable(false, false)}
          isShowArrow={false}
          className="processProcedureModalBlock supportingFileModalBlock"
          isOpen={true}
        />
      ),
      scrollModalTitle: "Supporting Files",
      handleModalConfirm: () => {
        //there is a empty function
      },
      handleModalCancel: () => {
        //there is a empty function
      },
    });
  };

  /**
   * function about edit check callback unlock
   */
  handleUpdateSupportingFileUnlock = () => {
    this.createFiles("modal", this.openSupportingfileUpdateModal);
  };

  /**
   * function about check whether supporting file section is locked when click edit icon
   * @param {*} isLock
   * @param {*} lockUser
   */
  checkSupportingFileWhetherLock = (isLock, lockUser) => {
    if (isLock) {
      this.props.setLock(`${lockUser} is editing this section.`);
      this.handleSupportingFileLock();
    } else {
      this.props.setActionType(this.props.actionType);
      this.props.setLock("");
      this.state.status === 3
        ? this.handleUpdateSupportingFileUnlock()
        : this.handleSupportingFileUnlock();
    }
  };

  createLine = (dataList, fileId, fileName) => {
    const lineId = this._generateGUID();
    const fileKey = this._generateGUID();
    if (this.state.status === 3) {
      dataList.unshift({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: fileId },
          { name: "fileKey", value: fileKey },
          {
            name: "fileName",
            value: fileName,
            tagValue: fileTags.new,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(getTimeZoneString(this._getDate())),
          },
          {
            name: "uploadByName",
            value: this.user.identityUserInfo.englishName,
          },
          {
            name: "uploadBy",
            value: this.user.identityUserInfo.internalUserID,
          },
          {
            name: "proposedChange",
            value: proposedChangeTags.new,
          },
          { name: "note", value: "" },
          { name: "approveDate", value: null },
          { name: "approveTime", value: null },
          { name: "fileOperate", value: 1 },
        ],
        checked: false,
      });
    } else {
      dataList.unshift({
        data: [
          { name: "lineId", value: lineId },
          { name: "fileId", value: fileId },
          { name: "fileKey", value: fileKey },
          {
            name: "fileName",
            value: fileName,
          },
          {
            name: "uploadedDate",
            value: this._formateDate(getTimeZoneString(this._getDate())),
          },
          {
            name: "uploadByName",
            value: this.user.identityUserInfo.englishName,
          },
          {
            name: "uploadBy",
            value: this.user.identityUserInfo.internalUserID,
          },
          { name: "note", value: "" },
          { name: "approveDate", value: null },
          { name: "fileOperate", value: 1 },
        ],
        checked: false,
      });
    }
  };

  /**
   * success callback for upload file
   * @param {*} result
   * @param {*} returnPara
   * @param {*} ref
   */
  uploadFileOnSuccess = (result, returnPara, ref) => {
    const fileId = result.storageFileID;
    handleUploadErrorCode(result.errorCode);
    const supportingFileData = ref.current.getDataList();
    this.createLine(supportingFileData, fileId, returnPara.fileName);
    this.handleUploadLoading("supporting");
    ref.current.updateDataList(supportingFileData);
    if (returnPara.fileIndex < this.fileList.length - 1) {
      this.uploadFile(returnPara.fileIndex + 1, ref);
    }
  };

  /* function about upload depulicate file when upload file */
  handleReplaceFile = (event, ref) => {
    this.fileList = [...event.target.files];
    this.procedureFileNum = this.fileList.length;
    if (handleAlertUploadFileError(this.fileList)) {
      return;
    }
    const tableDataList = ref.current.getDataList();
    let dataList = [];
    tableDataList.forEach((data) => {
      const item = data.data.find((dataItem) => dataItem.name === "fileName");
      dataList.push(item);
    });

    if (this.state.status === 3) {
      this.updateFileProcess(0, dataList, tableDataList, ref);
    } else {
      this.FileProcess(0, dataList, tableDataList, ref);
    }
  };

  /**
   * funtion about return supporting file section
   * @param {*} obj
   * @returns
   */
  handleReturnSupportingFileTable = (obj) => {
    const {
      supportingSubmited,
      supportingSaved,
      isShowUploadBtn,
      isShowDeleteBtn,
      isShowDownloadBtn,
      isShowShareBtn,
      isShowActionBtns,
      isShowSupportingFileEdit,
      isShowUpdate,
      isShowHistoryVersion,
      className,
      noteDisabled,
      ref,
      fileTableRef,
      pageType,
      title,
      hintMsg,
      inModal,
      isSupportingSection,
    } = obj;
    const componentProps = {
      fileInputRef: supportingFileRef,
      fileTableRef: fileTableRef,
      title: title,
      handleFileSelect: (e) => this.handleReplaceFile(e, ref),
      onSave: () => this.supportingFileOnSave(ref),
      onSubmit: () => this.supportingFileOnSubmit(ref),
      onCancel: () => this.supportingFileOnCancel(ref),
      hasSubmit: supportingSubmited,
      hasSave: supportingSaved,
      isScrollData: true,
      className: className,
      isShowExpendIcon: true,
      isShowUploadBtn: isShowUploadBtn,
      isShowDeleteBtn: isShowDeleteBtn,
      isShowDownloadBtn: isShowDownloadBtn,
      isShowShareBtn: isShowShareBtn,
      isShowActionBtns: isShowActionBtns,
      isShowSupportingFileEdit: isShowSupportingFileEdit,
      isShowUpdate: isShowUpdate,
      isShowHistoryVersion: isShowHistoryVersion,
      hintMsg: hintMsg,
      isEditToolTipOpen: this.state.isSupportingFileEditToolTipOpen,
      handleEditClick: () => this.handleSupportingFileEditClick(),
      onBlur: () => this.setScrollModalOpen(false),
      noteDisabled: noteDisabled,
      pageType: pageType,
      status: this.state.status,
      initProcedureDataList: this.state.initSupportingDataList,
      procedureMandatoryCheck: () => this.procedureMandatoryCheck(ref),
      _showLoading: this.props.showLoading,
      _hideLoading: this.props.hideLoading,
      inModal: inModal,
      taskInformationData: this.props.taskInformationData,
      procedureId: this.props.procedureId,
      procedureTaskId: this.props.procedureTaskId,
      isSupportingSection: isSupportingSection,
      supportingPendingProcessId: this.state.supportingPendingProcessId,
      isProcedureDetailSection: true,
    };
    return this.state.status === 3 ? (
      <UpdateSupportingFileManagement ref={ref} {...componentProps} />
    ) : (
      <BaseSupportingFileManagement ref={ref} {...componentProps} />
    );
  };

  render() {
    const { isShowTable, pageType } = this.props;
    const modalWidth =
      document.getElementsByClassName("procedureBlock")[0] !== undefined &&
      document.getElementsByClassName("procedureBlock")[0].offsetWidth;
    return (
      <>
        <ProcedureBlock
          child={
            isShowTable &&
            this.state.isMount &&
            this.handleReturnSupportingFileTable({
              supportingSubmited: this.state.supportingSubmited,
              supportingSaved: this.state.supportingSaved,
              isShowUploadBtn: false,
              isShowDeleteBtn: false,
              isShowDownloadBtn: true,
              isShowShareBtn: true,
              isShowActionBtns: false,
              isShowSupportingFileEdit: this.props.isShowSupportingFileEdit,
              isShowUpdate: false,
              isShowHistoryVersion: true,
              className: "supportingFiles",
              noteDisabled: true,
              ref: ref,
              fileTableRef: supportingFileTableRef,
              pageType: pageType,
              title: "Supporting Files",
              hintMsg: this.props.hintMsg,
              inModal: false,
              isSupportingSection: true,
              supportingPendingProcessId: this.state.supportingPendingProcessId,
            })
          }
          isShowArrow={false}
          className="processProcedureBlock supportingFileBlock"
          isOpen={true}
        />
        <NormalModal
          okText={this.state.modalOkText}
          cancelText={this.state.modalCancelText}
          childContent={<p className="fs-16">{this.state.modalChildContent}</p>}
          isModalOpen={this.state.isModalOpen}
          handleOk={this.state.handleModalConfirm}
          handleCancel={() => {
            this.state.handleModalCancel();
            this.hideModal();
          }}
          closable={false}
        />
        <ModalWithScroll
          isModalOpen={this.state.isScrollModalOpen}
          modalContent={this.state.scrollModalContent}
          title={this.state.scrollModalTitle}
          handleCloseClick={() => {
            this.hideScrollModal();
            this.handleResetLockWithoutRefresh("SupportingFile");
            this.props.setActionType(null);
          }}
          width={modalWidth}
          actionType={this.props.actionType}
        />
      </>
    );
  }
}
