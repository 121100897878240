import { actions } from "../../../../publicDictionaryValues";
import BasePMPExcludedComplete from "../../basePMPExcluded/basePMPExcludedComplete";
import styles from "../../style/index.module.scss";

export default class ApprovePMPExcludedComplete extends BasePMPExcludedComplete {
  handleReturnPageName = () => {
    return "Approve PMP Excluded";
  };

  handleReturnRejectReason = () => {
    const { newRejectReason } = this.props.pageProps;
    return (
      this.props.actionID === actions.reject &&
      newRejectReason !== null && (
        <div className={styles.rejectReasonBlock}>
          <span className={styles.rejectReasonTip}>Reject Reason</span>
          <p
            className={styles.rejectReasonContent}
            dangerouslySetInnerHTML={{ __html: newRejectReason }}
          />
        </div>
      )
    );
  };
}
