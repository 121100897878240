import React, { createRef } from "react";
import ProcedureBlock from "../../../../base/basicComponents/procedureBlock";
import TaskInformation from "../../../sections/taskInfoSections";
import PageHeader from "../../../commonComponents/pageHeaders/PageHeader";
import ReadOnlyProcedureFileSection from "../../../sections/procedureFileSections/readOnlyProcedureFileSection";
import ReadOnlySupportingFileSection from "../../../sections/supportingFileSections/readOnlySupportingFileSection";
import ActiveShareTaskReadOnly from "../../../sections/sharedTaskSections/activeShareTaskReadOnly";
import ProcedureDetailService from "../../../../service/procedureFile/ProcedureDetailService";
import styles from "../index.module.scss";
import ApproveChangePPOService from "../../../../service/changePPO/ApproveChangePPOService";
import ReassignProcedureInformation from "../../../sections/procedureInfoSections/reassignProcedureInfoSection";
import ProcessBase from "../../../commonComponents/processBase";
import NormalSpin from "../../../../base/basicComponents/spin";
import StepBusinessType from "../../../../service/stepBase/stepBusinessType";
import StepService from "../../../../service/stepBase/StepService";

const procedureFileRef = createRef();
const supportingFileRef = createRef();
const procedureInfoRef = createRef();
export default class ReassignPPOPending extends ProcessBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageHeadState: {
        receiveDate: null,
        requestByName: null,
        procedureStepStatus: null,
      },
      procedureFileData: {
        procedureTableData: [],
        clientServerPath: null,
        savedInRadioValue: null,
      },
      supportingFileData: [],
      procedureInfoState: {},
      taskInformationData: [],
      shareTask: {
        mainTaskAccountId: null,
        procedureProcessID: null,
        reason: null,
        returnReasonLogs: null,
        shareTaskAccountId: null,
        shareTaskProcedureStepID: null,
        shareTasks: [],
        submitReasonLogs: null,
      },
      jbProcedureSavedIn: null,
    };
    this.procedureTableData = [];
    this.procedureTaskId = this._getRoutingParam("ProcedureTaskId");
    this.procedureID = this._getRoutingParam("ProcedureID");
    this.procedureStepID = this._getRoutingParam("ProcedureStepID");
  }

  _showLoading = () => {
    this.setState({ loading: true });
  };

  _hideLoading = () => {
    this.setState({ loading: false });
  };

  closePage = () => {
    window.location = "/ToDoList";
  };

  /* function about adjust data when get data from api */
  handleDataNull = (file, key) => {
    if (file[key] === null) {
      if (key === "procedureFileID") {
        return 0;
      } else {
        return null;
      }
    } else {
      return file[key];
    }
  };

  setProcedureFileData = (result) => {
    if (result.procedureFolder !== null) {
      this.setProcedureTableData(result.procedureFolder);
      this.setState({
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder !== null
              ? result.procedureFolder.procedureSaveInCategoryID
              : null,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
    }
  };

  setSupportingFileData = (result) => {
    if (result.supportingFolder !== null) {
      this.setSupportingTableData(result);
      this.setState({
        supportingFileData: this.supportingTableData,
      });
    }
  };

  setShareTaskData = (result) => {
    if (result.shareTask === null || result.shareTask === undefined) {
      return;
    }

    this.setState({
      shareTask: result.shareTask,
    });
  };

  /* callback about get task information successed */
  _getTaskInfoSuccess = (result) => {
    const data = result;
    this.setState({
      taskInformationData: data,
    });
  };

  /* callback about get task information failed */
  _getTaskInfoFailed = (result) => {
    // this._alertError("Get Task Information Failed");
    console.log(result);
  };

  /* function about get task information section data */
  getTaskInfo = () => {
    ProcedureDetailService.getTaskInfo(
      this.procedureTaskId,
      this._getTaskInfoSuccess,
      this._getTaskInfoFailed
    );
  };

  _getPrimaryProcedureOwnerStepItemSuccess = (result) => {
    const {
      reviceDate,
      requestByName,
      primaryProcedureOwner,
      procedureOwner,
      procedureNote,
      shareTask,
      applyPmpExcluded,
      procedureFolder,
      procedureStepStatus,
      workflowProcessInstanceId,
      procedureStatusID,
      statusCode,
      message,
    } = result;
    if (statusCode === 401) {
      this._alertError(message);
    } else {
      this.setProcedureTableData(procedureFolder);
      this.setSupportingTableData(result);
      this.setState({
        pageHeadState: {
          receiveDate: this._formateDate(reviceDate),
          requestByName,
          procedureStepStatus,
        },
        procedureInfoState: {
          ...this.state.procedureInfoState,
          primaryProcedureOwner,
          procedureOwner: procedureOwner,
          workflowProcessInstanceId,
          isExcluded: applyPmpExcluded?.isExcluded ? "Yes" : "No",
          procedureNote,
          procedureStatus: procedureStatusID,
        },
        shareTask,
        procedureFileData: {
          savedInRadioValue:
            result.procedureFolder?.procedureSaveInCategoryID || null,
          clientServerPath:
            result.procedureFolder !== null
              ? result.procedureFolder.clientServerPath
              : "",
          procedureTableData: this.procedureTableData,
        },
        supportingFileData: this.supportingTableData,
        jbProcedureSavedIn:
          result.procedureFolder === null
            ? null
            : result.procedureFolder.jbProcedureSaveIn,
      });
      this._hideLoading();
    }
  };

  _getPrimaryProcedureOwnerStepItemFailed = () => {
    console.log("Get Primary Procedure Owner Step Item Failed");
  };

  getPrimaryProcedureOwnerStepItem = () => {
    const parentPageData = this._getRoutingParam("ParentPage");
    const param = {
      taskID: this.procedureTaskId,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
      parentPage: parentPageData,
    };

    ApproveChangePPOService.getPrimaryProcedureOwnerStepItem(
      param,
      this._getPrimaryProcedureOwnerStepItemSuccess,
      this._getPrimaryProcedureOwnerStepItemFailed
    );
  };

  componentDidMount() {
    this._showLoading();
    this.getPrimaryProcedureOwnerStepItem();
    this.getTaskInfo();
  }

  render() {
    const { receiveDate, requestByName, procedureStepStatus } =
      this.state.pageHeadState;
    const {
      loading,
      taskInformationData,
      shareTask,
      procedureInfoState,
      jbProcedureSavedIn,
    } = this.state;
    const { procedureTableData, clientServerPath, savedInRadioValue } =
      this.state.procedureFileData;
    const procedureData = {
      ...procedureInfoState,
      procedureID: this.procedureID,
      procedureStepID: this.procedureStepID,
    };
    const params = {
      TaskId: this.procedureTaskId,
      ProcedureID: this.procedureID,
      ProcedureStepID: this.procedureStepID,
    };

    const isReassignPending = procedureStepStatus === "Pending";

    return (
      <NormalSpin spinning={loading} size="large" zIndex={1500}>
        <div className={styles.body}>
          <div className={styles.header}>
            <PageHeader
              title="Change Primary Procedure Owner"
              receiveDate={this._formateDate(receiveDate)}
              requestBy={requestByName}
              status={
                procedureStepStatus
                  ? isReassignPending
                    ? "Pending"
                    : "Complete"
                  : ""
              }
            />
          </div>
          <ProcedureBlock
            className={styles.approveProcedureBlock}
            child={<TaskInformation data={taskInformationData} />}
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setTaskInfoData,
                this.setError,
                [StepBusinessType.taskInfo]
              );
            }}
          />
          <ProcedureBlock
            child={
              <ReadOnlyProcedureFileSection
                procedureFileData={procedureTableData}
                procedureFileRef={procedureFileRef}
                clientServerPath={clientServerPath}
                savedInRadioValue={savedInRadioValue}
                jbProcedureSavedIn={jbProcedureSavedIn}
              />
            }
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setProcedureFileData,
                this.setError,
                [StepBusinessType.procedureFile]
              );
            }}
          />
          <ProcedureBlock
            child={
              <ReadOnlySupportingFileSection
                supportingFileRef={supportingFileRef}
                supportingFileData={this.state.supportingFileData}
              />
            }
            isShowArrow={true}
            isOpen={false}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setSupportingFileData,
                this.setError,
                [StepBusinessType.supportingFile]
              );
            }}
          />
          <ProcedureBlock
            className={styles.ProcedureInfoSection}
            child={
              <ReassignProcedureInformation
                ref={procedureInfoRef}
                procedureTaskId={this.procedureTaskId}
                procedureData={procedureData}
                submitData={procedureInfoState}
                noteDisabled={true}
                isPPODisabled={true}
                title="Procedure Information"
                closePage={this.closePage}
                isReassignPending={isReassignPending}
                _showLoading={this._showLoading}
                _hideLoading={this._hideLoading}
                params={params}
              />
            }
            isShowArrow={true}
            isOpen={true}
          />
          <ProcedureBlock
            className={styles.approveProcedureBlock}
            child={<ActiveShareTaskReadOnly shareTaskData={shareTask} />}
            isShowArrow={true}
            onShowDataLoad={() => {
              return StepService.getStepData(
                params,
                this.setShareTaskData,
                this.setError,
                [StepBusinessType.shareTask]
              );
            }}
          />
        </div>
      </NormalSpin>
    );
  }
}
