import { deepClone } from "../../publicDictionaryValues";

class TablePublicMethods {
  compaireTwoArray = (arr1, arr2) => {
    if (arr1 !== undefined && arr2 !== undefined) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      arr1.sort();
      arr2.sort();

      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr1[i].data.length; j++) {
          const currentList = deepClone(arr1[i]);
          const initList = deepClone(arr2[i]);
          currentList.data.find((item) => item.name === "lineId").value = "";
          initList.data.find((item) => item.name === "lineId").value = "";
          currentList.data.find((item) => item.name === "fileKey").value = "";
          initList.data.find((item) => item.name === "fileKey").value = "";
          const currentStr = JSON.stringify(currentList.data[j]).replaceAll(
            `""`,
            `${null}`
          );
          const initStr = JSON.stringify(initList.data[j]).replaceAll(
            `""`,
            `${null}`
          );
          if (currentStr !== initStr) {
            return false;
          }
        }
      }

      return true;
    }
  };

  compaireTwoCircular = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const list1 = deepClone(arr1);
    const list2 = deepClone(arr2);

    list1.sort();
    list2.sort();

    for (let i = 0; i < list1.length; i++) {
      for (let j = 0; j < list1[i].data.length; j++) {
        const currentStr = JSON.stringify(list1[i].data[j]).replaceAll(
          `""`,
          `${null}`
        );
        const initStr = JSON.stringify(list2[i].data[j]).replaceAll(
          `""`,
          `${null}`
        );
        if (currentStr !== initStr) {
          return false;
        }
      }
    }

    return true;
  };
}

const tablePublicMethods = new TablePublicMethods();
export default tablePublicMethods;
