import ApproveProcedureFileManagement from "../../../../procedureFileSections/fields/procedureFileManagementFields/approveProcedureFileManagement";
import ApporveUpdateSupportingFileTable from "../../supportingFileTable/approveUpdateSupportingFileTable";

export default class ApproveUpdateSupportingFileManagement extends ApproveProcedureFileManagement {
  handleReturnColumns = (obj) => {
    const { isDisableAll, status, reasonRef } = obj;
    return [
      {
        name: "ProcedureId",
        dataName: "procedureId",
        type: "text",
        isShow: false,
      },
      {
        name: "checkbox",
        dataName: "checkbox",
        type: "checkbox",
        isShow: true,
        check: this._check,
        onCheck: this._onCheck,
        headCheck: this._headCheck,
        headOnCheck: this._headOnCheck,
      },
      {
        name: "File Name",
        dataName: "fileName",
        type: "fileName",
        isShow: true,
        isDisabled: isDisableAll,
      },
      {
        name: "Uploaded Date",
        dataName: "uploadedDate",
        type: "text",
        isShow: true,
      },
      {
        name: "Uploaded By",
        dataName: "uploadByName",
        type: "text",
        isShow: true,
      },
      {
        name: "Proposed Change",
        dataName: "proposedChange",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Approval Date",
        dataName: "approveDate",
        type: "text",
        isShow: status === 3 || this.props.isCopyPage,
      },
      {
        name: "Status",
        dataName: "status",
        type: "tag",
        isShow: !this.props.isCopyPage,
      },
      {
        name: "Return Reason",
        dataName: "returnReason",
        type: "input",
        ref: reasonRef,
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
        isDisabled: isDisableAll,
      },
      {
        name: "Attachment",
        dataName: "attachment",
        type: "link",
        isShow: !this.props.isCopyPage,
        showEdit: !isDisableAll,
      },
      {
        name: "Action",
        dataName: "action",
        type: "button",
        isApproveDisabled: false,
        isReturnDisabled: false,
        approveText: "Approve",
        returnText: "Return to Draft",
        approveColor: "blue",
        returnColor: "grey",
        onApproveClick: this.handleProcedureFileTableApprovedClick,
        onReturnClick: this.handleProcedureFileTableReturnClick,
        isShow: !isDisableAll && !this.props.isCopyPage,
      },
    ];
  };

  handleReturnFileTable = (obj) => {
    const {
      optionList,
      fileTableStatus,
      currentRole,
      downloadFile,
      initProcedureTableData,
      fileTableRef,
      isTableBorderError,
      isDisableAll,
      columns,
      className,
    } = obj;
    return (
      <ApporveUpdateSupportingFileTable
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        inputOnChange={this.inputOnChange}
        fileTableRef={fileTableRef}
        className={className}
        ref={fileTableRef}
        columns={columns}
        optionList={optionList}
        isShowReturnReason={true}
        handleReturnClick={(e) =>
          this.handleProcedureFileTableReturnClick(
            e.target.attributes.rowindex.value
          )
        }
        procedureNoteMaxLength={1000}
        procedureNoteSize="small"
        isComplete={isDisableAll}
        initProcedureTableData={initProcedureTableData}
        fileTableStatus={fileTableStatus}
        isTableBorderError={isTableBorderError}
        currentRole={currentRole}
        downloadFile={downloadFile}
        isRejectCompletePage={this.props.isRejectCompletePage}
      />
    );
  };
}
