import { Tooltip } from "antd";
import { GetColumnCheckboxGroupFilterDropdown } from "../../commonComponents/listTable/CustomFilter";
import ListColumnBase from "../../commonComponents/listBase/listColumnBase";
import "../../../base/basicComponents/status/statusDiv.scss";
export default function MyRequestListColumn({
  myRequestListFilterData,
  filteredInfo,
  transactionIdShow,
  taskLabelNameShow,
  processCategoryShow,
  processInitiateDateShow,
  processInitiatorShow,
  accountAbbrShow,
  accountNameShow,
  taskGroupShow,
  categoryShow,
  taskIdShow,
  highlyRegulatedShow,
  triggerProcessShow,
  processStatusShow,
  currentProcessorShow,
  processCompletedDateShow,
  tableSortData,
  handleColumnFilterClick,
  filterLoading,
}) {
  const {
    mapFilter,
    handleFilteredValue,
    handleWidth,
    handleTableSortOrder,
    formatDate,
  } = ListColumnBase();
  const columns = [
    {
      title: "PMP Transaction ID",
      dataIndex: "transactionId",
      dbName: "TransactionID",
      width: handleWidth(!transactionIdShow, 160),
      ellipsis: {
        showTitle: false,
      },
      render: (transactionId) => (
        <Tooltip placement="topLeft" title={transactionId}>
          {transactionId}
        </Tooltip>
      ),
    },
    {
      title: "Account Code",
      dataIndex: "accountAbbr",
      dbName: "AccountAbbr",
      sortName: "AccountAbbr",
      sorter: true,
      sortOrder: handleTableSortOrder("AccountAbbr", tableSortData),
      width: handleWidth(!accountAbbrShow, 145),
      ellipsis: {
        showTitle: false,
      },
      render: (accountAbbreviation) => (
        <Tooltip placement="topLeft" title={accountAbbreviation}>
          {accountAbbreviation}
        </Tooltip>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
      dbName: "AccountName",
      ellipsis: {
        showTitle: false,
      },
      render: (accountName) => (
        <Tooltip placement="topLeft" title={accountName}>
          {accountName}
        </Tooltip>
      ),
      width: handleWidth(!accountNameShow, 220),
    },
    {
      title: "Task Group",
      dataIndex: "taskGroupName",
      dbName: "TaskGroupID",
      sortName: "TaskGroupName",
      filters: mapFilter(myRequestListFilterData.TaskGroupName),
      filteredValue: handleFilteredValue(filteredInfo.taskGroupName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TaskGroupName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("TaskGroupName", tableSortData),
      width: handleWidth(!taskGroupShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (taskGroup) => (
        <Tooltip placement="topLeft" title={taskGroup}>
          {taskGroup}
        </Tooltip>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      dbName: "CategoryName",
      sortName: "CategoryName",
      filters: mapFilter(myRequestListFilterData.CategoryName, false, null, true),
      filteredValue: handleFilteredValue(filteredInfo.categoryName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'CategoryName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("CategoryName", tableSortData),
      width: handleWidth(!categoryShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (category) => (
        <Tooltip placement="topLeft" title={category}>
          {category}
        </Tooltip>
      ),
    },
    {
      title: "Task ID",
      dataIndex: "taskId",
      dbName: "TaskID",
      width: handleWidth(!taskIdShow, 100),
      ellipsis: {
        showTitle: false,
      },
      render: (taskId) => (
        <Tooltip placement="topLeft" title={taskId}>
          {taskId}
        </Tooltip>
      ),
    },
    {
      title: "Task Label Name",
      dataIndex: "taskLabelName",
      dbName: "TaskLabelName",
      sortName: "TaskLabelName",
      filters: mapFilter(myRequestListFilterData.TaskLabelName),
      filteredValue: handleFilteredValue(filteredInfo.taskLabelName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TaskLabelName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("TaskLabelName", tableSortData),
      width: handleWidth(!taskLabelNameShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (taskLabelName) => (
        <Tooltip placement="topLeft" title={taskLabelName}>
          {taskLabelName}
        </Tooltip>
      ),
    },
    {
      title: "Highly Regulated?",
      dataIndex: "highlyRegulated",
      dbName: "HighlyRegulated",
      filters: mapFilter(myRequestListFilterData.HighlyRegulated),
      filteredValue: handleFilteredValue(filteredInfo.highlyRegulated),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'HighlyRegulated'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!highlyRegulatedShow, 170),
      ellipsis: {
        showTitle: false,
      },
      render: (highlyRegulated) => (
        <Tooltip placement="topLeft" title={highlyRegulated}>
          {highlyRegulated}
        </Tooltip>
      ),
    },
    {
      title: "Process Category",
      dataIndex: "processCategoryName",
      dbName: "ProcessCategoryName",
      sortName: "ProcessCategoryName",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessCategoryName", tableSortData),
      width: handleWidth(!processCategoryShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          <a
            href={"/ProcessDetail/" + record.processID}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Trigger Process",
      dataIndex: "triggerProcessName",
      dbName: "TriggerProcess",
      sortName: "TriggerProcessName",
      filters: mapFilter(myRequestListFilterData.TriggerProcessName),
      filteredValue: handleFilteredValue(filteredInfo.triggerProcessName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'TriggerProcessName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder(
        "TriggerProcessName",
        tableSortData
      ),
      width: handleWidth(!triggerProcessShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (triggerProcess, record) => (
        <Tooltip placement="topLeft" title={triggerProcess}>
          <a
            href={"/ProcessDetail/" + record.parentProcessID}
            target="_blank"
            rel="noopener noreferrer"
          >
            {triggerProcess}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Process Status",
      dataIndex: "processStatus",
      dbName: "ProcessStatus",
      sortName: "ProcessStatus",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessStatus", tableSortData),
      width: handleWidth(!processStatusShow, 220),
      ellipsis: {
        showTitle: false,
      },
      render: (processStatus) => {
        let className;

        switch (processStatus) {
          case "Pending Approval":
            className = "pendingApproval";
            break;
          case "Pending Submission":
            className = "pendingSubmission";
            break;
          case "Rejected":
            className = "rejected";
            break;
          case "Approved":
            className = "approval";
            break;
          case "System Termination":
            className = "systemTermination";
            break;
          default:
            className = "";
        }
        return (
          <Tooltip placement="topLeft" title={processStatus}>
            <div className={`MyRequestProcessStatusDiv ${className}`}>
              {processStatus}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Current Processor",
      dataIndex: "currentProcessor",
      dbName: "CurrentProcessor",
      sortName: "CurrentProcessor",
      filters: mapFilter(myRequestListFilterData.CurrentProcessor),
      filteredValue: handleFilteredValue(filteredInfo.currentProcessor),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'CurrentProcessor'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      sorter: true,
      sortOrder: handleTableSortOrder("CurrentProcessor", tableSortData),
      width: handleWidth(!currentProcessorShow, 243),
      ellipsis: {
        showTitle: false,
      },
      render: (currentProcessor) => (
        <Tooltip placement="topLeft" title={currentProcessor}>
          {currentProcessor}
        </Tooltip>
      ),
    },
    {
      title: "Process Initiate Date",
      dataIndex: "processInitiateDate",
      dbName: "ProcessInitiateDate",
      sortName: "ProcessInitiateDate",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessInitiateDate", tableSortData),
      width: handleWidth(!processInitiateDateShow, 185),
      ellipsis: {
        showTitle: false,
      },
      render: (processInitiateDate) => (
        <Tooltip placement="topLeft" title={formatDate(processInitiateDate)}>
          {formatDate(processInitiateDate)}
        </Tooltip>
      ),
    },
    {
      title: "Process Initiator",
      dataIndex: "processInitiatorName",
      dbName: "ProcessInitiator",
      filters: mapFilter(myRequestListFilterData.ProcessInitiatorName),
      filteredValue: handleFilteredValue(filteredInfo.processInitiatorName),
      onFilterDropdownOpenChange: (e) => handleColumnFilterClick(e, 'ProcessInitiatorName'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
      }) =>
        GetColumnCheckboxGroupFilterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          filters,
          filterLoading,
        }),
      width: handleWidth(!processInitiatorShow, 155),
      ellipsis: {
        showTitle: false,
      },
      render: (processInitiator) => (
        <Tooltip placement="topLeft" title={processInitiator}>
          {processInitiator}
        </Tooltip>
      ),
    },
    {
      title: "Process Completed Date",
      dataIndex: "processCompletedDate",
      dbName: "ProcessCompletedDate",
      sortName: "ProcessCompletedDate",
      sorter: true,
      sortOrder: handleTableSortOrder("ProcessCompletedDate", tableSortData),
      width: handleWidth(!processCompletedDateShow, 210),
      ellipsis: {
        showTitle: false,
      },
      render: (processCompletedDate) => {
        if (processCompletedDate) {
          return (
            <Tooltip
              placement="topLeft"
              title={formatDate(processCompletedDate)}
            >
              {formatDate(processCompletedDate)}
            </Tooltip>
          );
        }
        return <></>;
      },
    },
    {
      title: "",
      dataIndex: "space",
      render: () => {},
      width: 1,
    },
  ];

  return columns;
}
