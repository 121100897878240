export default class MenuModel {
    
    constructor(param) {
        this.url = param.url;
        this.name = param.name;
        this.icon = param.icon;
        this.children = this.createChildren(param.children);
        this.order = param.sort;
        this.active = param.default;
        this.routing = param.routing;
        this.key = param.key;
        this.iconKey = param.iconKey;
    }

    createChildren(children) {
        if (children == null) {
            return null;
        }

        let result = [];
        for (let i = 0; i < children.length; i++) {

            result.push(new MenuModel(children[i]))
        }

        return result.sort((a, b) => ((a.order > b.order) && 1) || -1);
    }
}