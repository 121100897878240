import { Table } from "antd";
import BaseComponent from "../../../base/common/BaseComponent";
import styles from "./index.module.scss";
import "./antTableCustom.scss";

class ListTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleTableChange = (pagination, filters, sorter) => {
    const tableFilter = filters;
    const tableSorter = sorter;
    let filterArr = [];
    let sortArr = [];

    if (Object.values(tableFilter).some((value) => value !== null)) {
      filterArr = Object.entries(tableFilter)
        .filter(([key, value]) => value !== null)
        .map(([key, value]) => {
          return {
            filterColumn: this.props.tableFilterMap.get(key),
            filterValue: value,
          };
        });
    }

    if (
      Object.keys(tableSorter).length !== 0 &&
      tableSorter.column !== undefined
    ) {
      sortArr.push({
        sortColumn: tableSorter.column.sortName ?? tableSorter.column.dbName,
        isAscending: tableSorter.order === "ascend" ? true : false,
      });
    }

    this.props.handleFilterSort(filterArr, sortArr, filters);
  };

  render() {
    const { data, columns, tableWidth, tableHeight } = this.props;

    const filteredColumns = columns.filter((column) => !column.hidden);

    return (
      <div className={styles.tableDiv}>
        <Table
          columns={filteredColumns}
          rowKey={this._generateGUID()}
          dataSource={data}
          pagination={false}
          onChange={this.handleTableChange}
          scroll={{
            x: tableWidth,
            y: tableHeight,
          }}
          rowClassName={(record) => (record.isHighlight ? "red-row" : "")}
        />
      </div>
    );
  }
}

export default ListTable;
