export default class validationStateModel {
    
    /**
     * @param  {object} keyValuePairs 
     */
    constructor(keyValuePairs) {
        for (let key in keyValuePairs) {
            this[key] = keyValuePairs[key];
        }
    }
}