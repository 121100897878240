import { createRef } from "react";
import { flowCategoryId } from "../../../../publicDictionaryValues";
import CreateSupportingFileReviseSection from "../../../../sections/supportingFileSections/createSupportingFileReviseSection";
import BaseNewSupportingFileComplete from "../../baseNewSupportingFile/baseNewSupportingFileComplete";

const supportingFileTableRef = createRef();
const reasonRef = createRef();
const noteRef = createRef();
export default class ReviseNewSupportingFileComplete extends BaseNewSupportingFileComplete {
  constructor(props) {
    super(props);
    const { rejectReason, rejectStatus } = this.props.pageProps;
    this.state = {
      pageState: {
        rejectReason,
        rejectStatus,
        isCancelDisabled: false,
        isSubmitDisabled: false,
        isRejectDisabled: false,
        isTableBorderError: false,
        isChange: false,
      },
    };
  }

  /* function about control save button disable when procedure file table change */
  cancelIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: false,
      },
    });
  };

  /* function about control save button disable when procedure file table change */
  setIsTableChange = () => {
    this.setState({
      pageState: {
        ...this.state.pageState,
        isChange: true,
      },
    });
  };

  handleReturnPageName = () => {
    if (
      this.props.pageProps.flowCategoryID === flowCategoryId.copySupportingFile
    ) {
      return "Copy Supporting File";
    }

    return "Revise New Supporting File";
  };

  handleReturnSupportingFileSection = () => {
    const { currentRole, supportingReplaceFileRef, isDisableAll } =
      this.props.pageProps;
    return (
      <CreateSupportingFileReviseSection
        sectionProps={{ currentRole, isDisableAll }}
        initSupportingTableData={this.props.initSupportingTableData}
        refs={{
          supportingRef: this.props.supportingRef,
          supportingFileTableRef,
          reasonRef,
          noteRef,
          supportingReplaceFileRef,
        }}
        data={this.props.data}
        setIsTableChange={this.setIsTableChange}
        cancelIsTableChange={this.cancelIsTableChange}
        isCopyPage={this.props.isCopyPage}
      />
    );
  };
}
