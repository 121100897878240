import BaseComponent from "../../../base/common/BaseComponent";
import { procedureJbStatus } from "../../publicDictionaryValues";

class CreateProcedureTableData extends BaseComponent {
  createProcedureData = (files, ref, status) => {
    const dataList = [];
    files !== null &&
      files.forEach((item) => {
        const lineId = this._generateGUID();
        dataList.push({
          data: [
            { name: "lineId", value: lineId },
            { name: "procedureFileID", value: item.procedureFileID },
            { name: "fileId", value: item.fileId },
            { name: "fileKey", value: item.fileKey },
            {
              name: "fileName",
              value: item.fileName,
            },
            { name: "uploadedDate", value: this._formateDate(item.uploadedOn) },
            { name: "uploadByName", value: item.uploadedByName },
            { name: "uploadBy", value: item.uploadedBy },
            { name: "note", value: item.note },
            {
              name: "approveDate",
              value: this._formateDate(item.approvedDate),
            },
            { name: "fileOperate", value: 1 },
            { name: "jbProcedure", value: procedureJbStatus[item.jbProcedure] },
          ],
          checked: false,
        });
      });
    ref?.current && ref.current.updateDataList(dataList);
    return dataList;
  };

  createUpdateProcedureData = (files, ref) => {
    const dataList = [];
    files !== null &&
      files.forEach((item) => {
        const lineId = this._generateGUID();
        dataList.push({
          data: [
            { name: "lineId", value: lineId },
            { name: "procedureFileID", value: item.procedureFileID },
            { name: "initFileId", value: item.fileId },
            { name: "fileId", value: item.fileId },
            { name: "initFileKey", value: item.fileKey },
            { name: "fileKey", value: item.fileKey },
            { name: "initFileName", value: item.fileName },
            {
              name: "fileName",
              value: item.fileName,
            },
            { name: "uploadedDate", value: this._formateDate(item.uploadedOn) },
            { name: "uploadByName", value: item.uploadedByName },
            { name: "uploadBy", value: item.uploadedBy },
            { name: "proposedChange", value: "" },
            {
              name: "approveDate",
              value: this._formateDate(item.approvedDate),
            },
            { name: "approveTime", value: item.approvedDate },
            { name: "fileOperate", value: 1 },
            { name: "jbProcedure", value: procedureJbStatus[item.jbProcedure] },
          ],
          checked: false,
        });
      });
    ref?.current && ref.current.updateDataList(dataList);
    return dataList;
  };

  createSupportingData = (files, ref) => {
    const dataList = [];
    files !== null &&
      files.forEach((item) => {
        const lineId = this._generateGUID();
        dataList.push({
          data: [
            { name: "lineId", value: lineId },
            { name: "fileId", value: item.fileId },
            { name: "fileKey", value: item.fileKey },
            {
              name: "fileName",
              value: item.fileName,
            },
            { name: "uploadedDate", value: this._formateDate(item.uploadedOn) },
            { name: "uploadByName", value: item.uploadedByName },
            { name: "uploadBy", value: item.uploadedBy },
            { name: "note", value: item.note },
            {
              name: "approveDate",
              value: this._formateDate(item.approvedDate),
            },
            { name: "fileOperate", value: 1 },
            { name: "jbProcedure", value: "" },
          ],
          checked: false,
        });
      });
    ref?.current && ref.current.updateDataList(dataList);
    return dataList;
  };

  createUpdateSupportingData = (files, ref) => {
    const dataList = [];
    files !== null &&
      files.forEach((item) => {
        const lineId = this._generateGUID();
        dataList.push({
          data: [
            { name: "lineId", value: lineId },
            { name: "initFileId", value: item.fileId },
            { name: "fileId", value: item.fileId },
            { name: "initFileKey", value: item.fileKey },
            { name: "fileKey", value: item.fileKey },
            { name: "initFileName", value: item.fileName },
            {
              name: "fileName",
              value: item.fileName,
              tagValue: "",
            },
            { name: "uploadedDate", value: this._formateDate(item.uploadedOn) },
            { name: "uploadByName", value: item.uploadedByName },
            { name: "uploadBy", value: item.uploadedBy },
            { name: "proposedChange", value: "" },
            { name: "note", value: item.note },
            {
              name: "approveDate",
              value: this._formateDate(item.approvedDate),
            },
            { name: "approveTime", value: item.approvedDate },
            { name: "fileOperate", value: 1 },
            { name: "jbProcedure", value: "" },
          ],
          checked: false,
        });
      });
    ref?.current && ref.current.updateDataList(dataList);
    return dataList;
  };
}

const createProcedureTableData = new CreateProcedureTableData();
export default createProcedureTableData;
