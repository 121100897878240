import BaseComponent from "../../../base/common/BaseComponent";

class FormateTaskInfoData extends BaseComponent {
  setTaskInfoData = (result) => {
    return {
      accountAbbr: result.taskInfomation.accountAbbr,
      accountName: result.taskInfomation.accountName,
      accountManager: result.taskInfomation.accountManager,
      accountSupervisor: result.taskInfomation.accountSupervisor,
      accountTeamLeader: result.taskInfomation.accountTeamLeader,
      taskGroupName: result.taskInfomation.taskGroupName,
      categoryName: result.taskInfomation.categoryName,
      taskLabelName: result.taskInfomation.taskLabelName,
      taskID: result.taskInfomation.taskID,
      taskStatus: result.taskInfomation.taskStatus,
      createdDate: result.taskInfomation.createdDate,
      highRegulated: result.taskInfomation.highRegulated,
      turnaroundTime: result.taskInfomation.turnaroundTime,
      temporaryTask: result.taskInfomation.temporaryTask,
    };
  };
}

const formateTaskInfoData = new FormateTaskInfoData();
export default formateTaskInfoData;
