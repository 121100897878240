import React, { createRef } from "react";
import InputLabel from "../../../../../base/basicComponents/inputLabel";
import NormalSelect from "../../../../../base/basicComponents/select";
import { FileUploadDragger } from "../../../../../base/basicComponents/uploadFile";
import { NormalTextArea } from "../../../../../base/basicComponents/input";
import Buttons from "../../../../../base/basicComponents/button";
import NormalModal from "../../../../../base/basicComponents/modal";
import {
  signOffSelectValues,
  actions,
} from "../../../../publicDictionaryValues";
import styles from "../styles/index.module.scss";
import ProcedureDetailService from "../../../../../service/procedureFile/ProcedureDetailService";
import BaseComponent from "../../../../../base/common/BaseComponent";
import appService from "../../../../../service/app";
import LostPermissionModal from "../../../../sections/lostPermissionModal";

const attachmentRef = createRef();
const attachmentInputRef = createRef();
export default class BaseAlignWithClient extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectValue:
        this.props.alignWithClientValue === null
          ? signOffSelectValues.No
          : this.props.alignWithClientValue,
      reason: this.props.reasonValue,
      fileList: this.props.attachmentFiles,
      fileIdList: this.props.attachmentFileIds,
      fileNameList: this.props.attachmentFileNames,
      submitDisabled:
        this.props.alignWithClientValue === null ||
        this.props.alignWithClientValue === signOffSelectValues.No
          ? true
          : false,
      cancelDisabled: true,
      isModalOpen: false,
      isReasonError: false,
      isComplete: this.props.isComplete,
      action: "",
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
      noPermission: false,
    };

    this.initState = {
      selectValue:
        this.props.alignWithClientValue === null
          ? signOffSelectValues.No
          : this.props.alignWithClientValue,
      reason: null,
    };

    this.optionList = [
      { name: "No", value: signOffSelectValues.No },
      { name: "No Need", value: signOffSelectValues.NoNeed },
      { name: "Yes", value: signOffSelectValues.Yes },
    ];
  }

  /* function about change align with client select */
  handleSelectChange = (e) => {
    if (e !== this.state.selectValue) {
      attachmentRef.current.cancelMandatory();
      this.setState({ isReasonError: false });
    }
    this.setState({ selectValue: e });
    if (
      e !== this.initState.selectValue ||
      (this.state.reason !== this.initState.reason &&
        this.state.reason !== "") ||
      (this.state.fileList !== null && this.state.fileList.length > 0)
    ) {
      this.setState({ cancelDisabled: false });
      if (e !== signOffSelectValues.No) {
        this.setState({ submitDisabled: false });
      }
    } else {
      this.setState({
        cancelDisabled: true,
      });
    }
    if (e === signOffSelectValues.No) {
      this.setState({ submitDisabled: true });
    }
  };

  /* function about upload attach email */
  handleFileListChange = (list) => {
    this.setState({ fileList: list });
    if (
      list.length > 0 ||
      (this.state.reason !== this.initState.reason &&
        this.state.reason !== "") ||
      this.state.selectValue !== this.initState.selectValue
    ) {
      this.setState({ cancelDisabled: false });
      if (this.state.selectValue !== signOffSelectValues.No) {
        this.setState({ submitDisabled: false });
      }
    } else {
      this.setState({
        cancelDisabled: true,
      });
    }
    if (this.state.selectValue === signOffSelectValues.No) {
      this.setState({ submitDisabled: true });
    }
  };

  /* function about change reason */
  handleReasonChange = (e) => {
    const value = e.target.value;
    this.setState({ reason: value, isReasonError: false });
    if (
      (value !== this.initState.reason && value !== "") ||
      this.state.selectValue !== this.initState.selectValue ||
      (this.state.fileList !== null && this.state.fileList.length > 0)
    ) {
      this.setState({ cancelDisabled: false });
      if (this.state.selectValue !== signOffSelectValues.No) {
        this.setState({ submitDisabled: false });
      }
    } else {
      this.setState({
        cancelDisabled: true,
      });
    }
    if (this.state.selectValue === signOffSelectValues.No) {
      this.setState({ submitDisabled: true });
    }
  };

  /* function about create submit modal */
  setSubmitModal = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: <span>Are you sure to submit?</span>,
      action: "submit",
    });
  };

  _checkStepPermissionSuccess = (result) => {
    this.props.hideLoading();
    const { statusCode } = result;
    const noPermission = statusCode !== 200;
    this.setState({
      noPermission,
    });
    if (noPermission) return;
    this.handleSubmitClick();
  };

  checkStepPermission = () => {
    this.props.openLoading();
    const param = {
      taskID: this.props.params.ProcedureTaskId,
      procedureID: this.props.params.ProcedureID,
      procedureStepID: this.props.params.ProcedureStepID,
      parentPage: null,
    };
    appService.checkStepPermission(
      param,
      this._checkStepPermissionSuccess,
      (response) => console.log(response.message)
    );
  };

  /* function about click submit button in section */
  handleSubmitClick = () => {
    if (this.state.selectValue === signOffSelectValues.Yes) {
      if (this.state.fileList.length === 0) {
        this.state.fileList.length === 0 &&
          attachmentRef.current.checkMandatory();
        const uploadInput = document.getElementsByClassName(
          "uploadInputContainer"
        )[0];
        window.scrollTo(0, uploadInput.offsetTop || 0);
      } else {
        this.setSubmitModal();
      }
    } else if (this.state.selectValue === signOffSelectValues.NoNeed) {
      const reasonInput = document.getElementsByClassName("reasonInput")[0];
      if (
        (this.state.reason !== null && this.state.reason.trim() === "") ||
        this.state.reason === null
      ) {
        this.setState({
          isReasonError: true,
        });
        window.scrollTo(0, reasonInput.offsetTop || 0);
      } else {
        this.setSubmitModal();
      }
    }
  };

  /* function about click cancel button in section */
  handleCancelClick = () => {
    this.setState({
      isModalOpen: true,
      modalOkText: "Confirm",
      modalCancelText: "Cancel",
      modalChildContent: (
        <span>Are you sure to quit without saving?</span>
      ),
      action: "cancel",
    });
  };

  /* function about click cancel button in modal */
  handleModalCancelClick = () => {
    this.setState({
      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
      action: "",
    });
  };

  /* callback about submit success */
  signOffTriggerSuccess = (response) => {
    if (response.succeeded === false) {
      console.log(response.message);
    }
    this.setState({
      submitDisabled: true,
      cancelDisabled: true,
      isModalOpen: false,
    });
    this.props.hideLoading();
    this.props.closePage();
  };

  /* function about click confirm button in section */
  handleSubmitModalConfirmClick = () => {
    this.props.openLoading();
    this.setState({
      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
    });
    const submitData = this.setSubmitData();
    ProcedureDetailService.signOffTriggerAction(
      submitData,
      this.signOffTriggerSuccess,
      (error) => console.log(error)
    );
  };

  /* function about confirm button click in cancel modal */
  handleCancelModalConfirmClick = () => {
    this.setState({
      submitDisabled: true,
      cancelDisabled: true,
      isModalOpen: false,
      modalOkText: "",
      modalCancelText: "",
      modalChildContent: "",
    });
    this.props.closePage();
  };

  /* function about click confirm button in modal */
  handleModalOkClick = () => {
    const { action } = this.state;
    switch (action) {
      case "submit":
        return this.handleSubmitModalConfirmClick();
      case "cancel":
        return this.handleCancelModalConfirmClick();
      default:
        return;
    }
  };

  /* function about create submit data for submit api */
  setSubmitData = () => {
    const fileList = attachmentRef.current.state.fileList;
    const fileIds = attachmentRef.current.state.fileIdList;
    const {
      procedureId,
      workflowProcessInstanceId,
      flowCategoryID,
      procedureStepID,
      signOffStepID,
    } = this.props.submitIds;
    let submitFiles = [];
    fileList.forEach((item, index) => {
      submitFiles.push({
        attachEmailFileName: item.name,
        signOffStepID: signOffStepID,
        fileID: fileIds[index],
      });
    });
    return {
      procedureId: procedureId,
      workflowProcessInstanceId: workflowProcessInstanceId,
      flowCategoryID: flowCategoryID,
      actionName: "Submit",
      actionID: actions.submit,
      accountID: null,
      procedureStepID: procedureStepID,
      rejectReason: null,
      signOffStepID: signOffStepID,
      alignedWithClientID: this.state.selectValue,
      reason: this.state.reason,
      attachEmailFiles: [...submitFiles],
    };
  };

  render() {
    const {
      selectValue,
      reason,
      submitDisabled,
      cancelDisabled,
      modalOkText,
      modalCancelText,
      modalChildContent,
      isModalOpen,
      isReasonError,
      isComplete,
      fileIdList,
      fileNameList,
    } = this.state;

    const { openLoading, hideLoading, updateFileNumLoading } = this.props;

    return (
      <div className={styles.alignWithClientContainer}>
        <div className={styles.awcTitle}>Align with Client</div>
        <div className={styles.awcFieldDiv}>
          <div className={styles.awcSelect}>
            <InputLabel text="Aligned with Client?" />
            <NormalSelect
              value={selectValue}
              optionList={this.optionList}
              handleSelect={(e) => this.handleSelectChange(e)}
              disabled={isComplete}
            />
          </div>
          <div className={styles.awcBottomDiv}>
            <div className={styles.attachEmailDiv}>
              <InputLabel
                text="Attach Email"
                required={selectValue === signOffSelectValues.Yes}
              />
              <FileUploadDragger
                ref={attachmentRef}
                className={styles.fileDragger}
                fileInputRef={attachmentInputRef}
                isShowBox={true}
                isMultiple={true}
                handleFileListChange={this.handleFileListChange}
                isShowDeleteBtn={!isComplete}
                openLoading={openLoading}
                hideLoading={hideLoading}
                updateFileNumLoading={updateFileNumLoading}
                fileIds={fileIdList}
                fileNames={fileNameList}
                isDownload={isComplete}
                fileNameType="link"
              />
            </div>
            <div className={styles.reasonDiv}>
              <InputLabel
                text="Reason"
                required={selectValue === signOffSelectValues.NoNeed}
              />
              <NormalTextArea
                onChange={(e) => this.handleReasonChange(e)}
                value={reason}
                width="44.75"
                className="reasonInput"
                status={isReasonError ? "error" : ""}
                disabled={isComplete}
              />
            </div>
          </div>
        </div>
        {!isComplete && (
          <div className={styles.awcButton}>
            <Buttons
              color="grey"
              disabled={cancelDisabled}
              size="middle"
              onClick={this.handleCancelClick}
            >
              Cancel
            </Buttons>
            <Buttons
              color="blue"
              disabled={submitDisabled}
              size="middle"
              onClick={this.checkStepPermission}
            >
              Submit
            </Buttons>
          </div>
        )}
        <NormalModal
          okText={modalOkText}
          cancelText={modalCancelText}
          childContent={<p className="fs-16">{modalChildContent}</p>}
          isModalOpen={isModalOpen}
          handleOk={() => this.handleModalOkClick()}
          handleCancel={() => this.handleModalCancelClick()}
        />
        <LostPermissionModal isModalOpen={this.state.noPermission} />
      </div>
    );
  }
}
