import BaseComponent from "../../common/BaseComponent";

class ControlPageBehavior extends BaseComponent {
  constructor(props) {
    super(props);
    this.overTime = 20;
    this.ele = null;
    this.method = () => {
      // empty function
    };
  }
  resetOverTime = () => {
    clearInterval(this.timer);
    this.overTime = 20;
    this.resetListener(this.ele, this.method);
  };

  resetListener = (ele, method) => {
    this.ele = ele;
    this.method = method;
    this.timer = setInterval(() => {
      this.overTime -= 1;
      if (this.overTime === 0) {
        method();
      }
    }, 60000);
  };

  clearListener = () => {
    clearInterval(this.timer);
  };

  addEleListener = (ele) => {
    ele.addEventListener("dbclick", () => {
      this.resetOverTime();
    });
    ele.addEventListener("mousedown", () => {
      this.resetOverTime();
    });
    ele.addEventListener("keydown", () => {
      this.resetOverTime();
    });
    ele.addEventListener("keypress", () => {
      this.resetOverTime();
    });
  };
}

const controlPageBehavior = new ControlPageBehavior();
export default controlPageBehavior;
export const timeOutMins = 20;
